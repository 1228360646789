import ComingSoonView from "components/views/ComingSoonView";
import React from "react";
import { Redirect, Route, useRouteMatch } from "react-router";
import { RetailerDataType } from "../retailerDashboard";
import PromotionsPage from "../retailerDashboard/SinglePages/PromotionsPage";
import BusinessSummary from "./BusinessSummary/BusinessSummary";
import NewBusinessSummary from "./BusinessSummary/NewBusinessSummary";
import ChatPage from "./ChatPage/ChatPage";
import CustomerDetails from "./CustomerDetails/CustomerDetails";
import PlaceReorder from "./CustomerDetails/PlaceReorder";
import RetailerCartPage from "./CustomerDetails/RetailerCartPage";
import HomePage from "./HomePage/HomePage";
import IncomingOrders from "./IncomingOrders/IncomingOrders";
import InventoryPage from "./InventoryPage/InventoryPage";
import Layout from "./layout/Layout";
import MarketingCampaign from "./MarketingCampaign/MarketingCampaign";
import MarketingCampaignSetup from "./MarketingCampaign/MarketingCampaignSetup";
import OrdersPage from "./OrdersPage/OrdersPage";

interface RetailerDashboardMobileProps {
  retailerData: RetailerDataType;
}

export default function RetailerDashboardMobile({
  retailerData,
}: RetailerDashboardMobileProps) {
  const { path } = useRouteMatch("/retailer/dashboard") || {};

  return (
    <Layout retailerData={retailerData}>
      <Route
        path={path}
        exact
        render={() => <Redirect to={"/retailer/dashboard/home"} />}
      />
      <Route
        path={"/retailer/dashboard/home"}
        render={() => <HomePage retailerData={retailerData} />}
      />
      <Route
        path={"/retailer/dashboard/incoming-orders"}
        render={() => <IncomingOrders retailerData={retailerData} />}
      />
      <Route
        path={"/retailer/dashboard/orders"}
        render={() => <OrdersPage retailerData={retailerData} />}
      />
      <Route
        path={"/retailer/dashboard/business-summary"}
        render={() => <NewBusinessSummary retailerData={retailerData} />}
      />
      <Route
        exact
        path={"/retailer/dashboard/customer-details"}
        render={() => <CustomerDetails retailerData={retailerData} />}
      />
      <Route
        exact
        path={
          "/retailer/dashboard/customer-details/:customerUserId/place-reorder"
        }
        render={() => <PlaceReorder retailerData={retailerData} />}
      />
      <Route
        exact
        path={
          "/retailer/dashboard/customer-details/:customerUserId/place-reorder/:cartId"
        }
        render={() => <RetailerCartPage retailerData={retailerData} />}
      />
      <Route
        path={"/retailer/dashboard/chat"}
        render={() => <ChatPage retailerData={retailerData} />}
      />
      <Route
        path={"/retailer/dashboard/inventory"}
        render={() => <InventoryPage retailerData={retailerData} />}
      />
      <Route
        exact
        path={"/retailer/dashboard/marketing-campaigns"}
        render={() => <MarketingCampaign retailerData={retailerData} />}
      />
      <Route
        path={"/retailer/dashboard/marketing-campaigns/new"}
        render={() => <MarketingCampaignSetup retailerData={retailerData} />}
      />
      <Route
        path={"/retailer/dashboard/promotions"}
        render={() => <PromotionsPage retailerData={retailerData} />}
      />
    </Layout>
  );
}
