import { CheckOutlined } from "@ant-design/icons";
import DocIcon from "assets/svgs/doc-icon.svg";
import React from "react";
import { createUseStyles } from "react-jss";
import DoubleCheckedIconSvg from "assets/svgs/double-checked-icon.svg";

type ChatMessageProps = {
  type: string;
  content: any;
  time: string;
  action: string;
  readStatus?: boolean;
  large?: boolean;
  message: any;
  onLoad?: () => void;
};

function ChatMessage({
  type,
  content,
  time,
  action,
  readStatus,
  large,
  message,
  onLoad,
}: ChatMessageProps) {
  const classes = useStyles({ large });

  let messageClass = "";
  if (action === "received") messageClass = classes.messageRecieved;
  else if (action === "sent") messageClass = classes.messageSent;

  const DoubleCheckIcon = () => (
    <img
      src={DoubleCheckedIconSvg}
      alt="diuble checked icon"
      style={{ width: 15, marginLeft: 5 }}
    />
  );

  function open(url: any) {
    const win = window.open(url, "_blank");
    if (win != null) {
      win.focus();
    }
  }

  const handleUrl = async () => {
    const tempUrl = await message.media.getContentTemporaryUrl();

    open(tempUrl);
  };

  return (
    <div className={messageClass} onLoad={onLoad}>
      <div className={classes.messageContent}>
        {type === "text" ? (
          content
        ) : (
          <>
            <img src={DocIcon} alt={content.docTitle} />
            <div
              className={classes.docText}
              style={{ color: "rgba(0,0,0,0.6)" }}
              onClick={handleUrl}
            >
              {content.docTitle}
            </div>
          </>
        )}
      </div>
      <div className={classes.messageDetails}>
        <span>{time}</span>
        {action === "sent" &&
          (!readStatus ? <CheckOutlined /> : <DoubleCheckIcon />)}
      </div>
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  messageSent: {
    width: "fit-content",
    maxWidth: ({ large }) => (large ? "80%" : "70%"),
    minWidth: "100px",
    marginLeft: "auto",
    marginBottom: "0.5rem",
    background: "#2A9D8F",
    color: colors.light100,
    borderRadius: "10px 10px 0px 10px",
  },
  messageRecieved: {
    width: "fit-content",
    maxWidth: ({ large }) => (large ? "80%" : "70%"),
    minWidth: "100px",
    marginRight: "auto",
    marginBottom: "0.5rem",
    background: "#F2F2F2",
    color: "#505050",
    borderRadius: "10px 10px 10px 0px",
  },

  messageContent: {
    padding: "0.5rem 1rem 0 1rem",
  },
  messageDetails: {
    width: "100%",
    height: "20px",
    paddingRight: "8px",
    fontSize: "0.65rem",
    opacity: "0.7",
    textAlign: "right",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",

    "& .anticon": {
      marginLeft: "5px",
    },

    "& span": {
      paddingRight: "0.15rem",
    },
  },
  docText: {
    maxWidth: "10rem",
    display: "block",
    fontSize: "0.8rem",
    paddingTop: "0.35rem",
    color: "inherit",
    transition: "0.2s",
    wordBreak: "break-all",
    hyphens: "auto",

    "&:hover": {
      color: "inherit",
      opacity: 0.5,
    },
  },
  "@media (min-width: 768px) and (max-width: 1050px)": {
    messageContent: {
      padding: "0.3rem 1rem 0 1rem",
      fontSize: 12,

      "& img": {
        width: "2.7rem",
        paddingTop: 5,
      },
    },
    docText: { fontSize: "0.72rem" },
    messageDetails: {
      height: 17,
      fontSize: "0.5rem",
    },
  },
}));

export default ChatMessage;
