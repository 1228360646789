import {
  EditOutlined,
  PlusOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import { Button, Card, Input, message, Space, Table, Typography } from "antd";
import { getProducts } from "api/api";
import { getCatalogue } from "api/retailerApi";
import Flex from "components/layouts/Flex";
import Loader from "components/loader/Loader";
import AddPriceModal from "components/modals/AddPriceModal";
import PricingRulesModal from "components/modals/PricingRulesModal";
import copy from "copy-text-to-clipboard";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { Link } from "react-router-dom";

type InventoryPageProps = {
  retailerData: {
    ID: number;
    Name: string;
  };
};

export default function InventoryPage({ retailerData }: InventoryPageProps) {
  const classes = useStyle();

  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState<any[]>([]);
  const [searchInput, setSearchInput] = useState("");
  const [endOfList, setEndOfList] = useState(false);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 20,
    totalPages: 0,
  });
  const [pricingRulesModalVisible, setPricingRulesModalVisible] =
    useState(false);
  const [addPriceModal, setAddPriceModal] = useState({
    visible: false,
    product: null,
  });

  const handlePricingRulesClick = () => {
    setPricingRulesModalVisible(true);
  };

  const handlePriceUpdate = (newPrice: number, productSku: string) => {
    setProducts((curr) => {
      let index = curr.findIndex((i) => i.product_sku === productSku);
      curr[index].strike_price = newPrice;

      return [...curr];
    });
  };

  const handleAddPriceClick = (row: any) => () => {
    setAddPriceModal({
      visible: true,
      product: row,
    });
  };

  const handleScroll = async (e: any) => {
    if (endOfList) {
      return;
    }
    let element = e.target;

    if (
      [element.clientHeight, element.clientHeight - 1].includes(
        element.scrollHeight - Math.round(element.scrollTop)
      )
    ) {
      setPagination((curr) => ({
        ...curr,
        currentPage: curr.currentPage + 1,
      }));
    }
  };

  const handleProductShare = (product: any) => () => {
    if (!!retailerData.ID) {
      let url = `https://${window.location.host}/dashboard/stores/${
        retailerData.ID
      }/product/${product?.product_sku ?? product.product_id}`;
      copy(url);

      message.success("URL copied to clipboard!");
      return;
    }
  };

  const handleSearch = (val: string) => setSearchInput(val);

  const handleShareSearchResults = () => {
    if (!searchInput.length) return message.info("Please search something...");

    if (!!retailerData.ID) {
      let url = `https://${window.location.host}/dashboard/stores/${retailerData.ID}/shop?catalog_key=2&search=${searchInput}`;
      copy(url);

      message.success("URL copied to clipboard!");
      return;
    }
  };

  const fetchProducts = async () => {
    let [count, ...products] = await getCatalogue(
      retailerData.ID,
      pagination.currentPage,
      searchInput
    );

    if (pagination.currentPage > 1) {
      setProducts((curr) => [...curr, ...products]);
    } else {
      setProducts(products);
    }

    setEndOfList(products.length < pagination.pageSize);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
  }, [retailerData.ID, searchInput, pagination.pageSize]);

  useEffect(() => {
    fetchProducts();
  }, [loading, pagination.currentPage]);

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        document
          .querySelector(".ant-table-body")
          ?.addEventListener("scroll", handleScroll);
      }, 2000);
    }

    return () => {
      document
        .querySelector(".ant-table-body")
        ?.removeEventListener("scroll", handleScroll);
    };
  }, [loading]);

  const productsTablecolums: any = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "160px",
    },
    // {
    //   title: "Category",
    //   dataIndex: "category_name",
    //   key: "category_name",
    //   width: "100px",
    // },
    // {
    //   title: "Manufacturer",
    //   dataIndex: "manufacturer",
    //   key: "manufacturer",
    //   width: "160px",
    //   render: (val: any) => val ?? " - ",
    // },
    // {
    //   title: "SKU",
    //   dataIndex: "product_sku",
    //   key: "product_sku",
    //   width: "100px",
    //   render: (val: any) => val ?? " - ",
    // },
    // {
    //   title: "Vendor",
    //   dataIndex: "vendor",
    //   key: "vendor",
    //   width: "65px",
    //   render: (val: any) => val ?? " - ",
    // },
    // {
    //   title: "Domain",
    //   dataIndex: "product_domain",
    //   key: "product_domain",
    //   width: "65px",
    //   render: (val: any) => val ?? " - ",
    // },
    {
      title: "Pack",
      dataIndex: "pack",
      key: "pack",
      width: "100px",
      render: (val: any) => val ?? " - ",
    },
    {
      title: "MRP",
      dataIndex: "pack_price",
      key: "pack_price",
      width: "100px",
      render: (val: any, row: any) => "₹"+(+row?.pack_price).toFixed(2),
    },
    {
      title: "Net Purchase Price",
      dataIndex: "net_purchase_price",
      key: "net_purchase_price",
      width: "100px",
      render: (val: any, row: any) => (!!val ? "₹"+(+val).toFixed(2) : " - "),
    },
    // {
    //   title: "Selling Price",
    //   dataIndex: "strike_price",
    //   key: "strike_price",
    //   width: "100px",
    //   render: (val: any, row: any) => (!!val ? (+val).toFixed(2) : " - "),
    // },
    {
      title: "Current Stock",
      dataIndex: "curr_inventory_size",
      key: "curr_inventory_size",
      width: "100px",
      render: (val: any, row: any) => (!!val ? (+val) : " - "),
    },
    // {
    //   title: "Share",
    //   dataIndex: "share",
    //   width: "80px",
    //   render: (val: any, row: any) => (
    //     <Button
    //       type="link"
    //       icon={<ShareAltOutlined />}
    //       onClick={handleProductShare(row)}
    //     />
    //   ),
    // },

    // {
    //   title: "Edit Price",
    //   dataIndex: "Edit Price",
    //   width: "80px",
    //   fixed: "right",
    //   render: (val: any, row: any) => (
    //     <Button
    //       type="link"
    //       style={{ padding: 0 }}
    //       onClick={handleAddPriceClick(row)}
    //       icon={<EditOutlined />}
    //     />
    //   ),
    // },
  ];

  return (
    <div className={classes.productsListWrapper}>
      <Space direction="vertical" className={classes.cardHeader}>
        <Flex justify="space-between" align="center">
          <Typography.Text className={classes.cardTitle}>
            Products
          </Typography.Text>

          <Button
            type="link"
            icon={<ShareAltOutlined />}
            hidden={searchInput === "" || !products.length}
            onClick={handleShareSearchResults}
          >
            Share
          </Button>

          {/* <Button shape="round" onClick={handlePricingRulesClick}>
            Pricing Rules
          </Button> */}
        </Flex>

        <div>
          <Input.Search
            allowClear
            placeholder="Search Products"
            onSearch={handleSearch}
            style={{ width: "100%" }}
          />
        </div>
      </Space>

      <PricingRulesModal
        retailerData={retailerData}
        visible={pricingRulesModalVisible}
        onCancel={() => setPricingRulesModalVisible(false)}
      />

      <AddPriceModal
        {...addPriceModal}
        retailerData={retailerData}
        onPriceUpdate={handlePriceUpdate}
        onCancel={() => setAddPriceModal({ visible: false, product: null })}
      />

      {!loading ? (
        <Table
          dataSource={products}
          columns={productsTablecolums}
          size={"small"}
          scroll={{ y: "calc(100vh - 270px)" }}
          pagination={false}
          // pagination={{
          //   total: pagination.totalPages,
          //   pageSize: pagination.pageSize,
          //   current: pagination.currentPage,
          //   onChange: handlePageChange,
          // }}
        />
      ) : (
        <Loader height="70vh" />
      )}
    </div>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  productsListWrapper: {
    padding: 15,
    "& .ant-space": {
      width: "100%",
    },
  },
  productsListCard: {
    flex: 1,
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: 5,
    boxShadow:
      "-1px -1px 4px rgb(34 94 119 / 25%), 1px 1px 4px rgb(34 94 119 / 25%)",
  },
  paginationWrapper: {
    padding: [20, 25, 10],
  },
  cardHeader: {
    paddingBottom: 15,
  },
  cardTitle: {
    margin: "0 !important",
    paddingRight: 20,
    fontWeight: 500,
    fontSize: 18,
  },
}));
