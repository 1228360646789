import useForm from "hooks/useForm";
import React from "react";
import { createUseStyles } from "react-jss";
import { FormProvider } from "react-hook-form";
import Field from "components/field/Field";
import { Button, Input } from "antd";
import customeScrollbarStyle from "components/style/CustomScrollbar";

function EditRetailerUserForm({ defaultValues, ...rest }: any) {
  const classes = useStyles();

  const { isSubmitting, methods, submitHandler } = useForm<any>({
    defaultValues,
    ...rest,
  });

  return (
    <FormProvider {...methods}>
      <form className={classes.formInputs} onSubmit={submitHandler}>
        {defaultValues?.id && <Field as={Input} name="id" type="hidden" />}
        <Field as={Input} name="fullName" type="text" label="Full Name" />
        <Field
          as={Input.Password}
          name="password"
          type="password"
          label="Change Password"
          placeholder="Enter new password ****"
        />

        <Button
          type="primary"
          htmlType="submit"
          block
          loading={isSubmitting}
          className={classes.submitButton}
        >
          Save
        </Button>
      </form>
    </FormProvider>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  formInputs: {
    paddingTop: "1.5rem",

    "& *": {
      fontSize: 12,
    },
    "& > div": {
      marginBottom: "1.5rem",
    },
    "& .ant-form-item": {
      marginBottom: "0.5rem",
    },

    // customizing the scrollbar
    ...customeScrollbarStyle,
  },
  submitButton: {
    marginBottom: "1rem",
    fontWeight: 500,
    color: colors.light100,
    background: colors.primary200,
  },
}));

export default EditRetailerUserForm;
