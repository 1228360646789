import React from "react";
import dayjs from "dayjs";
import { Typography } from "antd";
import { Row, Col, Table } from "antd/es";
import Text from "antd/es/typography/Text";
import { createUseStyles } from "react-jss";
import DatePicker from "components/DatePicker";
import type { Order, OrderItem } from "generated/schema";
import type { ColumnType } from "antd/lib/table/interface";
import WrapperHeightProvider from "components/WrapperHeightProvider";
import { ClockCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";

type NoAuthBillDetailsViewProps = {
  order?: Order;
};
const columns: ColumnType<OrderItem>[] = [
  {
    title: "#",
    dataIndex: "sno",
    align: "center",
    width: "40px",
    fixed: "left",
    render: (item: string, record: OrderItem, index) => index + 1,
  },
  {
    title: "Item",
    dataIndex: "item",
    width: "110px",
    fixed: "left",
    render: (item: string, record: OrderItem) => record.product.name,
  },
  {
    title: "Qty",
    dataIndex: "bill_quantity",
    width: "50px",
    align: "center",
  },
  {
    dataIndex: "unitprice",
    title: "MRP",
    width: "69px",
    align: "center",
    render: (total: number, record: OrderItem) =>
      `₹ ${record.product.pack_price}`,
  },
  {
    dataIndex: "total",
    title: "Amount",
    width: "70px",
    align: "right",
    render: (subTotal: number) => `₹ ${subTotal}`,
  },
];

function NoAuthBillDetailsView({ order }: NoAuthBillDetailsViewProps) {
  const classes = useStyles();
  if (!order) {
    return null;
  }

  const summaryRow = (name: string, value: number) => (
    <Table.Summary.Row className={classes.summaryRow} key={name}>
      <Table.Summary.Cell index={0} colSpan={4}>
        <Text strong>{name}</Text>
      </Table.Summary.Cell>
      <Table.Summary.Cell className={classes.summaryText} index={1} colSpan={1}>
        <Text strong>
          {name === "Discount" ? "- " : ""}
          ₹&nbsp;
          {value}
        </Text>
      </Table.Summary.Cell>
    </Table.Summary.Row>
  );

  const orderSummary = () => {
    const { discount, subTotal, total, round_off } = order;
    return [
      summaryRow("Subtotal", subTotal),
      summaryRow("Discount", discount),
      summaryRow("Round Off", round_off),
      summaryRow("Total", total),
    ];
  };
  const heightFix = (containerHeight: number) => {
    return Math.abs(containerHeight - 30);
  };

  return (
    <>
      <Row justify="start" align="middle" className={classes.filterSection}>
        <Col span={6} className={classes.orderNumber}>
          <InfoCircleOutlined />
          <span>{order.invoice_number}</span>
        </Col>
        <Col span={9} style={{ display: "flex", justifyContent: "flex-end" }}>
          <Typography className={classes.time}>
            <ClockCircleOutlined style={{ marginRight: ".4rem" }} />
            {dayjs(order.createdAt).format("hh:mm A")}
          </Typography>
        </Col>
        <Col span={9} style={{ display: "flex", justifyContent: "flex-end" }}>
          <DatePicker
            format="DD/MM/YYYY"
            value={dayjs(order.invoice_date)}
            open={false}
            allowClear={false}
            className={classes.dateBox}
            inputReadOnly
          />
        </Col>
      </Row>
      <WrapperHeightProvider>
        {(containerHeight) => (
          <div>
            <Table
              bordered
              className={classes.billTable}
              columns={columns}
              dataSource={order.orderItems}
              pagination={false}
              size="small"
              scroll={{ y: heightFix(containerHeight) }}
              summary={orderSummary}
            />
          </div>
        )}
      </WrapperHeightProvider>
    </>
  );
}

export default NoAuthBillDetailsView;

const useStyles = createUseStyles(({ colors }: Theme) => ({
  header: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    // Header svg needs
    zIndex: 2,

    "& .ant-typography": {
      color: `${colors.light100} !important`,
      marginBottom: "0 !important",
      lineHeight: "1.4 !important",
      letterSpacing: "0.5px",
    },
  },
  paragraph: {
    fontSize: "10px !Important",
  },
  summaryRow: {
    backgroundColor: colors.light200,

    "&:last-child": {
      "& *": {
        fontSize: "13px !important",
      },
    },
    "& td.ant-table-cell": {
      "&:first-child": {
        paddingLeft: "4rem !important",
      },
      "&:last-child": {
        paddingRight: "5% !important",
      },
    },
  },
  "@media (min-width:900px)": {
    summaryRow: {
      "& td.ant-table-cell": {
        "&:first-child": {
          paddingLeft: "2rem !important",
        },
      },
    },
  },
  summaryText: {
    textAlign: "right",
  },
  filterSection: {
    width: "100%",
    padding: ".5rem .8rem .3rem",
  },
  dateBox: {
    width: 100,
    padding: 5,
    borderRadius: 4,
    backgroundColor: colors.primary,
    color: colors.light100,
    boxShadow:
      "inset 1px 1px 4px rgb(0 0 0 / 25%), inset -1px -1px 4px rgb(0 0 0 / 25%)",

    "& .ant-picker-input": {
      flexDirection: "row-reverse",

      "& input": {
        textAlign: "center",
        color: "inherit",
        background: "inherit",
        fontSize: 11,
      },

      "& .ant-picker-suffix": {
        fontSize: "1rem",
        color: "inherit",
        background: "inherit",
      },
    },
  },
  time: {
    marginRight: "5px",
    color: colors.primary,
    fontSize: 12,
    lineHeight: "15px",
  },
  orderNumber: {
    height: "40px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",

    "& .anticon": {
      margin: "2.5px 5px",
      color: "#FCB415",
    },

    "& > span": {
      color: "#838383",
      fontSize: 11,
    },
  },
  billTable: {
    fontFamily: "Roboto",

    // customizing border
    "& .ant-table-container": {
      border: "none",
      "& *": {
        borderColor: "rgba(0, 0, 0, 0.25) !important",
        borderLeft: "none !important",
      },
    },

    "& thead th": {
      background: "#EAF5F4 !important",
      textTransform: "uppercase",
      padding: "8px 10px !important",
      fontSize: 9,
      fontWeight: 700,
      borderBottom: "none !important",
      "&:last-child": {
        paddingRight: "4% !important",
      },
    },

    "& .ant-table-row": {
      height: 40,
    },

    "& .ant-table-body": {
      paddingBottom: "4rem !important",
    },

    "& .ant-table-row td.ant-table-cell": {
      padding: "12px 10px !important",
      fontSize: 11,
      "&:last-child": {
        paddingRight: "5% !important",
      },
    },
    "& .ant-table-summary *": {
      borderRight: "none !important",
      background: "#EAF5F4",
      fontWeight: 500,
      fontSize: 11,
    },
    "& .ant-table-summary td": {
      padding: "1rem 0 !important",
    },
  },
}));
