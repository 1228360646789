import { Row, Col } from "antd/es/grid";
import Loader from "components/loader/Loader";
import { Channel } from "twilio-chat/lib/channel";
import React, { useState, useEffect } from "react";
import { useStyles } from "./CustomerDetails.style";
import { changeCustomerPriority } from "api/retailerApi";
import CustomersListCard from "../CustomerDetails/CustomerListCard";
import CustomerSummaryCard from "../CustomerDetails/CustomerSummaryCard";
import ChatCard from "components/pages/retailerDashboard/IncomingOrders/ChatCard";
import CustomerDetails from "components/pages/retailerDashboard/IncomingOrders/CustomerDetails";
import { fetchRetailerOrders } from "components/pages/retailerDashboard/APIs/fetchRetailerOrders";
import { fetchReorderCustomers } from "components/pages/retailerDashboard/APIs/fetchReorderCustomers";
import {
  getRetailStoreCustomers,
  getRetailerOrders,
  getCustomerPurchases,
} from "components/pages/retailerDashboard/APIs/getRetailStoreCustomers";
import ProductsCard from "./ProductsCard";

type CustomerDetailsPageProps = {
  client: any;
  retailerData: {
    ID: number;
    Name: string;
  };
};

interface customerType {
  customers: Array<any>;
  priorityCustomers: Array<any>;
  selectedCustomerIndex: number;
  loading: boolean;
}

function CustomerDetailsPage({
  client,
  retailerData,
}: CustomerDetailsPageProps) {
  const classes = useStyles();
  // -------------NEW STATES IN USE-----------------------------
  const [customersData, setCustomersData] = useState<customerType>({
    customers: [],
    priorityCustomers: [],
    selectedCustomerIndex: -1,
    loading: true,
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [endOfList, setEndOfList] = useState(false);
  const [placeReorder, setPlaceReorder] = useState(false);
  const [customerCategory, setCustomerCategory] = useState("All");
  const [channelName, setChannelName] = useState<Channel>();
  const [customerCount, setCustomerCount] = useState(0);
  // -----------------------------------------------------------

  const handleCustomerAdd = (customer: any) => [
    setCustomersData({
      ...customersData,
      customers: [customer, ...customersData.customers],
      selectedCustomerIndex: 0,
    }),
  ];

  const toggleCustomerPriorityCallback = (
    isPriorityNow: boolean,
    customer_id: any
  ) => {
    let newData: any = {};
    if (isPriorityNow) {
      newData.customers = customersData.customers.map((item: any) =>
        item.user_id === customer_id ? { ...item, priority: true } : item
      );
    } else {
      newData.customers = customersData.customers.map((item: any) =>
        item.user_id === customer_id ? { ...item, priority: false } : item
      );
    }
    newData.priorityCustomers = newData.customers.filter(
      (item: any) => item.priority
    );

    console.log(newData);
    setCustomersData({ ...customersData, ...newData });
  };

  const toggleCustomerPriority = async (customerUserId: any) => {
    if (customerUserId !== "")
      await changeCustomerPriority(customerUserId, retailerData?.ID).then(
        (result) => {
          toggleCustomerPriorityCallback(
            result.priority.includes(`${retailerData?.ID}`),
            result.user_id
          );
        }
      );
  };

  function format(date: any) {
    date = new Date(date);

    var day = ("0" + date.getDate()).slice(-2);
    var month = ("0" + (date.getMonth() + 1)).slice(-2);
    var year = date.getFullYear();

    return year + "-" + month + "-" + day;
  }

  const handleChannelChange = async () => {
    if (
      client !== undefined &&
      retailerData.ID > 0 &&
      customersData.customers.length > 0
    ) {
      var customerID =
        customersData.customers[customersData.selectedCustomerIndex]["user_id"];
      var customerName =
        customersData.customers[customersData.selectedCustomerIndex]["name"];

      // Populate the selected customer with items
      var item = await getCustomerPurchases(retailerData.ID, customerID);

      for (let i in item) {
        let t = format(item[i].date);
        item[i].date = t;
      }

      var temp = customersData.customers[customersData.selectedCustomerIndex];

      Object.assign(temp, { items: item });

      if (
        customerCategory !== "All" &&
        customersData.priorityCustomers.length > 0
      ) {
        customerID =
          customersData.priorityCustomers[customersData.selectedCustomerIndex][
            "user_id"
          ];
        customerName =
          customersData.priorityCustomers[customersData.selectedCustomerIndex][
            "name"
          ];
      }
      const channelUniqueName = `${retailerData.ID}_${customerID}`;
      const channelFriendlyName = `${retailerData.Name}_to_${customerName}`;

      if (channelUniqueName || channelFriendlyName) {
        joinOrCreateChannel(channelUniqueName, channelFriendlyName);
      }
    }
  };

  const joinChannel = async (channel: any) => {
    if (channel.channelState.status !== "joined") {
      await channel.join();
    }
  };

  async function joinOrCreateChannel(
    channelUniqueName: string,
    channelFriendlyName: string
  ) {
    try {
      const channel = await client.getChannelByUniqueName(channelUniqueName);
      joinChannel(channel);
      setChannelName(channel);
    } catch (err) {
      try {
        const channel = await client.createChannel({
          uniqueName: channelUniqueName,
          friendlyName: channelFriendlyName,
        });
        joinChannel(channel);
        setChannelName(channel);
      } catch {
        throw new Error("Unable to create channel, please reload this page");
      }
    }
  }

  function filterCustomersCategory(customers: any, category: string) {
    if (category === "All") {
      return customers;
    }
    var priorityCustomers = [];
    for (var i = 0; i < customers?.length; i++) {
      if (customers[i].priority) {
        priorityCustomers.push(customers[i]);
      }
    }
    return priorityCustomers;
  }

  async function fetchInitialData(search?: string) {
    var customers = await getRetailStoreCustomers(
      retailerData.ID,
      pageNumber,
      search ?? ""
    );
    setCustomerCount(customers.count.count);
    customers = customers.items;

    setEndOfList(customers.length === 0);
    if (customers.length === 0) {
      return setCustomersData({
        ...customersData,
        loading: false,
      });
    }

    for (let i in customers) {
      customers[i] = {
        ...customers[i],
        retail_store_id: retailerData.ID,
        key: customers[i].customer_id,
      };
    }

    var priorityCustomers = filterCustomersCategory(customers, "Priority");

    if (pageNumber === 1) {
      // Populate the first customer with items rest are being populated in handle change
      var item = await getCustomerPurchases(
        retailerData.ID,
        customers[0]?.user_id
      );

      for (let i in item) {
        let t = format(item[i].date);
        item[i].date = t;
      }

      customers[0].items = item;

      setCustomersData((customersData: customerType) => {
        return {
          customers: customers,
          priorityCustomers: priorityCustomers,
          selectedCustomerIndex: 0,
          loading: false,
        };
      });
    } else {
      setCustomersData((customersData: customerType) => {
        return {
          ...customersData,
          customers: [...customersData.customers, ...customers],
          selectedCustomerIndex: 0,
          loading: false,
        };
      });
    }
  }

  const handleCustomerSearch = (search: string) => {
    fetchInitialData(search);
  };

  useEffect(() => {
    fetchInitialData();
  }, [pageNumber]);

  useEffect(() => {
    if (retailerData.ID && customersData.loading === true) {
      fetchInitialData();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retailerData.ID]);

  useEffect(() => {
    handleChannelChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, customersData.selectedCustomerIndex]);

  useEffect(() => {
    if (customersData.loading === false) {
      setCustomersData({
        customers: customersData.customers,
        priorityCustomers: customersData.priorityCustomers,
        selectedCustomerIndex: 0,
        loading: false,
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerCategory]);

  return (
    <>
      {!customersData.loading && channelName ? (
        <>
          <div className={classes.customerDetailsPage}>
            <div className={classes.pageHeader}>
              <div className={classes.pageTitle}>
                {customerCategory === "Priority" ? (
                  <>
                    Priority Customers(
                    {customersData.priorityCustomers.length})
                  </>
                ) : (
                  <>All Customers({customerCount})</>
                )}
              </div>
            </div>
            <Row className={classes.cards}>
              <Col lg={6} xxl={4} className={classes.cardWrapper}>
                <CustomersListCard
                  onCustomerAdd={handleCustomerAdd}
                  retailerData={retailerData}
                  onSearch={handleCustomerSearch}
                  customers={customersData.customers}
                  customerCategory={customerCategory}
                  setCustomerCategory={setCustomerCategory}
                  priorityCustomers={customersData.priorityCustomers}
                  selectedCustomerIndex={customersData.selectedCustomerIndex}
                  setCustomersData={setCustomersData}
                  toggleCustomerPriority={toggleCustomerPriority}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                  endOfList={endOfList}
                />
              </Col>
              {!placeReorder && customersData.customers.length > 0 ? (
                <>
                  <Col lg={12} xxl={14} className={classes.cardWrapper}>
                    <CustomerSummaryCard
                      onPlaceReorder={() => setPlaceReorder(true)}
                      customer={
                        customerCategory === "All"
                          ? customersData.customers[
                              customersData.selectedCustomerIndex
                            ]
                          : customersData.priorityCustomers[
                              customersData.selectedCustomerIndex
                            ]
                      }
                    />
                  </Col>
                  <Col
                    lg={6}
                    className={`${classes.cardWrapper} ${classes.lastWrapper}`}
                  >
                    {(customerCategory === "All" &&
                      customersData.customers.length > 0) ||
                    (customerCategory === "Priority" &&
                      customersData.priorityCustomers.length > 0) ? (
                      <CustomerDetails
                        customerId={
                          customerCategory === "All"
                            ? customersData.customers.length > 0 &&
                              customersData.customers.length >
                                customersData.selectedCustomerIndex
                              ? customersData.customers[
                                  customersData.selectedCustomerIndex
                                ]["user_id"]
                              : 0
                            : customersData.priorityCustomers.length > 0 &&
                              customersData.priorityCustomers.length >
                                customersData.selectedCustomerIndex
                            ? customersData.priorityCustomers[
                                customersData.selectedCustomerIndex
                              ]["user_id"]
                            : 0
                        }
                        customerName={
                          customerCategory === "All"
                            ? customersData.customers.length > 0 &&
                              customersData.customers.length >
                                customersData.selectedCustomerIndex
                              ? customersData.customers[
                                  customersData.selectedCustomerIndex
                                ]["user_name"]
                              : ""
                            : customersData.priorityCustomers.length > 0 &&
                              customersData.priorityCustomers.length >
                                customersData.selectedCustomerIndex
                            ? customersData.priorityCustomers[
                                customersData.selectedCustomerIndex
                              ]["user_name"]
                            : ""
                        }
                        isPriority={
                          customerCategory === "All"
                            ? customersData.customers.length > 0 &&
                              customersData.customers.length >
                                customersData.selectedCustomerIndex
                              ? customersData.customers[
                                  customersData.selectedCustomerIndex
                                ]["priority"]
                              : false
                            : customersData.priorityCustomers.length > 0 &&
                              customersData.priorityCustomers.length >
                                customersData.selectedCustomerIndex
                            ? customersData.priorityCustomers[
                                customersData.selectedCustomerIndex
                              ]["priority"]
                            : false
                        }
                        retailStoreId={+retailerData.ID}
                        toggleCustomerPriority={toggleCustomerPriority}
                      />
                    ) : (
                      ""
                    )}
                    {channelName &&
                    ((customerCategory === "All" &&
                      customersData.customers.length > 0) ||
                      (customerCategory === "Priority" &&
                        customersData.priorityCustomers.length > 0)) ? (
                      <ChatCard
                        retailStoreId={+retailerData.ID}
                        chatName={
                          customerCategory === "All"
                            ? customersData.customers.length > 0 &&
                              customersData.customers.length >
                                customersData.selectedCustomerIndex
                              ? customersData.customers[
                                  customersData.selectedCustomerIndex
                                ]["user_name"]
                              : ""
                            : customersData.priorityCustomers.length > 0 &&
                              customersData.priorityCustomers.length >
                                customersData.selectedCustomerIndex
                            ? customersData.priorityCustomers[
                                customersData.selectedCustomerIndex
                              ]["user_name"]
                            : ""
                        }
                        channel={channelName}
                      />
                    ) : (
                      ""
                    )}
                  </Col>
                </>
              ) : (
                <ProductsCard
                  storeId={retailerData.ID}
                  onClose={() => setPlaceReorder(false)}
                  userId={
                    customersData.customers[
                      customersData.selectedCustomerIndex
                    ]["user_id"]
                  }
                />
              )}
            </Row>
          </div>
          <div
            hidden={customersData.customers.length > 0}
            className={classes.centreText}
          >
            {" "}
            No Customer Details
          </div>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default CustomerDetailsPage;
