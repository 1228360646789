// @ts-nocheck

import dayjs, { Dayjs } from 'dayjs'
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs'
import generatePicker from 'antd/es/date-picker/generatePicker'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import 'antd/es/date-picker/style/index'

dayjs.extend(weekday)
dayjs.extend(localeData)

const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig)

export default DatePicker
