import React, { useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import RaseetLogo from "../../assets/logos/retailer-with-name.png";
import LoginIllustration from "../../assets/svgs/raseet-login-illustration.svg";
import { createUseStyles } from "react-jss";
import { LockOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";
import InputField from "components/dataEntry/InputField";
import FormWrapper from "components/dataEntry/FormWrapper";

import Field from "components/field/Field";
import useForm from "hooks/useForm";
import User from "models/User";
import { CreateSessionInput } from "generated/schema";
import { Input } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { Link } from "react-router-dom";

function RetailerLoginForm({
  toggleLoginMethod,
  setMobileNumber,
  ...rest
}: any) {
  const classes = useStyles();
  const isMD = useBreakpoint().md;

  const { methods, submitHandler } = useForm<CreateSessionInput>({
    ...rest,
    resolver: User.validateLogin(),
  });

  useEffect(() => {
    if (!!methods.watch().mobileNumber) {
      setMobileNumber(methods.watch().mobileNumber);
    }
  }, [methods.watch().mobileNumber]);

  return (
    <div className={classes.loginForm}>
      <img className={classes.logo} src={RaseetLogo} alt="Raseet Logo" />
      <FormProvider {...methods}>
        <FormWrapper formTitle="Login To RASEET" onSubmit={submitHandler}>
          <Field
            as={Input}
            name="mobileNumber"
            type="number"
            placeholder={`${isMD ? "Enter " : ""}Mobile Number`}
            prefix={<PhoneOutlined rotate={90} />}
            className={classes.inputField}
          />

          <Field
            as={Input.Password}
            className={classes.inputField}
            name="password"
            type="password"
            placeholder={`${isMD ? "Enter Your " : ""}Password`}
            prefix={<LockOutlined />}
          />

          <button type="submit" className={classes.submitBtn}>
            Submit
          </button>

          <p className={classes.formActionText} onClick={toggleLoginMethod}>
            Login via OTP
          </p>
          <Link to="/forgot-password">
            <p className={classes.formActionText}>Forgot password?</p>
          </Link>
        </FormWrapper>
      </FormProvider>

      <img
        className={classes.illustration}
        src={LoginIllustration}
        alt="Raseet Illustration"
      />
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  loginForm: {
    minHeight: "100vh",
    padding: "2rem 2.5rem",
    backgroundColor: colors.light100,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    position: "relative",

    "@media (min-width: 1280px)": {
      padding: "2.5rem 5rem",
    },
  },
  logo: {
    width: "20rem",
    margin: "2rem auto",
  },
  illustration: {
    width: "20rem",
    left: "-18.5rem",
    bottom: "0",
    position: "absolute",
    zIndex: "10",

    "@media (min-width: 1280px)": {
      width: "23rem",
    },
  },
  resendOTP: {
    width: "fit-content",
    marginLeft: "auto",
    color: colors.primary200,
    fontWeight: 600,
    cursor: "pointer",
  },
  warning: {
    color: "red",
    fontWeight: 600,
    marginLeft: 15,
  },
  formText: {
    color: colors.dark400,
  },
  formActionText: {
    width: "100%",
    margin: 0,
    marginTop: 20,
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    textAlign: "right",
    color: "#225E77",
    cursor: "pointer",
  },
  submitBtn: {
    width: "100%",
    minHeight: "2.5rem",
    color: colors.light100,
    backgroundColor: colors.primary200,
    borderRadius: "0.6rem",
    lineHeight: "1.3rem",
    fontWeight: 600,
    border: "none",
    outline: "none",

    "&:hover": {
      backgroundColor: "#12485f",
    },
  },
  "@media (max-width: 768px)": {
    logo: {
      width: "14rem",
      margin: "4rem auto",
    },
  },
  inputField: {
    padding: [10, 15],
    "& .anticon": {
      fontSize: 16,
    },
    "& .ant-input-prefix": {
      paddingRight: 10,
    },
  },
}));

export default RetailerLoginForm;
