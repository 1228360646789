import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(({ colors }: Theme) => ({
  incomingOrdersPage: {
    height: "100%",
  },
  // rowCard: {
  //   height: "50%",
  // },
  pageHeader: {
    width: "25%",
    minHeight: "60px",
    padding: "0 0.8rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  pageTitle: {
    fontWeight: 600,
    fontSize: "1rem",
    color: "#505050",
  },
  itemCount: {
    fontSize: "0.8rem",
    color: "#E76F51",
    fontWeight: 600,
  },
  cards: {
    height: "100%",
    "& > div": {
      padding: "10px",
    },
  },
  cardWrapper: {
    height: "calc(100vh - 135px)",
    overflowY: "auto",
  },
  lastWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  centreText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    fontWeight: "bold",
    fontSize: "1.2rem",
  },
}));

export { useStyles };
