import Form from "antd/es/form";
import React from "react";
import Typography from "antd/es/typography";
import { Controller, get, useFormContext } from "react-hook-form";
import type { ControllerProps, Control } from "react-hook-form";

type Inputs =
  | React.ReactElement
  | React.ComponentType<any>
  | "input"
  | "select"
  | "textarea";

type FieldProps<TAs extends Inputs, TControl extends Control> = ControllerProps<
  TAs,
  TControl
> & {
  label?: string;
  hint?: string;
};

const labelStyle = {
  display: "block",
  fontWeight: 600,
  marginBottom: 5,
};

function Field<TAs extends Inputs, TControl extends Control>({
  label,
  hint,
  ...rest
}: FieldProps<TAs, TControl>) {
  const { errors, control } = useFormContext();

  const { name, type } = rest;
  const error = errors && get(errors, name);
  let errorProps = {};
  const controller = (
    <Controller
      type={type}
      control={control}
      {...(rest as ControllerProps<TAs, TControl>)}
    />
  );

  if (error) {
    errorProps = {
      validateStatus: "error",
      help: error?.message,
    };
  }

  if (type === "hidden") {
    return controller;
  }

  return (
    <div>
      <Typography.Text style={labelStyle}>{label}</Typography.Text>
      <Form.Item {...errorProps}>{controller}</Form.Item>
    </div>
  );
}

export default Field;
