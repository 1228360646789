import React from "react";

import RetailStoreForm from "components/forms/RetailStoreForm";
import { useCreateRetailStoreMutation } from "generated/schema";
import useSubmitHandler from "hooks/useSubmitHandler";
import CustomerAddressForm from "components/forms/CustomerAddressForm";
var uuid = require("uuid");

type CreateCustomerViewProps = {
  token?: string;
  user_id?: string;
  user_name?: string;
  mobile_number?: string;
  onComplete: () => void;
};
function CreateCustomerView({
  token,
  user_id,
  user_name,
  mobile_number,
  onComplete,
}: CreateCustomerViewProps) {
  const headers: Record<string, string> = {};
  const url = process.env.REACT_APP_API_NODE_BASE_URL;
  if (token) {
    headers["X-Token"] = token;
  }
  const [createRetailStore] = useCreateRetailStoreMutation({
    context: { headers },
    onCompleted: onComplete,
  });

  const handleCreateCustomer = async ({ ...values }) => {
    let data = {
      user_id: user_id,
      name: `'${user_name}'`,
      email: "''",
      phone_no: `'${mobile_number}'`,
    };
    await fetch(url + "/insertCustomer", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "x-access-token": `${token}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        let address = {
          line1: `'${values.streetAddress}'`,
          line2: `'${values.locality}'`,
          landmark: `'${values.locality}'`,
          pincode: `'${values.pincode}'`,
          city: `'${values.city}'`,
          state: `'${values.state}'`,
          addressable_type: `'Customer'`,
          addressable_id: json[0].id,
        };
        console.log(address);
        fetch(url + "/insertAddresses", {
          method: "POST",
          body: JSON.stringify(address),
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "x-access-token": `${token}`,
          },
        })
          .then((response) => response.json())
          .then((j) => {
            onComplete();
          });
      });
  };
  const handleBlankCustomer = async ({ ...values }) => {
    let data = {
      user_id: user_id,
      name: `'${user_name}'`,
      email: "''",
      phone_no: `'${mobile_number}'`,
    };
    await fetch(url + "/insertCustomer", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "x-access-token": `${token}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        let address = {
          line1: `' '`,
          line2: `' '`,
          landmark: `' '`,
          pincode: `' '`,
          city: `' '`,
          state: `' '`,
          addressable_type: `'Customer'`,
          addressable_id: json[0].id,
        };
        console.log(address);
        fetch(url + "/insertAddresses", {
          method: "POST",
          body: JSON.stringify(address),
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "x-access-token": `${token}`,
          },
        })
          .then((response) => response.json())
          .then((j) => {
            onComplete();
          });
      });
  };
  return (
    <CustomerAddressForm onSubmit={handleCreateCustomer} onSkip={handleBlankCustomer} />
  );
}

export default CreateCustomerView;
