import { message, Modal, ModalProps, Table, Typography } from "antd";
import { getOrdersByUserId } from "api/api";
import { reconcileMap } from "api/retailerApi";
import Loader from "components/loader/Loader";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import OrderItemsModal from "./OrderItemsModal";

interface MapOrderModalProps extends ModalProps {
  reorderId: number;
  customerUserId: number;
  storeId: number;
  mappedOrders: number[];
}

export default function MapOrderModal({
  reorderId,
  mappedOrders,
  customerUserId,
  storeId,
  ...props
}: MapOrderModalProps) {
  const classes = useStyle();
  const [loading, setLoading] = useState(true);
  const [pastPurchases, setPastPurchases] = useState<any[]>([]);
  //   const [reorders, setReorders] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState<any[]>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalOrderId, setModalOrderId] = useState("");
  const [modalInvoiceNumber, setModalInvoiceNumber] = useState("");

  const handleMapOrder = async () => {
    if (!selectedOrders.length)
      return message.error("Please select atleast 1 order to map.");

    console.log(selectedOrders);
    let dataToSend = {
      change_to: "verification pending",
      reorders: [reorderId],
      orders: selectedOrders.map((i) => +i.id),
    };

    await reconcileMap(dataToSend).then((res) => {
      if (res.mapped) {
        message.success("Mapped order successfully! Sent to verification.");
        // @ts-ignore
        props?.onCancel(null);
      }
    });
  };

  const handleMapOrderClick = () => {
    Modal.confirm({
      centered: true,
      title: `Map #${reorderId} with these orders?`,
      onOk: handleMapOrder,
    });
  };

  const handleRowSelectionChange = (
    selectedRowKeys: React.Key[],
    selectedRows: any[]
  ) => {
    setSelectedOrders(selectedRows);
  };

  const onOrderIdClick = (orderId: any, invoiceNumber: any) => {
    setModalOrderId(orderId);
    setModalInvoiceNumber(invoiceNumber);
    setModalVisible(true);
  };

  const fetchInitialData = async () => {
    await getOrdersByUserId(+customerUserId, +storeId).then((res: any[]) => {
      let ordersArray = res.map((obj, i) => ({ ...obj, key: i + 1 }));

      if (mappedOrders?.length) {
        console.log(mappedOrders);
        let selectedOrdersArr = ordersArray.filter((i) =>
          mappedOrders.includes(+i.id)
        );
        setSelectedOrders(selectedOrdersArr);
      }

      if (res.length) {
        setPastPurchases(ordersArray);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    setPastPurchases([]);
    setSelectedOrders([]);
  }, [reorderId]);

  useEffect(() => {
    if (loading && props.visible) {
      fetchInitialData();
    }
  }, [loading, props.visible]);

  const pastPurchasesTableColumns = [
    {
      title: "#",
      key: "key",
      dataIndex: "key",
      width: "45px",
    },
    {
      title: "Invoice No.",
      key: "invoice_number",
      dataIndex: "invoice_number",
      width: "100px",
      render: (val: string) => (val?.length ? val : " - "),
    },
    {
      title: "Date",
      key: "date",
      dataIndex: "date",
      width: "150px",
      render: (val: string) => moment(val).format("hh:mm MMM DD, YYYY"),
    },
    {
      title: "Items",
      dataIndex: "invoice_number",
      width: "140px",
      render: (invoice_number: any, record: any) => (
        <p
          style={{
            fontFamily: "Roboto",
            display: "contents",
            cursor: "pointer",
            fontWeight: 500,
          }}
          onClick={() => {
            return onOrderIdClick(record.id, invoice_number);
          }}
        >
          Show details
        </p>
      ),
    },
  ];

  return (
    <Modal {...props} title="Map Order" okText="Map" onOk={handleMapOrderClick}>
      <Typography.Text style={{ marginBottom: 20, display: "block" }}>
        Please select one or more orders to map with the Reorder.
      </Typography.Text>

      {!loading ? (
        <Table
          size="small"
          scroll={{ y: 400 }}
          pagination={false}
          dataSource={pastPurchases}
          columns={pastPurchasesTableColumns}
          rowSelection={{
            hideSelectAll: true,
            selections: selectedOrders,
            selectedRowKeys: selectedOrders.map((i) => i.key),
            onChange: handleRowSelectionChange,
          }}
        />
      ) : (
        <Loader height="50vh" />
      )}
      <OrderItemsModal
        orderId={modalOrderId}
        order={pastPurchases.find((i: any) => i?.id === modalOrderId)}
        invoiceNumber={modalInvoiceNumber}
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false);
          setModalOrderId("");
        }}
      />
    </Modal>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({}));
