import { Col, Layout, Row } from "antd";
import Loader from "components/loader/Loader";
import { useRouteMatch } from "react-router-dom";
import { getReorders, getOrders } from "api/api";
import React, { useState, useEffect } from "react";
import DrawerSidebar from "components/drawer/Drawer";
import useStyles from "./Styles/ActiveOrdersView.style";
import ActiveOrdersCard from "components/cards/ActiveOrdersCard";

type RouteParams = {
  retailStoreId: string;
};

function ActiveOrdersView() {
  // -------------NEW STATES IN USE-----------------------------
  const classes = useStyles();
  const {
    params: { retailStoreId },
  } = useRouteMatch<RouteParams>() || {};
  const [pageData, setPageData] = useState({
    orders: [],
    loading: true,
  });
  // -----------------------------------------------------------

  function getRetailers(orders: any) {
    var retailerIDList: any;
    retailerIDList = [];
    for (var i = 0; i < orders.length; i++) {
      if (!retailerIDList.includes(orders[i]["retail_store_id"])) {
        retailerIDList.push(orders[i]["retail_store_id"]);
      }
    }
    if (retailerIDList.length > 0) {
      return retailerIDList;
    }
    return [];
  }

  async function fetchDetailsHere() {
    var ordersList = await getReorders();
    ordersList.sort(function (a: any, b: any) {
      return +new Date(b.created_at) - +new Date(a.created_at);
    });

    setPageData({
      orders: ordersList,
      loading: false,
    });
  }

  function setOrders(orders: any) {
    setPageData({
      loading: false,
      orders: orders,
    });
  }

  function setLoader() {
    setPageData({
      loading: true,
      orders: pageData.orders,
    });
  }

  useEffect(() => {
    if (pageData.loading) {
      fetchDetailsHere();
    }
  });

  return (
    <>
      <Layout.Content>
        {!pageData.loading ? (
          <div className={classes.activeOrdersPage}>
            <Row className={classes.topAlignment}>
              <Col
                className={classes.center}
                style={{ marginLeft: "33px" }}
                span={3}
              >
                <DrawerSidebar />
              </Col>
              <Col className={classes.moreDetails} span={15}>
                Active Orders
              </Col>
            </Row>
            {pageData.orders?.length > 0 ? (
              <div className={classes.activeOrders}>
                {pageData.orders?.length &&
                  pageData.orders.map((order: any) => (
                    <ActiveOrdersCard
                      order={order}
                      setOrders={setOrders}
                      setLoader={setLoader}
                      orders={pageData.orders}
                    />
                  ))}
              </div>
            ) : (
              <div className={classes.centreText}>No Orders</div>
            )}
          </div>
        ) : (
          <Loader />
        )}
      </Layout.Content>
    </>
  );
}

export default ActiveOrdersView;
