import { Input, Button, message, Typography } from "antd";
import React, { useState } from "react";
import {
  changePassword,
  createOtp,
  updatePassword,
  validatePasswordOtp,
} from "api/api";
import useForm from "hooks/useForm";
import User from "models/User";
import { FormProvider } from "react-hook-form";
import Field from "./field/Field";
import { getSession, Session } from "client/reactives/session";
import gtag from "./Tracking/gtag";
import { useHistory } from "react-router";
import { createUseStyles } from "react-jss";
import hideNumberInputArrows from "./style/hideNumberInputArrows";

export const ChangePassword = () => {
  const { mobileNumber, userId } = getSession() as Session;

  const history = useHistory();

  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);

  // handle "Send OTP" click
  const handleOTPSend = async (values: any) => {
    let data = {
      mobile_number: mobileNumber,
      otp_type: `'Forgot Password'`,
    };

    await createOtp(data).then((res) => {
      if (res?.status === "success") {
        message.success(res.message);
        setOtpSent(true);
      } else if (res?.status === "failure") {
        message.error(res.error);
      }
    });
  };

  // Handle "Verify OTP" click
  const handleVerifyOTP = async ({ otp }: any) => {
    let data = {
      otp,
      mobile_number: mobileNumber,
      otp_type: `'Forgot Password'`,
    };

    await validatePasswordOtp(data).then((res) => {
      if (res?.status === "success") {
        setOtpVerified(true);
      } else {
        setOtpVerified(false);
        message.error(res?.error);
      }
    });
  };

  // handle "Change Password" click
  const handleChangePassword = async ({ password }: any) => {
    await updatePassword(userId, password).then(
      (res) => res?.status && handleChangePasswordComplete(res.status)
    );
  };

  // handle change password complete (event, redirect, message)
  const handleChangePasswordComplete = (type: "Success" | "Failure") => {
    if (type === "Success") {
      message.success("Password Changed Successfully!");
      gtag("event", "change_password");
      history.push("/");
    } else if (type === "Failure") {
      message.error("Something went wrong.!");
    }
  };

  return (
    <>
      {!otpSent && <SendOTP onSend={handleOTPSend} />}
      {otpSent && !otpVerified && <VerifyOTP onSubmit={handleVerifyOTP} />}
      {otpVerified && <ChangePasswordForm onSubmit={handleChangePassword} />}
    </>
  );
};

// Divided components
// -----
const SendOTP = ({ onSend }: any) => (
  <>
    <Typography.Paragraph>
      An OTP would be sent to your registered mobile number. You can change
      password after verification.
    </Typography.Paragraph>
    <Button type="primary" onClick={onSend}>
      Send OTP
    </Button>
  </>
);

const VerifyOTP = ({ ...rest }: any) => {
  const classes = useStyles();
  const { isSubmitting, methods, submitHandler } = useForm<any>({
    ...rest,
    resolver: User.validateOTP(),
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={submitHandler} className={classes.veriyOTP}>
        <Field as={Input} name="otp" type="number" placeholder="OTP" />
        <Button type="primary" htmlType="submit" loading={isSubmitting} block>
          Verify OTP
        </Button>
      </form>
    </FormProvider>
  );
};

const ChangePasswordForm = ({ ...rest }: any) => {
  const { isSubmitting, methods, submitHandler } = useForm<any>({
    ...rest,
    resolver: User.validateChangePassword(),
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={submitHandler}>
        <Field as={Input.Password} name="password" placeholder="New Password" />

        <Field
          as={Input.Password}
          name="confirmPassword"
          placeholder="Confirm New Password"
        />

        <Button type="primary" htmlType="submit" loading={isSubmitting} block>
          Change Password
        </Button>
      </form>
    </FormProvider>
  );
};

const useStyles = createUseStyles({
  veriyOTP: {
    ...hideNumberInputArrows,
  },
});
