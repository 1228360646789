import React from 'react'
import { createUseStyles } from 'react-jss'

type ContainerProps = React.PropsWithChildren<{}>

const useStyles = createUseStyles(({ colors }: Theme) => ({
  container: {
    background: colors.light100,
    height: '100vh'
  }
}))

function Container({ children }: ContainerProps) {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      {children}
    </div>
  )
}

export default Container
