import moment from "moment";
import { Col, Row } from "antd/es";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";
import Loader from "components/loader/Loader";
import React, { useEffect, useState } from "react";
import { getUserAllPrescriptions } from "api/retailerApi";
import PreviewImageModal from "components/modals/PreviewImageModal";
import {
  ArrowLeftOutlined,
  DownloadOutlined,
  ExpandAltOutlined,
} from "@ant-design/icons";
import { Button } from "antd";
import { getSession, Session } from "client/reactives/session";

function PrescriptionsAndReports() {
  const classes = useStyles();
  const { userId } = getSession() as Session;
  const [pageData, setPageData] = useState({
    prescriptionImages: [],
    isModalOpen: false,
    currentPresIndex: 0,
    loading: true,
  });
  const history = useHistory();

  const openModal = (index: number) => {
    setPageData({
      ...pageData,
      isModalOpen: true,
      currentPresIndex: index,
    });
  };

  const closeModal = () => {
    setPageData({
      ...pageData,
      isModalOpen: false,
    });
  };

  async function getAllPrescriptions() {
    let presArray: any = [];
    var result = await getUserAllPrescriptions();
    var urlsArray = result.linkArray;
    for (let i = 0; i < urlsArray.length; i++) {
      presArray.push({
        pres_id: urlsArray[i].objectId,
        name: urlsArray[i].filename,
        image: urlsArray[i].value,
        date: urlsArray[i].date,
      });
    }
    setPageData({
      ...pageData,
      prescriptionImages: presArray,
      loading: false,
    });
  }

  useEffect(() => {
    if (pageData.loading === true) {
      getAllPrescriptions();
    }
  }, []);

  return (
    <div className={classes.prescriptionsAndReports}>
      <Row className={classes.header}>
        <Col className={classes.backButton} span={4}>
          <ArrowLeftOutlined onClick={() => history.goBack()} />
        </Col>
        <Col className={classes.pageHeader} span={20}>
          Prescriptions & Reports
        </Col>
      </Row>
      {!pageData.loading ? (
        <div className={classes.prescriptionsContainer}>
          {pageData.prescriptionImages.length ? (
            <>
              {pageData.prescriptionImages.map((pres: any, index: number) => (
                <div
                  className={classes.prescriptionCard}
                  onClick={() => openModal(index)}
                >
                  <div className={classes.imageWrapper}>
                    <img
                      src={pres.image}
                      width="100"
                      className={classes.prescriptionImg}
                    />
                  </div>
                  <div className={classes.prescriptionDetails}>
                    <div className={classes.prescriptionName}>{pres.name}</div>
                    <div className={classes.prescriptionDate}>
                      {moment(pres.date).format("Do MMM, YYYY")}
                    </div>
                    <Button
                      type="link"
                      href={pres.image}
                      target="_blank"
                      id="prescription-download-btn"
                      data-source="Prescriptions & Reports Page"
                      data-user={userId}
                      icon={<DownloadOutlined />}
                      className={classes.downloadButton}
                      onClick={(e) => e.stopPropagation()}
                    />
                  </div>
                </div>
              ))}
              <PreviewImageModal
                images={pageData.prescriptionImages.map((x: any) => x.image)}
                visible={pageData.isModalOpen}
                onCancel={closeModal}
                current={pageData.currentPresIndex}
              />
            </>
          ) : (
            <div className={classes.noPrescriptions}>
              No Prescriptions Found...
            </div>
          )}
        </div>
      ) : (
        <Loader height="calc(95vh - 58px)" />
      )}
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  prescriptionsAndReports: {
    height: "100vh",
    background: "rgba(217, 217, 217, 0.2)",
  },
  backButton: {
    textAlign: "center",
  },
  pageHeader: {
    fontSize: 13,
    fontWeight: 700,
  },
  header: {
    height: 58,
    position: "sticky",
    top: "0",
    background: "transparent",
    alignItems: "center",
  },
  prescriptionsContainer: {
    padding: 20,
    height: "calc(100vh - 58px)",
    overflowY: "auto",
  },
  prescriptionCard: {
    width: "100%",
    height: 75,
    marginBottom: 13,
    borderRadius: 5,
    overflow: "hidden",
    position: "relative",
    background: "#fff",
    boxShadow: "1px 1px 3px rgb(0 0 0 / 25%)",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  imageWrapper: {
    width: 90,
    padding: [7, 10],
    background: "rgba(0,0,0,0.08)",
    textAlign: "center",
  },
  prescriptionImg: {
    width: "100%",
    height: "40px",
    objectFit: "contain",
  },
  prescriptionDetails: {
    flex: 1,
    padding: [0, 12],
    whiteSpace: "nowrap",
  },
  prescriptionName: {
    fontSize: 11,
    fontWeight: 500,
    color: "#505050",
  },
  prescriptionDate: {
    marginTop: 4,
    fontSize: 9,
    color: "#979797",
  },
  noPrescriptions: {
    height: "calc(100vh - 58px)",
    width: "100%",
    color: "#505050",
    fontWeight: 600,
    fontSize: 12,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  downloadButton: {
    position: "absolute",
    bottom: 10,
    right: 10,
  },
}));

export default PrescriptionsAndReports;
