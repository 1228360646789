import React, { useState, useEffect, useMemo } from "react";
import { createUseStyles } from "react-jss";
import Loader from "components/loader/Loader";
import {
  Button,
  Col,
  Input,
  message,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
} from "antd";
import { getRetailStoreCustomers } from "../APIs/getRetailStoreCustomers";
import customeScrollbarStyle from "components/style/CustomScrollbar";
import {
  createMarketingCampaign,
  createSuggestedMarketingCampaign,
  getFilteredAilments,
  getMarketingTargetAudience,
} from "api/retailerApi";
import Flex from "components/layouts/Flex";
import SetupMarketingCampaignForm from "components/forms/SetupMarketingCampaignForm";
import { useHistory, useLocation } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import moment from "moment";

const truncate = (input: string, length: number) => {
  if (input.length > length) {
    return input.substring(0, length) + "...";
  }
  return input;
};

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

type MarketingCampaignSetupPageProps = {
  retailerData: {
    ID: number;
    Name: string;
  };
};

const filterOptions = {
  days_from_last_order: [
    { key: "7 days", value: "7" },
    { key: "14 days", value: "14" },
    { key: "21 days", value: "21" },
    { key: "30 days", value: "30" },
    { key: "90 days", value: "90" },
  ],
  lifetime_order_value: [
    { key: "₹0 - ₹500", value: "0-500" },
    { key: "₹500 - ₹1000", value: "500-1000" },
    { key: "₹1000 - ₹5000", value: "1000-5000" },
    { key: "₹5000 - ₹10,000", value: "5000-10000" },
    { key: "₹10,000 - ₹50,000", value: "10000-50000" },
    { key: "₹50,000 - ₹1,00,000", value: "50000-100000" },
  ],
  avg_order_value: [
    { key: "₹0 - ₹500", value: "0-500" },
    { key: "₹500 - ₹1000", value: "500-1000" },
    { key: "₹1000 - ₹5000", value: "1000-5000" },
    { key: "₹5000 - ₹10,000", value: "5000-10000" },
    { key: "₹10,000 - ₹50,000", value: "10000-50000" },
    { key: "₹50,000 - ₹1,00,000", value: "50000-100000" },
  ],
  lifetime_order_count: [
    { key: "1 - 5", value: "1-5" },
    { key: "5 - 10", value: "5-10" },
    { key: "10 - 20", value: "10-20" },
    { key: "20 - 50", value: "20-50" },
    { key: "50-100", value: "50-100" },
  ],
};

const customerTableColumns = [
  {
    title: "#",
    key: "id",
    width: "40px",
    render: (val: any, row: any, index: number) => index + 1,
  },
  {
    title: "Name",
    key: "user_name",
    width: "120px",
    dataIndex: "user_name",
  },
  {
    title: "Phone No.",
    key: "user_number",
    width: "120px",
    dataIndex: "user_number",
  },
  {
    title: "Lifetime Order Value",
    key: "lifetime_order_value",
    dataIndex: "lifetime_order_value",
    width: "150px",
    render: (val: any) => "₹ " + val,
  },
  {
    title: "Lifetime Order Count",
    key: "lifetime_order_count",
    dataIndex: "lifetime_order_count",
    width: "150px",
    render: (val: any) => val,
  },
  {
    title: "Days From Last Order",
    key: "days_from_last_order",
    dataIndex: "days_from_last_order",
    width: "150px",
    render: (val: any) => val + " days ago",
  },
  {
    title: "Disease Group",
    key: "ailment_class",
    dataIndex: "ailment_class",
    width: "150px",
    render: (val: any) =>
      !!val ? <Tooltip title={val}>{truncate(val, 15)}</Tooltip> : " - ",
  },
  {
    title: "Ailment Type",
    key: "ailment_type",
    dataIndex: "ailment_type",
    width: "150px",
    render: (val: any) =>
      !!val ? <Tooltip title={val}>{truncate(val, 15)}</Tooltip> : " - ",
  },
];

export default function MarketingCampaignSetupPage({
  retailerData,
}: MarketingCampaignSetupPageProps) {
  const classes = useStyles();
  const query = useQuery();
  const history = useHistory();
  const [pageSize] = useState(20);
  const [isSuggestion, setIsSuggestion] = useState(false);
  const [loading, setLoading] = useState(true);
  const [endOfList, setEndOfList] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [medium, setMedium] = useState(null);
  const [customerList, setCustomerList] = useState<any[]>([]);
  const [customerCount, setCustomerCount] = useState(0);
  const [filters, setFilters] = useState<any[]>([]);
  const [ailmentClasses, setAilmentClasses] = useState<any[]>([]);
  const [showMoreFilters, setShowMoreFilters] = useState(false);

  const handleCampaignSubmit = async ({ campaign_time, ...values }: any) => {
    let dataToSend = {
      ...values,
      retail_store_ids: [+retailerData.ID],
      campaign_time: campaign_time.format(),
      customer_filter: filters,
    };

    await createMarketingCampaign(dataToSend).then((res) => {
      if (res.data?.length) {
        message.success(res.message);
        return history.goBack();
      }

      message.error("Something went wrong!");
      console.log(res);
    });
  };

  const handleSuggestionCampaignSubmit = async ({
    campaign_time,
    ...values
  }: any) => {
    let dataToSend = {
      ...values,
      campaign_id: query.get("campaign_id"),
      retail_store_ids: [+retailerData.ID],
      campaign_time: campaign_time.format(),
      customer_filter: filters,
    };

    await createSuggestedMarketingCampaign(dataToSend).then((res) => {
      if (res.message === "Campaign created successfully.") {
        message.success(res.message);
        return history.replace("/retailer/dashboard/marketing-campaigns");
      }

      message.error("Something went wrong!");
      console.log(res);
    });
  };

  const handleScroll = async (e: any) => {
    if (endOfList) {
      return;
    }
    let element = e.target;
    console.log(
      element.scrollHeight - Math.round(element.scrollTop),
      element.clientHeight
    );
    if (
      element.scrollHeight -
        Math.round(element.scrollTop) -
        element.clientHeight >
        -2 &&
      element.scrollHeight -
        Math.round(element.scrollTop) -
        element.clientHeight <
        2
    ) {
      setCurrentPage((curr) => curr + 1);
    }
  };

  const handleFilterInputChange = (key: string) => (e: any) => {
    setFilters((curr) => {
      if (!e.target?.value?.length) return curr.filter((i) => i.type !== key);

      let index = curr.findIndex((i) => i.type === key);
      let filterData = {
        type: key,
        max_value: +e.target.value,
        min_value: 0,
      };

      if (index !== -1) {
        curr[index] = filterData;
      } else {
        curr.push(filterData);
      }

      return [...curr];
    });
    setCurrentPage(1);
    setEndOfList(false);
  };

  const handleFilterChange = (key: string) => (value: any) => {
    setCurrentPage(1);
    setEndOfList(false);

    let newFilters: any[] = [];
    if (key === "ailment_class") {
      return setFilters((curr) => {
        let index = curr.findIndex((i) => i.type === key);
        if (index !== -1) {
          curr[index] = {
            ...curr[index],
            values: [...curr[index].values, value],
          };
        } else {
          curr.push({
            type: "ailment_class",
            values: [value],
          });
        }

        return [...curr];
      });
    } else {
      if (Array.isArray(value)) {
        newFilters = value.map((item) => ({
          type: key,
          max_value: +item.split("-")?.[1],
          min_value: +item.split("-")?.[0],
        }));
      } else {
        console.log(value);
        if (value?.includes("-")) {
          newFilters.push({
            type: key,
            max_value: +value.split("-")?.[1],
            min_value: +value.split("-")?.[0],
          });
        } else {
          newFilters.push({
            type: key,
            max_value: +value,
            min_value: 0,
          });
        }
      }
    }

    setFilters((curr) => [...curr, ...newFilters]);
  };

  const handleFilterClear = (key: string) => () => {
    setFilters((curr) => curr.filter((i) => i.type !== key));
    setCurrentPage(1);
    setEndOfList(false);
  };

  const handleFilterDeselect = (key: string) => (value: any) => {
    setCurrentPage(1);
    setEndOfList(false);

    if (key === "ailment_class") {
      return setFilters((curr) => {
        let index = curr.findIndex((i) => i.type === key);
        curr[index] = {
          ...curr[index],
          values: curr[index].values.filter((i: any) => i !== value),
        };
        return [...curr];
      });
    }

    if (value?.includes("-")) {
      let max_value = +value.split("-")?.[1];
      let min_value = +value.split("-")?.[0];

      setFilters((curr) => {
        return curr.filter(
          (i) => i.max_value !== max_value && i.min_value !== min_value
        );
      });
    } else {
      setFilters((curr) => {
        return curr.filter((i) => i.max_value !== +value);
      });
    }
  };

  const fetchInitialData = async () => {
    if (endOfList) return;

    const customersRes = await getMarketingTargetAudience(
      retailerData.ID,
      {
        customer_filter: filters,
      },
      pageSize,
      currentPage
    );

    if (Array.isArray(customersRes?.data?.customers)) {
      if (customersRes?.data?.customers?.length < pageSize) {
        setEndOfList(true);
      }

      if (currentPage > 1) {
        setCustomerList((curr) => [...curr, ...customersRes.data?.customers]);
      } else {
        setCustomerList(customersRes.data?.customers);
      }

      setCustomerCount(+customersRes?.data?.customer_count);
    }

    setLoading(false);
  };

  const fetchAilments = async () => {
    const ailmentsRes = await getFilteredAilments(
      retailerData.ID,
      "ailment_class"
    );
    if (!!ailmentsRes.data?.ailment_class) {
      setAilmentClasses(ailmentsRes.data.ailment_class);
    }
  };

  useEffect(() => {
    fetchAilments();

    if (query.get("campaign_type") === "Suggestion") {
      setIsSuggestion(true);
      handleFilterChange("ailment_class")(query.get("disease_group"));
    }
  }, []);

  useEffect(() => {
    fetchInitialData();
  }, [filters, currentPage]);

  useEffect(() => {
    setTimeout(() => {
      document
        .querySelector(".ant-table-body")
        ?.addEventListener("scroll", handleScroll);
    }, 2000);

    return () => {
      document
        .querySelector(".ant-table-body")
        ?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <Row gutter={[20, 20]} className={classes.marketingCampaignsPage}>
      <Col lg={24}>
        <Space style={{ width: "100%" }} className={classes.pageHeader}>
          <Button
            size="large"
            icon={<ArrowLeftOutlined />}
            onClick={history.goBack}
          />
          <Typography.Text className={classes.pageTitle}>
            Create New Marketing Campaign
          </Typography.Text>
        </Space>
      </Col>
      <Col lg={16}>
        <Space size="large" direction="vertical" className={classes.pageLeft}>
          <Space direction="vertical" className={classes.filtersWrapper}>
            <Flex justify="space-between">
              <Typography.Text>Days From Last Order :</Typography.Text>
              <Input
                disabled={isSuggestion}
                style={{ width: "100%" }}
                onChange={handleFilterInputChange("days_from_last_order")}
                placeholder="Please type"
              />
            </Flex>
            <Flex justify="space-between">
              <Typography.Text>Lifetime Order Value :</Typography.Text>
              <Select
                disabled={isSuggestion}
                allowClear
                mode="multiple"
                style={{ width: "100%" }}
                placeholder="Please select"
                onSelect={handleFilterChange("lifetime_order_value")}
                onDeselect={handleFilterDeselect("lifetime_order_value")}
                onClear={handleFilterClear("lifetime_order_value")}
              >
                {filterOptions.lifetime_order_value.map((item, index) => (
                  <Select.Option
                    key={item.value}
                    value={item.value}
                    style={{ width: "100%" }}
                  >
                    {item.key}
                  </Select.Option>
                ))}
              </Select>
            </Flex>

            <Flex justify="space-between">
              <Typography.Text>Disease Group :</Typography.Text>
              <Select
                disabled={isSuggestion}
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                defaultValue={query.get("disease_group") ?? undefined}
                placeholder="Please select"
                onSelect={handleFilterChange("ailment_class")}
                onDeselect={handleFilterDeselect("ailment_class")}
                onClear={handleFilterClear("ailment_class")}
              >
                {ailmentClasses.map((item, index) => (
                  <Select.Option
                    key={index}
                    value={item}
                    style={{ width: "100%" }}
                  >
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Flex>

            {showMoreFilters ? (
              <>
                <Flex justify="space-between">
                  <Typography.Text>Average Order Value :</Typography.Text>
                  <Select
                    disabled={isSuggestion}
                    allowClear
                    mode="multiple"
                    style={{ width: "100%" }}
                    placeholder="Please select"
                    onSelect={handleFilterChange("avg_order_value")}
                    onDeselect={handleFilterDeselect("avg_order_value")}
                    onClear={handleFilterClear("avg_order_value")}
                  >
                    {filterOptions.avg_order_value.map((item, index) => (
                      <Select.Option
                        key={item.value}
                        value={item.value}
                        style={{ width: "100%" }}
                      >
                        {item.key}
                      </Select.Option>
                    ))}
                  </Select>
                </Flex>
                <Flex justify="space-between">
                  <Typography.Text>Lifetime Order Count :</Typography.Text>
                  <Select
                    disabled={isSuggestion}
                    allowClear
                    mode="multiple"
                    style={{ width: "100%" }}
                    placeholder="Please select"
                    onSelect={handleFilterChange("lifetime_order_count")}
                    onDeselect={handleFilterDeselect("lifetime_order_count")}
                    onClear={handleFilterClear("lifetime_order_count")}
                  >
                    {filterOptions.lifetime_order_count.map((item, index) => (
                      <Select.Option
                        key={item.value}
                        value={item.value}
                        style={{ width: "100%" }}
                      >
                        {item.key}
                      </Select.Option>
                    ))}
                  </Select>
                </Flex>

                <Flex justify="flex-end">
                  <Button
                    type="link"
                    onClick={() => setShowMoreFilters(false)}
                    className={classes.showFilterBtn}
                  >
                    Show less filters
                  </Button>
                </Flex>
              </>
            ) : (
              <Flex justify="flex-end">
                <Button
                  type="link"
                  onClick={() => setShowMoreFilters(true)}
                  className={classes.showFilterBtn}
                >
                  Show more filters
                </Button>
              </Flex>
            )}
          </Space>

          <div className={classes.customerListWrapper}>
            <Typography.Title level={5}>
              Customers List ({customerCount})
            </Typography.Title>
            <Table
              pagination={false}
              columns={customerTableColumns}
              dataSource={customerList}
              className={classes.customersListTable}
              scroll={{ x: true, y: "300px" }}
            />
          </div>
        </Space>
      </Col>

      <Col lg={8}>
        <Space size="large" direction="vertical" className={classes.pageLeft}>
          <Space direction="vertical" className={classes.calcWrapper}>
            <SetupMarketingCampaignForm
              onSubmit={
                isSuggestion
                  ? handleSuggestionCampaignSubmit
                  : handleCampaignSubmit
              }
              onMediumChange={setMedium}
              defaultValues={{
                campaign_name: "",
                campaign_type: query.get("campaign_type") ?? "general",
                campaign_medium: "Whatsapp",
                campaign_time: moment(),
              }}
            />

            {!!medium && (
              <>
                <div className={classes.calcItem}>
                  <Typography.Text>Total Customers :</Typography.Text>
                  <Typography.Text>{customerCount}</Typography.Text>
                </div>

                <div className={classes.calcItem}>
                  <Typography.Text>Cost Per Customer :</Typography.Text>
                  <Typography.Text>
                    ₹{medium === "Sms" ? "0.5" : "1.0"}
                  </Typography.Text>
                </div>

                <div className={classes.calcItem}>
                  <Typography.Text strong>Campaign Cost :</Typography.Text>
                  <Typography.Text strong>
                    ₹
                    {medium === "Sms" ? 0.5 * customerCount : customerCount * 1}
                  </Typography.Text>
                </div>
              </>
            )}
          </Space>
        </Space>
      </Col>
    </Row>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  marketingCampaignsPage: {
    padding: "2rem",
  },
  pageLeft: {
    width: "100%",
  },
  pageHeader: {
    "& .ant-space-item:last-child": {
      flex: 1,
    },
  },
  pageTitle: {
    height: 40,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    backgroundColor: colors.primary200,
    color: colors.light100,
    fontSize: 14,
    fontWeight: 500,
    borderRadius: 5,
  },
  filtersWrapper: {
    width: "100%",
    padding: 20,
    borderRadius: 5,
    backgroundColor: "rgba(34, 94, 119, 0.1)",

    fontSize: 12,

    "& .ant-select, & .ant-input": {
      width: "300px !important",
      fontSize: 12,
    },

    "& .ant-space": {
      width: "100%",
      "& .ant-space-item:last-child": {
        flex: 1,
      },
    },
  },
  customerListWrapper: {
    width: "100%",
    padding: 20,
    borderRadius: 5,
    backgroundColor: "rgba(34, 94, 119, 0.1)",

    "& *": {
      fontSize: 12,
    },
  },

  customersListTable: {
    width: "100%",

    "& *": {
      fontSize: 12,
    },

    // auto resizing table bug fix
    "& .ant-spin-nested-loading": {
      overflow: "hidden",
    },

    // customizing
    "& .ant-table": {
      width: "100%",
      background: "transparent",
      overflow: "hidden",
      borderRadius: 5,
    },

    "& .ant-table-header": {
      overflow: "hidden",
      borderRadius: 5,
      borderBottomRightRadius: 15,
    },

    "& thead": {
      background: colors.light100,
      "& .anticon *": {
        fontSize: "10px !important",
      },
      "& th": {
        height: 30,
        paddingTop: "0 !important",
        paddingBottom: "0 !important",
        color: "#838383",
      },
    },

    "& tbody .ant-table-row td": {
      paddingTop: "8px !important",
      paddingBottom: "8px !important",
      color: "#000",
    },

    // customizing horizontal scrollbar for antBody
    "& .ant-table-body": {
      ...customeScrollbarStyle,
    },

    "& .ant-table-row:hover": {
      "& td": {
        background: "transparent !important",
        "&:first-child": {
          borderBottomLeftRadius: 5,
          borderTopLeftRadius: 5,
        },
        "&:last-child": {
          borderTopRightRadius: 5,
          borderBottomRightRadius: 5,
        },
      },
    },
  },
  calcWrapper: {
    width: "100%",
    padding: [20],
    borderRadius: 5,
    backgroundColor: "rgba(34, 94, 119, 0.1)",
    fontSize: 14,
  },
  calcItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  footerButtons: {
    width: "100%",
    marginTop: 10,

    "& > .ant-space-item": {
      flex: 1,
    },
  },
  showFilterBtn: {
    fontSize: 12,
    padding: 0,
  },
}));
