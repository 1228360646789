import React, { useState } from "react";
import RadioSwitch from "components/radioSwitch/RadioSwitch";
import {
  PlusCircleOutlined,
  SearchOutlined,
  StarFilled,
} from "@ant-design/icons";
import { useStyles } from "./CustomerListcard.style";
import Loader from "components/loader/Loader";
import { EmptyStarIcon } from "components/customIcons";
import { Button, Empty, Input } from "antd";
import AddCustomerModal from "components/modals/AddCustomerModal";

type CustomerListCardProps = {
  customers: Array<Object>;
  priorityCustomers: Array<Object>;
  selectedCustomerIndex: number;
  setCustomersData: any;
  customerCategory: string;
  toggleCustomerPriority: any;
  pageNumber: number;
  setPageNumber: any;
  endOfList: any;
  onSearch: (search: string) => void;
  onCustomerAdd: (customer: any) => void;
  setCustomerCategory: (category: string) => void;
  retailerData: {
    ID: number;
    Name: string;
  };
};

type CustomerItemListProps = {
  customer: any;
  index: number;
};

const switchButtonOptions = [
  { label: "All", value: "All" },
  { label: "Priority", value: "Priority" },
];

function CustomerListCard({
  customers,
  retailerData,
  priorityCustomers,
  selectedCustomerIndex,
  setCustomersData,
  customerCategory,
  toggleCustomerPriority,
  pageNumber,
  setPageNumber,
  endOfList,
  onSearch,
  onCustomerAdd,
  setCustomerCategory,
}: CustomerListCardProps) {
  const classes = useStyles();
  const [addCustomerModalVisible, setAddCustomerModalVisible] = useState(false);

  const toggleAddCustomerModal = () =>
    setAddCustomerModalVisible(!addCustomerModalVisible);

  const handleAddCustomerFormSave = (customer: any) => {
    toggleAddCustomerModal();
    onCustomerAdd(customer);
  };

  const changeCustomerCategory = (e: any) => {
    setCustomerCategory(e.target.value);
  };

  const changeSelectedCustomer = (index: number) => {
    if (selectedCustomerIndex !== index) {
      setCustomersData({
        customers: customers,
        priorityCustomers: priorityCustomers,
        selectedCustomerIndex: index,
        loading: false,
      });
    }
  };

  const handleScroll = async (e: any) => {
    //console.log("test")
    if (endOfList) {
      return;
    }
    //check if reached bottom
    let element = e.target;
    //console.log(element.scrollHeight, element.scrollTop, element.clientHeight);
    if (
      element.scrollHeight - Math.round(element.scrollTop) ===
      element.clientHeight
    ) {
      setPageNumber((pageNumber: any) => pageNumber + 1);
      //console.log("eof");
    }
  };

  function CustomerListItem({ customer, index }: CustomerItemListProps) {
    const customerItemStyles =
      index === selectedCustomerIndex
        ? `${classes.customerItem} ${classes.selectedItem}`
        : classes.customerItem;

    return (
      <div
        key={customer.id}
        className={customerItemStyles}
        onClick={() => changeSelectedCustomer(index)}
      >
        <div className={classes.CustomerItemLeft}>
          {customer.priority === true ? (
            <StarFilled
              onClick={async () =>
                await toggleCustomerPriority(customer.user_id)
              }
            />
          ) : (
            <EmptyStarIcon
              onClick={async () =>
                await toggleCustomerPriority(customer.user_id)
              }
            />
          )}
          <div>
            <div className={classes.customerID}>{customer.customer_id}</div>
            <div className={classes.customerName}>
              {customer.name ?? customer.phone_no}
            </div>
          </div>
        </div>
        <div className={classes.customerTotal}>
          ₹ {parseFloat(customer.sum).toFixed(2)}
        </div>
      </div>
    );
  }

  return (
    <div className={classes.itemsListCard}>
      <div className={classes.listSettings}>
        <RadioSwitch
          size={"small"}
          options={switchButtonOptions}
          value={customerCategory}
          onChange={changeCustomerCategory}
        />

        <Button
          type="link"
          icon={<PlusCircleOutlined />}
          onClick={toggleAddCustomerModal}
        >
          ADD
        </Button>
        <AddCustomerModal
          retailStoreId={retailerData.ID}
          visible={addCustomerModalVisible}
          onClose={toggleAddCustomerModal}
          onSave={handleAddCustomerFormSave}
        />
      </div>
      <div className={classes.searchWrapper}>
        <Input
          size="large"
          placeholder="Search..."
          className={classes.searchInput}
          onChange={(e) => onSearch(e.target.value)}
          suffix={<SearchOutlined />}
        />
      </div>

      <div
        className={classes.itemsList}
        onScroll={(e) => {
          handleScroll(e);
        }}
      >
        {customers.length || priorityCustomers?.length ? (
          customerCategory === "All" ? (
            customers.map((customer, index) => (
              <CustomerListItem key={index} customer={customer} index={index} />
            ))
          ) : priorityCustomers?.length > 0 ? (
            priorityCustomers.map((priorityCustomer, index) => (
              <CustomerListItem
                key={index}
                customer={priorityCustomer}
                index={index}
              />
            ))
          ) : (
            <div className={classes.centreText}>No Priority Customers</div>
          )
        ) : (
          <div className={classes.emptyWrapper}>
            <Empty />
          </div>
        )}
      </div>
    </div>
  );
}

export default CustomerListCard;
