import Dragger from "antd/es/upload/Dragger";
import Modal from "antd/es/modal";
import React from "react";
import { InboxOutlined } from "@ant-design/icons";
import type { ModalProps } from "antd/es/modal";
import type { UploadChangeParam, UploadFile } from "antd/es/upload/interface";

type UploadImageModal = ModalProps & {
  onSubmit: (file: any) => Promise<string>;
};

function UploadImageModal({ onSubmit, onCancel, ...rest }: UploadImageModal) {
  const onUploadChange = (info: UploadChangeParam<UploadFile<any>>) => {
    const { file } = info;

    if (file?.status !== "removed") {
      // @ts-ignore
      onCancel();
      onSubmit(file);
    } else {
      // @ts-ignore
      onCancel();
      onSubmit(undefined);
    }
  };
  return (
    <Modal
      {...rest}
      onCancel={onCancel}
      closable
      title="Upload Logo"
      footer={null}
    >
      <Dragger
        name="invoice-uploader"
        multiple={false}
        onChange={onUploadChange}
        action={onSubmit}
        beforeUpload={() => false}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag image to this area to upload
        </p>
      </Dragger>
    </Modal>
  );
}

export default UploadImageModal;
