import React, { useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";

type WrapperHeightProviderProps = {
  children: (height: number) => React.ReactNode;
};

const useStyles = createUseStyles({
  wrapper: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    height: "100%",
  },
});

function WrapperHeightProvider({ children }: WrapperHeightProviderProps) {
  const classes = useStyles();
  const [containerHeight, setContainerHeight] = useState<number>(0);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (wrapperRef.current && containerHeight === 0) {
      // var ele = wrapperRef.current.childNodes[0].childNodes[0];
      // console.log("wrapperRef.current.clientHeight: ", ele);
      setContainerHeight(wrapperRef.current.clientHeight);
    }
    // console.log("containerHeight: ", containerHeight);
  }, [containerHeight]);

  return (
    <div className={classes.wrapper} ref={wrapperRef}>
      {children(containerHeight)}
    </div>
  );
}

export default WrapperHeightProvider;
