import React, { useState } from "react";
import FormStepper from "components/FormStepper";
import RetailerLoginForm from "components/forms/RetailerLoginForm";
import { message } from "antd";
import { setSession } from "client/reactives/session";
import gtag from "components/Tracking/gtag";
import RetailerOtpLoginForm from "components/forms/RetailerOtpLoginForm";
import { generateLoginOtp, loginUser, OtpLogin } from "api/api";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import localforage from "localforage";

const LoginStepTitle = {
  1: "Digital E-commerceBills",
  2: "E-commerce",
  4: "Loyalty Programs",
};

function RetailerLoginPage() {
  const [loginViaOtp, setLoginViaOtp] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const isMd = useBreakpoint().md;

  const handleOtpLogin = async ({ ...values }) => {
    try {
      const res = await OtpLogin({
        mobile_number: values.mobileNumber,
        otp: values.otp,
        otp_type: "'Login'",
      });

      if (res.error) {
        message.error(res.error);
      } else {
        gtag("event", "login_via_otp", {
          type: "retailer",
        });
        localforage.setItem("userType", "Retailer");
      }
    } catch (e: any) {
      message.error(e);
    }
  };

  const handlePasswordLogin = async ({ ...values }) => {
    let data = {
      mobile_number: `${values.mobileNumber}`,
      user_type: "Retailer",
      password: `${values.password}`,
    };

    await loginUser(data).then((res) => {
      if (res.status) {
        gtag("event", "login_via_password", {
          type: "retailer",
        });
        localforage.setItem("userType", "Retailer");
      } else {
        message.info(res.error);
      }
    });
  };

  const steps = Object.values(LoginStepTitle);

  const toggleLoginMethod = () => setLoginViaOtp(!loginViaOtp);

  if (!isMd) {
    return loginViaOtp ? (
      <RetailerOtpLoginForm
        toggleLoginMethod={toggleLoginMethod}
        onSubmit={handleOtpLogin}
        setMobileNumber={setMobileNumber}
        defaultValues={{ mobileNumber, otp: "" }}
      />
    ) : (
      <RetailerLoginForm
        toggleLoginMethod={toggleLoginMethod}
        onSubmit={handlePasswordLogin}
        setMobileNumber={setMobileNumber}
        defaultValues={{ mobileNumber, password: "" }}
      />
    );
  }

  return (
    <FormStepper
      steps={steps}
      isRetailer={true}
      subTitle="Better!"
      title="Connect with your customers,"
    >
      {loginViaOtp ? (
        <RetailerOtpLoginForm
          toggleLoginMethod={toggleLoginMethod}
          onSubmit={handleOtpLogin}
          setMobileNumber={setMobileNumber}
          defaultValues={{ mobileNumber, otp: "" }}
        />
      ) : (
        <RetailerLoginForm
          toggleLoginMethod={toggleLoginMethod}
          onSubmit={handlePasswordLogin}
          setMobileNumber={setMobileNumber}
          defaultValues={{ mobileNumber, password: "" }}
        />
      )}
    </FormStepper>
  );
}

export default RetailerLoginPage;
