import React from "react";
import { Button, Divider } from "antd";
import Loader from "components/loader/Loader";
import { useStyles } from "./CustomerSummaryCard.style";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import LoyaltyPointsCard from "components/cards/LoyaltyPointsCard";
import ProgressBarListCard from "components/cards/ProgressBarListCard";
import PersonalisedOffersCard from "components/cards/PersonalisedOffersCard";
import CustomerSummaryTableCard from "components/cards/CustomerSummaryTableCard";

type CustomerSummaryCardProps = {
  customer: any;
  onPlaceReorder: () => void;
};

const frequentlyBoughtItemList = [
  // label : item name
  // count : sold items count,
  // value : percentage
  {
    label: "Disprin",
    count: 100,
    value: 50,
  },
  {
    label: "Horlics",
    count: 43,
    value: 45,
  },
  {
    label: "Strepcils",
    count: 34,
    value: 35,
  },
  {
    label: "Disprin",
    count: 100,
    value: 50,
  },
  {
    label: "Horlicks",
    count: 43,
    value: 40,
  },
];

function CustomerSummaryCard({
  customer,
  onPlaceReorder,
}: CustomerSummaryCardProps) {
  const classes = useStyles();
  const screens = useBreakpoint();
  const isXl = screens.xl;

  return (
    <>
      {customer ? (
        <div className={classes.orderSummaryCard}>
          <div className={classes.orderSummaryCardInner}>
            <div className={classes.orderDetails}>
              <div className={classes.orderID}>{customer.name}</div>
              <Button shape="round" type="primary" onClick={onPlaceReorder}>
                Place Reorder
              </Button>
            </div>
            {customer.items && customer.items.length > 0 ? (
              <div>
                <CustomerSummaryTableCard
                  items={customer.items}
                  total={customer.sum}
                  size={isXl ? "middle" : "small"}
                />
              </div>
            ) : (
              "No Items"
            )}
            {/* hiding below elements for now, remove hidden attr to make visible */}
            <div hidden>
              <Divider className={classes.seperator} />
              {/* <PersonalisedOffersCard items={[]} /> */}
              {/* <Divider className={classes.seperator} />
              <LoyaltyPointsCard customer={customer} /> */}
              {/* <Divider className={classes.seperator} /> */}
              <ProgressBarListCard
                cardTitle="Frequently Bought Items"
                list={frequentlyBoughtItemList}
              />
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default CustomerSummaryCard;
