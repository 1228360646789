import React from 'react'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  formFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
  },
})

function OnboardingFormFooter({ children }: React.PropsWithChildren<{}>) {
  const classes = useStyles()

  return (
    <div className={classes.formFooter}>
      {children}
    </div>
  )
}

export default OnboardingFormFooter
