import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import type { RouteProps } from "react-router-dom";
import InternalContext from "contexts/InternalContext";
import { User } from "generated/schema";
import CustomerDashboard from "components/pages/customerDashboard";
//import RetailerDashboard from 'components/pages/oldRetailerDashboard'
import RetailerDashboard from "components/pages/retailerDashboard";

import { getSession, Session } from "client/reactives/session";

enum SessionState {
  LOGGED_IN = "logged-in",
  LOGGED_OUT = "logged-out",
}

enum RestrictedSection {
  RETAILER = "Retailer",
  CUSTOMER = "Customer",
  ADMIN = "admin",
}

enum SectionPathPrefix {
  RETAILER = "/retailer",
  CUSTOMER = "/dashboard",
  ADMIN = "/admin",
}

enum UserType {
  USER = "user",
  ADMIN = "admin",
}

type ProtectedRouteProps = RouteProps & {
  layout: React.JSXElementConstructor<any>;
  requiredSessionState: SessionState;
  requiredRestrictedSection?: RestrictedSection;
  requiredUserType?: UserType;
};

function canAccessSection(
  roles: User["roles"] = [],
  section?: RestrictedSection
) {
  // if a route has no required section then the route can be accessed by users
  if (!section) {
    return true;
  }

  return roles.includes(section);
}

function redirectToRootPath(userType: string | undefined) {
  switch (userType) {
    case RestrictedSection.RETAILER:
      return <RetailerDashboard />;
    case RestrictedSection.CUSTOMER:
      return <CustomerDashboard />;
    default:
      return <Redirect to="/" />;
  }
}

function determineRootRedirection(userType: string | undefined) {
  //const [ role ] = roles

  return redirectToRootPath(userType);
}

function ProtectedRoute({
  component,
  layout: Layout,
  render,
  requiredRestrictedSection,
  requiredSessionState,
  requiredUserType,
}: ProtectedRouteProps) {
  // const { currentUser, currentAdminUser } = useContext(InternalContext)
  // const { roles } = currentUser || {}
  const { token, userType } = getSession() as Session;
  let currentUser = false;

  if (token != null) {
    currentUser = true;
  }
  const Component = (component || render) as React.JSXElementConstructor<any>;

  if (requiredSessionState === SessionState.LOGGED_IN) {
    if (requiredUserType === UserType.USER && !currentUser) {
      // if (window.location.pathname.includes("/shop")) {
      //   return (
      //     <Redirect
      //       to={`/login?redirect_url=${
      //         window.location.pathname + window.location.search
      //       }`}
      //     />
      //   );
      // }

      let redirectUrl = window.location.pathname;
      let urlSearch =
        window.location.search !== "" ? `${window.location.search}&` : "?";

      let toPath = `/login${urlSearch}redirect_url=${redirectUrl}`;

      return <Redirect to={toPath} />;
    }

    // if (requiredUserType === UserType.ADMIN && !currentAdminUser) {
    // return <Redirect to="/admin/login" />
    // }

    if (
      currentUser &&
      requiredRestrictedSection
      //&& !canAccessSection(roles, requiredRestrictedSection)
    ) {
      return redirectToRootPath(userType?.toString());
    }
  }
  if (requiredSessionState === SessionState.LOGGED_OUT && currentUser) {
    return determineRootRedirection(userType?.toString());
  }

  // if (requiredSessionState === SessionState.LOGGED_OUT && currentAdminUser) {
  // return <Redirect to="/admin" />
  // }

  return (
    <Layout>
      <Component />
    </Layout>
  );
}

export { RestrictedSection, SessionState, UserType };

export default ProtectedRoute;
