import { Button, Empty } from "antd";
import Loader from "components/loader/Loader";
import ChatDate from "components/chat/ChatDate";
import ChatInput from "components/chat/ChatInput";
import { Channel } from "twilio-chat/lib/channel";
import { LeftOutlined } from "@ant-design/icons";
import FullScreenModal from "components/modals/FullScreenModal";
import ChatMessage from "components/chat/ChatMessage";
import React, { useState, useRef, useEffect } from "react";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import {
  retailerMessagesArray,
  retailerUpperArray,
} from "components/chat/chatfunctions";
import { createUseStyles } from "react-jss";
import customeScrollbarStyle from "components/style/CustomScrollbar";

type RetailerChatModalProps = {
  retailStoreId: any;
  chatName: string;
  channel: Channel | undefined;
  modalOpen: boolean;
  onBack: () => void;
};

export default function RetailerChatModal({
  retailStoreId,
  chatName,
  channel,
  onBack,
  modalOpen,
}: RetailerChatModalProps) {
  // -------------NEW STATES IN USE-----------------------------
  const [chatData, setChatData] = useState({
    messages: [{}] as any,
    loading: true,
    intervalSet: false,
  });
  const [msgInputValue, setMsgInputValue] = useState("");
  const messageContainerBottomRef = useRef<HTMLDivElement>(null);
  const setIntervalKey = useRef<any>(0);
  const autoScroll = useRef<any>(1);
  const classes = useStyles();
  // -----------------------------------------------------------
  const screens = useBreakpoint();

  async function getMessages() {
    if (!!channel) {
      const newMessages = await channel.getMessages();
      const datedMessages = newMessages.items.map((item: any) => {
        return item.dateCreated.toDateString();
      });

      var UpperArray = [];
      for (var i = 0; i < datedMessages.length; i++) {
        if (i === 0) {
          var upperArrayElement = retailerUpperArray(
            newMessages.items[i],
            retailStoreId,
            datedMessages[i]
          );
          UpperArray.push(upperArrayElement);
        } else if (
          datedMessages[i] === UpperArray[UpperArray.length - 1].date
        ) {
          UpperArray[UpperArray.length - 1].messagesArray.push(
            retailerMessagesArray(newMessages.items[i], retailStoreId)
          );
        } else {
          UpperArray.push(
            retailerUpperArray(
              newMessages.items[i],
              retailStoreId,
              datedMessages[i]
            )
          );
        }
      }

      setChatData({
        messages: UpperArray,
        loading: false,
        intervalSet: chatData.intervalSet,
      });
      if (autoScroll.current) {
        scrollToBottom();
      }
    }
  }

  const handleMessageSend = async (channel: any) => {
    if (msgInputValue) {
      try {
        await channel.sendMessage(String(msgInputValue).trim());
      } catch (err) {
        console.log("Failed to send the message, error: ", err);
        alert("Something went wrong, please reload !");
      }
      setMsgInputValue("");
    }
  };

  const handleGallery = async (target: any) => {
    const file = target.files;
    const formData = new FormData();
    formData.append("file", file[0]);
    if (!!channel) {
      await channel.sendMessage(formData);
      getMessages();
    }
  };

  // function to scroll the messages container to bottom
  const scrollToBottom = () => {
    if (messageContainerBottomRef.current) {
      messageContainerBottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleMsgInputChange = (e: any) => setMsgInputValue(e.target.value);

  const handleScroll = async (e: any) => {
    let element = e.target;
    autoScroll.current = 0;

    if (element.scrollTop === 0 && !!chatData.messages && !!channel) {
      var initialHeight2 = element.scrollHeight;
      let newMsgArray = [...chatData.messages];
      let lastObject = newMsgArray[0];
      if (!!lastObject) {
        if (lastObject.messagesArray[0].message.index !== 0) {
          var messageIndex = lastObject.messagesArray[0].message.index - 1;

          clearInterval(setIntervalKey.current);
          setIntervalKey.current = 0;

          var oldMessages = await channel.getMessages(
            30,
            messageIndex,
            "backwards"
          );
          oldMessages.items.reverse();
          const datedMessages = oldMessages.items.map((item: any) =>
            item.dateCreated.toDateString()
          );

          for (let i = 0; i < datedMessages.length; i++) {
            if (datedMessages[i] === newMsgArray[0].date) {
              newMsgArray[0].messagesArray.unshift(
                await retailerMessagesArray(oldMessages.items[i], retailStoreId)
              );
            } else {
              newMsgArray.unshift(
                await retailerUpperArray(
                  oldMessages.items[i],
                  retailStoreId,
                  datedMessages[i]
                )
              );
            }
          }

          setChatData({
            messages: newMsgArray,
            loading: false,
            intervalSet: chatData.intervalSet,
          });
        }
      }

      var finalHeight2 = element.scrollHeight;
      element.scrollTop = finalHeight2 - initialHeight2;
    } else if (element.scrollHeight - element.scrollTop <= 250) {
      autoScroll.current = 1;
      setIntervalForMsgs();
    }
  };

  function setIntervalForMsgs() {
    //this needs to be improved or need to switch to new third-party provider
    if (setIntervalKey.current > 0) {
      // console.log("I'm sending back the request");
      return;
    }
    var intervalKey = setInterval(getMessages, 2000);
    setIntervalKey.current = intervalKey;
    if (!chatData.intervalSet) {
      setChatData({
        loading: chatData.loading,
        intervalSet: true,
        messages: chatData.messages,
      });
    }
  }

  useEffect(() => {
    if (msgInputValue === "") {
      getMessages();
      setIntervalForMsgs();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, msgInputValue]);

  return (
    <FullScreenModal modalOpen={modalOpen}>
      <div className={classes.chatCard} onScroll={(e) => handleScroll(e)}>
        <div className={classes.chatCardWrapper}>
          <div className={classes.chatHeader}>
            <Button
              type="text"
              size="large"
              icon={<LeftOutlined />}
              onClick={onBack}
            />
            <div className={classes.chatTitle}>{chatName}</div>
          </div>
          <div className={classes.messagesContainer}>
            <>
              {!chatData.loading ? (
                <>
                  {chatData.messages.length > 0 ? (
                    <div className={classes.messagesList}>
                      {chatData.messages.map((chatItem: any, index: any) => (
                        <div key={index}>
                          {Object.entries(chatItem).map(
                            (obj: any, index: number) => {
                              if (obj.length > 0 && obj[0] === "date") {
                                return <ChatDate key={index} date={obj[1]} />;
                              }
                              if (
                                obj.length > 0 &&
                                obj[0] === "messagesArray"
                              ) {
                                var messages = obj[1];
                                if (messages.length > 0) {
                                  return messages.map(
                                    (message: any, index: number) => (
                                      <ChatMessage
                                        key={index}
                                        {...message}
                                        large
                                      />
                                    )
                                  );
                                }
                              }
                              return <></>;
                            }
                          )}
                        </div>
                      ))}
                      <div ref={messageContainerBottomRef}></div>
                    </div>
                  ) : (
                    <div className={classes.emptyContainer}>
                      <Empty description={<b>Start Chatting.!</b>} />
                    </div>
                  )}
                </>
              ) : (
                <Loader />
              )}
            </>
          </div>
          <div className={classes.chatInputContainer}>
            <ChatInput
              large
              onMessageSend={() => {
                handleMessageSend(channel);
              }}
              onAttachmentChange={(e: any) => handleGallery(e.target)}
              value={msgInputValue}
              onChange={handleMsgInputChange}
              placeholder="Type Something..."
            />
          </div>
        </div>
      </div>
    </FullScreenModal>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  chatCard: {
    width: "100%",
    height: "100%",
    backgroundColor: colors.light100,
    boxShadow:
      "-1px -1px 4px rgba(34, 94, 119, 0.25), 1px 1px 4px rgba(34, 94, 119, 0.25)",
  },
  chatCardWrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  chatHeader: {
    height: 70,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "0 1rem",
  },
  expandIcon: {
    marginRight: "1rem",
    fontSize: "1.1rem",
    background: "rgba(220, 220, 220, 0.5)",
    color: colors.primary200,
    border: "none",
  },
  chatTitle: {
    color: "#225E77",
    fontSize: 18,
    marginLeft: 10,
    fontWeight: "700",
    fontFamily: "Roboto",
  },
  messagesContainer: {
    flex: "10px",
    minHeight: "22vh",
    padding: "0.4rem",
    marginBottom: "5px",
    boxShadow: "inset 0px 5px 15px  -10px #CCC,inset 0px -5px 15px -10px #CCC",
    display: "flex",
    flexDirection: "column",

    // customizing scrollbar
    ...customeScrollbarStyle,
  },
  messagesList: {
    flex: 1,
    overflowY: "auto",
  },
  emptyContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px",
    color: "#225E77",
  },
  chatInputContainer: {
    padding: "0.3rem 1rem",
  },
}));
