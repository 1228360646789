/* eslint-disable react/no-unused-prop-types */
import React from 'react'
import Layout from 'antd/es/layout'
import Menu from 'antd/es/menu'
import MenuItem from 'antd/es/menu/MenuItem'
import { createUseStyles } from 'react-jss'
import { Link, useLocation } from 'react-router-dom'

type NavLink = {
  to?: string,
  title: string,
  icon?: React.ReactNode,
  subNavLinks?: NavLink[]
}

type SidebarProps = {
  collapsed: boolean,
  navLinks: NavLink[]
}

const CONTENT_SPACING = 16

const useStyles = createUseStyles({
  logo: {
    background: 'rgba(255, 255, 255, 0.2)',
    height: 32,
    margin: CONTENT_SPACING
  }
})

function Sidebar({ collapsed, navLinks }: SidebarProps) {
  const { pathname } = useLocation()
  const classes = useStyles()

  const navLinkList = navLinks.reduce((acc: NavLink[], navLink) => {
    if (navLink?.subNavLinks) {
      return [ ...acc, ...navLink?.subNavLinks ]
    }

    acc.push(navLink)

    return acc
  }, [])

  const activeNavLink = navLinkList.find((navLink) => navLink?.to && pathname.match(navLink.to))!

  const renderMenuItem = ({ to, icon, title }: NavLink) => (
    <MenuItem key={to} icon={icon}>
      <Link to={to!}>{title}</Link>
    </MenuItem>
  )

  return (
    <Layout.Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
    >
      <Menu theme="dark" mode="inline" selectedKeys={[ activeNavLink?.to! ]}>
        <div className={classes.logo} />
        {navLinks.map((navLink) => {
          const { title, icon, subNavLinks } = navLink

          if (subNavLinks) {
            return (
              <Menu.SubMenu key={title} icon={icon} title={title}>
                {subNavLinks?.map(renderMenuItem)}
              </Menu.SubMenu>
            )
          }

          return renderMenuItem(navLink)
        })}
      </Menu>
    </Layout.Sider>
  )
}

export default Sidebar
