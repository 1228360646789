import { gql, makeVar, useQuery } from '@apollo/client'
import { on } from 'cluster'

export type Session = {
  token: string | null,
  userId:string|null,
  userType:string|null,
  userName:string|null,
  mobileNumber:string|null
}

type SessionData = {
  session: Session
}

const KEY = 'x-token'
const USER='userId'
const TYPE='userType'
const NAME='userName'
const NUMBER='mobileNumber'

const DEFAULT_OPTIONS: Session = {
  token: localStorage.getItem(KEY),
  userId:localStorage.getItem(USER),
  userType:localStorage.getItem(TYPE),
  userName:localStorage.getItem(NAME),
  mobileNumber:localStorage.getItem(NUMBER)
}

export const SESSION_QUERY = gql`
  query Session {
    session @client {
      token
    }
  }
`

export const sessionVar = makeVar<Session>(DEFAULT_OPTIONS)

const fieldType = {
  session: {
    read() {
      return sessionVar()
    }
  }
}

export const useSessionQuery = () => useQuery<SessionData>(SESSION_QUERY)

export const getSession = () => sessionVar()

export const setSession = (token: string,userId:string,userType:string,userName:string,mobileNumber:string) => {
  localStorage.setItem(KEY, token)
  localStorage.setItem(USER,userId)
  localStorage.setItem(TYPE,userType)
  localStorage.setItem(NAME,userName)
  localStorage.setItem(NUMBER,mobileNumber)
  sessionVar({ token,userId ,userType,userName,mobileNumber})
}

export const logout = () => {
  localStorage.removeItem(KEY)
  localStorage.removeItem(USER)
  localStorage.removeItem(TYPE)
  localStorage.removeItem(NAME)
  localStorage.removeItem(NUMBER)
  sessionVar({ token: null,userId:null,userType:null,userName:null,mobileNumber:null })
}

export default fieldType
