import React from "react";
import Typography from "antd/es/typography";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router";
import { ArrowLeftOutlined } from "@ant-design/icons";

type FormBoxProps = React.PropsWithChildren<{
  title: string | boolean;
  showBackButton?: boolean;
  onBack?: () => void;
}>;

const useStyles = createUseStyles(({ colors }: Theme) => ({
  formBox: {
    padding: [40, -28],
    "& .ant-typography": {
      fontSize: 12,
    },
    "& .ant-input-affix-wrapper > input.ant-input": {
      fontSize: 12,
    },
    "& .ant-form-item": {
      marginBottom: 20,
    },
    "& .ant-form-item-explain": {
      fontSize: 11,
    },
    "& .ant-btn": {
      fontSize: 12,
    },
  },
  title: {
    textAlign: "center",
    fontSize: "1.3rem !important",
    fontWeight: "bold",
    marginBottom: "2rem !important",
    marginTop: "-2rem !important",
  },
  goBackButton: { fontSize: "1.1rem", lineHeight: 2 },
}));

function FormBox({ children, title, showBackButton, onBack }: FormBoxProps) {
  const classes = useStyles();
  const { goBack } = useHistory();

  return (
    <div className={classes.formBox}>
      {showBackButton && (
        <div className={classes.goBackButton}>
          <ArrowLeftOutlined onClick={onBack ? onBack : goBack} />
        </div>
      )}
      {!!title && (
        <Typography.Title level={1} className={classes.title}>
          {title}
        </Typography.Title>
      )}
      {children}
    </div>
  );
}

export default FormBox;
