import * as yup from "yup";

import BaseModel from "./BaseModel";

class RetailStore extends BaseModel {
  static schema = yup.object().shape({
    name: yup.string().required(),
    primaryMobileNumber: yup
      .string()
      .length(10, "should be 10 digits")
      .required(),
    alternateNumber: yup.string().length(10, "should be 10 digits"),
    map_url: yup.string(),
    authenticity_document_value: yup.string(),
    authenticity_document_type: yup.string(),
    operating_system: yup.string(),
    name_of_printer: yup.string(),
    reference_code: yup.string(),
    logo: yup.object(),
    sample_invoice: yup.object(),

    address: yup.string(),
    locality: yup.string(),
    pincode: yup.string(),
    state: yup.string(),
    city: yup.string(),
  });

  static validateCreate<TInput>() {
    return RetailStore.validationResolver<TInput>([
      "name",
      "primaryMobileNumber",
    ]);
  }
}

export default RetailStore;
