import React, { useState } from "react";

import ConfirmMobileForm from "components/forms/ConfirmMobileFrom";
import OnboardingWrapper from "components/OnboardingWrapper";
import RequestMobileConfirmForm from "components/forms/RequestMobileConfirmForm";
import {
  ConfirmMobileNumberInput,
  RequestMobileNumberConfirmationInput,
} from "generated/schema";
import { createOtp, validatePasswordOtp } from "api/api";
import message from "antd/es/message";

type RenderProps = {
  token: string;
  userId: string;
  mobileNumber: string;
};

type OTPProtectedProps = {
  children: (props: RenderProps) => React.ReactNode;
  checkPresence?: boolean;
  title?: string;
};

const DEFAULT_TITLE = "Verify Number";

function OTPProtected({
  children,
  checkPresence = false,
  title = DEFAULT_TITLE,
}: OTPProtectedProps) {
  const [mobileNumber, setMobileNumber] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [userId, setUserId] = useState<string>("");
  const [verified, setVerified] = useState(false);
  const [otpSent, setOtpSent] = useState(false);

  const defaultValues = {
    userId,
    mobileNumber,
  };

  const handleRequest = async (values: any) => {
    let data = {
      mobile_number: values.mobileNumber,
      otp_type: `'Forgot Password'`,
    };

    let res = await createOtp(data);

    if (res?.status === "success") {
      success(3000, res.message);
      setOtpSent(true);
    } else if (res?.status === "failure") {
      failure(15000, res.error);
    }
  };

  const handleConfirm = async (values: any) => {
    let data = {
      otp: values.otp,
      mobile_number: mobileNumber,
      otp_type: `'Forgot Password'`,
    };

    const res = await validatePasswordOtp(data);

    if (res?.status === "success") {
      setVerified(true);
      setUserId(res?.value?.user_id);
      setToken(res.value?.token);
    } else {
      setVerified(false);
      failure(3000, res?.error);
    }
  };

  const onRequestSubmit = (values: RequestMobileNumberConfirmationInput) => {
    setMobileNumber(values.mobileNumber);

    return handleRequest({
      ...values,
      checkPresence,
    });
  };

  const onConfirmSubmit = (values: ConfirmMobileNumberInput) => {
    return handleConfirm(values);
  };

  const success = (t: any, msg: any) => {
    const hide = message.loading(msg, 0);
    // Dismiss manually and asynchronously
    setTimeout(hide, t);
  };

  const failure = (t: any, err: any) => {
    const h = message.error(err, 0);
    // Dismiss manually and asynchronously
    setTimeout(h, t);
  };

  if (!verified) {
    return (
      <OnboardingWrapper height="full">
        {otpSent ? (
          <ConfirmMobileForm
            title={title}
            withoutName
            defaultValues={defaultValues}
            requestMobileNumberConfirmation={onRequestSubmit}
            onSubmit={onConfirmSubmit}
          />
        ) : (
          <RequestMobileConfirmForm
            title={title}
            withoutName
            onSubmit={onRequestSubmit}
          />
        )}
      </OnboardingWrapper>
    );
  }

  if (token && verified && mobileNumber) {
    return (
      <>
        {children({
          token,
          userId,
          mobileNumber,
        })}
      </>
    );
  }

  return null;
}

export default OTPProtected;
