import { getSession, Session } from "client/reactives/session";
const url = process.env.REACT_APP_API_NODE_BASE_URL;

export async function getRetailStoreLogo(key: any) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/generateGetUrl?Key=${key}&BucketDirName=retailStoreLogoImages`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json.getURL;
    });
}

export async function getRetailerRetailStores() {
  const { token, userId } = (await getSession()) as Session;
  const endpoint = `/getRetailerRetailStores?user_id=${userId}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json[0];
    });
}

export async function getReorderCount(
  filterdate: string,
  retailStoreId: number,
  startdate: string
) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/getReorderCount?filterdate='${filterdate}'&retail_store_id=${retailStoreId}&startdate='${startdate}'`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function getDistinctCustomer(
  filterdate: string,
  retailStoreId: number,
  startdate: string
) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/getDistinctCustomer?filterdate='${filterdate}'&retail_store_id=${retailStoreId}&startdate='${startdate}'`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function getTotalRevenue(
  filterdate: string,
  retailStoreId: number,
  startdate: string
) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/getTotalRevenue?filterdate='${filterdate}'&retail_store_id=${retailStoreId}&startdate='${startdate}'`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function getProductCount(
  filterdate: string,
  retailStoreId: number,
  startdate: string
) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/getProductCount?filterdate='${filterdate}'&retail_store_id=${retailStoreId}&startdate='${startdate}'`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function getCustomerProductCount(
  retailStoreId: number,
  userId: number,
  mobileNumber: string
) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/getCustomerProductCount?retail_store_id=${retailStoreId}&user_id=${userId}&mobile_number='${mobileNumber}'`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function getPastPurchases(
  filterdate: string,
  retailStoreId: number,
  startdate: string
) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/getPastPurchases?filterdate='${filterdate}'&retail_store_id=${retailStoreId}&startdate='${startdate}'`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function getReorders(retailStoreId: number) {
  const { token, userId } = (await getSession()) as Session;
  const endpoint = `/getReorders?retail_store_id='${retailStoreId}'`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function getReorderItems(reorderId: number) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/getReorderItems?reorder_id=${reorderId}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function getProducts(id: number) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/getProducts?id=${id}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function getAddresses(
  addressable_id: number,
  addressable_type: string
) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/getAddresses?addressable_id=${addressable_id}&addressable_type='${addressable_type}'`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json[0];
    });
}

export async function getCustomer(user_id: number) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/getCustomer?user_id=${user_id}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json[0];
    });
}

export async function getPrescriptionsById(id: number) {
  const { token, userId } = (await getSession()) as Session;
  const endpoint = `/getPrescriptionsById?id=${id}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json[0];
    });
}

export async function getUserPrescriptions() {
  const { token, userId } = (await getSession()) as Session;
  const endpoint = `/getPrescriptions?user_id=${userId}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function getUserAllPrescriptions() {
  const { token, userId } = (await getSession()) as Session;
  const endpoint = `/generateMultipleGetUrls?type=prescription&user_id=${userId}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function getUserAllBills() {
  const { token, userId } = (await getSession()) as Session;
  const endpoint = `/generateMultipleGetUrls?type=order&user_id=${userId}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function generateGetUrl(key: string, bucketName: string) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/generateGetUrl?Key=${key}&BucketDirName=${bucketName}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json.getURL;
    });
}

export async function getRetailerOrders(retailStoreId: number) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/getRetailerOrders?retail_store_id=${retailStoreId}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function getOrderItems(orderId: number) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/getOrderItems?order_id=${orderId}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function getUsers(mobile_nuumber: String) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/getUsers?mobile_number='${mobile_nuumber}'`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json[0];
    });
}

export async function updateCustomer(id: number, priority: any) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/updateCustomer?id=${id}`;
  const body = {
    priority: `'{${priority}}'`,
  };
  return await fetch(url + endpoint, {
    method: "PUT",
    body: JSON.stringify(body),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function changeCustomerPriority(
  user_id: string,
  retail_store_id: number
) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/changeCustomerPriority`;
  const body = {
    user_id: user_id.toString(),
    retail_store_id: retail_store_id.toString(),
  };
  return await fetch(url + endpoint, {
    method: "PUT",
    body: JSON.stringify(body),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json[0];
    });
}

export async function postNotes(data: any) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/insertNotes`;
  return await fetch(url + endpoint, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function getNotes(customer_id: number, retail_store_id: number) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/getNotes?customer_id='${customer_id}&retail_store_id='${retail_store_id}'`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json[0];
    });
}

export async function updateNotes(id: number, data: any) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/updateNotes?id=${id}`;
  return await fetch(url + endpoint, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function deleteNotes(id: number) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/deleteNotes?id=${id}`;
  return await fetch(url + endpoint, {
    method: "DELETE",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function getLoyaltyInfo(id: any) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/getRetailStoresStatus?retail_store_id=${id}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function updateLoyaltyInfo(data: any, id: any) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/updateRetailStoresStatus?retail_store_id=${id}`;
  return await fetch(url + endpoint, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function reconcileMap(data: any) {
  const { token } = getSession() as Session;
  const endpoint = `/reconcile/map`;
  return await fetch(url + endpoint, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function reconcileReport(data: any) {
  const { token } = getSession() as Session;
  const endpoint = `/reconcile/report`;
  return await fetch(url + endpoint, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function getCatalogue(
  storeId: number,
  pageNumber: number = 1,
  search: string = ""
) {
  const { token } = (await getSession()) as Session;
  let endpoint = `/catalogue/products?retail_store_id=${storeId}&page=${pageNumber}&name=${search}`;

  return await fetch(encodeURI(url + endpoint), {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function changePrice(data: any) {
  const { token, userId } = (await getSession()) as Session;
  let endpoint = `/catalogue/products/changePrice?product_sku=${data.product_sku}&retail_store_id=${data.retail_store_id}&new_price=${data.new_price}&user_id=${userId}`;

  return await fetch(encodeURI(url + endpoint), {
    method: "PUT",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function getPricingRules(storeId: number) {
  const { token } = getSession() as Session;
  let endpoint = `/catalogue/pricing/rule?retail_store_id=${storeId}`;

  return await fetch(encodeURI(url + endpoint), {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function addPricingRule(data: any) {
  const { token } = getSession() as Session;
  let endpoint = `/catalogue/pricing/rule`;

  return await fetch(encodeURI(url + endpoint), {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function deletePricingRule(ruleId: number) {
  const { token } = getSession() as Session;
  let endpoint = `/catalogue/pricing/rule?pricing_rule_id=${ruleId}`;

  return await fetch(encodeURI(url + endpoint), {
    method: "DELETE",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function getBrands() {
  const { token } = getSession() as Session;
  const endpoint = `/catalogue/brand`;

  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((res) => res.json());
}

export async function getProductCategories(vendorCode: string) {
  const { token } = getSession() as Session;
  const endpoint = `/catalogue/category?vendor_code=${vendorCode}`;

  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((res) => res.json());
}

export async function getMarketingCampaigns(id: any) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/marketing/campaign?retail_store_id=${id}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function createMarketingCampaign(data?: any) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/marketing/campaign`;
  return await fetch(url + endpoint, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function createSuggestedMarketingCampaign(data?: any) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/marketing/suggested-campaign`;
  return await fetch(url + endpoint, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function getMarketingTargetAudience(
  id: any,
  data?: any,
  limit?: number,
  page?: number
) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/marketing/target-audience?retail_store_id=${id}&limit=${limit}&page=${page}`;
  return await fetch(url + endpoint, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function getFilteredAilments(id: any, filterType: any) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/marketing/filtered-ailments?retail_store_id=${id}&filter_type=${filterType}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function sendPromotionToRetailer(data: any) {
  const { token } = getSession() as Session;
  const endpoint = `/promotion/sendToRetailer`;
  return await fetch(url + endpoint, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function getStorePerformance1(id: any, data?: any) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/getStorePerformance1?retail_store_id=${id}`;
  return await fetch(url + endpoint, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function getStorePerformance2(id: any, data?: any) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/getStorePerformance2?retail_store_id=${id}`;
  return await fetch(url + endpoint, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function getSuggestedCampaigns(id: any) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/marketing/suggested-campaign?retail_store_id=${id}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function getTopSellingProducts(id: any, data?: any) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/marketing/top-selling-products?retail_store_id=${id}&order_by=product_revenue`;
  return await fetch(url + endpoint, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function getTopCustomers(id: any, data?: any) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/marketing/top-customers?retail_store_id=${id}&order_by=order_count&order_by_sort=ASC`;
  return await fetch(url + endpoint, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function createTicket(data: any) {
  const { token, userId } = (await getSession()) as Session;
  const endpoint = `/supportTickets/createTicket`;
  return await fetch(url + endpoint, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}
