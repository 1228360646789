import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(({ colors }: Theme) => {
  const flexStyles = {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  };

  return {
    "@global": {
      "html,body": {
        overflow: "hidden",
      },
    },
    tabs: {
      ...flexStyles,

      "& .ant-tabs-content-holder, & .ant-tabs-content, & .ant-tabs-tabpane": {
        ...flexStyles,
      },

      "& .ant-tabs-nav": {
        margin: [0, 10],
      },

      "& .ant-tabs-nav-list, & .ant-tabs-tab-btn": {
        width: "100%",
      },

      "& .ant-tabs-tab": {
        padding: 5,
        textAlign: "center",
        width: "50%",
        marginRight: "10px !important",

        "&:last-of-type": {
          margin: "0 !important",
        },
      },

      "& .ant-tabs-tab-btn": {
        fontSize: 12,
        lineHeight: "22px",
      },
    },
    footer: {
      padding: 0,
    },
    totalFooter: {
      height: 50,
      width: "100%",
      padding: "0 0 0 20px",
      display: "flex",
      alignItems: "center",
      position: "fixed",
      bottom: 0,
      zIndex: 7,
      background: colors.light100,
      boxShadow: "0px -4px 10px rgba(0, 0, 0, 0.07)",
    },
    noBillsMockup: {
      height: "85vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
  };
});

export { useStyles };
