import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  StandaloneSearchBox,
  useJsApiLoader,
} from "@react-google-maps/api";
import Geocode from "react-geocode";
import Modal from "antd/lib/modal/Modal";
import { Input } from "antd/es";
import { AimOutlined, SearchOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
const GoogleApiKey = "AIzaSyD77_HuK1Lr-x96zvkl9ZEcO3wAyjQk8gY";
Geocode.setApiKey(GoogleApiKey);
Geocode.enableDebug();

const containerStyle = {
  width: "100%",
  height: "350px",
};

const center = {
  lat: 28.6139,
  lng: 77.209,
};

type LocationPickerModalProps = {
  visible: boolean;
  onCancel: () => any;
  onDone: (data: any) => any;
};

function LocationPickerModal({
  visible,
  onCancel,
  onDone,
}: LocationPickerModalProps) {
  const classes = useStyles();
  const [searchBox, setSearchBox] = useState<any>(null);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [pageData, setPageData] = useState({
    address: "New Delhi",
    city: "",
    area: "",
    state: "",
    mapUrl: "",
    pincode: "",
    mapPosition: {
      lat: 28.6139,
      lng: 77.209,
    },
    markerPosition: {
      lat: 28.6139,
      lng: 77.209,
    },
  });

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    libraries: ["places"],
    googleMapsApiKey: GoogleApiKey,
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    // @ts-ignore
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const getCity = (addressArray: any) => {
    let city = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "administrative_area_level_2" === addressArray[i].types[0]
      ) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };

  const getArea = (addressArray: any) => {
    let area = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if (
            "sublocality_level_1" === addressArray[i].types[j] ||
            "sublocality" === addressArray[i].types[j] ||
            "route" === addressArray[i].types[j]
          ) {
            area = addressArray[i].long_name;
            return area;
          }
        }
      }
    }
  };

  const getState = (addressArray: any) => {
    let state = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "administrative_area_level_1" === addressArray[i].types[0]
        ) {
          state = addressArray[i].long_name;
          return state;
        }
      }
    }
  };

  const getPincode = (addressArray: any) => {
    let pincode = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "postal_code" === addressArray[i].types[0]
        ) {
          pincode = addressArray[i].long_name;
          return pincode;
        }
      }
    }
  };

  const getMapUrl = (place_id: string) => {
    return `https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${place_id}`;
  };

  const onMarkerDragEnd = (event: any) => {
    let newLat = event.latLng.lat(),
      newLng = event.latLng.lng();
    Geocode.fromLatLng(newLat, newLng).then(
      (response) => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          mapUrl = getMapUrl(response.results[0].place_id),
          city = getCity(addressArray),
          area = getArea(addressArray),
          pincode = getPincode(addressArray),
          state = getState(addressArray);
        setPageData({
          address: address ? address : "",
          area: area ? area : "",
          city: city ? city : "",
          state: state ? state : "",
          pincode: pincode ? pincode : "",
          mapUrl: mapUrl ? mapUrl : "",
          markerPosition: {
            lat: newLat,
            lng: newLng,
          },
          mapPosition: {
            lat: newLat,
            lng: newLng,
          },
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const onSearchBox = (ref: any) => {
    setSearchBox(ref);
  };
  const onPlacesChanged = () => {
    let place = searchBox!.getPlaces()[0];
    const address = place.formatted_address,
      addressArray = place.address_components,
      mapUrl = place.url,
      city = getCity(addressArray),
      area = getArea(addressArray),
      pincode = getPincode(addressArray),
      state = getState(addressArray),
      latValue = place.geometry.location.lat(),
      lngValue = place.geometry.location.lng();
    // Set these values in the state.
    setPageData({
      address: address ? address : "",
      area: area ? area : "",
      city: city ? city : "",
      state: state ? state : "",
      pincode: pincode ? pincode : "",
      mapUrl: mapUrl ? mapUrl : "",
      markerPosition: {
        lat: latValue,
        lng: lngValue,
      },
      mapPosition: {
        lat: latValue,
        lng: lngValue,
      },
    });
    setSearchInputValue("");
  };

  const onInfoWindowClose = () => {};

  const handleModalOk = () => {
    onDone(pageData);
    onCancel();
  };

  const chooseCurrentLocation = () => {
    const pos: any = {};
    const geolocation = navigator.geolocation;
    if (geolocation) {
      geolocation.getCurrentPosition(findLocal, showEror);
    }
    function findLocal(position: any) {
      let newLat = position.coords.latitude;
      let newLng = position.coords.longitude;
      if (newLat && newLng) {
        Geocode.fromLatLng(newLat, newLng).then(
          (response) => {
            const address = response.results[0].formatted_address,
              addressArray = response.results[0].address_components,
              mapUrl = getMapUrl(response.results[0].place_id),
              city = getCity(addressArray),
              area = getArea(addressArray),
              pincode = getPincode(addressArray),
              state = getState(addressArray);
            setPageData({
              address: address ? address : "",
              area: area ? area : "",
              city: city ? city : "",
              state: state ? state : "",
              pincode: pincode ? pincode : "",
              mapUrl: mapUrl ? mapUrl : "",
              markerPosition: {
                lat: newLat,
                lng: newLng,
              },
              mapPosition: {
                lat: newLat,
                lng: newLng,
              },
            });
          },
          (error) => {
            console.error(error);
          }
        );
      }
    }
    function showEror() {}
  };

  return (
    <Modal
      title="Pick Location"
      centered
      visible={visible}
      closable={false}
      onCancel={onCancel}
      onOk={handleModalOk}
      okButtonProps={{
        disabled: pageData.state === "",
      }}
      okType="primary"
      cancelText="Close"
      okText="Done"
      className={classes.locationPickerModal}
    >
      {isLoaded ? (
        <>
          <StandaloneSearchBox
            onLoad={onSearchBox}
            onPlacesChanged={onPlacesChanged}
          >
            <Input
              size="large"
              placeholder="Search for your store..."
              className={classes.searchInput}
              value={searchInputValue}
              prefix={<SearchOutlined />}
              suffix={
                <Tooltip title="Choose current location">
                  <AimOutlined onClick={chooseCurrentLocation} />
                </Tooltip>
              }
              onChange={(e) => setSearchInputValue(e.target.value)}
            />
          </StandaloneSearchBox>

          <GoogleMap
            mapContainerStyle={containerStyle}
            center={pageData.mapPosition}
            zoom={10}
            options={{
              controlSize: 25,
              streetViewControl: false,
            }}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >
            {/* Child components, such as markers, info windows, etc. */}
            <Marker
              draggable={true}
              onDragEnd={onMarkerDragEnd}
              position={{
                lat: pageData.markerPosition.lat,
                lng: pageData.markerPosition.lng,
              }}
            />
            <InfoWindow
              onCloseClick={onInfoWindowClose}
              position={{
                lat: pageData.markerPosition.lat,
                lng: pageData.markerPosition.lng,
              }}
            >
              <div>
                <span style={{ padding: 0, margin: 0 }}>
                  {pageData.address}
                </span>
              </div>
            </InfoWindow>
          </GoogleMap>
        </>
      ) : (
        <></>
      )}
    </Modal>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  "@global": {
    ".gm-style .gm-style-iw-c": {
      top: "-45px !important",
    },
    ".gm-style .gm-style-iw-t::after": {
      top: "-45px !important",
    },
  },
  locationPickerModal: {
    "& .ant-modal-footer .ant-btn": {
      width: "49%",
      fontWeight: 600,
    },
  },
  searchInput: {
    border: "none",
    marginBottom: 15,
    boxShadow: "0 0px 4px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%)",
    borderRadius: 5,
    transform: "scale(1.03)",
    padding: "12px 15px",

    "& .anticon": {
      paddingRight: 7,
    },

    "& .ant-input": {
      fontSize: 14,
      fontWeight: 600,
    },
  },
  "@media (max-width:1024px)": {
    locationPickerModal: {
      "& .ant-modal-footer .ant-btn": {
        width: "48%",
        fontWeight: 600,
      },
    },
  },
}));

export default LocationPickerModal;
