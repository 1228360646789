import moment from "moment";
import { Col, Row } from "antd/es";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";
import Loader from "components/loader/Loader";
import React, { useEffect, useState } from "react";
import { getUserAllPrescriptions } from "api/retailerApi";
import PreviewImageModal from "components/modals/PreviewImageModal";
import { ArrowLeftOutlined, DownloadOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import { getSession, Session } from "client/reactives/session";

interface RecentDocumentsSectionProps {
  prescriptions: any[];
}

export default function RecentDocumentsSection({
  prescriptions,
}: RecentDocumentsSectionProps) {
  const classes = useStyles();
  const { userId } = getSession() as Session;
  const [data, setData] = useState({
    isModalOpen: false,
    currentPresIndex: 0,
    loading: true,
  });
  const history = useHistory();

  const openModal = (index: number) => {
    setData({
      ...data,
      isModalOpen: true,
      currentPresIndex: index,
    });
  };

  const closeModal = () => {
    setData({
      ...data,
      isModalOpen: false,
    });
  };

  return (
    <Space
      size={12}
      direction="vertical"
      className={classes.prescriptionsContainer}
    >
      {prescriptions.slice(0, 3).map((pres: any, index: number) => (
        <div
          className={classes.prescriptionCard}
          onClick={() => openModal(index)}
        >
          <div className={classes.imageWrapper}>
            <img
              src={pres.image}
              width="100"
              className={classes.prescriptionImg}
            />
          </div>
          <div className={classes.prescriptionDetails}>
            <div className={classes.prescriptionName}>{pres.name}</div>
            <div className={classes.prescriptionDate}>
              {moment(pres.date).format("DD MMMM YYYY")}
            </div>
            <Button
              type="link"
              href={pres.image}
              target="_blank"
              id="prescription-download-btn"
              data-source="Stores Home Page"
              data-user={userId}
              icon={<DownloadOutlined />}
              className={classes.downloadButton}
              onClick={(e) => e.stopPropagation()}
            />
          </div>
        </div>
      ))}
      <PreviewImageModal
        images={prescriptions.map((x: any) => x.image)}
        visible={data.isModalOpen}
        onCancel={closeModal}
        current={data.currentPresIndex}
      />
    </Space>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  prescriptionsAndReports: {
    height: "100vh",
    background: "rgba(217, 217, 217, 0.2)",
  },
  backButton: {
    textAlign: "center",
  },
  pageHeader: {
    fontSize: 13,
    fontWeight: 700,
  },
  header: {
    height: 58,
    position: "sticky",
    top: "0",
    background: "transparent",
    alignItems: "center",
  },
  prescriptionsContainer: {
    width: "100%",
    marginTop: 10,
  },
  prescriptionCard: {
    width: "100%",
    height: 75,
    paddingLeft: 10,
    overflow: "hidden",
    position: "relative",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",

    background: "#fff",
    border: "0.3px solid #D9D9D9",
    boxShadow:
      "-1px -1px 2px rgba(0, 0, 0, 0.1), 1px 1px 2px rgba(0, 0, 0, 0.1)",
    borderRadius: 2,
  },
  imageWrapper: {
    width: 90,
    padding: [7, 10],
    background: "rgba(0,0,0,0.08)",
    textAlign: "center",
  },
  prescriptionImg: {
    width: "100%",
    height: "40px",
    objectFit: "contain",
  },
  prescriptionDetails: {
    flex: 1,
    marginLeft: 20,
    whiteSpace: "nowrap",
  },
  prescriptionName: {
    fontSize: 12,
    fontWeight: 500,
    color: "#505050",
  },
  prescriptionDate: {
    fontSize: 10,
    color: "#979797",
  },
  noPrescriptions: {
    height: "calc(100vh - 58px)",
    width: "100%",
    color: "#505050",
    fontWeight: 600,
    fontSize: 12,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  downloadButton: {
    position: "absolute",
    top: "50%",
    right: 10,
    transform: "translateY(-50%)",
    "& .anticon": {
      fontSize: 20,
    },
  },
}));
