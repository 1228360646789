export default function getPlatform() {
  // @ts-ignore
  const standalone = window.navigator?.standalone,
    userAgent = window.navigator.userAgent.toLowerCase(),
    safari = /safari/.test(userAgent),
    ios = /iphone|ipod|ipad/.test(userAgent);

  let platform = "";

  if (ios) {
    if (!standalone && safari) {
      platform = "safari";
      // Safari
    } else if (!standalone && !safari) {
      platform = "ios_webview";
      // iOS webview
    }
  } else {
    if (userAgent.includes("wv")) {
      platform = "android_webview";
      // Android webview
    } else {
      platform = "browser";
      // Chrome
    }
  }

  return platform;
}
