import { UploadOutlined } from "@ant-design/icons/lib/icons";
import Button from "antd/es/button";
import React, { useState } from "react";

import UploadImageModal from "components/modals/UploadImageModal";

// @ts-ignore
// eslint-disable-next-line react/prop-types
function UploadInput({ onChange }) {
  const [isUploadImageModalOpen, setIsUploadImageModalOpen] = useState(false);

  const openImageUploadModal = () => setIsUploadImageModalOpen(true);
  const closeImageUploadModal = () => setIsUploadImageModalOpen(false);

  return (
    <>
      <Button
        icon={<UploadOutlined />}
        size="middle"
        onClick={openImageUploadModal}
      >
        Upload
      </Button>

      <UploadImageModal
        visible={isUploadImageModalOpen}
        onSubmit={onChange}
        onCancel={closeImageUploadModal}
      />
    </>
  );
}

export default UploadInput;
