import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Carousel,
  Checkbox,
  DatePicker,
  Popover,
  Space,
  Table,
  Tooltip,
  Typography,
} from "antd";
import Layout from "antd/es/layout/layout";
import Flex from "components/layouts/Flex";
import { createUseStyles } from "react-jss";
import BannerImg from "assets/pngs/rdash-home-banner.png";
import DiabatesCare from "assets/svgs/diabetes-care.svg";
import MedicineIcon from "assets/svgs/medicine-bottle.svg";
import Hypertension from "assets/svgs/hypertension.svg";
import { hideScrollbar } from "components/style/CustomScrollbar";
import { getRetailStoreCustomers } from "components/pages/retailerDashboard/APIs/getRetailStoreCustomers";
import {
  getStorePerformance1,
  getSuggestedCampaigns,
  getTopCustomers,
  getTopSellingProducts,
} from "api/retailerApi";
import { Link } from "react-router-dom";
import moment from "moment";
import Loader from "components/loader/Loader";
import OrderBagsModal from "components/modals/OrderBagsModal";

const truncate = (input: string, length: number) => {
  if (input.length > length) {
    return input.substring(0, length) + "...";
  }
  return input;
};

type HomePageProps = {
  retailerData: {
    ID: number;
    Name: string;
  };
};

export default function HomePage({ retailerData }: HomePageProps) {
  const classes = useStyle();
  const [loading, setLoading] = useState(true);
  const [orderBagModalVisible, setOrderBagModalVisible] = useState(false);
  const [topCustomers, setTopCustomers] = useState<any[]>([]);
  const [suggestedCampaigns, setSuggestedCampaigns] = useState<any[]>([]);
  const [storePerformance, setStorePerformance] = useState<any>({});
  const [topSellingProducts, setTopSellingProducts] = useState<any[]>([]);
  const [storePerformanceDateRange, setStorePerformanceDateRange] = useState<
    any[]
  >([moment().startOf("M"), moment().endOf("D")]);

  const TableColumns: any[] = [
    {
      title: "#",
      render: (a: any, b: any, index: number) => index + 1,
      width: "50px",
    },
    {
      title: "Customer Name",
      dataIndex: "mobile_number",
      key: "mobile_number",
      width: "120px",
    },
    {
      title: "Purchase Value",
      dataIndex: "sum_total",
      key: "sum_total",
      width: "120px",
      render: (val: any) => "₹" + val,
    },
    {
      title: "Repeat",
      dataIndex: "is_repeat_buyer",
      key: "is_repeat_buyer",
      width: "120px",
      render: (val: boolean) => <Checkbox disabled checked={val} />,
    },
    {
      title: "Disease Group",
      key: "ailment_class",
      dataIndex: "ailment_class",
      width: "150px",
      render: (val: any) =>
        !!val ? <Tooltip title={val}>{truncate(val, 15)}</Tooltip> : " - ",
    },
  ];

  const getFilterObj = () =>
    !storePerformanceDateRange.includes(null)
      ? {
          customer_filter: [
            {
              type: "created_at",
              // filter_on: "O",
              min_value: storePerformanceDateRange?.[0].format("YYYY-MM-DD"),
              max_value: storePerformanceDateRange?.[1]?.format("YYYY-MM-DD"),
            },
          ],
        }
      : {
          customer_filter: [
            {
              type: "created_at",
              min_value: moment().format("YYYY-MM-DD"),
              max_value: moment().format("YYYY-MM-DD"),
            },
          ],
        };

  const fetchTopCustomers = async () => {
    let topCustomersRes = await getTopCustomers(retailerData.ID);
    let topSellingProductsRes = await getTopSellingProducts(retailerData.ID);
    let suggestedCampaignsRes = await getSuggestedCampaigns(retailerData.ID);

    if (!!topCustomersRes?.data?.top_selling_products)
      setTopCustomers(topCustomersRes.data.top_selling_products);
    if (!!topSellingProductsRes?.data?.top_selling_products)
      setTopSellingProducts(topSellingProductsRes.data.top_selling_products);
    if (!!suggestedCampaignsRes?.data?.length)
      setSuggestedCampaigns(suggestedCampaignsRes.data.slice(0, 3));

    setLoading(false);
  };

  const fetchStorePerformance = async () => {
    let storePerformanceRes = await getStorePerformance1(
      retailerData.ID,
      getFilterObj()
    );
    if (!!storePerformanceRes?.data) {
      setStorePerformance(storePerformanceRes.data);
    }
  };

  useEffect(() => {
    fetchTopCustomers();
  }, []);

  useEffect(() => {
    fetchStorePerformance();
  }, [storePerformanceDateRange]);

  if (loading) {
    return <Loader height="80vh" />;
  }

  return (
    <Layout className={classes.homePage}>
      <div className={classes.upperSection}>
        <Flex className={classes.headerSliderActions}>
          {suggestedCampaigns.map((campaign, index) => (
            <div
              key={campaign.id}
              className={classes.actionCard2}
              style={{
                backgroundColor: index === 1 ? "#FFB8B880" : "#1890FF40",
              }}
            >
              <Typography.Text strong>
                {campaign.target_customer_ailment_class}
              </Typography.Text>

              <Link
                to={`/retailer/dashboard/marketing-campaigns/new?disease_group=${campaign.target_customer_ailment_class}&campaign_id=${campaign.id}&campaign_type=Suggestion`}
              >
                <Button size="small" type="primary">
                  Click to send reminder
                </Button>
              </Link>

              <img src={Hypertension} alt="" />
            </div>
          ))}
        </Flex>

        <Carousel>
          <img
            src={BannerImg}
            alt=""
            width="100%"
            onClick={() => setOrderBagModalVisible(true)}
          />
        </Carousel>

        <OrderBagsModal
          visible={orderBagModalVisible}
          onCancel={() => setOrderBagModalVisible(false)}
          retailerData={retailerData}
        />

        <div className={classes.storePerformanceCard}>
          <Space direction="vertical" size={15}>
            <Flex justify="space-between">
              <Typography.Text>Store Performance</Typography.Text>
              <Typography.Text>
                {!storePerformanceDateRange.includes(null)
                  ? `${storePerformanceDateRange[0]?.format(
                      "DD/MM/YY"
                    )} - ${storePerformanceDateRange[1]?.format("DD/MM/YY")}`
                  : "Today"}
                <Popover
                  placement="bottomRight"
                  trigger={["click"]}
                  content={
                    <DatePicker.RangePicker
                      // @ts-ignore
                      defaultValue={storePerformanceDateRange}
                      onChange={(values) =>
                        setStorePerformanceDateRange(values ?? [null, null])
                      }
                      ranges={{
                        Today: [moment(), moment()],
                        "This week": [
                          moment().startOf("week"),
                          moment().endOf("week"),
                        ],
                        "Last week": [
                          moment().subtract(1, "weeks").startOf("week"),
                          moment()
                            .subtract(1, "weeks")
                            .startOf("week")
                            .add(6, "days"),
                        ],
                        "This Month": [
                          moment().startOf("month"),
                          moment().endOf("month"),
                        ],
                        "Last Month": [
                          moment().subtract(1, "months").startOf("month"),
                          moment().subtract(1, "months").endOf("month"),
                        ],
                      }}
                    />
                  }
                >
                  <Typography.Text type="secondary"> (change)</Typography.Text>
                </Popover>
              </Typography.Text>
            </Flex>

            <div>
              <Flex justify="space-between">
                <Typography.Text strong>Sales</Typography.Text>
                <Typography.Text strong>
                  ₹{(+storePerformance?.sales?.sales_done).toFixed(2)}
                </Typography.Text>
              </Flex>
              <Space
                size={5}
                direction="vertical"
                className={classes.performanceCardSubItems}
              >
                <Flex justify="space-between">
                  <Typography.Text type="secondary">
                    Rx (Medicines)
                  </Typography.Text>
                  <Typography.Text type="secondary">
                    ₹{(+storePerformance?.sales?.medicines).toFixed(2)}
                  </Typography.Text>
                </Flex>
                <Flex justify="space-between">
                  <Typography.Text type="secondary">
                    Non-Rx (OTC)
                  </Typography.Text>
                  <Typography.Text type="secondary">
                    ₹{(+storePerformance?.sales?.otc).toFixed(2)}
                  </Typography.Text>
                </Flex>
                <Flex justify="space-between">
                  <Typography.Text type="secondary">Other</Typography.Text>
                  <Typography.Text type="secondary">
                    ₹{(+storePerformance?.sales?.other).toFixed(2)}
                  </Typography.Text>
                </Flex>
              </Space>
            </div>

            <Flex justify="space-between">
              <Typography.Text strong>Bills</Typography.Text>
              <Typography.Text strong>{storePerformance.bills}</Typography.Text>
            </Flex>

            <Flex justify="space-between">
              <Typography.Text strong>Customers</Typography.Text>
              <Typography.Text strong>
                {storePerformance.customers}
              </Typography.Text>
            </Flex>
          </Space>
        </div>

        <Link to="/retailer/dashboard/marketing-campaigns/new">
          <Button block type="primary" className={classes.boostSalesBtn}>
            Boost Sales
          </Button>
        </Link>
      </div>

      <div className={classes.lowerSection}>
        <Flex justify="space-between" align="center">
          <Typography.Text className={classes.topSellerTitle}>
            Top Sellers
          </Typography.Text>

          <Link to="/retailer/dashboard/business-summary">
            <Button type="link" style={{ padding: 0 }}>
              View more
            </Button>
          </Link>
        </Flex>

        <Flex className={classes.topSellerProducts}>
          {topSellingProducts.slice(0, 10).map((product, index) => (
            <Badge.Ribbon placement="start" text={"#" + (index + 1)}>
              <div className={classes.topSellerProductCard}>
                <img
                  src={
                    product?.image_urls?.length
                      ? product?.image_urls[0]
                      : MedicineIcon
                  }
                  alt=""
                />
                <Typography.Text>{product.name}</Typography.Text>
                <Typography.Text strong>
                  {product?.sum_quantity_sold} qty
                </Typography.Text>
              </div>
            </Badge.Ribbon>
          ))}
        </Flex>

        <Flex
          justify="space-between"
          align="center"
          className={classes.topCustomersHeader}
        >
          <Typography.Text className={classes.topSellerTitle}>
            Top Customers
          </Typography.Text>

          <Link to="/retailer/dashboard/business-summary">
            <Button type="link" style={{ padding: 0 }}>
              View more
            </Button>
          </Link>
        </Flex>

        <Table
          size="small"
          pagination={false}
          columns={TableColumns}
          dataSource={topCustomers.slice(0, 10)}
          scroll={{ y: "calc(100vh - 270px)", x: true }}
        />
      </div>
    </Layout>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  "@global": {
    ".ant-picker-panel-container .ant-picker-panels": {
      flexWrap: "wrap !important",
    },
    ".ant-picker-content, .ant-picker-date-panel, .ant-picker-panel": {
      width: "100% !important",
    },
    ".ant-picker-range-wrapper": {
      minWidth: "unset !important",
      maxWidth: "320px !important",
    },
  },
  homePage: {
    height: "calc(100vh - 130px)",
    overflowY: "auto",

    "& .ant-table *": {
      fontSize: 12,
    },
  },
  upperSection: {
    padding: 15,
    backgroundColor: colors.light100,
  },
  headerSliderActions: {
    width: "calc(100vw - 30px)",
    marginBottom: 15,
    overflowX: "auto",
    justifyContent: "flex-start",
    ...hideScrollbar,

    "& > *": {
      height: 120,
      width: "70vw",
      minWidth: "70vw",
      backgroundColor: colors.light100,
      marginRight: 10,
    },
  },
  actionCard1: {
    padding: [30, 30, 25],
    backgroundColor: "#FFB8B880",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    fontSize: 12,

    "& .ant-btn": {
      fontSize: 12,
    },
    "& img": {
      width: 80,
      position: "absolute",
      right: 0,
      bottom: 0,
    },
  },
  actionCard2: {
    padding: [30, 30, 25],
    backgroundColor: "#1890FF40",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    fontSize: 12,

    "& .ant-btn": {
      fontSize: 12,
    },
    "& img": {
      width: 80,
      position: "absolute",
      right: 0,
      bottom: -10,
    },
  },
  storePerformanceCard: {
    padding: 15,
    marginTop: 10,
    backgroundColor: colors.light100,
    border: "1px solid rgba(43, 119, 151, 0.75)",
    borderRadius: 5,

    "& .ant-space": {
      width: "100%",
    },
  },
  performanceCardSubItems: {
    padding: [10, 0, 5, 10],
    "& .ant-typography": {
      fontSize: 10,
    },
  },
  boostSalesBtn: {
    marginTop: 10,
    backgroundColor: "#38BD6E",
  },

  lowerSection: {
    marginTop: 20,
    padding: [15, 15, 50],
    backgroundColor: colors.light100,
  },
  topSellerTitle: {
    fontSize: 16,
    fontWeight: 600,
  },
  topSellerProducts: {
    width: "calc(100vw - 30px)",
    paddingLeft: 10,
    marginTop: 10,
    marginBottom: 15,
    overflowX: "auto",
    justifyContent: "flex-start",
    ...hideScrollbar,
  },
  topSellerProductCard: {
    height: 140,
    width: 120,
    minWidth: 140,
    padding: 10,
    marginRight: 20,
    fontSize: 12,

    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",

    background: "#FFFFFF",
    border: "0.3px solid rgba(80, 80, 80, 0.5)",
    boxShadow: "0.3px 0.3px 1px rgba(0, 0, 0, 0.1)",
    borderRadius: 5,

    "& img": {
      width: 80,
      height: 60,
      marginBottom: 15,
      objectFit: "contain",
    },
  },
  topCustomersHeader: {
    margin: [30, 0, 10],
  },
}));
