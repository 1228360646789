import { Button, Modal, ModalProps, Typography } from "antd";
import React from "react";
import { createUseStyles } from "react-jss";
import MedicineVector from "assets/svgs/medicines-vector.svg";
import { Link, useRouteMatch } from "react-router-dom";

interface CongratsModalProps extends ModalProps {}

export default function CongratsModal({ ...props }: CongratsModalProps) {
  const classes = useStyle();
  const {
    params: { retailStoreId },
  } = useRouteMatch<any>() || {};

  return (
    <Modal
      {...props}
      centered
      title={false}
      footer={false}
      width="90vw"
      className={classes.congratsModal}
    >
      <img src={MedicineVector} alt="" className={classes.vector} />
      <Typography.Text strong className={classes.modalTitle}>
        Congratulations!
      </Typography.Text>
      <Typography.Paragraph>
        For choosing to make your healthcare
        <br />
        more affordable today!
      </Typography.Paragraph>

      <Link to={`/dashboard/stores/${retailStoreId}/plans`}>
        <Button type="link">Click here for details</Button>
      </Link>
    </Modal>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  congratsModal: {
    "& .ant-modal-body": {
      padding: [50, 10],
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
    },
  },
  modalTitle: {
    margin: [10, 0, 20],
    fontSize: 16,
  },
  vector: {},
}));
