import { ArrowLeftOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import React from "react";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";

function ReturnPolicy() {
  const classes = useStyles();
  const history = useHistory();

  const Section = ({ title, children }: any) => (
    <div className={classes.pageSection}>
      <div className={classes.sectionTitle}>{title}</div>
      <div className={classes.sectionContent}>{children}</div>
    </div>
  );

  return (
    <div className={classes.privacyPolicyPage}>
      <Row className={classes.header}>
        <Col className={classes.backButton} span={3}>
          <ArrowLeftOutlined onClick={() => history.goBack()} />
        </Col>
        <Col className={classes.pageHeader} span={20}>
          Return Policy
        </Col>
      </Row>
      <div className={classes.pageContent}>
        <div className={classes.pageSubtitle}>
          Thanks for using the RASEET platform to aid your purchase from your
          favourite store online. We hope that you enjoyed your shopping
          experience. However, if you are not completely satisfied
          with your purchase for any reason, we would assist you with
          a full refund, store credits or exchange on your return.
          Please refer to our return policies as detailed below:
        </div>

        <Section title="Returns">
          RASEET acts as a platform for your local stores to serve you digitally.
          Therefore, every participating store has their respective return policy
          in place. However, RASEET ensures that your return requests are
          entertained irrespective of a store’s policy if your requests fulfill
          the following criterias:
            1. The return request is made within 7 days of purchase
            2. Products being returned are unused and preferably with original packaging
        </Section>
        <Section title="Process">
          Please visit the bill receipt on the RASEET interface and select one, many or all 
          items in the bill to be returned. Post this, select the reason for return from the 
          menu or detail any other reason if not in the options provided. At last, click the
          picture of the item/s that you wish to return and submit the return request.
          <br />
          <br />
          After submitting the return request, you may choose to either return the item/s
          yourself to the store you purchased them from or request a pick up. In either case,
          the item/s should return to the store within 24 hours of placing the return request.
        </Section>

        <Section title="Refunds/Store Credits/Exchange">
          After the item/s are received by the store, we shall process a refund of your purchase
          within 2 working days. The refund status shall be updated on your RASEET account as
          well as notified to you via email and message (SMS/Whatsapp).
        </Section>
        <Section title="Questions">
          If you have any questions concerning the return policy, please reach out
          to us at: <a href="mailto:hello@raseet.com">hello@raseet.com</a>
        </Section>
      </div>
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  privacyPolicyPage: {
    background: "#fff",
  },
  backButton: {
    textAlign: "center",
  },
  pageHeader: {
    fontSize: 13,
    fontWeight: 700,
  },
  header: {
    height: 58,
    position: "sticky",
    top: "0",
    background: "transparent",
    alignItems: "center",
  },
  pageContent: {
    height: "calc(100vh - 58px)",
    padding: 15,
    overflowY: "auto",
  },
  pageSection: {
    marginBottom: 40,
  },
  pageSubtitle: {
    fontSize: 12,
    fontWeight: 500,
    margin: [0, 0, 30],
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: 500,
    marginBottom: 10,
  },
  sectionContent: {
    fontSize: 10,
    whiteSpace: "break-spaces",
    textAlign: "justify",

    "& b": {
      fontWeight: "500 !important",
    },
    "& ul": {
      paddingLeft: 30,
    },
  },
}));

export default ReturnPolicy;
