import { Col, Row } from "antd/es/grid";
import React, { useState } from "react";
import Sidebar from "components/pages/retailerDashboard/RetailerDashboardLayout/Sidebar";
import Topbar from "components/pages/retailerDashboard/RetailerDashboardLayout/Topbar";
import { useStyles } from "./RetailerDashboardLayout.style";

type LayoutProps = {
  children: React.ReactNode;
  retailerData: {
    Name: string;
    Logo: string;
  };
};

function RetailerDashboardLayout({ children, retailerData }: LayoutProps) {
  const classes = useStyles();
  const [sidebarCollapsed, setsidebarCollapsed] = useState(true);

  return (
    <Row className={classes.retailerDashboard}>
      <Col
        md={sidebarCollapsed ? 2 : 3}
        xl={sidebarCollapsed ? 1 : 3}
        xxl={sidebarCollapsed ? 1 : 2}
        className={classes.sidebarColumn}
      >
        {/* ---Sidebar--- */}
        <Sidebar
          sidebarCollapsed={sidebarCollapsed}
          setsidebarCollapse={setsidebarCollapsed}
        />
      </Col>
      <Col
        md={sidebarCollapsed ? 22 : 21}
        xl={sidebarCollapsed ? 23 : 21}
        xxl={sidebarCollapsed ? 23 : 22}
      >
        <div>
          {/* ---Topbar--- */}
          <Topbar retailerData={retailerData} />
        </div>
        <div className={classes.pageContent}>
          {/* Page Content */}
          {children}
        </div>
      </Col>
    </Row>
  );
}

export default RetailerDashboardLayout;
