import React, { useEffect } from "react";
import Menu from "antd/es/menu";
import Layout from "antd/es/layout";
import {
  FileFilled,
  HomeOutlined,
  ShopOutlined,
  LineChartOutlined,
  ThunderboltOutlined,
  FileOutlined,
  HomeFilled,
  ShopFilled,
  ThunderboltFilled,
  BookOutlined,
  BookFilled,
  ProfileOutlined,
  ProfileFilled,
} from "@ant-design/icons/lib/icons";
import { createUseStyles } from "react-jss";
import MenuItem from "antd/es/menu/MenuItem";
import { Link, useLocation, useParams } from "react-router-dom";
import gtag from "./Tracking/gtag";
import { PlansFilledIcon, PlansOutlinedIcon } from "./customIcons";

type FooterMenuProps = {
  retailStoreId: string;
  noLinks?: boolean;
};

const MENU_HEIGHT = 50;

const useStyles = createUseStyles(({ colors }: Theme) => ({
  footer: {
    padding: 0,
    width: "100%",
    position: ({ noLinks }) => (noLinks ? "absolute" : "fixed"),
    bottom: 0,
    left: 0,
    zIndex: 5,
    boxShadow: "1px 8px 27px 2px rgba(0,0,0,0.3)",

    "& .anticon": {
      fontSize: 16,
    },

    "& .ant-menu-item-selected a": {
      fontWeight: 700,
    },
  },
  menu: {
    backgroundColor: colors.light100,
    boxShadow: "1px, 1px, 5px, 0, rgba(0, 0, 0, 0.5)",
    border: "none !important",
    display: "flex",
    height: MENU_HEIGHT,
    justifyContent: "space-between",
    padding: 0,

    "& li": {
      border: "none !important",
      margin: "0 !important",
    },
  },
  menuItem: {
    alignItems: "center !important",
    border: "none !important",
    bottom: "0 !important",
    color: `${colors.primary} !important`,
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "space-evenly !important",
    opacity: 0.5,
    top: "0 !important",

    "&.ant-menu-item-selected, &.ant-menu-item-active": {
      opacity: 1,
    },

    "& a": {
      fontSize: "10px !important",

      "&::before": {
        bottom: "0 !important",
      },
    },

    "& span": {
      margin: "0 !important",
      lineHeight: "0 !important",
    },
  },
}));

const navLinks = (retailStoreId: string) => [
  {
    to: `/${retailStoreId}/home`,
    title: "Home",
    icon: <HomeOutlined />,
    activeIcon: <HomeFilled />,
  },
  {
    to: `/${retailStoreId}/bill`,
    title: "Bills",
    icon: <FileOutlined />,
    activeIcon: <FileFilled />,
  },
  {
    to: `/${retailStoreId}/shop`,
    title: "Shop",
    icon: <ShopOutlined />,
    activeIcon: <ShopFilled />,
    onClick: handleShopClick(retailStoreId),
  },
  // {
  //   to: `/${retailStoreId}/plans`,
  //   title: "Plans",
  //   icon: <PlansOutlinedIcon />,
  //   activeIcon: <PlansFilledIcon />,
  // },
  {
    to: `/${retailStoreId}/utility`,
    title: "Utility",
    icon: <BookOutlined style={{ fontSize: "19px !important" }} />,
    activeIcon: <BookFilled style={{ fontSize: "19px !important" }} />,
  },
];

const handleShopClick = (store: string) => () => {
  gtag("event", "shop_page_click", { store });
};

function FooterMenu({ retailStoreId, noLinks = false }: FooterMenuProps) {
  const classes = useStyles({ noLinks });
  let { pathname } = useLocation();
  const links = navLinks(retailStoreId);
  if (pathname.includes("/orderPlaced/")) {
    pathname = "/dashboard/stores/" + retailStoreId + "/bill";
  }

  const activeNavLink = links.find((link) => {
    if ("/dashboard/stores" + link.to === pathname) {
      return true;
    }
  });

  return (
    <Layout.Footer className={classes.footer}>
      <Menu
        className={classes.menu}
        mode="horizontal"
        selectedKeys={[
          activeNavLink && activeNavLink.to ? activeNavLink.to : "",
        ]}
      >
        {links.map(({ title, icon, activeIcon, to, ...rest }) => (
          <MenuItem
            className={classes.menuItem}
            key={to}
            icon={activeNavLink && activeNavLink.to === to ? activeIcon : icon}
          >
            {noLinks ? (
              <Link to="#">{title}</Link>
            ) : (
              <Link to={"/dashboard/stores" + to} onClick={rest?.onClick}>
                {title}
              </Link>
            )}
          </MenuItem>
        ))}
      </Menu>
    </Layout.Footer>
  );
}

export { MENU_HEIGHT };

export default FooterMenu;
