import { EnvironmentOutlined, PhoneOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Empty,
  Layout,
  message,
  Modal,
  Rate,
  Row,
  Space,
  Tabs,
  Typography,
} from "antd";
import { createTicket, getRetailStores, getUtilitiesByType } from "api/api";
import FooterMenu from "components/FooterMenu";
import Flex from "components/layouts/Flex";
import Loader from "components/loader/Loader";
import RetailStoreDetailsView from "components/views/RetailerStoreDetailsView";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useRouteMatch } from "react-router-dom";

const truncate = (input: string, length: number) => {
  if (input.length > length) {
    return input.substring(0, length) + "...";
  }
  return input;
};

type RouteParams = {
  retailStoreId: string;
};

export default function CarePage() {
  const classes = useStyle();
  const {
    params: { retailStoreId },
  } = useRouteMatch<RouteParams>() || {};
  const [activeKey, setActiveKey] = useState("doctors");
  const [loading, setLoading] = useState(true);
  const [storeDetails, setStoreDetails] = useState({});
  const [utilities, setUtilities] = useState({
    doctors: [],
    pathalogies: [],
  });

  const handleRequestCallbackClick = (item: any) => () => {
    // Modal.confirm({
    //   centered:true,
    //   title: `Send callback request to ${item.name}?`,
    //   okText: "Send",
    //   onOk: ,
    // });
    sendCallbackRequest(item)();
  };

  const sendCallbackRequest = (item: any) => async () => {
    let dataToSend = {
      status: "OPEN",
      ticket_cause: "GET CALLBACK",
      retail_store_id: retailStoreId,
      referenced_entity_id: item.place_id,
      referenced_entity_type: activeKey,
    };

    await createTicket(dataToSend).then((res) => {
      if (!!res?.id) {
        message.success("Callback requested successfully!");
      } else {
        message.error("Something went wrong!");
      }
    });
  };

  const fetchInitialData = async () => {
    let storeDetailsRes = await getRetailStores(+retailStoreId);
    let doctorsUtilitiesRes = await getUtilitiesByType(
      +retailStoreId,
      "doctors"
    );
    let pathalogyUtilitiesRes = await getUtilitiesByType(
      +retailStoreId,
      "pathology"
    );

    setUtilities({
      doctors: doctorsUtilitiesRes?.data,
      pathalogies: pathalogyUtilitiesRes?.data,
    });

    setStoreDetails(storeDetailsRes);
    setLoading(false);
  };

  useEffect(() => {
    if (loading) {
      fetchInitialData();
    }
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={classes.carePage}>
      <RetailStoreDetailsView retailStore={storeDetails} />

      <Tabs
        activeKey={activeKey}
        onChange={setActiveKey}
        className={classes.pageTabs}
      >
        {/* Doctors Tab */}
        <Tabs.TabPane tab="Doctors" key="doctors">
          <Row gutter={[10, 10]} style={{ padding: 20 }}>
            {utilities.doctors?.length ? (
              utilities.doctors.map((item: any, index) => (
                <Col xs={24} key={index}>
                  <Card className={classes.utilityCard}>
                    <img
                      src="https://img.freepik.com/premium-vector/character-doctor-vector-illustration-flat-style_605517-147.jpg?w=200"
                      alt=""
                    />

                    <div>
                      <Typography.Text strong className={classes.utilityName}>
                        {item.name}
                      </Typography.Text>
                      <Typography.Paragraph className={classes.utilityAddress}>
                        {item?.formatted_address}
                      </Typography.Paragraph>
                      {!!+item?.rating && (
                        <Flex align="center" className={classes.ratings}>
                          <Rate disabled defaultValue={item?.rating} />
                          <Typography.Text
                            type="secondary"
                            className={classes.ratingCount}
                          >
                            ({item?.user_ratings_total})
                          </Typography.Text>
                        </Flex>
                      )}

                      {item?.phone_no?.length > 0 && (
                        <Button
                          size="small"
                          type="text"
                          href={"tel:" + item?.phone_no}
                          icon={<PhoneOutlined rotate={90} />}
                          className={classes.callBtn}
                        >
                          {item?.phone_no}
                        </Button>
                      )}

                      <Space className={classes.utilityButtonWrapper}>
                        <Button
                          size="small"
                          icon={<EnvironmentOutlined />}
                          href={item?.location_url}
                          className={classes.locationBtn}
                        />

                        <Button
                          size="small"
                          type="primary"
                          icon={<PhoneOutlined rotate={90} />}
                          className={classes.utilityButton}
                          onClick={handleRequestCallbackClick(item)}
                        >
                          Request Callback
                        </Button>
                      </Space>
                    </div>
                  </Card>
                </Col>
              ))
            ) : (
              <div className={classes.emptyContainer}>
                <Empty description="No doctors available." />
              </div>
            )}
          </Row>
        </Tabs.TabPane>

        {/* Pathology Tab */}
        <Tabs.TabPane tab="Pathologies" key="pathalogies">
          <Row gutter={[10, 10]} style={{ padding: 20 }}>
            {utilities.pathalogies?.length ? (
              utilities.pathalogies.map((item: any, index) => (
                <Col xs={24} key={index}>
                  <Card className={classes.utilityCard}>
                    <img
                      src="https://media.istockphoto.com/vectors/pathologist-biochemist-vector-id1277930269?k=20&m=1277930269&s=612x612&w=0&h=ucSrdWDkUI6etTSLmfBpG0mzrpQiXC0a4UzAd6NeIMQ="
                      alt=""
                    />

                    <div>
                      <Typography.Text strong className={classes.utilityName}>
                        {item.name}
                      </Typography.Text>
                      <Typography.Paragraph className={classes.utilityAddress}>
                        {item?.formatted_address}
                      </Typography.Paragraph>

                      {!!+item?.rating && (
                        <Flex align="center" className={classes.ratings}>
                          <Rate disabled defaultValue={item?.rating} />
                          <Typography.Text
                            type="secondary"
                            className={classes.ratingCount}
                          >
                            ({item?.user_ratings_total})
                          </Typography.Text>
                        </Flex>
                      )}

                      {item?.phone_no?.length > 0 && (
                        <Button
                          size="small"
                          type="text"
                          href={"tel:" + item?.phone_no}
                          icon={<PhoneOutlined rotate={90} />}
                          className={classes.callBtn}
                        >
                          {item?.phone_no}
                        </Button>
                      )}

                      <Space className={classes.utilityButtonWrapper}>
                        <Button
                          size="small"
                          icon={<EnvironmentOutlined />}
                          href={item?.location_url}
                          className={classes.locationBtn}
                        />

                        <Button
                          size="small"
                          type="primary"
                          icon={<PhoneOutlined rotate={90} />}
                          className={classes.utilityButton}
                          onClick={handleRequestCallbackClick(item)}
                        >
                          Request Callback
                        </Button>
                      </Space>
                    </div>
                  </Card>
                </Col>
              ))
            ) : (
              <div className={classes.emptyContainer}>
                <Empty description="No pathalogies available." />
              </div>
            )}
          </Row>
        </Tabs.TabPane>
      </Tabs>

      <Layout.Footer style={{ padding: 0 }}>
        <FooterMenu retailStoreId={retailStoreId} />
      </Layout.Footer>
    </div>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  carePage: {
    height: "100vh",
    overflow: "hidden",
  },
  pageTabs: {
    "& .ant-tabs-nav": {
      marginBottom: "0 !important",
    },
    "& .ant-tabs-nav-list": {
      width: "100%",
    },
    "& .ant-tabs-tab, & .ant-tabs-tab .ant-tabs-tab-btn": {
      flex: 1,
      textAlign: "center",
    },
    "& .ant-tabs-content": {
      backgroundColor: colors.light300,
      height: "calc(100vh - 180px)",
      overflowY: "auto",
      overflowX: "hidden",
    },
  },
  utilityCard: {
    // height: 100,
    paddingTop: 10,
    paddingBottom: 10,

    position: "relative",

    "& .ant-card-body": {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },

    "& img": {
      width: 100,
      height: 100,
      marginRight: 10,

      objectFit: "contain",
      borderRadius: "50%",
    },
  },
  utilityName: {
    display: "block",
    fontSize: 12,
  },
  utilityAddress: {
    marginTop: 5,
    fontSize: 10,
  },
  utilitySubtitle: {
    fontSize: 12,
  },
  utilityButtonWrapper: {
    width: "100%",
    marginTop: 10,
  },
  utilityButton: {
    fontSize: 10,
  },
  locationBtn: {
    "& .anticon": {
      fontSize: 12,
    },
  },
  ratingCount: {
    marginLeft: 10,
    fontSize: 12,
  },
  callBtn: {
    fontSize: 10,
  },
  ratings: {
    marginBottom: 5,
  },
  emptyContainer: {
    width: "100%",
    height: "calc(100vh - 250px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
