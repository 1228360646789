import {
  getOrders,
  getRetailStores,
  getCustomerCart,
  getCartItems,
  insertCart,
  getCartLoyaltyInfo,
} from "api/api";
import Tabs from "antd/es/tabs";
import { Row, Col } from "antd/es";
import { Layout, Button, Empty } from "antd";
import { Link } from "react-router-dom";
import Text from "antd/es/typography/Text";
import Loader from "components/loader/Loader";
import FooterMenu from "components/FooterMenu";
import React, { useState, useEffect } from "react";
import BillListView from "components/views/BillListView";
import { useRouteMatch, useLocation } from "react-router-dom";
import { getSession, Session } from "client/reactives/session";
import BillDetailsView from "components/views/BillDetailsView";
import { useStyles } from "components/views/Styles/BillView.style";
import RetailStoreDetailsView from "components/views/RetailerStoreDetailsView";
import BannerCard from "components/cards/BannerCard";

type RouteParams = {
  retailStoreId: string;
};

function BillView() {
  const {
    params: { retailStoreId },
  } = useRouteMatch<RouteParams>() || {};
  const { userId } = getSession() as Session;
  // -------------NEW STATES IN USE-----------------------------
  const classes = useStyles();
  const [bannerLength, setBannerLength] = useState(0);
  const [billData, setBillData] = useState<any>({
    storeDetails: {},
    userBills: [{}],
    selectedBillIndex: -1,
    activeKey: "1",
    loading: true,
    reorder: false,
    reorderItems: [],
    checkBoxClicked: false,
    cartId: 0,
    loyaltyInfo: {},
  });
  let location = useLocation();
  // -----------------------------------------------------------

  function CartDetails(reorderItems: any) {
    var totalItems = reorderItems.length;
    var totalAmount = 0;
    var cartId = reorderItems[0].cart_id;
    for (var i = 0; i < totalItems; i++) {
      totalAmount += +reorderItems[i].total;
    }
    return (
      <div style={{ height: 45, width: "100%" }}>
        <Row
          justify="space-around"
          align="middle"
          style={{ height: "100%", textAlign: "center" }}
        >
          <Col span={6} style={{ fontSize: 12, textAlign: "left" }}>
            <Col>
              <Text>Total Items</Text>
            </Col>
            <Col>
              <Text strong>{totalItems} items</Text>
            </Col>
          </Col>
          <Col span={6} style={{ fontSize: 12, textAlign: "right" }}>
            <Col>
              <Text>Total</Text>
            </Col>
            <Col>
              <Text strong>₹ {totalAmount.toFixed(2)}</Text>
            </Col>
          </Col>
          <Col span={12}>
            <Link
              to={"cart/" + cartId}
              id="view-cart-button"
              data-store={`${billData?.storeDetails?.name} - ${billData?.storeDetails?.id}`}
              data-source="Reorder Footer"
              data-user={userId}
            >
              <Button
                type="primary"
                key="console"
                style={{ width: "80%", fontSize: 12 }}
              >
                View Cart
              </Button>
            </Link>
          </Col>
        </Row>
      </div>
    );
  }

  async function getReorderItems(cartId: number) {
    var cartItems: any;
    cartItems = [];
    if (cartId) {
      cartItems = await getCartItems(cartId);
    }
    return cartItems;
  }

  async function fetchBillInitialData(storeId: number) {
    var storeDetails = await getRetailStores(storeId);
    var orders = await getOrders();
    var cartDetails = await getCustomerCart(storeId);
    var cartItems = [];
    var cartId = 0;
    var selectedBillIndex = 0;
    var activeTabKey = "1";
    var loyaltyPointsInfo = {};
    if (cartDetails.length > 0) {
      cartId = cartDetails[0]["id"];
      cartItems = await getReorderItems(cartId);
      var loyaltyPointsDetail = await getCartLoyaltyInfo(cartId, storeId);
      loyaltyPointsInfo = loyaltyPointsDetail;
    }

    var order_id = new URLSearchParams(location.search).get("order_id");
    var userBills = [];
    for (var i = 0; i < orders.length; i++) {
      if (+orders[i].retail_store_id === storeId) {
        userBills.push(orders[i]);
      }
      if (order_id && orders[i].id === order_id) {
        selectedBillIndex = i;
      }
    }
    if ((order_id && +order_id > 0) || selectedBillIndex > 0) {
      activeTabKey = "2";
    }

    setBillData({
      storeDetails: storeDetails,
      userBills: userBills,
      selectedBillIndex: selectedBillIndex,
      activeKey: activeTabKey,
      loading: false,
      reorder: false,
      reorderItems: cartItems,
      checkBoxClicked: false,
      cartId: cartId,
      loyaltyInfo: loyaltyPointsInfo,
    });
  }

  function setCheckBoxCheck() {
    setBillData({
      storeDetails: billData.storeDetails,
      userBills: billData.userBills,
      selectedBillIndex: billData.selectedBillIndex,
      activeKey: billData.activeKey,
      loading: false,
      reorder: billData.reorder,
      reorderItems: billData.reorderItems,
      checkBoxClicked: !billData.checkBoxClicked,
      cartId: billData.cartId,
      loyaltyInfo: billData.loyaltyInfo,
    });
  }

  function setSelectedBillIndex(index: number) {
    setBillData({
      storeDetails: billData.storeDetails,
      userBills: billData.userBills,
      selectedBillIndex: index,
      activeKey: "2",
      loading: false,
      reorder: billData.reorder,
      reorderItems: billData.reorderItems,
      checkBoxClicked: billData.checkBoxClicked,
      cartId: billData.cartId,
      loyaltyInfo: billData.loyaltyInfo,
    });
  }

  async function switchReorder() {
    var newCartId = billData.cartId;
    if (!billData.reorder && billData.cartId <= 0) {
      const { token, userId } = (await getSession()) as Session;
      var cartData = {
        retail_store_id: retailStoreId,
        payment_status: false,
        discount: 0,
        gst: 0,
        price_with_gst: 0,
        price_with_out_gst: 0,
        user_id: userId,
      };
      // Create the cart
      const data = await insertCart(cartData);
      if (data.length > 0) {
        newCartId = data[0].id;
      }
    }
    setTimeout(() => {
      setBillData({
        storeDetails: billData.storeDetails,
        userBills: billData.userBills,
        selectedBillIndex: billData.selectedBillIndex,
        activeKey: billData.activeKey,
        loading: false,
        reorder: !billData.reorder,
        reorderItems: billData.reorderItems,
        checkBoxClicked: billData.checkBoxClicked,
        cartId: newCartId,
        loyaltyInfo: billData.loyaltyInfo,
      });
    }, 100);
  }

  async function updateCartItems() {
    var cartItems = await getReorderItems(billData.cartId);
    setBillData({
      storeDetails: billData.storeDetails,
      userBills: billData.userBills,
      selectedBillIndex: billData.selectedBillIndex,
      activeKey: billData.activeKey,
      loading: false,
      reorder: billData.reorder,
      reorderItems: cartItems,
      checkBoxClicked: billData.checkBoxClicked,
      cartId: billData.cartId,
      loyaltyInfo: billData.loyaltyInfo,
    });
  }

  useEffect(() => {
    if (billData.loading === true) {
      fetchBillInitialData(+retailStoreId);
    }
  }, []);

  useEffect(() => {
    if (billData.loading === false) {
      updateCartItems();
    }
  }, [billData.checkBoxClicked]);

  return (
    <>
      <Layout.Content>
        {!billData.loading ? (
          <div
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            {Object.keys(billData.storeDetails).length > 0 ? (
              <RetailStoreDetailsView
                retailStore={billData.storeDetails}
                itemsCount={billData.reorderItems.length}
              />
            ) : (
              "No Store Info Data Provided"
            )}
            <div style={{ marginBottom: 10, padding: "10px 10px 0" }}>
              <BannerCard
                page="bill_details_top"
                storeId={+billData.storeDetails?.id}
                setBannersLength={setBannerLength}
              />
            </div>

            {billData.userBills.length > 0 ? (
              <Tabs
                activeKey={billData.activeKey}
                centered
                className={classes.tabs}
                onChange={(key) => {
                  var reorderValue = key === "1" ? false : billData.reorder;
                  setBillData({
                    storeDetails: billData.storeDetails,
                    userBills: billData.userBills,
                    selectedBillIndex: billData.selectedBillIndex,
                    activeKey: key as string,
                    loading: false,
                    reorder: reorderValue,
                    reorderItems: billData.reorderItems,
                    checkBoxClicked: billData.checkBoxClicked,
                    cartId: billData.cartId,
                    loyaltyInfo: billData.loyaltyInfo,
                  });
                }}
                style={{ height: "80%" }}
              >
                <Tabs.TabPane
                  tab={`All Bills (${billData.userBills.length})`}
                  key="1"
                >
                  <BillListView
                    bannerLength={bannerLength}
                    billData={billData}
                    setSelectedBillIndex={setSelectedBillIndex}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Itemized Bill" key="2">
                  {billData.selectedBillIndex >= 0 &&
                  billData.userBills.length > 0 ? (
                    <BillDetailsView
                      bannerLength={bannerLength}
                      billData={billData}
                      retailStore={billData.storeDetails}
                      switchReorder={switchReorder}
                      order={billData.userBills[billData.selectedBillIndex]}
                      setCheckBoxCheck={setCheckBoxCheck}
                    />
                  ) : (
                    "No Selected Bill"
                  )}
                </Tabs.TabPane>
              </Tabs>
            ) : (
              <Empty
                description="You have no bills yet"
                className={classes.noBillsMockup}
              />
            )}
          </div>
        ) : (
          <Loader />
        )}
      </Layout.Content>
      <Layout.Footer className={classes.footer}>
        {billData.reorder && billData.reorderItems.length > 0 ? (
          <div className={classes.totalFooter}>
            {CartDetails(billData.reorderItems)}
          </div>
        ) : (
          <FooterMenu retailStoreId={retailStoreId} />
        )}
      </Layout.Footer>
    </>
  );
}

export default BillView;
