import Button from "antd/es/button";
import Input from "antd/es/input";
import React, { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import type { CreateSessionInput } from "generated/schema";

import Field from "components/field/Field";
import useForm from "hooks/useForm";
import User from "models/User";
import type { FormProps } from "hooks/useForm";
import { createUseStyles } from "react-jss";
import hideNumberInputArrows from "components/style/hideNumberInputArrows";

type LoginPageProps = FormProps<CreateSessionInput> & {
  isAdmin?: boolean;
  setMobileNumber: (val: any) => any;
};

function LoginForm({ isAdmin, setMobileNumber, ...rest }: LoginPageProps) {
  const { isSubmitting, methods, submitHandler } = useForm<CreateSessionInput>({
    ...rest,
    resolver: isAdmin ? User.validateAdminLogin() : User.validateLogin(),
  });
  const classes = useStyles();

  useEffect(() => {
    if (!!methods.watch().mobileNumber) {
      setMobileNumber(methods.watch().mobileNumber);
    }
  }, [methods.watch().mobileNumber]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={submitHandler} className={classes.loginForm}>
        <Field as="input" name="isAdmin" type="hidden" />

        {isAdmin ? (
          <Field as={Input} name="email" label="Email" placeholder="Email" />
        ) : (
          <Field
            as={Input}
            name="mobileNumber"
            type="number"
            label="Mobile Number"
          />
        )}

        <Field
          as={Input.Password}
          name="password"
          label="Password"
          type="password"
        />

        <Button type="primary" htmlType="submit" loading={isSubmitting} block>
          Log in
        </Button>
      </form>
    </FormProvider>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  loginForm: {
    ...hideNumberInputArrows,
  },
}));

export default LoginForm;
