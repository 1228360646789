import React from "react";
import { Layout } from "antd";
import FooterMenu from "components/FooterMenu";
import { useRouteMatch } from "react-router-dom";
import ComingSoonView from "components/views/ComingSoonView";

type RouteParams = {
    retailStoreId: string;
};

function StatsPage() {
    const {
        params: { retailStoreId },
    } = useRouteMatch<RouteParams>() || {};

    return (
        <>
            <Layout.Content>
                <ComingSoonView image={process.env.PUBLIC_URL + "/Group499.png"} />
            </Layout.Content>
            <Layout.Footer style={{ padding: 0 }} >
                <FooterMenu retailStoreId={retailStoreId} />
            </Layout.Footer>
        </>

    );
}

export default StatsPage;
