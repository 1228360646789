import { Col, Row } from "antd/es/grid";
import ChatListCard from "./chatListCard";
import Loader from "components/loader/Loader";
import { useStyles } from "./ChatPage.styles";
import { Channel } from "twilio-chat/lib/channel";
import React, { useEffect, useState } from "react";
import CustomerDetails from "../IncomingOrders/CustomerDetails";
import ChatExpandedCard from "components/cards/ChatExpandedCard";
import { fetchRetailerOrders } from "components/pages/retailerDashboard/APIs/fetchRetailerOrders";
import { fetchReorderCustomers } from "components/pages/retailerDashboard/APIs/fetchReorderCustomers";
import { changeCustomerPriority } from "api/retailerApi";

type ChatPageProps = {
  client: any;
  retailerData: {
    ID: number;
    Name: string;
  };
};

function ChatPage({ client, retailerData }: ChatPageProps) {
  const classes = useStyles();
  // -------------NEW STATES IN USE-----------------------------
  const [chatData, setChatData] = useState({
    customers: [],
    priorityCustomers: [],
    selectedCustomerIndex: -1,
    loading: true,
  });
  const [customerCategory, setCustomerCategory] = useState("All");
  const [channelName, setChannelName] = useState<Channel>();
  // -----------------------------------------------------------

  const toggleCustomerPriorityCallback = (
    isPriorityNow: boolean,
    customer_id: any
  ) => {
    let newData: any = {};
    if (isPriorityNow) {
      newData.customers = chatData.customers.map((item: any) =>
        item.user_id === customer_id ? { ...item, priority: true } : item
      );
    } else {
      newData.customers = chatData.customers.map((item: any) =>
        item.user_id === customer_id ? { ...item, priority: false } : item
      );
    }
    newData.priorityCustomers = newData.customers.filter(
      (item: any) => item.priority
    );

    console.log(newData);
    setChatData({ ...chatData, ...newData });
  };

  const toggleCustomerPriority = async (customerUserId: any) => {
    if (customerUserId !== "")
      await changeCustomerPriority(customerUserId, retailerData?.ID).then(
        (result) => {
          toggleCustomerPriorityCallback(
            result.priority.includes(`${retailerData?.ID}`),
            result.user_id
          );
        }
      );
  };

  const handleChannelChange = async () => {
    if (
      client !== undefined &&
      retailerData.ID > 0 &&
      chatData.customers.length > 0
    ) {
      var customerID =
        chatData.customers[chatData.selectedCustomerIndex]["user_id"];
      var customerName =
        chatData.customers[chatData.selectedCustomerIndex]["user_name"];
      if (customerCategory !== "All" && chatData.priorityCustomers.length > 0) {
        customerID =
          chatData.priorityCustomers[chatData.selectedCustomerIndex]["user_id"];
        customerName =
          chatData.priorityCustomers[chatData.selectedCustomerIndex][
            "user_name"
          ];
      }
      const channelUniqueName = `${retailerData.ID}_${customerID}`;
      const channelFriendlyName = `${retailerData.Name}_to_${customerName}`;

      if (channelUniqueName || channelFriendlyName) {
        joinOrCreateChannel(channelUniqueName, channelFriendlyName);
      }
    }
  };

  const joinChannel = async (channel: any) => {
    if (channel.channelState.status !== "joined") {
      await channel.join();
    }
  };

  async function joinOrCreateChannel(
    channelUniqueName: string,
    channelFriendlyName: string
  ) {
    try {
      const channel = await client.getChannelByUniqueName(channelUniqueName);
      joinChannel(channel);
      setChannelName(channel);
    } catch (err) {
      try {
        const channel = await client.createChannel({
          uniqueName: channelUniqueName,
          friendlyName: channelFriendlyName,
        });
        joinChannel(channel);
        setChannelName(channel);
      } catch {
        console.log("Unable to create channel, please reload this page");
        alert("Something went wrong, please reload !");
        // throw new Error("Unable to create channel, please reload this page");
      }
    }
  }

  function filterCustomers(reorderData: any, orderData: any) {
    var allData = reorderData.concat(orderData);
    var allDataMap = new Map();
    var finalData: any = [];
    for (var i = 0; i < allData.length; i++) {
      if (allDataMap.has(allData[i].user_id)) {
        var total = allDataMap.get(allData[i].user_id).grandtotal;
        if (+allData[i].total) {
          total += +allData[i].total;
        } else if (+allData[i].grandtotal) {
          total += +allData[i].grandtotal;
        }
        allDataMap.get(allData[i].user_id).grandtotal = total;
        continue;
      }
      allData[i].key = 1;
      allDataMap.set(allData[i].user_id, {
        updated_at: allData[i].updated_at,
        user_id: allData[i].user_id,
        user_name: allData[i].user_name,
        priority: allData[i].priority,
        retail_store_id: allData[i].retail_store_id,
        id: allData[i].id,
        grandtotal: +allData[i].total | +allData[i].grandtotal,
        created_at: allData[i].created_at,
      });
    }
    allDataMap.forEach((value: any, key: any) => {
      finalData.push(value);
    });
    return finalData;
  }

  function filterCustomersCategory(customers: any, category: string) {
    if (category === "All") {
      return customers;
    }
    var priorityCustomers = [];
    for (var i = 0; i < customers?.length; i++) {
      if (customers[i].priority) {
        priorityCustomers.push(customers[i]);
      }
    }
    return priorityCustomers;
  }

  function addReorderKeyValue(data: any, reorder: boolean) {
    for (var i = 0; i < data.length; i++) {
      data[i].reorder = reorder ? "Yes" : "No";
      data[i].grandtotal = data[i].total | data[i].grandtotal;
      var dateTime = new Date(data[i].created_at);
      var date =
        dateTime.getDate() < 10 ? "0" + dateTime.getDate() : dateTime.getDate();
      var month =
        dateTime.getMonth() < 10
          ? "0" + dateTime.getMonth()
          : dateTime.getMonth();
      data[i].date = date + "." + month + "." + dateTime.getFullYear();
    }
    return data;
  }

  async function fetchInitialData() {
    var reorderData = await fetchReorderCustomers(retailerData.ID);
    var orderData = await fetchRetailerOrders(retailerData.ID, false);
    reorderData = addReorderKeyValue(reorderData, true);
    orderData = addReorderKeyValue(orderData, false);
    var allCustomers = filterCustomers(reorderData, orderData);
    var priorityCustomers = filterCustomersCategory(allCustomers, "Priority");
    setChatData({
      customers: allCustomers,
      priorityCustomers: priorityCustomers,
      selectedCustomerIndex: 0,
      loading: false,
    });
  }

  useEffect(() => {
    if (retailerData.ID && chatData.loading === true) {
      fetchInitialData();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retailerData.ID]);

  useEffect(() => {
    handleChannelChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, chatData.selectedCustomerIndex]);

  useEffect(() => {
    if (chatData.loading === false) {
      setChatData({
        customers: chatData.customers,
        priorityCustomers: chatData.priorityCustomers,
        selectedCustomerIndex: 0,
        loading: false,
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerCategory]);

  return (
    <>
      {!chatData.loading && channelName ? (
        <div className={classes.chatPage}>
          <div className={classes.pageHeader}>
            <div className={classes.pageTitle}>Chat With Customer</div>
          </div>
          {chatData.customers.length > 0 ||
          chatData.priorityCustomers.length > 0 ? (
            <Row className={classes.cards}>
              <Col lg={6} xxl={4} className={classes.cardWrapper}>
                <ChatListCard
                  customers={chatData.customers}
                  customerCategory={customerCategory}
                  setCustomerCategory={setCustomerCategory}
                  priorityCustomers={chatData.priorityCustomers}
                  selectedCustomerIndex={chatData.selectedCustomerIndex}
                  setChatData={setChatData}
                  toggleCustomerPriority={toggleCustomerPriority}
                />
              </Col>
              <Col lg={12} xxl={14} className={classes.cardWrapper}>
                <ChatExpandedCard
                  retailStoreId={+retailerData.ID}
                  chatName={
                    customerCategory === "All"
                      ? chatData.customers.length > 0 &&
                        chatData.customers.length >
                          chatData.selectedCustomerIndex
                        ? chatData.customers[chatData.selectedCustomerIndex][
                            "user_name"
                          ]
                        : ""
                      : chatData.priorityCustomers.length > 0 &&
                        chatData.priorityCustomers.length >
                          chatData.selectedCustomerIndex
                      ? chatData.priorityCustomers[
                          chatData.selectedCustomerIndex
                        ]["user_name"]
                      : ""
                  }
                  channel={channelName}
                />
              </Col>
              <Col
                lg={6}
                className={`${classes.cardWrapper} ${classes.lastWrapper}`}
              >
                {(customerCategory === "All" &&
                  chatData.customers.length > 0) ||
                (customerCategory === "Priority" &&
                  chatData.priorityCustomers.length > 0) ? (
                  <CustomerDetails
                    fullHeight
                    customerId={
                      customerCategory === "All"
                        ? chatData.customers.length > 0 &&
                          chatData.customers.length >
                            chatData.selectedCustomerIndex
                          ? chatData.customers[chatData.selectedCustomerIndex][
                              "user_id"
                            ]
                          : 0
                        : chatData.priorityCustomers.length > 0 &&
                          chatData.priorityCustomers.length >
                            chatData.selectedCustomerIndex
                        ? chatData.priorityCustomers[
                            chatData.selectedCustomerIndex
                          ]["user_id"]
                        : 0
                    }
                    customerName={
                      customerCategory === "All"
                        ? chatData.customers.length > 0 &&
                          chatData.customers.length >
                            chatData.selectedCustomerIndex
                          ? chatData.customers[chatData.selectedCustomerIndex][
                              "user_name"
                            ]
                          : ""
                        : chatData.priorityCustomers.length > 0 &&
                          chatData.priorityCustomers.length >
                            chatData.selectedCustomerIndex
                        ? chatData.priorityCustomers[
                            chatData.selectedCustomerIndex
                          ]["user_name"]
                        : ""
                    }
                    isPriority={
                      customerCategory === "All"
                        ? chatData.customers.length > 0 &&
                          chatData.customers.length >
                            chatData.selectedCustomerIndex
                          ? chatData.customers[chatData.selectedCustomerIndex][
                              "priority"
                            ]
                          : false
                        : chatData.priorityCustomers.length > 0 &&
                          chatData.priorityCustomers.length >
                            chatData.selectedCustomerIndex
                        ? chatData.priorityCustomers[
                            chatData.selectedCustomerIndex
                          ]["priority"]
                        : false
                    }
                    retailStoreId={+retailerData.ID}
                    toggleCustomerPriority={toggleCustomerPriority}
                  />
                ) : (
                  ""
                )}
              </Col>
            </Row>
          ) : (
            <div className={classes.centreText}>No Customers</div>
          )}
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default ChatPage;
