import React from "react";
import { createUseStyles } from "react-jss";

type DetailItemProps = {
  label: string;
  content?: string;
  icon: React.ReactNode;
  iconBackground: string;
  suffix?: any;
};

const DetailListItem = ({
  label,
  content,
  icon,
  iconBackground,
  suffix,
}: DetailItemProps) => {
  const classes = useStyles();

  return (
    <div className={classes.detailItemWrapper}>
      <div
        className={classes.detailIcon}
        style={{ background: iconBackground }}
      >
        {icon}
      </div>
      <div className={classes.itemRight}>
        <div className={classes.detailLabel}>{label}</div>
        <div className={classes.itemContent}>{content}</div>
      </div>
      <div className={classes.suffix}>{suffix}</div>
    </div>
  );
};

const useStyles = createUseStyles(({ colors }: Theme) => ({
  detailItemWrapper: {
    padding: "5px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  detailIcon: {
    width: 36,
    minWidth: 36,
    height: 36,
    margin: "0.7rem 1rem 0.7rem 0.5rem",
    fontSize: "1.3rem",
    color: colors.light100,
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  detailLabel: {
    fontWeight: 700,
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontSize: 12,
    color: "#505050",
    lineHeight: "14px",
  },
  itemContent: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 12,
    lineHeight: "20px",
  },
  suffix: {
    marginLeft: "auto",
  },
  itemRight: {},
  "@media (max-width: 1050px)": {
    detailIcon: {
      width: 30,
      minWidth: 30,
      height: 30,
      fontSize: "1.1rem",
    },
    itemRight: {
      fontSize: 12,
      wordBreak: "break-all",
    },
    suffix: {
      fontSize: "0.75rem",
      "& .anticon": {
        fontSize: "0.9rem",
      },
    },
  },
}));

export default DetailListItem;
