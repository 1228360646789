import {
  HomeOutlined,
  PhoneOutlined,
  EnvironmentOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import Row from "antd/es/row";
import { Avatar, Badge, Collapse } from "antd";
import Col from "antd/es/grid/col";
import Button from "antd/es/button";
import { Link } from "react-router-dom";
import { getCustomerCart, getCartItems } from "api/api";
import Text from "antd/es/typography/Text";
import constants from "constants/constants";
import ChatModal from "components/chat/ChatModal";
import React, { useState, useEffect } from "react";
import Paragraph from "antd/es/typography/Paragraph";
import DrawerSidebar from "components/drawer/Drawer";
import { useStyles } from "components/views/Styles/RetailerStoreDetailsView.style";
import { getSession, Session } from "client/reactives/session";

const getAcronym = (text: any) => {
  let acronym = text
    .split(/\s/)
    .reduce((response: any, word: any) => (response += word.slice(0, 1)), "");
  return acronym.toLocaleUpperCase();
};

const { Panel } = Collapse;
type RetailStoreDetailsViewProps = {
  retailStore: any;
  noBorder?: boolean;
  collapsed?: boolean;
  hideChatModal?: boolean;
  noHeaderOptions?: boolean;
  hideAddress?: boolean;
  itemsCount?: any;
  logoUrl?: string;
  countChange?: boolean;
  setCountChange?: any | null;
  onStoreChange?: () => void;
};

function RetailStoreDetailsView({
  retailStore,
  noBorder = false,
  collapsed = true,
  hideChatModal = false,
  noHeaderOptions = false,
  countChange = false,
  setCountChange = null,
  hideAddress = false,
  itemsCount,
  logoUrl,
  onStoreChange,
}: RetailStoreDetailsViewProps) {
  const { userId } = getSession() as Session;
  // -------------NEW STATES IN USE-----------------------------
  const classes = useStyles({ noBorder });
  const [localityState, setLocalityState] = useState(collapsed);
  const [cartId, setCartId] = useState(0);
  const [logoLoaded, setLogoLoaded] = useState(false);
  const [cartItemsCount, setCartItemsCount] = useState(
    itemsCount ? itemsCount : 0
  );
  // -----------------------------------------------------------

  const toggleLocalityState = () => {
    setLocalityState(!localityState);
  };

  const rendermap = (store_location: string) => (
    <Button
      type="link"
      key={store_location}
      href={`${store_location}`}
      id="see-on-map-button"
      data-store={`${retailStore.name} - ${retailStore.id}`}
      data-user={userId}
      className={classes.contact}
    >
      See On Map
    </Button>
  );

  const renderContact = (number: string) => (
    <Button
      type="link"
      key={number}
      href={`tel:+91${number}`}
      id="call-button"
      data-store={`${retailStore.name} - ${retailStore.id}`}
      data-source="Retailer Details"
      data-user={userId}
      className={classes.contact}
    >
      {number}
    </Button>
  );

  const address = (address: any, landmark: any) => (
    <Col span={23} style={{ paddingLeft: "0 !important" }}>
      <section className={classes.section}>
        <HomeOutlined className={classes.iconColor} />
        <Paragraph
          style={{ marginLeft: 7, marginBottom: 0, paddingRight: 15 }}
          ellipsis={{ rows: 2, expandable: false, symbol: "..." }}
        >
          {localityState ? landmark : address}
        </Paragraph>
      </section>
    </Col>
  );

  const collapse = (number: string, store_location: string) => (
    <Row justify="space-between">
      <section>
        <EnvironmentOutlined className={classes.iconColor} />
        <Text style={{ marginLeft: 12 }}>{rendermap(store_location)}</Text>
      </section>
      <section>
        <PhoneOutlined className={classes.iconColor} rotate={90} />
        <Text style={{ marginLeft: 12 }}>{renderContact(number)}</Text>
      </section>
    </Row>
  );

  async function fetchCartId() {
    var cartDetails = await getCustomerCart(retailStore.id);
    var cart_id = 0;
    if (cartDetails.length > 0) {
      cart_id = +cartDetails[0].id;
    }
    if (cart_id > 0 && !itemsCount) {
      let cartItems = await getCartItems(cart_id);
      setCartItemsCount(cartItems.length);
    } else if (itemsCount > 0) {
      setCartItemsCount(itemsCount);
    }
    setCartId(cart_id);
  }

  useEffect(() => {
    if ((cartId <= 0 && !hideChatModal) || countChange) {
      fetchCartId();
      if (countChange) {
        setCountChange(false);
      }
    }
  }, [countChange, cartId, hideChatModal, cartItemsCount]);

  useEffect(() => {
    if (itemsCount >= 0) {
      setCartItemsCount(itemsCount);
    }
  }, [itemsCount]);

  return (
    <div style={{ background: "#fff", paddingBottom: 2 }}>
      <Row
        justify="start"
        align="middle"
        className="shopHeader"
        style={{ padding: "0.6rem 0.8rem 0.3rem 1rem" }}
      >
        {!noHeaderOptions && (
          <Col span={3} md={2}>
            <DrawerSidebar onStoreChange={onStoreChange} />
          </Col>
        )}
        <Col span={3} md={logoUrl ? 3 : 2} className={classes.logoWrapper}>
          <img
            hidden={!logoLoaded}
            onLoad={() => setLogoLoaded(true)}
            src={
              logoUrl ? logoUrl : constants.s3Url + retailStore.logo_data.key
            }
          />
          {!logoLoaded && (
            <Avatar size="large" shape="square">
              {getAcronym(retailStore.name?.split(" ")[0])}
            </Avatar>
          )}
        </Col>
        <Col span={12} md={16} style={{ paddingLeft: 10 }}>
          <Text strong className={classes.storeName}>
            {retailStore.name}
          </Text>
          {console.log(retailStore)}
          {!!retailStore.authenticity_document_value && (
            <Text className={classes.regdNo}>
              {retailStore.authenticity_document_type} :&nbsp;
              {retailStore.authenticity_document_value}
            </Text>
          )}
        </Col>
        <Col span={6} md={4} className={classes.headerOptions}>
          {!hideChatModal ? (
            <ChatModal
              retailStoreId={retailStore.id}
              retailerName={retailStore.name}
            />
          ) : (
            ""
          )}
          {!noHeaderOptions && (
            <Badge size="small" count={cartItemsCount}>
              <Link
                to={"/dashboard/stores/" + retailStore.id + "/cart/" + cartId}
                id="view-cart-button"
                data-store={`${retailStore.name} - ${retailStore.id}`}
                data-source="Header Cart Button"
                data-user={userId}
              >
                <ShoppingCartOutlined style={{ fontSize: 21.5 }} />
              </Link>
            </Badge>
          )}
        </Col>
      </Row>
      {!hideAddress && (
        <Collapse
          expandIconPosition="right"
          ghost
          bordered={false}
          className={classes.collapse}
          defaultActiveKey={collapsed ? "" : 1}
          onChange={toggleLocalityState}
        >
          <Panel
            header={address(
              (retailStore.line1 ? retailStore.line1 + ", " : "") +
                (retailStore.line2 ? retailStore.line2 + ", " : "") +
                (retailStore.landmark ? retailStore.landmark + ", " : "") +
                (retailStore.city ? retailStore.city + ", " : "") +
                (retailStore.state
                  ? retailStore.state + ", "
                  : retailStore.state) +
                retailStore.pincode,
              retailStore.landmark
            )}
            key="1"
          >
            <div>
              {collapse(
                retailStore.primary_mobile_number,
                retailStore.store_location
              )}
            </div>
          </Panel>
        </Collapse>
      )}
    </div>
  );
}

export default RetailStoreDetailsView;
