import { createContext } from 'react'

import type { AdminUser, RetailStore, User } from 'generated/schema'

type InternalContextProps = {
  currentAdminUser?: Partial<AdminUser>,
  currentRetailStore?: RetailStore,
  currentUser?: Partial<User>,
  retailStores?: RetailStore[]
}

const InternalContext = createContext<Partial<InternalContextProps>>({})

export default InternalContext
