import classNames from 'classnames'
import React from 'react'
import { createUseStyles } from 'react-jss'

type FormGroupProps = React.PropsWithChildren<{
  align?: 'center' | 'left' | 'right'
}>

const useStyles = createUseStyles({
  formGroup: {
    display: 'flex',

    '& > div': {
      flex: 1,
      marginRight: 24
    },

    '& > div:last-of-type': {
      marginRight: 0
    }
  },
  align_center: {
    justifyContent: 'center'
  }
})

function FormGroup({ align = 'left', children }: FormGroupProps) {
  const classes = useStyles()

  return (
    <div className={classNames(classes.formGroup, {
      [classes.align_center]: align === 'center'
    })}
    >
      {children}
    </div>
  )
}

export default FormGroup
