import { RetailerDataType } from "components/pages/retailerDashboard";
import React from "react";
import BottomMenu from "./BottomMenu";
import Topbar from "./Topbar";

interface LayoutProps {
  children: React.ReactNode;
  retailerData: RetailerDataType;
}

export default function Layout({ children, retailerData }: LayoutProps) {
  return (
    <div style={{ paddingTop: 65, fontFamily: "Roboto" }}>
      <Topbar retailerData={retailerData} />
      {children}
      <BottomMenu />
    </div>
  );
}
