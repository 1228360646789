import Button from "antd/es/button";
import Input from "antd/es/input";
import React from "react";
import { FormProvider } from "react-hook-form";

import Field from "components/field/Field";
import FormBox from "components/FormBox";
import useForm from "hooks/useForm";
import User from "models/User";
import type { FormProps } from "hooks/useForm";
import { createUseStyles } from "react-jss";
import hideNumberInputArrows from "components/style/hideNumberInputArrows";

type RequestMobileConfirmFormProps = FormProps<any> & {
  title?: string;
  withoutName?: boolean;
};

function RequestMobileConfirmForm({
  withoutName = false,
  title = "Register to Raseet",
  ...rest
}: RequestMobileConfirmFormProps) {
  const { isSubmitting, methods, submitHandler } = useForm<any>({
    ...rest,
    resolver: User.requestMobileConfirm(withoutName),
  });

  const classes = useStyles();

  return (
    <FormBox showBackButton title={title}>
      <FormProvider {...methods}>
        <form
          onSubmit={submitHandler}
          className={classes.requestMobileConfirmForm}
        >
          {!withoutName && <Field name="name" as={Input} label="Full name" />}
          <Field
            name="mobileNumber"
            as={Input}
            type="number"
            label="Mobile Number"
          />
          <Button type="primary" htmlType="submit" loading={isSubmitting} block>
            Send OTP
          </Button>
        </form>
      </FormProvider>
    </FormBox>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  requestMobileConfirmForm: {
    ...hideNumberInputArrows,
  },
}));

export default RequestMobileConfirmForm;
