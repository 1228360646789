import React from "react";
import { createUseStyles } from "react-jss";

type ProgressBarItemProps = {
  label: string;
  count: number;
  value: number;
};

type ProgressBarListCardProps = {
  cardTitle: string;
  headerSuffix?: React.ReactNode;
  list: any[];
};

function ProgressBarListCard({
  cardTitle,
  headerSuffix,
  list,
}: ProgressBarListCardProps) {
  const classes = useStyles();

  const ProgressBarItem = ({ label, count, value }: ProgressBarItemProps) => (
    <div className={classes.progressBarItem}>
      <div className={classes.itemHeader}>
        <label htmlFor={label} className={classes.ItemLabel}>
          {label}
        </label>
        <span className={classes.ItemCount}>{count}</span>
      </div>
      <div className={classes.progressBar}>
        <progress id={label} max="100" value={value}></progress>
      </div>
    </div>
  );

  return (
    <div className={classes.progressBarListCard}>
      <div className={classes.cardHeader}>
        <div className={classes.cardTitle}>{cardTitle}</div>
        <div className={classes.headerSuffix}>{headerSuffix}</div>
      </div>
      <div className={classes.progressBarList}>
        {list.length > 0 ? (
          <>
            {list.map((item, index) => (
              <ProgressBarItem key={index} {...item} />
            ))}
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  progressBarListCard: {
    width: "100%",
    // height: "100%",
    padding: "1rem 2rem",
    borderRadius: 5,
    backgroundColor: "#E5E5E5",
  },
  cardHeader: {
    minHeight: 40,
    marginBottom: "0.5rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cardTitle: {
    fontWeight: 600,
    fontSize: 14,
  },
  headerSuffix: {},
  progressBarList: {},
  progressBarItem: {
    margin: "1rem 0",
  },
  itemHeader: {
    display: "flex",
    justifyContent: "space-between",
  },

  ItemLabel: {
    fontWeight: 500,
    fontSize: 12,
  },
  ItemCount: {
    fontWeight: 500,
    paddingRight: 5,
    fontSize: 12,
  },
  progressBar: {
    "& progress": {
      width: "100%",
      height: "5px",
      color: colors.light100,
      background: colors.light100,
      borderRadius: "4.5px",
      border: "none",
      overflow: "hidden",
      "-webkit-appearance": "none",
      appearance: "none",

      "&::-webkit-progress-value": {
        background: "#E76F51",
        borderRadius: 2.5,
        overflow: "hidden",
      },
      "&::-webkit-progress-bar": {
        background: colors.light100,
        borderRadius: 4.5,
        overflow: "hidden",
      },
    },
  },
  "@media (max-width: 1050px)": {
    itemHeader: {
      fontSize: 12,
    },
    progressBar: {
      "& progress": {
        height: 4,
      },
    },
  },
}));

export default ProgressBarListCard;
