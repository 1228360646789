import React, { useState, useEffect } from "react";
import { createUseStyles } from "react-jss";
import {
  Card,
  Row,
  Divider,
  Col,
  Checkbox,
  Input,
  Typography,
  Button,
  message,
} from "antd";
import { FormProvider } from "react-hook-form";
import useForm from "hooks/useForm";
import Configs from "models/Configs";
import Field from "components/field/Field";
import { setShipping } from "api/api";

export default function ShippingConfigsForm({
  retailerData,
  checkBoxChecked,
  ...props
}: any) {
  const classes = useStyles();
  const [shippingChargeApplicable, setShippingChargeApplicable] =
    useState(checkBoxChecked);

  const handleShippingChargeSubmit = async (values: any) => {
    await setShipping({
      retail_store_id: retailerData.ID + "",
      shipping_charge_applicable: shippingChargeApplicable + "",
      shipping_charge: values?.shipping_charge_applicable,
      minimum_order_amount: values?.minimum_order_amount,
    }).then((res) => message.success("Shipping configs updated successfully!"));
  };

  const { isSubmitting, methods, submitHandler, reset } = useForm<any>({
    ...props,
    onSubmit: handleShippingChargeSubmit,
    resolver: Configs.shippingCharges(),
  });

  return (
    <Card className={classes.shippingChargesWrapper}>
      <Typography.Text className={classes.shippingChargesTitle}>
        Shipping Charges
      </Typography.Text>

      <Divider />

      <div className={classes.checkboxWrapper}>
        <Checkbox
          checked={shippingChargeApplicable}
          onChange={(e) => setShippingChargeApplicable(e.target.checked)}
        />
        <Typography.Text className={classes.checkboxLabel}>
          Is shipping charge applicable?
        </Typography.Text>
      </div>

      <FormProvider {...methods}>
        <form onSubmit={submitHandler}>
          <Row gutter={[10, 10]} className={classes.inputsWrapper}>
            <Col span={12}>
              <span>Minimum order amount</span>
              <Field
                as={Input}
                name="minimum_order_amount"
                disabled={!shippingChargeApplicable}
              />
            </Col>
            <Col span={12}>
              <span>Shipping charge</span>
              <Field
                as={Input}
                name="shipping_charge_applicable"
                disabled={!shippingChargeApplicable}
              />
            </Col>
          </Row>

          <Col span={12}>
            <Button
              block
              type="primary"
              htmlType="submit"
              loading={isSubmitting}
            >
              Save
            </Button>
          </Col>
        </form>
      </FormProvider>
    </Card>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  configsPage: {
    padding: "2rem",
  },
  shippingChargesWrapper: {
    padding: "0.5rem",
    width: 400,
    boxShadow:
      "-1px -1px 2px rgba(0, 0, 0, 0.1), 1px 1px 2px rgba(0, 0, 0, 0.1)",
  },
  shippingChargesTitle: {
    color: "#505050",
    fontWeight: 500,
    fontSize: 18,
    display: "block",
    marginBottom: -10,
  },
  checkboxLabel: {
    marginLeft: 10,
  },
  checkboxWrapper: {
    margin: [20, 0],
  },
  inputsWrapper: {
    margin: [30, 0, 5],
  },
}));
