import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { message } from "antd";
import OnboardingWrapper from "components/OnboardingWrapper";
import RegisterForm from "components/forms/RegisterForm";
import useSubmitHandler from "hooks/useSubmitHandler";
import RequestMobileConfirmForm from "components/forms/RequestMobileConfirmForm";
import ConfirmMobileForm from "components/forms/ConfirmMobileFrom";
import {
  useConfirmMobileNumberMutation,
  useCreateUserMutation,
  useRequestMobileNumberConfirmationMutation,
} from "generated/schema";
import type { CreateUserInput } from "generated/schema";
import CustomerRegisterUserForm from "components/forms/CustomerRegisterUserForm";
import { createOtp, insertUsers, validateOtp } from "api/api";
import { values } from "lodash";

type CustomerRegisterUserViewProps = {
  onComplete: (
    token: string,
    user_id: string,
    user_type: string,
    user_name: string,
    mobile_number: string
  ) => void;
};

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function CustomerRegisterUserView({
  onComplete,
}: CustomerRegisterUserViewProps) {
  const query: any = useQuery();
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [wrongOtp, setWrongOtp] = useState(false);
  const [resend, setResend] = useState(false);
  const [moboError, setMoboError] = useState(false);
  const [mobile, setMobile] = useState();
  const [name, setName] = useState();
  const [retailStoreCode, setRetailStoreCode] = useState();

  const resetOtpStates = () => {
    setOtpSent(false);
    setOtpVerified(false);
    setWrongOtp(false);
    setResend(false);
    setMoboError(false);
  };

  async function sendOtp(mobile: any) {
    let data = {
      mobile_number: mobile,
      otp_type: `'Registration'`,
    };
    let OTP = await createOtp(data);
    console.log(OTP);
    if (OTP?.status === "success") {
      setOtpSent(true);
      setMoboError(false);
      setResend(false);
    } else if (OTP?.status === "failure") {
      if (!otpSent) {
        failure(3000, OTP?.error);
      } else if (otpSent) {
        success(15000, OTP?.error);
      }
      // message.info("Request another OTP in 15 seconds");
    }
  }

  async function resendOtp({ confirmPassword, ...values }: any) {
    let data = {
      mobile_number: mobile,
      otp_type: `'Registration'`,
    };

    await createOtp(data).then((res) => {
      if (res?.status === "success") {
        success(3000, res.message);
        setOtpSent(true);
        setMoboError(false);
        setResend(false);
      } else if (res?.status === "failure") {
        success(15000, res.error);
      }
    });
  }

  const success = (t: any, msg: any) => {
    const hide = message.loading(msg, 0);
    // Dismiss manually and asynchronously
    setTimeout(hide, t);
  };

  const failure = (t: any, err: any) => {
    const h = message.error(err, 0);
    // Dismiss manually and asynchronously
    setTimeout(h, t);
  };

  useEffect(() => {
    if(retailStoreCode !== query.get("retail_store_code")) {setRetailStoreCode(query.get("retail_store_code"))}
  }, [retailStoreCode])

  async function checkOtp(otpInput: any, mobile: any) {
    let data = {
      otp: otpInput,
      mobile_number: mobile,
      otp_type: `'Registration'`,
    };
    const res = await validateOtp(data);
    if (res?.status === "success") {
      setOtpVerified(true);
      setWrongOtp(false);

      //return true
    } else {
      if (res?.error.charAt(0) == "W") {
        setOtpVerified(false);
        failure(3000, res?.error);
        //setWrongOtp(true);
      } else {
        setOtpVerified(false);
        failure(3000, res?.error);
      }

      //return false
    }
  }

  const onRegisterUser = async ({ confirmPassword, ...values }: any) => {
    if (!otpVerified) {
      if (!otpSent && !otpVerified) {
        setMobile(values.mobileNumber);
        setName(values.name);
        await sendOtp(values.mobileNumber);
      } else if (otpSent && !otpVerified) {
        // --------
        if (values.otp === undefined) {
          failure(3000, "Please enter the OTP sent to your mobile number.");
        } else if (values.mobileNumber === undefined) {
          failure(3000, "Please enter a mobile number.");
        } else {
          await checkOtp(values.otp, values.mobileNumber);
        }
      }
    } else {
      saveUser(values);
    }
  };

  const onPasswordSkip = () => {
    saveUser({}, true);
  };

  const saveUser = async (values: any, skipPassword?: boolean) => {
    const url = process.env.REACT_APP_API_NODE_BASE_URL;
    let user_type = "Customer";

    let data: any = {
      email: "''",
      name: `'${name}'`,
      mobile_number: `'${mobile}'`,
      user_type: `'${user_type}'`,
      retail_store_code: retailStoreCode
    };

    if (skipPassword !== true) {
      data["password_digest"] = `${values?.password}`;
    }

    await insertUsers(data).then((res) => {
      if (res.auth) {
        onComplete(
          res.token,
          res.user_id,
          res.user_type,
          res.user_name,
          res.mobile_number
        );
      } else {
        message.info(res.error);
      }
    });
  };

  return (
    <CustomerRegisterUserForm
      onReset={resetOtpStates}
      onSubmit={onRegisterUser}
      onPasswordSkip={onPasswordSkip}
      resendOtp={resendOtp}
      otpStatus={{
        otpSent,
        otpVerified,
        wrongOtp,
        moboError,
        resend,
      }}
    />
  );
}

export default CustomerRegisterUserView;
