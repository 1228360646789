import React from "react";

import RetailStoreForm from "components/forms/RetailStoreForm";
var uuid = require("uuid");

type CreateRetailStoreViewProps = {
  token?: string;
  user_id?: string;
  onComplete: () => void;
  showPreview: boolean;
  setShowPreview: any;
  mobile_number: any;
};
function CreateRetailStoreView({
  token,
  user_id,
  onComplete,
  showPreview,
  setShowPreview,
  mobile_number,
}: CreateRetailStoreViewProps) {
  const headers: Record<string, string> = {};
  const url = process.env.REACT_APP_API_NODE_BASE_URL;
  if (token) {
    headers["X-Token"] = token;
  }

  async function generatePutUrl(
    key: string,
    type: string,
    bucketDirName?: string
  ) {
    const endpoint = `/generatePutUrl?Key=${key}&ContentType=${type}&BucketDirName=${
      bucketDirName ?? "retailStoreLogoImages"
    }`;
    return await fetch(url + endpoint, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "x-access-token": `${token}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        return json.putURL;
      });
  }
  async function uploadImage(url: string, file: File) {
    fetch(url, {
      method: "PUT",
      body: file,
      redirect: "follow",
      headers: {
        "Content-Type": file.type,
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => console.log(response.status))
      .then((result) => console.log("Success"))
      .catch((err) => {
        console.log("err", err);
      });
  }
  const handleCreateRetailStore = async ({ ...values }) => {
    if (values.logo !== undefined) {
      const putUrl = await generatePutUrl(values.logo.name, values.logo.type);
      await uploadImage(putUrl, values.logo);
    }
    if (values.sample_invoice !== undefined) {
      const sampleInvoicePutUrl = await generatePutUrl(
        values.sample_invoice.name,
        values.sample_invoice.type,
        "retailerSampleInvoices"
      );
      await uploadImage(sampleInvoicePutUrl, values.sample_invoice);
    }
    let whatsappNumber = [];
    if (
      values.whatsapp_check1 !== undefined &&
      values.whatsapp_check1.target.checked
    ) {
      whatsappNumber.push(values.primaryMobileNumber);
    }
    if (
      values.whatsapp_check2 !== undefined &&
      values.whatsapp_check2.target.checked
    ) {
      whatsappNumber.push(values.alternateNumber);
    }
    let data = {
      user_id: user_id,
      name: `'${values.name}'`,
      description: "''",
      alternate_number: `'${values.alternateNumber}'`,
      store_location: `'${values.map_url}'`,
      operating_system: `'${values.operating_system}'`,
      name_of_printer: `'${values.name_of_printer}'`,
      reference_code: `'${values.reference_code}'`,
      authenticity_document_value: `'${values.authenticity_document_value}'`,
      authenticity_document_type: `'${values.authenticity_document_type}'`,
      logo_data:
        values.logo !== undefined
          ? `'{"key":"${values.logo.name}","contentType":"${values.logo.type}"}'`
          : `'{}'`,
      sample_invoice:
        values.sample_invoice !== undefined
          ? `'{"key":"${values.sample_invoice.name}","contentType":"${values.sample_invoice.type}"}'`
          : `'{}'`,
      primary_mobile_number: `'${values.primaryMobileNumber}'`,
      whatsapp_number: `'{${whatsappNumber}}'`,
    };
    await fetch(url + "/insertRetailStores", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "x-access-token": `${token}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        let address = {
          line1: `'${values.address}'`,
          line2: "''",
          landmark: `'${values.locality}'`,
          pincode: `'${values.pincode}'`,
          city: `'${values.city}'`,
          state: `'${values.state}'`,
          addressable_type: `'RetailStore'`,
          addressable_id: json[0].id,
        };
        fetch(url + "/insertAddresses", {
          method: "POST",
          body: JSON.stringify(address),
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "x-access-token": `${token}`,
          },
        })
          .then((response) => response.json())
          .then((j) => {
            let keypairs = {
              retail_store_id: json[0].id,
              public_key: `'${token}'`,
              private_key: `'${uuid.v4()}'`,
              expires_at: `'2021-02-08 08:02:45.970824'`,
            };
            fetch(url + "/insertKeyPair", {
              method: "POST",
              body: JSON.stringify(keypairs),
              headers: {
                "Content-type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "x-access-token": `${token}`,
              },
            })
              .then((response) => response.json())
              .then((d) => {
                onComplete();
                setShowPreview(false);
              });
          });
      });
  };

  return (
    <RetailStoreForm
      defaultValues={{
        primaryMobileNumber: mobile_number,
        authenticity_document_type: "GSTIN",
      }}
      onSubmit={handleCreateRetailStore}
      showPreview={showPreview}
      setShowPreview={setShowPreview}
    />
  );
}

export default CreateRetailStoreView;
