import React, { useState, useEffect } from "react";
import { Row, Col } from "antd/es/grid";
import OrderSummaryCard from "components/pages/retailerDashboard/IncomingOrders/OrderSummaryCard";
import ChatCard from "components/pages/retailerDashboard/IncomingOrders/ChatCard";
import CustomerDetails from "components/pages/retailerDashboard/IncomingOrders/CustomerDetails";
import DashboardListCard from "components/pages/retailerDashboard/IncomingOrders/DashboardListCard";
import { fetchReorderCustomers } from "components/pages/retailerDashboard/APIs/fetchReorderCustomers";
import { Channel } from "twilio-chat/lib/channel";
import { useStyles } from "./IncomingOrders.style";
import Loader from "components/loader/Loader";
import { changeCustomerPriority } from "api/retailerApi";

type IncomingOrdersProps = {
  client: any;
  retailerData: {
    ID: number;
    Name: string;
  };
};

function IncomingOrders({ client, retailerData }: IncomingOrdersProps) {
  const classes = useStyles();
  // -------------NEW STATES IN USE-----------------------------
  const [incomingOrdersData, setIncomingOrdersData] = useState({
    reorders: [],
    priorityOrders: [],
    selectedOrderIndex: -1,
    loading: true,
  });
  const [search, setSearch] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [endOfList, setEndOfList] = useState(false);
  const [orderCategory, setOrderCategory] = useState("All");
  const [orderStatus, setOrderStatus] = useState("incoming");
  const [channelName, setChannelName] = useState<Channel>();
  // -----------------------------------------------------------

  const toggleCustomerPriorityCallback = (
    isPriorityNow: boolean,
    customer_id: any
  ) => {
    let newData: any = {};
    if (isPriorityNow) {
      newData.reorders = incomingOrdersData.reorders.map((item: any) =>
        item.user_id === customer_id ? { ...item, priority: true } : item
      );
    } else {
      newData.reorders = incomingOrdersData.reorders.map((item: any) =>
        item.user_id === customer_id ? { ...item, priority: false } : item
      );
    }
    newData.priorityOrders = newData.reorders.filter(
      (item: any) => item.priority
    );

    console.log(newData);
    setIncomingOrdersData({ ...incomingOrdersData, ...newData });
  };

  const toggleCustomerPriority = async (customerUserId: any) => {
    if (customerUserId !== "")
      await changeCustomerPriority(customerUserId, retailerData?.ID).then(
        (result) => {
          toggleCustomerPriorityCallback(
            result.priority.includes(`${retailerData?.ID}`),
            result.user_id
          );
        }
      );
  };

  const handleChannelChange = async () => {
    if (
      client !== undefined &&
      retailerData.ID > 0 &&
      incomingOrdersData.reorders.length > 0
    ) {
      var userID =
        incomingOrdersData.reorders[incomingOrdersData.selectedOrderIndex][
          "user_id"
        ];
      var userName =
        incomingOrdersData.reorders[incomingOrdersData.selectedOrderIndex][
          "user_name"
        ];
      if (
        orderCategory !== "All" &&
        incomingOrdersData.priorityOrders.length > 0
      ) {
        userID =
          incomingOrdersData.priorityOrders[
            incomingOrdersData.selectedOrderIndex
          ]["user_id"];
        userName =
          incomingOrdersData.priorityOrders[
            incomingOrdersData.selectedOrderIndex
          ]["user_name"];
      }
      const channelUniqueName = `${retailerData.ID}_${userID}`;
      const channelFriendlyName = `${retailerData.Name}_to_${userName}`;

      if (channelUniqueName || channelFriendlyName) {
        joinOrCreateChannel(channelUniqueName, channelFriendlyName);
      }
    }
  };

  const joinChannel = async (channel: any) => {
    if (channel.channelState.status !== "joined") {
      await channel.join();
    }
  };

  async function joinOrCreateChannel(
    channelUniqueName: string,
    channelFriendlyName: string
  ) {
    try {
      const channel = await client.getChannelByUniqueName(channelUniqueName);
      joinChannel(channel);
      setChannelName(channel);
    } catch (err) {
      try {
        const channel = await client.createChannel({
          uniqueName: channelUniqueName,
          friendlyName: channelFriendlyName,
        });
        joinChannel(channel);
        setChannelName(channel);
      } catch {
        alert("Unable to create channel, please reload this page");
      }
    }
  }

  async function fetchInitialData() {
    let reorderData = await fetchReorderCustomers(
      retailerData.ID,
      pageNumber,
      search ?? "",
      orderStatus,
      orderCategory === "Priority"
    );

    setEndOfList(reorderData?.length === 0);

    if (pageNumber === 1) {
      setIncomingOrdersData({
        reorders: filterReorderCustomers(reorderData, "All"),
        priorityOrders: filterReorderCustomers(reorderData, "Priority"),
        selectedOrderIndex: 0,
        loading: false,
      });
    } else {
      reorderData = [
        ...incomingOrdersData.reorders,
        ...incomingOrdersData.priorityOrders,
        ...reorderData,
      ];
      setIncomingOrdersData({
        reorders: filterReorderCustomers(reorderData, "All"),
        priorityOrders: filterReorderCustomers(reorderData, "Priority"),
        selectedOrderIndex: 0,
        loading: false,
      });
    }
  }

  function filterReorderCustomers(reorderData: any, category: string) {
    if (category === "All") {
      return reorderData;
    }
    var priorityReorders = [];
    for (var i = 0; i < reorderData?.length; i++) {
      if (reorderData[i].priority) {
        priorityReorders.push(reorderData[i]);
      }
    }
    return priorityReorders;
  }

  const handleReorderSearch = (search: any) => {
    setSearch(search);
    setPageNumber(1);
  };

  useEffect(() => {
    fetchInitialData();
  }, [pageNumber, orderStatus, orderCategory, search]);

  useEffect(() => {
    if (retailerData.ID && incomingOrdersData.reorders.length === 0) {
      fetchInitialData();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retailerData.ID]);

  useEffect(() => {
    handleChannelChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, incomingOrdersData.selectedOrderIndex]);

  // useEffect(() => {
  //   if (incomingOrdersData.loading === false) {
  //     setIncomingOrdersData({
  //       reorders: incomingOrdersData.reorders,
  //       priorityOrders: incomingOrdersData.priorityOrders,
  //       selectedOrderIndex: 0,
  //       loading: false,
  //     });
  //   } // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [orderCategory]);

  return (
    <div>
      {!incomingOrdersData.loading ? (
        <div className={classes.incomingOrdersPage}>
          <div className={classes.pageHeader}>
            <div className={classes.pageTitle}>
              {orderCategory === "Priority" ? (
                <>Priority Orders({incomingOrdersData.priorityOrders.length})</>
              ) : (
                <>All Orders({incomingOrdersData.reorders.length})</>
              )}
            </div>
          </div>
          <Row className={classes.cards}>
            <Col lg={6} xxl={4} className={classes.cardWrapper}>
              {incomingOrdersData.selectedOrderIndex >= 0 ? (
                <DashboardListCard
                  onSearch={handleReorderSearch}
                  toggleCustomerPriority={toggleCustomerPriority}
                  reorderData={incomingOrdersData.reorders}
                  priorityOrderData={incomingOrdersData.priorityOrders}
                  selectedOrderIndex={incomingOrdersData.selectedOrderIndex}
                  setReorderData={setIncomingOrdersData}
                  category={orderCategory}
                  setCategory={setOrderCategory}
                  setPageNumber={setPageNumber}
                  endOfList={endOfList}
                  setOrderStatus={setOrderStatus}
                  orderStatus={orderStatus}
                />
              ) : (
                <div>No Orders</div>
              )}
            </Col>
            <Col lg={12} xxl={14} className={classes.cardWrapper}>
              <OrderSummaryCard
                orderStatus={orderStatus}
                order={
                  orderCategory === "All"
                    ? incomingOrdersData.reorders[
                        incomingOrdersData.selectedOrderIndex
                      ]
                    : incomingOrdersData.priorityOrders[
                        incomingOrdersData.selectedOrderIndex
                      ]
                }
              />
            </Col>
            <Col
              lg={6}
              className={`${classes.cardWrapper} ${classes.lastWrapper}`}
            >
              {(orderCategory === "All" &&
                incomingOrdersData.reorders.length > 0) ||
              (orderCategory === "Priority" &&
                incomingOrdersData.priorityOrders.length > 0) ? (
                <CustomerDetails
                  customerId={
                    orderCategory === "All"
                      ? incomingOrdersData.reorders.length > 0 &&
                        incomingOrdersData.reorders.length >
                          incomingOrdersData.selectedOrderIndex
                        ? incomingOrdersData.reorders[
                            incomingOrdersData.selectedOrderIndex
                          ]["user_id"]
                        : 0
                      : incomingOrdersData.priorityOrders.length > 0 &&
                        incomingOrdersData.priorityOrders.length >
                          incomingOrdersData.selectedOrderIndex
                      ? incomingOrdersData.priorityOrders[
                          incomingOrdersData.selectedOrderIndex
                        ]["user_id"]
                      : 0
                  }
                  customerName={
                    orderCategory === "All"
                      ? incomingOrdersData.reorders.length > 0 &&
                        incomingOrdersData.reorders.length >
                          incomingOrdersData.selectedOrderIndex
                        ? incomingOrdersData.reorders[
                            incomingOrdersData.selectedOrderIndex
                          ]["user_name"]
                        : ""
                      : incomingOrdersData.priorityOrders.length > 0 &&
                        incomingOrdersData.priorityOrders.length >
                          incomingOrdersData.selectedOrderIndex
                      ? incomingOrdersData.priorityOrders[
                          incomingOrdersData.selectedOrderIndex
                        ]["user_name"]
                      : ""
                  }
                  isPriority={
                    orderCategory === "All"
                      ? incomingOrdersData.reorders.length > 0 &&
                        incomingOrdersData.reorders.length >
                          incomingOrdersData.selectedOrderIndex
                        ? incomingOrdersData.reorders[
                            incomingOrdersData.selectedOrderIndex
                          ]["priority"]
                        : false
                      : incomingOrdersData.priorityOrders.length > 0 &&
                        incomingOrdersData.priorityOrders.length >
                          incomingOrdersData.selectedOrderIndex
                      ? incomingOrdersData.priorityOrders[
                          incomingOrdersData.selectedOrderIndex
                        ]["priority"]
                      : false
                  }
                  retailStoreId={+retailerData.ID}
                  toggleCustomerPriority={toggleCustomerPriority}
                />
              ) : (
                ""
              )}
              {channelName &&
              ((orderCategory === "All" &&
                incomingOrdersData.reorders.length > 0) ||
                (orderCategory === "Priority" &&
                  incomingOrdersData.priorityOrders.length > 0)) ? (
                <ChatCard
                  retailStoreId={+retailerData.ID}
                  chatName={
                    orderCategory === "All"
                      ? incomingOrdersData.reorders.length > 0 &&
                        incomingOrdersData.reorders.length >
                          incomingOrdersData.selectedOrderIndex
                        ? incomingOrdersData.reorders[
                            incomingOrdersData.selectedOrderIndex
                          ]["user_name"]
                        : ""
                      : incomingOrdersData.priorityOrders.length > 0 &&
                        incomingOrdersData.priorityOrders.length >
                          incomingOrdersData.selectedOrderIndex
                      ? incomingOrdersData.priorityOrders[
                          incomingOrdersData.selectedOrderIndex
                        ]["user_name"]
                      : ""
                  }
                  channel={channelName}
                />
              ) : (
                ""
              )}
            </Col>
          </Row>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default IncomingOrders;
