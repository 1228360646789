import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import BannerImg from "assets/banner.gif";
import { getShopBanner } from "api/api";
import { Carousel } from "antd";
import { Link } from "react-router-dom";

interface BannerCardProps {
  page: string;
  storeId: number;
  defaultBanners?: false | any[];
  setBannersLength?: (val: number) => void;
}

export default function BannerCard({
  page,
  storeId,
  defaultBanners = false,
  setBannersLength,
}: BannerCardProps) {
  const classes = useStyle();
  const [banners, setBanners] = useState<any[]>([]);

  const fetchBanner = async () => {
    let bannerRes = await getShopBanner(storeId, page);
    if (bannerRes?.length) {
      setBanners(bannerRes);
      if (!!setBannersLength) {
        setBannersLength(bannerRes.length);
      }
    }
  };

  useEffect(() => {
    if (!!defaultBanners) {
      setBanners(defaultBanners);
    } else {
      fetchBanner();
    }
  }, [defaultBanners]);

  if (!banners.length) {
    return null;
  }

  return (
    <div className={classes.bannerCard}>
      <Carousel autoplay autoplaySpeed={5000}>
        {banners.map((banner: any, index) =>
          !!banner?.redirect_to ? (
            <Link to={banner.redirect_to}>
              <img src={banner.banner_url} key={index} width="100%" />
            </Link>
          ) : (
            <img src={banner.banner_url} key={index} width="100%" />
          )
        )}
      </Carousel>
    </div>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  bannerCard: {
    width: "100%",
    // height: 100,
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
  },
}));
