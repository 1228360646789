import React from "react";
import { useStyles } from "./MorePage.style";
import { Card, Col, Layout, Row } from "antd";
import FooterMenu from "components/FooterMenu";
import { RightOutlined } from "@ant-design/icons";
import ChatModal from "components/chat/ChatModal";
import DrawerSidebar from "components/drawer/Drawer";
import { Link, useRouteMatch } from "react-router-dom";

type RouteParams = {
  retailStoreId: string;
  url: string;
};

function MorePage() {
  // -------------NEW STATES IN USE-----------------------------
  const classes = useStyles();
  const {
    params: { retailStoreId },
    url,
  } = useRouteMatch<RouteParams>() || {};
  // -----------------------------------------------------------

  const OptionCard = ({ optionTitle, to }: any) => (
    <Link to={to}>
      <Card hoverable className={classes.optionCard}>
        <div className={classes.optionTitle}>{optionTitle}</div>
        <RightOutlined />
      </Card>
    </Link>
  );

  return (
    <>
      <Layout.Content>
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <Row className={classes.topAlignment}>
            <Col
              className={classes.center}
              style={{ margin: "10px 0px 0px 30px" }}
              span={4}
            >
              <DrawerSidebar />
            </Col>
            <Col className={classes.moreDetails} span={12}>
              More details
            </Col>
            <Col span={4}>
              <ChatModal retailStoreId={retailStoreId} />
            </Col>
          </Row>
        </div>
        <div className={classes.morePageOptions}>
          <OptionCard optionTitle="Active Orders" to={url + "/active-orders"} />
          <OptionCard
            optionTitle="Download Previous Bills"
            to={url + "/previous-bills"}
          />
        </div>
      </Layout.Content>
      <Layout.Footer className={classes.footer}>
        <FooterMenu retailStoreId={retailStoreId} />
      </Layout.Footer>
    </>
  );
}

export default MorePage;
