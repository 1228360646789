import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(({ colors }: Theme) => ({
  topbar: {
    width: "100%",
    padding: "0.7rem",
    minHeight: "65px",
    borderBottom: `3px solid ${colors.light500}`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  topbarLeft: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  searchIcon: {
    height: "40px",
    width: "40px",
    margin: "0 0.7rem",
    backgroundColor: "#ebebeb",
    borderRadius: "50%",
    fontSize: "1.2rem",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  searchInput: {
    lineHeight: "40px",
    fontWeight: 600,
    border: "none",
    outline: "none",
  },
  topbarRight: {
    // width: 250,
    minWidth: "20rem",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  topbarIconBtn: {
    height: "40px",
    width: "40px",
    fontSize: "2rem",
    borderRadius: "50%",
    backgroundColor: "#ebebeb",
    position: "relative",

    "&:first-child": {
      marginRight: 10,
    },
    "& .anticon": {
      fontSize: "1.12rem",
    },
    "& .ant-badge": {
      position: "absolute",
      bottom: "11px",
      left: "11px",
    },
    "& .ant-scroll-number": {
      fontSize: "0.8rem",
    },
  },

  user: {
    flex: 0.85,
    marginLeft: "1rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  profileImg: {
    padding: 4,
    width: 50,
  },
  buinessName: {
    paddingLeft: 5,
    fontSize: "0.85rem",
    fontWeight: 600,
    lineHeight: "20px",
    whiteSpace: "nowrap",
    color: "#505050",
  },
  userName: {
    paddingLeft: 5,
    fontSize: 10,
    color: colors.light500,
    whiteSpace: "nowrap",
  },
}));

export { useStyles };
