import { LeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import ChatModal from "components/chat/ChatModal";
import RetailerChatModal from "components/chat/RetailerChatModal";
import { RetailerDataType } from "components/pages/retailerDashboard";
import React, { useState, useEffect, useRef } from "react";
import { createUseStyles } from "react-jss";
import CustomerList from "../CustomerDetails/CustomerList";

const Chat = require("twilio-chat");

interface ChatPageProps {
  retailerData: RetailerDataType;
}

export default function ChatPage({ retailerData }: ChatPageProps) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [client, setClient] = useState<any>(null);
  const [channelName, setChannelName] = useState<any>(null);
  const [selectedCustomer, setSelectedCustomer] = useState<any>({
    customer_id: "156",
    name: "Anant Garg",
    priority: true,
    phone_no: "9925466335",
    user_id: "349",
    sum: "14604.00",
  });
  const [selectedCustomerUserId, setSelectedCustomerUserId] = useState(0);

  const tokenRef = useRef<any>();

  const handleSelectedCustomerChange = (customer: any) => {
    setSelectedCustomer(customer);
    setSelectedCustomerUserId(customer?.user_id);
  };

  const handleBackClick = () => {
    setSelectedCustomer({});
    setSelectedCustomerUserId(0);
  };

  const handleChannelChange = async () => {
    const userID = selectedCustomer.user_id;
    const userName = selectedCustomer.name;

    const channelUniqueName = `${retailerData.ID}_${userID}`;
    const channelFriendlyName = `${retailerData.Name}_to_${userName}`;

    if (channelUniqueName || channelFriendlyName) {
      joinOrCreateChannel(channelUniqueName, channelFriendlyName);
    }
  };

  const joinChannel = async (channel: any) => {
    if (channel.channelState.status !== "joined") {
      await channel.join();
    }
  };

  const joinOrCreateChannel = async (
    channelUniqueName: string,
    channelFriendlyName: string
  ) => {
    console.log(11111, channelUniqueName, channelFriendlyName);
    try {
      const channel = await client.getChannelByUniqueName(channelUniqueName);
      joinChannel(channel);
      setChannelName(channel);
      console.log(22222, channel);
    } catch (err) {
      try {
        const channel = await client.createChannel({
          uniqueName: channelUniqueName,
          friendlyName: channelFriendlyName,
        });
        console.log(33333, channel);
        joinChannel(channel);
        setChannelName(channel);
      } catch {
        alert("Unable to create channel, please reload this page");
      }
    }
  };

  const getTwilioToken = async (id: any) => {
    const url = process.env.REACT_APP_API_NODE_BASE_URL;
    const data = await fetch(`${url}/createTwilioToken`, {
      method: "POST",
      body: JSON.stringify({
        id: id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => res.json());
    tokenRef.current = data.token;
  };

  async function instantiateTwilio() {
    if (retailerData.ID > 0) {
      try {
        await getTwilioToken(retailerData.ID);
      } catch {
        console.log("Unable to get token, please reload this page");
        alert("Something went wrong, please reload !");
        // throw new Error("Unable to get token, please reload this page");
      }
      setClient(await Chat.Client.create(tokenRef.current));
    }
  }

  async function clientRefresh() {
    if (!!client) {
      client.on("tokenAboutToExpire", async function () {
        await getTwilioToken(retailerData.ID);
        client.updateToken(tokenRef.current);
      });

      client.on("tokenExpired", async function () {
        await getTwilioToken(retailerData.ID);
        client.updateToken(tokenRef.current);
      });
    }
  }

  useEffect(() => {
    instantiateTwilio();
  }, []);

  useEffect(() => {
    if (!!client) {
      handleChannelChange();
    }
  }, [client, loading]);

  useEffect(() => {
    clientRefresh();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  useEffect(() => {
    if (!!selectedCustomerUserId) {
      setLoading(true);
    }
  }, [client, selectedCustomerUserId]);

  if (!!!selectedCustomerUserId) {
    return (
      <CustomerList
        retailerData={retailerData}
        onSelectedCustomerChange={handleSelectedCustomerChange}
      />
    );
  }

  return (
    <div className={classes.chatPage}>
      <RetailerChatModal
        onBack={handleBackClick}
        modalOpen={!!selectedCustomerUserId}
        retailStoreId={retailerData.ID}
        chatName={selectedCustomer.name}
        channel={channelName}
      />
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  chatPage: {},
}));
