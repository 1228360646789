import { InfoCircleOutlined } from "@ant-design/icons";
import Tooltip from "antd/es/tooltip";
import React from "react";
import { createUseStyles } from "react-jss";

// type InputField = {
//   prefix: React.ReactNode;
//   suffix: React.ReactNode;
//   props: any;
// };

function InputField(props: any) {
  const { prefix, suffix, className, inputError, ...otherInputProps } = props;

  const classes = useStyles();

  return (
    <div className={`${className} ${classes.formInputWrapper}`}>
      {prefix && <div className={classes.inputPrefix}>{prefix}</div>}

      <input className={classes.inputField} {...otherInputProps} />
      {inputError ? (
        <div className={classes.inputSuffix}>
          <Tooltip placement="topRight" color="#ff0033" title={inputError}>
            <InfoCircleOutlined />
          </Tooltip>
        </div>
      ) : (
        suffix && <div className={classes.inputSuffix}>{suffix}</div>
      )}
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  formInputWrapper: {
    width: "100%",
    margin: "2px",
    padding: "5px 12px",
    backgroundColor: colors.light100,
    border: "1.5px solid #DBD4F4",
    borderRadius: "0.6rem",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& $inputField:-webkit-autofill,& $inputField:-webkit-autofill:hover, & $inputField:-webkit-autofill:focus, & $inputField:-webkit-autofill:active":
      {
        WebkitBoxShadow: "0 0 0 30px white inset !important",
      },
  },
  inputField: {
    width: "100%",
    lineHeight: "1.3rem",
    fontWeight: 600,
    border: "none",
    outline: "none",
  },

  inputPrefix: {
    fontSize: "1.3rem",
    padding: "0 10px 0 5px",
  },
  inputSuffix: {
    fontSize: "1.2rem",
    padding: "0 6px",
  },
  inputError: {
    color: "#ff0033",
  },
}));

export default InputField;
