import { DeleteOutlined } from "@ant-design/icons";
import { Button, Card, Col, message, Row, Typography } from "antd";
import {
  deleteCartItem,
  getCustomerCart,
  insertCart,
  insertCartItems,
  updateCartItemsByProductId,
} from "api/api";
import { getSession, Session } from "client/reactives/session";
import NumberSelect from "components/dataEntry/NumberSelect";
import gtag from "components/Tracking/gtag";
import React from "react";
import { createUseStyles } from "react-jss";
import { useRouteMatch } from "react-router-dom";

type RouteParams = {
  retailStoreId: string;
};

const getPriceWithoutGst = (gstPercentage: number, priceWithGst: number) => {
  let gstAmount = priceWithGst - priceWithGst * (100 / (100 + gstPercentage));
  return priceWithGst - gstAmount;
};

interface ProductCardProps {
  data: any;
  onChange: (action: string, product: any) => void;
}

const getPercentageOff = (data: any) => {
  let absDiscount = +data.pack_price - +data.strike_price;
  return Math.round((absDiscount / +data.pack_price) * 100);
};

export default function ProductCard({ data, onChange }: ProductCardProps) {
  const classes = useStyle();
  const {
    params: { retailStoreId },
  } = useRouteMatch<RouteParams>() || {};
  const { userId } = getSession() as Session;

  const getCartId = async () => {
    let cartId = 0;
    let cartRes = await getCustomerCart(+retailStoreId);

    if (cartRes.length) {
      cartId = cartRes[0].id;
    } else {
      let cartData = {
        retail_store_id: retailStoreId,
        payment_status: false,
        discount: 0,
        gst: 0,
        price_with_gst: 0,
        price_with_out_gst: 0,
        user_id: userId,
      };
      // Create the cart
      const insertCartRes = await insertCart(cartData);
      if (insertCartRes?.length) {
        cartId = insertCartRes[0].id;
      }
    }

    return cartId;
  };

  const onAdd = async (Product: any) => {
    if (+Product.quantity === 0) {
      Product.quantity = 1;
    }
    if (!!!Product.cart_id || Product.cart_id === "0") {
      try {
        let cartId: any = await getCartId();
        Product.cart_id = cartId;
      } catch (e) {
        console.log(e);
      }
    }

    try {
      let productPrice = Product?.strike_price ?? Product.pack_price;
      let sub_total = getPriceWithoutGst(
        +Product.sgst + +Product.cgst,
        +productPrice * +Product.quantity
      );
      let newCartItem = {
        // product_section: "my_catalogue",
        product_id: Product?.product_id ?? Product.id,
        product_sku: Product?.product_sku,
        quantity: Product.quantity,
        cart_id: !!Product.cart_id ? +Product.cart_id : 0,
        cgst: Product.cgst,
        sgst: Product.sgst,
        discount: Product.discount,
        total: (+productPrice * +Product.quantity).toFixed(2),
        sub_total: sub_total.toFixed(2),
      };

      await insertCartItems(newCartItem).then((res) => {
        if (res && res.id) {
          message.success("Items added to cart");

          onChange("add", { ...Product, ...res });

          gtag("event", "cart_item_added", {
            source: "shop",
            user: userId,
            store: Product.retail_store_id,
            product: Product.name || "notset",
          });
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onUpdate = async (Product: any) => {
    try {
      let productPrice = Product?.strike_price ?? Product.pack_price;
      let sub_total = getPriceWithoutGst(
        +Product.sgst + +Product.cgst,
        +productPrice * +Product.quantity
      );

      await updateCartItemsByProductId(
        +Product.cart_id,
        Product?.retailer_specific_product_id ??
          Product?.product_id ??
          Product.id,
        {
          quantity: Product.quantity,
          total: (+productPrice * +Product.quantity).toFixed(2),
          sub_total: sub_total.toFixed(2),
        }
      ).then((res) => {
        if (res?.length) {
          onChange("update", { ...Product, ...res[0] });

          message.warning("Qunatity has been updated");
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onRemove = async (Product: any) => {
    await deleteCartItem(+Product?.cart_item_id).then((result) => {
      if (result.length) {
        onChange("remove", { ...Product, ...result[0] });

        message.success("Item removed from cart");
      }
    });
  };

  return (
    <div className={classes.productCard}>
      <div>
        <Typography.Text strong className={classes.productName}>
          {data.name}
        </Typography.Text>
        <Typography.Text className={classes.productPack}>
          {data.pack}
        </Typography.Text>
      </div>

      <Row justify="space-between" align="bottom">
        <Col className={classes.priceWrapper}>
          <Typography.Text strong className={classes.sellingPrice}>
            ₹{parseFloat(data.strike_price ?? data.pack_price).toFixed(2)}
          </Typography.Text>
          {!!data.strike_price && +data.strike_price < +data.pack_price && (
            <>
              <Typography.Text
                type="secondary"
                delete
                className={classes.deletedPrice}
              >
                ₹{(+data.pack_price).toFixed(2)}
              </Typography.Text>
              {/* <Typography.Text className={classes.offPercentage}>
                <i>{getPercentageOff(data)}% off</i>
              </Typography.Text> */}
            </>
          )}
        </Col>

        <Col>
          {+data.cart_item_id > 0 ? (
            <div className={classes.productActions}>
              <Button
                size="small"
                type="text"
                icon={<DeleteOutlined />}
                onClick={() => onRemove(data)}
              />
              <NumberSelect
                till={100}
                defaultValue={data.quantity}
                style={{ marginTop: 15 }}
                onChange={(qty: any) => onUpdate({ ...data, quantity: qty })}
              />
            </div>
          ) : (
            <Button
              type="primary"
              className={classes.addButton}
              onClick={() => onAdd(data)}
            >
              Add
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  productCard: {
    padding: 15,
    height: 100,
    width: 200,
    fontSize: 12,

    border: "0.3px solid #D9D9D9",
    boxShadow:
      "-1px -1px 2px rgba(0, 0, 0, 0.1), 1px 1px 2px rgba(0, 0, 0, 0.1)",
    borderRadius: 2,

    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",

    "& .ant-typography": {
      display: "block",
    },
  },
  productName: {
    color: colors.textPrimary,
  },
  productPack: {
    fontSize: 10,
  },
  priceWrapper: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-start",
    "& > *": {
      marginRight: 3,
    },
  },
  sellingPrice: {
    fontSize: 12,
    display: "block",
  },
  deletedPrice: {
    fontSize: 10,
    display: "block",
  },
  offPercentage: {
    fontSize: 8,
    display: "block",
  },

  addButton: {
    width: 45,
    height: 21,
    fontSize: 10,
    marginTop: 15,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  productActions: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    "& > .ant-btn": {
      marginRight: 5,
      height: 21,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      // "& .anticon":{}
    },
  },
}));
