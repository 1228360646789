import React from "react";
import Button from "antd/es/button";
import RaseetLogo from "../../../assets/logos/retailer-with-name.png";
import { createUseStyles } from "react-jss";

type GetStrtedViewProps = {
  onClick: () => void;
};

function GetStartedView({ onClick }: GetStrtedViewProps) {
  const classes = useStyles();

  return (
    <div className={classes.getStartedPage}>
      <img className={classes.logo} src={RaseetLogo} alt="Raseet Logo" />
      <div className={classes.pageTitle}>
        {/* eslint-disable-next-line react-hooks/exhaustive-deps */}
        Congratulations 🎉, You are all set for RASEET.!
      </div>
      <Button
        type="primary"
        size="large"
        onClick={onClick}
        className={classes.getStartedButton}
      >
        Let's Get Started
      </Button>
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  getStartedPage: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  logo: {
    width: "17rem",
    margin: "3.5rem auto 25vh auto",
  },
  pageTitle: {
    padding: "0 2rem",
    marginBottom: "2rem",
    textAlign: "center",
    fontSize: "1.8rem",
    fontWeight: 600,
    color: "#333",
  },
  getStartedButton: {
    width: "40%",
    fontWeight: 600,
    background: colors.primary200,
  },
  "@media (max-width: 768px)": {
    getStartedPage: {
      // justifyContent: "center",
      height: "85vh",
    },
    logo: {
      width: "12rem",
      marginBottom: "7rem",
    },
    pageTitle: {
      fontSize: "1.4rem",
    },
    getStartedButton: {
      fontSize: 14,
    },
  },
}));

export default GetStartedView;
