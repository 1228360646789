import { gql, makeVar, useQuery } from '@apollo/client'
import type { ArgsProps } from 'antd/es/notification'

export type Alert = ArgsProps & {
  isOpen: boolean
}

type AlertData = {
  alert: Alert
}

const DEFAULT_TIMEOUT = 5 // in secs

const DEFAULT_OPTIONS: Alert = {
  message: '',
  description: '',
  type: 'info',
  isOpen: false,
  duration: DEFAULT_TIMEOUT
}

export const ALERT_QUERY = gql`
  query Alert {
    alert @client {
      description
      isOpen
      message
      type
    }
  }
`

export const alertVar = makeVar<Alert>(DEFAULT_OPTIONS)

const fieldType = {
  alert: {
    read() {
      return alertVar()
    }
  }
}

export const useAlertQuery = () => useQuery<AlertData>(ALERT_QUERY)

export const openAlert = (options: Omit<Alert, 'isOpen' | 'duration'>) => {
  alertVar({
    ...DEFAULT_OPTIONS,
    ...options,
    isOpen: true
  })
}

export const closeAlert = () => {
  const options = alertVar()

  alertVar({
    ...options,
    isOpen: false
  })
}

export default fieldType
