import React, { useEffect, useState } from "react";
import { RetailerDataType } from "components/pages/retailerDashboard";
import { createUseStyles } from "react-jss";
import Layout from "antd/es/layout/layout";
import {
  Badge,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Empty,
  Popover,
  Row,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import Flex from "components/layouts/Flex";
import { FilterOutlined } from "@ant-design/icons";
import { getRetailStoreCustomers } from "components/pages/retailerDashboard/APIs/getRetailStoreCustomers";
import {
  getStorePerformance2,
  getTopCustomers,
  getTopSellingProducts,
} from "api/retailerApi";
import MedicineIcon from "assets/svgs/medicine-bottle.svg";
import Loader from "components/loader/Loader";
import moment from "moment";

const truncate = (input: string, length: number) => {
  if (input.length > length) {
    return input.substring(0, length) + "...";
  }
  return input;
};

interface NewBusinessSummaryProps {
  retailerData: RetailerDataType;
}

export default function NewBusinessSummary({
  retailerData,
}: NewBusinessSummaryProps) {
  const classes = useStyle();

  const [loading, setLoading] = useState(true);
  const [topCustomers, setTopCustomers] = useState<any[]>([]);
  const [topSellingProducts, setTopSellingProducts] = useState<any[]>([]);
  const [loadMoreTopSellingProducts, setLoadMoreTopSellingProducts] =
    useState(false);
  const [storePerformance, setStorePerformance] = useState<any>({});
  const [dateFilters, setDateFilters] = useState<any>({
    storePerformance: [null, null],
    topSellingProducts: [null, null],
    topCustomers: [null, null],
  });

  const TableColumns: any[] = [
    {
      title: "#",
      render: (a: any, b: any, index: number) => index + 1,
      width: "50px",
    },
    {
      title: "Customer Number",
      dataIndex: "mobile_number",
      key: "mobile_number",
      width: "120px",
    },
    {
      title: "Purchase Value",
      dataIndex: "sum_total",
      key: "sum_total",
      width: "120px",
      render: (val: any) => "₹" + val,
    },
    {
      title: "Repeat",
      dataIndex: "is_repeat_buyer",
      key: "is_repeat_buyer",
      width: "80px",
      render: (val: boolean) => <Checkbox disabled checked={val} />,
    },
    {
      title: "Disease Group",
      key: "ailment_class",
      dataIndex: "ailment_class",
      width: "150px",
      render: (val: any) =>
        !!val ? <Tooltip title={val}>{truncate(val, 15)}</Tooltip> : " - ",
    },
  ];

  const handleRangeFilterChange = (key: string) => (values: any) => {
    setDateFilters((curr: any) => {
      curr[key] = values ?? [null, null];
      return { ...curr };
    });
  };

  const getFilterObj = (key: string) =>
    !dateFilters[key].includes(null)
      ? {
          customer_filter: [
            {
              type: "created_at",
              filter_on: "O",
              min_value: dateFilters?.[key]?.[0].format("YYYY-MM-DD"),
              max_value: dateFilters?.[key]?.[1]?.format("YYYY-MM-DD"),
            },
          ],
        }
      : {};

  const fetchTopCustomers = async () => {
    let storePerformanceRes = await getStorePerformance2(
      retailerData.ID,
      getFilterObj("storePerformance")
    );
    let topCustomersRes = await getTopCustomers(
      retailerData.ID,
      getFilterObj("topCustomers")
    );
    let topSellingProductsRes = await getTopSellingProducts(
      retailerData.ID,
      getFilterObj("topSellingProducts")
    );

    if (!!topCustomersRes?.data?.top_selling_products)
      setTopCustomers(topCustomersRes.data.top_selling_products);

    if (!!topSellingProductsRes?.data?.top_selling_products)
      setTopSellingProducts(topSellingProductsRes.data.top_selling_products);

    if (!!storePerformanceRes?.data)
      setStorePerformance(storePerformanceRes.data);

    setLoading(false);
  };

  useEffect(() => {
    fetchTopCustomers();
  }, [dateFilters]);

  const StorePerformanceCard = ({ label, value, addRsSymbol }: any) => (
    <Col span={8}>
      <Card className={classes.performanceCard}>
        <Typography.Title level={5} className={classes.performanceCardValue}>
          {addRsSymbol ? "₹ " : ""}
          {!!value
            ? parseFloat(value + "")?.toLocaleString("en-IN", {
                maximumFractionDigits: 2,
              })
            : 0.0}
        </Typography.Title>
        <Typography.Text className={classes.performanceCardLabel}>
          {label}
        </Typography.Text>
      </Card>
    </Col>
  );

  const getFilteredRangeTag = (key: any) => {
    return !dateFilters[key]?.includes(null) ? (
      <Tag closable onClose={() => handleRangeFilterChange(key)(null)}>
        {dateFilters[key][0]?.format("Do MMM") +
          " - " +
          dateFilters[key][1]?.format("Do MMM")}
      </Tag>
    ) : (
      <></>
    );
  };

  const getFilterBtnContent = (key: string) => (
    <DatePicker.RangePicker
      onChange={handleRangeFilterChange(key)}
      ranges={{
        Today: [moment(), moment()],
        "This week": [moment().startOf("week"), moment().endOf("week")],
        "Last week": [
          moment().subtract(1, "weeks").startOf("week"),
          moment().subtract(1, "weeks").startOf("week").add(6, "days"),
        ],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
        "Last Month": [
          moment().subtract(1, "months").startOf("month"),
          moment().subtract(1, "months").endOf("month"),
        ],
      }}
    />
  );

  if (loading) {
    return <Loader height="80vh" />;
  }

  return (
    <Layout className={classes.businessSummaryPage}>
      <Space direction="vertical">
        <Card>
          <Flex align="center" justify="space-between">
            <Typography.Text style={{ fontWeight: 500, fontSize: 14 }}>
              Store Performance
            </Typography.Text>

            <Flex align="center">
              {getFilteredRangeTag("storePerformance")}

              <Popover
                placement="bottomRight"
                trigger={["click"]}
                content={getFilterBtnContent("storePerformance")}
              >
                <Button type="text" icon={<FilterOutlined />} />
              </Popover>
            </Flex>
          </Flex>

          <Row gutter={[10, 10]} className={classes.performanceCardsWrapper}>
            <StorePerformanceCard
              label="New Customers"
              value={storePerformance?.new_customers}
            />
            <StorePerformanceCard
              label="Repeat Customers"
              value={storePerformance?.repeat_customers}
            />
            <StorePerformanceCard
              label="Return/Exchange"
              value={storePerformance?.return}
            />
            <StorePerformanceCard
              label="Bill Prints"
              value={storePerformance?.bill_prints}
            />
            <StorePerformanceCard
              label="Items Sold"
              value={storePerformance?.items_sold}
            />
            <StorePerformanceCard
              addRsSymbol
              label="Sales Done"
              value={storePerformance?.sales_done ?? 0}
            />
          </Row>
        </Card>

        <Card>
          <Flex align="center" justify="space-between">
            <Typography.Text style={{ fontWeight: 500, fontSize: 14 }}>
              Top Sold Items
            </Typography.Text>

            <Flex align="center">
              {getFilteredRangeTag("topSellingProducts")}

              <Popover
                placement="bottomRight"
                trigger={["click"]}
                content={getFilterBtnContent("topSellingProducts")}
              >
                <Button type="text" icon={<FilterOutlined />} />
              </Popover>
            </Flex>
          </Flex>

          <Row gutter={[20, 15]} className={classes.performanceCardsWrapper}>
            {topSellingProducts.length ? (
              topSellingProducts
                .slice(0, loadMoreTopSellingProducts ? undefined : 6)
                .map((product, index) => (
                  <Col span={12}>
                    <Badge.Ribbon
                      placement="start"
                      text={"#" + (index + 1)}
                      className={classes.rankBadge}
                    >
                      <div className={classes.topSellerProductCard}>
                        <img
                          src={
                            product?.image_urls?.length
                              ? product?.image_urls[0]
                              : MedicineIcon
                          }
                          alt=""
                        />

                        <Space
                          direction="vertical"
                          size={0}
                          style={{ paddingLeft: 5 }}
                        >
                          <Typography.Text
                            style={{ fontSize: 10, lineHeight: "10px" }}
                          >
                            {product.name}
                          </Typography.Text>
                          <Typography.Text strong style={{ fontSize: 10 }}>
                            {product.sum_quantity_sold} qty
                          </Typography.Text>
                        </Space>
                      </div>
                    </Badge.Ribbon>
                  </Col>
                ))
            ) : (
              <Col span={24}>
                <Flex justify="center" style={{ padding: 15 }}>
                  <Empty description="No items sold" />
                </Flex>
              </Col>
            )}
          </Row>
          <Flex hidden={topSellingProducts.length <= 6} justify="center">
            <Button
              type="link"
              size="small"
              style={{ marginTop: 10 }}
              onClick={() =>
                setLoadMoreTopSellingProducts(!loadMoreTopSellingProducts)
              }
            >
              {!loadMoreTopSellingProducts ? "Load more" : "Show less"}
            </Button>
          </Flex>
        </Card>

        <Card>
          <Flex
            align="center"
            justify="space-between"
            className={classes.tableCardHeader}
          >
            <Typography.Text style={{ fontWeight: 500, fontSize: 14 }}>
              Top Customers
            </Typography.Text>

            <Flex align="center">
              {getFilteredRangeTag("topCustomers")}

              <Popover
                placement="bottomRight"
                trigger={["click"]}
                content={getFilterBtnContent("topCustomers")}
              >
                <Button type="text" icon={<FilterOutlined />} />
              </Popover>
            </Flex>
          </Flex>

          <Table
            size="small"
            pagination={false}
            columns={TableColumns}
            dataSource={topCustomers}
            scroll={{ y: "calc(100vh - 270px)", x: true }}
          />
        </Card>
      </Space>
    </Layout>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  "@global": {
    ".ant-picker-panel-container .ant-picker-panels": {
      flexWrap: "wrap !important",
    },
    ".ant-picker-content, .ant-picker-date-panel, .ant-picker-panel": {
      width: "100% !important",
    },
    ".ant-picker-range-wrapper": {
      minWidth: "unset !important",
      maxWidth: "320px !important",
    },
  },

  businessSummaryPage: {
    height: "calc(100vh - 130px)",
    padding: 10,
    overflowY: "auto",

    "& .ant-table *": {
      fontSize: 12,
    },
    "& .ant-space": {
      width: "100%",
    },
  },
  performanceCardsWrapper: {
    margin: [10, 0, 5],
    paddingLeft: 5,
  },
  performanceCard: {
    background: colors.light300,

    "& .ant-card-body": {
      padding: [15, 5],
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      textAlign: "center",
    },
  },
  performanceCardValue: {
    fontSize: "12px !important",
    marginBottom: "3px !important",
  },
  performanceCardLabel: {
    fontSize: 10,
  },

  topSellerProductCard: {
    padding: [10, 10, 10, 15],
    height: 100,

    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // flexDirection: "column",

    background: "#FFFFFF",
    border: "0.3px solid rgba(80, 80, 80, 0.5)",
    boxShadow: "0.3px 0.3px 1px rgba(0, 0, 0, 0.1)",
    borderRadius: 2,

    "& img": {
      width: 50,
      objectFit: "contain",
    },
  },

  tableCardHeader: {
    marginBottom: 10,
  },
  rankBadge: {
    height: "18px !important",
    lineHeight: "18px !important",
    fontSize: 10,
  },
}));
