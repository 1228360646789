import { createUseStyles } from "react-jss";
const useStyles = createUseStyles(({ colors }: Theme) => ({
  chatPage: {
    height: "100%",
  },
  pageHeader: {
    width: "25%",
    minHeight: "60px",
    padding: "0 0.8rem",

    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  pageTitle: {
    fontWeight: 600,
    fontSize: "1rem",
    color: "#505050",
  },
  cards: {
    height: "90%",
  },
  cardWrapper: {
    height: "100%",
    padding: "10px",
  },
  lastWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  centreText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    fontWeight: "bold",
    fontSize: "1.2rem",
  },
}));

export { useStyles };
