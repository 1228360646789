import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(({ colors }: Theme) => ({
  editProfileTabs: {
    "& .ant-tabs-tab-btn": {
      fontSize: 12,
    },
  },
  header: {
    height: 58,
    position: "sticky",
    top: "0",
    backgroundColor: colors.primary,
    color: colors.light100,
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: "1.2rem",
    padding: 0,
    zIndex: 1,
  },
  inputField: {
    padding: "0.5rem 0rem",
  },
  layout: {
    background: "#FFFFFF",
  },
  tab: {
    height: "calc(100vh - 4rem)",
    overflow: "scroll",
  },
  editProfile: {
    fontSize: 16,
  },
  arrow: {
    fontSize: "1.2rem",
    color: "#FFF",
  },
  col: {
    textAlign: "center",
  },
}));

export { useStyles };
