import { MessageOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { createUseStyles } from "react-jss";

type OrderMsgCardProps = {
  title: string;
  badge?: string;
  amount: number;
};

function OrderMsgCard({ title, badge, amount }: OrderMsgCardProps) {
  const classes = useStyles();

  return (
    <div className={classes.orderMsgCard}>
      <div className={classes.msgCardTitle}>
        {title}
        {badge === "confirmBadge" && (
          <div className={classes.confirmBadge}>CONFIRMED SALE</div>
        )}
        {badge === "lostBadge" && (
          <div className={classes.lostBadge}>LOST SALE</div>
        )}
      </div>
      <div className={classes.msgCardInner}>
        <div className={classes.showItemsText}>Show items</div>
        <div className={classes.msgCardAmount}>₹ {amount}</div>
      </div>
      <div className={classes.msgCardSendBtn}>
        <Button size="small" block icon={<MessageOutlined />}>
          Send
        </Button>
      </div>
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  orderMsgCard: {
    width: "31.5%",
    maxWidth: "280px",
    minHeight: "140px",
    marginLeft: 12,
    marginBottom: "1rem",
    background: colors.light100,
    boxShadow:
      "1px 1px 4px rgba(0, 0, 0, 0.25),-1px -1px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
  },
  msgCardTitle: {
    width: "100%",
    minHeight: "35px",
    paddingLeft: "10px",
    background: "#E9EFF1",
    fontSize: 12,
    fontWeight: 600,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  confirmBadge: {
    marginLeft: "10px",
    color: colors.light100,
    textAlign: "center",
    fontSize: "0.5rem",
    padding: "2px 10px",
    background: "#359C10",
    borderRadius: "20px",
  },
  lostBadge: {
    marginLeft: "10px",
    color: colors.light100,
    textAlign: "center",
    padding: "2px 10px",
    fontSize: "0.5rem",
    background: "#D10B0B",
    borderRadius: "20px",
  },
  msgCardInner: {
    minHeight: "55px",
    fontSize: "1rem",
    padding: "13px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
  },
  showItemsText: {
    color: colors.dark400,
    fontWeight: 600,
    fontSize: 12,
  },
  msgCardAmount: {
    fontWeight: "700",
    fontSize: 12,
  },
  msgCardSendBtn: {
    width: "100%",
    padding: "5px",

    marginTop: "auto",
  },
  "@media (max-width: 1050px)": {
    msgCardTitle: { fontSize: "0.8rem" },
    msgCardInner: { minHeight: 55, fontSize: "0.8rem" },
  },
}));

export default OrderMsgCard;
