import { createUseStyles } from "react-jss";
import customeScrollbarStyle from "components/style/CustomScrollbar";

const useStyles = createUseStyles(({ colors }: Theme) => ({
  orderSummaryCard: {
    width: "100%",
    height: "calc(100vh - 155px)",
    padding: "0.8rem",
    backgroundColor: colors.light100,
    borderRadius: "5px",
    boxShadow:
      "-1px -1px 4px rgba(34, 94, 119, 0.25), 1px 1px 4px rgba(34, 94, 119, 0.25)",
  },
  orderSummaryCardInner: {
    height: "100%",
    padding: "0.2rem",
    overflowY: "auto",

    // customizing scrollbar
    ...customeScrollbarStyle,
  },
  orderDetails: {
    display: "flex",
    minHeight: "30px",
    paddingBottom: "20px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  orderID: {
    display: "flex",
    margin: [0, 18, 0, 10],
    fontSize: 16,
    fontWeight: 700,
    color: colors.primary200,
    justifyContent: "center",
  },
  statusTitle: {
    display: "flex",
    fontSize: 16,
    fontWeight: 700,
    color: colors.primary200,
    justifyContent: "flex-end",
  },
  paymentStatusTitle: {
    display: "flex",
    margin: [5, 0, 0, 10],
    fontSize: 16,
    fontWeight: 700,
    color: colors.primary200,
    justifyContent: "center",
  },
  paymentStatus: {
    display: "flex",
    margin: [0, 5, 0, 5],
    justifyContent: "flex-end",
    alignItems: "center",
    color: colors.dark1000
  },
  orderDateTime: {
    display: "flex",
    margin: [3,0,0,0],
    flexDirection: "column",
    justifyContent: "center",
    fontSize: 12,
    fontWeight: 500,
    color: colors.light500,
  },
  sendMsgBtn: {
    minHeight: "90px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  divider: {
    height: "1.5px",
    background: colors.light500,
  },
  orderMsgsTitle: {
    minHeight: "70px",
    padding: "0 1rem",
    color: colors.primary200,
    fontSize: 14,
    fontWeight: 600,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  seperator: {
    border: "0.5px solid rgba(0, 0, 0, 0.25)",
  },
  orderMsgsWrapper: {
    position: "relative",
  },
  overlayComingSoon: {
    width: "100%",
    height: "110%",
    background: "rgba(171, 183, 183, 0.8)",
    borderRadius: "5px",
    position: "absolute",
    zIndex: "5",
    left: "0",
    top: "0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  overlayComingSoonText: {
    fontWeight: 600,
    fontSize: 20,
    padding: "1rem",
    color: colors.light100,
  },
  orderMsgs: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  centreText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    fontWeight: "bold",
    fontSize: "1.2rem",
  },
}));

export { useStyles };
