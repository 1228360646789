import {
  EditOutlined,
  InfoCircleOutlined,
  ArrowLeftOutlined,
  PhoneOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Button, message, Layout, Typography } from "antd";
import Avatar from "antd/es/avatar/avatar";
import TextArea from "antd/lib/input/TextArea";
import {
  deleteCart,
  getRatings,
  postRatings,
  getReorderById,
  getRetailStores,
  createRazorpayOrder,
  verifyPaymentSuccess,
  getReorderLoyaltyInfo,
  updatePaymentFailedStatus,
  getReorderItems,
} from "api/api";
import constants from "constants/constants";
import { createUseStyles } from "react-jss";
import Loader from "components/loader/Loader";
import React, { useEffect, useState } from "react";
import { GoldenCrownIcon } from "components/customIcons";
import { useHistory, useRouteMatch } from "react-router-dom";
import FooterMenu from "components/FooterMenu";
import Confetti from "components/Confetti";
import CongratsModal from "components/modals/CongratsModal";

type RouteParams = {
  reorderId: string;
  retailStoreId: string;
};

function OrderPlacedPage() {
  const {
    params: { reorderId, retailStoreId },
  } = useRouteMatch<RouteParams>() || {};
  const history = useHistory();
  const classes = useStyles();
  const [congratsModalVisible, setCongratsModalVisible] = useState(false);
  const [pageData, setPageData] = useState<any>({
    reorder: {},
    reorderItems: [],
    loyaltyInfo: {},
    rating: 0,
    review: "",
    retailerDetails: {},
    feedbackGiven: false,
    loading: true,
  });
  const queryParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    let showCongratsModal = queryParams.get("showCongratsModal");
    if (!!showCongratsModal) {
      setCongratsModalVisible(true);

      let newParam = new URLSearchParams(window.location.search);
      newParam.delete("showCongratsModal");
      history.replace(window.location.pathname + "?" + newParam.toString());
    }
  }, []);

  useEffect(() => {
    (async () => {
      await getReorderById(reorderId).then(async ([reorder]) => {
        let items = getReorderItems(+reorderId);
        let loyaltyInfo = await getReorderLoyaltyInfo(
          +reorderId,
          +retailStoreId
        );
        var retailerDetails = await getRetailStores(+retailStoreId);

        setPageData({
          ...pageData,
          reorder,
          loyaltyInfo,
          loading: false,
          reorderItems: items,
          retailerDetails,
        });
        await getRatings(+reorderId, "reorder").then((result) => {
          if (result?.reorder_id) {
            setPageData({
              ...pageData,
              reorder,
              loyaltyInfo,
              retailerDetails,
              reorderItems: items,
              rating: +result.rating,
              review: result.feedback,
              feedbackGiven: true,
              loading: false,
            });
          }
        });
      });
    })();
  }, []);

  const clearCart = async () => {
    await deleteCart(parseInt(pageData.reorder.cart_id));
    setPageData({ ...pageData, cartItems: [] });
  };

  const completePayment = async () => {
    console.log("Loading razorpay js.....");
    // ---------- Load Razorpay script --------------------------------
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
    // ----------------------------------------------------------------
    var reorderAmount = parseFloat(pageData?.reorder?.grandtotal).toFixed(2);

    var userName = localStorage.getItem("userName");
    var mobileNumber = localStorage.getItem("mobileNumber");
    var userId = localStorage.getItem("userId");

    // creating a new razorpay order
    var reqBody = {
      amount: reorderAmount,
      cartId: pageData.reorder.cart_id,
      userId: userId,
    };
    var resp = await createRazorpayOrder(reqBody);

    if (!resp || resp.message !== "Created") {
      alert(resp ? resp.message : "Something went wrong");
      return;
    }

    // Getting the order details back
    const amount = resp.value.amount;
    const orderId = resp.value.id;
    const currency = resp.value.currency;

    // ------------------------------------------------------------------------
    var options = {
      key: resp.api_key,
      amount: amount.toString(), // 2000 paise = INR 20, amount in paisa
      currency: currency,
      name: "RASEET",
      description: "Ab aapka bill hi hai aapki duqaan",
      order_id: orderId,
      handler: async function (response: any) {
        const data = {
          orderCreationId: orderId,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };

        verifyPaymentSuccess(data)
          .then(function (res) {
            if (res.msg === "success") {
              console.log("Payment successful");

              if (
                pageData.reorderItems.filter((i: any) => !!i?.is_plan).length >
                0
              ) {
                setCongratsModalVisible(true);
              }
              // window.location.href =
              //   window.location.origin +
              //   `/dashboard/stores/${retailStoreId}/reorder-placed/${pageData.reorder.id}`;
            }
          })
          .catch((e) => {
            console.log(e);
            alert(e);
          });
      },
      prefill: {
        name: userName,
        contact: mobileNumber,
      },
      notes: {
        address:
          "User ID: " +
          userId +
          ", with cartId: " +
          pageData.reorder.cart_id +
          " and reorder ID: " +
          pageData.reorder.id,
      },
      theme: {
        color: "#2a9d8f",
      },
    };
    // ------------------------------------------------------------------------

    var wndw: any = window || {};
    let rzp = new wndw.Razorpay(options);

    rzp.on("payment.error", async function (response: any) {
      console.log("error: ", response.error);
      if (response.error.metadata) {
        const req = {
          razorpayOrderId: response.error.metadata.order_id,
          razorpayPaymentId: response.error.metadata.payment_id,
          status: "FAILED",
        };
        await updatePaymentFailedStatus(req);
        await clearCart();
        window.location.href =
          window.location.origin +
          `/dashboard/stores/${retailStoreId}/reorder-placed/${pageData.reorder.id}`;
      } else {
        console.log(response.error.description);
        // alert(response.error.description);
      }
    });

    rzp.open();
    return;
  };

  const goToOrderSummary = () => {
    history.replace(
      `/dashboard/stores/${retailStoreId}/reorder-placed/${reorderId}/order-summary`
    );
  };

  const handleRatingsChange = (level: number) => {
    setPageData({ ...pageData, rating: level });
  };

  const handleReviewChange = (e: any) => {
    setPageData({ ...pageData, review: e.target.value });
  };

  const handleSubmitFeedback = async () => {
    let ratingsData = {
      rating: pageData.rating,
      feedback: pageData.review,
      feedback_type: "text",
      bill_type: "reorder",
      reorder_id: pageData.reorder.id,
      customer_id: pageData.reorder.user_id,
    };

    let result = await postRatings(ratingsData, false)
      .then((r) => {
        if (r?.reorder_id) {
          message.success("Thanks for your feedback.!");
          setPageData({ ...pageData, feedbackGiven: true });
        } else {
          message.error("Couldn't submit the feedback.!");
        }
      })
      .catch((e) => message.error("Something went wrong.!"));
  };

  const getRatingStyleObject = (level: number) => {
    let styles =
      level === pageData.rating
        ? {
            width: "32px",
            height: "32px",
            lineHeight: "32px",
            fontSize: "12px",
          }
        : {};
    let background =
      level < 6 ? "#e64102b3" : level > 8 ? "#2A9D8F" : "#FCAA17";
    return { ...styles, background };
  };

  const onExitPage = () =>
    history.replace(`/dashboard/stores/${retailStoreId}/bill`);

  return (
    <div className={classes.orderPlacedPage}>
      <CongratsModal
        visible={congratsModalVisible}
        onCancel={() => setCongratsModalVisible(false)}
      />

      {!pageData.loading ? (
        <>
          <div className={classes.pageHeader}>
            <GoldenCrownIcon />
            <div className={classes.orderPlacedText}>
              Your Order Is Placed !
            </div>
            {pageData.loyaltyInfo?.loyalty_points_to_be_earned ? (
              <div className={classes.loyaltyPointsSummary}>
                You will earn{" "}
                <span>{pageData.loyaltyInfo?.loyalty_points_to_be_earned}</span>{" "}
                Loyalty points on this purchase
                <InfoCircleOutlined />
              </div>
            ) : (
              ""
            )}
            {queryParams.get("from") === "active-orders" ? (
              <div className={classes.backButton}>
                <ArrowLeftOutlined onClick={() => history.goBack()} />
              </div>
            ) : (
              ""
            )}
            <Button
              type="primary"
              shape="circle"
              size="small"
              className={classes.closeButton}
              onClick={onExitPage}
            >
              X
            </Button>
            <Button
              type="text"
              href={
                "tel:+91" + pageData?.retailerDetails?.primary_mobile_number
              }
              icon={<PhoneOutlined rotate={90} />}
              className={classes.contactStoreBtn}
            >
              Contact Store
            </Button>
          </div>

          <div className={classes.paymentDetailsWrapper}>
            <div className={classes.wrapperTitle}>Payment Details</div>
            <div className={classes.paymentDetails}>
              <div className={classes.paymentDetailsItem}>
                <span>Order Amount</span>
                <span>
                  ₹ {parseFloat(pageData?.reorder?.grandtotal).toFixed(2)}
                </span>
              </div>
              <div className={classes.paymentDetailsItem}>
                <span>Total Loyalty Points</span>
                <span>₹ {pageData.loyaltyInfo?.loyalty_points}</span>
              </div>
            </div>
          </div>

          <div className={classes.orderDetailsWrapper}>
            <div className={classes.wrapperTitle}>Order Details</div>
            <div className={classes.orderDetails}>
              {console.log(pageData.retailerDetails)}
              <img
                src={
                  pageData.retailerDetails
                    ? constants.s3Url +
                      pageData?.retailerDetails?.logo_data?.key
                    : ""
                }
                alt="retailer logo"
              />
              <div className={classes.orderDetailContent}>
                Your order of ₹{" "}
                {parseFloat(pageData?.reorder?.grandtotal).toFixed(2)} from
                {pageData.retailerDetails
                  ? " " + pageData.retailerDetails.name
                  : ""}{" "}
                will arrive shortly.
              </div>
              <div
                className={classes.orderDetailsRight}
                onClick={goToOrderSummary}
              >
                <span>{pageData?.reorder?.items_count} Items</span>
                <RightOutlined />
              </div>
            </div>
          </div>

          {!!pageData?.reorder?.coupon_discount && (
            <div className={classes.discountWrapper}>
              <Confetti />
              <Typography.Text>
                Congrats! You saved ₹
                {parseFloat(pageData?.reorder?.coupon_discount).toFixed(2)} on
                this order 🎉
              </Typography.Text>
            </div>
          )}

          <div className={classes.feedbackWrapper}>
            <div className={classes.wrapperTitle}>Feedback</div>
            <div className={classes.feedback}>
              <div className={classes.feedbackTitle}>
                How likely are you to recommend this to a colleague or a friend?
              </div>
              <div className={classes.ratingOptions}>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((level: number) => (
                  <div
                    onClick={() =>
                      !pageData.feedbackGiven && handleRatingsChange(level)
                    }
                  >
                    <Avatar style={getRatingStyleObject(level)}>{level}</Avatar>
                  </div>
                ))}
              </div>

              <div className={classes.reviewHeader}>
                <div className={classes.feedbackTitle}>
                  Tell us about your likes, dislikes or any suggestions if you
                  have.
                </div>
                <Button type="primary" shape="circle" icon={<EditOutlined />} />
              </div>
              <TextArea
                className={classes.textReviewInputField}
                autoSize={{ minRows: 7, maxRows: 7 }}
                defaultValue={pageData.review}
                readOnly={pageData.feedbackGiven}
                placeholder="Please write your feedback here..."
                onChange={handleReviewChange}
              />
              <Button
                block
                size="small"
                type="primary"
                className={classes.submitReviewButton}
                disabled={pageData.feedbackGiven}
                onClick={handleSubmitFeedback}
              >
                SUBMIT
              </Button>
            </div>
          </div>
          <Layout.Footer style={{ padding: 0 }}>
            <FooterMenu retailStoreId={retailStoreId} />
          </Layout.Footer>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  orderPlacedPage: {
    minHeight: "100vh",
    paddingBottom: "5rem",
    backgroundColor: "#EAF5F4",
  },
  pageHeader: {
    height: 150,
    width: "100%",
    padding: [16, 20, 0],
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    textAlign: "center",

    "& .anticon": {
      fontSize: 22,
    },

    "& > *": {
      marginBottom: 5,
    },
  },
  closeButton: {
    fontSize: 12,
    background: "#979797",
    boxShadow:
      "-1px -1px 4px rgba(0, 0, 0, 0.1), 1px 1px 4px rgba(0, 0, 0, 0.1)",
    position: "fixed",
    top: 20,
    right: 20,
  },
  backButton: {
    fontSize: 12,
    position: "fixed",
    top: 20,
    left: 20,
  },
  orderPlacedText: {
    width: "100%",
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "22px",
    color: "#505050",
  },
  loyaltyPointsSummary: {
    fontWeight: "500",
    fontSize: "10px",
    lineHeight: "12px",
    color: "#505050",

    "& span": {
      color: "#9c9c9c",
    },

    "& .anticon": {
      fontSize: 10,
      color: "#F5A900",
      marginLeft: 5,
    },
  },

  wrapperTitle: {
    height: 30,
    paddingLeft: 18,
    marginTop: 15,
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "14px",
    color: "#505050",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },

  paymentDetailsWrapper: {},
  paymentDetails: {
    padding: [10, 18],
    background: "#FFFFFF",
    border: "0.3px solid #D9D9D9",
    boxSizing: "border-box",
  },
  paymentDetailsItem: {
    width: "100%",
    fontSize: "10px",
    lineHeight: "12px",
    color: "#505050",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "&:first-child": {
      marginBottom: 7,
    },
  },

  orderDetailsWrapper: {},
  orderDetails: {
    padding: [10, 23],
    background: "#FFFFFF",
    border: "0.3px solid #D9D9D9",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "& img": {
      width: 35,
      objectFit: "contain",
    },
  },
  orderDetailContent: {
    flex: 1,
    padding: [0, 20],
    fontWeight: "normal",
    fontSize: "10px",
    lineHeight: "15px",
    color: "#2D2C2C",

    "& b": {
      fontWeight: "500 !important",
    },
  },
  orderDetailsRight: {
    width: 55,
    fontWeight: 300,
    fontSize: "10px",
    lineHeight: "15px",
    color: "#2D2C2C",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "& .anticon": {
      fontSize: 10,
      color: "#2D2C2C",
    },
  },
  discountWrapper: {
    marginTop: 20,
    textAlign: "center",
    padding: [20, 18],
    background: "#FFFFFF",
    border: "0.3px solid #D9D9D9",
    boxSizing: "border-box",
    color: "#505050",
    fontWeight: 600,
  },

  feedbackWrapper: {},
  feedback: {
    padding: [20, 18],
    background: "#FFFFFF",
    border: "0.3px solid #D9D9D9",
    boxSizing: "border-box",
  },
  feedbackTitle: {
    flex: 1,
    fontWeight: "500",
    fontSize: "10px",
    lineHeight: "12px",
    color: "#505050",
  },
  ratingOptions: {
    height: 50,
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    overflowX: "auto",

    "& .ant-avatar": {
      height: 28,
      width: 28,
      minWidth: 28,
      marginRight: 5,
      lineHeight: "28px",
      fontWeight: "500",
      fontSize: "10px",
      transition: "all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)",
    },
  },
  reviewHeader: {
    marginTop: 25,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "& .ant-btn-default": {
      color: "#2A9D8F",
      borderColor: "#2A9D8F",
      margin: "0 10px",
    },
  },
  textReviewInputField: {
    margin: [12, 0],
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "10px",
    lineHeight: "15px",
    color: "#727272",
    border: "0.3px solid #59595980",
  },
  submitReviewButton: {
    fontWeight: "500",
    fontSize: "10px",
    lineHeight: "15px",
    color: "#9C9C9C !important",
    background: "#fff !important",
  },
  contactStoreBtn: {
    fontSize: 12,
    margin: 10,

    "& .anticon": {
      fontSize: 14,
    },
  },
}));

export default OrderPlacedPage;
