import { MessageFilled } from "@ant-design/icons";
import Empty from "antd/es/empty";
import React, { useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import ChatDate from "components/chat/ChatDate";
import { Channel } from "twilio-chat/lib/channel";
import Loader from "components/loader/Loader";
import {
  retailerMessagesArray,
  retailerUpperArray,
} from "components/chat/chatfunctions";
import customeScrollbarStyle from "components/style/CustomScrollbar";
import ChatInput from "components/chat/ChatInput";
import ChatMessage from "components/chat/ChatMessage";
type chatUpperArray = any[];
type ChatExpandedCardProps = {
  retailStoreId: any;
  chatName: string;
  channel: Channel | undefined;
};

function ChatExpandedCard({
  retailStoreId,
  chatName,
  channel,
}: ChatExpandedCardProps) {
  // -------------NEW STATES IN USE-----------------------------
  const [chatData, setChatData] = useState({
    messages: [{}] as any,
    loading: true,
    intervalSet: false
  });
  const [msgInputValue, setMsgInputValue] = useState("");
  const messageContainerBottomRef = useRef<HTMLDivElement>(null);
  const setIntervalKey = useRef<any>(0);
  const autoScroll = useRef<any>(1);
  const [msgListener, setMsgListener] = useState(false);
  const classes = useStyles();
  // -----------------------------------------------------------

  async function getMessages() {
    if (channel !== undefined) {
      const newMessages = await channel.getMessages();
      const datedMessages = newMessages.items.map((item: any) => {
        return item.dateCreated.toDateString();
      });

      var UpperArray = [];
      for (var i = 0; i < datedMessages.length; i++) {
        if (i === 0) {
          var upperArrayElement = retailerUpperArray(
            newMessages.items[i],
            retailStoreId,
            datedMessages[i]
          );
          UpperArray.push(upperArrayElement);
        } else if (
          datedMessages[i] === UpperArray[UpperArray.length - 1].date
        ) {
          UpperArray[UpperArray.length - 1].messagesArray.push(
            retailerMessagesArray(newMessages.items[i], retailStoreId)
          );
        } else {
          UpperArray.push(
            retailerUpperArray(
              newMessages.items[i],
              retailStoreId,
              datedMessages[i]
            )
          );
        }
      }

      setChatData({
        messages: UpperArray,
        loading: false,
        intervalSet: chatData.intervalSet
      });
      if (autoScroll.current) {
        scrollToBottom();
      }
    }
  }

  const handleMessageSend = async (channel: any) => {
    if (msgInputValue) {
      try {
        await channel.sendMessage(String(msgInputValue).trim());
      } catch (err) {
        console.log("Failed to send the message, error: ", err);
        alert("Something went wrong, please reload !");
      }
      setMsgInputValue("");
    }
  };

  const handleGallery = async (target: any) => {
    const file = target.files;
    const formData = new FormData();
    formData.append("file", file[0]);
    if (channel !== undefined) {
      await channel.sendMessage(formData);
      getMessages();
    }
  };

  // function to scroll the messages container to bottom
  const scrollToBottom = () => {
    if (messageContainerBottomRef.current) {
      messageContainerBottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleScroll = async (e: any) => {
    let element = e.target;
    autoScroll.current = 0;

    if (
      element.scrollTop === 0 &&
      chatData.messages !== undefined &&
      channel !== undefined
    ) {
      var initialHeight2 = element.scrollHeight;

      let newMsgArray = [...chatData.messages];
      let lastObject = newMsgArray[0];
      if (lastObject !== undefined) {
        if (lastObject.messagesArray[0].message.index !== 0) {
          var messageIndex = lastObject.messagesArray[0].message.index - 1;

          clearInterval(setIntervalKey.current);
          setIntervalKey.current = 0;

          var oldMessages = await channel.getMessages(
            30,
            messageIndex,
            "backwards"
          );
          oldMessages.items.reverse();
          const datedMessages = oldMessages.items.map((item: any) =>
            item.dateCreated.toDateString()
          );

          for (let i = 0; i < datedMessages.length; i++) {
            if (datedMessages[i] === newMsgArray[0].date) {
              newMsgArray[0].messagesArray.unshift(
                await retailerMessagesArray(oldMessages.items[i], retailStoreId)
              );
            } else {
              newMsgArray.unshift(
                await retailerUpperArray(
                  oldMessages.items[i],
                  retailStoreId,
                  datedMessages[i]
                )
              );
            }
          }

          setChatData({
            messages: newMsgArray,
            loading: false,
            intervalSet: chatData.intervalSet
          });
        }
      }

      var finalHeight2 = element.scrollHeight;
      element.scrollTop = finalHeight2 - initialHeight2;
    } else if (
      element.scrollHeight - element.scrollTop <= 600
    ) {
      autoScroll.current = 1;
      // setIntervalForMsgs();
    }
  };

  const handleMsgInputChange = (e: any) => setMsgInputValue(e.target.value);

  // function setIntervalForMsgs() {
  //   //this needs to be improved or need to switch to new third-party provider
  //   if (setIntervalKey.current > 0) {
  //     // console.log("I'm sending back the request");
  //     return;
  //   }
  //   var intervalKey = setInterval(getMessages, 2000);
  //   setIntervalKey.current = intervalKey;
  //   if (!chatData.intervalSet) {
  //     setChatData({
  //       loading: chatData.loading,
  //       intervalSet: true,
  //       messages: chatData.messages
  //     });
  //   }
  // }

  function messageReceived(message: any) {
    // newMsgsCount.current++;
    getMessages();
  }

  useEffect(() => {
    if (msgInputValue === "") {
      getMessages();
      // setIntervalForMsgs();
      if (channel && !msgListener && !chatData.loading) {
        var listn = channel.on('messageAdded', messageReceived);
        if (listn) {
          setMsgListener(true);
        }
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, msgInputValue]);

  return (
    <div className={classes.chatExpandedCard} onScroll={(e) => handleScroll(e)}>
      <div className={classes.chatCardHeader}>
        <MessageFilled className={classes.chatIcon} />
        <div className={classes.chatTitle}>Chat with {chatName}</div>
      </div>
      <div className={classes.messagesContainer}>
        <>
          {!chatData.loading ? (
            <>
              {chatData.messages.length > 0 ? (
                <div className={classes.messagesList}>
                  {chatData.messages.map((chatItem: any, index: any) => (
                    <div key={index}>
                      {Object.entries(chatItem).map(
                        (obj: any, index: number) => {
                          if (obj.length > 0 && obj[0] === "date") {
                            return <ChatDate key={index} date={obj[1]} />;
                          }
                          if (obj.length > 0 && obj[0] === "messagesArray") {
                            var messages = obj[1];
                            if (messages.length > 0) {
                              return messages.map(
                                (message: any, index: number) => (
                                  <ChatMessage key={index} {...message} large />
                                )
                              );
                            }
                          }
                          return <></>
                        }
                      )}
                    </div>
                  ))}
                  <div ref={messageContainerBottomRef}></div>
                </div>
              ) : (
                <div className={classes.emptyContainer}>
                  <Empty description={<b>Start Chatting.!</b>} />
                </div>
              )}
            </>
          ) : (
            <Loader />
          )}
        </>
      </div>

      <ChatInput
        onMessageSend={() => {
          handleMessageSend(channel);
        }}
        onAttachmentChange={(e: any) => handleGallery(e.target)}
        value={msgInputValue}
        onChange={handleMsgInputChange}
        placeholder="Type Something..."
      />
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  chatExpandedCard: {
    width: "100%",
    height: "100%",
    padding: "1rem",
    backgroundColor: colors.light100,
    borderRadius: "5px",
    boxShadow:
      "-1px -1px 4px rgba(34, 94, 119, 0.25), 1px 1px 4px rgba(34, 94, 119, 0.25)",
    position: "relative",
  },
  messagesList: {
    height: "100%",
    width: "100%",
    overflowY: "auto",
  },

  // Chat top
  chatCardHeader: {
    height: "20px",
    marginBottom: "15px",
    display: "flex",
    paddingLeft: "5px",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  chatIcon: {
    marginRight: "1rem",
    fontSize: "1.4rem",
    color: colors.primary200,
  },
  chatTitle: {
    fontWeight: 700,
    fontSize: 16,
    color: colors.primary200,
  },
  // msessages
  messagesContainer: {
    height: "calc(100% - 80px)",
    padding: "0.5rem 0",
    overflowY: "auto",
    boxShadow: "0px -6px 50px -30px rgba(0,0,0,0.4) inset",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    ...customeScrollbarStyle,
  },
  emptyContainer: {
    width: "100%",
    height: "100%",
    color: "#505050",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  //   chat bottom
  chatInputContainer: {
    width: "100%",
    padding: "0.5rem",
    paddingLeft: "0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  iconName: {
    fontSize: ".9rem",
    color: colors.primary,
  },
  attachmentButton: {
    border: "none",
    "& .anticon": {
      fontSize: "1.5rem",
    },
  },
  sendMsgButton: {},
  msgInputField: {
    width: "100%",
    paddingRight: "1rem",
    paddingLeft: "0.5rem",

    "& input": {
      width: "100%",
      height: "44px",
      lineHeight: "95%",
      fontWeight: 600,
    },
  },
  "@media (max-width: 1050px)": {
    chatExpandedCard: { paddingTop: "0.5rem !important" },
    messagesContainer: { height: "calc(100% - 90px)" },
    chatTitle: { fontSize: "1.1rem" },
    msgInputField: {
      "& input": { height: 35 },
    },
  },
}));

export default ChatExpandedCard;
