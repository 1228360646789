import { useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

import client from "client";
import { getSession, logout, Session } from "client/reactives/session";
import { useDestroySessionMutation } from "generated/schema";
import {
  getConnectedRetailStores,
  getRetailerRetailStores,
  unsubscribeFCMTopic,
} from "api/api";
import firebase from "firebase/app";

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

function LogoutPage() {
  const { push } = useHistory();
  const query = useQuery();
  const [destroySession] = useDestroySessionMutation();
  const { token, userType, userId } = getSession() as Session;

  async function tokenFunc(topic: any, type: any) {
    if (!!token) {
      await unsubscribeFCMTopic({
        topic,
        user_id: userId,
        regTokens: [token],
        application_type: type,
      }).then((res) => {
        if (!res?.errors?.length) {
          localStorage.setItem("subscribedToNotifications", "false");
        }
      });
    }
  }

  const unsubscribeFCM = async () => {
    let subscribedToNotifications = localStorage.getItem(
      "subscribedToNotifications"
    );
    if (userType === "Customer" && subscribedToNotifications === "true") {
      // @ts-ignore
      await getConnectedRetailStores(+userId).then((res) => {
        if (res?.Published?.length) {
          res.Published.map((i: any) => {
            if (!!i?.notifications_enabled) {
              tokenFunc("store_" + i?.retail_store_id, "customer");
              console.log("store_" + i?.retail_store_id);
            }
          });
        }
      });
    } else if (
      userType === "Retailer" &&
      subscribedToNotifications === "true"
    ) {
      await getRetailerRetailStores().then((res) => {
        if (res?.length) {
          tokenFunc("store_" + res[0]?.id, "retailer");
          console.log("store_" + res[0]?.id);
        }
      });
    }
  };

  // @ts-ignore
  //const handleLogout = useSubmitHandler(destroySession)
  const handleLogout = async () => {
    const url = process.env.REACT_APP_API_NODE_BASE_URL;
    let data = {
      expiry_status: "'expired'",
    };
    //handleCreateUser(values)
    await fetch(url + "/logout", {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "x-access-token": `${token}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {});

    // await unsubscribeFCM();
  };
  useEffect(() => {
    // @ts-ignore
    handleLogout().finally(() => {
      logout();
      client.clearStore();
      push(query?.get("redirect") ?? "/login");
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

export default LogoutPage;
