import React, { useEffect, useState } from "react";
import { Input, message, Select } from "antd";
import { Link } from "react-router-dom";
import { createUseStyles } from "react-jss";
import LoginPageBg from "assets/login-page-bg.png";
import constants from "constants/constants";
import RaseetLogo from "assets/raseet-logo-wide.png";
import { generateLoginOtp, OtpLogin } from "api/api";
import EnterOtpPage from "./EnterOtpPage";
import gtag from "components/Tracking/gtag";
import EnterPasswordPage from "./EnterPasswordPage";
const { Option } = Select;

interface CustomizedLoginPage {
  onPasswordSubmit: (values: any) => void;
  retailStore: {
    name: string | null;
    logo_data: {
      key: string | null;
    };
  };
}

export default function CustomizedLoginPage({
  onPasswordSubmit,
  retailStore,
}: CustomizedLoginPage) {
  const classes = useStyles();
  const [mobileNumber, setMobileNumber] = useState("");

  const [otpBoxVisible, setOtpBoxVisible] = useState(false);
  const [resendVisible, setResendVisible] = useState(false);
  const [deadline, setDeadline] = useState(Date.now());
  const [loginWithOtp, setLoginWithOtp] = useState(true);

  const handleMobileNumberChange = (e: any) => {
    if (e.target.value.length <= 10) {
      setMobileNumber(e.target.value);
    }
  };

  const handleSendOtp = async () => {
    try {
      const res = await generateLoginOtp({
        mobile_number: mobileNumber,
        otp_type: "'Login'",
      });
      if (res.error) {
        message.error(res.error);
        return;
      }
    } catch (e: any) {
      message.error(e);
      return;
    }
    setOtpBoxVisible(true);
    setDeadline(Date.now() + 30 * 1000);
  };

  const handlePasswordLogin = (password: string) => {
    onPasswordSubmit({
      mobileNumber,
      password,
    });
  };

  const handleOTPSubmit = async (otp: any) => {
    try {
      const res = await OtpLogin({
        otp,
        mobile_number: mobileNumber,
        otp_type: "'Login'",
      });

      if (res.error) {
        message.error(res.error);
      } else {
        gtag("event", "login_via_otp", {
          type: "customer",
        });
      }
    } catch (e: any) {
      message.error(e);
    }
  };

  const onFinishCounter = () => setResendVisible(true);

  const toggleLoginMode = () => setLoginWithOtp(!loginWithOtp);

  const handleBack = () => {
    setOtpBoxVisible(false);
    setDeadline(Date.now());
    setResendVisible(false);
  };

  useEffect(() => {
    if (mobileNumber.length === 10) {
      handleSendOtp();
    }
  }, [mobileNumber]);

  if (otpBoxVisible) {
    return loginWithOtp ? (
      <EnterOtpPage
        deadline={deadline}
        mobileNumber={mobileNumber}
        resendVisible={resendVisible}
        onFinishCounter={onFinishCounter}
        onSubmit={handleOTPSubmit}
        onBack={handleBack}
        handleSendOtp={handleSendOtp}
        onLoginModeChange={toggleLoginMode}
      />
    ) : (
      <EnterPasswordPage
        onBack={handleBack}
        mobileNumber={mobileNumber}
        onSubmit={handlePasswordLogin}
        onLoginModeChange={toggleLoginMode}
      />
    );
  }

  return (
    <>
      <div className={classes.pageHeadWrapper}>
        <div className={classes.pageHead}>
          <img
            className={classes.storeLogo}
            src={constants.s3Url + retailStore.logo_data.key}
          />
          <div className={classes.storeName}>{retailStore.name}</div>
        </div>
        <div className={classes.pageHeadSubtitle}>Online Healthcare Hub</div>
      </div>

      <div className={classes.formWrapper}>
        <div className={classes.formTitle}> Login</div>

        <Input.Group compact className={classes.mobileInput}>
          <Select defaultValue="+91" style={{ width: "80px" }}>
            <Option value="+91">+91</Option>
          </Select>
          <Input
            placeholder="Enter your mobile number"
            onChange={handleMobileNumberChange}
          />
        </Input.Group>

        {/* <Link to="/">
          <div className={classes.helpingLink}>Trouble signing in?</div>
        </Link> */}
        <Link to="/register">
          <div className={classes.helpingLink}>Register</div>
        </Link>
      </div>

      <div className={classes.poweredByWrapper}>
        <div className={classes.poweredByText}>Powered by</div>
        <img src={RaseetLogo} className={classes.raseetLogoWide} />
      </div>
    </>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  storeLogo: {
    width: "3.5rem",
    height: "3.5rem",
    objectFit: "contain",
  },
  storeName: {
    width: "min-content",
    paddingLeft: 15,
    fontSize: 24,
    fontWeight: 600,
    color: "#fff",
    textTransform: "uppercase",
  },
  formTitle: {
    fontSize: 20,
    padding: [10, 10, 15],
    fontWeight: 600,
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
  },
  pageHeadSubtitle: {
    width: "100%",
    paddingBottom: 25,
    fontSize: 22,
    fontWeight: 600,
    position: "absolute",
    bottom: 0,

    color: "#fff",
    textAlign: "center",
  },
  pageHeadWrapper: {
    background: `url('${LoginPageBg}')`,
    backgroundSize: "cover",
    position: "relative",
  },
  pageHead: {
    height: "55vh",
    paddingTop: 120,
    background: "rgba(108, 150, 188, 0.35)",
    // borderRadius: "0% 100% 0% 100% / 72% 0% 100% 28% ",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  formWrapper: {
    padding: [20, 40],
  },
  mobileInput: {
    padding: 5,
    marginBottom: 10,
    display: "flex !important",
    border: "1px solid #d9d9d9 !important",
    borderRadius: 5,

    "&  .ant-select-selector, & > .ant-input": {
      border: "none !important",
    },
    "& > .ant-input": {
      borderLeft: "1px solid #d9d9d9 !important",
      "&:hover, &:focus": {
        boxShadow: "none !important",
      },
    },
  },
  helpingLink: {
    paddingTop: 15,
    textDecoration: "underline",
    color: colors.dark400,
  },
  poweredByWrapper: {
    textAlign: "right",
    position: "absolute",
    right: 10,
    bottom: 10,
  },
  poweredByText: {
    paddingRight: 5,
    fontSize: 10,
    color: colors.primary,
  },
  raseetLogoWide: {
    height: 25,
  },
}));
