import { ArrowLeftOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import React from "react";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";

function PricingPolicy() {
  const classes = useStyles();
  const history = useHistory();

  const Section = ({ title, children }: any) => (
    <div className={classes.pageSection}>
      <div className={classes.sectionTitle}>{title}</div>
      <div className={classes.sectionContent}>{children}</div>
    </div>
  );

  return (
    <div className={classes.privacyPolicyPage}>
      <Row className={classes.header}>
        <Col className={classes.backButton} span={3}>
          <ArrowLeftOutlined onClick={() => history.goBack()} />
        </Col>
        <Col className={classes.pageHeader} span={20}>
          Pricing Policy
        </Col>
      </Row>
      <div className={classes.pageContent}>
        <div className={classes.pageSubtitle}>
          RASEET is a platform that helps your local stores serve you digitally.
          Thus, RASEET does not decide the pricing of any product being sold
          on the platform. The pricing of each product is solely governed by
          the partner store.
        </div>

        <Section title="Offers & Promotions">
          RASEET may engage the partner stores in different promotional campaigns
          to provide more value to its respective customers. The offered
          discounts given under such promotions are either auto applied or
          are open to choice for the customer at the time of checkout.
        </Section>
        <Section title="GST">
          The applicable Goods & Service Taxes are included in the final amount to be
          paid by the customer and are also clearly mentioned at the time of checkout.
        </Section>

        <Section title="Questions">
          If you have any questions concerning the return policy, please reach out
          to us at: <a href="mailto:hello@raseet.com">hello@raseet.com</a>
        </Section>
      </div>
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  privacyPolicyPage: {
    background: "#fff",
  },
  backButton: {
    textAlign: "center",
  },
  pageHeader: {
    fontSize: 13,
    fontWeight: 700,
  },
  header: {
    height: 58,
    position: "sticky",
    top: "0",
    background: "transparent",
    alignItems: "center",
  },
  pageContent: {
    height: "calc(100vh - 58px)",
    padding: 15,
    overflowY: "auto",
  },
  pageSection: {
    marginBottom: 40,
  },
  pageSubtitle: {
    fontSize: 12,
    fontWeight: 500,
    margin: [0, 0, 30],
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: 500,
    marginBottom: 10,
  },
  sectionContent: {
    fontSize: 10,
    whiteSpace: "break-spaces",
    textAlign: "justify",

    "& b": {
      fontWeight: "500 !important",
    },
    "& ul": {
      paddingLeft: 30,
    },
  },
}));

export default PricingPolicy;
