import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(({ colors }: Theme) => ({
  conversionFactorCard: {
    padding: 25,
    marginTop: 45,
    background: "#E6EBEE",
    borderRadius: "5px",
  },
  cardTitle: {
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "24px",
    color: "#000000",
  },
  cardSubTitle: {
    fontWeight: "normal",
    fontSize: "10px",
    lineHeight: "20px",
    color: "#000000",
  },
  setConversionFactorWrapper: {
    marginTop: 10,
    display: "flex",
    justifyContent: "flex-start",

    "& .ant-input-affix-wrapper": {
      width: 50,
      fontSize: "12px",
    },
    "& .ant-input": {
      textAlign: "right",
      fontSize: "12px",
    },
    "& div:last-child": {
      width: "100%",
    },
  },
  setConversionFactorWrapperText: {
    minWidth: 210,
    marginRight: 10,
    fontSize: "12px",
    lineHeight: "23px",
  },
  bottomText: {
    padding: [10, 0],
    fontStyle: "italic",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "20px",
    color: "#565656",
    tiersList: {},
  },
  tiersList: {
    margin: [10, 0, 20],

    "& .ant-divider": {
      margin: 0,
      borderTop: "1px solid rgba(0, 0, 0, 0.26)",
    },
    "& .ant-divider:last-child": {
      display: "none",
    },
  },
  tierItemTitle: {
    minWidth: 170,
    marginRight: 10,
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "14px",
    color: "#000",
    textTransform: "capitalize",
  },
  tierItem: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",

    "& .ant-input-affix-wrapper": {
      width: 50,
      marginRight: 25,
      fontSize: "12px",
    },
    "& .ant-input": {
      textAlign: "right",
      fontSize: "12px",
    },
  },
}));

export default useStyles;
