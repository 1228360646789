import Button from "antd/es/button";
import React, { useState, useEffect } from "react";
import { createUseStyles } from "react-jss";
import { Col, message } from "antd";
import FormBox from "components/FormBox";
import OnboardingFormFooter from "components/OnboardingFormFooter";
import LoginForm from "components/forms/LoginForm";
import OnboardingWrapper from "components/OnboardingWrapper";
import { setSession } from "client/reactives/session";
import Space from "antd/es/space";
import LoginWithOtp from "./LoginWithOtp";
import gtag from "components/Tracking/gtag";
import { Link } from "react-router-dom";
import { useLocation, useHistory } from "react-router-dom";
import localforage from "localforage";
import { getRetailStoreBasicDetails, userLoginByUID } from "api/api";
import CustomizedLoginPage from "./CustomizedLoginPage";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  finalLogo: {
    width: "6rem",
    height: "7rem",
    marginBottom: "3rem",
    objectFit: "contain",
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

type LoginPageProps = {
  isAdmin?: boolean;
};

function LoginPage({ isAdmin, ...props }: LoginPageProps) {
  const classes = useStyles();
  const query = useQuery();
  const { push, replace } = useHistory();
  const [loginWithOtp, setLoginWithOtp] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [retailStoreCode, setRetailStoreCode] = useState<string | null>();
  const [retailStore, setRetailStore] = useState({
    name: null,
    logo_data: {
      key: null,
    },
  });

  const fetchRetailStoreDetails = async (code: any) => {
    if (!!code) {
      const data = await getRetailStoreBasicDetails(code);

      if (data?.length) {
        setRetailStore(data[0]);
      }
    }
  };

  ///const handleCreateSession = useSubmitHandler(createSession)
  const handleCreateSession = async ({ ...values }) => {
    const url = process.env.REACT_APP_API_NODE_BASE_URL;
    let data = {
      mobile_number: `${values.mobileNumber}`,
      password: `${values.password}`,
      retail_store_code: retailStoreCode,
    };
    await fetch(url + "/login", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.auth) {
          setSession(
            json.token,
            json.user_id,
            json.user_type,
            json.user_name,
            json.mobile_number
          );
          gtag("event", "login_via_password", {
            type: "customer",
          });
          localforage.setItem("userType", json.user_type);

          if (!!query.get("redirect_url")) {
            push(query.get("redirect_url") ?? "");
          }
        } else {
          message.info(json.error);
        }
      });
  };

  const handleUserAutoLogin = async (uuid: any) => {
    let userLoginData = await userLoginByUID(uuid, true);
    // set userData to session
    if (userLoginData) {
      setSession(
        userLoginData.token,
        userLoginData.user_id,
        userLoginData.user_type,
        userLoginData.user_name,
        userLoginData.mobile_number
      );
    }

    // add GA Event only for no auth bill pages to track number of views on the bill pages
    if (window.location.hostname === "app.raseet.com") {
      gtag("event", "auto_login", {
        dimension_name: "Customer Dashboard",
        utm_source: query.get("redirect_url") || "/login",
      });
    }

    if (!!userLoginData.token) {
      let redirectUrl = query.get("redirect_url") ?? "/dashboard/stores";

      replace("/login");
      push(redirectUrl);
    }
  };

  useEffect(() => {
    if (!!query.get("user_uuid")) {
      handleUserAutoLogin(query.get("user_uuid"));
    }
  }, []);

  useEffect(() => {
    if (retailStoreCode !== query.get("retail_store_code")) {
      setRetailStoreCode(query.get("retail_store_code"));
      fetchRetailStoreDetails(query.get("retail_store_code"));
    }
  }, [retailStoreCode]);

  if (!!retailStore?.name) {
    return (
      <CustomizedLoginPage
        onPasswordSubmit={handleCreateSession}
        retailStore={retailStore}
      />
    );
  }

  return (
    <>
      <OnboardingWrapper height="full">
        <Space direction="vertical" align="center" style={{ width: "100%" }}>
          <img
            className={classes.finalLogo}
            src={process.env.PUBLIC_URL + "/Raseet-final-logo.png"}
          />
        </Space>
        <FormBox title="Login to Raseet">
          {!loginWithOtp ? (
            <LoginForm
              onSubmit={handleCreateSession}
              isAdmin={isAdmin}
              setMobileNumber={setMobileNumber}
              defaultValues={{
                isAdmin,
                mobileNumber,
              }}
            />
          ) : (
            <LoginWithOtp
              setMobileNumber={setMobileNumber}
              defaultValues={{ mobileNumber }}
            />
          )}
          <OnboardingFormFooter>
            <Button style={{ color: "#2A9D8F" }} type="link" href="/register">
              Register
            </Button>
            <Button
              style={{ color: "#2A9D8F" }}
              type="link"
              id="forgot-password-btn"
              href="/forgot-password"
              hidden={loginWithOtp}
            >
              Forgot password?
            </Button>
            <br></br>
            <Button
              style={{ color: "#2A9D8F" }}
              type="link"
              hidden={loginWithOtp}
              onClick={() => setLoginWithOtp(true)}
            >
              Login with OTP
            </Button>
            <Button
              style={{ color: "#2A9D8F" }}
              type="link"
              hidden={!loginWithOtp}
              onClick={() => setLoginWithOtp(false)}
            >
              Login with password
            </Button>
          </OnboardingFormFooter>
        </FormBox>
      </OnboardingWrapper>
      <Col
        xs={{ span: 18, offset: 3 }}
        sm={{ span: 14, offset: 5 }}
        lg={{ span: 8, offset: 8 }}
        style={{ marginTop: -20 }}
      >
        <div className={classes.footer}>
          <Link to="/terms-and-conditions">Terms & Conditions</Link>
          <Link to="/about-us">About Us</Link>
        </div>
        <div className={classes.footer}>
          <Link to="/return-policy">Return Policy</Link>
          <Link to="/pricing-policy">Pricing Policy</Link>
        </div>
        <div className={classes.footer}>
          <Link to="/privacy-policy">Privacy Policy</Link>
        </div>
      </Col>
    </>
  );
}

export default LoginPage;
