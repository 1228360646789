import { Empty, Select } from "antd";
import { fetchOrders } from "api/api";
import { changeCustomerPriority } from "api/retailerApi";
import SearchBox from "components/dataEntry/SearchBox";
import Loader from "components/loader/Loader";
import { RetailerDataType } from "components/pages/retailerDashboard";
import { fetchReorderCustomers } from "components/pages/retailerDashboard/APIs/fetchReorderCustomers";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import RadioToggler from "../IncomingOrders/RadioToggler";
import OrdersCard from "./OrdersCard";

interface OrdersPageProps {
  retailerData: RetailerDataType;
}

export default function OrdersPage({ retailerData }: OrdersPageProps) {
  const classes = useStyle();
  const [ordersData, setOrdersData] = useState({
    orders: [],
    priorityOrders: [],
    selectedOrderIndex: -1,
    loading: true,
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [endOfList, setEndOfList] = useState(false);
  const [orderCategory, setOrderCategory] = useState("All");
  const [activeStatus, setActiveStatus] = useState("");

  const handleSearch = (value: string) => {
    fetchInitialData(value);
  };

  const handleScroll = async (e: any) => {
    //console.log("test")
    if (endOfList) {
      return;
    }
    //check if reached bottom
    let element = e.target;
    //console.log(element.scrollHeight, element.scrollTop, element.clientHeight);
    if (
      element.scrollHeight - Math.round(element.scrollTop) ===
      element.clientHeight
    ) {
      setPageNumber((pageNumber: any) => pageNumber + 1);
      //console.log("eof");
    }
  };

  const toggleCustomerPriorityCallback = (
    isPriorityNow: boolean,
    customer_id: any
  ) => {
    let newData: any = {};
    if (isPriorityNow) {
      newData.orders = ordersData.orders.map((item: any) =>
        item.user_id === customer_id ? { ...item, priority: true } : item
      );
    } else {
      newData.orders = ordersData.orders.map((item: any) =>
        item.user_id === customer_id ? { ...item, priority: false } : item
      );
    }
    newData.priorityOrders = newData.orders.filter(
      (item: any) => item.priority
    );

    console.log(newData);
    setOrdersData({ ...ordersData, ...newData });
  };

  const toggleCustomerPriority = async (customerUserId: any) => {
    if (customerUserId !== "")
      await changeCustomerPriority(customerUserId, retailerData?.ID).then(
        (result) => {
          toggleCustomerPriorityCallback(
            result.priority.includes(`${retailerData?.ID}`),
            result.user_id
          );
        }
      );
  };

  async function fetchInitialData(search?: any, page?: any) {
    let reorderData = await fetchOrders(
      retailerData.ID,
      page ?? pageNumber,
      search ?? ""
      //   activeStatus
    );

    setEndOfList(reorderData?.length === 0);

    if (pageNumber === 1 || page === 1) {
      setOrdersData({
        orders: filterReorderCustomers(reorderData, "All"),
        priorityOrders: filterReorderCustomers(reorderData, "Priority"),
        selectedOrderIndex: 0,
        loading: false,
      });
    } else {
      reorderData = [
        ...ordersData.orders,
        ...ordersData.priorityOrders,
        ...reorderData,
      ];
      setOrdersData({
        orders: filterReorderCustomers(reorderData, "All"),
        priorityOrders: filterReorderCustomers(reorderData, "Priority"),
        selectedOrderIndex: 0,
        loading: false,
      });
    }
  }

  function filterReorderCustomers(reorderData: any, category: string) {
    if (category === "All") {
      return reorderData;
    }
    var priorityOrders = [];
    for (var i = 0; i < reorderData?.length; i++) {
      if (reorderData[i].priority) {
        priorityOrders.push(reorderData[i]);
      }
    }
    return priorityOrders;
  }

  useEffect(() => {
    fetchInitialData();
  }, [pageNumber, activeStatus]);

  useEffect(() => {
    if (retailerData.ID && ordersData.orders.length === 0) {
      fetchInitialData();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retailerData.ID]);

  useEffect(() => {
    if (ordersData.loading === false) {
      setOrdersData({
        orders: ordersData.orders,
        priorityOrders: ordersData.priorityOrders,
        selectedOrderIndex: 0,
        loading: false,
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderCategory]);

  if (ordersData.loading) {
    return <Loader />;
  }

  return (
    <div className={classes.incomingOrders}>
      <RadioToggler onChange={setOrderCategory} />

      <SearchBox onSearch={handleSearch} />

      <div
        className={classes.incomingOrdersCardsWrapper}
        onScroll={handleScroll}
      >
        {ordersData[orderCategory === "All" ? "orders" : "priorityOrders"]
          .length ? (
          ordersData[orderCategory === "All" ? "orders" : "priorityOrders"].map(
            (reorder: any) => (
              <OrdersCard
                orderStatus={reorder.order_status}
                date={reorder?.invoice_date ?? reorder.created_at}
                paymentStatus={reorder.payment_status}
                customerName={reorder?.customer_name}
                orderId={reorder.id}
                invoiceNumber={reorder.invoice_number}
                isPriority={reorder.priority}
                onPriorityToggle={() =>
                  toggleCustomerPriority(reorder?.user_id)
                }
                orderAmount={reorder.total}
              />
            )
          )
        ) : (
          <div className={classes.emptyContainer}>
            <Empty description="No data found" />
          </div>
        )}
      </div>
    </div>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  incomingOrders: {
    height: "calc(100vh - 130px)",
    overflow: "hidden",
    padding: "0 2rem",
    background: "#fbfbfb",
  },
  incomingOrdersCardsWrapper: {
    height: "calc(100vh - 285px)",
    overflowY: "auto",
  },
  emptyContainer: {
    height: "50vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  statusSelector: {
    width: 110,
    border: "none",
  },
}));
