import * as yup from "yup";

import BaseModel from "./BaseModel";

class User extends BaseModel {
  static schema = yup.object().shape({
    name: yup.string().required(),
    mobileNumber: yup.string().length(10, "should be 10 digits").required(),
    email: yup.string().email().required(),
    password: yup.string().min(8).max(72).required(),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password")], "doesn't match password")
      .required(),
    otp: yup.string().length(6, "should be 6 digits").required(),
    // address
    streetAddress: yup
      .string()
      .trim()
      .required()
      .min(5, "Minimum 5 characters"),
    state: yup.string(),
    city: yup.string().trim().required(),
    locality: yup.string(),
    pincode: yup.string().trim().required().length(6, "Invalid pincode"),
  });

  static customerAddress<TInput>() {
    return User.validationResolver<TInput>([
      "streetAddress",
      "state",
      "city",
      "locality",
      "pincode",
    ]);
  }

  static registerUserWithoutPassword<TInput>() {
    return User.validationResolver<TInput>(["name", "mobileNumber"]);
  }

  static addCustomer<TInput>() {
    return User.validationResolver<TInput>(["name", "mobileNumber"]);
  }

  static registerUser<TInput>() {
    return User.validationResolver<TInput>([
      "name",
      "mobileNumber",
      "password",
      "confirmPassword",
    ]);
  }

  static requestMobileConfirm<TInput>(withoutName?: boolean) {
    const fields = ["mobileNumber"];

    if (!withoutName) {
      fields.push("name");
    }

    return User.validationResolver<TInput>(fields);
  }

  static verifyMobile<TInput>(withoutName?: boolean) {
    const fields = ["mobileNumber", "otp"];

    if (!withoutName) {
      fields.push("name");
    }

    return User.validationResolver<TInput>(fields);
  }

  static validateLogin<TInput>() {
    return User.validationResolver<TInput>(["mobileNumber", "password"]);
  }

  static validateLoginWithOtp<TInput>() {
    return User.validationResolver<TInput>(["mobileNumber", "otp"]);
  }

  static validateAdminLogin<TInput>() {
    return User.validationResolver<TInput>(["email", "password"]);
  }

  static validateOTP<TInput>() {
    return User.validationResolver<TInput>(["otp"]);
  }

  static validateResetPassword<TInput>() {
    return User.validationResolver<TInput>([
      "mobileNumber",
      "password",
      "confirmPassword",
    ]);
  }

  static validateChangePassword<TInput>() {
    return User.validationResolver<TInput>(["password", "confirmPassword"]);
  }
}

export default User;
