import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(({ colors }: Theme) => ({
  activeOrdersPage: {
    height: "100vh",
    background: colors.light300,
  },
  header: {
    height: 60,
    display: "flex",
    zIndex: 6,
    background: "inherit",
    width: "100%",
    position: "fixed",
    alignItems: "center",
  },
  headerIcon: {
    padding: 20,
    fontSize: "1.4rem",
  },
  headerTitle: {
    fontWeight: 600,
    fontSize: "1.1rem",
  },
  deliveryAddress: {
    background: "#fff",
    padding: "0.2rem 1rem",

    "& .ant-typography": {
      fontSize: 11,
    },

    "& .anticon": {
      marginTop: 3,
      fontSize: 20,
    },
  },
  orderDetailsView: {
    height: "100vh",
    width: "100%",
    overflowY: "auto",
    paddingBottom: "1rem",
    paddingTop: 70,
  },
  orderItems: {
    height: "100%",
    padding: "2rem",
    overflowY: "auto",
    background: "inherit",
  },
  itemCard: {
    padding: "0 0.5rem",
    marginBottom: "1rem",
    boxShadow: "-1px -1px 2px rgb(0 0 0 / 15%), 1px 1px 2px rgb(0 0 0 / 15%)",
    fontWeight: 700,
    fontSize: 12,
    color: "#505050",
    fontFamily: "'Roboto'",
  },
  itemCardInner: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  itemQuantity: {
    width: 55,
    marginTop: 5,
    padding: "5px 3px",
    fontWeight: 500,
    fontSize: 11,
    textAlign: "center",
    color: "#848484",
    background: "#f0f0f0",
    borderRadius: 4,
  },
  itemAmount: {
    color: "#848484",
    fontSize: 12,
  },

  footer: {
    width: "100%",
    height: 60,
    padding: "0 1rem",
    position: "fixed",
    bottom: 0,
    left: 0,
    zIndex: 7,
    background: colors.light100,
    boxShadow: "0px -4px 10px rgba(0, 0, 0, 0.07)",
  },
  footerAmount: {
    fontWeight: 700,
    paddingLeft: "1rem",
    fontSize: 12,
  },
  orderPrescriptions: {
    height: 55,
    transition: "all 0.3s",
    width: "100%",
    padding: "0.7rem 1.5rem",
    borderRadius: "2rem 2rem 0 0",
    background: colors.light100,
    position: "fixed",
    bottom: 46,
    left: 0,
    boxShadow: "0px -4px 10px rgba(0, 0, 0, 0.07)",
  },
  prescriptionsTitle: {
    padding: "0 10px",
    fontSize: 13,
    fontWeight: 600,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& *": {
      transition: "0.3s",
    },
  },
  prescriptionsMidTitle: {
    padding: "0 10px",
    fontSize: 13,
    fontWeight: 600,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& *": {
      transition: "0.3s",
    },
  },
  prescription: {
    height: 46,
    marginTop: "0.8rem",
    padding: 5,

    "& > img": {
      height: "100%",
      width: "100%",
      objectFit: "contain",
    },
  },
}));

export default useStyles;
