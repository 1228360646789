import React from 'react'
import AntRow from 'antd/es/row'
import { createUseStyles } from 'react-jss'
import type { RowProps } from 'antd/es/row'

const useStyles = createUseStyles({
  row: {
    marginLeft: '0 !important',
    marginRight: '0 !important'
  }
})

function Row(props: RowProps) {
  const classes = useStyles()

  return (
    <AntRow
      className={classes.row}
      gutter={[ { xs: 8, sm: 16, md: 24, lg: 32 }, { xs: 8, sm: 16, md: 24, lg: 32 } ]}
      {...props}
    />
  )
}

export default Row
