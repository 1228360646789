import React from "react";
import { createUseStyles } from "react-jss";

type StepProps = {
  index: number;
  label?: string;
  isActive: boolean;
};

const Step = ({ index, label, isActive }: StepProps) => {
  const classes = useStyles({ showIndexOnly: label ? false : true });

  let stepStyles = isActive
    ? `${classes.step} ${classes.active}`
    : classes.step;

  return (
    <>
      <div className={stepStyles}>
        <div className={classes.stepIndex}>{index}</div>
        <div className={classes.stepLabel}>{label}</div>
      </div>
      <div className={classes.stepLine}></div>
    </>
  );
};

const useStyles = createUseStyles(({ colors }: Theme) => ({
  step: {
    margin: "1rem 0",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",

    "& $active": {
      display: "none",
    },
  },
  stepLine: {
    margin: "3px 15px",
    width: "2px",
    height: "4rem",
    backgroundColor: colors.light500,

    "&:last-child": {
      display: "none",
    },
  },
  stepIndex: {
    height: "32px",
    width: "32px",
    objectFit: "contain",
    fontSize: "0.8rem",

    marginRight: ({ showIndexOnly }) => (showIndexOnly ? "" : "2rem"),
    borderRadius: "50%",
    border: `1.5px solid ${colors.light500}`,

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  stepLabel: { fontSize: "1.1rem", fontWeight: 600, color: colors.light500 },
  active: {
    "& $stepIndex": {
      border: `2px dashed ${colors.light100}`,
    },
    "& $stepLabel": {
      color: colors.light100,
    },
  },
}));

export default Step;
