import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(({ colors }: Theme) => ({
  sidebar: {
    width: "100%",
    height: "calc(100vh + 10px)",
    overflow: "auto",
    padding: "0.5rem",
    backgroundColor: colors.primary200,

    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  logo: {
    width: ({ sidebarCollapsed }) => (sidebarCollapsed ? "35px" : "100%"),
    margin: "auto",
    marginTop: "1rem",
    marginBottom: "7vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",

    "& img": {
      width: ({ sidebarCollapsed }) => (sidebarCollapsed ? "100%" : "80%"),
      margin: "auto",
      objectFit: "contain",
    },
  },
  sidebarToggleButton: {
    border: "none",
    background: "transparent",
    marginTop: "1.3rem",

    "& .anticon": {
      color: "#ffffffa3",
      fontSize: "1.35rem",
    },
    "& *": {
      transition: "all .7s",
    },
  },
  sidebarMenu: {
    "& $menuOption": {
      marginBottom: "1.2rem",
    },
  },
  menuOption: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    opacity: "0.5",
    "&:hover": {
      opacity: "0.8",
    },
  },
  activeMenuOption: {
    opacity: "1",
    "&:hover": {
      opacity: "1",
    },
    "& $menuIcon": {
      color: colors.primary200,
      backgroundColor: colors.light100,
    },
  },
  menuIcon: {
    width: 35,
    height: 35,
    margin: "0.4rem",
    fontSize: "1.25rem",
    borderRadius: "0.2rem",
    display: "flex",
    color: colors.light100,
    justifyContent: "center",
    alignItems: "center",
  },
  menuLabel: {
    color: colors.light100,
    textAlign: "center",
    fontSize: 12,
    fontFamily: "Roboto",
    fontWeight: 500,
    display: ({ sidebarCollapsed }) => (sidebarCollapsed ? "none" : "block"),
  },
  "@media (max-width: 1050px)": {
    menuIcon: {
      width: "2.2rem",
      height: "2.2rem",
      fontSize: "1.2rem",
    },
    menuLabel: {
      fontSize: "0.7rem",
      letterSpacing: "0.2px",
    },
    logo: {
      "& img": { width: "85%", marginBottom: "-15px !important" },
    },
  },
}));

export { useStyles };
