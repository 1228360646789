import React, { useEffect, useState } from "react";
import {
  generatePutUrl,
  getRetailStores,
  updateAddresses,
  updatePassword,
  updateRetailStores,
  updateUser,
  uploadImage,
} from "api/api";
import EditBusinessProfileForm from "./../../../forms/EditBusinessProfileForm";
import Loader from "components/loader/Loader";
import { getSession, Session, setSession } from "client/reactives/session";
import { message } from "antd";
import constants from "constants/constants";

function EditBusinessProfile({ retailerData, setRetailerInfo }: any) {
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("#business-details");
  const { userName, mobileNumber, token, userId, userType } =
    getSession() as Session;

  useEffect(() => {
    (async () => {
      if (loading === true) {
        await loadPageData();
      }
    })();
  }, [loading]);

  const loadPageData = async () => {
    const result = await getRetailStores(retailerData?.ID);
    setData({
      ...result,
      whatsapp_check1: result.whatsapp_number?.includes(
        result.primary_mobile_number
      ),
      whatsapp_check2: result.whatsapp_number?.includes(
        result.alternate_number
      ),
      logoImg: retailerData?.Logo,
      fullName: userName,
      password: "",
    });
    console.log("logo", retailerData?.Logo);
    setLoading(false);
  };

  const showMessage = (type: "Success" | "Failure", messageString?: string) => {
    if (type === "Success") {
      message.success(messageString || "Saved successfully.!");
      setLoading(true);
    } else if (type === "Failure") {
      message.error(messageString || "Something went wrong.!");
    }
  };

  const updateUserName = async (userNameValue: any) => {
    if (userNameValue !== undefined && userNameValue !== null) {
      // @ts-ignore
      setSession(token, userId, userType, userNameValue, mobileNumber);
    }
  };

  const handlePersonalDetailsFormSubmit = async ({ ...values }) => {
    if (values.password !== "" && values.password !== undefined) {
      const result = await updatePassword(data.user_id, values.password).then(
        (result) => {
          if (result?.status) {
            showMessage(result.status, result.message);
          }
        }
      );
    }
    if (values.fullName !== "" && values.fullName !== undefined) {
      const result2 = await updateUser(
        data.user_id,
        values.fullName,
        token
      ).then((result) => {
        if (result?.status) {
          if (result.status === "Success" && result?.value !== undefined) {
            updateUserName(result?.value);
            setRetailerInfo({ ...retailerData });
          }
          showMessage(result.status, result.message);
        }
      });
    }
  };

  const handleEditBusinessDetailsFormSubmit = async ({ ...values }) => {
    if (values.id) {
      if (values.logo !== undefined && values.logo?.key === undefined) {
        const putUrl = await generatePutUrl(values.logo.name, values.logo.type);
        await uploadImage(putUrl, values.logo)
          .then((r) => console.log(r))
          .catch((e) => console.log(e));
      }
      let whatsappNumber = [];
      if (
        values.whatsapp_check1.target?.checked ||
        values.whatsapp_check1 === true
      ) {
        whatsappNumber.push(values.primary_mobile_number);
      }
      if (
        values.whatsapp_check2.target?.checked ||
        values.whatsapp_check2 === true
      ) {
        whatsappNumber.push(values.alternate_number);
      }
      let logoKey =
        values.logo !== undefined ? values.logo.name : data.logo_data.key;
      let businessDetails = {
        name: `'${values.name}'`,
        description: "''",
        alternate_number: `'${values.alternate_number}'`,
        store_location: `'${values.store_location}'`,
        authenticity_document_value: `'${values.authenticity_document_value}'`,
        authenticity_document_type: `'${values.authenticity_document_type}'`,
        logo_data:
          values.logo !== undefined
            ? `'{"key":"${values.logo.name}","contentType":"${values.logo.type}"}'`
            : `'{"key":"${data.logo_data.key}","contentType":"${data.logo_data.type}"}'`,
        whatsapp_number: `'{${whatsappNumber}}'`,
      };
      let address = {
        line1: `'${values.line1}'`,
        line2: "''",
        landmark: `'${values.landmark}'`,
        pincode: `'${values.pincode}'`,
        city: `'${values.city}'`,
        state: `'${values.state}'`,
      };
      const result = await updateAddresses(
        retailerData?.ID,
        "RetailStore",
        address
      );
      const result2 = await updateRetailStores(
        retailerData?.ID,
        businessDetails
      );
      if (result.id && result2.id) {
        setRetailerInfo({
          ...retailerData,
          Name: values.name,
          Logo: constants.s3Url + logoKey,
        });
        showMessage("Success");
      } else {
        showMessage("Failure");
      }
    }
  };

  return !loading ? (
    <EditBusinessProfileForm
      onSubmit={
        activeTab === "#personal-details"
          ? handlePersonalDetailsFormSubmit
          : handleEditBusinessDetailsFormSubmit
      }
      showPreview={true}
      defaultValues={data}
      onTabChange={setActiveTab}
    />
  ) : (
    <Loader />
  );
}

export default EditBusinessProfile;
