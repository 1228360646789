/* eslint-disable */
// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
export type Maybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  File: any;
  /** Represents untyped JSON */
  Hash: any;
  Upload: any;
};

export type Address = {
  __typename?: "Address";
  city: Scalars["String"];
  createdAt: Scalars["String"];
  id: Scalars["ID"];
  landmark?: Maybe<Scalars["String"]>;
  line1: Scalars["String"];
  line2?: Maybe<Scalars["String"]>;
  pincode: Scalars["String"];
  state: Scalars["String"];
  updatedAt: Scalars["String"];
};

export type AddressInput = {
  id?: Maybe<Scalars["ID"]>;
  line1: Scalars["String"];
  city: Scalars["String"];
  state: Scalars["String"];
  pincode: Scalars["String"];
};

export type AdminUser = {
  __typename?: "AdminUser";
  createdAt: Scalars["String"];
  email?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name: Scalars["String"];
  role: Scalars["String"];
  updatedAt: Scalars["String"];
};

export type ConfirmMobileNumberInput = {
  mobileNumber: Scalars["String"];
  token: Scalars["String"];
  code: Scalars["String"];
};

export type Count = {
  __typename?: "Count";
  count: Scalars["Int"];
};

export type CreateInvoiceMappingInput = {
  retailStoreId: Scalars["ID"];
  file: Scalars["Upload"];
  mappings?: Maybe<Scalars["Hash"]>;
  dimension?: Maybe<Scalars["Hash"]>;
};

export type CreateRetailStoreInput = {
  id?: Maybe<Scalars["ID"]>;
  name: Scalars["String"];
  primaryMobileNumber: Scalars["String"];
  contacts: Array<Scalars["String"]>;
  address: AddressInput;
  logo: Scalars["File"];
  authenticity_document_value: Scalars["String"];
  authenticity_document_type: Scalars["String"];
};

/** CreateSessionInput */
export type CreateSessionInput = {
  mobileNumber?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  isAdmin?: Maybe<Scalars["Boolean"]>;
};

export type CreateUserInput = {
  name: Scalars["String"];
  mobileNumber: Scalars["String"];
  token: Scalars["String"];
  code: Scalars["String"];
  isRetailer: Scalars["Boolean"];
  password: Scalars["String"];
};

export type Customer = {
  __typename?: "Customer";
  createdAt: Scalars["String"];
  id: Scalars["ID"];
  summary: OrderSummary;
  updatedAt: Scalars["String"];
  user: User;
};

export type CustomerStore = {
  __typename?: "CustomerStore";
  createdAt: Scalars["String"];
  id: Scalars["ID"];
  name: Scalars["String"];
  orderCount: Scalars["Int"];
  total: Scalars["Float"];
  updatedAt: Scalars["String"];
};

export type InvoiceMapping = {
  __typename?: "InvoiceMapping";
  createdAt: Scalars["String"];
  fileName: Scalars["String"];
  fileUrl: Scalars["String"];
  id: Scalars["ID"];
  isActive: Scalars["Boolean"];
  mappings?: Maybe<Scalars["Hash"]>;
  updatedAt: Scalars["String"];
};

export type Mutation = {
  __typename?: "Mutation";
  confirmMobileNumber: Response;
  createInvoiceMapping: Response;
  createRetailStore: RetailStore;
  createSession: Session;
  createUser: Session;
  destroySession: Response;
  requestMobileNumberConfirmation: Verification;
  resetPassword: Response;
  updateInvoiceMapping: Response;
  updateRetailStore: RetailStore;
  updateUser: User;
};

export type MutationConfirmMobileNumberArgs = {
  input: ConfirmMobileNumberInput;
};

export type MutationCreateInvoiceMappingArgs = {
  input: CreateInvoiceMappingInput;
};

export type MutationCreateRetailStoreArgs = {
  input: CreateRetailStoreInput;
};

export type MutationCreateSessionArgs = {
  input: CreateSessionInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationRequestMobileNumberConfirmationArgs = {
  input?: Maybe<RequestMobileNumberConfirmationInput>;
};

export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};

export type MutationUpdateInvoiceMappingArgs = {
  input: UpdateInvoiceMappingInput;
};

export type MutationUpdateRetailStoreArgs = {
  input: UpdateRetailStoreInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type Order = {
  __typename?: "Order";
  createdAt: Scalars["String"];
  invoice_date: Scalars["String"];
  invoice_number: Scalars["String"];
  discount: Scalars["Float"];
  gst: Scalars["Float"];
  round_off: Scalars["Float"];
  id: Scalars["ID"];
  orderItems: Array<OrderItem>;
  retailStore: RetailStore;
  status: Scalars["String"];
  subTotal: Scalars["Float"];
  total: Scalars["Float"];
  updatedAt: Scalars["String"];
  user: User;
};

export type OrderItem = {
  __typename?: "OrderItem";
  cgst: Scalars["Float"];
  createdAt: Scalars["String"];
  discount: Scalars["Float"];
  id: Scalars["ID"];
  product: Product;
  quantity: Scalars["Int"];
  bill_quantity: Scalars["Int"];
  sgst: Scalars["Float"];
  subTotal: Scalars["Float"];
  total: Scalars["Float"];
  updatedAt: Scalars["String"];
};

export type OrderSummary = {
  __typename?: "OrderSummary";
  count: Scalars["Int"];
  createdAt: Scalars["String"];
  id: Scalars["ID"];
  total: Scalars["Float"];
  updatedAt: Scalars["String"];
};

export type Product = {
  __typename?: "Product";
  createdAt: Scalars["String"];
  id: Scalars["ID"];
  name: Scalars["String"];
  price: Scalars["Float"];
  pack_price: Scalars["Float"];
  status: Scalars["String"];
  updatedAt: Scalars["String"];
};

export type Query = {
  __typename?: "Query";
  /** Get all retailer stores */
  allRetailStores?: Maybe<Array<RetailStore>>;
  /** Get current admin user */
  currentAdminUser?: Maybe<AdminUser>;
  /** Get current user */
  currentUser?: Maybe<User>;
  /** Get invoice mappings of a store */
  customerOrders?: Maybe<Array<Order>>;
  /** Get stores having orders for a customer */
  customerStores?: Maybe<Array<CustomerStore>>;
  /** Get invoice mappings of a store */
  invoiceMappings?: Maybe<Array<InvoiceMapping>>;
  /** Get order preview */
  orderPreview?: Maybe<Order>;
  /** Get retailer store */
  retailStore?: Maybe<RetailStore>;
  /** Retail store order count in a date range */
  retailStoreNewCustomers?: Maybe<Array<Customer>>;
  /** Get invoice mappings of a store */
  retailStoreOrder?: Maybe<Order>;
  /** Retail store order count in a date range */
  retailStoreOrderCount: Count;
  /** Get invoice mappings of a store */
  retailStoreOrders?: Maybe<Array<Order>>;
  /** Retail store order count in a date range */
  retailStoreRepeatedCustomers?: Maybe<Array<Customer>>;
  /** Get all retailer stores for a user */
  retailStores?: Maybe<Array<RetailStore>>;
};

export type QueryCustomerOrdersArgs = {
  retailStoreId: Scalars["ID"];
};

export type QueryInvoiceMappingsArgs = {
  retailStoreId: Scalars["ID"];
};

export type QueryOrderPreviewArgs = {
  friendlyId: Scalars["String"];
  token?: Maybe<Scalars["String"]>;
  code?: Maybe<Scalars["String"]>;
};

export type QueryRetailStoreArgs = {
  id: Scalars["ID"];
};

export type QueryRetailStoreNewCustomersArgs = {
  retailStoreId: Scalars["ID"];
  startDate: Scalars["Date"];
  endDate: Scalars["Date"];
};

export type QueryRetailStoreOrderArgs = {
  retailStoreId: Scalars["ID"];
  orderId: Scalars["ID"];
};

export type QueryRetailStoreOrderCountArgs = {
  retailStoreId: Scalars["ID"];
  startDate: Scalars["Date"];
  endDate: Scalars["Date"];
};

export type QueryRetailStoreOrdersArgs = {
  retailStoreId: Scalars["ID"];
};

export type QueryRetailStoreRepeatedCustomersArgs = {
  retailStoreId: Scalars["ID"];
  startDate: Scalars["Date"];
  endDate: Scalars["Date"];
};

export type RequestMobileNumberConfirmationInput = {
  mobileNumber: Scalars["String"];
  checkAvailability?: Maybe<Scalars["Boolean"]>;
  checkPresence?: Maybe<Scalars["Boolean"]>;
};

export type ResetPasswordInput = {
  token: Scalars["String"];
  userId: Scalars["String"];
  mobileNumber: Scalars["String"];
  password: Scalars["String"];
};

export type Response = {
  __typename?: "Response";
  success: Scalars["Boolean"];
};

export type RetailStore = {
  __typename?: "RetailStore";
  address?: Maybe<Address>;
  contacts?: Maybe<Array<Scalars["String"]>>;
  createdAt: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  authenticity_document_value: Scalars["String"];
  authenticity_document_type: Scalars["String"];
  id: Scalars["ID"];
  logo?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  primaryMobileNumber: Scalars["String"];
  updatedAt: Scalars["String"];
  user: User;
};

export type Session = {
  __typename?: "Session";
  createdAt: Scalars["String"];
  expiresAt: Scalars["String"];
  id: Scalars["ID"];
  ipAddress: Scalars["String"];
  location: Scalars["String"];
  token: Scalars["String"];
  updatedAt: Scalars["String"];
  userAgent: Scalars["String"];
};

/** UpdateInvoiceMappingInput */
export type UpdateInvoiceMappingInput = {
  id: Scalars["ID"];
  file?: Maybe<Scalars["File"]>;
  mappings?: Maybe<Scalars["Hash"]>;
  dimension?: Maybe<Scalars["Hash"]>;
};

export type UpdateRetailStoreInput = {
  id: Scalars["ID"];
  name: Scalars["String"];
  primaryMobileNumber: Scalars["String"];
  contacts: Array<Scalars["String"]>;
  address: AddressInput;
  logo: Scalars["File"];
  authenticity_document_value: Scalars["String"];
  authenticity_document_type: Scalars["String"];
};

export type UpdateUserInput = {
  name?: Maybe<Scalars["String"]>;
  password?: Maybe<Scalars["String"]>;
  confirmPassword?: Maybe<Scalars["String"]>;
};

export type User = {
  __typename?: "User";
  addresses?: Maybe<Array<Address>>;
  createdAt: Scalars["String"];
  email?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  isConfirmed: Scalars["Boolean"];
  isMobileNumberConfirmed: Scalars["Boolean"];
  mobileNumber: Scalars["String"];
  name: Scalars["String"];
  roles: Array<Scalars["String"]>;
  updatedAt: Scalars["String"];
};

export type Verification = {
  __typename?: "Verification";
  createdAt: Scalars["String"];
  id: Scalars["ID"];
  token: Scalars["String"];
  updatedAt: Scalars["String"];
};

export type AllRetailStoresQueryVariables = Exact<{ [key: string]: never }>;

export type AllRetailStoresQuery = { __typename?: "Query" } & {
  allRetailStores?: Maybe<
    Array<
      { __typename?: "RetailStore" } & Pick<
        RetailStore,
        "id" | "name" | "description"
      >
    >
  >;
};

export type ConfirmMobileNumberMutationVariables = Exact<{
  input: ConfirmMobileNumberInput;
}>;

export type ConfirmMobileNumberMutation = { __typename?: "Mutation" } & {
  confirmMobileNumber: { __typename?: "Response" } & Pick<Response, "success">;
};

export type CreateInvoiceMappingMutationVariables = Exact<{
  input: CreateInvoiceMappingInput;
}>;

export type CreateInvoiceMappingMutation = { __typename?: "Mutation" } & {
  createInvoiceMapping: { __typename?: "Response" } & Pick<Response, "success">;
};

export type CreateRetailStoreMutationVariables = Exact<{
  input: CreateRetailStoreInput;
}>;

export type CreateRetailStoreMutation = { __typename?: "Mutation" } & {
  createRetailStore: { __typename?: "RetailStore" } & Pick<
    RetailStore,
    "id" | "contacts" | "description" | "name"
  > & {
      address?: Maybe<
        { __typename?: "Address" } & Pick<
          Address,
          "line1" | "line2" | "landmark" | "city" | "state" | "pincode"
        >
      >;
    };
};

export type CreateSessionMutationVariables = Exact<{
  input: CreateSessionInput;
}>;

export type CreateSessionMutation = { __typename?: "Mutation" } & {
  createSession: { __typename?: "Session" } & Pick<Session, "token">;
};

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;

export type CreateUserMutation = { __typename?: "Mutation" } & {
  createUser: { __typename?: "Session" } & Pick<Session, "token">;
};

export type CustomerOrdersQueryVariables = Exact<{
  retailStoreId: Scalars["ID"];
}>;

export type CustomerOrdersQuery = { __typename?: "Query" } & {
  customerOrders?: Maybe<
    Array<
      { __typename?: "Order" } & Pick<
        Order,
        | "id"
        | "status"
        | "total"
        | "subTotal"
        | "discount"
        | "gst"
        | "createdAt"
      > & {
          orderItems: Array<
            { __typename?: "OrderItem" } & Pick<
              OrderItem,
              "cgst" | "discount" | "quantity" | "sgst" | "subTotal" | "total"
            > & {
                product: { __typename?: "Product" } & Pick<
                  Product,
                  "name" | "price"
                >;
              }
          >;
        }
    >
  >;
};

export type CustomerStoresQueryVariables = Exact<{ [key: string]: never }>;

export type CustomerStoresQuery = { __typename?: "Query" } & {
  customerStores?: Maybe<
    Array<
      { __typename?: "CustomerStore" } & Pick<
        CustomerStore,
        "id" | "name" | "orderCount" | "total"
      >
    >
  >;
};

export type DestroySessionMutationVariables = Exact<{ [key: string]: never }>;

export type DestroySessionMutation = { __typename?: "Mutation" } & {
  destroySession: { __typename?: "Response" } & Pick<Response, "success">;
};

export type InternalContextQueryVariables = Exact<{ [key: string]: never }>;

export type InternalContextQuery = { __typename?: "Query" } & {
  currentUser?: Maybe<
    { __typename?: "User" } & Pick<
      User,
      "id" | "email" | "isConfirmed" | "mobileNumber" | "name" | "roles"
    >
  >;
  currentAdminUser?: Maybe<
    { __typename?: "AdminUser" } & Pick<
      AdminUser,
      "id" | "email" | "name" | "role"
    >
  >;
  retailStores?: Maybe<
    Array<
      { __typename?: "RetailStore" } & Pick<
        RetailStore,
        | "id"
        | "contacts"
        | "primaryMobileNumber"
        | "description"
        | "name"
        | "logo"
        | "authenticity_document_type"
        | "authenticity_document_value"
      > & {
          address?: Maybe<
            { __typename?: "Address" } & Pick<
              Address,
              "line1" | "line2" | "landmark" | "city" | "state" | "pincode"
            >
          >;
        }
    >
  >;
};

export type InvoiceMappingsQueryVariables = Exact<{
  retailStoreId: Scalars["ID"];
}>;

export type InvoiceMappingsQuery = { __typename?: "Query" } & {
  invoiceMappings?: Maybe<
    Array<
      { __typename?: "InvoiceMapping" } & Pick<
        InvoiceMapping,
        | "id"
        | "fileUrl"
        | "fileName"
        | "isActive"
        | "mappings"
        | "createdAt"
        | "updatedAt"
      >
    >
  >;
};

export type OrderPreviewQueryVariables = Exact<{
  friendlyId: Scalars["String"];
  token?: Maybe<Scalars["String"]>;
  code?: Maybe<Scalars["String"]>;
}>;

export type OrderPreviewQuery = { __typename?: "Query" } & {
  orderPreview?: Maybe<
    { __typename?: "Order" } & Pick<
      Order,
      "id" | "status" | "total" | "subTotal" | "discount" | "gst" | "createdAt"
    > & {
        orderItems: Array<
          { __typename?: "OrderItem" } & Pick<
            OrderItem,
            "cgst" | "discount" | "quantity" | "sgst" | "subTotal" | "total"
          > & {
              product: { __typename?: "Product" } & Pick<
                Product,
                "name" | "price"
              >;
            }
        >;
        retailStore: { __typename?: "RetailStore" } & Pick<
          RetailStore,
          | "id"
          | "contacts"
          | "description"
          | "name"
          | "logo"
          | "primaryMobileNumber"
          | "authenticity_document_type"
          | "authenticity_document_value"
        > & {
            address?: Maybe<
              { __typename?: "Address" } & Pick<
                Address,
                "line1" | "line2" | "landmark" | "city" | "state" | "pincode"
              >
            >;
          };
      }
  >;
};

export type RequestMobileNumberConfirmationMutationVariables = Exact<{
  input: RequestMobileNumberConfirmationInput;
}>;

export type RequestMobileNumberConfirmationMutation = {
  __typename?: "Mutation";
} & {
  requestMobileNumberConfirmation: { __typename?: "Verification" } & Pick<
    Verification,
    "token"
  >;
};

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;

export type ResetPasswordMutation = { __typename?: "Mutation" } & {
  resetPassword: { __typename?: "Response" } & Pick<Response, "success">;
};

export type RetailStoreQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RetailStoreQuery = { __typename?: "Query" } & {
  retailStore?: Maybe<
    { __typename?: "RetailStore" } & Pick<
      RetailStore,
      | "id"
      | "contacts"
      | "primaryMobileNumber"
      | "description"
      | "name"
      | "logo"
      | "authenticity_document_type"
      | "authenticity_document_value"
    > & {
        address?: Maybe<
          { __typename?: "Address" } & Pick<
            Address,
            "line1" | "line2" | "landmark" | "city" | "state" | "pincode"
          >
        >;
      }
  >;
};

export type RetailStoreNewCustomersQueryVariables = Exact<{
  retailStoreId: Scalars["ID"];
  startDate: Scalars["Date"];
  endDate: Scalars["Date"];
}>;

export type RetailStoreNewCustomersQuery = { __typename?: "Query" } & {
  retailStoreNewCustomers?: Maybe<
    Array<
      { __typename?: "Customer" } & {
        user: { __typename?: "User" } & Pick<User, "name" | "mobileNumber">;
        summary: { __typename?: "OrderSummary" } & Pick<
          OrderSummary,
          "total" | "count"
        >;
      }
    >
  >;
};

export type RetailStoreOrderQueryVariables = Exact<{
  retailStoreId: Scalars["ID"];
  orderId: Scalars["ID"];
}>;

export type RetailStoreOrderQuery = { __typename?: "Query" } & {
  retailStoreOrder?: Maybe<
    { __typename?: "Order" } & Pick<
      Order,
      "id" | "status" | "total" | "subTotal" | "discount" | "gst" | "createdAt"
    > & {
        user: { __typename?: "User" } & Pick<
          User,
          "id" | "name" | "mobileNumber"
        > & {
            addresses?: Maybe<
              Array<
                { __typename?: "Address" } & Pick<
                  Address,
                  | "id"
                  | "line1"
                  | "line2"
                  | "landmark"
                  | "city"
                  | "state"
                  | "pincode"
                >
              >
            >;
          };
        orderItems: Array<
          { __typename?: "OrderItem" } & Pick<
            OrderItem,
            "id" | "quantity" | "total"
          > & {
              product: { __typename?: "Product" } & Pick<
                Product,
                "id" | "name" | "price"
              >;
            }
        >;
      }
  >;
};

export type RetailStoreOrderCountQueryVariables = Exact<{
  retailStoreId: Scalars["ID"];
  startDate: Scalars["Date"];
  endDate: Scalars["Date"];
}>;

export type RetailStoreOrderCountQuery = { __typename?: "Query" } & {
  retailStoreOrderCount: { __typename?: "Count" } & Pick<Count, "count">;
};

export type RetailStoreOrdersQueryVariables = Exact<{
  retailStoreId: Scalars["ID"];
}>;

export type RetailStoreOrdersQuery = { __typename?: "Query" } & {
  retailStoreOrders?: Maybe<
    Array<
      { __typename?: "Order" } & Pick<
        Order,
        | "id"
        | "status"
        | "total"
        | "subTotal"
        | "discount"
        | "gst"
        | "createdAt"
      > & {
          user: { __typename?: "User" } & Pick<
            User,
            "id" | "name" | "mobileNumber"
          >;
        }
    >
  >;
};

export type RetailStoreRepeatedCustomersQueryVariables = Exact<{
  retailStoreId: Scalars["ID"];
  startDate: Scalars["Date"];
  endDate: Scalars["Date"];
}>;

export type RetailStoreRepeatedCustomersQuery = { __typename?: "Query" } & {
  retailStoreRepeatedCustomers?: Maybe<
    Array<
      { __typename?: "Customer" } & {
        user: { __typename?: "User" } & Pick<User, "name" | "mobileNumber">;
        summary: { __typename?: "OrderSummary" } & Pick<
          OrderSummary,
          "total" | "count"
        >;
      }
    >
  >;
};

export type RetailStoresQueryVariables = Exact<{ [key: string]: never }>;

export type RetailStoresQuery = { __typename?: "Query" } & {
  retailStores?: Maybe<
    Array<
      { __typename?: "RetailStore" } & Pick<
        RetailStore,
        | "id"
        | "contacts"
        | "primaryMobileNumber"
        | "description"
        | "name"
        | "logo"
        | "authenticity_document_type"
        | "authenticity_document_value"
      > & {
          address?: Maybe<
            { __typename?: "Address" } & Pick<
              Address,
              "line1" | "line2" | "landmark" | "city" | "state" | "pincode"
            >
          >;
        }
    >
  >;
};

export type UpdateInvoiceMappingMutationVariables = Exact<{
  input: UpdateInvoiceMappingInput;
}>;

export type UpdateInvoiceMappingMutation = { __typename?: "Mutation" } & {
  updateInvoiceMapping: { __typename?: "Response" } & Pick<Response, "success">;
};

export type UpdateRetailStoreMutationVariables = Exact<{
  input: UpdateRetailStoreInput;
}>;

export type UpdateRetailStoreMutation = { __typename?: "Mutation" } & {
  updateRetailStore: { __typename?: "RetailStore" } & Pick<
    RetailStore,
    | "id"
    | "contacts"
    | "primaryMobileNumber"
    | "description"
    | "name"
    | "logo"
    | "authenticity_document_type"
    | "authenticity_document_value"
  > & {
      address?: Maybe<
        { __typename?: "Address" } & Pick<
          Address,
          "line1" | "line2" | "landmark" | "city" | "state" | "pincode"
        >
      >;
    };
};

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type UpdateUserMutation = { __typename?: "Mutation" } & {
  updateUser: { __typename?: "User" } & Pick<User, "id" | "name">;
};

export const AllRetailStoresDocument = gql`
  query AllRetailStores {
    allRetailStores {
      id
      name
      description
    }
  }
`;

/**
 * __useAllRetailStoresQuery__
 *
 * To run a query within a React component, call `useAllRetailStoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllRetailStoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllRetailStoresQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllRetailStoresQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AllRetailStoresQuery,
    AllRetailStoresQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    AllRetailStoresQuery,
    AllRetailStoresQueryVariables
  >(AllRetailStoresDocument, baseOptions);
}
export function useAllRetailStoresLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllRetailStoresQuery,
    AllRetailStoresQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    AllRetailStoresQuery,
    AllRetailStoresQueryVariables
  >(AllRetailStoresDocument, baseOptions);
}
export type AllRetailStoresQueryHookResult = ReturnType<
  typeof useAllRetailStoresQuery
>;
export type AllRetailStoresLazyQueryHookResult = ReturnType<
  typeof useAllRetailStoresLazyQuery
>;
export const ConfirmMobileNumberDocument = gql`
  mutation ConfirmMobileNumber($input: ConfirmMobileNumberInput!) {
    confirmMobileNumber(input: $input) {
      success
    }
  }
`;

/**
 * __useConfirmMobileNumberMutation__
 *
 * To run a mutation, you first call `useConfirmMobileNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmMobileNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmMobileNumberMutation, { data, loading, error }] = useConfirmMobileNumberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmMobileNumberMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ConfirmMobileNumberMutation,
    ConfirmMobileNumberMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ConfirmMobileNumberMutation,
    ConfirmMobileNumberMutationVariables
  >(ConfirmMobileNumberDocument, baseOptions);
}
export type ConfirmMobileNumberMutationHookResult = ReturnType<
  typeof useConfirmMobileNumberMutation
>;
export const CreateInvoiceMappingDocument = gql`
  mutation CreateInvoiceMapping($input: CreateInvoiceMappingInput!) {
    createInvoiceMapping(input: $input) {
      success
    }
  }
`;

/**
 * __useCreateInvoiceMappingMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceMappingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceMappingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoiceMappingMutation, { data, loading, error }] = useCreateInvoiceMappingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInvoiceMappingMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateInvoiceMappingMutation,
    CreateInvoiceMappingMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateInvoiceMappingMutation,
    CreateInvoiceMappingMutationVariables
  >(CreateInvoiceMappingDocument, baseOptions);
}
export type CreateInvoiceMappingMutationHookResult = ReturnType<
  typeof useCreateInvoiceMappingMutation
>;
export const CreateRetailStoreDocument = gql`
  mutation CreateRetailStore($input: CreateRetailStoreInput!) {
    createRetailStore(input: $input) {
      id
      contacts
      description
      name
      address {
        line1
        line2
        landmark
        city
        state
        pincode
      }
    }
  }
`;

/**
 * __useCreateRetailStoreMutation__
 *
 * To run a mutation, you first call `useCreateRetailStoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRetailStoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRetailStoreMutation, { data, loading, error }] = useCreateRetailStoreMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRetailStoreMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateRetailStoreMutation,
    CreateRetailStoreMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateRetailStoreMutation,
    CreateRetailStoreMutationVariables
  >(CreateRetailStoreDocument, baseOptions);
}
export type CreateRetailStoreMutationHookResult = ReturnType<
  typeof useCreateRetailStoreMutation
>;
export const CreateSessionDocument = gql`
  mutation CreateSession($input: CreateSessionInput!) {
    createSession(input: $input) {
      token
    }
  }
`;

/**
 * __useCreateSessionMutation__
 *
 * To run a mutation, you first call `useCreateSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSessionMutation, { data, loading, error }] = useCreateSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSessionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateSessionMutation,
    CreateSessionMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateSessionMutation,
    CreateSessionMutationVariables
  >(CreateSessionDocument, baseOptions);
}
export type CreateSessionMutationHookResult = ReturnType<
  typeof useCreateSessionMutation
>;
export const CreateUserDocument = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      token
    }
  }
`;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateUserMutation,
    CreateUserMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateUserMutation,
    CreateUserMutationVariables
  >(CreateUserDocument, baseOptions);
}
export type CreateUserMutationHookResult = ReturnType<
  typeof useCreateUserMutation
>;
export const CustomerOrdersDocument = gql`
  query CustomerOrders($retailStoreId: ID!) {
    customerOrders(retailStoreId: $retailStoreId) {
      id
      status
      total
      subTotal
      discount
      gst
      createdAt
      orderItems {
        cgst
        discount
        quantity
        sgst
        subTotal
        total
        product {
          name
          price
        }
      }
    }
  }
`;

/**
 * __useCustomerOrdersQuery__
 *
 * To run a query within a React component, call `useCustomerOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerOrdersQuery({
 *   variables: {
 *      retailStoreId: // value for 'retailStoreId'
 *   },
 * });
 */
export function useCustomerOrdersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CustomerOrdersQuery,
    CustomerOrdersQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    CustomerOrdersQuery,
    CustomerOrdersQueryVariables
  >(CustomerOrdersDocument, baseOptions);
}
export function useCustomerOrdersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CustomerOrdersQuery,
    CustomerOrdersQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    CustomerOrdersQuery,
    CustomerOrdersQueryVariables
  >(CustomerOrdersDocument, baseOptions);
}
export type CustomerOrdersQueryHookResult = ReturnType<
  typeof useCustomerOrdersQuery
>;
export type CustomerOrdersLazyQueryHookResult = ReturnType<
  typeof useCustomerOrdersLazyQuery
>;
export const CustomerStoresDocument = gql`
  query CustomerStores {
    customerStores {
      id
      name
      orderCount
      total
    }
  }
`;

/**
 * __useCustomerStoresQuery__
 *
 * To run a query within a React component, call `useCustomerStoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerStoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerStoresQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomerStoresQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CustomerStoresQuery,
    CustomerStoresQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    CustomerStoresQuery,
    CustomerStoresQueryVariables
  >(CustomerStoresDocument, baseOptions);
}
export function useCustomerStoresLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CustomerStoresQuery,
    CustomerStoresQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    CustomerStoresQuery,
    CustomerStoresQueryVariables
  >(CustomerStoresDocument, baseOptions);
}
export type CustomerStoresQueryHookResult = ReturnType<
  typeof useCustomerStoresQuery
>;
export type CustomerStoresLazyQueryHookResult = ReturnType<
  typeof useCustomerStoresLazyQuery
>;
export const DestroySessionDocument = gql`
  mutation DestroySession {
    destroySession {
      success
    }
  }
`;

/**
 * __useDestroySessionMutation__
 *
 * To run a mutation, you first call `useDestroySessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroySessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroySessionMutation, { data, loading, error }] = useDestroySessionMutation({
 *   variables: {
 *   },
 * });
 */
export function useDestroySessionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DestroySessionMutation,
    DestroySessionMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DestroySessionMutation,
    DestroySessionMutationVariables
  >(DestroySessionDocument, baseOptions);
}
export type DestroySessionMutationHookResult = ReturnType<
  typeof useDestroySessionMutation
>;
export const InternalContextDocument = gql`
  query InternalContext {
    currentUser {
      id
      email
      isConfirmed
      mobileNumber
      name
      roles
    }
    currentAdminUser {
      id
      email
      name
      role
    }
    retailStores {
      id
      contacts
      primaryMobileNumber
      description
      name
      logo
      authenticity_document_type
      authenticity_document_value
      address {
        line1
        line2
        landmark
        city
        state
        pincode
      }
    }
  }
`;

/**
 * __useInternalContextQuery__
 *
 * To run a query within a React component, call `useInternalContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useInternalContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInternalContextQuery({
 *   variables: {
 *   },
 * });
 */
export function useInternalContextQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    InternalContextQuery,
    InternalContextQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    InternalContextQuery,
    InternalContextQueryVariables
  >(InternalContextDocument, baseOptions);
}
export function useInternalContextLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    InternalContextQuery,
    InternalContextQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    InternalContextQuery,
    InternalContextQueryVariables
  >(InternalContextDocument, baseOptions);
}
export type InternalContextQueryHookResult = ReturnType<
  typeof useInternalContextQuery
>;
export type InternalContextLazyQueryHookResult = ReturnType<
  typeof useInternalContextLazyQuery
>;
export const InvoiceMappingsDocument = gql`
  query InvoiceMappings($retailStoreId: ID!) {
    invoiceMappings(retailStoreId: $retailStoreId) {
      id
      fileUrl
      fileName
      isActive
      mappings
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useInvoiceMappingsQuery__
 *
 * To run a query within a React component, call `useInvoiceMappingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceMappingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceMappingsQuery({
 *   variables: {
 *      retailStoreId: // value for 'retailStoreId'
 *   },
 * });
 */
export function useInvoiceMappingsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    InvoiceMappingsQuery,
    InvoiceMappingsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    InvoiceMappingsQuery,
    InvoiceMappingsQueryVariables
  >(InvoiceMappingsDocument, baseOptions);
}
export function useInvoiceMappingsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    InvoiceMappingsQuery,
    InvoiceMappingsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    InvoiceMappingsQuery,
    InvoiceMappingsQueryVariables
  >(InvoiceMappingsDocument, baseOptions);
}
export type InvoiceMappingsQueryHookResult = ReturnType<
  typeof useInvoiceMappingsQuery
>;
export type InvoiceMappingsLazyQueryHookResult = ReturnType<
  typeof useInvoiceMappingsLazyQuery
>;
export const OrderPreviewDocument = gql`
  query OrderPreview($friendlyId: String!, $token: String, $code: String) {
    orderPreview(friendlyId: $friendlyId, token: $token, code: $code) {
      id
      status
      total
      subTotal
      discount
      gst
      createdAt
      orderItems {
        cgst
        discount
        quantity
        sgst
        subTotal
        total
        product {
          name
          price
        }
      }
      retailStore {
        id
        contacts
        description
        name
        logo
        primaryMobileNumber
        authenticity_document_type
        authenticity_document_value
        address {
          line1
          line2
          landmark
          city
          state
          pincode
        }
      }
    }
  }
`;

/**
 * __useOrderPreviewQuery__
 *
 * To run a query within a React component, call `useOrderPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderPreviewQuery({
 *   variables: {
 *      friendlyId: // value for 'friendlyId'
 *      token: // value for 'token'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useOrderPreviewQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OrderPreviewQuery,
    OrderPreviewQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    OrderPreviewQuery,
    OrderPreviewQueryVariables
  >(OrderPreviewDocument, baseOptions);
}
export function useOrderPreviewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrderPreviewQuery,
    OrderPreviewQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    OrderPreviewQuery,
    OrderPreviewQueryVariables
  >(OrderPreviewDocument, baseOptions);
}
export type OrderPreviewQueryHookResult = ReturnType<
  typeof useOrderPreviewQuery
>;
export type OrderPreviewLazyQueryHookResult = ReturnType<
  typeof useOrderPreviewLazyQuery
>;
export const RequestMobileNumberConfirmationDocument = gql`
  mutation RequestMobileNumberConfirmation(
    $input: RequestMobileNumberConfirmationInput!
  ) {
    requestMobileNumberConfirmation(input: $input) {
      token
    }
  }
`;

/**
 * __useRequestMobileNumberConfirmationMutation__
 *
 * To run a mutation, you first call `useRequestMobileNumberConfirmationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestMobileNumberConfirmationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestMobileNumberConfirmationMutation, { data, loading, error }] = useRequestMobileNumberConfirmationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestMobileNumberConfirmationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RequestMobileNumberConfirmationMutation,
    RequestMobileNumberConfirmationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RequestMobileNumberConfirmationMutation,
    RequestMobileNumberConfirmationMutationVariables
  >(RequestMobileNumberConfirmationDocument, baseOptions);
}
export type RequestMobileNumberConfirmationMutationHookResult = ReturnType<
  typeof useRequestMobileNumberConfirmationMutation
>;
export const ResetPasswordDocument = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      success
    }
  }
`;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(ResetPasswordDocument, baseOptions);
}
export type ResetPasswordMutationHookResult = ReturnType<
  typeof useResetPasswordMutation
>;
export const RetailStoreDocument = gql`
  query RetailStore($id: ID!) {
    retailStore(id: $id) {
      id
      contacts
      primaryMobileNumber
      description
      name
      logo
      authenticity_document_type
      authenticity_document_value
      address {
        line1
        line2
        landmark
        city
        state
        pincode
      }
    }
  }
`;

/**
 * __useRetailStoreQuery__
 *
 * To run a query within a React component, call `useRetailStoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetailStoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetailStoreQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRetailStoreQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    RetailStoreQuery,
    RetailStoreQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<RetailStoreQuery, RetailStoreQueryVariables>(
    RetailStoreDocument,
    baseOptions
  );
}
export function useRetailStoreLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    RetailStoreQuery,
    RetailStoreQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    RetailStoreQuery,
    RetailStoreQueryVariables
  >(RetailStoreDocument, baseOptions);
}
export type RetailStoreQueryHookResult = ReturnType<typeof useRetailStoreQuery>;
export type RetailStoreLazyQueryHookResult = ReturnType<
  typeof useRetailStoreLazyQuery
>;
export const RetailStoreNewCustomersDocument = gql`
  query RetailStoreNewCustomers(
    $retailStoreId: ID!
    $startDate: Date!
    $endDate: Date!
  ) {
    retailStoreNewCustomers(
      retailStoreId: $retailStoreId
      startDate: $startDate
      endDate: $endDate
    ) {
      user {
        name
        mobileNumber
      }
      summary {
        total
        count
      }
    }
  }
`;

/**
 * __useRetailStoreNewCustomersQuery__
 *
 * To run a query within a React component, call `useRetailStoreNewCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetailStoreNewCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetailStoreNewCustomersQuery({
 *   variables: {
 *      retailStoreId: // value for 'retailStoreId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useRetailStoreNewCustomersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    RetailStoreNewCustomersQuery,
    RetailStoreNewCustomersQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    RetailStoreNewCustomersQuery,
    RetailStoreNewCustomersQueryVariables
  >(RetailStoreNewCustomersDocument, baseOptions);
}
export function useRetailStoreNewCustomersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    RetailStoreNewCustomersQuery,
    RetailStoreNewCustomersQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    RetailStoreNewCustomersQuery,
    RetailStoreNewCustomersQueryVariables
  >(RetailStoreNewCustomersDocument, baseOptions);
}
export type RetailStoreNewCustomersQueryHookResult = ReturnType<
  typeof useRetailStoreNewCustomersQuery
>;
export type RetailStoreNewCustomersLazyQueryHookResult = ReturnType<
  typeof useRetailStoreNewCustomersLazyQuery
>;
export const RetailStoreOrderDocument = gql`
  query RetailStoreOrder($retailStoreId: ID!, $orderId: ID!) {
    retailStoreOrder(retailStoreId: $retailStoreId, orderId: $orderId) {
      id
      status
      total
      subTotal
      discount
      gst
      createdAt
      user {
        id
        name
        mobileNumber
        addresses {
          id
          line1
          line2
          landmark
          city
          state
          pincode
        }
      }
      orderItems {
        id
        quantity
        total
        product {
          id
          name
          price
        }
      }
    }
  }
`;

/**
 * __useRetailStoreOrderQuery__
 *
 * To run a query within a React component, call `useRetailStoreOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetailStoreOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetailStoreOrderQuery({
 *   variables: {
 *      retailStoreId: // value for 'retailStoreId'
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useRetailStoreOrderQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    RetailStoreOrderQuery,
    RetailStoreOrderQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    RetailStoreOrderQuery,
    RetailStoreOrderQueryVariables
  >(RetailStoreOrderDocument, baseOptions);
}
export function useRetailStoreOrderLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    RetailStoreOrderQuery,
    RetailStoreOrderQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    RetailStoreOrderQuery,
    RetailStoreOrderQueryVariables
  >(RetailStoreOrderDocument, baseOptions);
}
export type RetailStoreOrderQueryHookResult = ReturnType<
  typeof useRetailStoreOrderQuery
>;
export type RetailStoreOrderLazyQueryHookResult = ReturnType<
  typeof useRetailStoreOrderLazyQuery
>;
export const RetailStoreOrderCountDocument = gql`
  query RetailStoreOrderCount(
    $retailStoreId: ID!
    $startDate: Date!
    $endDate: Date!
  ) {
    retailStoreOrderCount(
      retailStoreId: $retailStoreId
      startDate: $startDate
      endDate: $endDate
    ) {
      count
    }
  }
`;

/**
 * __useRetailStoreOrderCountQuery__
 *
 * To run a query within a React component, call `useRetailStoreOrderCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetailStoreOrderCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetailStoreOrderCountQuery({
 *   variables: {
 *      retailStoreId: // value for 'retailStoreId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useRetailStoreOrderCountQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    RetailStoreOrderCountQuery,
    RetailStoreOrderCountQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    RetailStoreOrderCountQuery,
    RetailStoreOrderCountQueryVariables
  >(RetailStoreOrderCountDocument, baseOptions);
}
export function useRetailStoreOrderCountLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    RetailStoreOrderCountQuery,
    RetailStoreOrderCountQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    RetailStoreOrderCountQuery,
    RetailStoreOrderCountQueryVariables
  >(RetailStoreOrderCountDocument, baseOptions);
}
export type RetailStoreOrderCountQueryHookResult = ReturnType<
  typeof useRetailStoreOrderCountQuery
>;
export type RetailStoreOrderCountLazyQueryHookResult = ReturnType<
  typeof useRetailStoreOrderCountLazyQuery
>;
export const RetailStoreOrdersDocument = gql`
  query RetailStoreOrders($retailStoreId: ID!) {
    retailStoreOrders(retailStoreId: $retailStoreId) {
      id
      status
      total
      subTotal
      discount
      gst
      createdAt
      user {
        id
        name
        mobileNumber
      }
    }
  }
`;

/**
 * __useRetailStoreOrdersQuery__
 *
 * To run a query within a React component, call `useRetailStoreOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetailStoreOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetailStoreOrdersQuery({
 *   variables: {
 *      retailStoreId: // value for 'retailStoreId'
 *   },
 * });
 */
export function useRetailStoreOrdersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    RetailStoreOrdersQuery,
    RetailStoreOrdersQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    RetailStoreOrdersQuery,
    RetailStoreOrdersQueryVariables
  >(RetailStoreOrdersDocument, baseOptions);
}
export function useRetailStoreOrdersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    RetailStoreOrdersQuery,
    RetailStoreOrdersQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    RetailStoreOrdersQuery,
    RetailStoreOrdersQueryVariables
  >(RetailStoreOrdersDocument, baseOptions);
}
export type RetailStoreOrdersQueryHookResult = ReturnType<
  typeof useRetailStoreOrdersQuery
>;
export type RetailStoreOrdersLazyQueryHookResult = ReturnType<
  typeof useRetailStoreOrdersLazyQuery
>;
export const RetailStoreRepeatedCustomersDocument = gql`
  query RetailStoreRepeatedCustomers(
    $retailStoreId: ID!
    $startDate: Date!
    $endDate: Date!
  ) {
    retailStoreRepeatedCustomers(
      retailStoreId: $retailStoreId
      startDate: $startDate
      endDate: $endDate
    ) {
      user {
        name
        mobileNumber
      }
      summary {
        total
        count
      }
    }
  }
`;

/**
 * __useRetailStoreRepeatedCustomersQuery__
 *
 * To run a query within a React component, call `useRetailStoreRepeatedCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetailStoreRepeatedCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetailStoreRepeatedCustomersQuery({
 *   variables: {
 *      retailStoreId: // value for 'retailStoreId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useRetailStoreRepeatedCustomersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    RetailStoreRepeatedCustomersQuery,
    RetailStoreRepeatedCustomersQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    RetailStoreRepeatedCustomersQuery,
    RetailStoreRepeatedCustomersQueryVariables
  >(RetailStoreRepeatedCustomersDocument, baseOptions);
}
export function useRetailStoreRepeatedCustomersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    RetailStoreRepeatedCustomersQuery,
    RetailStoreRepeatedCustomersQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    RetailStoreRepeatedCustomersQuery,
    RetailStoreRepeatedCustomersQueryVariables
  >(RetailStoreRepeatedCustomersDocument, baseOptions);
}
export type RetailStoreRepeatedCustomersQueryHookResult = ReturnType<
  typeof useRetailStoreRepeatedCustomersQuery
>;
export type RetailStoreRepeatedCustomersLazyQueryHookResult = ReturnType<
  typeof useRetailStoreRepeatedCustomersLazyQuery
>;
export const RetailStoresDocument = gql`
  query RetailStores {
    retailStores {
      id
      contacts
      primaryMobileNumber
      description
      name
      logo
      authenticity_document_type
      authenticity_document_value
      address {
        line1
        line2
        landmark
        city
        state
        pincode
      }
    }
  }
`;

/**
 * __useRetailStoresQuery__
 *
 * To run a query within a React component, call `useRetailStoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetailStoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetailStoresQuery({
 *   variables: {
 *   },
 * });
 */
export function useRetailStoresQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    RetailStoresQuery,
    RetailStoresQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    RetailStoresQuery,
    RetailStoresQueryVariables
  >(RetailStoresDocument, baseOptions);
}
export function useRetailStoresLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    RetailStoresQuery,
    RetailStoresQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    RetailStoresQuery,
    RetailStoresQueryVariables
  >(RetailStoresDocument, baseOptions);
}
export type RetailStoresQueryHookResult = ReturnType<
  typeof useRetailStoresQuery
>;
export type RetailStoresLazyQueryHookResult = ReturnType<
  typeof useRetailStoresLazyQuery
>;
export const UpdateInvoiceMappingDocument = gql`
  mutation UpdateInvoiceMapping($input: UpdateInvoiceMappingInput!) {
    updateInvoiceMapping(input: $input) {
      success
    }
  }
`;

/**
 * __useUpdateInvoiceMappingMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceMappingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceMappingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceMappingMutation, { data, loading, error }] = useUpdateInvoiceMappingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInvoiceMappingMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateInvoiceMappingMutation,
    UpdateInvoiceMappingMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateInvoiceMappingMutation,
    UpdateInvoiceMappingMutationVariables
  >(UpdateInvoiceMappingDocument, baseOptions);
}
export type UpdateInvoiceMappingMutationHookResult = ReturnType<
  typeof useUpdateInvoiceMappingMutation
>;
export const UpdateRetailStoreDocument = gql`
  mutation UpdateRetailStore($input: UpdateRetailStoreInput!) {
    updateRetailStore(input: $input) {
      id
      contacts
      primaryMobileNumber
      description
      name
      logo
      authenticity_document_type
      authenticity_document_value
      address {
        line1
        line2
        landmark
        city
        state
        pincode
      }
    }
  }
`;

/**
 * __useUpdateRetailStoreMutation__
 *
 * To run a mutation, you first call `useUpdateRetailStoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRetailStoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRetailStoreMutation, { data, loading, error }] = useUpdateRetailStoreMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRetailStoreMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateRetailStoreMutation,
    UpdateRetailStoreMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateRetailStoreMutation,
    UpdateRetailStoreMutationVariables
  >(UpdateRetailStoreDocument, baseOptions);
}
export type UpdateRetailStoreMutationHookResult = ReturnType<
  typeof useUpdateRetailStoreMutation
>;
export const UpdateUserDocument = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      name
    }
  }
`;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >(UpdateUserDocument, baseOptions);
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
