import { getSession, Session } from "client/reactives/session";
const url = process.env.REACT_APP_API_NODE_BASE_URL;

export async function fetchRetailerOrders(id: number, priority: boolean) {
  const { token } = (await getSession()) as Session;
  var endpoint = `/getRetailerOrders?retail_store_id=${id}`;
  if (priority) {
    endpoint += `&priority=${priority}`;
  }
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
}
