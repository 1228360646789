import Modal from "antd/es/modal/Modal";
import React from "react";
import { createUseStyles } from "react-jss";

type FullScreenModalProps = {
  modalOpen: boolean;
  children: React.ReactNode;
  disabledFullScreen?: boolean;
  onClose?: () => void;
};

function FullScreenModal({
  modalOpen,
  children,
  onClose,
  disabledFullScreen = false,
}: FullScreenModalProps) {
  const classes = useStyles({ disabledFullScreen });

  let props: any = {
    title: false,
    closable: false,
    width: "100%",
    centered: true,
    mask: false,
    className: classes.modal,
    visible: modalOpen,
    footer: false,
  };

  if (disabledFullScreen) {
    props = {
      ...props,
      title: "Your Raseet will look like this.",
      closable: true,
      width: "fit-content",
      onCancel: onClose,
      className: classes.modalWithoutFullScreen,
    };
  }

  return <Modal {...props}>{children}</Modal>;
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  "@global": {
    "& .ant-modal-centered": {
      textAlign: ({ disabledFullScreen }) =>
        disabledFullScreen ? "center !important" : "initial !important",
    },
  },
  modal: {
    height: "100%",
    width: "100%",
    margin: "0",
    padding: 0,
    maxWidth: "unset",

    "& .ant-modal-centered::before": {
      content: "unset",
    },

    "& .ant-modal-content": {
      width: "100vw",
    },

    "& .ant-modal-body": { width: "100%", height: "100vh", padding: 0 },

    "& .ant-modal-title": {
      display: "none",
    },
  },

  modalMask: {
    display: "none",

    "& > div": {
      textAlign: "initial",
    },
  },
  modalWithoutFullScreen: {
    "& .ant-layout-footer": {
      position: "static !important",
      boxShadow: "inherit !important",
    },
    "& .ant-modal-body": {
      paddingBottom: "6rem",
    },
  },
}));

export default FullScreenModal;
