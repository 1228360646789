import {
  getRatings,
  postRatings,
  getOrderItems,
  deleteCartItem,
  insertCartItems,
  getOrderLoyaltyInfo,
  updateCartItemsByProductId,
  getShopBanner,
} from "api/api";
import dayjs from "dayjs";
import {
  ClockCircleOutlined,
  DownloadOutlined,
  EditOutlined,
  LikeOutlined,
  StarOutlined,
  ThunderboltFilled,
  TrophyOutlined,
} from "@ant-design/icons";
import {
  DownloadBillIconLight,
  DownloadPrescriptionIconLight,
  ReorderIcon,
} from "components/customIcons";
import { Carousel, Typography } from "antd";
import Table from "antd/es/table";
import Skeleton from "antd/es/skeleton";
import { Divider, message } from "antd";
import { Link } from "react-router-dom";
import Text from "antd/es/typography/Text";
import { FormInstance } from "antd/lib/form";
import { Button, Col, Row, Form } from "antd";
import DatePicker from "components/DatePicker";
import TextArea from "antd/lib/input/TextArea";
import AdBanner from "assets/svgs/ad-banner.svg";
import OffersDrawer from "components/OffersDrawer";
import NumberSelect from "components/dataEntry/NumberSelect";
import { getSession, Session } from "client/reactives/session";
import React, { useEffect, useState, useContext } from "react";
import WrapperHeightProvider from "components/WrapperHeightProvider";
import { useStyles } from "components/views/Styles/BillDetailsView.style";
import gtag from "components/Tracking/gtag";
import BannerCard from "components/cards/BannerCard";

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
  key: string;
  item: string;
  quantity: number;
  price: number;
  total: number;
}

interface EditableRowProps {
  index: number;
}

const getPriceWithoutGst = (gstPercentage: number, priceWithGst: number) => {
  let gstAmount = priceWithGst - priceWithGst * (100 / (100 + gstPercentage));
  return (priceWithGst - gstAmount).toFixed(2);
};

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
  handleQtyUpdate: (record: any) => void;
  onReorderChangeFooter?: any;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  handleQtyUpdate,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const form = useContext(EditableContext)!;

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    var qty = handleQtyUpdate(record);

    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    childNode = (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <NumberSelect till={99} qty={qty} onChange={save} />
      </Form.Item>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type BillDetailsViewProps = {
  billData: any;
  order: any;
  switchReorder: any;
  retailStore: any;
  setCheckBoxCheck: any;
  bannerLength: number;
};

interface DataType {
  key: React.Key;
  item: string;
  quantity: number;
  price: number;
  total: number;
}

const columns = [
  {
    key: "1",
    title: "#",
    dataIndex: "key",
    width: "46.5px",
    align: "center",
  },
  {
    key: "2",
    title: "Item",
    dataIndex: "name",
    align: "center",
    width: "100px",
    render: (itemName: string, row: any) => (
      <Link
        to={`/dashboard/stores/${row?.retail_store_id}/product/${row?.product_sku}`}
      >
        <div style={{ textAlign: "left", color: "#505050" }}>{itemName}</div>
      </Link>
    ),
  },
  {
    key: "3",
    title: "Qty",
    dataIndex: "quantity",
    align: "center",
    width: "50px",
  },
  {
    key: "4",
    dataIndex: "pack_price_at_time_of_order",
    title: "Unit Price",
    width: "65px",
    align: "center",
    render: (pack_price_at_time_of_order: string) => `₹ ${(+pack_price_at_time_of_order).toFixed(2)}`,
  },
  {
    key: "5",
    dataIndex: "total",
    title: "Amount",
    width: "70px",
    align: "right",
    render: (total: string) => `₹ ${(+total).toFixed(2)}`,
  },
];

const reorderColumns = [
  {
    key: "2",
    title: "Item",
    dataIndex: "name",
    align: "center",
    width: "100px",
    render: (itemName: string) => (
      <div style={{ textAlign: "left" }}>{itemName}</div>
    ),
  },
  {
    key: "3",
    title: "Qty",
    dataIndex: "quantity",
    align: "center",
    width: "50px",
    editable: true,
  },
  {
    key: "4",
    dataIndex: "pack_price",
    title: "Unit Price",
    width: "65px",
    align: "center",
    render: (price: string) => `₹ ${(+price).toFixed(2)}`,
  },
  {
    key: "5",
    dataIndex: "total",
    title: "Amount",
    width: "70px",
    align: "right",
    render: (total: string, record: any) =>
      `₹ ${(
        (+record.reorder_qty || +record.quantity) * +record.price
      ).toFixed(2)}`,
  },
];

function BillDetailsView({
  billData,
  order,
  bannerLength,
  switchReorder,
  retailStore,
  setCheckBoxCheck,
}: BillDetailsViewProps) {
  // -------------NEW STATES IN USE-----------------------------
  const classes = useStyles({ isReorder: billData.reorder });
  const { userId } = getSession() as Session;
  const [offersDrawerVisibility, setOffersDrawerVisibility] = useState(false);
  var loyaltyInfoProp: any;
  const [shopBanners, setShopBanners] = useState([]);
  const [billItems, setBillItems] = useState({
    items: [{}],
    selectedItemRows: [""],
    rowSelection: {},
    orderId: order.id,
    feedbackText: "",
    feedbackGiven: false,
    ratingsLevel: 0,
    ratingsGiven: false,
    loading: true,
    orderLoyaltyInfo: loyaltyInfoProp,
  });
  // -----------------------------------------------------------
  async function postRatingsAndFeedback(rating: any, feedback: string) {
    const { token, userId } = (await getSession()) as Session;
    var ratingsData = {
      rating: rating,
      feedback: feedback,
      feedback_type: "text",
      order_id: order.id,
      customer_id: userId,
      bill_type: "order",
    };
    var updateAPI = false;
    if (billItems.ratingsGiven || billItems.feedbackGiven) {
      updateAPI = true;
    }

    var data = await postRatings(ratingsData, updateAPI);
    var feedbackText = data.feedback;
    var feedbackGiven = feedbackText.length > 0 ? true : false;
    var ratingsLevel = data.rating;
    var ratingsGiven = +ratingsLevel > 0 ? true : false;

    setBillItems({
      items: billItems.items,
      orderId: billItems.orderId,
      selectedItemRows: billItems.selectedItemRows,
      rowSelection: billItems.rowSelection,
      feedbackText: feedbackText,
      feedbackGiven: feedbackGiven,
      ratingsLevel: +ratingsLevel,
      ratingsGiven: ratingsGiven,
      loading: billItems.loading,
      orderLoyaltyInfo: billItems.orderLoyaltyInfo,
    });
  }

  async function updateItemToCart(selectedRows: any) {
    var arr1: any;
    var arr2: any;
    arr1 = [];
    arr2 = [];
    var updateCartAction = "deletion";
    if (selectedRows.length > rowSelection.selectedRowKeys.length) {
      arr1 = selectedRows;
      arr2 = rowSelection.selectedRowKeys;
      updateCartAction = "insertion";
    } else {
      arr1 = rowSelection.selectedRowKeys;
      arr2 = selectedRows;
    }
    let difference = arr1.filter((x: any) => arr2.indexOf(x) === -1);

    for (var i = 0; i < difference.length; i++) {
      var newItem: any;
      newItem = billItems.items[difference[i] - 1];
      if (updateCartAction === "insertion") {
        let quantity = +newItem.quantity;
        let sub_total = getPriceWithoutGst(
          +newItem.sgst + +newItem.cgst,
          +newItem.price * quantity
        );

        let newCartItem = {
          product_section: "bill_view",
          product_id: newItem.product_id,
          quantity: quantity,
          cart_id: billData.cartId,
          cgst: newItem.cgst,
          sgst: newItem.sgst,
          discount: newItem.discount,
          total: +newItem.price * quantity,
          sub_total: sub_total,
        };

        let res = await insertCartItems(newCartItem);

        if (res.length > 0) {
          gtag("event", "cart_item_added", {
            user: userId,
            source: "reorder",
            store: retailStore.id,
            product: newItem?.name || "notset",
          });

          message.success({
            content: "Items added to cart",
            duration: 1,
            style: {
              marginTop: "80vh",
            },
          });
        }
      } else if (updateCartAction === "deletion") {
        var productId = newItem.product_id;
        var itemRowId = billData.reorderItems.filter((x: any) => {
          if (x.product_id === productId) {
            return x;
          }
        });

        var res = await deleteCartItem(itemRowId[0].id);

        if (res.length > 0) {
          message.error({
            content: "Items deleted from cart",
            duration: 1,
            style: {
              marginTop: "80vh",
            },
          });
        }
      }
    }
    setCheckBoxCheck();
  }

  const rowSelection = {
    selectedRowKeys: billItems.selectedItemRows,
    onChange: (selectedRowsKeys: React.Key[], selectedRows: DataType[]) => {
      // add item to cart via updateItemToCart API call
      updateItemToCart(selectedRowsKeys);
    },
    getCheckboxProps: (record: DataType) => ({}),
    columnWidth: "35px",
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  var itemsColumns = billData.reorder ? reorderColumns : columns;
  const c = itemsColumns.map((col: any) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => {
        var rowData: any;
        rowData = billItems.rowSelection;
        if (rowData.selectedRowKeys !== undefined) {
          return rowData.selectedRowKeys.includes(
            parseInt(record.key.toString())
          )
            ? {
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave,
                handleQtyUpdate: handleQtyUpdate,
              }
            : {
                record,
                dataIndex: col.dataIndex,
                title: col.title,
              };
        }
      },
    };
  });

  const summaryRow = (name: string, value: number) => (
    <Table.Summary.Row className={classes.summaryRow} key={name}>
      <Table.Summary.Cell index={0} colSpan={3}>
        <Text strong>{name}</Text>
      </Table.Summary.Cell>
      <Table.Summary.Cell className={classes.summaryText} index={1} colSpan={2}>
        <Text strong>
          {name === "Discount" ? "- " : ""}
          ₹&nbsp;{value}
        </Text>
      </Table.Summary.Cell>
    </Table.Summary.Row>
  );

  const orderSummary = () => {
    const { discount, sub_total, total } = order;
    return [
      summaryRow("Subtotal", sub_total),
      summaryRow("Discount", discount),
      summaryRow("Total", total),
    ];
  };

  const updateReorderQuantity = (id: any, quantity: any, data?: any) => {
    let newItems: any = data ? [...data] : [...billItems.items];
    for (let i = 0; i < newItems.length; i++) {
      if (newItems[i].product_id === id) {
        newItems[i].reorder_qty = quantity;
      }
    }
    setBillItems({
      ...billItems,
      items: newItems,
    });
  };

  const handleSave = async (row: any) => {
    let sub_total = getPriceWithoutGst(
      +row.sgst + +row.cgst,
      +row.pack_price * row.quantity
    );
    let items = {
      sub_total,
      quantity: row.quantity,
      total: row.pack_price * row.quantity,
    };

    let res = await updateCartItemsByProductId(
      billData.cartId,
      row.product_id,
      items
    );
    updateReorderQuantity(row.product_id, row.quantity);
    if (res.length > 0) {
      setCheckBoxCheck();
    }
  };

  const handleQtyUpdate = (record: any) => {
    for (var i = 0; i < billData.reorderItems.length; i++) {
      if (billData.reorderItems[i].product_id === record.product_id) {
        return billData.reorderItems[i].quantity;
      }
    }
  };

  async function fetchOrderItems() {
    if (order.id) {
      var items = await getOrderItems(order.id);
      for (var i = 0; i < items.length; i++) {
        items[i]["key"] = i + 1;
      }
      // fetch ratings and feedback
      var ratingsAndFeedback = await getRatings(order.id, "order");
      var feedbackText = "";
      var feedbackGiven = false;
      var ratingsLevel = 0;
      var ratingsGiven = false;
      if (ratingsAndFeedback) {
        feedbackText = ratingsAndFeedback.feedback;
        feedbackGiven = feedbackText.length > 0 ? true : false;
        ratingsLevel = ratingsAndFeedback.rating;
        ratingsGiven = +ratingsLevel > 0 ? true : false;
      }

      var data = await getOrderLoyaltyInfo(order.id, retailStore.id);
      var orderLoyaltyInfo: any = data.value;

      for (var i = 0; i < billData.reorderItems.length; i++) {
        updateReorderQuantity(
          billData.reorderItems[i].product_id,
          billData.reorderItems[i].quantity,
          items
        );
      }

      setBillItems({
        items: items,
        orderId: billItems.orderId,
        selectedItemRows: [],
        rowSelection: "",
        feedbackText: feedbackText,
        feedbackGiven: feedbackGiven,
        ratingsLevel: +ratingsLevel,
        ratingsGiven: ratingsGiven,
        loading: false,
        orderLoyaltyInfo: orderLoyaltyInfo,
      });
    }
  }

  const fetchShopBanner = async () => {
    let shopBannerImages = await getShopBanner(retailStore?.id, "");
    if (shopBannerImages?.length) setShopBanners(shopBannerImages);
  };

  useEffect(() => {
    if (
      (billItems.loading === true || billItems.orderId !== order.id) &&
      billData.activeKey === "2"
    ) {
      fetchOrderItems();
      fetchShopBanner();
    }
  }, [order, billData.activeKey]);

  useEffect(() => {
    if (!billItems.loading && billData.activeKey === "2") {
      var reorderIds = [];
      var selectedRows = [];
      for (var i = 0; i < billData.reorderItems.length; i++) {
        reorderIds.push(billData.reorderItems[i].product_id);
      }

      if (billItems.items[0] !== {}) {
        for (var i = 0; i < billItems.items.length; i++) {
          var oneItem: any;
          oneItem = billItems.items[i];
          if (reorderIds.includes(oneItem.product_id)) {
            selectedRows.push(oneItem.key);
          }
        }
      }

      var rowSelectionData: any;
      rowSelectionData = "";
      if (billData.reorder) {
        rowSelection.selectedRowKeys = selectedRows;
        rowSelectionData = rowSelection;
      }

      setBillItems({
        items: billItems.items,
        orderId: billItems.orderId,
        selectedItemRows: billItems.selectedItemRows,
        rowSelection: rowSelectionData,
        feedbackText: billItems.feedbackText,
        feedbackGiven: billItems.feedbackGiven,
        ratingsLevel: billItems.ratingsLevel,
        ratingsGiven: billItems.ratingsGiven,
        loading: false,
        orderLoyaltyInfo: billItems.orderLoyaltyInfo,
      });
    }
  }, [
    billData.reorder,
    billData.reorderItems.length,
    billData.checkBoxClicked,
  ]);

  useEffect(() => {
    if (billData.activeKey === "1") {
      setBillItems({
        items: [{}],
        selectedItemRows: [""],
        rowSelection: {},
        orderId: order.id,
        feedbackText: "",
        feedbackGiven: false,
        ratingsLevel: 0,
        ratingsGiven: false,
        loading: true,
        orderLoyaltyInfo: {},
      });
    }
  }, [billData.activeKey]);

  return (
    <>
      {!billItems.loading ? (
        <>
          <Row justify="start" align="middle" className={classes.filterSection}>
            <Col span={6} className={classes.orderNumber}>
              {billData.reorder ? (
                <a
                  id="reorder-cancel-button"
                  data-store={`${retailStore.name} - ${retailStore.id}`}
                  data-user={userId}
                >
                  <Button
                    size="small"
                    type="primary"
                    className={classes.reorderButton}
                    onClick={() => {
                      switchReorder();
                      setOffersDrawerVisibility(false);
                    }}
                    danger
                  >
                    Cancel
                  </Button>
                </a>
              ) : (
                <a
                  id="reorder-button"
                  data-store={`${retailStore.name} - ${retailStore.id}`}
                  data-user={userId}
                >
                  <Button
                    size="small"
                    type="primary"
                    icon={<ReorderIcon />}
                    className={classes.reorderButton}
                    onClick={() => switchReorder()}
                  >
                    Reorder
                  </Button>
                </a>
              )}
            </Col>
            <Col
              span={11}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Typography className={classes.time}>
                <ClockCircleOutlined style={{ marginRight: ".4rem" }} />
                {order.invoice_date && !order.invoice_date?.includes("00:00:00")
                  ? dayjs(order.invoice_date).format("hh:mm A")
                  : order.created_at
                  ? dayjs(order.created_at).format("hh:mm A")
                  : ""}
              </Typography>
            </Col>
            <Col
              span={7}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <DatePicker
                format="DD/MM/YYYY"
                value={
                  order.invoice_date
                    ? dayjs(order.invoice_date)
                    : dayjs(order.created_at)
                }
                open={false}
                allowClear={false}
                className={classes.dateBox}
                inputReadOnly
              />
            </Col>
          </Row>
          <WrapperHeightProvider>
            {(containerHeight) => (
              // previous
              // <div style={{ overflowY: "scroll", height: "700px" }}>
              // new for develpoment
              <div
                style={{
                  width: "100vw",
                  overflowY: "auto",
                  overflowX: "hidden",
                  height: bannerLength
                    ? "calc(100vh - 330px)"
                    : "calc(100vh - 220px)",
                }}
              >
                {billItems.items.length > 0 ? (
                  <Table
                    bordered
                    components={components}
                    className={classes.billTable}
                    columns={c}
                    rowSelection={billItems.rowSelection}
                    dataSource={billItems.items}
                    pagination={false}
                    size="small"
                    // scroll={{ y: "calc(100vh - 175px)" }}
                    summary={billData.reorder ? () => <></> : orderSummary}
                    footer={() => (
                      <>
                        {!billData.reorder ? (
                          <div className={classes.billFooterFeatures}>
                            {billItems.orderLoyaltyInfo ? (
                              <>
                                <Divider />
                                <div className={classes.purchasePointsDetails}>
                                  <div
                                    className={
                                      classes.purchasePointsDetailsTitle
                                    }
                                  >
                                    <div
                                      className={
                                        classes.downloadDocumentsTitleIcon
                                      }
                                    >
                                      <TrophyOutlined />
                                    </div>
                                    <div>
                                      Purchase Points{" "}
                                      <b>
                                        {billItems.orderLoyaltyInfo[
                                          "order_points"
                                        ] > 0
                                          ? billItems.orderLoyaltyInfo[
                                              "order_points"
                                            ].toFixed(2)
                                          : 0}
                                      </b>
                                    </div>
                                    {/* <div>
                                      Current Points <b>{billItems.orderLoyaltyInfo['current_loyalty_points'] > 0 ? billItems.orderLoyaltyInfo['current_loyalty_points'] : 0 }</b>
                                    </div> */}
                                    <div>
                                      Total Points{" "}
                                      <b>
                                        {billItems.orderLoyaltyInfo[
                                          "total_loyalty_points"
                                        ] > 0
                                          ? billItems.orderLoyaltyInfo[
                                              "total_loyalty_points"
                                            ].toFixed(2)
                                          : 0}
                                      </b>
                                    </div>
                                  </div>
                                </div>
                                <Divider />
                              </>
                            ) : (
                              ""
                            )}

                            <div className={classes.downloadDocuments}>
                              <div
                                className={classes.downloadDocumentsTitleIcon}
                              >
                                <DownloadOutlined />
                              </div>
                              <div>Download documents</div>
                              <a
                                href={order.s3_public_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                id="bill-receipt-download-btn"
                                data-source="Itemized Bill Page"
                                onClick={() =>
                                  !!!order?.s3_public_url &&
                                  message.info("Bill copy not available.")
                                }
                              >
                                <Button
                                  type="primary"
                                  shape="circle"
                                  icon={<DownloadBillIconLight />}
                                  className={classes.downloadDocumentBtn}
                                />
                              </a>

                              <Link to="/dashboard/stores/prescriptions-and-reports">
                                <Button
                                  type="primary"
                                  shape="circle"
                                  icon={<DownloadPrescriptionIconLight />}
                                  className={classes.downloadDocumentBtn}
                                />
                              </Link>
                            </div>
                            <Divider />

                            <BannerCard
                              storeId={retailStore.id}
                              page="bill_details_bottom"
                            />

                            <div className={classes.rateYourExperience}>
                              <div className={classes.rateYourExperienceTitle}>
                                <div
                                  className={classes.downloadDocumentsTitleIcon}
                                >
                                  <StarOutlined />
                                </div>
                                <div>
                                  {billItems.ratingsLevel > 0
                                    ? "You've selected emoji"
                                    : "How would you rate your experience?"}
                                </div>
                              </div>
                              <div className={classes.emojisList}>
                                {billItems.ratingsLevel > 0 ? (
                                  <>
                                    {billItems.ratingsLevel === 1 ? (
                                      <span>🤬</span>
                                    ) : billItems.ratingsLevel === 2 ? (
                                      <span>😡</span>
                                    ) : billItems.ratingsLevel === 3 ? (
                                      <span>😤</span>
                                    ) : billItems.ratingsLevel === 4 ? (
                                      <span>😠</span>
                                    ) : billItems.ratingsLevel === 5 ? (
                                      <span>🙁</span>
                                    ) : billItems.ratingsLevel === 6 ? (
                                      <span>😐</span>
                                    ) : billItems.ratingsLevel === 7 ? (
                                      <span>🙂</span>
                                    ) : billItems.ratingsLevel === 8 ? (
                                      <span>😊</span>
                                    ) : billItems.ratingsLevel === 9 ? (
                                      <span>😃</span>
                                    ) : billItems.ratingsLevel === 10 ? (
                                      <span>😍</span>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <span
                                      onClick={() =>
                                        postRatingsAndFeedback(
                                          1,
                                          billItems.feedbackText
                                        )
                                      }
                                    >
                                      🤬
                                    </span>
                                    <span
                                      onClick={() =>
                                        postRatingsAndFeedback(
                                          2,
                                          billItems.feedbackText
                                        )
                                      }
                                    >
                                      😡
                                    </span>
                                    <span
                                      onClick={() =>
                                        postRatingsAndFeedback(
                                          3,
                                          billItems.feedbackText
                                        )
                                      }
                                    >
                                      😤
                                    </span>
                                    <span
                                      onClick={() =>
                                        postRatingsAndFeedback(
                                          4,
                                          billItems.feedbackText
                                        )
                                      }
                                    >
                                      😠
                                    </span>
                                    <span
                                      onClick={() =>
                                        postRatingsAndFeedback(
                                          5,
                                          billItems.feedbackText
                                        )
                                      }
                                    >
                                      🙁
                                    </span>
                                    <span
                                      onClick={() =>
                                        postRatingsAndFeedback(
                                          6,
                                          billItems.feedbackText
                                        )
                                      }
                                    >
                                      😐
                                    </span>
                                    <span
                                      onClick={() =>
                                        postRatingsAndFeedback(
                                          7,
                                          billItems.feedbackText
                                        )
                                      }
                                    >
                                      🙂
                                    </span>
                                    <span
                                      onClick={() =>
                                        postRatingsAndFeedback(
                                          8,
                                          billItems.feedbackText
                                        )
                                      }
                                    >
                                      😊
                                    </span>
                                    <span
                                      onClick={() =>
                                        postRatingsAndFeedback(
                                          9,
                                          billItems.feedbackText
                                        )
                                      }
                                    >
                                      😃
                                    </span>
                                    <span
                                      onClick={() =>
                                        postRatingsAndFeedback(
                                          10,
                                          billItems.feedbackText
                                        )
                                      }
                                    >
                                      😍
                                    </span>
                                  </>
                                )}
                              </div>
                            </div>
                            <Divider />
                            <div className={classes.yourFeedback}>
                              <div className={classes.yourFeedbackTitle}>
                                <div
                                  className={classes.downloadDocumentsTitleIcon}
                                >
                                  <LikeOutlined />
                                </div>
                                <div>Your feedback</div>
                                {!billItems.feedbackGiven ? (
                                  <div className={classes.feedbackOptions}>
                                    <Button
                                      type="primary"
                                      shape="circle"
                                      icon={<EditOutlined />}
                                    />
                                    {/* <Button
                                    type="primary"
                                    shape="circle"
                                    icon={<MicIcon />}
                                  /> */}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              {!billItems.feedbackGiven ? (
                                <>
                                  <TextArea
                                    className={classes.textReviewInputField}
                                    autoSize={{ minRows: 7, maxRows: 7 }}
                                    placeholder="Type something..."
                                    value={billItems.feedbackText}
                                    onChange={(e: any) =>
                                      setBillItems({
                                        items: billItems.items,
                                        orderId: billItems.orderId,
                                        selectedItemRows:
                                          billItems.selectedItemRows,
                                        rowSelection: billItems.rowSelection,
                                        feedbackText: e.target.value,
                                        feedbackGiven: billItems.feedbackGiven,
                                        ratingsLevel: billItems.ratingsLevel,
                                        ratingsGiven: billItems.ratingsGiven,
                                        loading: billItems.loading,
                                        orderLoyaltyInfo:
                                          billItems.orderLoyaltyInfo,
                                      })
                                    }
                                  />
                                  <Button
                                    block
                                    size="small"
                                    type="primary"
                                    className={classes.submitReviewButton}
                                    onClick={() => {
                                      if (billItems.feedbackText.length > 0) {
                                        postRatingsAndFeedback(
                                          billItems.ratingsLevel,
                                          billItems.feedbackText
                                        );
                                      }
                                    }}
                                  >
                                    SUBMIT
                                  </Button>
                                </>
                              ) : (
                                <div
                                  style={{
                                    padding: "15px 10px",
                                    lineBreak: "anywhere",
                                  }}
                                >
                                  {billItems.feedbackText}
                                </div>
                              )}
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className={classes.checkOffers}>
                              <div className={classes.checkOffersText}>
                                Check offers
                              </div>
                              <a
                                id="check-offers-button"
                                data-store={`${retailStore.name} - ${retailStore.id}`}
                                data-user={userId}
                              >
                                <Button
                                  shape="circle"
                                  icon={<ThunderboltFilled />}
                                  className={classes.checkOffersButton}
                                  onClick={() =>
                                    setOffersDrawerVisibility(true)
                                  }
                                />
                              </a>
                              <OffersDrawer
                                visibility={offersDrawerVisibility}
                                setVisibility={setOffersDrawerVisibility}
                                onClose={() => setOffersDrawerVisibility(false)}
                              />
                            </div>
                          </>
                        )}
                      </>
                    )}
                  />
                ) : (
                  ""
                )}
              </div>
            )}
          </WrapperHeightProvider>
        </>
      ) : (
        <Skeleton className={classes.skeleton} />
      )}
    </>
  );
}

export default BillDetailsView;
