import { getSession, Session } from "client/reactives/session";
const url = process.env.REACT_APP_API_NODE_BASE_URL;

export async function getCustomerById(user_id: number) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/getCustomer?user_id=${user_id}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json[0];
    });
}
