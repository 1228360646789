import { Input, Select } from "antd";
import Button from "antd/es/button";
import Field from "../field/Field";
import React, { useEffect, useState } from "react";
import useForm from "../../hooks/useForm";
import { createUseStyles } from "react-jss";
import { FormProvider } from "react-hook-form";
import { getBrands, getProductCategories } from "api/retailerApi";
import Loader from "components/loader/Loader";

export default function AddPricingRuleForm({ onSkip, ...props }: any) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    brand: [],
    category: [],
  });

  const { isSubmitting, methods, submitHandler } = useForm<any>({
    ...props,
    // resolver: User.addCustomer(),
  });

  const fetchInitialData = async () => {
    let brandsRes = await getBrands();
    let categoriesRes = await getProductCategories("RAS01");

    if (brandsRes.length && categoriesRes.length) {
      setData({
        brand: brandsRes,
        category: categoriesRes,
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    if (loading) {
      fetchInitialData();
    }
  }, []);

  if (loading) {
    return <Loader height="30vh" />;
  }

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={submitHandler} className={classes.addCustomerFormView}>
          <Field as={Select} name="rule_tier" placeholder="Rule Tier">
            <Select.Option value="catalogue">Catalogue</Select.Option>
            <Select.Option value="category">Category</Select.Option>
            <Select.Option value="brand">Brand</Select.Option>
          </Field>

          <div
            hidden={!["category", "brand"].includes(methods.watch().rule_tier)}
          >
            <Field
              as={Select}
              name="referenced_entity_id"
              placeholder="Reference Entity"
            >
              {data[methods.watch().rule_tier]?.map(
                (opt: any, index: number) => (
                  <Select.Option key={index} value={opt.id}>
                    {opt?.brand_name ?? opt?.name}
                  </Select.Option>
                )
              )}
            </Field>
          </div>

          <Field
            as={Select}
            name="price_change_type"
            placeholder="Price Change Type"
          >
            <Select.Option value="percentage">Percentage</Select.Option>
            <Select.Option value="absolute">Absolute</Select.Option>
          </Field>

          <Field
            as={Input}
            type="number"
            name="price_discount_value"
            placeholder="Discount Value"
          />

          <Button block type="primary" htmlType="submit" loading={isSubmitting}>
            Add
          </Button>
        </form>
      </FormProvider>
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  addCustomerFormView: {
    "& .ant-btn": {
      fontWeight: "500",
      letterSpacing: "0.45px",
    },
  },
}));
