import { ArrowLeftOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import React from "react";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";

function AboutUs() {
  const classes = useStyles();
  const history = useHistory();

  const Section = ({ title, children }: any) => (
    <div className={classes.pageSection}>
      <div className={classes.sectionTitle}>{title}</div>
      <div className={classes.sectionContent}>{children}</div>
    </div>
  );

  return (
    <div className={classes.privacyPolicyPage}>
      <Row className={classes.header}>
        <Col className={classes.backButton} span={3}>
          <ArrowLeftOutlined onClick={() => history.goBack()} />
        </Col>
        <Col className={classes.pageHeader} span={20}>
          About Us
        </Col>
      </Row>
      <div className={classes.pageContent}>
        <div className={classes.pageSubtitle}>
          Raseet - a data digitization & remarketing platform
        </div>
        <Section title="">
            RASEET is a technology company that helps local stores to improve their 
            engagement with their customers. It does so by establishing connections 
            between the two via digital receipts. These digital receipts not only 
            serve as convenient records of the transactions that the customer has 
            executed at the store but also drive repeat business to the stores by 
            allowing the purchase of items from the bill receipt itself. To further 
            the engagement, RASEET helps the local stores to provide personalized 
            offers and promotions to their customers as well as value added 
            services like loyalty programs, spend analytics and 
            customer support.
        </Section>
      </div>
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  privacyPolicyPage: {
    background: "#fff",
  },
  backButton: {
    textAlign: "center",
  },
  pageHeader: {
    fontSize: 13,
    fontWeight: 700,
  },
  header: {
    height: 58,
    position: "sticky",
    top: "0",
    background: "transparent",
    alignItems: "center",
  },
  pageContent: {
    height: "calc(100vh - 58px)",
    padding: 15,
    overflowY: "auto",
  },
  pageSection: {
    marginBottom: 40,
  },
  pageSubtitle: {
    fontSize: 12,
    fontWeight: 500,
    margin: [0, 0, 30],
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: 500,
    marginBottom: 10,
  },
  sectionContent: {
    fontSize: 10,
    whiteSpace: "break-spaces",
    textAlign: "justify",

    "& b": {
      fontWeight: "500 !important",
    },
    "& ul": {
      paddingLeft: 30,
    },
  },
}));

export default AboutUs;
