import React from "react";
import { message, Modal, ModalProps } from "antd";
import { createUseStyles } from "react-jss";
import AddPricingRuleForm from "components/forms/AddPricingRuleFrom";
import { addPricingRule } from "api/retailerApi";
import { getSession, Session } from "client/reactives/session";

interface AddPricingRuleModalProps extends ModalProps {
  onRuleAdd: () => void;
  retailerData: {
    ID: number;
    Name: string;
  };
}

export default function AddPricingRuleModal({
  visible,
  onCancel,
  retailerData,
  onRuleAdd,
}: AddPricingRuleModalProps) {
  const classes = useStyles();
  const { userId } = getSession() as Session;

  const handleFormSubmit = async (values: any) => {
    let dataToSend = {
      ...values,
      user_id: userId,
      retail_store_id: retailerData.ID,
    };

    if (values?.rule_tier === "catalogue") {
      dataToSend.referenced_entity_id = "";
    }

    await addPricingRule(dataToSend).then((res) => {
      if (!!res?.data[0]?.id) {
        message.success(res.message);
        onRuleAdd();
        // @ts-ignore
        onCancel();
      }
    });
  };

  return (
    <Modal
      centered
      visible={visible}
      title={`Add New Rule`}
      footer={false}
      onCancel={onCancel}
      className={classes.addPricingRuleModal}
    >
      <AddPricingRuleForm onSubmit={handleFormSubmit} />
    </Modal>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  addPricingRuleModal: {
    maxWidth: "90vw",
  },
}));
