import { createUseStyles } from "react-jss";
import customeScrollbarStyle from "components/style/CustomScrollbar";

const useStyles = createUseStyles(({ colors }: Theme) => ({
  retailerDashboard: {
    height: "100vh",
    overflow: "hidden",
    color: "#000",
    "& *": {
      fontFamily: "Roboto",
    },
  },
  sidebarColumn: {
    transition: "all 0.6s",
  },
  pageContent: {
    height: "calc(100vh - 68.5px)",
    overflow: "auto",
    ...customeScrollbarStyle,
  },
}));

export { useStyles };
