import React, { useState } from "react";
import PlaceholderImage from "assets/svgs/placeholder-profile-img.svg";
import { RetailerDataType } from "components/pages/retailerDashboard";
import { createUseStyles } from "react-jss";
import { Avatar, Button, Typography } from "antd";
import {
  BellOutlined,
  LogoutOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

const getAcronym = (text: string) => {
  let acronym = text
    ?.split(/\s/)
    .reduce((response, word) => (response += word.slice(0, 1)), "");
  return acronym;
};

interface TopbarProps {
  retailerData: RetailerDataType;
}

export default function Topbar({ retailerData }: TopbarProps) {
  const classes = useStyles();
  const [logoLoaded, setLogoLoaded] = useState(false);

  return (
    <div className={classes.topbar}>
      <img
        alt="profile_image"
        hidden={!logoLoaded}
        onLoad={() => setLogoLoaded(true)}
        className={classes.profileImg}
        src={
          retailerData.Logo.length > 0 ? retailerData.Logo : PlaceholderImage
        }
      />
      {!logoLoaded && (
        <Avatar size="large" shape="square">
          {getAcronym(retailerData?.Name?.split(" ")[0])}
        </Avatar>
      )}

      <Typography.Text strong className={classes.retailerName}>
        {retailerData.Name}
      </Typography.Text>

      <div className={classes.topbarActions}>
        <Link to="/retailer/dashboard/chat">
          <Button type="text" icon={<MessageOutlined />} />
        </Link>
        <Link to="/logout?redirect=/retail/login">
          <Button type="text" icon={<LogoutOutlined />} />
        </Link>
      </div>
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  topbar: {
    height: 65,
    width: "100%",
    padding: [10, 20],
    background: colors.primary200,
    display: "flex",
    alignItems: "center",
    position: "fixed",
    top: 0,
    zIndex: 5,
  },
  profileImg: {
    height: "100%",
    objectFit: "contain",
  },
  retailerName: {
    padding: [0, 10],
    color: colors.light100,
  },
  topbarActions: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",

    "& .ant-btn": {
      margin: [0, 5],
    },

    "& .anticon": {
      fontSize: 20,
      margin: [0, 10],
      color: colors.light100,
    },
  },
}));
