import classNames from 'classnames'
import React, { useState } from 'react'
import Grid from 'antd/es/grid'
import Layout from 'antd/es/layout'
import {
  PercentageOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  FileOutlined
} from '@ant-design/icons'
import { useRouteMatch } from 'react-router-dom'
import { createUseStyles } from 'react-jss'

import Button from 'antd/es/button'
import HeaderMenu from 'components/HeaderMenu'
import Sidebar from 'components/Sidebar'

const CONTENT_SPACING = 16

const useStyles = createUseStyles(({ colors }: Theme) => ({
  layout: {
    height: '100vh',
    background: `${colors.light100} !important`
  },
  header: {
    background: colors.light100,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: [ 0, CONTENT_SPACING ]
  },
  content: {
    background: colors.light100,
    height: '100%',
    overflowY: 'auto'
  },
  content_mobile: {
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
    padding: 0
  }
}))

type CustomerLayoutProps = React.PropsWithChildren<{}>

const navLinks = (baseUrl: string) => ([
  {
    to: `${baseUrl}/bills`,
    title: 'Bills',
    icon: <FileOutlined />
  },
  {
    to: `${baseUrl}/offers`,
    title: 'Offers',
    icon: <PercentageOutlined />
  }
])

const { useBreakpoint } = Grid
const { Header, Content } = Layout

function CustomerLayout({ children }: CustomerLayoutProps) {
  const classes = useStyles()
  const screens = useBreakpoint()
  const { url } = useRouteMatch('/dashboard') || {}
  const [ sidebarCollapsed, setSidebarCollapsed ] = useState(false)

  const links = navLinks(url!)

  const toggleSidebarCollapsed = () => setSidebarCollapsed(!sidebarCollapsed)

  const isMobile = screens.xs

  const normalLayout = (
    <>
      <Sidebar
        collapsed={sidebarCollapsed}
        navLinks={links}
      />
      <Layout>
        <Header className={classes.header}>
          <Button type="primary" onClick={toggleSidebarCollapsed}>
            {sidebarCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </Button>
          <HeaderMenu />
        </Header>
        <Content className={classNames(classes.content, {
          [classes.content_mobile]: isMobile
        })}
        >
          {children}
        </Content>
      </Layout>
    </>
  )

  const mobileLayout = (
    <>
      <Layout>
        <Content className={classNames(classes.content, {
          [classes.content_mobile]: isMobile
        })}
        >
          {children}
        </Content>
      </Layout>
    </>
  )

  return (
    <Layout className={classes.layout}>
      {isMobile ? mobileLayout : normalLayout}
    </Layout>
  )
}

export {
  CONTENT_SPACING
}

export default CustomerLayout
