import React, { useEffect, useState } from "react";
import NoAuthOrderView from "noAuth/views/NoAuthOrderView";

interface PreviewProps {
  name?: string;
  gstNo?: string;
  contactNo?: string;
  address?: string;
  logo?: any;
  retailStore?: any;
  logoImg?: string;
}

let retailStoreDetails: any = {
  line1: "Shop LG 06, Paramount City Square",
  line2: "",
  landmark: "Bisrakh Jalalpur",
  pincode: "201306",
  city: "GB Nagar",
  state: "UP",
  name: "Business Name",
  description: "",
  authenticity_document_value: "123-123-123",
  authenticity_document_type: "GSTIN",
  retailerLogoUrl: "/Raseet-final-logo.png",
  primary_mobile_number: "9999999999",
};

const ordersData = {
  createdAt: "2021-06-20T18:30:00.000Z",
  discount: "1.71",
  gst: "1.83",
  id: "426",
  invoice_date: "2021-06-20T18:30:00.000Z",
  invoice_number: "BP000771",
  orderItems: [
    {
      bill_quantity: "1:0",
      cgst: "6",
      discount: "0",
      product: {
        name: "OMNACORTIL 10MG TAB",
        pack_price: "10.84",
        price: "10.84",
      },
      product_id: "115",
      quantity: "1.00",
      sgst: "6",
      subTotal: "9.539200000000001",
      total: "10.84",
    },
    {
      bill_quantity: "1:0",
      cgst: "6",
      discount: "0",
      product: {
        name: "OMNACORTIL 5MG TAB",
        pack_price: "6.27",
        price: "6.27",
      },
      product_id: "116",
      quantity: "1.00",
      sgst: "6",
      subTotal: "5.5176",
      total: "6.27",
    },
  ],
  retailStoreId: "53",
  round_off: "-0.40",
  subTotal: "17.11",
  total: "15.00",
};

export const Preview = ({ retailStore, logoImg }: PreviewProps) => {
  const [retailerData, setRetailerData] = useState({ ...retailStoreDetails });

  useEffect(() => {
    let tempData: any = { ...retailerData };
    // retailStore
    tempData.name = getValue(retailStore.name, retailStoreDetails.name);
    tempData.authenticity_document_value = getValue(
      retailStore.authenticity_document_value,
      retailStoreDetails.authenticity_document_value
    );
    tempData.authenticity_document_type = getValue(
      retailStore.authenticity_document_type,
      retailStoreDetails.authenticity_document_type
    );
    tempData.primary_mobile_number = getValue(
      retailStore.primary_mobile_number || retailStore.primaryMobileNumber,
      retailStoreDetails.primary_mobile_number
    );
    // Address
    tempData.line1 = getValue(
      retailStore.line1 || retailStore.address,
      retailStoreDetails.line1
    );
    tempData.line2 = getValue(retailStore.line2, retailStoreDetails.line2);
    tempData.city = getValue(retailStore.city, retailStoreDetails.city);
    tempData.landmark = getValue(
      retailStore.landmark || retailStore.locality,
      retailStoreDetails.landmark
    );
    tempData.pincode = getValue(
      retailStore.pincode,
      retailStoreDetails.pincode
    );
    tempData.state = getValue(retailStore.state, retailStoreDetails.state);
    // logo
    let logo =
      retailStore?.logo?.name !== undefined
        ? URL.createObjectURL(retailStore.logo)
        : logoImg;
    tempData.retailerLogoUrl = getValue(
      logo,
      retailStoreDetails.retailerLogoUrl
    );

    setRetailerData(tempData);
    console.log(tempData);
  }, [retailStore]);

  const getValue = (value: any, defaultValue: any) => {
    if (checkValue(value)) {
      return value;
    } else {
      return defaultValue;
    }
  };

  const checkValue = (value: any) =>
    value !== "" && value !== null && value !== undefined;

  return (
    <div style={{ height: "100%", position: "relative" }}>
      <NoAuthOrderView
        retailStore={retailerData}
        order={ordersData}
        noHeaderOptions
        retailerLogoUrl={retailerData.retailerLogoUrl}
      />
    </div>
  );
};
