import { Input, message, Modal, ModalProps, Typography } from "antd";
import { reconcileReport } from "api/retailerApi";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import OrderItemsModal from "./OrderItemsModal";

interface ReportReorderModalProps extends ModalProps {
  reorderId: number;
}

export default function ReportReorderModal({
  reorderId,
  ...props
}: ReportReorderModalProps) {
  const classes = useStyle();
  const [reportInput, setReportInput] = useState("");

  const handleSubmitReport = async () => {
    if (!reportInput.length)
      return message.info("Please input your report reason");

    await reconcileReport({
      reorder_id: +reorderId,
      report_reason: reportInput,
    }).then((res) => {
      if (res?.reported) {
        message.success("Report submmited successfully!");
        //  @ts-ignore
        props.onCancel(null);
      } else {
        message.error("Something went wrong!");
      }
    });
  };

  return (
    <Modal {...props} title="Report" okText="Map" onOk={handleSubmitReport}>
      <Typography.Text style={{ marginBottom: 20, display: "block" }}>
        Report / Raise dispute for reorder #{reorderId}.
      </Typography.Text>

      <Input.TextArea
        rows={5}
        autoFocus
        value={reportInput}
        placeholder="Report details / reason"
        onChange={(e) => setReportInput(e.target.value)}
      />
    </Modal>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({}));
