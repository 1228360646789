import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(({ colors }: Theme) => ({
  customerDashboard: {
    "& *": {
      fontFamily: "'ROBOTO'",
    },
  },
}));

export { useStyles };
