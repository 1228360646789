import { PaperClipOutlined, SendOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import React, { useRef } from "react";
import { createUseStyles } from "react-jss";

function ChatInput(props: any) {
  const classes = useStyles();
  const { onMessageSend, onAttachmentChange, ...inputProps } = props;
  const screens = useBreakpoint();
  const isLarge = props.large ?? screens.xl;

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleMsgFormSubmit = (e: any) => {
    e.preventDefault();
    onMessageSend();
  };

  return (
    <div className={classes.chatInputContainer}>
      <input
        id="gallery"
        accept="image/*"
        type="file"
        hidden
        ref={hiddenFileInput}
        onChange={onAttachmentChange}
      />
      <Button
        size={isLarge ? "large" : "middle"}
        shape="circle"
        onClick={handleClick}
        className={classes.attachmentButton}
        icon={<PaperClipOutlined />}
      />
      <form onSubmit={handleMsgFormSubmit} className={classes.msgInputField}>
        <Input
          size={isLarge ? "middle" : "small"}
          placeholder="Type Something..."
          {...inputProps}
        />
      </form>
      <Button
        shape="circle"
        size={isLarge ? "large" : "small"}
        icon={<SendOutlined />}
        onClick={onMessageSend}
      />
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  chatInputContainer: {
    width: "100%",
    padding: "0 0.4rem",
    margin: "8px 0px",
    background: "#F2F2F2",
    color: "#838383",
    borderRadius: "4rem",
    overflow: "hidden",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "& *": {
      border: "none",
      background: "inherit",
      color: "inherit",

      "&:hover": {
        background: "inherit",
      },
    },
  },

  iconName: {
    fontSize: ".9rem",
    color: colors.primary,
  },

  attachmentButton: {
    background: "inherit",
  },

  emojiIcon: {
    minWidth: "0px !important",
  },

  msgInputField: {
    width: "100%",

    "& input": {
      paddingLeft: 0,
      paddingRight: 0,
      outline: "none",
      width: "100%",
      height: "100%",
      lineHeight: "95%",
      fontWeight: 600,

      "&:focus": {
        boxShadow: "none",
      },
    },
  },
  "@media (max-width: 1050px)": {
    msgInputField: {
      "& input": { fontSize: 12 },
    },
  },
}));

export default ChatInput;
