import ChatPage from "./ChatPage/ChatPage";
import Loader from "components/loader/Loader";
import constants from "../../../constants/constants";
import { getRetailerRetailStores } from "api/retailerApi";
import React, { useEffect, useState, useRef } from "react";
import BusinessSummary from "./BusinessSummary/BusinessSummary";
import { Route, Redirect, useRouteMatch } from "react-router-dom";
import CustomerDetailsPage from "./CustomerDetails/CustomerDetails";
import EditBusinessProfile from "./EditBusinessProfile/EditBusinessProfile";
import IncomingOrders from "components/pages/retailerDashboard/IncomingOrders/IncomingOrders";
import RetailerDashboardLayout from "components/pages/retailerDashboard/RetailerDashboardLayout/RetailerDashboardLayout";
import LoyaltyProgram from "./LoyaltyProgram/LoyaltyProgram";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import RetailerDashboardMobile from "../retailerDashboardMobile";
import ConfigsPage from "./ConfigsPage/ConfigsPage";
import InventoryPage from "./InventoryPage/InventoryPage";
import PromotionsPage from "./SinglePages/PromotionsPage";
import MarketingCampaignPage from "./MarketingCampaign/MarketingCampaign";
import MarketingCampaignSetupPage from "./MarketingCampaign/MarketingCampaignSetup";
import NewBusinessSummary from "./BusinessSummary/NewBusinessSummary";
import HomePage from "./HomePage/HomePage";

const Chat = require("twilio-chat");

export type RetailerDataType = {
  ID: number;
  Name: string;
  CreatedAt: string;
  Logo: string;
};

function Index() {
  // -------------NEW STATES IN USE-----------------------------
  const { path } = useRouteMatch("/retailer/dashboard") || {};
  const [loading, setLoading] = useState(true);
  const [client, setClient] = useState<any>();
  const tokenRef = useRef<any>();
  const isMD = useBreakpoint().md;
  const [retailerInfo, setRetailerInfo] = useState<RetailerDataType>({
    ID: 0,
    Name: "",
    CreatedAt: "",
    Logo: "",
  });
  // -----------------------------------------------------------

  const getTwilioToken = async (id: any) => {
    const url = process.env.REACT_APP_API_NODE_BASE_URL;
    const data = await fetch(`${url}/createTwilioToken`, {
      method: "POST",
      body: JSON.stringify({
        id: id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => res.json());
    tokenRef.current = data.token;
  };

  async function instantiateTwilio() {
    if (retailerInfo.ID > 0) {
      try {
        await getTwilioToken(retailerInfo.ID);
      } catch {
        console.log("Unable to get token, please reload this page");
        alert("Something went wrong, please reload !");
        // throw new Error("Unable to get token, please reload this page");
      }
      setClient(await Chat.Client.create(tokenRef.current));
    }
  }

  async function clientRefresh() {
    if (client !== undefined) {
      client.on("tokenAboutToExpire", async function () {
        await getTwilioToken(retailerInfo.ID);
        client.updateToken(tokenRef.current);
      });

      client.on("tokenExpired", async function () {
        await getTwilioToken(retailerInfo.ID);
        client.updateToken(tokenRef.current);
      });
    }
  }

  useEffect(() => {
    async function get() {
      if (loading) {
        const retailStore = await getRetailerRetailStores();
        if (retailStore?.id) {
          setLoading(false);
          var retailerId: number = +retailStore?.id;
          setRetailerInfo({
            ID: retailerId,
            Name: retailStore?.name,
            CreatedAt: retailStore?.created_at,
            Logo: constants.s3Url + retailStore?.logo_data.key,
          });
        }
      }
    }
    if (retailerInfo.ID === 0) {
      const favicon = document.getElementById("favicon");
      const touchFavicon = document.getElementById("touch-favicon");
      var userType = localStorage.getItem("userType");
      if (userType === "Retailer" && favicon && touchFavicon) {
        favicon.setAttribute("href", "/Retailer-logo.png");
        touchFavicon.setAttribute("href", "/Retailer-logo.png");
      }
      get();
    }
  });

  useEffect(() => {
    if (
      window.location.pathname !== "/retailer/dashboard/business-summary" &&
      window.location.pathname !== "/retailer/dashboard/loyalty-program" &&
      isMD
    ) {
      instantiateTwilio();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retailerInfo.ID]);

  useEffect(() => {
    if (isMD) {
      clientRefresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  if (!isMD) {
    return retailerInfo.ID > 0 ? (
      <RetailerDashboardMobile retailerData={retailerInfo} />
    ) : (
      <></>
    );
  }

  return (
    <RetailerDashboardLayout retailerData={retailerInfo}>
      {loading ? (
        <Loader />
      ) : (
        <>
          {retailerInfo.ID > 0 ? (
            <>
              <Route
                path={path}
                exact
                render={() => <Redirect to={"/retailer/dashboard/home"} />}
              />
              {client !== undefined ? (
                <Route
                  path={"/retailer/dashboard/incoming-orders"}
                  render={() => (
                    <IncomingOrders
                      client={client}
                      retailerData={retailerInfo}
                    />
                  )}
                />
              ) : (
                ""
              )}
              <Route
                path={"/retailer/dashboard/home"}
                render={() => <HomePage retailerData={retailerInfo} />}
              />
              <Route
                path={"/retailer/dashboard/business-summary"}
                render={() => (
                  <NewBusinessSummary retailerData={retailerInfo} />
                )}
              />
              <Route
                path={"/retailer/dashboard/customer-details"}
                render={() => (
                  <CustomerDetailsPage
                    client={client}
                    retailerData={retailerInfo}
                  />
                )}
                exact
              />
              <Route
                path={"/retailer/dashboard/chat/"}
                render={() => (
                  <ChatPage client={client} retailerData={retailerInfo} />
                )}
                exact
              />
              <Route
                path={"/retailer/dashboard/inventory"}
                render={() => <InventoryPage retailerData={retailerInfo} />}
              />
              <Route
                path={"/retailer/dashboard/loyalty-program"}
                render={() => <LoyaltyProgram retailerData={retailerInfo} />}
              />
              <Route
                path={"/retailer/dashboard/configs"}
                render={() => <ConfigsPage retailerData={retailerInfo} />}
              />
              <Route
                path={"/retailer/dashboard/promotions"}
                render={() => <PromotionsPage retailerData={retailerInfo} />}
              />
              <Route
                exact
                path={"/retailer/dashboard/marketing-campaigns"}
                render={() => (
                  <MarketingCampaignPage retailerData={retailerInfo} />
                )}
              />
              <Route
                path={"/retailer/dashboard/marketing-campaigns/new"}
                render={() => (
                  <MarketingCampaignSetupPage retailerData={retailerInfo} />
                )}
              />
              <Route
                path={"/retailer/dashboard/edit-business-profile/"}
                render={() => (
                  <EditBusinessProfile
                    retailerData={retailerInfo}
                    setRetailerInfo={setRetailerInfo}
                  />
                )}
                exact
              />
            </>
          ) : (
            <Loader />
          )}
        </>
      )}
    </RetailerDashboardLayout>
  );
}

export default Index;
