import moment from "moment";
import React from "react";
import { createUseStyles } from "react-jss";

function ChatDate({ date }: any) {
  const classes = useStyles();

  const getDate = () => {
    //const formatedData = moment(date, "dddd, Do MMM").format("dddd, Do MMM");
    var newDate = new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    }); //weekday: 'long'
    //var today= new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
    // dddd, Do MMM
    if (newDate === moment().format("MMM D, YYYY")) {
      return "Today";
    } else {
      return newDate;
    }
  };
  //var slicedDate = date.slice(4)

  return <div className={classes.chatDate}>{getDate()}</div>;
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  chatDate: {
    width: "100%",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#A1A1A1",
  },
  "@media (min-width: 768px) and (max-width: 1050px)": {
    chatDate: {
      fontSize: "0.8rem",
    },
  },
}));

export default ChatDate;
