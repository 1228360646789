import React, {useState, useEffect} from "react";
import { Redirect, useRouteMatch, Route } from "react-router-dom";
import { generateGetUrlForUid, getOrderImage } from "api/api";
import Loader from "components/loader/Loader";
import { createUseStyles } from "react-jss";

type ViewInvoiceProps = {};

function ViewInvoicePage({}: ViewInvoiceProps) {
    const classes = useStyles();
    const [loading, setLoading] = useState<any>(false);
    const [orderData, setOrderData] = useState<any>();
    const [invoiceUrl, setInvoiceUrl] = useState<any>();
    const {
      params: { uid },
      url,
    } = useRouteMatch<any>() || {};

    useEffect(() => {
        async function get() {
            if (!loading) {
                const order = await getOrderImage(uid);
                setOrderData(order)
                console.log(order)
                const imageUrl = await generateGetUrlForUid(
                    order[0].s3_key,
                    "raseetipp"
                )
                if (imageUrl) {
                    setInvoiceUrl(imageUrl)
                    setLoading(true)
                }
            }
        }
        //<Redirect to={invoiceUrl} />
        get()
    }, [])

    return (
        <>
        {orderData !== undefined && invoiceUrl !== undefined ? (<>
            <div className={classes.invoiceImg}>
                <img className={classes.invoiceImg} src={invoiceUrl} alt="Invoice"/>
            </div>
        </>) : ( <Loader />)}
        </>
    )
}

const useStyles = createUseStyles(() => ({
      invoiceImg: {
        width: "100%",
        maxWidth: "1920px",
        minWidth: "540px",
      }
  }));

export default ViewInvoicePage;