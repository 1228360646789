import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Input, message } from "antd";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";

export default function EnterPasswordPage({
  mobileNumber,
  onLoginModeChange,
  onSubmit,
  onBack,
}: any) {
  const classes = useStyles();
  const [password, setPassword] = useState("");

  const handleSubmit = () => {
    if (password.length > 8)
      return message.info("Password must be atleast 8 characters.");

    onSubmit(password);
  };

  return (
    <div>
      <div className={classes.pageHeader}>
        <Button icon={<ArrowLeftOutlined />} onClick={onBack} type="text" />
        {/* <Link to="">Help</Link> */}
      </div>

      <div className={classes.otpFormWrapper}>
        <div className={classes.enterOtpText}>
          Enter the password for your account on
        </div>
        <div className={classes.mobileNumberText}>+91{mobileNumber}</div>

        <Input.Password
          size="large"
          value={password}
          placeholder="Password"
          className={classes.passwordInput}
          onChange={(e) => setPassword(e.target.value)}
        />

        <div className={classes.continueButtonWrapper}>
          <Button block size="large" type="primary" onClick={handleSubmit}>
            Continue
          </Button>
        </div>

        <div className={classes.bottomOptions}>
          <div
            onClick={onLoginModeChange}
            style={{
              width: "100%",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Login with OTP
          </div>
        </div>
      </div>
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  pageHeader: {
    height: 80,
    padding: [0, 25],

    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "& .anticon": {
      fontSize: 18,
    },
  },
  otpFormWrapper: {
    padding: [0, 25],
  },
  enterOtpText: {
    // fontWeight: 500,
  },
  mobileNumberText: {
    fontWeight: 500,
    marginBottom: 20,
  },

  bottomOptions: {
    marginTop: 20,
    fontSize: 12,
    color: colors.dark400,

    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  CountDown: {
    // width: "100%",
    "& .ant-statistic-content-value": {
      fontSize: "0.55em",
      color: colors.dark400,
    },
    "& .ant-statistic-content": {
      display: "flex",
      justifyContent: "flex-start",
    },
  },
  continueButtonWrapper: {
    width: "100%",
    padding: [10, 10, 15],
    position: "fixed",
    bottom: 0,
    left: 0,

    "& .ant-btn": {
      fontSize: 12,
      marginTop: 20,
      borderRadius: 5,
    },
  },
  passwordInput: {
    borderRadius: 5,

    "& input": {
      fontSize: 12,
    },
  },
}));
