import React, { useState } from 'react'
import Layout from 'antd/es/layout'
import Typography from 'antd/es/typography'
import { createUseStyles } from 'react-jss'
import { useLocation, useRouteMatch } from 'react-router-dom'
import {
  AuditOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ShopOutlined
} from '@ant-design/icons'

import HeaderMenu from 'components/HeaderMenu'
import Button from 'antd/es/button'
import Sidebar from 'components/Sidebar'

const CONTENT_SPACING = 16

const useStyles = createUseStyles({
  layout: {
    height: '100vh',
    background: '#fff'
  },
  header: {
    background: '#fff',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: [ 0, CONTENT_SPACING ]
  },
  content: {
    background: '#fff',
    height: '100%',
    margin: CONTENT_SPACING,
    overflowY: 'auto',
    padding: CONTENT_SPACING
  }
})

type AdminLayoutProps = React.PropsWithChildren<{}>

const { Header, Content } = Layout

const navLinks = (baseUrl: string) => ([
  {
    to: `${baseUrl}/retails`,
    title: 'Retail Stores',
    icon: <ShopOutlined />
  },
  {
    to: `${baseUrl}/orders`,
    title: 'Orders',
    icon: <AuditOutlined />
  }
])

function AdminLayout({ children }: AdminLayoutProps) {
  const classes = useStyles()
  const { url } = useRouteMatch('/admin/dashboard') || {}
  const { pathname } = useLocation()
  const [ sidebarCollapsed, setSidebarCollapsed ] = useState(false)

  const links = navLinks(url!)
  const activeNavLink = links.find((link) => pathname.match(link.to))!

  const toggleSidebarCollapsed = () => setSidebarCollapsed(!sidebarCollapsed)

  return (
    <Layout className={classes.layout}>
      <Sidebar
        collapsed={sidebarCollapsed}
        navLinks={links}
      />
      <Layout>
        <Header className={classes.header}>
          <Button type="primary" onClick={toggleSidebarCollapsed}>
            {sidebarCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </Button>
          <Typography.Title level={4}>{activeNavLink?.title}</Typography.Title>
          <HeaderMenu />
        </Header>
        <Content className={classes.content}>
          {children}
        </Content>
      </Layout>
    </Layout>
  )
}

export default AdminLayout
