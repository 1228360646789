import { getSession, Session } from "client/reactives/session";
const url = process.env.REACT_APP_API_NODE_BASE_URL;

export async function fetchReorderCustomers(
  id: number,
  page?: any,
  search?: any,
  orderStatus?: string,
  priority?: boolean | string
) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/getReorders?retail_store_id=${id}&status=${orderStatus}&limit=20&page=${
    page ?? ""
  }&search=${search ?? ""}&priority=${priority}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
}
