import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(({ colors }: Theme) => ({
  activeOrdersPage: {
    height: "100vh",
    background: colors.light300,
  },
  header: {
    height: 60,
    display: "flex",
    zIndex: 6,
    background: "inherit",
    color: "#2A9D8F",
    width: "100%",
    position: "fixed",
    alignItems: "center",
  },
  headerIcon: {
    padding: 20,
    fontSize: "1rem",
  },
  headerTitle: {
    fontWeight: 700,
    fontSize: 13,
  },
  activeOrders: {
    padding: "1rem 2rem 2rem",
    height: "calc(100vh - 58px)",
    overflow: "auto",
  },
  centreText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    fontWeight: "bold",
    fontSize: "1.2rem",
  },
  center: {
    textAlign: "center",
  },
  moreDetails: {
    fontSize: 13,
    fontWeight: 700,
  },
  topAlignment: {
    alignItems: "center",
    padding: "15px 0px",
  },
}));

export default useStyles;
