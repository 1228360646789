import { createUseStyles } from "react-jss";
import customeScrollbarStyle from "components/style/CustomScrollbar";

const useStyles = createUseStyles(({ colors }: Theme) => ({
  itemsListCard: {
    width: "100%",
    height: "100%",
    padding: "0.5rem",
    backgroundColor: colors.light100,
    borderRadius: "5px",
    boxShadow:
      "-1px -1px 4px rgba(34, 94, 119, 0.25), 1px 1px 4px rgba(34, 94, 119, 0.25)",
  },
  listSettings: {
    minHeight: 70,
    width: "fit-content",
    marginBottom: 10,
    padding: "0 0.5rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
  },
  filterBtn: {},
  itemsList: {
    height: "calc(100% - 105px)",
    overflowY: "auto",

    //  custom scrollbar
    ...customeScrollbarStyle,
  },
  customerItem: {
    width: "100%",
    height: 40,
    cursor: "pointer",
    paddingRight: "1rem",
    paddingLeft: "calc(1rem + 30px)",
    color: "#505050",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `1.5px solid ${colors.light400}`,
    position: "relative",
  },
  selectedItem: {
    backgroundColor: "#ededed",
    borderBottom: "none",
  },
  CustomerItemLeft: {
    display: "flex",
    justifyContent: "center",
    // alignItems: "center",

    "& .anticon": {
      marginTop: "10px",
      marginRight: "15px",
      fontSize: 12,
      color: "#E76F51",
      position: "absolute",
      left: 22,
      top: 4,
    },
  },
  customerID: {
    display: "none",
    fontWeight: 700,
    fontSize: "1.05rem",
    paddingBottom: "0.5rem",
  },
  customerName: {
    fontWeight: 700,
    textTransform: "capitalize",
    fontSize: 12,
  },
  customerTotal: {
    fontWeight: 700,
    fontSize: 12,
  },
  centreText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    fontWeight: "bold",
    fontSize: "1.1rem",
    color: "#225E77",
  },
  "@media (max-width: 1050px)": {
    listSettings: {
      minHeight: 70,
    },
    centreText: { fontSize: "1rem" },
  },
}));

export { useStyles };
