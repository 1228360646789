import {
  CalendarOutlined,
  ClockCircleOutlined,
  DownloadOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import Col from "antd/es/col";
import Row from "antd/es/row";
import Card from "antd/es/card";
import Empty from "antd/es/empty";
import DatePicker from "components/DatePicker";
import React, { useEffect, useState } from "react";
import { useStyles } from "components/views/Styles/BillListView.style";
import { Button } from "antd";
import OrderDownloadModal from "components/modals/OrderDownloadModal";
import gtag from "components/Tracking/gtag";
import BannerCard from "components/cards/BannerCard";

type BillListViewProps = {
  billData: {
    storeDetails: Object;
    userBills: Array<any>;
    selectedBillIndex: number;
    activeKey: string;
    loading: boolean;
  };
  setSelectedBillIndex: any;
  bannerLength?: number;
};

function BillListView({
  billData,
  setSelectedBillIndex,
  bannerLength,
}: BillListViewProps) {
  const classes = useStyles();
  const [pageData, setPageData] = useState<any>({
    availableDates: [],
    downloadModalVisible: false,
    downloadModalSelectedOrder: {},
  });

  function openDownloadModal(order: any) {
    setPageData({
      ...pageData,
      downloadModalVisible: true,
      downloadModalSelectedOrder: order,
    });
  }

  function closeDownloadModal() {
    setPageData({
      ...pageData,
      downloadModalVisible: false,
      downloadModalSelectedOrder: {},
    });
  }

  useEffect(() => {
    let datesArray = billData?.userBills?.map((order) =>
      dayjs(order?.invoice_date).format("DD-MM-YYYY")
    );
    setPageData({ ...pageData, availableDates: datesArray });
  }, [billData]);

  function onCardClick(key: number, order: any) {
    setSelectedBillIndex(key);
    gtag("event", "bill_click", {
      bill: order.invoice_number,
      store: order.retail_store_id,
    });
  }

  const onDateFilterChange = (date: any) => {
    // if (date === null) {
    //   const filteredOrders = orders;
    //   setOrderList(filteredOrders);
    //   if (filteredOrders !== undefined) {
    //     setDataSource?.([
    //       { key: 0, item: "", quantity: 0, price: 0, total: 0 },
    //     ]);
    //     setSelectedOrder?.(filteredOrders[0]);
    //   }
    // } else {
    //   //setShow(true);
    //   const filteredOrders = orders?.filter((order) => {
    //     var d = dayjs(order.createdAt).format("DD-MM-YYYY");
    //     return date.format("DD-MM-YYYY") === d;
    //   });
    //   setOrderList(filteredOrders);
    //   if (filteredOrders !== undefined) {
    //     setDataSource?.([
    //       { key: 0, item: "", quantity: 0, price: 0, total: 0 },
    //     ]);
    //     setSelectedOrder?.(filteredOrders[0]);
    //   }
    // }
  };

  const BillListItemCard = (order: any) => (
    <Card hoverable className={classes.billListItemCard}>
      <Row className={classes.billListItemCardWrapper}>
        <Col span={15}>
          <CalendarOutlined />{" "}
          {order.order.invoice_date
            ? dayjs(order.order.invoice_date).format("DD MMMM YYYY")
            : order.order.created_at
            ? dayjs(order.order.created_at).format("DD MMMM YYYY")
            : ""}
        </Col>
        <Col span={9}>₹ {order.order.total}</Col>
        <Col span={15}>
          <ClockCircleOutlined />{" "}
          {order.order.invoice_date &&
          !order.order.invoice_date?.includes("00:00:00")
            ? dayjs(order.order.invoice_date).format("hh:mm A")
            : order.order.created_at
            ? dayjs(order.order.created_at).format("hh:mm A")
            : ""}
        </Col>
        <Col span={9}>Items: {order.order.order_items_count}</Col>
        <Col span={15}>
          <InfoCircleOutlined /> {order.order.invoice_number}
        </Col>
        <Col span={9}>Qty : {order.order.quantity}</Col>
      </Row>
      <div className={classes.downloadBillBtn}>
        <Button
          icon={<DownloadOutlined />}
          onClick={(e) => {
            openDownloadModal(order.order);
            e.stopPropagation();
          }}
        />
      </div>
    </Card>
  );

  return (
    <>
      {/* <BannerCard /> */}
      <Row
        justify="space-between"
        align="middle"
        className={classes.filterSection}
      >
        <div style={{ marginLeft: "auto", marginTop: 10 }}>
          <DatePicker
            inputReadOnly
            format="DD-MM-YYYY"
            // onChange={onDateFilterChange}
            placeholder="Filter by date"
            className={classes.dateBox}
            dateRender={(current) => {
              const style: any = {};
              if (
                pageData.availableDates.includes(current.format("DD-MM-YYYY"))
              ) {
                style.borderRadius = "2px";
                style.background = "#d9ff91";
              }
              return (
                <div className="ant-picker-cell-inner" style={style}>
                  {current.date()}
                </div>
              );
            }}
          />
        </div>
      </Row>
      {billData.userBills.length === 0 ? (
        <Empty
          className={classes.empty}
          description="No bills found for this date."
        />
      ) : (
        ""
      )}
      <div
        style={{
          overflowY: "scroll",
          height: bannerLength ? "calc(100vh - 220px)" : "calc(100vh - 110px)", // this needs to be dynamic
          paddingBottom: "10rem",
        }}
      >
        {billData.userBills.map((order, key) => (
          <Col
            key={key}
            style={{ padding: "5px 10px" }}
            onClick={() => onCardClick(key, order)}
          >
            <BillListItemCard order={order} />
          </Col>
        ))}
        <OrderDownloadModal
          visible={pageData.downloadModalVisible}
          order={pageData.downloadModalSelectedOrder}
          onClose={closeDownloadModal}
        />
      </div>
    </>
  );
}

export default BillListView;
