import { createUseStyles } from "react-jss";
import customeScrollbarStyle from "components/style/CustomScrollbar";

const useStyles = createUseStyles(({ colors }: Theme) => ({
  customerDetailsCard: {
    width: "100%",
    overflowY: "auto",
    padding: "0.3rem",
    backgroundColor: colors.light100,
    borderRadius: "5px",
    boxShadow:
      "-1px -1px 4px rgba(34, 94, 119, 0.25), 1px 1px 4px rgba(34, 94, 119, 0.25)",
  },
  moreDetailsContainer: {
    width: "100%",
  },
  hidden: {
    display: "none",
  },
  customerDetailsCardInner: {
    padding: "0.2rem",
    overflowY: "auto",

    // customizing scrollbar
    ...customeScrollbarStyle,
  },
  header: {
    minHeight: 50,
    padding: "0.5rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cardTitle: {
    fontSize: "16px",
    fontWeight: "700",
    opacity: "0.8",
    fontFamily: "Roboto",
    color: colors.primary200,
  },
  showLessMoreBtn: {
    fontWeight: 500,
    color: "#50aba4",
    cursor: "pointer",
    fontSize: 14,
    fontFamily: "Roboto",
  },
  divider: {
    width: "85%",
    color: colors.light300,
    background: colors.light300,
    margin: "1rem auto",
  },
  basicDetailsList: {},
  moreDetailsList: {},
  notesInputContainer: {
    padding: "1rem",
    position: "relative",
    marginBottom: "2rem",

    "& > p": {
      fontSize: 14,
      fontWeight: 600,
      color: "#505050",
    },
  },
  notesInputField: {
    fontSize: 12,
    fontStyle: "italic",
    resize: "none",
    background: "#EDEDED",
  },
  addToNotesButton: {
    position: "absolute",
    bottom: "0",
    right: "5px",
  },
  priorityStar: {
    color: ({ isPriority }) => (isPriority ? "#EED639" : ""),
    cursor: "pointer",
  },
  "@media (max-width: 1050px)": {
    header: { minHeight: 50 },
    cardTitle: { fontSize: "0.85rem" },
    showLessMoreBtn: { fontSize: "0.65rem" },
    userProfileImg: { minHeight: 45 },
  },
}));

export { useStyles };
