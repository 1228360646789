import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(({ colors }: Theme) => ({
  header: {
    height: 58,
    position: "sticky",
    top: "0",
    backgroundColor: "transparent",
    color: colors.primary,
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: "1.15rem",
    padding: 0,
    zIndex: 1,
  },
  centreText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    fontWeight: "bold",
    fontSize: "1.2rem",
  },
  prescriptionCard: {
    width: "100%",
    marginBottom: ".5rem",
    transition: "all .2s",
    boxShadow:
      "-1px -1px 2px rgba(42, 157, 143, 0.2), 1px 1px 2px rgba(42, 157, 143, 0.2)",
    "& .name": {
      maxWidth: "60vw",
      whiteSpace: "nowrap",
      overflow: " hidden",
      textOverflow: "ellipsis",
      fontSize: 12,
      lineHeight: "22px",
    },
    "& .ant-card-body": {
      padding: "8px 10px",
    },
  },
  continue: {
    width: "100%",
    fontSize: 12,
  },
  footer: {
    padding: "10px 30px 10px",
    position: "fixed",
    bottom: "0",
    width: "100%",
    boxShadow: "1px 8px 27px 2px rgba(0,0,0,0.3)",
  },
  myPres: { padding: "1rem" },
  content: {
    height: "calc(100vh - 17vh)",
    overflow: "scroll",
  },
  myPresText: {
    fontSize: 13,
  },
  arrow: {
    fontSize: "1.15rem",
    color: "#2A9D8F",
  },
  center: {
    textAlign: "center",
  },
  date: {
    fontSize: 10,
    lineHeight: "11px",
  },
}));

export { useStyles };
