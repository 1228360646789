import { ArrowLeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { Link, useHistory } from "react-router-dom";
import PointsCard from "assets/svgs/points-card.svg";
import { getCustomerLoyaltyDetails, getCustomerLoyaltyLogs } from "api/api";
import Loader from "components/loader/Loader";
import moment from "moment";

const addCommas = (value: any) => {
  return value.toLocaleString("en-IN", {
    maximumFractionDigits: 2,
  });
};

export default function CashbackSummaryPage() {
  const classes = useStyles();
  const history = useHistory();
  const [pageData, setPageData] = useState({
    loading: true,
    overall: {
      total_loyalty_points: 0,
      current_loyalty_points: 0,
      loyalty_points_redeemed: 0,
    },
    history: [],
    retailStoreId: 0,
  });

  const fetchInitialData = async () => {
    let res = await getCustomerLoyaltyDetails();
    let loyaltyLogs = await getCustomerLoyaltyLogs();
    let loyaltyHistory = loyaltyLogs?.addition_only?.filter(
      (i: any) =>
        i.status !== "PENDING" &&
        (!!i.order_id || i.transaction_type === "REFUND")
    );

    if (res?.message === "successful") {
      setPageData({
        ...pageData,
        loading: false,
        overall: res.value,
        history: loyaltyHistory,
        retailStoreId: loyaltyHistory.length
          ? loyaltyHistory[0].retail_store_id
          : 0,
      });
    }
  };

  useEffect(() => {
    if (pageData.loading) {
      fetchInitialData();
    }
  }, []);

  if (pageData.loading) {
    return <Loader />;
  }

  return (
    <div className={classes.cashbackSummaryPage}>
      <Row className={classes.header}>
        <Col className={classes.backButton} span={4}>
          <ArrowLeftOutlined onClick={() => history.goBack()} />
        </Col>
        <Col className={classes.pageHeader} span={20}>
          Cashback Summary
        </Col>
      </Row>

      <div className={classes.pageContent}>
        <Card className={classes.loyaltyCard}>
          <img
            src={PointsCard}
            alt="points-card"
            className={classes.pointsCardMockup}
          />

          <Typography.Title level={5}>Loyalty Card</Typography.Title>

          <div>
            <Typography.Text className={classes.availableCasbackText}>
              AVAILABLE CASHBACK
            </Typography.Text>
            <Typography.Title level={2} style={{ margin: 0 }}>
              ₹ {addCommas(pageData.overall.current_loyalty_points)}
            </Typography.Title>
          </div>

          <div>
            <div className={classes.loyaltyCardBottomStatWrapper}>
              <Typography.Text>Total Cashback Won</Typography.Text>
              <Typography.Text strong>
                ₹ {addCommas(pageData.overall.total_loyalty_points)}
              </Typography.Text>
            </div>

            <div className={classes.loyaltyCardBottomStatWrapper}>
              <Typography.Text>Cashback Redeemed</Typography.Text>
              <Typography.Text strong>
                ₹ {addCommas(pageData.overall.loyalty_points_redeemed)}
              </Typography.Text>
            </div>
          </div>
        </Card>

        {!!pageData.overall.total_loyalty_points && (
          <Link
            to={`/dashboard/stores/${pageData.retailStoreId}/shop?catalog_key=2`}
          >
            <Button block size="large" className={classes.redeemCashbackBtn}>
              Redeem Cashback
            </Button>
          </Link>
        )}

        <div className={classes.historyText}>History</div>

        <div className={classes.historyCardsWrapper}>
          {pageData.history.map((item: any, index) => (
            <Card
              key={index}
              className={classes.historyCard}
              onClick={() => {
                if (!!item.order_id) {
                  history.push(
                    `/dashboard/stores/${item.retail_store_id}/bill?order_id=${item.order_id}`
                  );
                }
              }}
            >
              <div className={classes.historyCardLeft}>
                <Typography.Text strong>
                  {!!item.order_id
                    ? `Order ID: #${item.order_id}`
                    : item.transaction_type}
                </Typography.Text>
                <Typography.Text disabled>
                  {moment(item.created_at).format("hh:mm, DD MMM YYYY")}
                </Typography.Text>
              </div>
              <div className={classes.historyCardRight}>
                <Typography.Text className={classes.historyCardPoints}>
                  + {item.loyalty_points.toFixed(2)}
                </Typography.Text>
                <Button type="text" icon={<RightOutlined />} />
              </div>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  cashbackSummaryPage: {
    height: "100vh",
    overflow: "hidden",
    background: "rgba(217, 217, 217, 0.2)",
  },
  pageContent: {
    padding: [15],
  },
  backButton: {
    textAlign: "center",
  },
  pageHeader: {
    fontSize: 13,
    fontWeight: 700,
  },
  header: {
    height: 58,
    position: "sticky",
    top: "0",
    background: "transparent",
    alignItems: "center",
  },
  loyaltyCard: {
    background: "#0EC78D",
    padding: [0, 20],
    position: "relative",

    "& .ant-card-body": {
      padding: [0, 15],

      minHeight: 180,
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
    },

    "& *": {
      color: "#fff !important",
    },
  },
  availableCasbackText: {
    fontSize: 12,
  },
  loyaltyCardBottomStatWrapper: {
    width: "60%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "& *": {
      fontSize: 10,
      textTransform: "uppercase",
      margin: [2, 0],
    },
  },
  pointsCardMockup: {
    width: 80,
    position: "absolute",
    top: 30,
    right: 40,
  },
  redeemCashbackBtn: {
    margin: [15, 0, 0],
    fontSize: 14,
    borderRadius: 5,
    color: colors.primary,
  },

  historyText: {
    marginTop: 10,
    padding: [20, 20, 15],
    fontSize: 14,
    fontWeight: 500,
  },

  historyCardsWrapper: {
    height: "calc(100vh - 315px)",
    paddingBottom: 50,
    overflow: "auto",
  },
  historyCard: {
    marginTop: 10,
    border: "0.3px solid #D9D9D9",
    boxShadow:
      "-1px -1px 2px rgba(42, 157, 143, 0.2), 1px 1px 2px rgba(42, 157, 143, 0.2)",
    borderRadius: 5,

    "& .ant-card-body": {
      padding: [15, 20],
      display: "flex",
      alignItems: "center",
    },
  },
  historyCardLeft: {
    height: 40,
    fontSize: 12,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  historyCardRight: {
    marginLeft: "auto",
  },
  historyCardPoints: {
    marginRight: 10,
    fontSize: 16,
    fontWeight: 500,
    color: "#2A9D8F",
  },
}));
