import {
  AreaChartOutlined,
  CloseOutlined,
  DashboardOutlined,
  FileOutlined,
  FilterOutlined,
  FundOutlined,
  HomeOutlined,
  MessageOutlined,
  MoreOutlined,
  NotificationOutlined,
  ShopOutlined,
  ShoppingOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Drawer } from "antd";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { Link, useLocation } from "react-router-dom";


type NavOption = {
  icon: React.ReactNode;
  label: string;
  to: string;
};

export default function BottomMenu() {
  const classes = useStyles();
  let { pathname } = useLocation();
  const [moreMenuVisible, setMoreMenuVisible] = useState(false);

  const handleMoreMenuToggle = () => setMoreMenuVisible(!moreMenuVisible);

  const baseUrl = "/retailer/dashboard/";
  const navOptions: NavOption[] = [
    {
      icon: <HomeOutlined />,
      label: "Home",
      to: baseUrl + "home",
    },
    {
      icon: <ShoppingOutlined />,
      label: "Orders",
      to: baseUrl + "incoming-orders",
    },
    {
      icon: <FileOutlined />,
      label: "Invoices",
      to: baseUrl + "orders",
    },
    {
      icon: <UserOutlined />,
      label: "Customers",
      to: baseUrl + "customer-details",
    },
  ];

  const navOptionsExtra: NavOption[] = [
    {
      icon: <ShopOutlined />,
      label: "Catalog",
      to: baseUrl + "inventory",
    },
    {
      icon: <FilterOutlined />,
      label: "Analytics",
      to: baseUrl + "business-summary",
    },
    {
      icon: <NotificationOutlined />,
      label: "Marketing",
      to: baseUrl + "marketing-campaigns",
    },
    {
      icon: <FundOutlined />,
      label: "Greetings",
      to: baseUrl + "promotions",
    },
  ];

  return (
    <div className={classes.bottomMenu}>
      {navOptions.map((option) => (
        <div className={classes.iconSet}>
          <Link to={option.to}>
            <Button
              size="large"
              shape="circle"
              type={pathname === option.to ? "default" : "text"}
              icon={
                <div className={classes.btnIcon}>
                  {option.icon}
                  {/* {option.label} */}
                </div>
              }
            />
            <div className={classes.iconLabelBottomMenu}>
              {option.label}
            </div>
          </Link>
        </div>
        
      ))}
      <div className={classes.iconSet}>
        <Button
          size="large"
          shape="circle"
          type="text"
          onClick={handleMoreMenuToggle}
          icon={
            <div className={classes.btnIcon}>
              {moreMenuVisible ? <CloseOutlined /> : <MoreOutlined />}
            </div>
          }
        />
        <div className={classes.iconLabelBottomMenu}>
          More
        </div>
      </div>


      <Drawer
        height={160}
        placement="bottom"
        closable={false}
        onClose={handleMoreMenuToggle}
        visible={moreMenuVisible}
        className={classes.moreMenu}
      >
      <div className={classes.moreMenuDrawer}>
        {navOptionsExtra.map((option) => (
          <div className={classes.iconSet}>
            <Link to={option.to}>
              <Button
                size="large"
                shape="circle"
                type={pathname === option.to ? "primary" : "text"}
                icon={<div>{option.icon}</div>}
                onClick={handleMoreMenuToggle}
              />
              <div className={classes.iconLabelBottomDrawer}>
                {option.label}
              </div>
            </Link>
          </div>
        ))}
          </div>

      </Drawer>
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  
  bottomMenu: {
    height: 65,
    width: "100%",
    background: colors.primary200,
    position: "fixed",
    bottom: 0,
    zIndex: 10001,

    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",

    "& .ant-btn-default .anticon": {
      color: colors.dark400,
    },
  },

  btnIcon: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: colors.light100,
    "& .anticon": {
      color: colors.light100,
    },
  },

  moreMenu: {
    display: "flex",

    "& .ant-drawer-body > *": {
      marginRight: 20,
    },

    "& $btnIcon": {
      color: colors.dark400,
      "& .anticon": {
        color: colors.dark400,
      },
    },
  },

  moreMenuDrawer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    zIndex: 10001
  },

  iconSet: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    textAlign: "center"
  },

  iconLabelBottomMenu: {
    fontSize: 10,
    color: 'white',
  },

  iconLabelBottomDrawer: {
    fontSize: 10,
    color: colors.primary200,
  }
}));
