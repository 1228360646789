import {
  getCart,
  deleteCart,
  getCustomer,
  getProducts,
  getCartItems,
  applyCoupon,
  insertReorder,
  deleteCartItem,
  getCustomerCoupons,
  insertReorderItems,
  getCartLoyaltyInfo,
  createRazorpayOrder,
  insertReorderLoyalty,
  verifyPaymentSuccess,
  updatePaymentFailedStatus,
  updateCartItemsByProductId,
  getShipping,
  getProductDetails,
} from "api/api";
import {
  ArrowLeftOutlined,
  CaretDownOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  CloseOutlined,
  DeleteOutlined,
  DownOutlined,
  InfoCircleOutlined,
  MobileOutlined,
  PercentageOutlined,
  RightOutlined,
} from "@ant-design/icons";
import Swipeout from "rc-swipeout";
import "rc-swipeout/assets/index.css";
import { createUseStyles } from "react-jss";
import Loader from "components/loader/Loader";
import { EmptyCart } from "components/EmptyCart";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { Button, Col, Row, Steps } from "antd/es";
import React, { useEffect, useState } from "react";
import { CashIcon, GoldenCrownIcon } from "components/customIcons";
import { PrescriptionIcon } from "components/customIcons";
import NumberSelect from "components/dataEntry/NumberSelect";
import { AddPrescriptionDrawer } from "components/AddPrescriptionDrawer";
import { useHistory, useRouteMatch, useLocation, Link } from "react-router-dom";
import Search from "antd/lib/input/Search";
import AutoComplete from "antd/lib/auto-complete";
import gtag from "components/Tracking/gtag";
import AddressModal from "components/modals/AddressModal";
import { getSession, Session } from "client/reactives/session";
import { Card, Radio, Space, Typography } from "antd";

const { Step } = Steps;
const DeleteBox = DeleteOutlined;

type RouteParams = {
  cartId: string;
  retailStoreId: string;
};

const getPriceWithoutGst = (gstPercentage: number, priceWithGst: number) => {
  let gstAmount = priceWithGst - priceWithGst * (100 / (100 + gstPercentage));
  return priceWithGst - gstAmount;
};

const checkAddressField = (val: string, endWithIfSucess = "") =>
  val?.trim() !== "" && !!val ? val + endWithIfSucess : "";

const getAddressString = (address: any) => {
  return (
    checkAddressField(address.line1, ", ") +
    checkAddressField(address.line2, ", ") +
    checkAddressField(address.landmark, ", ") +
    checkAddressField(address.city, ", ") +
    checkAddressField(address.state, ", ") +
    checkAddressField(address.pincode)
  );
};

const getReorderItemDataObject = (item: any, reorder_id: any) => ({
  reorder_id,
  product_id: item?.product_id,
  quantity: item?.quantity,
  discount: 0,
  sub_total: item?.sub_total,
  total: item?.total,
  sgst: item?.sgst,
  cgst: item?.cgst,
});

function CartPage() {
  const {
    params: { cartId, retailStoreId },
    path,
  } = useRouteMatch<RouteParams>() || {};
  const { goBack, push } = useHistory();
  const { userName } = getSession() as Session;

  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const [couponOptions, setCouponOptions] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState("cod");
  const [paymentMethodSelector, setPaymentMethodSelector] = useState(false);
  const [addressModalVisible, setAddressModalVisible] = useState(false);
  const [planValue, setPlanValue] = useState(0);
  const [pageData, setPageData] = useState<any>({
    cart: [],
    cartItems: [],
    delivery: {
      deliverTo: "",
      address: "",
    },
    products: [],
    shippingCharge: 0,
    redeemLoyaltyPoints: false,
    loyaltyInfo: {},
    visibility: false,
    prescriptionId: "",
    myPrescriptions: [],
    prescriptionState: {
      required: true,
      isUploaded: true,
    },
    loading: true,
  });

  useEffect(() => {
    if (!pageData.loading) {
      (async () => {
        const cart = await getCart(+cartId);
        const { shippingCharge } = await getShipping(+retailStoreId, +cartId);
        setPageData({ ...pageData, shippingCharge, cart: cart[0] });
      })();
    }
  }, [pageData.cartItems]);

  useEffect(() => {
    let data: any = {};
    async function get() {
      if (pageData.loading && cartId !== "undefined") {
        const cart = await getCart(+cartId);
        const customer = await getCustomer();
        const coupons = await getCustomerCoupons(
          +cart[0]?.retail_store_id,
          +cart[0]?.price_with_gst
        );
        const loyaltyInfo = await getCartLoyaltyInfo(
          +cartId,
          +retailStoreId
        ).catch((e) => console.log(e));
        if (cart?.length > 0 && cart !== undefined) {
          const { shippingCharge } = await getShipping(+retailStoreId, +cartId);
          const cartItems: any[] = await getCartItems(+cartId);

          for (let i = 0; i < cartItems.length; i++) {
            if (!!cartItems[i]?.is_plan) {
              setPlanValue((curr) => curr + +cartItems[i].total);
            }
          }

          const delivery = {
            deliverTo: userName ?? customer?.name,
            address:
              customer?.line1?.trim() !== "" && !!customer?.line1
                ? getAddressString(customer)
                : "",
          };

          data = {
            shippingCharge,
            couponsList: coupons,
            cartItems: cartItems,
            delivery: delivery,
            loyaltyInfo,
            cart: cart[0],
          };
        }
      }

      var myPres = location.state as any;

      var prescriptionDrawerVisibility = false;
      var prescriptionsList = [];
      if (myPres && myPres.presList.length > 0) {
        prescriptionDrawerVisibility = true;
        prescriptionsList = myPres.presList;
      }

      setCouponOptions(data.couponsList);
      setPageData({
        ...pageData,
        cart: data.cart,
        shippingCharge: data.shippingCharge,
        cartItems: data.cartItems,
        delivery: data.delivery,
        loyaltyInfo: data.loyaltyInfo,
        visibility: prescriptionDrawerVisibility,
        myPrescriptions: prescriptionsList,
        prescriptionState: {
          required: true,
          isUploaded: true, //prescriptionsList.length > 0 ? true : false,
        },
        loading: false,
      });
    }
    if (pageData.loading) {
      get();
    }
  }, [pageData.loading]);

  const handlePaymentMethodChange = (e: any) => {
    setPaymentMethod(e.target.value);

    if (e.target.value === "cod" && pageData.redeemLoyaltyPoints) {
      setPageData({ ...pageData, redeemLoyaltyPoints: false });
    }
  };

  const clearCart = async () => {
    await deleteCart(parseInt(cartId));
    setPageData({ ...pageData, cartItems: [] });
  };

  const deactivateCart = async () => {
    await deleteCart(parseInt(cartId));
  };

  const sendPurchaseEvent = async (data: any, items: any) => {
    console.log(data, items);
    if (!items.length) return null;

    gtag("event", "purchase", {
      order_id: +data?.id,
      coupon_discount: parseFloat(pageData?.cart?.coupon_discount).toFixed(2),
      coupon_code: pageData.cart?.coupon_code,
      loyalty_points_deduction: getLoyaltyPointsDeduction(),
      value: +data.grandtotal,
      currency: "INR",
      gst: +data.gst,
      items: items.map((item: any) => ({
        id: item.product_id,
        name: item.name,
        price: parseFloat(item?.price).toFixed(2),
        quantity: item.quantity,
      })),
    });
  };

  const reorderConfirmedWithPayment = async (
    reorderAmount: number,
    dataObj: any
  ) => {
    if (!pageData.loading) {
      let reorderData: any = {
        cart_id: pageData.cart.id,
        retail_store_id: pageData.cart.retail_store_id,
        payment_mode: paymentMethod,
        payment_status: `'${pageData.cart.payment_status}'`,
        discount: 0,
        gst: pageData.cart.gst,
        prescription_id: null,
        subtotal: pageData.cart.price_with_out_gst,
        grandtotal: reorderAmount.toFixed(2),
        user_id: pageData.cart.user_id,
        shipping_charge: +pageData?.shippingCharge ?? 0,
      };

      let loyaltyData = {
        cart_id: pageData.cart.id,
        reorder_amount: getTotalAmount(true),
        retail_store_id: +retailStoreId,
        use_loyalty_points: pageData.redeemLoyaltyPoints,
      };

      setPageData({ ...pageData, loading: true });

      await insertReorder(reorderData).then(async (result) => {
        if (!result?.length) return result;

        for (var i = 0; i < pageData.cartItems?.length; i++) {
          let itemData = getReorderItemDataObject(
            pageData.cartItems[i],
            result[0].id
          );
          await insertReorderItems(itemData);
        }

        if (pageData.redeemLoyaltyPoints) {
          await insertReorderLoyalty({
            ...loyaltyData,
            reorder_id: +result[0].id,
          });
        }

        await deactivateCart();
        await sendPurchaseEvent(result[0], pageData.cartItems);
        await completePayment(+result[0].id);
      });
    }
  };

  const completePayment = async (reorder_id: number) => {
    var reorderAmount = getTotalAmount();
    if (+reorderAmount === 0 || paymentMethod === "cod") {
      let redirectTo = `/dashboard/stores/${retailStoreId}/reorder-placed/${reorder_id}?source=cart`;
      if (pageData.cartItems.filter((i: any) => !!i?.is_plan).length > 0) {
        redirectTo += "&showCongratsModal=true";
      }
      return push(redirectTo);
    }
    console.log("Loading razorpay js.....");
    // ---------- Load Razorpay script --------------------------------
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
    // ----------------------------------------------------------------

    var userName = localStorage.getItem("userName");
    var mobileNumber = localStorage.getItem("mobileNumber");
    var userId = localStorage.getItem("userId");

    // creating a new razorpay order
    var reqBody = {
      amount: reorderAmount,
      cartId: pageData.cart.id,
      userId: userId,
    };
    var resp = await createRazorpayOrder(reqBody);

    if (!resp || resp.message !== "Created") {
      alert(resp ? resp.message : "Something went wrong");
      return;
    }

    // Getting the order details back
    const amount = resp.value.amount;
    const orderId = resp.value.id;
    const currency = resp.value.currency;

    // ------------------------------------------------------------------------
    var options = {
      key: resp.api_key,
      amount: amount.toString(), // 2000 paise = INR 20, amount in paisa
      currency: currency,
      name: "RASEET",
      description: "Ab aapka bill hi hai aapki duqaan",
      order_id: orderId,
      handler: async function (response: any) {
        const data = {
          orderCreationId: orderId,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };

        verifyPaymentSuccess(data)
          .then(async function (res) {
            await deactivateCart();
            if (res.msg === "success") {
              console.log("Payment successful");

              let redirectTo = `/dashboard/stores/${retailStoreId}/reorder-placed/${reorder_id}?source=cart`;

              if (
                pageData.cartItems.filter((i: any) => !!i?.is_plan).length > 0
              ) {
                redirectTo += "&showCongratsModal=true";
              }
              push(redirectTo);
            }
          })
          .catch(async (e) => {
            await deactivateCart();
            console.log(e);
            window.location.href =
              window.location.origin +
              `/dashboard/stores/${retailStoreId}/reorder-placed/${reorder_id}`;
          });
      },
      modal: {
        ondismiss: async function () {
          console.log("payment modal dismissed");
          await deactivateCart();
          window.location.href =
            window.location.origin +
            `/dashboard/stores/${retailStoreId}/reorder-placed/${reorder_id}`;
        },
      },
      prefill: {
        name: userName,
        contact: mobileNumber,
      },
      notes: {
        address:
          "User ID: " +
          userId +
          ", with cartId: " +
          pageData.cart.id +
          " and reorder ID: " +
          reorder_id,
      },
      theme: {
        color: "#2a9d8f",
      },
    };
    // ------------------------------------------------------------------------

    var wndw: any = window || {};
    let rzp = new wndw.Razorpay(options);

    rzp.on("payment.error", async function (response: any) {
      console.log("error: ", response.error);
      await deactivateCart();
      if (response.error.metadata) {
        const req = {
          razorpayOrderId: response.error.metadata.order_id,
          razorpayPaymentId: response.error.metadata.payment_id,
          status: "FAILED",
        };
        await updatePaymentFailedStatus(req);
        window.location.href =
          window.location.origin +
          `/dashboard/stores/${retailStoreId}/reorder-placed/${reorder_id}`;
      } else {
        console.log(response.error.description);
        // alert(response.error.description);
      }
    });

    rzp.open();
    return;
  };

  const getPercentageOff = (mp: number, sp: number) => {
    let absDiscount = mp - sp;
    return Math.round((absDiscount / mp) * 100);
  };

  const handleAddressModalSave = (newAddress: any) => {
    setPageData({
      ...pageData,
      delivery: { ...pageData.delivery, address: getAddressString(newAddress) },
    });
    toggleAddressModal();
  };

  const toggleAddressModal = () => setAddressModalVisible(!addressModalVisible);

  const handleCheckoutClick = () => {
    if (pageData.delivery.address === "") {
      setAddressModalVisible(true);
    } else {
      handlePlaceReorder();
      gtag("event", "checkout_clicks", {
        store: retailStoreId,
        customer: pageData?.cart?.user_id,
      });
    }
  };

  const handlePlaceReorder = async () => {
    var reorderAmount = getTotalAmount();
    reorderConfirmedWithPayment(+reorderAmount, "");
  };

  const removeCartItem = async (itemId: number) => {
    await deleteCartItem(itemId).then((result) => {
      let newItemsArray = pageData.cartItems.filter(
        (cartItem: any) => cartItem.id !== result[0].id
      );
      setPageData({ ...pageData, cartItems: newItemsArray });

      if (planValue > 0) {
        if (
          pageData.cartItems.findIndex((item: any) => +item.id === itemId) !==
          -1
        ) {
          setPageData({ ...pageData, loading: true });
        }
      }
    });
  };

  const handleQuantityChange = async (newQty: number, item: any) => {
    console.log(newQty, item);
    let productPrice = +item?.total / +item?.quantity;
    let sub_total = getPriceWithoutGst(
      +item.sgst + +item.cgst,
      +productPrice * newQty
    );
    let newData = {
      sub_total,
      quantity: newQty,
      total: +productPrice * newQty,
    };

    await updateCartItemsByProductId(+cartId, +item.product_id, newData).then(
      (result) => {
        if (!!result[0]?.product_id) {
          updateItemInState(result[0]?.product_id, newData);
        }
      }
    );
  };

  const updateItemInState = (productId: string, newData: any) => {
    let newItems: any = [...pageData.cartItems];
    for (let i = 0; i < newItems.length; i++) {
      if (newItems[i].product_id === productId) {
        newItems[i] = { ...newItems[i], ...newData };
      }
    }
    setPageData({ ...pageData, cartItems: newItems });
  };

  const swipeoutProps = (itemId: any) => ({
    left: [
      {
        text: (
          <div style={{ width: 250 }}>
            <DeleteOutlined style={{ fontSize: 20, marginLeft: 10 }} />
          </div>
        ),
        style: { backgroundColor: "#ff5f58", color: "white" },
      },
    ],
    onOpen: () => removeCartItem(parseInt(itemId)),
  });

  const CartItem = ({ cartItem }: any) => (
    <Swipeout {...swipeoutProps(cartItem?.id)}>
      <div className={classes.cartItem}>
        <div className={classes.cartItemLeft}>
          <div
            className={classes.itemName}
            onClick={() =>
              push(
                `/dashboard/stores/${retailStoreId}/product/${cartItem?.product_sku}?source=cart`
              )
            }
          >
            {cartItem?.name}
          </div>
          <div className={classes.cartItemLeftBottom}>
            <NumberSelect
              till={50}
              size="small"
              disabled={!!cartItem.is_plan}
              defaultValue={cartItem?.quantity}
              onChange={(value: any) => handleQuantityChange(value, cartItem)}
            />
            {+cartItem?.strike_price < +cartItem?.pack_price ? (
              <Typography.Text className={classes.cartItemTotal}>
                <del>₹ {parseFloat(cartItem?.pack_price).toFixed(2)}</del>
                {"  "}
                <Typography.Text strong>
                  ₹ {parseFloat(cartItem?.strike_price).toFixed(2)}
                </Typography.Text>

                <Typography.Text style={{ paddingLeft: 5, fontSize: 8 }}>
                  <i>
                    {getPercentageOff(
                      +cartItem.pack_price,
                      +cartItem.strike_price
                    )}
                    % OFF
                  </i>
                </Typography.Text>
              </Typography.Text>
            ) : (
              <Typography.Text strong className={classes.cartItemTotal}>
                ₹ {parseFloat(cartItem?.total).toFixed(2)}
              </Typography.Text>
            )}
          </div>
        </div>
        <div className={classes.cartItemRight}>
          <Button
            type="text"
            size="small"
            className={classes.removeItemButton}
            onClick={() => removeCartItem(parseInt(cartItem.id))}
          >
            REMOVE
          </Button>
        </div>
      </div>
    </Swipeout>
  );

  const customDot = (dot: any, { status, index }: any) => {
    if (status === "finish") {
      return <CheckCircleFilled style={{ fontSize: 12, color: "#2A9D8F" }} />;
    } else if (status === "process") {
      return (
        <div>
          <div className={classes.processDotWrapper}>{dot}</div>
        </div>
      );
    } else {
      return <div style={{ height: 12, width: 12 }}>{dot}</div>;
    }
  };

  if (cartId === undefined || cartId == "0") {
    return <EmptyCart onButtonClick={goBack} />;
  }

  const setPrescriptionId = (prescriptionId: string) => {
    var uploaded = false;
    if (+prescriptionId > 0) {
      uploaded = true;
    }
    setPageData({
      ...pageData,
      prescriptionId: prescriptionId,
      prescriptionState: {
        required: true,
        isUploaded: uploaded,
      },
    });
  };

  const closeDrawer = () => {
    setPageData({
      ...pageData,
      visibility: true,
    });
  };

  const openDrawer = () => {
    setPageData({
      ...pageData,
      visibility: true,
    });
  };

  const setVisibility = (visibility: boolean) => {
    setPageData({
      ...pageData,
      visibility: visibility,
    });
  };

  const handleRedeemCheckboxChange = () => {
    setPageData({
      ...pageData,
      redeemLoyaltyPoints: !pageData.redeemLoyaltyPoints,
    });
  };

  const getLoyaltyPointsDeduction = (getRedeemable = false) => {
    let totalAmount = +pageData?.cart?.price_with_gst - planValue;
    // let totalAmount =
    //   +pageData?.cart?.price_with_gst - +pageData?.cart?.discount;
    let loyaltyPointsDeduction = 0.0;

    if (+pageData.loyaltyInfo?.loyalty_points > 100) {
      let redeemableLP = +pageData.loyaltyInfo?.loyalty_points - 100;

      if (pageData.redeemLoyaltyPoints || getRedeemable) {
        if (pageData.loyaltyInfo && redeemableLP >= totalAmount) {
          loyaltyPointsDeduction = totalAmount;
        } else {
          loyaltyPointsDeduction = redeemableLP;
        }
      }
    }

    return (+loyaltyPointsDeduction).toFixed(2);
  };

  const getTotalAmount = (dontAddLoyalty = false) => {
    let totalAmount =
      // let totalAmount =
      +pageData?.cart?.price_with_gst - +planValue;
    // +pageData?.cart?.price_with_gst - +pageData?.cart?.discount;
    let loyaltyPointsDeduction = 0.0;
    if (pageData.redeemLoyaltyPoints && !dontAddLoyalty) {
      loyaltyPointsDeduction = +getLoyaltyPointsDeduction();
      totalAmount -= loyaltyPointsDeduction;
    }

    if (totalAmount < 0) {
      totalAmount = 0;
    }

    return (+totalAmount + +pageData?.shippingCharge + +planValue).toFixed(2);
  };

  const handleCouponCodeApply = async (couponCode: string) => {
    if (
      pageData.cart.coupon_code !== couponCode &&
      (pageData.cart.coupon_code !== null || couponCode !== "")
    ) {
      await applyCoupon(couponCode, +cartId).then((res: any) => {
        if (res.length && res[0].id) {
          setPageData({ ...pageData, cart: res[0] });

          couponCode !== "" &&
            gtag("event", "coupon_apply", {
              coupon: res[0].coupon_code,
              store: res[0].retail_store_id,
              user: res[0].user_id,
            });
        }
      });
    }
  };

  const CouponACLabel = ({ coupon }: any) => (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      {coupon.coupon_code}
      <span>
        {coupon.value}
        {coupon.type === "value" ? "₹" : "%"}
      </span>
    </div>
  );

  return (
    <div className={classes.cartPage}>
      {pageData.cartItems && pageData.cartItems.length && !pageData.loading ? (
        <>
          <Row className={classes.pageHeader}>
            <Col className={classes.textCenter} span={4}>
              <ArrowLeftOutlined onClick={goBack} />
            </Col>
            <Col className={classes.pageTitle} span={20}>
              Cart
            </Col>
          </Row>

          <div className={classes.pageContent}>
            <div className={classes.checkoutStepsWrapper}>
              <Steps
                current={1}
                size="small"
                labelPlacement="vertical"
                progressDot={customDot}
                className={classes.checkoutSteps}
              >
                <Step title="Order" />
                <Step title="Summary" />
                <Step title="Payment" />
              </Steps>
            </div>

            <div className={classes.deliveryAddressWrapper}>
              <div className={classes.deliverTo}>
                Deliver To: <b>{pageData.delivery.deliverTo}</b>
              </div>
              <div className={classes.deliveryAddressText}>
                {pageData.delivery.address}{" "}
                <Button type="link" size="small" onClick={toggleAddressModal}>
                  Edit Address
                </Button>
              </div>
            </div>

            <div className={classes.cartItemsWrapper}>
              <div className={classes.cartItemsWrapperHeader}>
                <div className={classes.cartItemsCount}>
                  {pageData.cartItems.length} ITEMS
                </div>
                <a id="clear-cart-btn" data-store={retailStoreId}>
                  <Button
                    danger
                    size="small"
                    className={classes.clearCartButton}
                    onClick={clearCart}
                  >
                    <DeleteBox /> Clear Cart
                  </Button>
                </a>
              </div>
              <div className={classes.cartItemsList}>
                {pageData.cartItems.map((cartItem: any, index: number) => (
                  <CartItem key={index} cartItem={cartItem} />
                ))}
              </div>
            </div>

            {pageData.loyaltyInfo &&
            pageData.loyaltyInfo.loyalty_points &&
            +pageData.loyaltyInfo?.loyalty_points > 100 ? (
              <div className={classes.redeemLoyaltyPointsWrapper}>
                <div className={classes.redeemLoyaltyPointsCheckbox}>
                  <Checkbox
                    disabled={paymentMethod !== "online"}
                    checked={pageData.redeemLoyaltyPoints}
                    onChange={handleRedeemCheckboxChange}
                  />
                </div>
                <div className={classes.redeemLoyaltyPointsContent}>
                  <div className={classes.redeemLoyaltyPointsText}>
                    Redeem Loyalty Points
                  </div>
                  <div className={classes.redeemLoyaltyPointsValue}>
                    You can redeem {parseFloat(getLoyaltyPointsDeduction(true))}{" "}
                    Loyalty Points on this order
                  </div>
                </div>
                <div className={classes.redeemLoyaltyPointsIcon}>
                  <GoldenCrownIcon />
                </div>
              </div>
            ) : (
              ""
            )}

            <div className={classes.applyCouponWrapper}>
              <PercentageOutlined style={{ fontSize: 16 }} />
              {pageData.cart.coupon_id !== null ? (
                <div className={classes.applyCouponInner}>
                  <div className={classes.applyCouponTitle}>
                    {pageData.cart.coupon_code}
                    <span className={classes.applyCouponSubTitle}>
                      Offer applied on the bill
                    </span>
                  </div>
                  <Button
                    type="text"
                    icon={<CloseCircleFilled />}
                    className={classes.applyCouponBtn}
                    onClick={() => handleCouponCodeApply("")}
                  />
                </div>
              ) : (
                <div
                  className={classes.applyCouponInner}
                  onClick={() =>
                    push(window.location.pathname + "/apply-coupon")
                  }
                >
                  <div className={classes.applyCouponTitle}>Apply Coupon</div>
                  <Button
                    type="text"
                    icon={<RightOutlined />}
                    className={classes.applyCouponBtn}
                  />
                </div>
              )}
            </div>

            <div className={classes.priceDetailsWrapper}>
              <div className={classes.priceDetailsTitle}>Price Details</div>
              <div className={classes.priceDetailsItem}>
                <span>Total MRP</span>
                <span>₹ {(+pageData?.cart?.mrp_price_sum).toFixed(2)}</span>
              </div>
              <hr />
              <div className={classes.priceDetailsItem}>
                <span style={{ flex: 1 }}>Discount On MRP</span>
                <span
                  style={{ fontSize: 10, color: "#505050", marginRight: 10 }}
                >
                  {getPercentageOff(
                    +pageData?.cart?.mrp_price_sum,
                    +pageData?.cart?.mrp_price_sum -
                      +pageData?.cart?.mrp_discount
                  )}
                  % OFF
                </span>
                <span>₹ {(+pageData?.cart?.mrp_discount).toFixed(2)}</span>
              </div>
              <hr />
              <div className={classes.priceDetailsItem}>
                <span style={{ flex: 1 }}>Loyalty Point Deduction</span>
                <span
                  hidden={
                    getPercentageOff(
                      +pageData?.cart?.mrp_price_sum -
                        +pageData?.cart?.mrp_discount,
                      +pageData?.cart?.mrp_price_sum -
                        +pageData?.cart?.mrp_discount -
                        +getLoyaltyPointsDeduction()
                    ) < 1
                  }
                  style={{ fontSize: 10, color: "#505050", marginRight: 10 }}
                >
                  {getPercentageOff(
                    +pageData?.cart?.mrp_price_sum -
                      +pageData?.cart?.mrp_discount,
                    +pageData?.cart?.mrp_price_sum -
                      +pageData?.cart?.mrp_discount -
                      +getLoyaltyPointsDeduction()
                  )}
                  % OFF
                </span>
                <span>₹ {getLoyaltyPointsDeduction()}</span>
              </div>
              <hr />
              <div className={classes.priceDetailsItem}>
                <span style={{ flex: 1 }}>Coupon Discount</span>
                <span
                  hidden={
                    getPercentageOff(
                      +pageData?.cart?.mrp_price_sum -
                        +pageData?.cart?.mrp_discount,
                      +pageData?.cart?.mrp_price_sum -
                        +pageData?.cart?.mrp_discount -
                        pageData?.cart?.coupon_discount
                    ) < 1
                  }
                  style={{ fontSize: 10, color: "#505050", marginRight: 10 }}
                >
                  {getPercentageOff(
                    +pageData?.cart?.mrp_price_sum -
                      +pageData?.cart?.mrp_discount,
                    +pageData?.cart?.mrp_price_sum -
                      +pageData?.cart?.mrp_discount -
                      pageData?.cart?.coupon_discount
                  )}
                  % OFF
                </span>
                <span>
                  ₹ {parseFloat(pageData?.cart?.coupon_discount).toFixed(2)}
                </span>
              </div>
              <hr />
              <div className={classes.priceDetailsItem}>
                <span>Shipping Charge</span>
                <span>₹ {parseFloat(pageData?.shippingCharge).toFixed(2)}</span>
              </div>
              <hr />
              <div className={classes.totalAmount}>
                <span style={{ flex: 1 }}>Total Amount</span>

                {/* <span
                  hidden={
                    getPercentageOff(
                      +pageData?.cart?.mrp_price_sum,
                      +getTotalAmount()
                    ) < 1
                  }
                  style={{ fontSize: 10, color: "#505050", marginRight: 10 }}
                >
                  {getPercentageOff(
                    +pageData?.cart?.mrp_price_sum,
                    +getTotalAmount()
                  )}
                  % OFF
                </span> */}

                <span>₹ {getTotalAmount()}</span>
              </div>
            </div>

            {pageData.loyaltyInfo && pageData.loyaltyInfo.loyalty_points ? (
              <div className={classes.loyaltyPointsSummaryWrapper}>
                <GoldenCrownIcon />
                <div className={classes.loyaltyPointsSummary}>
                  You will earn{" "}
                  <span>
                    {pageData.loyaltyInfo?.loyalty_points_to_be_earned}
                  </span>{" "}
                  Loyalty points on this purchase
                </div>
              </div>
            ) : (
              ""
            )}

            {/* <AddPrescriptionDrawer
            onClose={closeDrawer}
            visibility={pageData.visibility}
            setPrescriptionId={setPrescriptionId}
            setVisibility={setVisibility}
            retailStoreId={parseInt(retailStoreId)}
            myPres={pageData.myPrescriptions}
          /> */}
            {!pageData.prescriptionState.isUploaded ? (
              <>
                <div className={classes.presMsg}>
                  *medicines require prescription
                </div>
                <Button
                  type="primary"
                  shape="circle"
                  size="large"
                  className={classes.addPres}
                  onClick={openDrawer}
                  icon={<PrescriptionIcon />}
                />
              </>
            ) : (
              <>
                {/* <div className={classes.presMsg}>*prescription added</div>
              <Button
                type="primary"
                shape="circle"
                size="large"
                className={classes.addPres}
                style={{ background: "#2A9D8F" }}
                onClick={openDrawer}
                icon={<CheckOutlined />}
              /> */}
                <AddressModal
                  visible={addressModalVisible}
                  onSave={handleAddressModalSave}
                  onClose={() => setAddressModalVisible(false)}
                />

                <div
                  style={{
                    transform: paymentMethodSelector
                      ? "translateY(0%)"
                      : "translateY(150%)",
                  }}
                  className={classes.selectPaymentMethodWrapper}
                >
                  <Typography.Text className={classes.selectPaymentMethodText}>
                    <span>Select Payment Method</span>

                    <Button
                      type="text"
                      size="small"
                      icon={<CloseOutlined />}
                      onClick={() => setPaymentMethodSelector(false)}
                    />
                  </Typography.Text>

                  <Radio.Group
                    value={paymentMethod}
                    onChange={handlePaymentMethodChange}
                  >
                    <Space direction="vertical" size="small">
                      <Card onClick={() => setPaymentMethod("online")}>
                        <Space>
                          <Radio value="online" />
                          <MobileOutlined />
                          <Typography.Text>Pay Online</Typography.Text>
                        </Space>
                      </Card>


                    </Space>
                  </Radio.Group>
                </div>

                <Space
                  size="large"
                  // @ts-ignore
                  hidden={paymentMethod === ""}
                  className={classes.checkoutButtonWrapper}
                >


                  <div>
                    <Typography.Text
                      type="secondary"
                      style={{
                        fontSize: 10,
                        display: "block",
                        whiteSpace: "nowrap",
                      }}
                    >
                      TOTAL
                    </Typography.Text>
                    <Typography.Text
                      style={{
                        color: "#505050",
                        fontSize: 12,
                        fontWeight: 600,
                      }}
                    >
                      ₹{getTotalAmount()}
                    </Typography.Text>
                  </div>

                  {paymentMethod === "cod" ? (
                    <Button block type="primary" onClick={handleCheckoutClick}>
                      Place Order
                    </Button>
                  ) : (
                    <Button block type="primary" onClick={handleCheckoutClick}>
                      Checkout
                    </Button>
                  )}
                </Space>
              </>
            )}
          </div>
        </>
      ) : pageData.cartItems.length === 0 && !pageData.loading ? (
        <EmptyCart onButtonClick={goBack} />
      ) : (
        <Loader />
      )}
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  cartPage: {
    width: "100%",
    height: "100vh",
    paddingBottom: "3rem",
    background: "#EAF5F4",
  },
  pageContent: {
    paddingBottom: 20,
    height: "calc(100vh - 103px)",
    overflowY: "auto",
    overflowX: "hidden",
  },
  textCenter: {
    textAlign: "center",
  },
  pageTitle: {
    fontSize: 13,
    fontWeight: 700,
  },
  pageHeader: {
    height: 58,
    position: "sticky",
    top: "0",
    zIndex: 4,
    background: "#EAF5F4",
    alignItems: "center",
  },
  presMsg: {
    padding: "0 10px",
    fontStyle: "italic",
    fontWeight: "normal",
    fontSize: "8px",
    lineHeight: "22px",
    color: "#2D2C2C",
    background: "#FFFFFF",
    boxShadow: "-5px 5px 5px rgba(0, 0, 0, 0.07)",
    borderRadius: "3px",
    position: "fixed",
    right: 68,
    bottom: 85,
  },
  addPres: {
    height: "46px !important",
    padding: "12px !important",
    width: "46px !important",
    border: "none",
    color: "#fff !important",
    background: "#F5A900",
    boxShadow: "1px 1px 5px 2px rgba(0, 0, 0, 0.25) !important",
    position: "fixed",
    right: 15,
    bottom: 70,
    "& .anticon": {
      fontSize: 24,
    },
  },
  checkoutStepsWrapper: {
    padding: [10, 0, 20],
  },
  checkoutSteps: {
    width: "100%",

    "& .ant-steps-item": {
      flex: 1,
    },
    "& .ant-steps-item-tail": {
      width: "107% !important",
      margin: "0 0 0 15vw !important",
      top: "3px !important",

      "&:after": {
        height: "2px !important",
      },
    },
    "& .ant-steps-icon > *": {
      width: "100% !important",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .ant-steps-item-icon, & .ant-steps-item-content": {
      marginLeft: "0 !important",
      width: "100% !important",
    },

    "& .ant-steps-icon-dot": {
      height: "6px !important",
      width: "6px !important",
      background: "#C4C4C4 !important",
      borderRadius: "50% !important",
    },

    "& .ant-steps-item-title": {
      fontStyle: "normal",
      fontSize: "12px",
      lineHeight: "22px",
      textAlign: "center",
      color: "#2A9D8F !important",
    },
    "& .ant-steps-item-process": {
      "& .ant-steps-item-title": {
        fontWeight: 500,
      },
    },
  },
  processDotWrapper: {
    height: "12px",
    borderRadius: "50%",
    width: "12px",
    border: "0.5px solid #2A9D8F",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  deliveryAddressWrapper: {
    width: "100%",
    padding: [8, 25],
    background: "#FFFFFF",
    border: "0.3px solid #D9D9D9",
  },
  deliverTo: {
    paddingBottom: 3,
    fontSize: "12px",
    lineHeight: "22px",
    color: "#505050",

    "& b": {
      fontWeight: "500 !important",
    },
  },
  deliveryAddressText: {
    fontSize: "10px",
    lineHeight: "18px",
    color: "#505050",
    "& .ant-btn": {
      fontSize: 12,
    },
  },

  redeemLoyaltyPointsWrapper: {
    width: "100%",
    margin: [10, 0, 15],
    padding: [10, 25],
    background: "#FFFFFF",
    border: "0.3px solid #D9D9D9",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  redeemLoyaltyPointsCheckbox: {
    width: 40,

    "& .ant-checkbox-wrapper": {
      transform: "scale(0.9)",
    },
  },
  redeemLoyaltyPointsContent: {
    flex: 1,
  },
  redeemLoyaltyPointsText: {
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "14px",
    color: "#505050",
  },
  redeemLoyaltyPointsValue: {
    paddingTop: 6,
    fontSize: 12,
    color: "#2A9D8F",
  },
  redeemLoyaltyPointsIcon: {
    fontSize: 20,
    width: 40,
    textAlign: "center",
  },

  applyCouponWrapper: {
    width: "100%",
    margin: [10, 0, 15],
    padding: [12, 25],
    background: "#FFFFFF",
    border: "0.3px solid #D9D9D9",
    display: "flex",
    alignItems: "center",
  },
  applyCouponInner: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  applyCouponTitle: {
    marginLeft: 15,
  },
  applyCouponSubTitle: {
    fontSize: 10,
    display: "block",
  },
  applyCouponBtn: {
    marginLeft: "auto",
  },

  cartItemsWrapper: {
    padding: [15, 0, 10],
  },
  cartItemsWrapperHeader: {
    height: 40,
    padding: [0, 26],
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cartItemsCount: {
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "22px",
    fontVariant: "small-caps",
    color: "#505050",
  },
  clearCartButton: {
    width: 65,
    height: 22,
    color: "#D62D20",
    border: "1px solid #D62D20",
    borderRadius: 3,
    fontSize: 9,
    fontWeight: 500,
    background: "transparent",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& .anticon": {
      fontSize: 10,
    },

    "& span:last-child": {
      marginLeft: 4,
    },
  },
  cartItemsList: {
    "& > div": {
      marginBottom: 10,
    },
  },
  cartItem: {
    width: "100%",
    height: 65,
    padding: [7, 23],
    background: "#fff",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "0.3px solid #D9D9D9",
    boxSizing: "border-box",
  },
  cartItemLeft: {},
  cartItemRight: {},
  cartItemLeftBottom: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  itemName: {
    paddingBottom: 12,
    fontWeight: "500",
    fontSize: "10px",
    lineHeight: "12px",
    color: "#505050",
    cursor: "pointer",
  },
  cartItemTotal: {
    marginLeft: 18,
    // fontWeight: 700,
    fontSize: "10px",
    lineHeight: "12px",
    color: "#505050",
  },
  removeItemButton: {
    fontWeight: "500",
    fontSize: "10px",
    lineHeight: "12px",
    color: "#505050",
  },

  priceDetailsWrapper: {
    width: "100%",
    marginBottom: 18,
    padding: [13, 23],
    background: "#fff",
    border: "0.3px solid #D9D9D9",
    boxSizing: "border-box",
  },
  priceDetailsTitle: {
    marginBottom: 18,
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "14px",
    color: "#505050",
  },
  priceDetailsItem: {
    width: "100%",
    marginBottom: 10,
    fontSize: "12px",
    lineHeight: "12px",
    color: "#505050",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  totalAmount: {
    width: "100%",
    marginTop: 20,
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "14px",
    color: "#505050",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  loyaltyPointsSummaryWrapper: {
    minHeight: 40,
    marginBottom: 18,
    background: "#FFFFFF",
    border: "0.3px solid #D9D9D9",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& .anticon": {
      fontSize: 22,
    },
  },
  loyaltyPointsSummary: {
    marginLeft: 15,
    fontWeight: "500",
    fontSize: "10px",
    lineHeight: "12px",
    color: "#505050",

    "& span": {
      color: "#9c9c9c",
    },
  },

  checkoutButtonWrapper: {
    height: 50,
    width: "100%",
    padding: [7, 23],
    position: "fixed",
    bottom: 0,
    background: "#FFFFFF",
    boxShadow: "0px -4px 10px rgba(0, 0, 0, 0.07)",

    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "& .ant-btn": {
      fontWeight: "500",
      fontSize: "10px",
    },
    "& .ant-space-item:last-child": {
      flex: 1,
    },
  },

  selectPaymentMethodWrapper: {
    width: "100%",
    padding: [15, 20, 25],

    position: "fixed",
    bottom: 50,
    left: 0,
    transition: "0.2s all ease-in-out",
    background: colors.light100,
    boxShadow: "0px -4px 10px rgba(0, 0, 0, 0.07)",
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,

    "& .ant-space, & .ant-radio-group": {
      width: "100%",
    },
    "& .ant-card-body": {
      padding: [14, 25],
    },
  },
  selectPaymentMethodText: {
    padding: [0, 5, 8],
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "22px",
    color: "#505050",

    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export default CartPage;
