import { Layout } from "antd";
import gtag from "components/Tracking/gtag";
import Loader from "components/loader/Loader";
import React, { useState, useEffect } from "react";
import { setSession } from "client/reactives/session";
import { getOrderByUid, userLoginByUID } from "api/api";
import NoAuthOrderView from "noAuth/views/NoAuthOrderView";
import { getSession, Session } from "client/reactives/session";
import { useHistory, Redirect, useRouteMatch, Route } from "react-router-dom";

function NoAuthPage() {
  // -------------NEW STATES IN USE-----------------------------
  const [pageData, setPageData] = useState({
    loading: true,
    orderData: {},
    retailStore: {},
  });
  let history = useHistory();
  // -----------------------------------------------------------
  const {
    params: { uid },
    url,
  } = useRouteMatch<any>() || {};

  function getRetailStoreData(data: any) {
    return {
      id: data.retail_stores_id,
      name: data.retail_stores_name,
      description: data.description,
      user_id: data.user_id,
      logo_data: data.logo_data,
      authenticity_document_type: data.authenticity_document_type,
      authenticity_document_value: data.authenticity_document_value,
      primary_mobile_number: data.primary_mobile_number,
      alternate_number: data.alternate_number,
      whatsapp_number: data.whatsapp_number,
      store_location: data.store_location,
      city: data.city,
      created_at: data.created_at,
      line1: data.line1,
      line2: data.line2,
      landmark: data.landmark,
      pincode: data.pincode,
      state: data.state,
      updated_at: data.updated_at,
    };
  }

  function getOrderItem(data: any) {
    return {
      product_id: data.product_id,
      total: parseFloat(data.order_items_total).toFixed(2),
      sub_total: data.order_items_sub_total,
      quantity: parseFloat(data.quantity).toFixed(2),
      bill_quantity: data.bill_quantity,
      cgst: data.order_items_cgst,
      sgst: data.order_items_sgst,
      discount: data.order_items_discount,
      product: {
        name: data.name,
        price: data.price,
        pack_price: data.pack_price,
      },
    };
  }

  async function fetchBillData() {
    // call login API
    const { token, userId } = (await getSession()) as Session;
    var userLoginData: any;
    userLoginData = await userLoginByUID(uid);
    // set userData to session
    if (userLoginData) {
      setSession(
        userLoginData.token,
        userLoginData.user_id,
        userLoginData.user_type,
        userLoginData.user_name,
        userLoginData.mobile_number
      );
    }

    // add GA Event only for no auth bill pages to track number of views on the bill pages
    if (window.location.hostname === "app.raseet.com") {
      gtag("event", "bill_link_opens", {
        dimension_name: "Customer Dashboard",
        utm_source: window.location.href,
      });
    }

    if (userLoginData || userId) {
      history.push(
        "/dashboard/stores/" +
          userLoginData.retail_store_id +
          "/bill?order_id=" +
          userLoginData.order_id
      );

      // No furthur usage of this component, hence return from this position only
      return;
    }

    var order: any;
    order = await getOrderByUid(uid);
    var orderItems = [];
    var retailStore = {};
    var orderData = {};
    if (order.length > 0) {
      retailStore = getRetailStoreData(order[0]);
      for (var i = 0; i < order.length; i++) {
        orderItems.push(getOrderItem(order[i]));
      }
      orderData = {
        id: order[0].id,
        retailStoreId: order[0].retail_store_id,
        total: order[0].total,
        createdAt: order[0].created_at,
        invoice_date: order[0].invoice_date,
        invoice_number: order[0].invoice_number,
        discount: parseFloat(order[0].discount).toFixed(2),
        gst: order[0].gst,
        round_off: order[0].round_off ? order[0].round_off.toFixed(2) : 0.0,
        subTotal: order[0].sub_total,
        orderItems: orderItems,
      };
    }

    setPageData({
      loading: false,
      orderData: orderData,
      retailStore: retailStore,
    });
  }

  useEffect(() => {
    if (pageData.loading) {
      fetchBillData();
    }
  }, []);

  return (
    <>
      {!pageData.loading ? (
        <Layout style={{ height: "100vh", backgroundColor: "#FFF" }}>
          <Layout.Content>
            <div style={{ height: "100%", overflowY: "scroll" }}>
              <Route
                exact
                path={url}
                render={() => <Redirect to={`${url}/bill`} />}
              />
              <Route
                path={`${url}/bill`}
                render={() => (
                  <NoAuthOrderView
                    retailStore={pageData.retailStore}
                    order={pageData.orderData}
                    visible={false}
                  />
                )}
              />
            </div>
          </Layout.Content>
        </Layout>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default NoAuthPage;
