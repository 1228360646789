import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(({ colors }: Theme) => ({
  header: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    // Header svg needs
    zIndex: 2,

    "& .ant-typography": {
      color: `${colors.light100} !important`,
      marginBottom: "0 !important",
      lineHeight: "1.4 !important",
      letterSpacing: "0.5px",
    },
  },
  paragraph: {
    fontSize: "10px !Important",
  },
  summaryRow: {
    backgroundColor: colors.light200,

    "&:last-child": {
      "& *": {
        fontWeight: "900 !important",
        color: "#101010",
      },
    },
    "& td.ant-table-cell": {
      "&:first-child": {
        paddingLeft: "2rem !important",
      },
      "&:last-child": {
        paddingRight: "5% !important",
      },
    },
  },
  summaryText: {
    textAlign: "right",
  },
  filterSection: {
    width: "100%",
    padding: "3px 8px",
  },
  dateBox: {
    color: "#505050",
    width: 95,
    border: "1px solid #505050",
    boxShadow:
      "inset 1px 1px 2px rgb(0 0 0 / 25%), inset -1px -1px 2px rgb(0 0 0 / 25%)",
    padding: "5px",
    borderRadius: "2px",
    backgroundColor: "transparent",

    "& .ant-picker-input": {
      flexDirection: "row-reverse",

      "& input": {
        marginTop: 2,
        textAlign: "center",
        color: "inherit",
        background: "inherit",
        fontSize: 10,
      },

      "& .ant-picker-suffix": {
        fontSize: 13,
        color: "inherit",
        background: "inherit",
      },
    },
  },
  time: {
    marginRight: "5px",
    fontSize: 10,
    color: "505050",
    lineHeight: "15px",
  },
  orderNumber: {
    height: "40px",
    // padding: "0 1rem 5px 1rem",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",

    "& .anticon": {
      margin: "2.5px 5px",
      color: "#FCB415",
    },

    "& > span": {
      color: "#838383",
      fontSize: 11,
    },
  },
  billTable: {
    fontFamily: "Roboto",

    // customizing border
    "& .ant-table-container": {
      border: "none",
      "& *": {
        borderColor: "rgba(0, 0, 0, 0.25) !important",
      },
    },

    "& .ant-checkbox-wrapper": {
      height: "100%",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center !important",

      "& .ant-checkbox": {
        top: 0,
      },
    },
    "& .ant-checkbox-inner": {
      width: 10,
      height: 10,
    },

    "& tbody .ant-checkbox-checked .ant-checkbox-inner": {
      "&:after": {
        border: "1.5px solid #fff !important",
        top: "45% !important",
        width: "3.4px !important",
        height: "6.5px !important",
        borderTop: "0 !important",
        borderLeft: "0 !important",
      },
    },

    "& thead": {
      "& .ant-table-selection": {
        height: "10px !important",
      },
      "& .ant-checkbox-indeterminate .ant-checkbox-inner": {
        borderColor: "#505050 !important",
        "&:after": {
          top: "49% !important",
          left: "51% !important",
          width: "6px !important",
          height: "6px !important",
          borderRadius: 1,
          backgroundColor: "#505050 !important",
        },
      },
      "& .ant-checkbox-checked .ant-checkbox-inner": {
        backgroundColor: "#505050 !important",
        "&:after": {
          border: "1.5px solid #fff !important",
          top: "45% !important",
          left: "1px !important",
          width: "3.4px !important",
          height: "6.5px !important",
          borderTop: "0 !important",
          borderLeft: "0 !important",
        },
      },
    },

    "& thead th": {
      background: "#EAF5F4 !important",
      textTransform: "uppercase",
      padding: "8px 10px !important",
      fontSize: 9,
      fontWeight: 700,
      borderBottom: "none !important",
    },

    "& .ant-table-body": {
      paddingBottom: ({ isReorder }) => (isReorder ? "7rem !important" : ""),
    },

    "& .ant-table-row td.ant-table-cell": {
      padding: "7.5px 10px !important",
      color: ({ isReorder }) => (isReorder ? "rgba(80, 80, 80, 0.3)" : ""),
      fontSize: 10,
      "&:last-child": {
        paddingRight: "5% !important",
        paddingLeft: "0 !important",
      },
      "&:nth-child(2)": {
        color: "inherit !important",
      },
      "&:nth-child(3)": {
        padding: "3.5px 6px !important",
        "& .ant-form-item-control-input": {
          minHeight: "21px !important",
        },
      },
    },

    "& .ant-table-row td.ant-table-cell:first-child": {
      padding: "0 !important",
    },

    "& .ant-table-footer": {
      border: "none !important",
    },

    "& .ant-table-row-selected > td": {
      background: "#fff !important",
      color: "inherit !important",
    },

    "& th.ant-table-cell": {
      height: 20,
      padding: "4px 5px !important",
      fontSize: 8,
      "&:last-child": {
        paddingRight: "4% !important",
      },
    },

    "& .ant-table-summary *": {
      borderRight: "none !important",
      background: "#EAF5F4",
      fontWeight: 500,
      fontSize: 11,
    },
    "& .ant-table-summary td": {
      padding: "8px 0 !important",
    },
  },
  skeleton: {
    padding: 20,
  },
  reorderButton: {
    width: 70,
    height: 22,
    fontSize: 10,
    borderRadius: 10,

    "& > *": {
      margin: "0 !important",
    },
    "& .anticon": {
      fontSize: 12,
      marginRight: "5px !important",
    },
  },
  billFooterFeatures: {
    margin: [20, 10],
    paddingBottom: "1rem",

    "& .ant-divider": {
      margin: [5, 0, 12],
    },
  },
  downloadDocuments: {
    width: "100%",
    margin: [15, 0],
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",

    "& > div": {
      fontWeight: "500",
      fontSize: 11,
      lineHeight: "15px",
      color: "#505050",
    },
    "& > div:nth-child(2)": {
      flex: 1,
    },
  },
  downloadDocumentsTitleIcon: {
    marginRight: 14,
    background: "#D9D9D9",
    border: "none",
    color: "#000",
    height: 24,
    width: 24,
    flex: "initial",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    "& .anticon": {
      fontSize: 14,
    },
  },
  downloadDocumentBtn: {
    marginLeft: 10,

    "& .anticon": {
      fontSize: 15,
    },
  },
  rateYourExperience: {
    width: "100%",
  },
  rateYourExperienceTitle: {
    width: "100%",
    margin: [15, 0],
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",

    "& .ant-btn": {
      background: "#D9D9D9",
      marginRight: 14,
      border: "none",
      color: "#000",
    },

    "& > div": {
      fontWeight: "500",
      fontSize: 11,
      lineHeight: "15px",
      color: "#505050",
    },
  },
  emojisList: {
    padding: [0, 0, 10],
    fontSize: "25px",
    whiteSpace: "nowrap",
    overflowX: "scroll",
    letterSpacing: "2px",
  },
  "@media (max-width: 320px)": {
    emojisList: {
      fontSize: "20px",
    },
  },
  "@media (min-width: 420px)": {
    emojisList: {
      fontSize: "28px",
    },
  },
  yourFeedback: {
    width: "100%",
    paddingBottom: 7,
  },
  yourFeedbackTitle: {
    width: "100%",
    maegin: [15, 0],
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",

    "& > .ant-btn": {
      background: "#D9D9D9",
      marginRight: 14,
      border: "none",
      color: "#000",
    },

    "& > div": {
      fontWeight: "500",
      fontSize: 11,
      lineHeight: "15px",
      color: "#505050",
    },
  },
  feedbackOptions: {
    marginLeft: "auto",
    "& .ant-btn": {
      marginLeft: 6,
      "& .anticon": {
        fontSize: 13,
      },
    },
  },
  textReviewInputField: {
    margin: [12, 0],
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "10px",
    lineHeight: "15px",
    color: "#727272",
  },
  submitReviewButton: {
    height: 26,
    fontWeight: "500",
    fontSize: "10px",
    lineHeight: "15px",
    color: "#9C9C9C !important",
    background: "#fff !important",
  },
  purchasePointsDetails: {
    width: "100%",
    paddingBottom: 10,
  },
  purchasePointsDetailsTitle: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",

    "& > .ant-btn": {
      background: "#D9D9D9",
      marginRight: 14,
      border: "none",
      color: "#000",
    },

    "& > div": {
      fontWeight: "500",
      fontSize: 11,
      lineHeight: "15px",
      color: "#505050",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",

      "& b": {
        marginLeft: 5,
        fontWeight: "bold",
        fontSize: "16px",
        lineHeight: "22px",
        textAlign: "center",
        color: "#2A9D8F",
      },

      "&:last-child": {
        marginLeft: "auto",
        marginRight: 14,
      },
    },
  },
  adBanner: {
    marginTop: 5,
    width: "100%",
    objectFit: "contain",
  },
  checkOffers: {},
  checkOffersText: {
    height: "13px",
    padding: "0 6px",
    position: "fixed",
    bottom: 80,
    right: 65,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontStyle: "italic",
    fontWeight: "normal",
    fontSize: "8px",
    lineHeight: "22px",
    textAlign: "center",
    color: "#505050",
    background: "#FFFFFF",
    boxShadow:
      "1px 1px 5px rgba(214, 206, 206, 0.5), -1px -1px 5px rgba(214, 206, 206, 0.5)",
    borderRadius: "3px",
  },
  checkOffersButton: {
    height: 40,
    width: 40,
    bottom: 70,
    right: 20,
    position: "fixed",
    border: "none",
    background: "#FFFFFF",
    boxShadow:
      "-1px -1px 5px rgb(214 206 206 / 50%), 1px 1px 5px rgb(214 206 206 / 50%) !important",

    "& .anticon": { paddingTop: 5, color: "#2A9D8F" },
  },
}));

export { useStyles };
