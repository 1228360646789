import FormStepper from "components/FormStepper";
import React, { useEffect, useState } from "react";
import { setSession } from "client/reactives/session";
import CustomerRegisterWrapper from "components/CustomerRegisterWrapper";
import GetStartedView from "components/views/registrationViews/GetStartedView";
import CustomerRegisterUserView from "components/views/CustomerRegisterUserView";
import RegisterUserView from "components/views/registrationViews/RegisterUserView";
import CreateCustomerView from "components/views/registrationViews/CreateCustomerView";
import CreateRetailStoreView from "components/views/registrationViews/CreateRetailStoreView";

enum RegistrationStep {
  PERSONAL,
  BUSINESS,
  DONE,
}

enum CustomerRegistrationStep {
  PERSONAL,
  ADDRESS,
}

type RegisterPageProps = {
  isRetailer: boolean;
};

const RegistrationStepTitle = {
  [RegistrationStep.PERSONAL]: "Registration",
  [RegistrationStep.BUSINESS]: "Business details",
  [RegistrationStep.DONE]: "Get started",
};

const CustomerRegistrationStepTitle = {
  [CustomerRegistrationStep.PERSONAL]: "Registration",
  [CustomerRegistrationStep.ADDRESS]: "Address",
};

function RegisterPage({ isRetailer }: RegisterPageProps) {
  const [token, setToken] = useState<string>();
  const [currentStep, setCurrentStep] = useState(0);
  const [user_id, setUserId] = useState<string>();
  const [user_type, setUserType] = useState<string>();
  const [user_name, setUserName] = useState<string>();
  const [mobile_number, setMobileNumber] = useState<string>();
  const [showPreview, setShowPreview] = useState<boolean>(false);

  const steps = Object.values(RegistrationStepTitle);
  const customersteps = Object.values(CustomerRegistrationStepTitle);

  const nextStep = () => setCurrentStep((prevValue) => prevValue + 1);

  const onCustomerRegisterComplete = (
    xToken: string,
    user_id: string,
    user_type: string,
    user_name: string,
    mobile_number: string
  ) => {
    setToken(xToken);
    setUserId(user_id);
    setUserType(user_type);
    setUserName(user_name);
    setMobileNumber(mobile_number);
    nextStep();
  };
  const onRetailRegisterComplete = (
    xToken: string,
    user_id: string,
    user_type: string,
    user_name: string,
    mobile_number: string
  ) => {
    setToken(xToken);
    setUserId(user_id);
    setUserType(user_type);
    setUserName(user_name);
    setMobileNumber(mobile_number);
    nextStep();
  };

  const onGetStartedClick = () => {
    setSession(token!, user_id!, user_type!, user_name!, mobile_number!);
  };

  useEffect(() => {
    const favicon = document.getElementById("favicon");
    const touchFavicon = document.getElementById("touch-favicon");
    if (favicon && touchFavicon) {
      favicon.setAttribute("href", "/Retailer-logo.png");
      touchFavicon.setAttribute("href", "/Retailer-logo.png");
    }
  });

  if (!isRetailer) {
    return (
      <CustomerRegisterWrapper steps={customersteps} currentStep={currentStep}>
        <CustomerRegisterUserView onComplete={onCustomerRegisterComplete} />
        <CreateCustomerView
          token={token}
          user_id={user_id}
          user_name={user_name}
          mobile_number={mobile_number}
          onComplete={onGetStartedClick}
        />
      </CustomerRegisterWrapper>
    );
  }

  return (
    <FormStepper
      steps={steps}
      shrink={showPreview}
      currentStep={currentStep}
      title="Let's Connect"
      subTitle="with us in just 3 simple steps!"
    >
      <RegisterUserView
        isRetailer={isRetailer}
        onComplete={onRetailRegisterComplete}
      />
      <CreateRetailStoreView
        token={token}
        user_id={user_id}
        onComplete={nextStep}
        showPreview={showPreview}
        setShowPreview={setShowPreview}
        mobile_number={mobile_number}
      />
      <GetStartedView onClick={onGetStartedClick} />
    </FormStepper>
  );
}

export default RegisterPage;
