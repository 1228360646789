import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  message,
  Modal,
  ModalProps,
  Space,
  Typography,
} from "antd";
import { createUseStyles } from "react-jss";
import { changePrice } from "api/retailerApi";

interface AddPriceModalProps extends ModalProps {
  product: any;
  retailerData: {
    ID: number;
    Name: string;
  };
  onPriceUpdate: (newPrice: number, productSku: string) => void;
}

export default function AddPriceModal({
  visible,
  onCancel,
  product,
  retailerData,
  onPriceUpdate,
}: AddPriceModalProps) {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState(product?.strike_price ?? "");

  const handleSave = async () => {
    const data = {
      product_sku: product.product_sku,
      new_price: +(+inputValue).toFixed(2),
      retail_store_id: retailerData.ID,
    };

    await changePrice(data).then((res) => {
      if (res.status === 200) {
        setInputValue("");
        // @ts-ignore
        onCancel();
        message.success("Changed price successfully!");
        onPriceUpdate(data.new_price, data.product_sku);
      }
    });
  };

  useEffect(() => {
    setInputValue(product?.strike_price ?? "");
  }, [product]);

  if (!!!product) return null;

  return (
    <Modal
      width={250}
      visible={visible}
      title={`Add Price`}
      centered
      footer={false}
      onCancel={onCancel}
      className={classes.addPriceModal}
    >
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Typography.Text>MRP: ₹{product.pack_price}</Typography.Text>
          <Input
            prefix="₹"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onPressEnter={handleSave}
          />
        </Space>

        <Button block type="primary" onClick={handleSave}>
          Save
        </Button>
      </Space>
    </Modal>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  addPriceModal: {
    maxWidth: "90vw",
  },
}));
