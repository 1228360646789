import React, { useRef } from "react";
import { Select } from "antd";
import { createUseStyles } from "react-jss";
import { CaretDownOutlined } from "@ant-design/icons";

const { Option } = Select;

function NumberSelect({ till, qty, whiteBg = false, ...props }: any) {
  const classes = useStyles({ whiteBg });
  const tempArray = Array.from(Array(till).keys());
  props.value = qty ? qty : props.value;
  props.value = props.value % 1 !== 0 ? props.value : Math.floor(props.value);
  props.defaultValue =
    props.defaultValue % 1 !== 0
      ? props.defaultValue
      : Math.floor(props.defaultValue);

  return (
    <Select
      className={classes.inputNumber}
      defaultValue={tempArray[qty]}
      size="small"
      {...props}
      // suffixIcon={<CaretDownOutlined />}
    >
      {tempArray.map((item, index) => (
        <Option key={index} value={index + 1}>
          {index + 1}
        </Option>
      ))}
    </Select>
  );
}

export default NumberSelect;

const useStyles = createUseStyles(({ colors }: Theme) => ({
  inputNumber: {
    fontSize: 10,
    width: "45px",
    height: 21,

    "& .ant-select-selector": {
      height: "21px !important",
      border: "none !important",
      background: ({ whiteBg }) => (whiteBg ? "#ffffff" : "#f4f4f4 !important"),
      padding: "0 0 0 5px !important",
    },

    "& .ant-select-arrow": {
      marginRight: "-4px",
      fontSize: "8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    "& *": {
      borderColor: ({ whiteBg }) =>
        whiteBg ? "#ffffff" : "#f4f4f4 !important",
      background: ({ whiteBg }) => (whiteBg ? "#ffffff" : "#f4f4f4"),
      color: "#505050",
      textAlign: "center",
    },
  },
}));
