import React, { useState } from 'react'
import Layout from 'antd/es/layout'
import { createUseStyles } from 'react-jss'
import { useRouteMatch } from 'react-router-dom'
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
  ShopOutlined,
  UserOutlined
} from '@ant-design/icons'

import HeaderMenu from 'components/HeaderMenu'
import Button from 'antd/es/button'
import Sidebar from 'components/Sidebar'

const CONTENT_SPACING = 16

const useStyles = createUseStyles({
  layout: {
    height: '100vh',
    background: '#fff'
  },
  header: {
    background: '#fff',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: [ 0, CONTENT_SPACING ]
  },
  content: {
    background: '#fff',
    height: '100%',
    margin: CONTENT_SPACING,
    overflowY: 'auto',
    padding: CONTENT_SPACING
  }
})

type RetailerLayoutProps = React.PropsWithChildren<{}>

const { Header, Content } = Layout

const navLinks = (baseUrl: string) => ([
  {
    to: `${baseUrl}/business`,
    title: 'Business',
    icon: <ShopOutlined />
  },
  {
    title: 'Customer',
    icon: <UserOutlined />,
    subNavLinks: [
      {
        to: `${baseUrl}/customer/order-history`,
        title: 'Order History'
      }
    ]
  },
  {
    title: 'Account',
    icon: <SettingOutlined />,
    to: `${baseUrl}/account`
  }
])

function RetailerLayout({ children }: RetailerLayoutProps) {
  const classes = useStyles()
  const { url } = useRouteMatch('/retailer/dashboard') || {}
  const [ sidebarCollapsed, setSidebarCollapsed ] = useState(false)

  const links = navLinks(url!)

  const toggleSidebarCollapsed = () => setSidebarCollapsed(!sidebarCollapsed)
  const raiseError = () => 1 / 0
  return (
    <Layout className={classes.layout}>
      <Sidebar
        collapsed={sidebarCollapsed}
        navLinks={links}
      />
      <Layout>
        <Header className={classes.header}>
          <Button type="primary" onClick={raiseError}>
            {sidebarCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </Button>
          <HeaderMenu />
        </Header>
        <Content className={classes.content}>
          {children}
        </Content>
      </Layout>
    </Layout>
  )
}

export default RetailerLayout
