import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Slider as AntSlider } from "antd/es";
import React from "react";
import { createUseStyles } from "react-jss";

function Slider({ onChange, value, ...props }: any) {
  const classes = useStyles();

  const handleMinusClick = () => value > 0 && onChange(value - 1);
  const handlePlusClick = () => value < 20 && onChange(value + 1);

  return (
    <div className={classes.sliderWrapper}>
      <Button size="small" icon={"-"} onClick={handleMinusClick} {...props} />
      <AntSlider
        min={0}
        max={20}
        defaultValue={value}
        onAfterChange={onChange}
        className={classes.slider}
        {...props}
      />
      <Button size="small" icon={"+"} onClick={handlePlusClick} {...props} />
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  slider: {
    width: 200,
    marginLeft: "15px",
    marginRight: "15px",

    "& .ant-slider-handle": {
      background: "#D9694D",
      boxShadow: "-1px 1px 5px rgb(0 0 0 / 25%)",
      border: "none",
      width: "17px",
      height: "17px",
    },
    "& .ant-slider-rail": {
      height: 5,
      "&:hover": {
        background: "#fff",
      },
    },
    "& .ant-slider-track": {
      height: 5,
      background: "#cecece",
    },
  },
  sliderWrapper: {
    margin: [15, 0],
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",

    "& .ant-btn": {
      width: "15px",
      border: "none",
      height: "15px",
      fontSize: "12px",
      padding: "0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#E76F51",

      "&:first-child": {
        fontSize: 17,
      },
    },
  },
}));

export default Slider;
