import {
  DeleteOutlined,
  FilterOutlined,
  SearchOutlined,
  SwapOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Dropdown,
  Input,
  Layout,
  Menu,
  message,
  Row,
  Space,
  Typography,
} from "antd";
import {
  deleteCartItem,
  getCatalogue,
  getCustomerCart,
  getRetailStores,
  insertCart,
  insertCartItems,
  updateCartItemsByProductId,
} from "api/api";
import { getSession, Session } from "client/reactives/session";
import BannerCard from "components/cards/BannerCard";
import NumberSelect from "components/dataEntry/NumberSelect";
import FooterMenu from "components/FooterMenu";
import Loader from "components/loader/Loader";
import { hideScrollbar } from "components/style/CustomScrollbar";
import gtag from "components/Tracking/gtag";
import RetailStoreDetailsView from "components/views/RetailerStoreDetailsView";
import React, { useEffect, useMemo, useState } from "react";
import { createUseStyles } from "react-jss";
import { Link, useLocation, useRouteMatch } from "react-router-dom";

type RouteParams = {
  retailStoreId: string;
};

const getPriceWithoutGst = (gstPercentage: number, priceWithGst: number) => {
  let gstAmount = priceWithGst - priceWithGst * (100 / (100 + gstPercentage));
  return priceWithGst - gstAmount;
};

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const getPercentageOff = (data: any) => {
  let absDiscount = +data.pack_price - +data.strike_price;
  return Math.round((absDiscount / +data.pack_price) * 100);
};

export default function NewShopPage() {
  const {
    params: { retailStoreId },
  } = useRouteMatch<RouteParams>() || {};
  const { userId } = getSession() as Session;

  const query = useQuery();
  const classes = useStyle();

  const [pageLoading, setPageloading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [storeDetails, setStoreDetails] = useState({});
  const [products, setProducts] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState(query.get("search") ?? "");
  const [pageNumber, setPageNumber] = useState(1);
  const [endOfList, setEndOfList] = useState(false);
  const [cartId, setCartId] = useState(0);
  const [countChange, setCountChange] = useState(false);
  const [showMyCatalog, setShowMyCatalog] = useState(
    query.get("filter_mycatalog") == "true"
  );
  const [filterDropdownVisible, setFilterDropdownVisible] = useState(false);

  const handleScroll = async (e: any) => {
    if (endOfList) {
      return;
    }
    //check if reached bottom
    let element = e.target;
    if (
      element.scrollHeight - Math.round(element.scrollTop) ===
      element.clientHeight
    ) {
      setPageNumber((curr) => curr + 1);
    }
  };

  const getCartId = async () => {
    let cartId = 0;
    let cartRes = await getCustomerCart(+retailStoreId);

    if (cartRes.length) {
      cartId = cartRes[0].id;
    } else {
      let cartData = {
        retail_store_id: retailStoreId,
        payment_status: false,
        discount: 0,
        gst: 0,
        price_with_gst: 0,
        price_with_out_gst: 0,
        user_id: userId,
      };
      // Create the cart
      const insertCartRes = await insertCart(cartData);
      if (insertCartRes?.length) {
        cartId = insertCartRes[0].id;
      }
    }

    return cartId;
  };

  const onAddHandler = async (Product: any) => {
    if (+Product.quantity === 0) {
      Product.quantity = 1;
    }
    if (!!!Product.cart_id || Product.cart_id === "0") {
      try {
        let cartId: any = await getCartId();
        Product.cart_id = cartId;
        setCartId(Product.cart_id);
      } catch (e) {
        console.log(e);
      }
    }

    try {
      let productPrice = Product?.strike_price ?? Product.pack_price;
      let sub_total = getPriceWithoutGst(
        +Product.sgst + +Product.cgst,
        +productPrice * +Product.quantity
      );
      let newCartItem = {
        product_section: showMyCatalog ? "my_catalogue" : "other_products",
        product_id: Product?.product_id ?? Product.id,
        product_sku: Product?.product_sku,
        quantity: Product.quantity,
        cart_id: !!Product.cart_id ? +Product.cart_id : 0,
        cgst: Product.cgst,
        sgst: Product.sgst,
        discount: Product.discount,
        total: (+productPrice * +Product.quantity).toFixed(2),
        sub_total: sub_total.toFixed(2),
      };

      await insertCartItems(newCartItem).then((res) => {
        if (res && res.id) {
          message.success("Items added to cart");
          setCountChange(true);
          // setLoading(true);
          setProducts((curr) => {
            let index = curr.findIndex(
              (i) => i.product_sku === newCartItem.product_sku
            );

            console.log(index);
            if (index >= 0) {
              curr[index].quantity = newCartItem.quantity;
              curr[index].cart_id = newCartItem.cart_id;
              curr[index].cart_item_id = res.id;
              curr[index].retailer_specific_product_id = res.product_id;
            }

            return [...curr];
          });

          gtag("event", "cart_item_added", {
            source: "shop",
            user: userId,
            store: Product.retail_store_id,
            product: Product.name || "notset",
          });
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onUpdateHandler = async (Product: any) => {
    try {
      let productPrice = Product?.strike_price ?? Product.pack_price;
      let sub_total = getPriceWithoutGst(
        +Product.sgst + +Product.cgst,
        +productPrice * +Product.quantity
      );

      await updateCartItemsByProductId(
        +Product.cart_id,
        Product?.retailer_specific_product_id ??
          Product?.product_id ??
          Product.id,
        {
          quantity: Product.quantity,
          total: (+productPrice * +Product.quantity).toFixed(2),
          sub_total: sub_total.toFixed(2),
        }
      ).then((res) => {
        if (res?.length) {
          message.warning("Quantity has been updated");

          setProducts((curr) => {
            let index = curr.findIndex(
              (i) => i.product_sku === Product.product_sku
            );

            if (index >= 0) {
              curr[index].quantity = Product.quantity;
            }

            return [...curr];
          });
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const removeCartItem = async (Product: any) => {
    await deleteCartItem(+Product?.cart_item_id).then((result) => {
      if (result.length) {
        message.success("Item removed from cart");
        setCountChange(true);

        setProducts((curr) => {
          let index = curr.findIndex(
            (i) => i.product_sku === Product.product_sku
          );

          if (index >= 0) {
            curr[index].quantity = "0";
            curr[index].cart_item_id = "0";
          }

          return [...curr];
        });
      }
    });
  };

  const fetchStoreDetails = async () => {
    let storeDetailsRes = await getRetailStores(+retailStoreId);
    setStoreDetails(storeDetailsRes);
    setPageloading(false);
  };

  const fetchInitialData = async () => {
    let [pCount, ...productsRes] = await getCatalogue(
      showMyCatalog ? "/getMyCatalogue" : "/catalogue/products",
      +retailStoreId,
      pageNumber,
      searchQuery
    );

    if (productsRes.length % 20 !== 0) {
      setEndOfList(true);
    }

    if (pageNumber > 1) {
      setProducts((curr) => [...curr, ...productsRes]);
    } else {
      setProducts(productsRes);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (pageLoading) fetchStoreDetails();
  }, []);

  useEffect(() => {
    fetchInitialData();
  }, [pageNumber, searchQuery, loading]);

  if (pageLoading) {
    return <Loader />;
  }

  return (
    <div className={classes.shopPage}>
      <RetailStoreDetailsView
        countChange={countChange}
        setCountChange={setCountChange}
        retailStore={storeDetails}
      />

      <Space className={classes.pageHeader}>
        <Input
          size="large"
          suffix={<SearchOutlined />}
          className={classes.searchInput}
          placeholder="Search for medicines..."
          value={searchQuery}
          onChange={(e) => {
            setPageNumber(1);
            setSearchQuery(e.target.value);
          }}
        />
        {/* <Button size="large" type="text" icon={<SwapOutlined rotate={-90} />} /> */}
        <Dropdown
          visible={filterDropdownVisible}
          onVisibleChange={setFilterDropdownVisible}
          trigger={["click"]}
          overlay={
            <Menu className={classes.filterMenu}>
              <Menu.ItemGroup title="Filters">
                <Menu.Item>
                  <Checkbox
                    checked={showMyCatalog}
                    onChange={(e) => {
                      setShowMyCatalog(e.target.checked);
                      setLoading(true);
                      setPageNumber(1);
                      setFilterDropdownVisible(false);
                    }}
                  />
                  My catalogue
                </Menu.Item>
              </Menu.ItemGroup>

              <div className={classes.resetFilterButtonWrapper}>
                {/* <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    setLoading(true);
                    setPageNumber(1);
                    setFilterDropdownVisible(false);
                  }}
                >
                  Save
                </Button> */}
                <Button
                  size="small"
                  type="link"
                  icon={<SyncOutlined />}
                  onClick={() => {
                    setShowMyCatalog(false);
                    setLoading(true);
                    setPageNumber(1);
                    setFilterDropdownVisible(false);
                  }}
                >
                  Reset
                </Button>
              </div>
            </Menu>
          }
        >
          <Button
            size="large"
            type="text"
            icon={<FilterOutlined />}
            onClick={() => setFilterDropdownVisible(!filterDropdownVisible)}
          />
        </Dropdown>
      </Space>

      {/* <Space className={classes.searchSuggestions}>
        {["Diabetes", "Heart Issues", "Mental Health", "Hypertension"].map(
          (tag, index) => (
            <Button key={index}>{tag}</Button>
          )
        )}
      </Space> */}

      <Space
        size={10}
        direction="vertical"
        className={classes.productsWrapper}
        // @ts-ignore
        onScroll={handleScroll}
      >
        {!loading ? (
          products.map((product, index) => (
            <>
              {index % 40 === 0 && (
                <BannerCard page="shop" storeId={+retailStoreId} />
              )}
              <ProductCard
                key={index}
                data={product}
                onAdd={onAddHandler}
                onUpdate={onUpdateHandler}
                onRemove={removeCartItem}
              />
            </>
          ))
        ) : (
          <Loader height="60vh" />
        )}
      </Space>

      <Layout.Footer style={{ padding: 0 }}>
        <FooterMenu retailStoreId={retailStoreId} />
      </Layout.Footer>
    </div>
  );
}

const ProductCard = ({ data, onAdd, onUpdate, onRemove }: any) => {
  const classes = useStyle();
  const {
    params: { retailStoreId },
  } = useRouteMatch<RouteParams>() || {};

  return (
    <Card className={classes.productCard}>
      {data.prescription === "PRESCRIPTION" && (
        <Typography.Text className={classes.prescriptionText}>
          Prescription Required
        </Typography.Text>
      )}
      {data.apply_inventory_check === true && (data.curr_inventory_size === null || data.curr_inventory_size == 0) && (
        <Typography.Text className={classes.prescriptionText}>
          Out of Stock
        </Typography.Text>
      )}
      <Row justify="space-between" className={classes.productCardContent}>
        <Col className={classes.productCardLeft}>
          <Link
            to={`/dashboard/stores/${retailStoreId}/product/${data?.product_sku}?source=other_products`}
          >
            <Typography.Text strong className={classes.productName}>
              {data.name}
            </Typography.Text>
            <Typography.Text className={classes.productStripText}>
              {data.pack}
            </Typography.Text>
          </Link>

          <Typography.Text className={classes.productManufacturer}>
            {data.manufacturer}
          </Typography.Text>
        </Col>
        <Col className={classes.productCardRight}>
          <div>
            <Typography.Text strong className={classes.sellingPrice}>
              ₹{parseFloat(data.strike_price ?? data.pack_price).toFixed(2)}
            </Typography.Text>
            {!!data.strike_price && +data.strike_price < +data.pack_price && (
              <>
                <Typography.Text
                  type="secondary"
                  delete
                  className={classes.deletedPrice}
                >
                  ₹{(+data.pack_price).toFixed(2)}
                </Typography.Text>
                <Typography.Text className={classes.offPercentage}>
                  <i>{getPercentageOff(data)}% off</i>
                </Typography.Text>
              </>
            )}
          </div>

          {+data.cart_item_id > 0 ? (
            <div className={classes.productActions}>
              <Button
                size="small"
                type="text"
                icon={<DeleteOutlined />}
                onClick={() => onRemove(data)}
              />
              <NumberSelect
                whiteBg
                till= {data.apply_inventory_check === true && (data.curr_inventory_size === null || data.curr_inventory_size == 0) ? Math.max(data.curr_inventory_size,99) : 99} 
                defaultValue={data.quantity}
                style={{ marginTop: 15 }}
                onChange={(qty: any) => onUpdate({ ...data, quantity: qty })}
              />
            </div>
          ) : ( data.apply_inventory_check === true && (data.curr_inventory_size === null || data.curr_inventory_size == 0) ? (
                <Button
                  type="primary"
                  className={classes.addButton}
                  onClick={() => onAdd(data)}
                  disabled
                >
                  Add
                </Button>
              ) : (
                <Button
                type="primary"
                className={classes.addButton}
                onClick={() => onAdd(data)}
                >
                  Add
                </Button>
              )
          )}
        </Col>
      </Row>
    </Card>
  );
};

const useStyle = createUseStyles(({ colors }: Theme) => ({
  shopPage: {},
  pageHeader: {
    padding: [10, 15],
    display: "flex",
    alignItems: "center",
    "& .ant-space-item:first-child": {
      flex: 1,
    },
  },
  searchInput: {
    "& input": {
      fontSize: 12,
    },
  },

  searchSuggestions: {
    padding: [5, 15],
    width: "100vw",
    overflowX: "auto",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    ...hideScrollbar,

    "& .ant-btn": {
      border: "none",
      backgroundColor: "#E9EFF1",
      borderRadius: 3,
    },
  },

  productsWrapper: {
    width: "100%",
    paddingBottom: 50,
    height: "calc(100vh - 200px)",
    overflowY: "auto",
    padding: 15,
  },
  productCard: {
    height: 105,
    width: "100%",
    borderRadius: 3,
    overflow: "hidden",
    position: "relative",

    background: "#F4F4F4",
    color: colors.textPrimary,
    border: "0.3px solid #D9D9D9",
    boxShadow:
      "-1px -1px 2px rgba(0, 0, 0, 0.1), 1px 1px 2px rgba(0, 0, 0, 0.1)",

    "& .ant-card-body": {
      padding: [10, 0, 0],
      height: "100%",
    },
  },
  productCardContent: {
    padding: 10,
    height: "100%",
  },
  productCardLeft: {
    flex: 1,
    maxWidth: "80%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  productCardRight: {
    textAlign: "right",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  prescriptionText: {
    width: "100%",
    // padding: 2,
    fontSize: 8,
    textAlign: "center",
    display: "block",
    color: colors.light100,
    backgroundColor: "#F47070",
    position: "absolute",
    top: 0,
    left: 0,
  },
  productName: {
    display: "block",
    fontSize: 14,
    fontWeight: 600,
    wordBreak: "break-word",
  },
  productStripText: {
    display: "block",
    fontSize: 10,
  },
  productManufacturer: {
    fontSize: 8,
    fontStyle: "italic",
    color: colors.textPrimary,
  },
  sellingPrice: {
    fontSize: 12,
    display: "block",
  },
  deletedPrice: {
    fontSize: 10,
    display: "block",
  },
  offPercentage: {
    fontSize: 8,
    display: "block",
  },
  addButton: {
    width: 45,
    height: 21,
    fontSize: 10,
    marginTop: 15,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  productActions: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    "& > .ant-btn": {
      marginRight: 5,
      height: 21,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      // "& .anticon":{}
    },
  },

  filterMenu: {
    "& .ant-checkbox": {
      marginRight: 8,
    },
  },
  resetFilterButtonWrapper: {
    padding: [15, 12, 5],
    display: "flex",
    "& .ant-btn": {
      fontSize: 10,
    },
  },
}));
