import copy from "copy-text-to-clipboard";
import { createUseStyles } from "react-jss";
import Loader from "components/loader/Loader";
import { CopyOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Row, message, Col, Divider, Input } from "antd";
import { getUserLoyaltyDetails, redeemLoyaltyPoints } from "api/api";

type CustomerSummaryCardProps = {
  customer: any;
};

function LoyaltyPointsCard({ customer }: CustomerSummaryCardProps) {
  const classes = useStyles();
  const [pageData, setPageData] = useState({
    userID: 0,
    discountCoupon: "MDE20049",
    orderAmount: 0,
    loyalty: {
      totalPoints: 0,
      currentPoints: 0,
      pointsRedeemed: 0,
    },
    showDiscountCoupon: false,
    loading: true,
  });

  const copyCouponID = (coupon_id: any) => {
    copy(coupon_id);
    message.success("Copied to clipboard!");
  };

  const generateDiscountCoupon = async () => {
    if (pageData.orderAmount <= 0) {
      message.warning("Order amount should be greater than zero!");
      return;
    }

    var data = {
      retail_store_id: customer.retail_store_id,
      customer_id: customer.user_id,
      order_amount: pageData.orderAmount,
      transaction_type: "ADD",
    };
    var resp = await redeemLoyaltyPoints(data);
    if (resp && resp.loyalty_points_added_or_redeemed) {
      message.success("Loyalty points added!");
    }

    // var discountCoupon = "RSE" + (Math.random()*100000).toFixed(0);
    // discountCoupon: discountCoupon,
    // showDiscountCoupon: true
    setPageData({
      ...pageData,
      loading: true,
    });
  };

  async function fetchData(cust: any) {
    var data = await getUserLoyaltyDetails(cust.user_id, cust.retail_store_id);
    var totalPoints = 0;
    var currentPoints = 0;
    if (data.length > 0) {
      totalPoints = +data[0].total_loyalty_points;
      currentPoints = +data[0].loyalty_points;
    }
    setPageData({
      userID: +cust.user_id,
      discountCoupon: "MDE20049",
      orderAmount: 0,
      loyalty: {
        totalPoints: totalPoints,
        currentPoints: currentPoints,
        pointsRedeemed: totalPoints - currentPoints,
      },
      showDiscountCoupon: false,
      loading: false,
    });
  }

  useEffect(() => {
    if (
      customer &&
      (pageData.loading || pageData.userID !== +customer.user_id)
    ) {
      fetchData(customer);
    }
  }, [customer, pageData.loading]);

  return (
    <>
      {!pageData.loading ? (
        <Row className={classes.loyaltyPointsCard}>
          <Col xs={24} md={8}>
            <div className={classes.loyaltyPointsCardTitle}>Loyalty Points</div>
            <div className={classes.currentPoints}>
              <span>{pageData.loyalty.currentPoints}</span>
              <p>CURRENT POINTS</p>
            </div>
            <div className={classes.loyaltyPointsSummary}>
              <p>TOTAL POINTS</p>
              <span>{pageData.loyalty.totalPoints}</span>
            </div>
            <div className={classes.loyaltyPointsSummary}>
              <p>POINTS REDEEMED</p>
              <span>{pageData.loyalty.pointsRedeemed}</span>
            </div>
          </Col>
          <Col xs={0} md={3}>
            <Divider type="vertical" />
          </Col>
          <Col xs={24} md={0}>
            <Divider type="horizontal" />
          </Col>
          <Col xs={24} md={13}>
            <div className={classes.loyaltyPointsCardTitle}>
              Redeem the points and generate coupon
            </div>
            <Input
              value={pageData.orderAmount > 0 ? pageData.orderAmount : ""}
              placeholder="Order value after discount"
              className={classes.generateCouponInput}
              addonAfter={
                <span
                  onClick={generateDiscountCoupon}
                  style={{ padding: "5px 20px" }}
                >
                  Submit
                </span>
              }
              onChange={(e) => {
                var showDiscountCoupon = pageData.showDiscountCoupon;
                if (+e.target.value === 0) {
                  showDiscountCoupon = false;
                }
                setPageData({
                  ...pageData,
                  orderAmount: +e.target.value,
                  showDiscountCoupon: showDiscountCoupon,
                });
              }}
            />
            {/* // don't show this section, can only show the relevant text */}
            {pageData.showDiscountCoupon && pageData.orderAmount > 0 ? (
              <>
                <Divider dashed />
                <div className={classes.discountCode}>
                  <b>Discount Code : </b>
                  <div
                    className={classes.couponContainer}
                    onClick={() => copyCouponID(pageData.discountCoupon)}
                  >
                    <CopyOutlined />
                    <span>{pageData.discountCoupon}</span>
                  </div>
                </div>
                <p className={classes.noteText}>
                  Click to copy the above code and then paste in your billing
                  software to apply the discount in the final order value
                </p>
              </>
            ) : (
              ""
            )}
          </Col>
        </Row>
      ) : (
        <Loader />
      )}
    </>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  loyaltyPointsCard: {
    width: "100%",
    // height: "100%",
    padding: "1.5rem 2rem",
    borderRadius: 5,
    backgroundColor: "#E5E5E5",

    "& .ant-divider-vertical": {
      height: "100%",
      borderLeft: "1px solid rgba(0, 0, 0, 0.25) !important",
    },

    "& .ant-divider-dashed": {
      margin: "10px auto",
      borderColor: "rgba(0, 0, 0, 0.25) !important",
      borderWidth: "2px 0 0 !important",
      minWidth: 193,
      width: 193,
    },
  },
  loyaltyPointsCardTitle: {
    width: "100%",
    fontWeight: 500,
    textAlign: "center",
    fontFamily: "Roboto",
    fontSize: 14,
  },
  currentPoints: {
    width: "100%",
    marginTop: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",

    "& span": {
      fontFamily: "Roboto",
      fontWeight: 500,
      fontSize: 24,
      lineHeight: "20px",
      color: "#E76F51",
    },

    "& p": {
      marginBottom: 20,
      fontFamily: "Roboto",
      fontWeight: 500,
      fontSize: 10,
      lineHeight: "20px",
    },
  },
  loyaltyPointsSummary: {
    width: "80%",
    height: 24,
    margin: "auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "& p": {
      margin: 0,
      fontFamily: "Roboto",
      fontWeight: 500,
      fontSize: 10,
    },
    "& span": {
      fontFamily: "Roboto",
      fontWeight: 500,
      fontSize: 14,
    },
  },
  generateCouponInput: {
    margin: "17px 0",

    "& .ant-input-wrapper": {
      borderRadius: 2,
      width: 210,
      margin: "auto",

      "& input": {
        fontSize: 8,
        borderTopLeftRadius: 2,
        overflow: "hidden",
        borderBottomLeftRadius: "4px",
        border: "none",
      },
    },
    "& .ant-input-group-addon": {
      width: 65,
      fontSize: 8,
      cursor: "pointer",
      borderRadius: 2,
      color: "#fff",
      background: "#E76F51",
    },
  },
  discountCode: {
    margin: "10px 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& > b": {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 10,
    },
  },
  couponContainer: {
    width: 82,
    height: 25,
    margin: "0 10px",
    background: "#D9D9D9",
    borderRadius: 20,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    cursor: "pointer",

    "&:hover": {
      background: "#D4D4D4",
    },

    "& > .anticon": {
      padding: "0 5px 0 10px",
    },

    "& > span": {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 10,
      lineHeight: 12,
      textTransform: "capitalize",
    },
  },
  noteText: {
    marginTop: 15,
    marginBottom: 0,
    fontSize: 9,
    lineHeight: "11px",
    color: "rgba(0, 0, 0, 0.6)",
    fontStyle: "italic",
    textAlign: "center",
    fontFamily: "Roboto",
    fontWeight: "normal",
  },
}));

export default LoyaltyPointsCard;
