import { Button, Card, Col, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import Layout from "antd/es/layout";
import { useStyles } from "./MyPrescriptionsPage.style";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { generateGetUrl, getPrescriptions } from "api/api";
import Logo from "components/Logo";
import Loader from "components/loader/Loader";
type RouteParams = {
  cartId: string;
  retailStoreId: string;
};
export interface prescriptionProps {
  name: string;
  date: string;
  imageUrl: string;
}

export const MyPrescriptionsPage = () => {
  const { goBack, push } = useHistory();
  const classes = useStyles();
  const Arrow = ArrowLeftOutlined;
  const { Header, Content, Footer } = Layout;
  const [myPrescriptions, setMyPrescriptions] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [selectedPrescriptions, setSelectedPrescriptions] = useState<number[]>(
    []
  );
  const {
    params: { cartId },
  } = useRouteMatch<RouteParams>() || {};

  useEffect(() => {
    async function get() {
      if (!loading) {
        const prescriptions = await getPrescriptions();
        var data = [];
        var items = [];
        if (prescriptions !== undefined && prescriptions?.length > 0) {
          for (var i = 0; i < prescriptions?.length; i++) {
            const date = (prescriptions[i]?.date).split("T");
            for (
              var j = 0;
              j < prescriptions[i]?.prescription_data?.length;
              j++
            ) {
              items.push({
                name: prescriptions[i]?.prescription_data[j],
                date: date[0],
              });
            }
          }
          const map = new Map();
          for (const item of items) {
            if (!map.has(item.name)) {
              map.set(item.name, true);
              const imageUrl = await generateGetUrl(
                item.name,
                "prescriptionImages"
              );
              data.push({
                name: item.name,
                date: item.date,
                imageUrl: imageUrl,
              });
            }
          }
        }
        setMyPrescriptions(data);
        setLoading(true);
      }
    }
    get();
  }, []);

  const onSelectPrescription = (prescriptionNo: number) => {
    if (selectedPrescriptions.includes(prescriptionNo)) {
      const newSelectedPrescriptions = [...selectedPrescriptions];
      const removePrescriptionNo =
        newSelectedPrescriptions.indexOf(prescriptionNo);
      newSelectedPrescriptions.splice(removePrescriptionNo, 1);
      setSelectedPrescriptions([...newSelectedPrescriptions]);
    } else {
      setSelectedPrescriptions([...selectedPrescriptions, prescriptionNo]);
    }
  };

  const submitSelectedPrescriptions = () => {
    const presList = myPrescriptions.filter(
      (p: prescriptionProps, pIndex: number) =>
        selectedPrescriptions.includes(pIndex) && p
    );
    push({ pathname: "../" + cartId, state: { presList } });
  };
  const cardOnSelectStyle = (prescriptionNo: number) => {
    if (selectedPrescriptions.includes(prescriptionNo)) {
      return {
        backgroundColor: "#2A9D8F",
        color: "#FFFFFF",
      };
    }
  };

  return (
    <>
      {myPrescriptions !== undefined ? (
        <Layout style={{ height: "100vh" }}>
          <Header className={classes.header}>
            <Row>
              <Col className={classes.center} xs={{ span: 4 }} lg={{ span: 2 }}>
                <Arrow className={classes.arrow} onClick={goBack} />
              </Col>
              <Col
                className={classes.myPresText}
                xs={{ span: 12 }}
                lg={{ span: 14 }}
              >
                My Prescription
              </Col>
            </Row>
          </Header>
          <Content>
            <div className={classes.content}>
              {myPrescriptions?.length > 0 ? (
                <div className={classes.myPres}>
                  {myPrescriptions.map((prescription: any, index: any) => (
                    <Card
                      className={classes.prescriptionCard}
                      onClick={onSelectPrescription.bind(this, index)}
                      style={cardOnSelectStyle(index)}
                    >
                      <Row align="middle">
                        <Col span={5}>
                          <Logo url={prescription?.imageUrl} />
                        </Col>
                        <Col span={18} offset={1}>
                          <Space direction="vertical">
                            <div className="name">{prescription?.name}</div>
                            <div className={classes.date}>
                              {prescription?.date}
                            </div>
                          </Space>
                        </Col>
                      </Row>
                    </Card>
                  ))}
                </div>
              ) : (
                <div className={classes.centreText}>No Prescription</div>
              )}
            </div>
          </Content>
          <Footer className={classes.footer}>
            <Button
              className={classes.continue}
              type="primary"
              onClick={submitSelectedPrescriptions}
            >
              Continue
            </Button>
          </Footer>
        </Layout>
      ) : (
        <Loader />
      )}
    </>
  );
};
