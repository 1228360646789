import { Drawer, Image } from "antd";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Button } from "antd/es";
import React from "react";
import { createUseStyles } from "react-jss";
import MedicineBottle from "assets/svgs/medicine-bottle.svg";
import Tablets from "assets/svgs/tablets.svg";
import { ShopOutlined } from "@ant-design/icons";

interface OffersDrawerProps {
  onClose: () => void;
  visibility: boolean;
  setVisibility?: any;
}

type RouteParams = {
  retailStoreId: string;
};

function OffersDrawer({
  onClose,
  visibility,
  setVisibility,
}: OffersDrawerProps) {
  const {
    params: { retailStoreId },
  } = useRouteMatch<RouteParams>() || {};
  const { push } = useHistory();
  const classes = useStyles();

  const offersArray = [
    {
      cardColor: "#FFF0CD",
      offerImg: MedicineBottle,
      title: "Disprin + Grillinctus syrup",
      titleColor: "#F5A900",
      content: (
        <>
          Buy all three: <b>Rs 120</b>
        </>
      ),
    },
    {
      cardColor: "rgba(42, 157, 143, 0.2)",
      offerImg: Tablets,
      title: "Saridon",
      titleColor: "#2A9D8F",
      content: <>325mg Tablet 10's</>,
    },
    {
      cardColor: "#FFF0CD",
      offerImg: MedicineBottle,
      title: "Disprin + Grillinctus syrup",
      titleColor: "#F5A900",
      content: (
        <>
          Buy all three: <b>Rs 120</b>
        </>
      ),
    },
    {
      cardColor: "rgba(42, 157, 143, 0.2)",
      offerImg: Tablets,
      title: "Saridon",
      titleColor: "#2A9D8F",
      content: <>325mg Tablet 10's</>,
    },
  ];

  const OfferCard = ({
    cardColor,
    offerImg,
    title,
    titleColor,
    content,
  }: any) => (
    <div style={{ background: cardColor }} className={classes.offerCard}>
      <img src={offerImg} />
      <div>
        <div className={classes.offerTitle} style={{ color: titleColor }}>
          {title}
        </div>
        <div className={classes.offerContent}>{content}</div>
        <Button size="small" type="primary" style={{ background: titleColor }}>
          Add
        </Button>
      </div>
    </div>
  );

  return (
    <div>
      <Drawer
        placement="bottom"
        onClose={onClose}
        // closable={false}
        title="Top Offers"
        mask={false}
        className={classes.OffersDrawer}
        visible={visibility}
        key="bottom"
        height="205px"
      >
        <div className={classes.offers}>
          {offersArray?.map((offer: any) => (
            <OfferCard {...offer} />
          ))}
        </div>
        <div className={classes.addMoreItemsBtn}>
          <Button size="small" icon={<ShopOutlined />} onClick={() => push(`/dashboard/stores/${retailStoreId}/shop`)}>
            Add more items
          </Button>
        </div>
      </Drawer>
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  OffersDrawer: {
    zIndex: 0,

    "& .ant-drawer-title": {
      textAlign: "left",
      fontWeight: 700,
      fontSize: "12px",
      lineHeight: "15px",
      color: "#505050",
    },
    "& .ant-drawer-header": {
      padding: "12px 13px 0 !important",
      borderBottom: "none",
    },
    "& .ant-drawer-close": {
      padding: "13px",
      fontSize: "12px",
      color: "#000",
    },
    "& .ant-drawer-body": {
      padding: "12px 13px !important",
    },
  },
  offers: {
    width: "100%",
    paddingBottom: 14,
    overflowX: "auto",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  offerCard: {
    width: "170px",
    minWidth: "170px",
    height: "58px",
    marginRight: 7,
    borderRadius: "3px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",

    "& img": {
      width: 30,
      objectFit: "contain",
      margin: [0, 10],
    },

    "& .ant-btn": {
      height: "15px",
      width: "65px",
      fontSize: "8px",
      border: "none",
    },
  },
  offerTitle: {
    marginBottom: 2,
    fontWeight: "500",
    fontSize: "8px",
    lineHeight: "10px",
    textTransform: "capitalize",
  },
  offerContent: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "8px",
    lineHeight: "12px",
    color: "#505050",
  },
  addMoreItemsBtn: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& .ant-btn": {
      width: 140,
      fontWeight: "500",
      fontSize: "10px",
      lineHeight: "15px",
      color: "#2A9D8F",

      "& .anticon": {
        fontSize: "12px",
      },
    },
  },
}));

export default OffersDrawer;
