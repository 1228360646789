import React from "react";
import { message, Modal } from "antd";
import { useState, useEffect } from "react";
import { createUseStyles } from "react-jss";
import {
  getAddressesByUserID,
  getCustomer,
  insertAddress,
  updateAddresses,
} from "api/api";
import CustomerAddressForm from "components/forms/CustomerAddressForm";
import User from "models/User";
import { getCustomerById } from "components/pages/retailerDashboard/APIs/getCustomerDetails";

function AddressModal({
  visible,
  onSave,
  onClose,
  customerUserId,
  ...props
}: any) {
  const classes = useStyles();
  const [pageData, setPageData] = useState({
    loading: true,
    addressExists: false,
    customer: {
      id: "",
    },
    defaultValues: {
      streetAddress: "",
      locality: "",
      pincode: "",
      city: "",
      state: "",
    },
  });

  useEffect(() => {
    if (pageData.loading) {
      fetchInitialData();
    }
  }, []);

  const checkEmpty = (value: any) =>
    value?.trim() !== "" && !!value ? value : "";

  const fetchInitialData = async () => {
    let customer;

    if (!!customerUserId) {
      customer = await getCustomerById(+customerUserId);
    } else {
      customer = await getCustomer();
    }

    const address = await getAddressesByUserID(customer?.user_id);

    if (!!address) {
      setPageData({
        ...pageData,
        loading: false,
        customer: customer,
        addressExists: true,
        defaultValues: {
          streetAddress: checkEmpty(customer?.line1),
          locality: checkEmpty(customer?.landmark),
          pincode: checkEmpty(customer?.pincode),
          city: checkEmpty(customer?.city),
          state: checkEmpty(customer?.state),
        },
      });
    } else {
      setPageData({
        ...pageData,
        loading: false,
        addressExists: false,
        customer: customer,
      });
    }
  };

  const handleFormSubmit = async (values: any) => {
    let address = {
      line1: `'${values?.streetAddress || " "}'`,
      line2: "''",
      landmark: `'${values?.locality || " "}'`,
      pincode: `'${values?.pincode || " "}'`,
      city: `'${values?.city || " "}'`,
      state: `'${values?.state || " "}'`,
    };

    let updatedAddress;
    if (pageData.addressExists) {
      updatedAddress = await updateAddresses(
        +pageData.customer?.id,
        "Customer",
        address
      );
    } else {
      updatedAddress = await insertAddress({
        ...address,
        addressable_type: `'Customer'`,
        addressable_id: +pageData.customer?.id,
      });
    }

    message.info("Successfully Updated");
    onSave(updatedAddress);
  };

  if (pageData.loading) {
    return null;
  }

  return (
    <Modal
      visible={visible}
      title={`Complete Your Address`}
      centered
      footer={false}
      onCancel={onClose}
      className={classes.addressModal}
      {...props}
    >
      <CustomerAddressForm
        hideSkip
        onSubmit={handleFormSubmit}
        defaultValues={pageData.defaultValues}
      />
    </Modal>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  addressModal: {
    "& .ant-modal-body": {
      paddingTop: "25px !important",
    },
  },
}));

export default AddressModal;
