import React from "react";
import Step from "./Step";
import Row from "antd/es/row";
import { createUseStyles } from "react-jss";
import { CheckOutlined } from "@ant-design/icons";

type StepperProps = {
  isRetailer: boolean;
  stepsArray: any;
  activeStep: number;
  showIndexOnly?: boolean;
};

function Stepper({
  isRetailer = false,
  stepsArray,
  activeStep,
  showIndexOnly,
}: StepperProps) {
  const classes = useStyles({ showIndexOnly });

  return (
    <div className={classes.stepper}>
      {showIndexOnly ? (
        <div className={classes.logoWrapper}>
          <img
            style={{ width: 50, height: 50 }}
            src={process.env.PUBLIC_URL + "/Retailer-logo_white.png"}
          />
        </div>
      ) : (
        ""
      )}
      {stepsArray?.map((step: any, index: any) => (
        <Step
          key={index}
          index={isRetailer ? <CheckOutlined /> : index + 1}
          label={!showIndexOnly && step}
          isActive={index === activeStep || isRetailer}
        />
      ))}
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  stepper: {
    padding: ({ showIndexOnly }) => (showIndexOnly ? "0" : "0.6rem"),
    color: colors.light100,
  },
  logoWrapper: {
    width: "100%",
    marginBottom: 50,
    position: "absolute",
    top: 30,
    left: 0,
    display: "flex",
    justifyContent: "center",
  },
}));

export default Stepper;
