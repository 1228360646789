import { Card, Col, message, Tabs } from "antd";
import {
  deleteCartItem,
  getCatalogue,
  getCustomerCart,
  insertCart,
  insertCartItems,
  updateCartItemsByProductId,
} from "api/api";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import CartOverviewCard from "./CartOverviewCard";
import ProductsCardTable from "./ProductsCardTable";
const { TabPane } = Tabs;

const getPriceWithoutGst = (gstPercentage: number, priceWithGst: number) => {
  let gstAmount = priceWithGst - priceWithGst * (100 / (100 + gstPercentage));
  return priceWithGst - gstAmount;
};

interface ProductsCardProps {
  storeId: number;
  userId: number;
  onClose: () => void;
}

export default function ProductsCard({
  storeId,
  userId,
  onClose,
}: ProductsCardProps) {
  const classes = useStyle();
  const [cartId, setCartId] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [products, setProducts] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [endOfList, setEndOfList] = useState(false);
  const [loading, setLoading] = useState(true);
  const [cartOverviewLoading, setCartOverviewLoading] = useState(false);

  const handleQuantityChange = async (qty: number, product: any) => {
    product.quantity = qty;
    let ACTION;

    if (+qty === 0 && !!product?.cart_item_id) {
      ACTION = "REMOVE_ITEM";
    } else if (
      !!product?.cart_item_id &&
      +product?.cart_item_id > 0 &&
      +qty > 0
    ) {
      ACTION = "UPDATE_ITEM";
    } else {
      ACTION = "ADD_ITEM";
    }

    console.log(ACTION);

    switch (ACTION) {
      case "ADD_ITEM":
        await addItemToCart(product);
        break;
      case "UPDATE_ITEM":
        await updateItemToCart(product);
        break;
      case "REMOVE_ITEM":
        await removeCartItem(+product.cart_item_id || 0);
        break;
      default:
        alert("Something Went Wrong.!");
        break;
    }

    setLoading(true);
  };

  const addItemToCart = async (product: any) => {
    let productPrice = product?.strike_price ?? product.pack_price;

    let sub_total = getPriceWithoutGst(
      +product.sgst + +product.cgst,
      +productPrice * +product.quantity
    );
    let newCartItem = {
      product_section: "admin",
      product_sku: product?.product_sku,
      product_id: product?.product_id ?? product?.id,
      quantity: product.quantity,
      cart_id: product.cart_id,
      cgst: product.cgst,
      sgst: product.sgst,
      discount: product.discount,
      total: (+productPrice * +product.quantity).toFixed(2),
      sub_total: sub_total.toFixed(2),
    };

    if (!!!product?.cart_id || product?.cart_id === "0") {
      await getCartId().then(async (cart_id) => {
        await insertCartItems({ ...newCartItem, cart_id }).then((res) => {
          message.success("Item Added Successfully.!");
          fetchProducts();
          setCartOverviewLoading(true);
        });
      });
    } else {
      await insertCartItems(newCartItem).then((res) => {
        message.success("Item Added Successfully.!");
        fetchProducts();
        setCartOverviewLoading(true);
      });
    }
  };

  const updateItemToCart = async (updatedProduct: any) => {
    let productPrice =
      updatedProduct?.strike_price ?? updatedProduct.pack_price;

    let sub_total = getPriceWithoutGst(
      +updatedProduct.sgst + +updatedProduct.cgst,
      +productPrice * +updatedProduct.quantity
    );
    let data = {
      product_section: "admin",
      product_sku: updatedProduct?.product_sku,
      quantity: updatedProduct.quantity,
      sub_total: sub_total.toFixed(2),
      total: (+productPrice * +updatedProduct.quantity).toFixed(2),
    };

    await updateCartItemsByProductId(
      +updatedProduct.cart_id,
      updatedProduct.product_id ?? updatedProduct.id,
      data
    );

    setCartOverviewLoading(true);
  };

  const removeCartItem = async (itemId: number) => {
    await deleteCartItem(itemId).then(async (result) => {
      setLoading(true);
      setCartOverviewLoading(true);
    });
  };

  const getCartId = async () => {
    let cartId = 0;
    let cartRes = await getCustomerCart(storeId, userId);

    if (cartRes.length) {
      cartId = cartRes[0].id;
    } else {
      let cartData = {
        retail_store_id: storeId,
        payment_status: false,
        discount: 0,
        gst: 0,
        price_with_gst: 0,
        price_with_out_gst: 0,
        user_id: userId,
      };
      // Create the cart
      const insertCartRes = await insertCart(cartData);
      if (insertCartRes?.length) {
        cartId = insertCartRes[0].id;
      }
    }

    setCartId(cartId);
    return cartId;
  };

  const handleProductsSearch = (value: string) => {
    if (searchInput !== value) {
      setSearchInput(value);
      setLoading(true);
    }
  };

  const handleScroll = async (e: any) => {
    if (endOfList) {
      return;
    }
    //check if reached bottom
    let element = e.target;
    if (
      element.scrollHeight - Math.round(element.scrollTop) ===
      element.clientHeight
    ) {
      setCurrentPage((curr) => curr + 1);
    }
  };

  const fetchProducts = async () => {
    let [count, ...products] = await getCatalogue(
      "/catalogue/products",
      storeId,
      currentPage,
      searchInput,
      userId
    );

    setProducts((curr) =>
      currentPage > 1 ? [...curr, ...products] : products
    );
    setCartId(products[0]?.cart_id ?? 0);
    setEndOfList(products.length < 20);
    setCartOverviewLoading(true);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
  }, [userId]);

  useEffect(() => {
    fetchProducts();
  }, [loading, currentPage]);

  useEffect(() => {
    if (products.length) {
      setTimeout(() => {
        document
          .querySelector(".ant-table-body")
          ?.addEventListener("scroll", handleScroll);
      }, 2000);
    }

    return () => {
      document
        .querySelector(".ant-table-body")
        ?.removeEventListener("scroll", handleScroll);
    };
  }, [products.length]);

  return (
    <>
      <Col lg={12} xxl={14} className={classes.cardWrapper}>
        <ProductsCardTable
          cartId={cartId}
          products={products}
          isLoading={loading}
          onClose={onClose}
          onQuantityChange={handleQuantityChange}
          onProductsSearch={handleProductsSearch}
          selectedCustomerUserId={userId}
          currentPage={currentPage}
        />
      </Col>
      <Col lg={6} className={classes.cardWrapper}>
        <CartOverviewCard
          cartId={cartId}
          loading={cartOverviewLoading}
          onChange={() => setLoading(true)}
          onLoaded={() => setCartOverviewLoading(false)}
        />
      </Col>
    </>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  cardWrapper: {
    height: "100%",
  },
  productsCard: {
    borderRadius: "5px",
    width: "100%",
    height: "100%",
    boxShadow:
      "-1px -1px 4px rgba(34, 94, 119, 0.25), 1px 1px 4px rgba(34, 94, 119, 0.25)",
  },
}));
