import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(({ colors }: Theme) => ({
  loyaltyProgramPage: {
    padding: 30,
  },
  tabs: {
    "& .ant-tabs-nav": {
      width: "fit-content",
      "&:before": {
        borderBottom: "2px solid #D9D9D9",
      },
    },
    "& .ant-tabs-tab": {
      width: 130,
      color: "#565656",
      marginLeft: "0px !important",
    },
    "& .ant-tabs-tab-active": {
      color: "#225E77",
      fontWeight: "500",
    },
    "& .ant-tabs-ink-bar": {
      background: "#225E77",
    },
  },
}));

export default useStyles;
