import Loader from "components/loader/Loader";
import useStyles from "./LoyaltyProgram.style";
import { getLoyaltyInfo } from "api/retailerApi";
import React, { useEffect, useState } from "react";
import ConversionFactorCard from "./ConversionFactorCard";
import RadioSwitch from "components/radioSwitch/RadioSwitch";

type LoyaltyProgramPageProps = {
  retailerData: {
    ID: number;
    Name: string;
  };
};

function LoyaltyProgram({
  retailerData
}: LoyaltyProgramPageProps) {

  const classes = useStyles();
  const [pageData, setPageData] = useState({
    activeOption: "Flat",
    conversionFactor: 1,
    newMembers: 1,
    silverMembers: 2,
    goldMembers: 3,
    platinumMembers: 4,
    retailerStoreID: retailerData.ID,
    valuesUpdated: false,
    loyaltyProgramStatus: "inactive",
    parser_status: "",
    reorder_status: false,
    loading: true
  });
  const rateOptions = ["Flat", "Tier"];

  const handleRateTypeChange = (e: any) =>
    setPageData({ ...pageData, activeOption: e.target.value });

  async function getInitialData() {
    var data = await getLoyaltyInfo(retailerData.ID);
    var activeOption = "Flat";
    var newMembers = 0;
    var silverMembers = 0;
    var goldMembers = 0;
    var platinumMembers = 0;
    var conversionFactor = 0;
    var loyaltyProgramStatus = "";
    if (pageData.loading && data.length > 0) {
      var loyaltyData = data[0]["loyalty_config"];
      var calculationFactor = loyaltyData["calculation_factor"];
      if (loyaltyData["tier_type"] === "multiple") {
        newMembers = calculationFactor["0"]*100;
        silverMembers = calculationFactor["1"]*100;
        goldMembers = calculationFactor["2"]*100;
        platinumMembers = calculationFactor["3"]*100;
        activeOption = "Tier";
      } else if (loyaltyData["tier_type"] === "single") {
        conversionFactor = calculationFactor*100;
      }
      loyaltyProgramStatus = loyaltyData["loyalty_program_status"];
    }

    setPageData({
      activeOption: activeOption,
      conversionFactor: conversionFactor,
      newMembers: newMembers,
      silverMembers: silverMembers,
      goldMembers: goldMembers,
      platinumMembers: platinumMembers,
      retailerStoreID: retailerData.ID,
      valuesUpdated: false,
      loyaltyProgramStatus: loyaltyProgramStatus,
      parser_status: data.length > 0 ? data[0]['parser_status'] : "",
      reorder_status: data.length > 0 ? data[0]['reorder_status'] : false,
      loading: false
    })
  }

  useEffect(() => {
    if (pageData.loading === true) {
      getInitialData();
    }
  }, []);

  return (
    <div className={classes.loyaltyProgramPage}>
      <div data-page={pageData}></div>
      {!pageData.loading ? (
        <>
          <RadioSwitch
            options={rateOptions}
            value={pageData.activeOption}
            onChange={handleRateTypeChange}
          />
          <ConversionFactorCard
            pageData={pageData}
            setPageData={setPageData}
            rateType={pageData.activeOption}
          />
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default LoyaltyProgram;
