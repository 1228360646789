import React, { useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import RaseetLogo from "../../assets/logos/retailer-with-name.png";
import LoginIllustration from "../../assets/svgs/raseet-login-illustration.svg";
import { createUseStyles } from "react-jss";
import { LockOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";
import InputField from "components/dataEntry/InputField";
import FormWrapper from "components/dataEntry/FormWrapper";
import * as yup from "yup";

import Field from "components/field/Field";
import useForm from "hooks/useForm";
import User from "models/User";
import { CreateSessionInput } from "generated/schema";
import Countdown from "antd/lib/statistic/Countdown";
import Row from "components/Row";
import { Button, Col, Form, message } from "antd";
import { generateLoginOtp } from "api/api";

function RetailerOtpLoginForm({
  toggleLoginMethod,
  setMobileNumber,
  ...rest
}: any) {
  const classes = useStyles();
  const [otpBoxVisible, setOtpBoxVisible] = useState(false);
  const [resendVisible, setResendVisible] = useState(false);
  const [deadline, setDeadline] = useState(Date.now());

  const { methods, submitHandler } = useForm<CreateSessionInput>({
    ...rest,
    resolver: User.validateLoginWithOtp(),
  });

  const generateOtp = async () => {
    try {
      const res = await generateLoginOtp({
        mobile_number: methods.watch().mobileNumber,
        otp_type: "'Login'",
        user_type: "RETAILER",
      });
      if (res.error) {
        message.error(res.error);
        return;
      }
    } catch (e: any) {
      message.error(e);
      return;
    }
    setOtpBoxVisible(true);
    setDeadline(Date.now() + 30 * 1000);
  };

  const onFinishCounter = async () => {
    setResendVisible(true);
  };

  const handleGenerateOtpClick = () => {
    if (methods.watch().mobileNumber?.length !== 10) {
      message.error("Phone number should be 10 digits.");
    } else {
      generateOtp();
    }
  };

  const getResendBtnStyles = () =>
    resendVisible
      ? { color: "#2A9D8F", fontSize: "0.9em" }
      : { fontSize: "0.9em" };

  useEffect(() => {
    if (!!methods.watch().mobileNumber) {
      setMobileNumber(methods.watch().mobileNumber);
    }
  }, [methods.watch().mobileNumber]);

  return (
    <div className={classes.loginForm}>
      <img className={classes.logo} src={RaseetLogo} alt="Raseet Logo" />
      <FormProvider {...methods}>
        <FormWrapper formTitle="Login via OTP" onSubmit={submitHandler}>
          <Field
            as={InputField}
            type="number"
            name="mobileNumber"
            disabled={otpBoxVisible}
            placeholder="Enter Mobile Number"
            prefix={<PhoneOutlined rotate={90} />}
          />
          <p className={classes.formText} hidden={otpBoxVisible}>
            An OTP would be sent on this number
          </p>

          {otpBoxVisible && (
            <Field
              as={InputField}
              name="otp"
              type="password"
              placeholder="Enter The OTP"
              prefix={<MailOutlined />}
            />
          )}

          <Row
            hidden={!otpBoxVisible}
            align="middle"
            justify="space-between"
            style={{ marginTop: "1em" }}
          >
            <Col span={7} className={classes.countDownWrapper}>
              <Countdown
                value={deadline}
                format="mm:ss"
                className={classes.CountDown}
                onFinish={onFinishCounter}
              />
            </Col>
            <Col span={8}>
              <Button
                type="link"
                onClick={generateOtp}
                disabled={!resendVisible}
                style={getResendBtnStyles()}
              >
                Resend OTP
              </Button>
            </Col>
          </Row>

          <Form.Item hidden={otpBoxVisible}>
            <Button
              type="primary"
              style={{ width: "100%" }}
              onClick={handleGenerateOtpClick}
            >
              Generate OTP
            </Button>
          </Form.Item>
          <Form.Item hidden={!otpBoxVisible}>
            <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
              Submit
            </Button>
          </Form.Item>

          <p className={classes.formActionText} onClick={toggleLoginMethod}>
            Login via Password
          </p>
        </FormWrapper>
      </FormProvider>

      <img
        className={classes.illustration}
        src={LoginIllustration}
        alt="Raseet Illustration"
      />
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  loginForm: {
    minHeight: "100vh",
    padding: "2rem 2.5rem",
    backgroundColor: colors.light100,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    position: "relative",

    "@media (min-width: 1280px)": {
      padding: "2.5rem 5rem",
    },
  },
  logo: {
    width: "20rem",
    margin: "2rem auto",
  },
  illustration: {
    width: "20rem",
    left: "-18.5rem",
    bottom: "0",
    position: "absolute",
    zIndex: "10",

    "@media (min-width: 1280px)": {
      width: "23rem",
    },
  },
  resendOTP: {
    width: "fit-content",
    marginLeft: "auto",
    color: colors.primary200,
    fontWeight: 600,
    cursor: "pointer",
  },
  warning: {
    color: "red",
    fontWeight: 600,
    marginLeft: 15,
  },
  formText: {
    color: colors.dark400,
  },
  formActionText: {
    width: "100%",
    margin: 0,
    marginTop: 20,
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    textAlign: "right",
    color: "#225E77",
    cursor: "pointer",
  },
  submitBtn: {
    width: "100%",
    minHeight: "2.5rem",
    color: colors.light100,
    backgroundColor: colors.primary200,
    borderRadius: "0.6rem",
    lineHeight: "1.3rem",
    fontWeight: 600,
    border: "none",
    outline: "none",

    "&:hover": {
      backgroundColor: "#12485f",
    },
  },
  countDownWrapper: {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
  },
  CountDown: {
    width: "100%",
    "& .ant-statistic-content-value": {
      fontSize: "0.55em",
      marginLeft: "1em",
    },
    "& .ant-statistic-content": {
      display: "flex",
      justifyContent: "flex-start",
    },
  },
  "@media (max-width: 768px)": {
    logo: {
      width: "14rem",
      margin: "4rem auto",
    },
  },
}));

export default RetailerOtpLoginForm;
