import React from "react";
import { Button, Col } from "antd/es";
import { Divider, Input, message } from "antd";
import Slider from "components/dataEntry/Slider";
import { updateLoyaltyInfo } from "api/retailerApi";
import useStyles from "./ConversionFactorCard.style";

type ConversionFactorCardProps = {
  pageData: any;
  setPageData: any;
  rateType: "Flat" | "Tier" | string;
};

function ConversionFactorCard({
  pageData,
  setPageData,
  rateType = "Flat",
}: ConversionFactorCardProps) {
  const classes = useStyles();

  async function saveCFValues() {
    if (pageData['valuesUpdated']) {
      var calculationFactor = pageData['conversionFactor'];
      if (pageData['activeOption'] === "Tier") {
        calculationFactor = {
          "0": pageData['newMembers']/100,
          "1": pageData['silverMembers']/100,
          "2": pageData['goldMembers']/100,
          "3": pageData['platinumMembers']/100
        }
      } else {
        calculationFactor = calculationFactor/100;
      }
      var reqSubSet = {
        "tier_type": pageData['activeOption'] === "Flat" ? "single" : "multiple",
        "calculation_factor": calculationFactor,
        "loyalty_program_status": "active"
      };
      var reqData = {
        "loyalty_config": JSON.stringify(reqSubSet),
        "parser_status": pageData['parser_status'],
        "reorder_status": pageData['reorder_status']
      }
      await updateLoyaltyInfo(reqData, pageData.retailerStoreID);
      message.success({
        content: "Values updated",
        duration: 4,
        style: {
          marginTop: "80vh",
        },
      });
    }
  }

  function sendErrorMsg(str: string) {
    message.error({
      content: "Value should be greater than " + str,
      duration: 4,
      style: {
        marginTop: "80vh",
      },
    });
  }

  const handleChange = (value: number, dataIndex: string) => {
    let newData = { ...pageData };
    var valueUpdated = newData["valuesUpdated"];
    if (dataIndex === 'conversionFactor') {
      newData[dataIndex] = value;
      if (!valueUpdated) {
        valueUpdated = true;
      }
    } else if(dataIndex === 'newMembers') {
      newData['silverMembers'] = value*2 <= 20 ? value*2 : 20;
      newData['goldMembers'] = value*3 <= 20 ? value*3 : 20;
      newData['platinumMembers'] = value*4 <= 20 ? value*4 : 20;
      newData[dataIndex] = value;
      if (!valueUpdated) {
        valueUpdated = true;
      }
    } else if(dataIndex === 'silverMembers') {
      if(value < 2*newData['newMembers']) {
        sendErrorMsg("new members");
      } else {
        var diff = value-newData['newMembers'];
        newData['goldMembers'] = value+1*diff <= 20 ? value+1*diff : 20;
        newData['platinumMembers'] = value+2*diff <= 20 ? value+2*diff : 20;
        newData[dataIndex] = value;
        if (!valueUpdated) {
          valueUpdated = true;
        }
      }
    } else if(dataIndex === 'goldMembers') {
      var df = newData['silverMembers'] - newData['newMembers'];
      if(value < newData['silverMembers'] + df) {
        sendErrorMsg("silver members");
      } else {
        var diff = value-newData['silverMembers'];
        newData['platinumMembers'] = value+diff <= 20 ? value+diff : 20;
        newData[dataIndex] = value;
        if (!valueUpdated) {
          valueUpdated = true;
        }
      }
    } else {
      var df = newData['goldMembers'] - newData['silverMembers'];
      if(value < newData['goldMembers'] + df) {
        sendErrorMsg("gold members");
      } else {
        newData[dataIndex] = value;
        if (!valueUpdated) {
          valueUpdated = true;
        }
      }
    }
    newData['valuesUpdated'] = valueUpdated;
    setPageData(newData);
  };

  const ConversionFactorItem = () => (
    <div className={classes.setConversionFactorWrapper}>
      <div className={classes.setConversionFactorWrapperText}>
        Set the value of the conversion factor:
      </div>
      <div>
        <Input
          value={pageData.conversionFactor}
          suffix="%"
          size="small"
          readOnly
        />
        <Slider
          value={pageData.conversionFactor}
          onChange={(v: number) => handleChange(v, "conversionFactor")}
        />
      </div>
    </div>
  );

  const TierItem = ({ title, dataIndex }: any) => (
    <div className={classes.tierItem}>
      <div className={classes.tierItemTitle}>{title}</div>
      <Input value={pageData[dataIndex]} suffix="%" size="small" readOnly />
      <Slider
        disabled={pageData['newMembers'] > 0 || dataIndex === 'newMembers' ? false : true}
        value={pageData[dataIndex]}
        onChange={(v: number) => handleChange(v, dataIndex)}
      />
    </div>
  );

  return (
    <Col lg={20} xl={13} xxl={10} className={classes.conversionFactorCard}>
      <div className={classes.cardTitle}>Conversion Factor</div>
      {rateType === "Tier" && (
        <div className={classes.cardSubTitle}>
          Set the value of conversion factor for specific Tiers{" "}
        </div>
      )}

      {rateType === "Flat" ? (
        <ConversionFactorItem />
      ) : (
        <div className={classes.tiersList}>
          {[
            "newMembers",
            "silverMembers",
            "goldMembers",
            "platinumMembers",
          ].map((dataIndex: string) => (
            <>
              <TierItem
                key={dataIndex}
                title={dataIndex.split("M").join(" M")}
                dataIndex={dataIndex}
              />
              <Divider />
            </>
          ))}
        </div>
      )}

      <div style={{textAlign: "right"}}>
        {pageData.valuesUpdated ? (
          <Button
            style={{backgroundColor: "rgb(34, 94, 119)", color: "white"}}
            disabled={!pageData['valuesUpdated']}
            onClick={saveCFValues}
          >
            Save
          </Button>
        ) : ""}
      </div>

      <div className={classes.bottomText}>
        * Conversion factor is the percentage of the final order value that is
        taken as loyalty points <br />
        e.g. a 1% conversion factor means that the customer gets 1 loyalty point
        for every ₹100 spent at the store
      </div>
    </Col>
  );
}

export default ConversionFactorCard;
