import {
  LeftOutlined,
  ContactsOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Button } from "antd";
import CustomerSummaryTableCard from "components/cards/CustomerSummaryTableCard";
import LoyaltyPointsCard from "components/cards/LoyaltyPointsCard";
import ProgressBarListCard from "components/cards/ProgressBarListCard";
import OrderItemsModal from "components/modals/OrderItemsModal";
import { RetailerDataType } from "components/pages/retailerDashboard";
import { getCustomerPurchases } from "components/pages/retailerDashboard/APIs/getRetailStoreCustomers";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { createUseStyles } from "react-jss";
import { Link } from "react-router-dom";
import CustomerList from "./CustomerList";

interface CustomerDetailsProps {
  retailerData: RetailerDataType;
}

const frequentlyBoughtItemList = [
  // label : item name
  // count : sold items count,
  // value : percentage
  {
    label: "Disprin",
    count: 100,
    value: 50,
  },
  {
    label: "Horlics",
    count: 43,
    value: 45,
  },
  {
    label: "Strepcils",
    count: 34,
    value: 35,
  },
  {
    label: "Disprin",
    count: 100,
    value: 50,
  },
  {
    label: "Horlicks",
    count: 43,
    value: 40,
  },
];

export default function CustomerDetails({
  retailerData,
}: CustomerDetailsProps) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [pastPurchases, setPastPurchases] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState<any>({});
  const [selectedCustomerUserId, setSelectedCustomerUserId] = useState(0);

  const [modalVisible, setModalVisible] = useState(false);
  const [modalOrderId, setModalOrderId] = useState("");
  const [modalInvoiceNumber, setModalInvoiceNumber] = useState("");

  const handleSelectedCustomerChange = (customer: any) => {
    setSelectedCustomer(customer);
    setSelectedCustomerUserId(customer?.user_id);
  };

  const handleBackClick = () => {
    setSelectedCustomer({});
    setSelectedCustomerUserId(0);
  };

  const onOrderIdClick = (orderId: any, invoiceNumber: any) => {
    setModalOrderId(orderId);
    setModalInvoiceNumber(invoiceNumber);
    setModalVisible(true);
  };

  const format = (date: any) => {
    date = new Date(date);

    var day = ("0" + date.getDate()).slice(-2);
    var month = ("0" + (date.getMonth() + 1)).slice(-2);
    var year = date.getFullYear();

    return year + "-" + month + "-" + day;
  };

  const fetchCustomerData = async () => {
    const items = await getCustomerPurchases(
      retailerData.ID,
      selectedCustomerUserId
    );

    // for (let i in items) {
    //   let t = format(items[i].date);
    //   items[i].date = t;
    // }

    setPastPurchases(items);
    setLoading(false);
  };

  useEffect(() => {
    if (loading) {
      fetchCustomerData();
    }
  }, [loading]);

  useEffect(() => {
    if (!!selectedCustomerUserId) {
      setLoading(true);
    }
  }, [selectedCustomerUserId]);

  const customColumns = [
    {
      title: "#",
      dataIndex: "key",
      align: "center",
      width: "30px",
    },
    {
      title: "Invoice No.",
      dataIndex: "invoice_number",
      width: "90px",
      render: (val: any) => val ?? " - ",
    },
    {
      title: "Date/Time",
      dataIndex: "date",
      width: "140px",
      render: (date: any) => moment(date).format("DD MMM YY, hh:mm A"),
    },
    {
      title: "Items",
      dataIndex: "invoice_number",
      width: "140px",
      render: (invoice_number: any, record: any) => (
        <p
          style={{
            fontFamily: "Roboto",
            display: "contents",
            cursor: "pointer",
            fontWeight: 500,
          }}
          onClick={() =>
            onOrderIdClick(record.order_id ?? record.reorder_id, invoice_number)
          }
        >
          Show details
        </p>
      ),
    },
  ];

  if (!!!selectedCustomerUserId) {
    return (
      <CustomerList
        retailerData={retailerData}
        onSelectedCustomerChange={handleSelectedCustomerChange}
      />
    );
  }

  return (
    <div className={classes.customerDetails}>
      <div className={classes.pageHeader}>
        <Button type="text" icon={<LeftOutlined />} onClick={handleBackClick} />
        <div className={classes.customerName}>{selectedCustomer.name}</div>
        <div className={classes.headerActions}>
          <Link
            to={`/retailer/dashboard/customer-details/${selectedCustomer.user_id}/place-reorder`}
          >
            <Button shape="round" type="primary">
              Place Reorder
            </Button>
          </Link>
        </div>
      </div>
      <CustomerSummaryTableCard
        size="small"
        items={pastPurchases}
        total={selectedCustomer.sum}
        customColumns={customColumns}
      />
      <OrderItemsModal
        small
        orderId={modalOrderId}
        order={pastPurchases.find(
          (i: any) =>
            i?.order_id === modalOrderId || i.reorder_id === modalOrderId
        )}
        invoiceNumber={modalInvoiceNumber}
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false);
          setModalOrderId("");
        }}
      />
      <br />
      {/* <LoyaltyPointsCard
        customer={{ ...selectedCustomer, retail_store_id: retailerData.ID }}
      /> */}
      <br />
      {/* <ProgressBarListCard
        cardTitle="Frequently Bought Items"
        list={frequentlyBoughtItemList}
      /> */}
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  customerDetails: {
    height: "calc(100vh - 130px)",
    overflow: "auto",
    padding: "0rem 1rem 3rem",
    background: "#fbfbfb",
  },
  pageHeader: {
    height: 80,
    padding: "0 0.5rem",
    display: "flex",
    alignItems: "center",
  },
  customerName: {
    fontSize: 18,
    marginLeft: 10,
    fontWeight: 500,
  },
  headerActions: {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",

    "& .anticon": {
      fontSize: 20,
    },
  },
}));
