import React from "react";
import { Input, Tabs } from "antd";
import Search from "antd/lib/input/Search";
import { useStyles } from "./DashboardListCard.style";
import { EmptyStarIcon } from "components/customIcons";
import RadioSwitch from "components/radioSwitch/RadioSwitch";
import { SearchOutlined, StarFilled } from "@ant-design/icons";

const { TabPane } = Tabs;

type DashboardListCardProps = {
  reorderData: Array<Object>;
  priorityOrderData: Array<Object>;
  selectedOrderIndex: number;
  setReorderData: any;
  category: string;
  toggleCustomerPriority: any;
  setCategory: (category: string) => void;
  onSearch: (search: string) => void;
  setPageNumber: (pageNumber: any) => void;
  endOfList: boolean;
  setOrderStatus: any;
  orderStatus: any;
};

type OrderItemListProps = {
  order: any;
  index: number;
};

const switchButtonOptions = [
  { label: "All", value: "All" },
  { label: "Priority", value: "Priority" },
];

function DashboardListCard({
  onSearch,
  category,
  reorderData,
  priorityOrderData,
  selectedOrderIndex,
  setReorderData,
  toggleCustomerPriority,
  endOfList,
  setCategory,
  setPageNumber,
  setOrderStatus,
  orderStatus,
}: DashboardListCardProps) {
  const classes = useStyles();

  const changeOrderCategory = (e: any) => {
    setCategory(e.target.value);
  };

  const changeSelectedItem = (index: number) => {
    if (selectedOrderIndex !== index) {
      setReorderData({
        reorders: reorderData,
        priorityOrders: priorityOrderData,
        selectedOrderIndex: index,
        loading: false,
      });
    }
  };

  const handleScroll = async (e: any) => {
    //console.log("test")
    if (endOfList) {
      return;
    }
    //check if reached bottom
    let element = e.target;
    //console.log(element.scrollHeight, element.scrollTop, element.clientHeight);
    if (
      element.scrollHeight - Math.round(element.scrollTop) ===
      element.clientHeight
    ) {
      setPageNumber((pageNumber: any) => pageNumber + 1);
      //console.log("eof");
    }
  };

  function OrderListItem({ order, index }: OrderItemListProps) {
    const orderItemStyles =
      index === selectedOrderIndex
        ? `${classes.orderItem} ${classes.selectedItem}`
        : classes.orderItem;

    return (
      <div
        key={order.id}
        className={orderItemStyles}
        onClick={() => changeSelectedItem(index)}
      >
        <div className={classes.OrderItemLeft}>
          {order.priority === true ? (
            <StarFilled
              onClick={async () => await toggleCustomerPriority(order.user_id)}
            />
          ) : (
            <EmptyStarIcon
              onClick={async () => await toggleCustomerPriority(order.user_id)}
            />
          )}
          <div>
            <div className={classes.orderID}>#{order.id}</div>
            <div className={classes.customerName}>{order.user_name}</div>
          </div>
        </div>
        <div className={classes.orderAmount}>
          ₹ {parseFloat(order.grandtotal).toFixed(2)}
        </div>
      </div>
    );
  }

  return (
    <div className={classes.itemsListCard}>
      <Tabs
        centered
        defaultActiveKey="1"
        // className={classes.tabs}
        onChange={setOrderStatus}
      >
        <TabPane tab="Incoming" key="incoming"></TabPane>
        <TabPane tab="Delivered" key="delivered"></TabPane>
        <TabPane tab="Reconciled" key="reconciled"></TabPane>
        <TabPane tab="Settled" key="settled"></TabPane>
      </Tabs>
      <div className={classes.listSettings}>
        <RadioSwitch
          size={"small"}
          options={switchButtonOptions}
          value={category}
          onChange={changeOrderCategory}
        />
      </div>
      <div className={classes.searchWrapper}>
        <Input
          size="large"
          placeholder="Search..."
          className={classes.searchInput}
          onChange={(e) => onSearch(e.target.value)}
          suffix={<SearchOutlined />}
        />
      </div>

      <div className={classes.itemsList} onScroll={handleScroll}>
        {reorderData.length || priorityOrderData?.length ? (
          category === "All" ? (
            reorderData.map((order, index) => (
              <OrderListItem key={index} order={order} index={index} />
            ))
          ) : priorityOrderData?.length > 0 ? (
            priorityOrderData.map((priorityOrder, index) => (
              <OrderListItem key={index} order={priorityOrder} index={index} />
            ))
          ) : (
            <div className={classes.centreText}>No Priority Orders </div>
          )
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}

export default DashboardListCard;
