import React, { Suspense, lazy } from 'react'
import Spin from 'antd/es/spin'
import { Route, useRouteMatch } from 'react-router-dom'

const AdminVerificationsPage = lazy(() => import('components/pages/adminDashboardPages/AdminVerificationsPage'))
const AdminInvoiceMappingPage = lazy(() => import('components/pages/adminDashboardPages/AdminInvoiceMappingPage'))
const AdminDashboardPage = lazy(() => import('components/pages/adminDashboardPages/AdminDashboardPage'))
const AdminRetailStoresPage = lazy(() => import('components/pages/adminDashboardPages/AdminRetailStoresPage'))
const AdminRetailStorePage = lazy(() => import('components/pages/adminDashboardPages/AdminRetailStorePage'))

function IndexPage() {
  const { path } = useRouteMatch('/admin/dashboard') || {}

  return (
    <Suspense fallback={<Spin size="large" />}>
      <Route path={path} exact component={AdminDashboardPage} />
      <Route path={`${path}/retails`} exact component={AdminRetailStoresPage} />
      <Route path={`${path}/retails/:retailStoreId`} exact component={AdminRetailStorePage} />
      <Route
        path={`${path}/retails/:retailStoreId/invoice-mapping`}
        component={AdminInvoiceMappingPage}
      />

      <Route path={`${path}/verifications`} exact component={AdminVerificationsPage} />
    </Suspense>
  )
}

export default IndexPage
