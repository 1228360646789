import { Radio } from "antd";
import React from "react";
import { createUseStyles } from "react-jss";

function RadioSwitch(props: any) {
  const classes = useStyles();

  return (
    <div className={classes.radioSwitch}>
      <Radio.Group {...props} optionType="button" buttonStyle="solid" />
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  radioSwitch: {
    width: "100%",

    "& .ant-radio-group-solid": {
      overflow: "hidden",
      padding: 0.9,
      borderRadius: "2rem",
      backgroundColor: colors.light400,
      boxShadow:
        "-1px -1px 4px rgba(0, 0, 0, 0.25), inset 1px 1px 4px rgba(0, 0, 0, 0.25)",
    },
    "& .ant-radio-button-wrapper": {
      width: 80,
      fontSize: 12,
      textAlign: "center",
      backgroundColor: "transparent",
      overflow: "hidden",
      borderRadius: "2rem",
      fontWeight: 600,
    },
    "& .ant-radio-button-wrapper-checked": {
      backgroundColor: `${colors.light100} !important`,
      color: `${colors.dark400} !important`,
      boxShadow:
        "-1px -1px 4px rgba(0, 0, 0, 0.25), 1px 1px 4px rgba(0, 0, 0, 0.25)",
    },

    "& *": {
      border: "none",
    },
  },
}));

export default RadioSwitch;
