import React from "react";
import EmptyCardIllustration from "assets/svgs/empty-cart.svg";
import Button from "antd/es/button";
import { ArrowLeftOutlined } from "@ant-design/icons";

export const EmptyCart = ({ onButtonClick, style }: any) => {
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        padding: "2rem",
        fontWeight: 700,
        color: "#7f5e5e",
        alignItems: "center",
        background: "#f2f2f2",
        flexDirection: "column",
        justifyContent: "center",
        ...style,
      }}
    >
      <img src={EmptyCardIllustration} style={{ width: "95%" }} />
      <p style={{ marginTop: "2.5rem", fontSize: 15 }}>Cart is Empty...</p>
      <Button
        type="primary"
        size="small"
        icon={<ArrowLeftOutlined />}
        style={{ fontSize: 9 }}
        onClick={onButtonClick}
      >
        Go Back
      </Button>
    </div>
  );
};
