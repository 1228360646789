import Button from "antd/es/button";
import { Col, Row } from "antd/es/grid";
import Modal from "antd/es/modal/Modal";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { getSession, Session } from "client/reactives/session";

type CancelOrderModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  retailStoreId: any;
  onOk: any;
};

function CancelOrderModal({
  visible,
  setVisible,
  retailStoreId,
  onOk,
}: CancelOrderModalProps) {
  const classes = useStyles();
  const { userId } = getSession() as Session;
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState(
    "Are you sure you want to cancel this order?"
  );

  const handleOk = () => {
    setModalText("Canceling order...");
    setConfirmLoading(true);
    onOk(() => {
      setVisible(false);
      setConfirmLoading(false);
    });
  };

  const handleCancel = () => setVisible(false);

  return (
    <Modal
      title={false}
      closable={false}
      footer={false}
      centered
      width={260}
      visible={visible}
      className={classes.cancelModal}
    >
      <p className={classes.modalText}>{modalText}</p>
      <Row justify="space-around" align="middle">
        <Col>
          <a
            id="cancel-active-order-button"
            data-store={retailStoreId}
            data-user={userId}
          >
            <Button
              danger
              size="small"
              loading={confirmLoading}
              type="primary"
              onClick={handleOk}
              className={classes.footerBtn}
            >
              Ok
            </Button>
          </a>
        </Col>
        <Col>
          <Button
            size="small"
            type="primary"
            onClick={handleCancel}
            className={classes.footerBtn}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  cancelModal: {
    "& .ant-modal-content": {
      borderRadius: "5px !important",
    },
  },
  modalText: {
    fontSize: 10.5,
    fontWeight: 500,
    color: "#000",
    padding: "1rem 0",
  },
  footerBtn: { fontSize: 10, width: 70, borderRadius: 5 },
}));

export default CancelOrderModal;
