import React, { useState } from "react";
import { FormProvider } from "react-hook-form";
import RaseetLogo from "../../assets/logos/retailer-with-name.png";
import LoginIllustration from "../../assets/svgs/raseet-login-illustration.svg";
import { createUseStyles } from "react-jss";
import {
  LockOutlined,
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
} from "@ant-design/icons";
import InputField from "components/dataEntry/InputField";
import FormWrapper from "components/dataEntry/FormWrapper";

import Field from "components/field/Field";
import useForm from "hooks/useForm";
import User from "models/User";
import type { FormProps } from "hooks/useForm";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

type RegisterFormProps = FormProps<any>;

function RegisterForm(props: RegisterFormProps) {
  const classes = useStyles();
  const isLG = useBreakpoint().lg;
  const [otpInput, setOtpInput] = useState("");
  const [otpSent, setOtpSent] = useState(true);
  const [otpVerified, setOtpVerified] = useState(true);
  const { isSubmitting, methods, submitHandler } = useForm<any>({
    ...props,
    resolver: User.registerUser(),
  });

  const handleOtpChange = (e: any) => setOtpInput(e.target.value);

  const handleFormButtonClick = () => {
    // if (otpSent && !otpVerified) {
    //   // verifiy OTP
    //   let otp = "123456";
    //   console.log(otpInput, "===", otpInput == otp);
    //   if (otpInput == otp) setOtpVerified(true);
    // } else {
    //   // send OTP
    //   setOtpSent(true);
    // }
  };

  return (
    <div className={classes.registerForm}>
      <img
        // hidden={!isLG}
        className={classes.logo}
        src={RaseetLogo}
        alt="Raseet Logo"
      />
      <FormProvider {...methods}>
        <FormWrapper formTitle="Register On Raseet" onSubmit={submitHandler}>
          <Field as="input" name="isRetailer" type="hidden" />
          <Field as="input" name="token" type="hidden" />
          <Field as="input" name="code" type="hidden" />

          <Field
            as={InputField}
            name="name"
            placeholder="Full Name"
            prefix={<UserOutlined />}
            onChange={(e: any) => console.log(e.target.value)}
          />
          <Field
            as={InputField}
            name="mobileNumber"
            type="number"
            placeholder="Mobile Number"
            prefix={<PhoneOutlined rotate={90} />}
          />

          {otpVerified ? (
            <>
              <Field
                as={InputField}
                name="password"
                type="password"
                placeholder="Enter Your Password"
                prefix={<LockOutlined />}
              />
              <Field
                as={InputField}
                name="confirmPassword"
                type="password"
                placeholder="Confirm Password"
                prefix={<LockOutlined />}
              />
            </>
          ) : otpSent ? (
            <>
              <InputField
                name="otp"
                type="password"
                value={otpInput}
                onChange={handleOtpChange}
                placeholder="Enter The OTP"
                prefix={<MailOutlined />}
              />
              {otpInput.length > 4 ? (
                <p className="warning">OTP is of 4 digits</p>
              ) : null}
              <p className={classes.resendOTP}>Resend OTP</p>
            </>
          ) : (
            <p className={classes.formText}>
              An OTP would be sent on this number
            </p>
          )}

          <button
            type={otpVerified ? "submit" : "button"}
            className={classes.submitBtn}
            onClick={handleFormButtonClick}
          >
            {otpVerified ? "Submit" : otpSent ? "Verify OTP" : "Send OTP"}
          </button>
        </FormWrapper>
      </FormProvider>

      <div className={classes.docsText}>
        By registering with Raseet, you agree with our
        <br />
        <a
          href="https://raseet-media.s3.ap-south-1.amazonaws.com/otherFiles/Terms%20%26%20Conditions.pdf"
          target="_blank"
        >
          Terms & Conditions
        </a>
        {" and "}
        <a
          href="https://raseet-media.s3.ap-south-1.amazonaws.com/otherFiles/Privacy%20Policy.pdf"
          target="_blank"
        >
          Privacy Policy
        </a>
      </div>

      <img
        hidden={!isLG}
        className={classes.illustration}
        src={LoginIllustration}
        alt="Raseet Illustration"
      />
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  // Register Page Right Side
  registerForm: {
    minHeight: "100vh",
    padding: "2rem 2.5rem",
    backgroundColor: colors.light100,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    position: "relative",

    "@media (min-width: 1280px)": {
      padding: "2.5rem 3rem",
    },
  },

  illustration: {
    width: "20rem",
    left: "-18.5rem",
    bottom: "0",
    position: "absolute",
    zIndex: "10",

    "@media (min-width: 1280px)": {
      width: "23rem",
    },
  },
  logo: {
    width: "15rem",
    margin: "2rem auto",
  },
  resendOTP: {
    width: "fit-content",
    marginLeft: "auto",
    color: colors.primary200,
    fontWeight: 600,
    cursor: "pointer",
  },
  warning: {
    color: "red",
    fontWeight: 600,
    marginLeft: 15,
  },
  formText: {
    color: colors.dark400,
  },
  submitBtn: {
    width: "100%",
    minHeight: "2.5rem",
    color: colors.light100,
    backgroundColor: colors.primary200,
    borderRadius: "0.6rem",
    lineHeight: "1.3rem",
    fontWeight: 600,
    border: "none",
    outline: "none",

    "&:hover": {
      backgroundColor: "#12485f",
    },
  },

  "@media (max-width:768px)": {
    registerForm: {
      padding: "2rem 1rem",
    },
    logo: {
      margin: "0 auto",
    },
  },

  docsText: {
    fontSize: 12,
    padding: [5, 20],
    textAlign: "center",
  },
}));

export default RegisterForm;
