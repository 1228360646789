import Button from "antd/es/button";
import Input from "antd/es/input";
import React, { useEffect } from "react";
import { FormProvider } from "react-hook-form";

import Field from "components/field/Field";
import FormBox from "components/FormBox";
import FormGroup from "components/FormGroup";
import useForm from "hooks/useForm";
import User from "models/User";
import type { FormProps } from "hooks/useForm";
import type { RequestMobileNumberConfirmationInput } from "generated/schema";
import { createUseStyles } from "react-jss";
import hideNumberInputArrows from "components/style/hideNumberInputArrows";

type ConfirmMobileFormProps = FormProps<any> & {
  requestMobileNumberConfirmation: (
    values: RequestMobileNumberConfirmationInput
  ) => void;
  title?: string;
  withoutName?: boolean;
};

function ConfirmMobileForm({
  requestMobileNumberConfirmation,
  title = "Register to Raseet",
  withoutName = false,
  ...rest
}: ConfirmMobileFormProps) {
  const { isSubmitting, methods, submitHandler } = useForm<any>({
    ...rest,
    resolver: User.verifyMobile(withoutName),
  });

  const mobileNumber = methods.watch("mobileNumber");

  const onResendClick = () => {
    requestMobileNumberConfirmation({ mobileNumber });
  };

  const classes = useStyles();

  return (
    <FormBox showBackButton title={title}>
      <FormProvider {...methods}>
        <form onSubmit={submitHandler} className={classes.confirmMobileForm}>
          {!withoutName && <Field as={Input} name="name" label="Full name" />}
          <Field
            as={Input}
            disabled
            name="mobileNumber"
            type="number"
            label="Mobile Number"
          />

          <Field
            as={Input}
            name="otp"
            type="number"
            maxLength={6}
            minLength={6}
            label="Please enter OTP"
          />
          <FormGroup align="center">
            <Button type="text" onClick={onResendClick}>
              Resend OTP
            </Button>
            <Button type="primary" htmlType="submit" loading={isSubmitting}>
              Submit
            </Button>
          </FormGroup>
        </form>
      </FormProvider>
    </FormBox>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  confirmMobileForm: {
    ...hideNumberInputArrows,
  },
}));

export default ConfirmMobileForm;
