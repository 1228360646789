import Button from "antd/es/button";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { message, Form, Input, Statistic, Row, Col } from "antd";
import { generateLoginOtp, OtpLogin } from "api/api";
import { PhoneOutlined, LockOutlined } from "@ant-design/icons";
import gtag from "components/Tracking/gtag";
import localforage from "localforage";
const { Countdown } = Statistic;
const useStyles = createUseStyles(({ colors }: Theme) => ({
  CountDown: {
    width: "100%",
    "& .ant-statistic-content-value": {
      fontSize: "0.55em",
      marginLeft: "1em",
    },
    "& .ant-statistic-content": {
      display: "flex",
      justifyContent: "flex-start",
    },
  },
}));

type LoginPageProps = {
  isAdmin?: boolean;
  defaultValues?: any;
  setMobileNumber: (val: string) => any;
};

function LoginWithOtp({
  isAdmin,
  defaultValues,
  setMobileNumber,
}: LoginPageProps) {
  const [otpBoxVisible, setOtpBoxVisible] = useState(false);
  const [resendVisible, setResendVisible] = useState(false);
  const [userMobile, setUserMobile] = useState(
    defaultValues?.mobileNumber ?? ""
  );
  const [deadline, setDeadline] = useState(Date.now());
  const classes = useStyles();

  const generateOtp = async () => {
    try {
      const res = await generateLoginOtp({
        mobile_number: userMobile,
        otp_type: "'Login'",
      });
      if (res.error) {
        message.error(res.error);
        return;
      }
    } catch (e: any) {
      message.error(e);
      return;
    }
    setOtpBoxVisible(true);
    setDeadline(Date.now() + 30 * 1000);
  };

  const onFinishCounter = async () => {
    setResendVisible(true);
  };

  const submitHandler = async (Values: any) => {
    // console.log(Values);
    try {
      const res = await OtpLogin({
        mobile_number: Values.mobileNumber,
        otp: Values.otp,
        otp_type: "'Login'",
      });

      if (res.error) {
        message.error(res.error);
      } else {
        gtag("event", "login_via_otp", {
          type: "customer",
        });
        localforage.setItem("userType", "Customer");
      }
    } catch (e: any) {
      message.error(e);
    }
  };

  return (
    <>
      <Form
        name="nest-messages"
        initialValues={defaultValues}
        onValuesChange={(values) => {
          setUserMobile("" + values.mobileNumber);
          setMobileNumber("" + values.mobileNumber);
        }}
        onFinish={submitHandler}
      >
        <Form.Item name={"mobileNumber"}>
          <Input
            prefix={<PhoneOutlined rotate={90} />}
            disabled={otpBoxVisible}
            placeholder="Mobile Number"
          />
        </Form.Item>
        <Form.Item name={"otp"} hidden={!otpBoxVisible}>
          <Row align="middle" justify="space-between">
            <Col span={24}>
              <Input
                key="otp"
                prefix={<LockOutlined />}
                placeholder="OTP"
                style={{ width: "100%" }}
              />
            </Col>
          </Row>
        </Form.Item>
        <Row
          hidden={!otpBoxVisible}
          align="middle"
          justify="space-between"
          style={{ marginTop: "1em" }}
        >
          <Col
            span={7}
            style={{
              width: "100%",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <Countdown
              value={deadline}
              format="mm:ss"
              className={classes.CountDown}
              onFinish={onFinishCounter}
            />
          </Col>
          <Col span={8}>
            <Button
              style={
                resendVisible
                  ? { color: "#2A9D8F", fontSize: "0.9em" }
                  : { fontSize: "0.9em" }
              }
              type="link"
              onClick={generateOtp}
              disabled={!resendVisible}
              //   ghost
            >
              Resend OTP
            </Button>
          </Col>
        </Row>
        {/* </Form.Item> */}

        <Form.Item hidden={otpBoxVisible}>
          <Button
            style={{ width: "100%" }}
            type="primary"
            disabled={userMobile.length !== 10}
            onClick={generateOtp}
          >
            Generate OTP
          </Button>
        </Form.Item>
        <Form.Item hidden={!otpBoxVisible}>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!otpBoxVisible}
            style={{ width: "100%" }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}

export default LoginWithOtp;
