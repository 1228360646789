import {
  CloseOutlined,
  EyeTwoTone,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import Layout from "antd/es/layout/layout";
import { getMarketingCampaigns } from "api/retailerApi";
import Flex from "components/layouts/Flex";
import Loader from "components/loader/Loader";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { Link } from "react-router-dom";

type MarketingCampaignPageProps = {
  retailerData: {
    ID: number;
    Name: string;
  };
};

export default function MarketingCampaignPage({
  retailerData,
}: MarketingCampaignPageProps) {
  const classes = useStyle();
  const [loading, setLoading] = useState(true);
  const [marketingCampaigns, setMarketingCampaigns] = useState([]);
  const [viewCampaign, setViewCampaign] = useState<{
    visible: boolean;
    data: any;
  }>({
    visible: false,
    data: null,
  });

  const TableColumns: any[] = [
    {
      title: "#",
      render: (a: any, b: any, index: number) => index + 1,
      width: "50px",
    },
    {
      title: "Campaign Name",
      dataIndex: "campaign_name",
      key: "campaign_name",
      width: "160px",
    },
    {
      title: "Campaign Type",
      dataIndex: "campaign_type",
      key: "campaign_type",
      width: "150px",
    },
    {
      title: "Campaign Medium",
      dataIndex: "campaign_medium",
      key: "campaign_medium",
      width: "170px",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "120px",
    },
    {
      title: "Campaign Cost",
      dataIndex: "campaign_cost",
      key: "campaign_cost",
      width: "170px",
      render: (val: any) => val ?? " - ",
    },
    {
      title: "Campaign Date",
      dataIndex: "campaign_time",
      key: "campaign_time",
      width: "140px",
      render: (val: any) => moment(val).format("Do MMM, YYYY"),
    },
    {
      title: "Total Messages Sent",
      dataIndex: "total_messages_sent",
      key: "total_messages_sent",
      width: "180px",
      render: (val: any) => val ?? " - ",
    },
    {
      title: "Actions",
      key: "actions",
      width: "120px",
      render: (val: any, row: any) => (
        <Button
          type="text"
          icon={<EyeTwoTone />}
          onClick={handleViewDetailsClick(row)}
        />
      ),
    },
  ];

  const handleViewDetailsClick = (data: any) => () => {
    setViewCampaign({
      data,
      visible: true,
    });
  };

  const handleCloseViewDetailsClick = () => {
    setViewCampaign({
      data: null,
      visible: false,
    });
  };

  const fetchInitialData = async () => {
    let marketingCampaignsRes = await getMarketingCampaigns(retailerData.ID);

    if (marketingCampaignsRes?.data?.length) {
      setMarketingCampaigns(marketingCampaignsRes.data);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (loading) {
      fetchInitialData();
    }
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <Layout className={classes.marketingCampaignsPage}>
      <Row gutter={[20, 20]}>
        <Col span={viewCampaign.visible ? 16 : 24}>
          <Card>
            <div className={classes.tableCardHeader}>
              <Typography.Title level={4}>Marketing Campaigns</Typography.Title>

              <Link to="/retailer/dashboard/marketing-campaigns/new">
                <Button shape="round" icon={<PlusCircleOutlined />}>
                  Setup New Campaign
                </Button>
              </Link>
            </div>

            <Table
              columns={TableColumns}
              dataSource={marketingCampaigns}
              pagination={false}
              scroll={{ y: "calc(100vh - 250px)", x: true }}
              locale={{
                emptyText: "No campaigns created",
              }}
            />
          </Card>
        </Col>

        <Col span={viewCampaign.visible ? 8 : 0}>
          <Card style={{ height: "100%" }}>
            <div className={classes.tableCardHeader}>
              <Typography.Title level={4}>Campaign Details</Typography.Title>

              <Button
                type="text"
                icon={<CloseOutlined />}
                onClick={handleCloseViewDetailsClick}
              />
            </div>

            <Space direction="vertical" style={{ width: "100%" }}>
              <Flex justify="space-between">
                <Typography.Text>Campaign Name</Typography.Text>
                <Typography.Text>
                  {viewCampaign?.data?.campaign_name}
                </Typography.Text>
              </Flex>
              <Flex justify="space-between">
                <Typography.Text>Campaign Type</Typography.Text>
                <Typography.Text>
                  {viewCampaign?.data?.campaign_type}
                </Typography.Text>
              </Flex>
              <Flex justify="space-between">
                <Typography.Text>Campaign Medium</Typography.Text>
                <Typography.Text>
                  {viewCampaign?.data?.campaign_medium}
                </Typography.Text>
              </Flex>
              <Flex justify="space-between">
                <Typography.Text>Campaign Status</Typography.Text>
                <Typography.Text>{viewCampaign?.data?.status}</Typography.Text>
              </Flex>
              <Flex justify="space-between">
                <Typography.Text>Campaign Cost</Typography.Text>
                <Typography.Text>
                  {viewCampaign?.data?.campaign_cost ?? " - "}
                </Typography.Text>
              </Flex>
              <Flex justify="space-between">
                <Typography.Text>Campaign Date</Typography.Text>
                <Typography.Text>
                  {moment(viewCampaign?.data?.campaign_time).format(
                    "Do MMM, YYYY"
                  )}
                </Typography.Text>
              </Flex>

              <Divider />

              <Flex justify="space-between">
                <Typography.Text>Total Messages Sent</Typography.Text>
                <Typography.Text>
                  {viewCampaign?.data?.total_messages_sent}
                </Typography.Text>
              </Flex>
              <Flex justify="space-between">
                <Typography.Text>Total Messages Delivered</Typography.Text>
                <Typography.Text>
                  {viewCampaign?.data?.total_messages_delivered}
                </Typography.Text>
              </Flex>
              <Flex justify="space-between">
                <Typography.Text>Open Rate</Typography.Text>
                <Typography.Text>
                  {viewCampaign?.data?.open_rate}%
                </Typography.Text>
              </Flex>
              <Flex justify="space-between">
                <Typography.Text>Response Rate</Typography.Text>
                <Typography.Text>
                  {viewCampaign?.data?.response_rate}%
                </Typography.Text>
              </Flex>
            </Space>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  marketingCampaignsPage: {
    padding: 20,
    height: "100%",
  },
  tableCardHeader: {
    marginBottom: 20,

    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    "& .ant-typography": {
      marginBottom: "0 !important",
    },
  },
}));
