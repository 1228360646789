import { Card } from "antd";
import Button from "antd/es/button";
import { Col, Row } from "antd/es/grid";
import { createUseStyles } from "react-jss";
import Typography from "antd/es/typography";
import Loader from "components/loader/Loader";
import React, { useState, useEffect } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import useStyles from "./Styles/OrderDetailsView.style";
import { delReorder } from "components/cards/ActiveOrdersCard";
import CancelOrderModal from "components/modals/CancelOrderModal";
import { CompletedOrderDrawer } from "components/CompletedOrderDrawer";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";
import {
  CloseOutlined,
  RocketOutlined,
  UpOutlined,
  ShoppingOutlined,
} from "@ant-design/icons";
import {
  updateAddresses,
  getCustomer,
  getAddressesByUserID,
  getPrescriptionsById,
  getReorderItems,
  getReorderById,
} from "api/api";

type RouteParams = {
  reorderId: string;
  prescriptionId: string;
};

function OrderDetailsView() {
  const {
    params: { reorderId, prescriptionId },
  } = useRouteMatch<RouteParams>() || {};

  // -------------NEW STATES IN USE-----------------------------
  var addressProp: any;
  var reorderDetailsProp: any;

  const classes = useStyles();
  const [pageData, setPageData] = useState({
    loading: true,
    reoderItems: [],
    address: addressProp,
    reorderDetails: reorderDetailsProp,
  });
  const { goBack } = useHistory();
  // -----------------------------------------------------------

  async function fetchPageData() {
    var data = await getReorderItems(+reorderId);
    var address = await getAddressesByUserID("Customer");
    var reorderDetails = await getReorderById(reorderId);
    setPageData({
      loading: false,
      reoderItems: data,
      address: address,
      reorderDetails: reorderDetails.length > 0 ? reorderDetails[0] : [],
    });
  }

  useEffect(() => {
    if (pageData.loading) {
      fetchPageData();
    }
  }, []);

  const [visible, setVisible] = useState(false);
  const [seeMoreDrawerOpen, setSeeMoreDrawerOpen] = useState(false);

  // const [showPresc, setShowPresc] = useState(false);
  // const classes = useStyles({ showPresc });
  // const togglePrescription = () => setShowPresc(!showPresc);

  const OrderItemCard = ({ name, quantity, sub_total }: any) => (
    <Card className={classes.itemCard}>
      <div className={classes.itemCardInner}>
        <div>
          <div>{name}</div>
          <div className={classes.itemQuantity}>Qty : {quantity}</div>
        </div>
        <div className={classes.itemAmount}>
          ₹ {parseFloat(sub_total)?.toFixed(2)}
        </div>
      </div>
    </Card>
  );

  const showPresciption = (add: any) => {
    window.open(add);
  };

  const openSeeMoreDrawer = () => {
    setPageData({
      loading: false,
      reoderItems: pageData.reoderItems,
      address: pageData.address,
      reorderDetails: pageData.reorderDetails,
    });
  };

  const closeSeeMoreDrawer = () => {
    setPageData({
      loading: false,
      reoderItems: pageData.reoderItems,
      address: pageData.address,
      reorderDetails: pageData.reorderDetails,
    });
  };

  const showCancelModal = () => {
    setPageData({
      loading: false,
      reoderItems: pageData.reoderItems,
      address: pageData.address,
      reorderDetails: pageData.reorderDetails,
    });
  };

  return (
    <>
      {!pageData.loading ? (
        <div className={classes.activeOrdersPage}>
          <div className={classes.header}>
            <div className={classes.headerIcon}>
              <ArrowLeftOutlined onClick={goBack} />
            </div>
            <div className={classes.headerTitle}>Order Details</div>
          </div>
          <div className={classes.orderDetailsView}>
            <Row className={classes.deliveryAddress}>
              <Col span={3} style={{ alignSelf: "center" }}>
                <ShoppingOutlined />
              </Col>
              <Col span={18} style={{ alignSelf: "center" }}>
                <div style={{ fontSize: 12, fontWeight: 900 }}>
                  Order No : {reorderId}
                </div>
              </Col>
            </Row>
            <Row className={classes.deliveryAddress}>
              {pageData.address ? (
                <>
                  <Col span={3} style={{ alignSelf: "center" }}>
                    <RocketOutlined rotate={30} />
                  </Col>
                  <Col span={18} style={{ alignSelf: "center" }}>
                    <span style={{ fontWeight: "bold" }}>Delivering To :</span>
                    {`${pageData.address.landmark}, ${pageData.address.line1}, ${pageData.address.city} , ${pageData.address.pincode}, ${pageData.address.state}`}
                  </Col>
                </>
              ) : (
                ""
              )}
            </Row>
            <div className={classes.orderItems}>
              {pageData.reoderItems.map((item: any) => (
                <OrderItemCard {...item} />
              ))}
            </div>
            {/* {order?.prescriptions?.length > 0 ? (
            <div className={classes.orderPrescriptions}>
              <div className={classes.prescriptionsTitle} onClick={togglePrescription}>
                <span>Uploaded Prescriptions</span>
                <UpOutlined rotate={showPresc ? 180 : 0} />
              </div>
              <Row>
                {order?.prescriptions?.map((presc: any) => (  
                  <Col span={6} className={classes.prescription}>    
                      <img onClick={()=>showPresciption(presc.url)} src={presc.url} alt={presc.title} />  
                  </Col>
                ))}
              </Row>
            </div>
          ):(
            <div className={classes.orderPrescriptions}>
              <div className={classes.prescriptionsMidTitle}><span>No prescription uploaded for this order.</span></div>
            </div>
          )} */}
            <div className={classes.footer}>
              <Row
                justify="space-around"
                align="middle"
                style={{ height: "100%" }}
              >
                <Col span={6}>
                  <div style={{ fontSize: 12 }}>
                    <b>Total</b>
                  </div>
                  {/* <div
                  style={{ fontSize: 10, color: "#979797" }}
                  onClick={openSeeMoreDrawer}
                >
                  See more &gt;
                </div> */}
                </Col>
                <Col span={7}>
                  <div className={classes.footerAmount}>
                    ₹ {(+pageData.reorderDetails.grandtotal).toFixed(2)}
                  </div>
                </Col>
                <Col span={11}>
                  {/* <Button
                  type="primary"
                  danger
                  block
                  onClick={showCancelModal}
                  style={{ fontSize: 12 }}
                >
                  Cancel order
                </Button>
                <CancelOrderModal
                  visible={visible}
                  setVisible={setVisible}
                  onOk={delReorder}
                /> */}
                </Col>
              </Row>
            </div>
          </div>
          <CompletedOrderDrawer
            onClose={closeSeeMoreDrawer}
            visibility={seeMoreDrawerOpen}
            clicked={delReorder}
            totalCost={pageData.reorderDetails.grandtotal}
            data={pageData.reorderDetails}
          />
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default OrderDetailsView;
