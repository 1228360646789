import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import React from "react";
import { createUseStyles } from "react-jss";

interface SearchBoxProps {
  onSearch: (value: string) => void;
  suffix?: React.ReactNode;
}

export default function SearchBox({ onSearch, suffix }: SearchBoxProps) {
  const classes = useStyles();

  return (
    <div className={classes.searchBox}>
      <SearchOutlined />
      <Input
        placeholder="Type To Search..."
        onChange={(e) => onSearch(e.target.value)}
      />
      {suffix}
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  searchBox: {
    marginBottom: 15,
    padding: "0.3rem 1rem",
    borderRadius: 5,
    border: "1px solid #d9d9d9",
    background: colors.light100,
    display: "flex",
    alignItems: "center",

    "& .ant-input": {
      border: "none",
      boxShadow: "none !important",
      background: "transparent",
    },
  },
}));
