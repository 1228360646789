//for i=== 0 , retailer
export function retailerUpperArray(
  message: any,
  retailStoreId: any,
  datedMessageI: any
) {
  if (message.type === "text") {
    if (+message.author === retailStoreId) {
      return {
        date: datedMessageI,
        messagesArray: [
          {
            type: message.type,
            content: message.body,
            time: message.dateCreated.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            }),
            action: "sent",
            readStatus: true,
            message: message,
          },
        ],
      };
    } else {
      return {
        date: datedMessageI,
        messagesArray: [
          {
            type: message.type,
            content: message.body,
            time: message.dateCreated.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            }),
            action: "received",
            readStatus: true,
            message: message,
          },
        ],
      };
    }
  } else {
    if (+message.author === retailStoreId) {
      return {
        date: datedMessageI,
        messagesArray: [
          {
            type: message.type,
            content: {
              docTitle: message.media.filename,
            },
            time: message.dateCreated.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            }),
            action: "sent",
            readStatus: true,
            message: message,
          },
        ],
      };
    } else {
      return {
        date: datedMessageI,
        messagesArray: [
          {
            type: message.type,
            content: {
              docTitle: message.media.filename,
            },
            time: message.dateCreated.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            }),
            action: "received",
            readStatus: true,
            message: message,
          },
        ],
      };
    }
  }
}

//for i > 0 , retailer; datedMessage1 = datedMessages[i-1], datedMessage2 = datedMessages[i]
export function retailerMessagesArray(message: any, retailStoreId: any) {
  if (message.type === "text") {
    if (+message.author === retailStoreId) {
      return {
        type: message.type,
        content: message.body,
        time: message.dateCreated.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
        action: "sent",
        readStatus: true,
        message: message,
      };
    } else {
      return {
        type: message.type,
        content: message.body,
        time: message.dateCreated.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
        action: "received",
        readStatus: true,
        message: message,
      };
    }
  } else {
    if (+message.author === retailStoreId) {
      return {
        type: message.type,
        content: {
          docTitle: message.media.filename,
        },
        time: message.dateCreated.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
        action: "sent",
        readStatus: true,
        message: message,
      };
    } else {
      return {
        type: message.type,
        content: {
          docTitle: message.media.filename,
        },
        time: message.dateCreated.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
        action: "received",
        readStatus: true,
        message: message,
      };
    }
  }
}

export async function customerUpperArray(
  message: any,
  retailStoreId: any,
  datedMessageI: any
) {
  if (message.type === "text") {
    if (message.author === retailStoreId) {
      return {
        date: datedMessageI,
        messagesArray: [
          {
            type: message.type,
            content: message.body,
            time: message.dateCreated.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            }),
            action: "received",
            readStatus: true,
            message: message,
          },
        ],
      };
    } else {
      return {
        date: datedMessageI,
        messagesArray: [
          {
            type: message.type,
            content: message.body,
            time: message.dateCreated.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            }),
            action: "sent",
            readStatus: true,
            message: message,
          },
        ],
      };
    }
  } else {
    if (message.author === retailStoreId) {
      return {
        date: datedMessageI,
        messagesArray: [
          {
            type: message.type,
            content: {
              docTitle: message.media.filename,
            },
            time: message.dateCreated.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            }),
            action: "received",
            readStatus: true,
            message: message,
          },
        ],
      };
    } else {
      return {
        date: datedMessageI,
        messagesArray: [
          {
            type: message.type,
            content: {
              docTitle: message.media.filename,
            },
            time: message.dateCreated.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            }),
            action: "sent",
            readStatus: true,
            message: message,
          },
        ],
      };
    }
  }
}

//for i > 0 , retailer; datedMessage1 = datedMessages[i-1], datedMessage2 = datedMessages[i]
export async function customerMessagesArray(message: any, retailStoreId: any) {
  if (message.type === "text") {
    if (message.author === retailStoreId) {
      return {
        type: message.type,
        content: message.body,
        time: message.dateCreated.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
        action: "received",
        readStatus: true,
        message: message,
      };
    } else {
      return {
        type: message.type,
        content: message.body,
        time: message.dateCreated.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
        action: "sent",
        readStatus: true,
        message: message,
      };
    }
  } else {
    if (message.author === retailStoreId) {
      return {
        type: message.type,
        content: {
          docTitle: message.media.filename,
        },
        time: message.dateCreated.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
        action: "received",
        readStatus: true,
        message: message,
      };
    } else {
      return {
        type: message.type,
        content: {
          docTitle: message.media.filename,
        },
        time: message.dateCreated.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
        action: "sent",
        readStatus: true,
        message: message,
      };
    }
  }
}
