import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(({ colors }: Theme) => ({
  storeName: {
    color: colors.primary,
    fontWeight: 700,
    fontSize: 12,
    lineHeight: "22px",
  },
  regdNo: {
    marginBottom: 8,
    display: "block",
    fontWeight: 500,
    fontSize: 9,
    lineHeight: "9px",
    color: "#838383",
    whiteSpace: "nowrap",
  },
  headerOptions: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",

    "& .ant-scroll-number-only": {
      fontSize: 10,
    },
  },
  icon: {
    width: "100%",
    fontSize: "1rem",
    color: colors.primary,
  },
  iconColor: {
    fontSize: 12,
    color: colors.primary,
  },
  wrapper: {
    borderBottom: ({ noBorder }) => !noBorder && [1, "solid", colors.dark400],
    margin: "18px !important",
    paddingBottom: ({ noBorder }) => !noBorder && 0,
  },
  section: {
    marginBottom: 0,
    display: "flex",
    alignItems: "center",

    "& > span:first-child": {
      marginTop: -3,
      marginRight: 6,
    },
  },
  contact: {
    lineHeight: "1.3 !important",
    padding: "0 !important",
  },
  collapse: {
    background: "rgb(244, 244, 244)",
    marginBottom: ".4rem",
    fontFamily: "'Roboto'",
    padding: "0 1.3rem",

    "& .ant-collapse-header": {
      padding: "7px 15px !important",
    },

    "& .ant-collapse-arrow": {
      padding: "5px 0 !important",
    },

    "& .ant-typography,& .ant-typography *": {
      fontSize: 10,
    },

    "& .ant-collapse-content": {
      paddingRight: 15,
    },
    "& .ant-collapse-content-box": {
      padding: "0 40px 0 15px !important",
      height: 25,
      overflow: "hidden",
    },
  },

  logoWrapper: {
    // padding: 10,
    "& img, & .ant-avatar": {
      height: 35,
      width: 35,
      objectFit: "contain",

      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
}));

export { useStyles };
