import * as yup from "yup";

import BaseModel from "./BaseModel";

class Configs extends BaseModel {
  static schema = yup.object().shape({
    shipping_charge_applicable: yup
      .string()
      .test(
        "nonzero-test",
        "Must be greater than or equal to 0",
        (value: any) => +value >= 0
      )
      .required(),
    minimum_order_amount: yup
      .string()
      .test(
        "nonzero-test",
        "Must be greater than or equal to 0",
        (value: any) => +value >= 0
      )
      .required(),
  });

  static shippingCharges<TInput>() {
    return Configs.validationResolver<TInput>([
      "shipping_charge_applicable",
      "minimum_order_amount",
    ]);
  }
}

export default Configs;
