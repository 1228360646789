import Button from "antd/es/button";
import Input from "antd/es/input";
import React, { useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { createUseStyles } from "react-jss";
import Col from "antd/es/col";
import Field from "components/field/Field";
import FormGroup from "components/FormGroup";
import Row from "components/Row";
import UploadInput from "components/inputs/UploadInput";
import useForm from "hooks/useForm";
import type { FormProps } from "hooks/useForm";
import Checkbox from "antd/es/checkbox";
import { Preview } from "components/Preview";
import customeScrollbarStyle from "components/style/CustomScrollbar";
import hideNumberInputArrows from "components/style/hideNumberInputArrows";
import { AutoComplete, message, Radio, Tabs } from "antd";
import EditRetailerUserForm from "./EditRetailerUserForm";
import { getCities, getStates } from "api/api";
import { EnvironmentFilled } from "@ant-design/icons";
import LocationPickerModal from "components/modals/LocationPickerModal";
const { TabPane } = Tabs;

type EditBusinessProfileFormProps = FormProps<any> & {
  title?: string;
  showPreview: boolean;
  setShowPreview?: any;
  onTabChange?: any;
};

const DEFAULT_TITLE = "Business set up";

function EditBusinessProfileForm({
  defaultValues,
  title = DEFAULT_TITLE,
  onTabChange,
  showPreview,
  setShowPreview,
  ...rest
}: EditBusinessProfileFormProps) {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState("#business-details");
  const [locationPickerVisble, setLocationPickerVisble] = useState(false);
  const [pageData, setPageData] = useState<any>({
    statesOptions: [],
    citiesOptions: [],
    statesArray: [],
    citiesArray: [],
    stateValue: "",
  });

  const { isSubmitting, methods, submitHandler, reset } = useForm<any>({
    defaultValues,
    ...rest,
  });
  const togglePreview = () => {
    if (
      (!methods.watch().name || methods.watch().name === "") &&
      !showPreview
    ) {
      message.info("Please enter Business Name.");
      return;
    }
    setShowPreview(!showPreview);
  };

  async function getIndianStates() {
    let statesList = await getStates("India");
    setPageData({
      ...pageData,
      statesOptions: statesList,
      statesArray: statesList.map((item: any) => item.value),
    });
  }

  async function getCitiesList(state: string) {
    let s = pageData.statesOptions.map((item: any) => item.value.toLowerCase());
    if (state && s.includes(state.toLowerCase())) {
      let citiesList = await getCities(state);
      setPageData({
        ...pageData,
        citiesOptions: citiesList,
        citiesArray: citiesList.map((item: any) => item.value),
        stateValue: state,
      });
    }
  }

  useEffect(() => {
    if (!pageData.statesOptions.length) {
      getIndianStates();
    }
  }, []);

  useEffect(() => {
    let state = methods.watch().state;
    if (state !== pageData.stateValue) {
      getCitiesList(state);
    }
  }, [methods.watch().state]);

  const onLocationChange = (data: any) => {
    reset({
      ...defaultValues,
      store_location: data.mapUrl,
      line1: data.area,
      city: data.city,
      state: data.state,
      pincode: data.pincode,
    });
  };

  return (
    <Row>
      {showPreview && (
        <Col span={12} className={classes.previewContainer}>
          <div className={classes.previewHeader}>
            <div className={classes.previewTitle}>
              Your Raseet will look like this
            </div>
          </div>
          <div className={classes.previewBox}>
            <Preview
              retailStore={methods.watch()}
              logoImg={defaultValues?.logoImg}
            />
          </div>
        </Col>
      )}
      <Col span={11} className={classes.retailStoreForm}>
        <Tabs
          defaultActiveKey={activeTab}
          onChange={(activeKey: string) => {
            onTabChange(activeKey);
            setActiveTab(activeKey);
          }}
        >
          <TabPane tab="Business Details" key="#business-details">
            <FormProvider {...methods}>
              <form className={classes.formInputs} onSubmit={submitHandler}>
                {defaultValues?.id && (
                  <Field as={Input} name="id" type="hidden" />
                )}
                <Field as={Input} name="name" label="Business name" />
                <FormGroup>
                  <div>
                    <Field
                      as={Input}
                      name="primary_mobile_number"
                      type="number"
                      label="Primary mobile number *"
                      disabled
                    />
                    <Field
                      as={Checkbox}
                      name="whatsapp_check1"
                      defaultChecked={defaultValues?.whatsapp_check1}
                    >
                      Whatsapp number
                    </Field>
                  </div>
                  <div>
                    <Field
                      as={Input}
                      name="alternate_number"
                      type="number"
                      label="Alternate number"
                    />
                    <Field
                      as={Checkbox}
                      name="whatsapp_check2"
                      defaultChecked={defaultValues?.whatsapp_check2}
                    >
                      Whatsapp number
                    </Field>
                  </div>
                </FormGroup>

                <Field
                  as={Input}
                  name="store_location"
                  label="Maps Url"
                  suffix={
                    <Button
                      type="text"
                      size="small"
                      onClick={() => setLocationPickerVisble(true)}
                      style={{ fontWeight: 600 }}
                    >
                      Open Map <EnvironmentFilled />
                    </Button>
                  }
                />
                <LocationPickerModal
                  visible={locationPickerVisble}
                  onCancel={() => setLocationPickerVisble(false)}
                  onDone={onLocationChange}
                />

                <Field as={Input} name="line1" label="Business address" />
                <FormGroup>
                  <Field
                    as={AutoComplete}
                    name="state"
                    label="State"
                    backfill
                    options={pageData.statesOptions}
                    aria-autoComplete="off"
                    filterOption={(inputValue: any, option: any) => {
                      if (!pageData.statesArray.includes(inputValue)) {
                        return (
                          option!.value
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        );
                      } else {
                        return true;
                      }
                    }}
                  />
                  <Field
                    as={AutoComplete}
                    name="city"
                    label="City"
                    backfill
                    options={pageData.citiesOptions}
                    filterOption={(inputValue: any, option: any) => {
                      if (!pageData.citiesArray.includes(inputValue)) {
                        return (
                          option!.value
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        );
                      } else {
                        return true;
                      }
                    }}
                    aria-autoComplete="off"
                  />
                </FormGroup>
                <FormGroup>
                  <Field as={Input} name="landmark" label="Locality" />
                  <Field
                    as={Input}
                    name="pincode"
                    type="number"
                    label="Pincode"
                  />
                </FormGroup>
                <Row>
                  <Col span={6}>
                    <img
                      src={
                        methods.watch().logo !== undefined
                          ? URL.createObjectURL(methods.watch().logo)
                          : defaultValues?.logoImg
                      }
                      width="80%"
                      height="100%"
                      style={{ objectFit: "contain" }}
                    />
                  </Col>
                  <Col span={16}>
                    {/* @ts-ignore */}
                    <Field
                      as={UploadInput}
                      name="logo"
                      label="Upload your business logo"
                    />
                    <p>File formats: PNG, SVG, JPEG (not more than 2 MB)</p>
                  </Col>
                </Row>

                <div style={{ marginBottom: -7 }}>
                  <Field
                    as={({ onChange, value }) => (
                      <Radio.Group
                        value={value}
                        defaultValue="GSTIN"
                        onChange={(e) => onChange(e.target.value)}
                      >
                        <Radio value="GSTIN">GSTIN</Radio>
                        <Radio value="DL No">DL No.</Radio>
                        <Radio value="SR No">SR No.</Radio>
                        <Radio value="PAN">PAN</Radio>
                      </Radio.Group>
                    )}
                    name="authenticity_document_type"
                  />
                </div>
                <Field as={Input} name="authenticity_document_value" label="" />

                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  loading={isSubmitting}
                  className={classes.submitButton}
                >
                  Save
                </Button>
              </form>
            </FormProvider>
          </TabPane>
          <TabPane tab="Personal Details" key="#personal-details">
            <EditRetailerUserForm defaultValues={defaultValues} {...rest} />
          </TabPane>
        </Tabs>
      </Col>
    </Row>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  retailStoreForm: {
    padding: "1rem 1.8rem !important",
    ...hideNumberInputArrows,
  },
  previewContainer: {
    width: 370,
    height: "100vh",
    padding: "0 2rem",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",

    "& .ant-table-body": {
      overflowY: "initial !important",
    },

    // customizing the scrollbar
    ...customeScrollbarStyle,
  },
  previewHeader: {
    width: "100%",
    padding: "2.75rem",
    height: "80px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",

    "& .anticon": {
      fontSize: "1.8rem",
    },
  },
  previewTitle: {
    flex: 1,
    fontSize: 16,
    fontWeight: 600,
    color: "#111",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  previewBox: {
    width: 350,
    height: 550,
    boxShadow: "0px 2px 8px rgb(0 0 0 / 25%)",
  },
  pageHeader: {
    width: "100%",
    height: "100px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  pageHeaderLeft: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",

    "& .anticon": {
      fontSize: "1.6rem",
    },
  },
  pageTitle: {
    fontWeight: 600,
    paddingLeft: "2rem",
    fontSize: "1.3rem",
  },
  previewButton: {
    width: "9rem",
    margin: "0 1.5rem",
    fontWeight: 600,
    borderRadius: "0.3rem",
    color: "#E76F51",
    border: "1px solid #E76F51",
  },
  formInputs: {
    paddingTop: "1.5rem",

    "& *": {
      fontSize: 12,
    },
    "& > div": {
      marginBottom: "1.5rem",
    },
    "& .ant-form-item": {
      marginBottom: "0.5rem",
    },

    // customizing the scrollbar
    ...customeScrollbarStyle,
  },
  submitButton: {
    marginBottom: "1rem",
    fontWeight: 500,
    color: colors.light100,
    background: colors.primary200,
  },
  skipThisStepButton: {
    color: colors.dark400,
    fontWeight: 600,
  },
}));

export default EditBusinessProfileForm;
