import { DownloadOutlined } from "@ant-design/icons";
import { Button, Card, Col, message, Modal, Row } from "antd";
import WhatsappIcon from "assets/whatsapp-icon.png";
import { getPromotions } from "api/api";
import { sendPromotionToRetailer } from "api/retailerApi";
import Loader from "components/loader/Loader";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";

interface PromotionsPageProps {
  retailerData: {
    ID: number;
    Name: string;
  };
}

export default function PromotionsPage({ retailerData }: PromotionsPageProps) {
  const classes = useStyle();
  const [loading, setLoading] = useState(true);
  const [promotions, setPromotions] = useState<any[]>([]);
  const [bannerViewModal, setBannerViewModal] = useState({
    visible: false,
    url: "",
  });

  const handleViewClick = (promotion: any) => () => {
    setBannerViewModal({
      ...bannerViewModal,
      visible: true,
      url: promotion.file_url,
    });
  };

  const handleDownload = (promotion: any) => async (e: any) => {
    e.stopPropagation();

    const blob = new Blob([promotion.content], { type: "image/svg+xml" });
    const file = new File([blob], "image.jpg", { type: "image/jpeg" });

    const element = document.createElement("a");
    element.href = URL.createObjectURL(file);
    element.download = "promotion.svg";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const handleShare = (promotion: any) => async (e: any) => {
    e.stopPropagation();

    await sendPromotionToRetailer({
      file_type: "promotion",
      file_key: promotion.file_key,
      retail_store_id: retailerData.ID,
    }).then((res) =>
      message.success(
        "The banner has been shared to your Whatsapp successfully!"
      )
    );
  };

  const fetchInitialData = async () => {
    let promotionsRes = await getPromotions(retailerData.ID);

    if (promotionsRes?.[0]?.data?.length)
      setPromotions(promotionsRes?.[0]?.data);
    setLoading(false);
  };

  useEffect(() => {
    if (loading) {
      fetchInitialData();
    }
  }, [loading]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={classes.promotionsPage}>
      <Row gutter={[20, 20]}>
        {promotions.map((promotion, index) => (
          <Col xs={12} lg={8} xl={6}>
            <Card
              onClick={handleViewClick(promotion)}
              cover={<img src={promotion.file_url} alt="" />}
              className={classes.bannerCard}
              actions={[
                <Button
                  type="text"
                  icon={<img src={WhatsappIcon} alt="" />}
                  onClick={handleShare(promotion)}
                />,
                <Button
                  type="text"
                  icon={<DownloadOutlined />}
                  href={promotion.file_url}
                  onClick={(e) => e.stopPropagation()}
                />,
              ]}
            />
          </Col>
        ))}
      </Row>

      <Modal
        title="Preview"
        visible={bannerViewModal.visible}
        footer={false}
        style={{ maxWidth: "90vw" }}
        onCancel={() =>
          setBannerViewModal({
            visible: false,
            url: "",
          })
        }
      >
        <img alt="" src={bannerViewModal.url} style={{ width: "100%" }} />
      </Modal>
    </div>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  promotionsPage: {
    padding: "2rem",
  },
  bannerCard: {
    // padding: [10, 10, 0],
    position: "relative",

    "& .ant-card-body": {
      padding: "0 !important",
    },
    "& .ant-card-cover": {
      height: 150,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      overflow: "hidden",
      background: "#f7f7f7",

      "& > *": {
        objectFit: "containe",
      },
    },
  },
  preveiwBannerImage: {
    width: "100%",
    objectFit: "contain",
  },
  viewBtn: {
    position: "absolute",
    top: 10,
    right: 10,
  },
  "@media (max-width: 768px)": {
    promotionsPage: {
      padding: "2rem 1.5rem",
    },
  },
}));
