import dayjs from "dayjs";
import React from "react";
import Typography from "antd/es/typography";
import { createUseStyles } from "react-jss";
import type { Order } from "generated/schema";
import DatePicker from "components/DatePicker";
import { Col, Row, Card, Empty } from "antd/es";
import WrapperHeightProvider from "components/WrapperHeightProvider";

type NoAuthBillListViewProps = {
  orders?: Array<any>;
  orderList?: Array<any>;
  setOrderList: (order: any) => void;
  setActiveKey: (key: string) => void;
  setDataSource?: (order: any) => void;
  setsubtotal?: (total: any) => void;
  setordertotal?: (ordertotal: any) => void;
  setSelectedOrder?: (order: any) => void;
};

function NoAuthBillListView({
  orders,
  setActiveKey,
  setDataSource,
  setordertotal,
  setsubtotal,
  setSelectedOrder,
  setOrderList,
  orderList,
}: NoAuthBillListViewProps) {
  const classes = useStyles();

  const onDateFilterChange = (date: any) => {
    if (date === null) {
      const filteredOrders = orders;
      setOrderList(filteredOrders);
      if (filteredOrders !== undefined) {
        setDataSource?.([
          { key: 0, item: "", quantity: 0, price: 0, total: 0 },
        ]);
        setSelectedOrder?.(filteredOrders[0]);
      }
    } else {
      const filteredOrders = orders?.filter((order) => {
        var d = dayjs(order.invoice_date).format("DD-MM-YYYY");
        return date.format("DD-MM-YYYY") === d;
      });
      setOrderList(filteredOrders);
      if (filteredOrders !== undefined) {
        setDataSource?.([
          { key: 0, item: "", quantity: 0, price: 0, total: 0 },
        ]);
        setSelectedOrder?.(filteredOrders[0]);
      }
    }
  };

  const onCardClick = (order: Order, key: number) => {
    setDataSource?.([{ key: 0, item: "", quantity: 0, price: 0, total: 0 }]);
    setSelectedOrder?.(order);
    setActiveKey("2");
    setordertotal?.(false);
    setsubtotal?.(0);
  };

  const BillListItemCard = (props: any) => (
    <Card
      hoverable
      className={classes.billListItemCard}
      onClick={props.onClick}
    >
      <div className={classes.billListItemCardWrapper}>
        <div className={classes.itemCardLeft}>
          <div className={classes.itemCardOrderNo}>
            Bill no: {props.orderNumber}
          </div>
          <div className={classes.itemCardDateTime}>{props.dateTime}</div>
        </div>
        <div className={classes.itemCardRight}>
          <div className={classes.itemCardCount}>
            Total items: {props.itemsCount}
          </div>
          <div className={classes.itemCardTotal}>₹ {props.total}</div>
        </div>
      </div>
    </Card>
  );

  return (
    <>
      <Row
        justify="space-between"
        align="middle"
        className={classes.filterSection}
      >
        <Typography.Title level={5}>Showing results by date</Typography.Title>
        {orderList && orderList?.length > 1 ? (
          <div>
            <DatePicker
              inputReadOnly
              format="DD-MM-YYYY"
              onChange={onDateFilterChange}
              className={classes.dateBox}
            />
          </div>
        ) : (
          <></>
        )}
      </Row>

      {orderList?.length === 0 && (
        <Empty
          className={classes.empty}
          description="No bills found for this date."
        />
      )}
      <WrapperHeightProvider>
        {(maxHeight) => (
          <div
            style={{
              overflowY: "scroll",
              height: maxHeight,
              paddingBottom: "4.5rem",
            }}
          >
            {orderList?.map((order, i) => {
              const { id, invoice_number, total, createdAt } = order;
              return (
                <Col key={id} span={24} style={{ padding: "5px 10px" }}>
                  <BillListItemCard
                    onClick={() => onCardClick(order, i)}
                    orderNumber={invoice_number}
                    dateTime={dayjs(createdAt).format("D MMM YYYY, h:mm A")}
                    total={total}
                    itemsCount={order?.orderItems.length}
                  />
                </Col>
              );
            })}
          </div>
        )}
      </WrapperHeightProvider>
    </>
  );
}

export default NoAuthBillListView;

const useStyles = createUseStyles(({ colors }: Theme) => ({
  wrapper: {
    padding: 30,
    overflowY: "auto",
  },
  listWrapper: {
    marginTop: 14,
  },
  empty: {
    marginTop: 50,
    width: "100%",
  },
  filterSection: {
    padding: "1rem .7rem .5rem",
    width: "100%",

    "& h5": {
      fontSize: 13,
    },
  },
  dateBox: {
    marginLeft: 5,
    width: 100,
    padding: 5,
    borderRadius: 4,
    backgroundColor: colors.primary,
    color: colors.light100,
    boxShadow:
      "inset 1px 1px 4px rgb(0 0 0 / 25%), inset -1px -1px 4px rgb(0 0 0 / 25%)",

    "& .ant-picker-input": {
      flexDirection: "row-reverse",

      "& input": {
        textAlign: "center",
        color: "inherit",
        background: "inherit",
        fontSize: 11,
      },

      "& .ant-picker-suffix": {
        fontSize: "1rem",
        color: "inherit",
        background: "inherit",
      },
    },
  },
  billListItemCard: {
    height: 60,
    boxShadow: "-1px -1px 2px rgb(0 0 0 / 15%), 1px 1px 2px rgb(0 0 0 / 15%)",

    "& .ant-card-body": {
      width: "100%",
      height: "100%",
      padding: "12px 10px",
      display: "flex",
      alignItems: "center",
    },
  },
  billListItemCardWrapper: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  itemCardLeft: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1,
  },
  itemCardRight: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  itemCardOrderNo: {
    fontSize: 11,
    fontWeight: 700,
    lineHeight: "14px",
    marginBottom: 7,
  },
  itemCardDateTime: {
    color: colors.dark400,
    fontSize: 12,
    lineHeight: "11px",
    margin: 0,
  },
  itemCardCount: {
    fontSize: 10,
    fontWeight: 700,
    lineHeight: "20px",
  },
  itemCardTotal: {
    fontSize: 12,
    fontWeight: 700,
    lineHeight: "14px",
  },
}));
