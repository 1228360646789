import { getSession, Session } from "client/reactives/session";
const url = process.env.REACT_APP_API_NODE_BASE_URL;

export async function getRetailStoreCustomers(
  store_id: number,
  page: number,
  search: string
) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/getRetailStoreCustomers?retail_store_id=${store_id}&limit=20&page=${page}&search=${search}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
}

export async function getRetailerOrders(store_id: number, user_id: number) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/getRetailerOrders?retail_store_id=${store_id}&user_id=${user_id}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
}

export async function getRetailerReorders(store_id: number, user_id: number) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/getCustomerReorders?user_id=${user_id}&retail_store_id=${user_id}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
}

export async function getCustomerPurchases(store_id: number, user_id: number) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/getCustomerPurchases?retail_store_id=${store_id}&user_id=${user_id}&limit=200`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
}
