import Tabs from "antd/es/tabs";
import Skeleton from "antd/es/skeleton";
import React, { useState } from "react";
import { Button, Modal, Space } from "antd";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";
import FooterMenu from "components/FooterMenu";
import NoAuthBillListView from "./noAuthBillListView";
import NoAuthBillDetailsView from "./noAuthBillDetailsView";
import RetailStoreDetailsView from "components/views/RetailerStoreDetailsView";

type NoAuthOrderProps = {
  retailStore: any;
  order?: any;
  retailerLogoUrl?: any;
  visible?: boolean;
  noHeaderOptions?: boolean;
};

function NoAuthOrderView({
  retailStore,
  visible,
  order,
  retailerLogoUrl,
  noHeaderOptions = false,
}: NoAuthOrderProps) {
  const classes = useStyles();
  const [activeKey, setActiveKey] = useState("2");
  const [selectedOrder, setSelectedOrder] = useState(order);
  const [popUpVisibility, setPopUpVisibility] = useState(visible);

  let history = useHistory();
  const [orderList, setOrderList] = useState([order]);
  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <RetailStoreDetailsView
          hideChatModal
          retailStore={retailStore}
          noHeaderOptions
          logoUrl={retailerLogoUrl}
        />
        <Tabs
          activeKey={activeKey}
          centered
          className={classes.tabs}
          onChange={setActiveKey}
          style={{ height: "80%" }}
        >
          <Tabs.TabPane tab={`Bills(${orderList.length})`} key="1">
            <NoAuthBillListView
              orders={order}
              setActiveKey={setActiveKey}
              setSelectedOrder={setSelectedOrder}
              orderList={orderList}
              setOrderList={setOrderList}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Itemized bill" key="2">
            {selectedOrder && order !== undefined ? (
              <NoAuthBillDetailsView order={selectedOrder} />
            ) : (
              <Skeleton className={classes.skeleton} />
            )}
          </Tabs.TabPane>
        </Tabs>
      </div>
      <Modal
        centered
        visible={popUpVisibility}
        onOk={() => setPopUpVisibility(false)}
        onCancel={() => setPopUpVisibility(false)}
        footer={null}
        className={classes.modal}
      >
        <Space direction="vertical" align="center">
          <h2>Please Proceed To Login</h2>
        </Space>
        <Button
          type="primary"
          style={{ width: "100%", borderRadius: 4, marginTop: ".7rem" }}
          size="middle"
          onClick={() => history.push("/login")}
        >
          Continue
        </Button>
      </Modal>
      <FooterMenu noLinks={noHeaderOptions} retailStoreId={retailStore.id} />
    </>
  );
}

export default NoAuthOrderView;

const useStyles = createUseStyles(() => {
  const flexStyles = {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  };

  return {
    tabs: {
      ...flexStyles,

      "& .ant-tabs-content-holder, & .ant-tabs-content, & .ant-tabs-tabpane": {
        ...flexStyles,
      },

      "& .ant-tabs-nav": {
        margin: [0, 10],
      },

      "& .ant-tabs-nav-list, & .ant-tabs-tab-btn": {
        width: "100%",
      },

      "& .ant-tabs-tab": {
        padding: 5,
        textAlign: "center",
        width: "50%",
        marginRight: "10px !important",

        "&:last-of-type": {
          margin: "0 !important",
        },
      },

      "& .ant-tabs-tab-btn": {
        fontSize: 12,
        lineHeight: "22px",
      },
    },
    modal: {
      "& .ant-modal-content": {
        borderRadius: 4,
      },
    },
    skeleton: {
      padding: 20,
    },
  };
});
