import React from "react";
import Loader from "components/loader/Loader";
import { StarFilled } from "@ant-design/icons";
import { useStyles } from "./chatListCard.style";
import RadioSwitch from "components/radioSwitch/RadioSwitch";
import { EmptyStarIcon } from "components/customIcons";

type DashboardListCardProps = {
  customers: Array<Object>;
  priorityCustomers: Array<Object>;
  selectedCustomerIndex: number;
  setChatData: any;
  customerCategory: string;
  setCustomerCategory: (category: string) => void;
  toggleCustomerPriority: any;
};

type CustomerItemListProps = {
  customer: any;
  index: number;
};

const switchButtonOptions = [
  { label: "All", value: "All" },
  { label: "Priority", value: "Priority" },
];

function ChatListCard({
  customers,
  priorityCustomers,
  selectedCustomerIndex,
  setChatData,
  customerCategory,
  setCustomerCategory,
  toggleCustomerPriority,
}: DashboardListCardProps) {
  const classes = useStyles();

  const changeCustomerCategory = (e: any) => {
    setCustomerCategory(e.target.value);
  };

  const changeSelectedCustomer = (index: number) => {
    if (selectedCustomerIndex !== index) {
      setChatData({
        customers: customers,
        priorityCustomers: priorityCustomers,
        selectedCustomerIndex: index,
        loading: false,
      });
    }
  };

  function CustomerListItem({ customer, index }: CustomerItemListProps) {
    const customerItemStyles =
      index === selectedCustomerIndex
        ? `${classes.customerItem} ${classes.selectedItem}`
        : classes.customerItem;

    return (
      <div
        key={customer.id}
        className={customerItemStyles}
        onClick={() => changeSelectedCustomer(index)}
      >
        <div className={classes.CustomerItemLeft}>
          {customer.priority === true ? (
            <StarFilled
              onClick={async () =>
                await toggleCustomerPriority(customer.user_id)
              }
            />
          ) : (
            <EmptyStarIcon
              onClick={async () =>
                await toggleCustomerPriority(customer.user_id)
              }
            />
          )}
          <div>
            <div className={classes.customerID}>{customer.id}</div>
            <div className={classes.customerName}>{customer.user_name}</div>
          </div>
        </div>
        <div className={classes.customerTotal}>
          ₹ {parseFloat(customer.grandtotal).toFixed(2)}
        </div>
      </div>
    );
  }

  return (
    <div className={classes.itemsListCard}>
      <div className={classes.listSettings}>
        <RadioSwitch
          size={"small"}
          options={switchButtonOptions}
          value={customerCategory}
          onChange={changeCustomerCategory}
        />
      </div>
      <div className={classes.itemsList}>
        {customers.length || priorityCustomers?.length ? (
          customerCategory === "All" ? (
            customers.map((customer, index) => (
              <CustomerListItem key={index} customer={customer} index={index} />
            ))
          ) : priorityCustomers?.length > 0 ? (
            priorityCustomers.map((priorityCustomer, index) => (
              <CustomerListItem
                key={index}
                customer={priorityCustomer}
                index={index}
              />
            ))
          ) : (
            <div className={classes.centreText}>No Priority Customers</div>
          )
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
}

export default ChatListCard;
