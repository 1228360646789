import { createUseStyles } from "react-jss";
import customeScrollbarStyle from "components/style/CustomScrollbar";

const useStyles = createUseStyles(({ colors }: Theme) => ({
  chatCard: {
    flex: "1",
    width: "100%",
    marginTop: "10px",
    padding: "0.5rem 1rem",
    backgroundColor: colors.light100,
    borderRadius: "5px",
    boxShadow:
      "-1px -1px 4px rgba(34, 94, 119, 0.25), 1px 1px 4px rgba(34, 94, 119, 0.25)",
  },
  chatCardWrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  chatHeader: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "5px 0px",
  },
  expandIcon: {
    marginRight: "1rem",
    fontSize: "1.1rem",
    background: "rgba(220, 220, 220, 0.5)",
    color: colors.primary200,
    border: "none",
  },
  chatTitle: {
    color: "#225E77",
    fontSize: "16px",
    fontWeight: "700",
    fontFamily: "Roboto",
  },
  messagesContainer: {
    flex: "10px",
    minHeight: "22vh",
    padding: "0.4rem",
    marginBottom: "5px",
    boxShadow: "inset 0px 5px 15px  -10px #CCC,inset 0px -5px 15px -10px #CCC",
    display: "flex",
    flexDirection: "column",

    // customizing scrollbar
    ...customeScrollbarStyle,
  },
  messagesList: {
    flex: 1,
    overflowY: "auto",
  },
  emptyContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px",
    color: "#225E77",
  },
  "@media (max-width: 1050px)": {
    chatCard: {
      // height: ({ chatExpanded }) =>
      //   chatExpanded ? "calc(100% - 258px)" : "130px",
      padding: "0rem 1rem",
    },
    chatTitle: {
      fontSize: "0.85rem",
    },
    expandIcon: {
      marginRight: 5,
    },
  },
}));

export { useStyles };
