import Button from "antd/es/button";
import React from "react";

import FormBox from "components/FormBox";
import OnboardingFormFooter from "components/OnboardingFormFooter";
import OnboardingWrapper from "components/OnboardingWrapper";
import OTPProtected from "components/OTPProtected";
import ResetPasswordForm from "components/forms/ResetPasswordForm";
import { useHistory } from "react-router-dom";
import { updatePassword } from "api/api";
import message from "antd/es/message";
import gtag from "components/Tracking/gtag";

function ForgotPasswordPage() {
  const { push } = useHistory();

  const showMessage = (type: "Success" | "Failure", messageString?: string) => {
    if (type === "Success") {
      message.success(messageString || "Saved successfully.!");
      gtag("event", "new_password_creation");
      push("/login");
    } else if (type === "Failure") {
      message.error(messageString || "Something went wrong.!");
    }
  };

  const onResetPasswordSubmit = async (values: any) => {
    await updatePassword(values.userId, values.password, values.token).then(
      (result) => result?.status && showMessage(result.status, result.message)
    );
  };

  return (
    <OTPProtected checkPresence title="Forgot Password?">
      {(renderProps) => (
        <OnboardingWrapper height="full">
          <FormBox title="Reset Password">
            <ResetPasswordForm
              onSubmit={onResetPasswordSubmit}
              defaultValues={{
                ...renderProps,
              }}
            />
            <OnboardingFormFooter>
              <Button type="link" href="/login">
                Login?
              </Button>
            </OnboardingFormFooter>
          </FormBox>
        </OnboardingWrapper>
      )}
    </OTPProtected>
  );
}

export default ForgotPasswordPage;
