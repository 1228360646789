import { BellOutlined, MessageOutlined } from "@ant-design/icons";
import { Badge } from "antd";
import React, { useEffect, useState } from "react";
import PlaceholderImage from "assets/svgs/placeholder-profile-img.svg";
import { getSession, Session } from "client/reactives/session";
import { useStyles } from "./Topbar.style";

type RetailerInfoProps = {
  retailerData: {
    Name: string;
    Logo: string;
  };
};

function Topbar({ retailerData }: RetailerInfoProps) {
  const classes = useStyles();
  const [retailerUserName, setRetailerUserName] = useState<any>("");
  const [profileImgLoaded, setProfileImgLoaded] = useState(false);

  useEffect(() => {
    const { userName } = getSession() as Session;
    setRetailerUserName(userName);
  }, [retailerData]);

  return (
    <div className={classes.topbar}>
      <div className={classes.topbarLeft}></div>
      <div className={classes.topbarRight}>
        {/* <div className={classes.topbarIconBtn}>
          <Badge size="small" count={0}>
            <BellOutlined />
          </Badge>
        </div>
        <div className={classes.topbarIconBtn}>
          <Badge size="small" count={0}>
            <MessageOutlined />
          </Badge>
        </div> */}

        <div className={classes.user}>
          <img
            hidden={!profileImgLoaded}
            onLoad={() => setProfileImgLoaded(true)}
            className={classes.profileImg}
            src={retailerData.Logo}
            alt="profile_image"
          />
          <img
            hidden={profileImgLoaded}
            className={classes.profileImg}
            src={PlaceholderImage}
            alt="profile_image"
          />

          <div>
            <div className={classes.buinessName}>
              {retailerData.Name?.toString()}
            </div>
            <div className={classes.userName}>
              {retailerUserName?.toString()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Topbar;
