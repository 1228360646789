import * as yup from "yup";

import BaseModel from "./BaseModel";

class MarketingCampaign extends BaseModel {
  static schema = yup.object().shape({
    campaign_type: yup.string().required(),
    campaign_medium: yup.string().required(),
  });

  static addCampaign<TInput>() {
    return MarketingCampaign.validationResolver<TInput>([
      "campaign_type",
      "campaign_medium",
    ]);
  }
}

export default MarketingCampaign;
