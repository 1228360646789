import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Input,
  message,
  Modal,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import { getProducts } from "api/api";
import {
  deletePricingRule,
  getCatalogue,
  getPricingRules,
} from "api/retailerApi";
import Flex from "components/layouts/Flex";
import Loader from "components/loader/Loader";
import AddPriceModal from "components/modals/AddPriceModal";
import AddPricingRuleModal from "components/modals/AddPricingRuleModal";
import PricingRulesModal from "components/modals/PricingRulesModal";
import copy from "copy-text-to-clipboard";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { Link } from "react-router-dom";

type InventoryPageProps = {
  retailerData: {
    ID: number;
    Name: string;
  };
};

export default function InventoryPage({ retailerData }: InventoryPageProps) {
  const classes = useStyle();

  const [loading, setLoading] = useState(true);
  const [rulesLoading, setRulesLoading] = useState(true);
  const [showPricingRules, setShowPricingRules] = useState(false);
  const [pricingRules, setPricingRules] = useState([]);

  const [products, setProducts] = useState<any[]>([]);
  const [searchInput, setSearchInput] = useState("");
  const [endOfList, setEndOfList] = useState(false);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 20,
    totalPages: 0,
  });
  const [addPricingRulesModalVisible, setAddPricingRulesModalVisible] =
    useState(false);
  const [addPriceModal, setAddPriceModal] = useState({
    visible: false,
    product: null,
  });

  const handleDeleteRuleClick = (rule: any) => () => {
    Modal.confirm({
      title: "Are you sure you want to delete this rule?",
      okText: "Confirm",
      onOk: async () => {
        return await deletePricingRule(+rule.id).then((res) => {
          message.success(res?.message);
          setRulesLoading(true);
        });
      },
    });
  };

  const handlePricingRulesClick = () => {
    setShowPricingRules(true);
  };

  const handlePriceUpdate = (newPrice: number, productSku: string) => {
    setProducts((curr) => {
      let index = curr.findIndex((i) => i.product_sku === productSku);
      curr[index].strike_price = newPrice;

      return [...curr];
    });
  };

  const handleAddPriceClick = (row: any) => () => {
    setAddPriceModal({
      visible: true,
      product: row,
    });
  };

  const handleScroll = async (e: any) => {
    if (endOfList) {
      return;
    }
    let element = e.target;

    if (
      [element.clientHeight, element.clientHeight - 1].includes(
        element.scrollHeight - Math.round(element.scrollTop)
      )
    ) {
      setPagination((curr) => ({
        ...curr,
        currentPage: curr.currentPage + 1,
      }));
    }
  };

  const handleProductShare = (product: any) => () => {
    if (!!retailerData.ID) {
      let url = `https://${window.location.host}/dashboard/stores/${
        retailerData.ID
      }/product/${product?.product_sku ?? product.product_id}`;
      copy(url);

      message.success("URL copied to clipboard!");
      return;
    }
  };

  const handleSearch = (val: string) => setSearchInput(val);

  const handleShareSearchResults = () => {
    if (!searchInput.length) return message.info("Please search something...");

    if (!!retailerData.ID) {
      let url = `https://${window.location.host}/dashboard/stores/${retailerData.ID}/shop?catalog_key=2&search=${searchInput}`;
      copy(url);

      message.success("URL copied to clipboard!");
      return;
    }
  };

  const fetchProducts = async () => {
    let [count, ...products] = await getCatalogue(
      retailerData.ID,
      pagination.currentPage,
      searchInput
    );

    if (pagination.currentPage > 1) {
      setProducts((curr) => [...curr, ...products]);
    } else {
      setProducts(products);
    }

    setEndOfList(products.length < pagination.pageSize);
    setLoading(false);
  };

  const fetchRules = async () => {
    await getPricingRules(retailerData.ID).then((res) => {
      if (res.data?.length) {
        setPricingRules(res.data);
      }
    });

    setRulesLoading(false);
  };

  useEffect(() => {
    if (rulesLoading) {
      fetchRules();
    }
  }, [rulesLoading]);

  useEffect(() => {
    setLoading(true);
  }, [retailerData.ID, searchInput, pagination.pageSize]);

  useEffect(() => {
    fetchProducts();
  }, [loading, pagination.currentPage]);

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        document
          .querySelector(".ant-table-body")
          ?.addEventListener("scroll", handleScroll);
      }, 2000);
    }

    return () => {
      document
        .querySelector(".ant-table-body")
        ?.removeEventListener("scroll", handleScroll);
    };
  }, [loading]);

  const productsTablecolums: any = [
    {
      title: "#",
      dataIndex: "index",
      width: "60px",
      align: "center",
      render: (value: any, row: any, index: any) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "160px",
    },
    // {
    //   title: "Category",
    //   dataIndex: "category_name",
    //   key: "category_name",
    //   width: "100px",
    // },
    // {
    //   title: "Manufacturer",
    //   dataIndex: "manufacturer",
    //   key: "manufacturer",
    //   width: "160px",
    //   render: (val: any) => val ?? " - ",
    // },
    // {
    //   title: "SKU",
    //   dataIndex: "product_sku",
    //   key: "product_sku",
    //   width: "100px",
    //   render: (val: any) => val ?? " - ",
    // },
    // {
    //   title: "Vendor",
    //   dataIndex: "vendor",
    //   key: "vendor",
    //   width: "65px",
    //   render: (val: any) => val ?? " - ",
    // },
    // {
    //   title: "Domain",
    //   dataIndex: "product_domain",
    //   key: "product_domain",
    //   width: "65px",
    //   render: (val: any) => val ?? " - ",
    // },
    {
      title: "Pack",
      dataIndex: "pack",
      key: "pack",
      width: "100px",
      render: (val: any) => val ?? " - ",
    },
    {
      title: "MRP",
      dataIndex: "pack_price",
      key: "pack_price",
      width: "100px",
      render: (val: any, row: any) => "₹"+(+row?.pack_price).toFixed(2),
    },
    {
      title: "Net Purchase Price",
      dataIndex: "net_purchase_price",
      key: "net_purchase_price",
      width: "100px",
      render: (val: any, row: any) => (!!val ? "₹"+(+val).toFixed(2) : " - "),
    },
    // {
    //   title: "Selling Price",
    //   dataIndex: "strike_price",
    //   key: "strike_price",
    //   width: "100px",
    //   render: (val: any, row: any) => (!!val ? (+val).toFixed(2) : " - "),
    // },
    {
      title: "Current Stock",
      dataIndex: "curr_inventory_size",
      key: "curr_inventory_size",
      width: "100px",
      render: (val: any, row: any) => (!!val ? (+val) : " - "),
    },
    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   width: "80px",
    //   render: (val: any, row: any) => (
    //     <Button
    //       type="link"
    //       style={{ padding: 0 }}
    //       onClick={handleAddPriceClick(row)}
    //     >
    //       EDIT PRICE
    //     </Button>
    //   ),
    // },
    // {
    //   title: "Share",
    //   dataIndex: "share",
    //   width: "80px",
    //   render: (val: any, row: any) => (
    //     <Button
    //       type="link"
    //       icon={<ShareAltOutlined />}
    //       onClick={handleProductShare(row)}
    //     />
    //   ),
    // },
  ];

  const pricingRulesColumns: any = [
    {
      title: "#ID",
      dataIndex: "id",
      width: "60px",
      align: "center",
    },
    {
      title: "Tier",
      dataIndex: "rule_tier",
      key: "rule_tier",
      width: "100px",
    },
    {
      title: "Tier Value",
      dataIndex: "referenced_entity_value",
      key: "referenced_entity_value",
      width: "100px",
      render: (val: any) => val ?? " - ",
    },
    {
      title: "Type",
      dataIndex: "rule_type",
      key: "rule_type",
      width: "100px",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      width: "150px",
      render: (val: any) => moment(val).format("hh:mm, Do MMM YY"),
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
      width: "150px",
      render: (val: any) => moment(val).format("hh:mm, Do MMM YY"),
    },
    {
      title: "Action",
      dataIndex: "share",
      width: "80px",
      render: (val: any, row: any) => (
        <Button
          type="link"
          icon={<DeleteOutlined />}
          onClick={handleDeleteRuleClick(row)}
        />
      ),
    },
  ];

  return (
    <Row gutter={[10, 10]} className={classes.productsListWrapper}>
      <Col span={showPricingRules ? 16 : 24}>
        <Card className={classes.productsListCard}>
          <div className={classes.cardHeader}>
            <div>
              <Typography.Text className={classes.cardTitle}>
                Products
              </Typography.Text>

              {/* <Button
                size="small"
                shape="round"
                onClick={handlePricingRulesClick}
              >
                Pricing Rules
              </Button> */}

              <Button
                type="link"
                icon={<ShareAltOutlined />}
                hidden={searchInput === "" || !products.length}
                onClick={handleShareSearchResults}
              >
                Share Search Results
              </Button>
            </div>

            <div>
              <Input.Search
                allowClear
                placeholder="Search Products"
                onSearch={handleSearch}
                style={{ width: "100%" }}
              />
            </div>
          </div>

          <AddPricingRuleModal
            retailerData={retailerData}
            visible={addPricingRulesModalVisible}
            onRuleAdd={() => setRulesLoading(true)}
            onCancel={() => setAddPricingRulesModalVisible(false)}
          />

          <AddPriceModal
            {...addPriceModal}
            retailerData={retailerData}
            onPriceUpdate={handlePriceUpdate}
            onCancel={() => setAddPriceModal({ visible: false, product: null })}
          />

          {!loading ? (
            <Table
              dataSource={products}
              columns={productsTablecolums}
              size={"middle"}
              scroll={{ y: "calc(100vh - 230px)" }}
              pagination={false}
              // pagination={{
              //   total: pagination.totalPages,
              //   pageSize: pagination.pageSize,
              //   current: pagination.currentPage,
              //   onChange: handlePageChange,
              // }}
            />
          ) : (
            <Loader height="70vh" />
          )}
        </Card>
      </Col>
      <Col span={showPricingRules ? 8 : 0}>
        <Card hidden={!showPricingRules} className={classes.productsListCard}>
          <Flex align="center" justify="space-between">
            <Space size={5} align="center">
              {/* <Typography.Text className={classes.cardTitle2}>
                Pricing Rules
              </Typography.Text> */}
              <Button
                type="link"
                icon={<PlusCircleOutlined />}
                onClick={() => setAddPricingRulesModalVisible(true)}
              >
                ADD RULE
              </Button>
            </Space>

            <Button
              type="text"
              icon={<CloseOutlined />}
              onClick={() => setShowPricingRules(false)}
            />
          </Flex>

          {!loading ? (
            <Table
              dataSource={pricingRules}
              columns={pricingRulesColumns}
              size={"middle"}
              scroll={{ y: "calc(100vh - 230px)" }}
              pagination={false}
            />
          ) : (
            <Loader height="70vh" />
          )}
        </Card>
      </Col>
    </Row>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  productsListWrapper: {
    padding: 10,
    "& > .ant-col": {
      display: "flex",
    },
  },
  productsListCard: {
    flex: 1,
    width: "100%",
    padding: "0.5rem",
    backgroundColor: "#fff",
    borderRadius: 5,
    minHeight: "calc(100vh - 100px)",
    boxShadow:
      "-1px -1px 4px rgb(34 94 119 / 25%), 1px 1px 4px rgb(34 94 119 / 25%)",
  },
  paginationWrapper: {
    padding: [20, 25, 10],
  },
  cardHeader: {
    paddingBottom: 10,
    display: "flex",
    justifyContent: "space-between",
    "& > div": {
      display: "flex",
      alignItems: "center",
    },
  },
  cardTitle: {
    margin: "0 !important",
    paddingRight: 20,
    fontWeight: 600,
    fontSize: 18,
  },
  cardTitle2: {
    margin: "0 !important",
    paddingRight: 20,
    fontWeight: 600,
    fontSize: 16,
  },
}));
