import {
  DeleteOutlined,
  EditOutlined,
  MobileOutlined,
} from "@ant-design/icons";
import {
  AutoComplete,
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Empty,
  message,
  Modal,
  Radio,
  Space,
  Typography,
} from "antd";
import Search from "antd/es/input/Search";
import {
  applyCoupon,
  deleteAllCartItems,
  deleteCart,
  deleteCartItem,
  getCart,
  getCartItems,
  getCartLoyaltyInfo,
  getCustomerCoupons,
  getProductDetails,
  getShipping,
  getUserById,
  insertReorder,
  insertReorderItems,
  insertReorderLoyalty,
  updateCartItemsByProductId,
} from "api/api";
import { CashIcon, GoldenCrownIcon } from "components/customIcons";
import NumberSelect from "components/dataEntry/NumberSelect";
import Loader from "components/loader/Loader";
import AddressModal from "components/modals/AddressModal";
import { hideScrollbar } from "components/style/CustomScrollbar";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";
import { getCustomerById } from "../APIs/getCustomerDetails";

interface CartOverviewCardProps {
  cartId: number;
  onChange: () => void;
  loading: boolean;
  onLoaded: () => void;
}

const getPriceWithoutGst = (gstPercentage: number, priceWithGst: number) => {
  let gstAmount = priceWithGst - priceWithGst * (100 / (100 + gstPercentage));
  return priceWithGst - gstAmount;
};

const getAddressString = (customer: any) =>
  (customer.line1 ? customer.line1 + ", " : "") +
  (customer.line2 ? customer.line2 + ", " : "") +
  (customer.landmark ? customer.landmark + ", " : "") +
  (customer.city ? customer.city + ", " : "") +
  (customer.state ? customer.state + ", " : customer.state) +
  (customer.pincode ? customer.pincode + ", " : customer.pincode);

const getReorderItemDataObject = (item: any, reorder_id: any) => ({
  reorder_id,
  product_id: item?.product_id,
  quantity: item?.quantity,
  discount: item?.discount,
  sub_total: item?.sub_total,
  total: item?.total,
  sgst: item?.sgst,
  cgst: item?.cgst,
});

export default function CartOverviewCard({
  cartId,
  loading,
  onChange,
  onLoaded,
}: CartOverviewCardProps) {
  const classes = useStyle();
  const history = useHistory();

  const [cart, setCart] = useState<any>({});
  const [cartItems, setCartItems] = useState<any[]>([]);
  const [shippingCharge, setShippingCharge] = useState(0);
  const [loyaltyInfo, setLoyaltyInfo] = useState<any>({});
  const [deliveryAddress, setDeliveryAddress] = useState<any>({});
  const [coupons, setCoupons] = useState<any[]>([]);
  const [redeemLoyaltyPoints, setRedeemLoyaltyPoints] = useState(false);
  const [addressModalVisible, setAddressModalVisible] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("cod");

  const handleCouponCodeApply = async (couponCode: string) => {
    if (
      cart.coupon_code !== couponCode &&
      (cart.coupon_code !== null || couponCode !== "")
    ) {
      await applyCoupon(couponCode, +cartId).then((res) => {
        if (res?.length && res[0].id) {
          setCart(res[0]);
        } else {
          message.error(res.err || res);
        }
      });
    }
  };

  const handlePaymentMethodChange = (e: any) => {
    setPaymentMethod(e.target.value);

    if (e.target.value === "cod" && redeemLoyaltyPoints) {
      setRedeemLoyaltyPoints(false);
    }
  };

  const handleAddressFormSave = (address: any) => {
    setAddressModalVisible(false);
    setDeliveryAddress({
      ...deliveryAddress,
      address: getAddressString(address),
    });
  };

  const toggleAddressModal = () => {
    setAddressModalVisible(!addressModalVisible);
  };

  const getLoyaltyPointsDeduction = (getRedeemable = false) => {
    let totalAmount = +cart?.price_with_gst;
    let loyaltyPointsDeduction = 0.0;

    if (+loyaltyInfo?.loyalty_points > 100) {
      let redeemableLP = +loyaltyInfo?.loyalty_points - 100;

      if (redeemLoyaltyPoints || getRedeemable) {
        if (loyaltyInfo && redeemableLP >= totalAmount) {
          loyaltyPointsDeduction = totalAmount;
        } else {
          loyaltyPointsDeduction = redeemableLP;
        }
      }
    }

    return (+loyaltyPointsDeduction).toFixed(2);
  };

  const getTotalAmount = () => {
    let totalAmount = +cart?.price_with_gst;

    let loyaltyPointsDeduction = 0.0;

    if (redeemLoyaltyPoints) {
      loyaltyPointsDeduction = +getLoyaltyPointsDeduction();
      totalAmount -= loyaltyPointsDeduction;
    }

    if (totalAmount < 0) {
      totalAmount = 0;
    }

    return (+totalAmount + +shippingCharge).toFixed(2);
  };

  const removeCartItem = async (itemId: number) => {
    await deleteCartItem(itemId).then((result) => {
      let newItemsArray = cartItems.filter(
        (cartItem: any) => cartItem.id !== result[0].id
      );
      setCartItems(newItemsArray);
      onChange();
    });
  };

  const clearCart = async () => {
    await deleteAllCartItems(+cart?.id);
    await deleteCart(+cart?.id);
    setCartItems([]);
    setCart({});
    onChange();
  };

  const handleQuantityChange = async (newQty: number, item: any) => {
    let productPrice = item?.strike_price ?? item.pack_price;
    let sub_total = getPriceWithoutGst(
      +item.sgst + +item.cgst,
      +productPrice * +newQty
    );

    let newData = {
      product_section: "cart",
      product_sku: item?.product_sku,
      sub_total: sub_total.toFixed(2),
      quantity: +newQty,
      total: (+productPrice * +newQty).toFixed(2),
    };

    await updateCartItemsByProductId(+cartId, +item.product_id, newData).then(
      (result) => {
        if (result[0]?.product_id) {
          fetchCartItems(cart);
          onChange();
        }
      }
    );
  };

  const fetchCartItems = async (cart: any) => {
    let cartItems = await getCartItems(cartId);
    let items = [];

    for (let i = 0; i < cartItems.length; i++) {
      const productDetails = await getProductDetails(
        cartItems[i]?.product_id,
        +cart?.retail_store_id,
        "other_products",
        +cart?.user_id
      );
      items.push({
        ...productDetails[0],
        ...cartItems[i],
      });
    }

    setCartItems(items);
  };

  const placeReorder = async () => {
    if (!loading) {
      // push uploaded prescription

      // data to send in inserReorder()
      let reorderData = {
        cart_id: cart.id,
        retail_store_id: cart.retail_store_id,
        payment_status: `'${cart.payment_status}'`,
        discount: cart.discount,
        gst: cart.gst,
        prescription_id: null,
        payment_mode: paymentMethod,
        admin: "retailer-dashboard",
        subtotal: cart.price_with_out_gst,
        grandtotal: +getTotalAmount(),
        user_id: cart.user_id,
      };

      // data to send in insertReorderLoyalty()
      let loyaltyData = {
        cart_id: cart.id,
        user_id: cart?.user_id,
        retail_store_id: +cart?.retail_store_id,
        reorder_amount: (+reorderData.grandtotal).toFixed(2),
        use_loyalty_points: redeemLoyaltyPoints,
      };

      await insertReorder(reorderData).then(async (result) => {
        // inserting reorder items
        for (var i = 0; i < cartItems?.length; i++) {
          let itemData = getReorderItemDataObject(cartItems[i], result[0].id);
          await insertReorderItems(itemData);
        }

        // insert loyaly data only if redeem checkbox is checked
        if (redeemLoyaltyPoints) {
          await insertReorderLoyalty({
            ...loyaltyData,
            reorder_id: +result[0].id,
          });
        }

        await clearCart();
        message.success("Order Placed Successfully.!");
        history.push("/retailer/dashboard/incoming-orders");
      });
    }
  };

  const handlePlaceReorderClick = () => {
    if (deliveryAddress.address === "") {
      setAddressModalVisible(true);
    } else {
      Modal.confirm({
        title: "Confirm Placing This Reorder.",
        okText: "Confirm",
        onOk: placeReorder,
      });
    }
  };

  const fetchInitialData = async () => {
    let cart = await getCart(cartId);
    if (cart?.length) {
      await fetchCartItems(cart[0]);

      const user = await getUserById(cart[0].user_id);
      const customer = await getCustomerById(cart[0].user_id);

      // fetch coupons suggestions
      const coupons = await getCustomerCoupons(
        +cart[0].retail_store_id,
        +cart[0].price_with_gst,
        +cart[0].user_id
      );

      // fetching loyalty details
      const loyaltyInfo = await getCartLoyaltyInfo(
        +cartId,
        +cart[0]?.retail_store_id,
        +cart[0]?.user_id
      );

      // fetch shippingCharge
      const { shippingCharge } = await getShipping(
        +cart[0]?.retail_store_id,
        +cartId
      );

      const delivery = {
        deliverTo: user?.name ?? customer?.name,
        address: getAddressString(customer),
      };

      setDeliveryAddress(delivery);
      setShippingCharge(shippingCharge);
      setLoyaltyInfo(loyaltyInfo);
      setCoupons(coupons);
      setCart(cart[0]);
    }
    onLoaded();
  };

  useEffect(() => {
    if (loading && !!cartId) {
      fetchInitialData();
    }
  }, [loading, cartId]);

  if (!!!cartId || cartId === 0) {
    return (
      <div className={classes.cardOverviewCard}>
        <div className={classes.cardHeader}>
          <Typography.Text strong className={classes.cardTitle}>
            Cart Overview
          </Typography.Text>
        </div>
      </div>
    );
  }

  const CouponACLabel = ({ coupon }: any) => (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      {coupon.coupon_code}
      <span>
        {coupon.value}
        {coupon.type === "value" ? "₹" : "%"}
      </span>
    </div>
  );

  return (
    <div className={classes.cardOverviewCard}>
      <div className={classes.cardHeader}>
        <Typography.Text strong className={classes.cardTitle}>
          Cart Overview
        </Typography.Text>
        <Button
          danger
          type="text"
          icon={<DeleteOutlined />}
          className={classes.clearCartBtn}
          onClick={clearCart}
        />
      </div>

      {!loading ? (
        cartItems.length ? (
          <div className={classes.cartOverviewCardContent}>
            <Card className={classes.deliveryAddressWrapper}>
              <div>
                <div className={classes.deliverTo}>
                  Deliver To: <b>{deliveryAddress.deliverTo}</b>
                </div>
                <div className={classes.deliveryAddressText}>
                  {deliveryAddress.address}
                </div>
              </div>

              <Button
                type="link"
                size="small"
                onClick={toggleAddressModal}
                icon={<EditOutlined />}
              />
            </Card>

            <AddressModal
              visible={addressModalVisible}
              onClose={toggleAddressModal}
              onSave={handleAddressFormSave}
              customerUserId={cart?.user_id}
            />

            {/* <div className={classes.cartSummary}>
            <div className={classes.cartSummaryItem}>
              <Typography.Text strong>Total Amount</Typography.Text>
              <Typography.Text strong>₹ {getTotalAmount()}</Typography.Text>
            </div>
          </div> */}

            <div>
              <Typography.Text className={classes.sectionTitle}>
                Items
              </Typography.Text>
              {cartItems.map((cartItem) => (
                <div className={classes.cartItem}>
                  <div className={classes.cartItemLeft}>
                    <div className={classes.itemName}>{cartItem?.name}</div>

                    <div className={classes.cartItemLeftBottom}>
                      <NumberSelect
                        till={50}
                        size="small"
                        value={cartItem?.quantity}
                        onChange={(value: any) =>
                          handleQuantityChange(value, cartItem)
                        }
                      />
                      <div className={classes.cartItemTotal}>
                        ₹ {parseFloat(cartItem?.total).toFixed(2)}
                      </div>
                    </div>
                  </div>
                  <div className={classes.cartItemRight}>
                    <Button
                      type="text"
                      size="small"
                      icon={<DeleteOutlined />}
                      className={classes.removeItemButton}
                      onClick={() => removeCartItem(parseInt(cartItem.id))}
                    />
                  </div>
                </div>
              ))}

              <Divider />

              <Card>
                <Typography.Paragraph className={classes.applyCouponTitle}>
                  <span>Have any coupon code?</span>

                  {cart.coupon_id !== null && (
                    <span style={{ fontSize: 12 }}>
                      APPLIED COUPON : <b>{cart.coupon_code}</b>
                    </span>
                  )}
                </Typography.Paragraph>

                <AutoComplete
                  size="small"
                  style={{ width: "100%" }}
                  onSelect={handleCouponCodeApply}
                  className={classes.applyCouponInput}
                  options={coupons.map((item: any) => ({
                    value: item.coupon_code,
                    label: <CouponACLabel coupon={item} />,
                  }))}
                >
                  <Search
                    allowClear
                    enterButton="Apply"
                    onSearch={handleCouponCodeApply}
                    placeholder="Enter your code here..."
                  />
                </AutoComplete>
              </Card>

              {+loyaltyInfo?.loyalty_points > 100 && (
                <Card className={classes.redeemLoyaltyPointsWrapper}>
                  <div className={classes.redeemLoyaltyPointsCheckbox}>
                    <Checkbox
                      checked={redeemLoyaltyPoints}
                      onChange={(e) => setRedeemLoyaltyPoints(e.target.checked)}
                    />
                  </div>
                  <div className={classes.redeemLoyaltyPointsContent}>
                    <div className={classes.redeemLoyaltyPointsText}>
                      Redeem Loyalty Points
                    </div>
                    <div className={classes.redeemLoyaltyPointsValue}>
                      You can redeem{" "}
                      {parseFloat(getLoyaltyPointsDeduction(true))} Loyalty
                      Points on this order
                    </div>
                  </div>
                  <div className={classes.redeemLoyaltyPointsIcon}>
                    <GoldenCrownIcon />
                  </div>
                </Card>
              )}

              <Card className={classes.priceDetailsWrapper}>
                <div className={classes.priceDetailsTitle}>Price Details</div>
                <div className={classes.priceDetailsItem}>
                  <span>Total MRP</span>
                  <span>
                    ₹{" "}
                    {(+cart?.price_with_gst + +cart?.coupon_discount).toFixed(
                      2
                    )}
                  </span>
                </div>
                <hr />
                <div className={classes.priceDetailsItem}>
                  <span>Loyalty Point Deduction</span>
                  <span>₹ {getLoyaltyPointsDeduction()}</span>
                </div>
                <hr />
                <div className={classes.priceDetailsItem}>
                  <span>Coupon Discount</span>
                  <span>₹ {parseFloat(cart?.coupon_discount).toFixed(2)}</span>
                </div>
                <hr />
                <div className={classes.priceDetailsItem}>
                  <span>Shipping Charge</span>
                  <span>₹ {parseFloat(shippingCharge + "").toFixed(2)}</span>
                </div>
                <hr />
                <div className={classes.totalAmount}>
                  <span>Total Amount</span>
                  <span>₹ {getTotalAmount()}</span>
                </div>
              </Card>
            </div>

            <Radio.Group
              value={paymentMethod}
              onChange={handlePaymentMethodChange}
            >
              <Space direction="vertical" size="small">
                <Card onClick={() => setPaymentMethod("online")}>
                  <Space>
                    <Radio value="online" />
                    <MobileOutlined />
                    <Typography.Text>Pay Online</Typography.Text>
                  </Space>
                </Card>


              </Space>
            </Radio.Group>

            <Col span={24} className={classes.placeReorderBtn}>
              <Button block type="primary" onClick={handlePlaceReorderClick}>
                Place Reorder
              </Button>
            </Col>
          </div>
        ) : (
          <div className={classes.emptyWrapper}>
            <Empty description="Please add products" />
          </div>
        )
      ) : (
        <Loader height="60vh" />
      )}
    </div>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  cardOverviewCard: {
    width: "100%",
    height: "100%",
    padding: "1rem",
    boxShadow:
      "-1px -1px 4px rgb(34 94 119 / 25%), 1px 1px 4px rgb(34 94 119 / 25%)",
    borderRadius: 5,
    backgroundColor: "#fff",
    position: "relative",
  },
  cardHeader: {
    marginBottom: 20,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cardTitle: {
    fontSize: 20,
  },

  cartOverviewCardContent: {
    height: "calc(100vh - 270px)",
    paddingBottom: 40,
    overflowY: "auto",

    ...hideScrollbar,
  },

  cartSummary: {
    marginTop: 0,
  },
  cartSummaryItem: {
    padding: [10, 0],
    display: "flex",
    justifyContent: "space-between",
  },

  cartItem: {
    width: "100%",
    height: 65,
    margin: [15, 0],
    padding: [10, 15],
    background: "#fff",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "0.3px solid #D9D9D9",
    boxSizing: "border-box",
  },
  cartItemLeft: {},
  cartItemRight: {},
  cartItemLeftBottom: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  itemName: {
    paddingBottom: 12,
    fontWeight: "500",
    fontSize: "10px",
    lineHeight: "12px",
    color: "#505050",
  },
  removeItemButton: {
    fontWeight: "500",
    fontSize: "10px",
    lineHeight: "12px",
    color: "#505050",
  },
  cartItemTotal: {
    marginLeft: 18,
    fontWeight: 700,
    fontSize: "10px",
    lineHeight: "12px",
    color: "#505050",
  },
  clearCartBtn: {
    "& .anticon": {
      fontSize: 16,
    },
  },
  placeReorderBtn: {
    width: "100%",
    position: "absolute",
    bottom: 0,
    left: 0,
    padding: 10,
    "& .ant-btn": {
      borderRadius: 5,
    },
  },

  deliveryAddressWrapper: {
    marginBottom: 15,
    position: "relative",
    "& .ant-card-body": {
      padding: 10,
    },

    "& .ant-btn": {
      position: "absolute",
      top: 10,
      right: 10,
    },
  },
  deliverTo: {
    paddingBottom: 3,
    fontSize: "12px",
    lineHeight: "22px",
    color: "#505050",

    "& b": {
      fontWeight: "500 !important",
    },
  },
  deliveryAddressText: {
    fontSize: "10px",
    lineHeight: "18px",
    color: "#505050",
  },

  applyCouponWrapper: {
    width: "100%",
    margin: [10, 0, 15],
    padding: [10, 25],
    background: "#FFFFFF",
    border: "0.3px solid #D9D9D9",
    "& input": {
      lineHeight: "26px",
      fontSize: 12,
    },
    "& button": {
      fontSize: 12,
      background: "#505050",
    },
  },
  applyCouponTitle: {
    fontWeight: 600,
    marginBottom: 5,
    fontSize: 12,
  },
  applyCouponInput: {
    "& .ant-input, & .ant-btn": {
      fontSize: 10,
    },
  },

  redeemLoyaltyPointsWrapper: {
    margin: [10, 0, 15],
    "& .ant-card-body": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  redeemLoyaltyPointsCheckbox: {
    paddingRight: 10,
  },
  redeemLoyaltyPointsContent: {
    flex: 1,
  },
  redeemLoyaltyPointsText: {
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "12px",
    color: "#505050",
  },
  redeemLoyaltyPointsValue: {
    paddingTop: 6,
    fontSize: 10,
    color: "#2A9D8F",
  },
  redeemLoyaltyPointsIcon: {
    paddingLeft: 10,
    fontSize: 20,
    textAlign: "center",
  },

  priceDetailsWrapper: {
    width: "100%",
    margin: [20, 0],
  },
  priceDetailsTitle: {
    marginBottom: 18,
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "14px",
    color: "#505050",
  },
  priceDetailsItem: {
    width: "100%",
    marginBottom: 10,
    fontSize: "12px",
    lineHeight: "12px",
    color: "#505050",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  totalAmount: {
    width: "100%",
    marginTop: 20,
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "14px",
    color: "#505050",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  sectionTitle: {
    fontSize: 14,
    fontWeight: 500,
    paddingLeft: 5,
  },

  emptyWrapper: {
    height: "60vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
