import Space from "antd/es/space";
import React, { useMemo } from "react";
import OnboardingWrapper from "./OnboardingWrapper";

type CustomerRegisterWrapper = {
  steps: string[];
  currentStep: number;
  children: React.ReactNode;
};

function CustomerRegisterWrapper({
  steps,
  currentStep,
  children,
}: CustomerRegisterWrapper) {
  const views = useMemo(() => React.Children.toArray(children), [children]);
  const currentView = views[currentStep];

  return (
    <OnboardingWrapper height="full">
      <Space direction="vertical" align="center" style={{ width: "100%" }}>
        <img
          style={{
            width: "6rem",
            height: "7rem",
            marginBottom: "1.8rem",
          }}
          src={process.env.PUBLIC_URL + "/Raseet-final-logo.png"}
        />
      </Space>
      {currentView}
    </OnboardingWrapper>
  );
}

export default CustomerRegisterWrapper;
