import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(({ colors }: Theme) => ({
  wrapper: {
    padding: 30,
    overflowY: "auto",
  },
  listWrapper: {
    marginTop: 14,
  },
  empty: {
    marginTop: 50,
    width: "100%",
  },
  filterSection: {
    padding: 10,
    width: "100%",

    "& h5": {
      fontSize: 13,
    },
  },
  dateBox: {
    marginLeft: 5,
    width: 100,
    padding: 5,
    borderRadius: 2,
    backgroundColor: colors.primary,
    color: colors.light100,
    boxShadow:
      "inset 1px 1px 4px rgb(0 0 0 / 25%), inset -1px -1px 4px rgb(0 0 0 / 25%)",

    "& .ant-picker-input": {
      flexDirection: "row-reverse",
      alignItems: "flex-end",

      "& input": {
        textAlign: "center",
        color: "inherit",
        background: "inherit",
        fontSize: 10,
        "&::placeholder": {
          color: "#fff !important",
          opacity: 0.8,
        },
      },

      "& .ant-picker-suffix": {
        fontSize: 14,
        color: "inherit",
        background: "inherit",
      },
    },
  },
  billListItemCard: {
    height: 60,
    boxShadow: "-1px -1px 2px rgb(0 0 0 / 15%), 1px 1px 2px rgb(0 0 0 / 15%)",

    "& .ant-card-body": {
      width: "100%",
      height: "100%",
      padding: "7px 20px",
      display: "flex",
      alignItems: "center",
    },
  },
  billListItemCardWrapper: {
    height: "100%",
    width: "100%",
    fontSize: 9,
    fontWeight: 400,
    lineHeight: "15px",
    display: "flex",
    alignItems: "center",

    "& .anticon": {
      fontSize: 10,
      marginRight: 5,
    },
    "& .ant-col:nth-child(even)": {
      color: "#2D2C2C",
      textAlign: "right",
      fontSize: 10,
      lineHeight: "12px",
    },
    "& .ant-col:nth-child(2)": {
      fontWeight: 700,
      color: "#2A9D8F",
    },
    "& .ant-col:nth-child(5)": {
      color: "rgba(80, 80, 80, 0.5)",
    },
  },
  itemCardLeft: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1,
  },
  itemCardRight: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  itemCardOrderNo: {
    fontSize: 11,
    fontWeight: 700,
    lineHeight: "14px",
    marginBottom: 7,
  },
  itemCardDateTime: {
    color: colors.dark400,
    fontSize: 12,
    lineHeight: "11px",
    margin: 0,
  },
  itemCardCount: {
    fontSize: 10,
    fontWeight: 700,
    lineHeight: "20px",
  },
  itemCardTotal: {
    fontSize: 12,
    fontWeight: 700,
    lineHeight: "14px",
  },
  downloadBillBtn: {
    marginLeft: 18,

    "& .ant-btn": {
      border: "none",
    },
  },
}));

export { useStyles };
