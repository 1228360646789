// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getMessaging, onMessage, getToken } from "firebase/messaging";
import firebase from "firebase/app";
import "firebase/messaging";
import localforage from "localforage";

const firebaseConfig = {
  Customer: {
    apiKey: "AIzaSyAUg5WepSqvmc8TGZf1kQRrzoE-ebLj-TA",
    authDomain: "raseet-application.firebaseapp.com",
    projectId: "raseet-application",
    storageBucket: "raseet-application.appspot.com",
    messagingSenderId: "943623577016",
    appId: "1:943623577016:web:b5019c7a6c187dda8c834d",
    measurementId: "G-YEQWMDLMFM",
  },
  Retailer: {
    apiKey: "AIzaSyAHL8iVD_u9EU1bLd9XqPUmLmrfX-0EUbI",
    authDomain: "raseet-retailer-application.firebaseapp.com",
    projectId: "raseet-retailer-application",
    storageBucket: "raseet-retailer-application.appspot.com",
    messagingSenderId: "649544120783",
    appId: "1:649544120783:web:c757484513eaa24f98faba",
    measurementId: "G-W4G3PCJ07J",
  },
};

// Initialize Firebase
let userType = "Customer";

userType = localStorage.getItem("userType") ?? "Customer";

firebase.initializeApp(firebaseConfig[userType]);

let messaging = null;

if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
}

const vapidKey = {
  Customer:
    "BD4w2zwrRkL4NFBAb1GhsEVNq13TQqrG4R-SJfpdTq9Sk6Tfvhz7GkqLkYN5klkxZN2GzORQunQWSpFWqCW7QRo",
  Retailer:
    "BD9Sf2m-ao9n_Kyc7dtiTGCrqkcoVjCK9sVsNwEszx7EQ6JX_t3gwN_M8Ar0iM1v0nli617QyxhEpicphUWcw7Q",
};

export const fetchToken = async () => {
  let token = "";

  await messaging
    .getToken({
      vapidKey: vapidKey[userType],
    })
    .then((currentToken) => {
      if (currentToken) {
        // Send the token to your server and update the UI if necessary
        // console.log(currentToken);
        token = currentToken;

        // ...
      } else {
        // Show permission request UI
        Notification.requestPermission();
        console.log(
          "No registration token available. Request permission to generate one."
        );
        // ...
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // ...
    });

  return token;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
