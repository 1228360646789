import { Table } from "antd";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import customeScrollbarStyle from "components/style/CustomScrollbar";
import { SizeType } from "antd/es/config-provider/SizeContext";
import OrderItemsModal from "components/modals/OrderItemsModal";
import ShowPrescriptionModal from "components/modals/ShowPrescriptionModal";

type CustomerSummaryTableCardProps = {
  items: any;
  total: any;
  size: SizeType;
  customColumns?: any;
};

function CustomerSummaryTableCard({
  customColumns,
  items,
  total,
  size,
}: CustomerSummaryTableCardProps) {
  const classes = useStyles();
  const [modalVisible, setModalVisible] = useState(false);
  const [modalOrderId, setModalOrderId] = useState("");
  const [modalInvoiceNumber, setModalInvoiceNumber] = useState("");
  const [prescriptionModalVisible, setPrescriptionModalVisible] =
    useState(false);
  const [modalPrescriptionId, setModalPrescriptionId] = useState("");

  const onOrderIdClick = (orderId: any, invoiceNumber: any) => {
    setModalOrderId(orderId);
    setModalInvoiceNumber(invoiceNumber);
    setModalVisible(true);
  };

  const showPrescription = (prescription_id: any) => {
    setModalPrescriptionId(prescription_id);
    setPrescriptionModalVisible(true);
  };

  const columns: any = [
    {
      title: "#",
      dataIndex: "key",
      align: "center",
      width: "60px",
    },
    {
      title: "Invoice Number",
      dataIndex: "invoice_number",
      width: "125px",
    },
    {
      title: "Date",
      dataIndex: "date",
      width: "150px",
    },
    {
      title: "Value",
      dataIndex: "value",
      render: (grandtotal: any) => `₹ ${grandtotal}`,
      width: "120px",
    },
    {
      title: "Reorder",
      dataIndex: "reorder",
      width: "100px",
      render: (reorder: any) =>
        reorder === true ? (
          <p
            style={{
              fontFamily: "Roboto",
              textDecoration: "underline",
              display: "contents",
              cursor: "pointer",
            }}
          >
            Yes
          </p>
        ) : (
          "No"
        ),
    },
    {
      title: "Prescription",
      dataIndex: "prescription_id",
      width: "120px",
      render: (prescription_id: any) =>
        prescription_id ? (
          <p
            style={{
              fontFamily: "Roboto",
              textDecoration: "underline",
              display: "contents",
              cursor: "pointer",
            }}
            onClick={() => showPrescription(prescription_id)}
          >
            View prescriptions
          </p>
        ) : (
          "No prescription"
        ),
    },
    {
      title: "Items",
      dataIndex: "invoice_number",
      width: "140px",
      render: (invoice_number: any, record: any) => (
        <p
          style={{
            fontFamily: "Roboto",
            display: "contents",
            cursor: "pointer",
            fontWeight: 500,
          }}
          onClick={() => {
            return onOrderIdClick(
              record.order_id ?? record.reorder_id,
              invoice_number
            );
          }}
        >
          Show details
        </p>
      ),
    },
  ];

  return (
    <div className={classes.container}>
      <div className="pageHeader">
        <div className={classes.tableTitle}>Past Purchases</div>
        <div className={classes.itemsCount}>{items.length} Items</div>
      </div>
      <Table
        className={classes.orderItemsTable}
        pagination={false}
        columns={customColumns ?? columns}
        dataSource={items}
        size={size}
        // summary={orderSummary}
        scroll={{ y: 200, x: "100%" }}
      />
      <div className={classes.customerLifetimeValue}>
        Customer Lifetime Value : ₹ {"  " + (+total)?.toFixed(2)}
      </div>
      <OrderItemsModal
        orderId={modalOrderId}
        order={items.find(
          (i: any) =>
            i?.order_id === modalOrderId || i.reorder_id === modalOrderId
        )}
        invoiceNumber={modalInvoiceNumber}
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false);
          setModalOrderId("");
        }}
      />
      <ShowPrescriptionModal
        prescriptionId={modalPrescriptionId}
        visible={prescriptionModalVisible}
        onCancel={() => setPrescriptionModalVisible(false)}
      />
    </div>
  );
}

const trBorderRadius = "15px";

const useStyles = createUseStyles(({ colors }: Theme) => ({
  container: {
    width: "100%",
    padding: "0.8rem",
    borderRadius: "5px",
    backgroundColor: "rgba(34, 94, 119, 0.1)",

    "& > .pageHeader": {
      minHeight: 32,
      display: "flex",
      justifyContent: "flex-start",
    },
  },

  summaryRow: {
    "& *": {
      fontSize: "12px !important",
      color: "#000",
    },
  },

  summaryText: {
    textAlign: "right",
  },

  orderItemsTable: {
    width: "100%",

    "& *": {
      fontSize: 12,
    },

    // auto resizing table bug fix
    "& .ant-spin-nested-loading": {
      overflow: "hidden",
    },

    // customizing
    "& .ant-table": {
      width: "100%",
      background: "transparent",
      overflow: "hidden",
      borderRadius: trBorderRadius,
    },

    "& .ant-table-header": {
      overflow: "hidden",
      borderRadius: trBorderRadius,
      borderBottomRightRadius: 15,
    },

    "& thead": {
      background: colors.light100,
      "& th": {
        height: 25,
        paddingTop: "0 !important",
        paddingBottom: "0 !important",
        color: "#838383",
      },
    },

    "& tbody .ant-table-row td": {
      paddingTop: "6px !important",
      paddingBottom: "6px !important",
      color: "#000",
    },

    // customizing horizontal scrollbar for antBody
    "& .ant-table-body": {
      ...customeScrollbarStyle,
    },

    "& .ant-table-row:hover": {
      "& td": {
        background: "transparent !important",
        "&:first-child": {
          borderBottomLeftRadius: trBorderRadius,
          borderTopLeftRadius: trBorderRadius,
        },
        "&:last-child": {
          borderTopRightRadius: trBorderRadius,
          borderBottomRightRadius: trBorderRadius,
        },
      },
    },
  },
  tableTitle: {
    margin: "0 0.7rem",
    fontSize: 14,
    fontWeight: 600,
    color: colors.dark1000,
  },
  unavailableItem: {
    color: "#8D7F7F",
  },
  itemsCount: {
    fontSize: "0.9rem",
    color: "#E76F51",
    fontWeight: 600,
  },
  availableIcon: {
    marginLeft: "5px",
    color: "#359C10",
  },
  unavailableIcon: {
    marginLeft: "5px",
    color: "#D10B0B",
  },
  textCenter: {
    textAlign: "center",
  },
  textUnderline: {
    textDecoration: "underline",
    "& .prescriptions": {
      maxWidth: "3rem",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  customerLifetimeValue: {
    paddingLeft: 20,
    paddingTop: 10,
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.85)",
  },
  "@media (max-width: 1050px)": {
    orderItemsTable: {
      "& .ant-table-thead, & .ant-table-tbody": {
        fontSize: 12,
      },
    },
  },
}));

export default CustomerSummaryTableCard;
