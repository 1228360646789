import { RightOutlined, StarOutlined } from "@ant-design/icons";
import { Button, Empty, Table, Select } from "antd";
import { changeCustomerPriority } from "api/retailerApi";
import OrderSummaryTableCard from "components/cards/OrderSummaryTableCard";
import SearchBox from "components/dataEntry/SearchBox";
import Loader from "components/loader/Loader";
import { RetailerDataType } from "components/pages/retailerDashboard";
import { fetchReorderCustomers } from "components/pages/retailerDashboard/APIs/fetchReorderCustomers";
import React, { useState, useEffect } from "react";
import { createUseStyles } from "react-jss";
import IncomingOrdersCard from "./IncomingOrdersCard";
import RadioToggler from "./RadioToggler";

interface IncomingOrdersProps {
  retailerData: RetailerDataType;
}

export default function IncomingOrders({ retailerData }: IncomingOrdersProps) {
  const classes = useStyles();
  const [incomingOrdersData, setIncomingOrdersData] = useState({
    reorders: [],
    priorityOrders: [],
    selectedOrderIndex: -1,
    loading: true,
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [endOfList, setEndOfList] = useState(false);
  const [orderCategory, setOrderCategory] = useState("All");
  const [activeStatus, setActiveStatus] = useState("");

  const handleSearch = (value: string) => {
    fetchInitialData(value);
  };

  const handleScroll = async (e: any) => {
    //console.log("test")
    if (endOfList) {
      return;
    }
    //check if reached bottom
    let element = e.target;
    //console.log(element.scrollHeight, element.scrollTop, element.clientHeight);
    if (
      element.scrollHeight - Math.round(element.scrollTop) ===
      element.clientHeight
    ) {
      setPageNumber((pageNumber: any) => pageNumber + 1);
      //console.log("eof");
    }
  };

  const toggleCustomerPriorityCallback = (
    isPriorityNow: boolean,
    customer_id: any
  ) => {
    let newData: any = {};
    if (isPriorityNow) {
      newData.reorders = incomingOrdersData.reorders.map((item: any) =>
        item.user_id === customer_id ? { ...item, priority: true } : item
      );
    } else {
      newData.reorders = incomingOrdersData.reorders.map((item: any) =>
        item.user_id === customer_id ? { ...item, priority: false } : item
      );
    }
    newData.priorityOrders = newData.reorders.filter(
      (item: any) => item.priority
    );

    console.log(newData);
    setIncomingOrdersData({ ...incomingOrdersData, ...newData });
  };

  const toggleCustomerPriority = async (customerUserId: any) => {
    if (customerUserId !== "")
      await changeCustomerPriority(customerUserId, retailerData?.ID).then(
        (result) => {
          toggleCustomerPriorityCallback(
            result.priority.includes(`${retailerData?.ID}`),
            result.user_id
          );
        }
      );
  };

  const getOrderStatus = (status: number) => {
    let orderStatus = "";

    if (status == 1) {
      orderStatus = "Placed";
    } else if (status == 2) {
      orderStatus = "Packed";
    } else if (status == 3) {
      orderStatus = "Shipped";
    } else if (status == 4) {
      orderStatus = "Partially Delivered";
    } else if (status == 5) {
      orderStatus = "Delivered";
    } else if (status >= 6) {
      orderStatus = "Closed";
    }

    return orderStatus;
  };

  async function fetchInitialData(search?: any, page?: any) {
    let reorderData = await fetchReorderCustomers(
      retailerData.ID,
      page ?? pageNumber,
      search ?? "",
      activeStatus
    );

    setEndOfList(reorderData?.length === 0);

    if (pageNumber === 1 || page === 1) {
      setIncomingOrdersData({
        reorders: filterReorderCustomers(reorderData, "All"),
        priorityOrders: filterReorderCustomers(reorderData, "Priority"),
        selectedOrderIndex: 0,
        loading: false,
      });
    } else {
      reorderData = [
        ...incomingOrdersData.reorders,
        ...incomingOrdersData.priorityOrders,
        ...reorderData,
      ];
      setIncomingOrdersData({
        reorders: filterReorderCustomers(reorderData, "All"),
        priorityOrders: filterReorderCustomers(reorderData, "Priority"),
        selectedOrderIndex: 0,
        loading: false,
      });
    }
  }

  function filterReorderCustomers(reorderData: any, category: string) {
    if (category === "All") {
      return reorderData;
    }
    var priorityReorders = [];
    for (var i = 0; i < reorderData?.length; i++) {
      if (reorderData[i].priority) {
        priorityReorders.push(reorderData[i]);
      }
    }
    return priorityReorders;
  }

  useEffect(() => {
    fetchInitialData();
  }, [pageNumber, activeStatus]);

  useEffect(() => {
    if (retailerData.ID && incomingOrdersData.reorders.length === 0) {
      fetchInitialData();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retailerData.ID]);

  useEffect(() => {
    if (incomingOrdersData.loading === false) {
      setIncomingOrdersData({
        reorders: incomingOrdersData.reorders,
        priorityOrders: incomingOrdersData.priorityOrders,
        selectedOrderIndex: 0,
        loading: false,
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderCategory]);

  if (incomingOrdersData.loading) {
    return <Loader />;
  }

  return (
    <div className={classes.incomingOrders}>
      <RadioToggler onChange={setOrderCategory} />

      <SearchBox
        onSearch={handleSearch}
        suffix={
          <Select
            defaultValue=""
            className={classes.statusSelector}
            onChange={setActiveStatus}
          >
            <Select.Option value="">All</Select.Option>
            <Select.Option value="active">Active</Select.Option>
            <Select.Option value="completed">Completed</Select.Option>
          </Select>
        }
      />

      <div
        className={classes.incomingOrdersCardsWrapper}
        onScroll={handleScroll}
      >
        {incomingOrdersData[
          orderCategory === "All" ? "reorders" : "priorityOrders"
        ].length ? (
          incomingOrdersData[
            orderCategory === "All" ? "reorders" : "priorityOrders"
          ].map((reorder: any) => (
            <IncomingOrdersCard
              orderStatus={reorder.order_status}
              date={reorder?.invoice_date ?? reorder.created_at}
              paymentStatus={reorder.payment_status}
              customerName={reorder?.name}
              orderId={reorder.id}
              isPriority={reorder.priority}
              onPriorityToggle={() => toggleCustomerPriority(reorder?.user_id)}
              orderAmount={reorder.grandtotal}
            />
          ))
        ) : (
          <div className={classes.emptyContainer}>
            <Empty description="No data found" />
          </div>
        )}
      </div>
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  incomingOrders: {
    height: "calc(100vh - 130px)",
    overflow: "hidden",
    padding: "0 2rem",
    background: "#fbfbfb",
  },
  incomingOrdersCardsWrapper: {
    height: "calc(100vh - 285px)",
    overflowY: "auto",
  },
  emptyContainer: {
    height: "50vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  statusSelector: {
    width: 110,
    border: "none",
  },
}));
