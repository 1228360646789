import { ArrowLeftOutlined } from "@ant-design/icons";
import { Col, Layout, Row } from "antd";
import React from "react";
import OnboardingWrapper from "components/OnboardingWrapper";
import { Tabs } from "antd";
import { useHistory } from "react-router-dom";
import { ChangePassword } from "components/ChangePassword";
import { EditAddress } from "components/EditAddress";
import { useStyles } from "./EditProfilePage.style";

export const EditProfilePage = () => {
  const history = useHistory();
  const { Header, Content } = Layout;
  const Arrow = ArrowLeftOutlined;
  const classes = useStyles();
  const { TabPane } = Tabs;

  function callback(key: any) {
    console.log(key);
  }

  return (
    <Layout className={classes.layout}>
      <Header className={classes.header}>
        <Row>
          <Col className={classes.col} xs={{ span: 4 }} lg={{ span: 2 }}>
            <Arrow className={classes.arrow} onClick={history.goBack} />
          </Col>
          <Col
            className={classes.editProfile}
            xs={{ span: 12 }}
            lg={{ span: 14 }}
          >
            Edit Profile
          </Col>
        </Row>
      </Header>
      <Content>
        <div className={classes.tab}>
          <Tabs
            defaultActiveKey="1"
            centered
            onChange={callback}
            className={classes.editProfileTabs}
          >
            <TabPane tab="Edit Address" key="1">
              <OnboardingWrapper height="full">
                <EditAddress />
              </OnboardingWrapper>
            </TabPane>
            <TabPane tab="Change Password" key="2">
              <OnboardingWrapper height="full">
                <ChangePassword />
              </OnboardingWrapper>
            </TabPane>
          </Tabs>
        </div>
      </Content>
    </Layout>
  );
};
