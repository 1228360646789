import { RightOutlined, StarFilled, StarOutlined } from "@ant-design/icons";
import { Button, Empty } from "antd";
import { changeCustomerPriority } from "api/retailerApi";
import SearchBox from "components/dataEntry/SearchBox";
import Loader from "components/loader/Loader";
import { RetailerDataType } from "components/pages/retailerDashboard";
import {
  getCustomerPurchases,
  getRetailStoreCustomers,
} from "components/pages/retailerDashboard/APIs/getRetailStoreCustomers";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import RadioToggler from "../IncomingOrders/RadioToggler";

interface CustomerListProps {
  retailerData: RetailerDataType;
  onSelectedCustomerChange: (customer: any) => void;
}

interface customerType {
  customers: Array<any>;
  priorityCustomers: Array<any>;
  selectedCustomerIndex: number;
  loading: boolean;
}

export default function CustomerList({
  retailerData,
  onSelectedCustomerChange,
}: CustomerListProps) {
  const classes = useStyles();
  const [customersData, setCustomersData] = useState<customerType>({
    customers: [],
    priorityCustomers: [],
    selectedCustomerIndex: -1,
    loading: true,
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [endOfList, setEndOfList] = useState(false);
  const [customerCategory, setCustomerCategory] = useState("All");
  const [customerCount, setCustomerCount] = useState(0);

  const handleSearch = (value: string) => {
    fetchInitialData(value);
  };

  const toggleCustomerPriorityCallback = (
    isPriorityNow: boolean,
    customer_id: any
  ) => {
    let newData: any = {};
    if (isPriorityNow) {
      newData.customers = customersData.customers.map((item: any) =>
        item.user_id === customer_id ? { ...item, priority: true } : item
      );
    } else {
      newData.customers = customersData.customers.map((item: any) =>
        item.user_id === customer_id ? { ...item, priority: false } : item
      );
    }
    newData.priorityCustomers = newData.customers.filter(
      (item: any) => item.priority
    );

    console.log(newData);
    setCustomersData({ ...customersData, ...newData });
  };

  const toggleCustomerPriority = async (customerUserId: any) => {
    if (customerUserId !== "")
      await changeCustomerPriority(customerUserId, retailerData?.ID).then(
        (result) => {
          toggleCustomerPriorityCallback(
            result.priority.includes(`${retailerData?.ID}`),
            result.user_id
          );
        }
      );
  };

  function filterCustomersCategory(customers: any, category: string) {
    if (category === "All") {
      return customers;
    }
    var priorityCustomers = [];
    for (var i = 0; i < customers?.length; i++) {
      if (customers[i].priority) {
        priorityCustomers.push(customers[i]);
      }
    }
    return priorityCustomers;
  }

  async function fetchInitialData(search?: string) {
    var customers = await getRetailStoreCustomers(
      retailerData.ID,
      pageNumber,
      search ?? ""
    );
    setCustomerCount(customers.count.count);
    customers = customers.items;

    setEndOfList(customers.length === 0);
    if (customers.length === 0) {
      return setCustomersData({
        ...customersData,
        loading: false,
      });
    }

    for (let i in customers) {
      customers[i] = {
        ...customers[i],
        retail_store_id: retailerData.ID,
        key: customers[i].customer_id,
      };
    }

    var priorityCustomers = filterCustomersCategory(customers, "Priority");

    setCustomersData((customersData: customerType) => {
      return {
        ...customersData,
        customers: !!search
          ? customers
          : [...customersData.customers, ...customers],
        selectedCustomerIndex: 0,
        loading: false,
      };
    });
  }

  const onCustomerClick = (customer: any) => {
    onSelectedCustomerChange(customer);
  };

  const handleScroll = async (e: any) => {
    //console.log("test")
    if (endOfList) {
      return;
    }
    //check if reached bottom
    let element = e.target;
    //console.log(element.scrollHeight, element.scrollTop, element.clientHeight);
    if (
      element.scrollHeight - Math.round(element.scrollTop) ===
      element.clientHeight
    ) {
      setPageNumber((pageNumber: any) => pageNumber + 1);
      //console.log("eof");
    }
  };

  useEffect(() => {
    if (customersData.loading || pageNumber !== 1) {
      fetchInitialData();
    }
  }, [pageNumber]);

  useEffect(() => {
    if (customersData.loading === false) {
      setCustomersData({
        customers: customersData.customers,
        priorityCustomers: customersData.priorityCustomers,
        selectedCustomerIndex: 0,
        loading: false,
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerCategory]);

  if (customersData.loading) {
    return <Loader />;
  }

  return (
    <div className={classes.customerList}>
      <RadioToggler onChange={setCustomerCategory} />

      <SearchBox onSearch={handleSearch} />

      <div className={classes.customerListWrapper} onScroll={handleScroll}>
        {customersData[
          customerCategory === "All" ? "customers" : "priorityCustomers"
        ].length ? (
          customersData[
            customerCategory === "All" ? "customers" : "priorityCustomers"
          ].map((customer) => (
            <CustomerListCard
              customer={customer}
              onPriorityToggle={toggleCustomerPriority}
              onCustomerClick={onCustomerClick}
            />
          ))
        ) : (
          <div className={classes.emptyContainer}>
            <Empty description="No data found" />
          </div>
        )}
      </div>
    </div>
  );
}
const CustomerListCard = ({
  customer,
  onPriorityToggle,
  onCustomerClick,
}: any) => {
  const classes = useStyles({ isPriority: customer?.priority });

  const handlePriorityToggle = (e: any) => {
    e.stopPropagation();
    onPriorityToggle(customer?.user_id);
  };

  const handleCustomerCardClick = () => {
    onCustomerClick(customer);
  };

  return (
    <div className={classes.customerListCard} onClick={handleCustomerCardClick}>
      <div className={classes.CustomerListCardInner}>
        <Button
          size="large"
          type="text"
          className={classes.priorityToggler}
          onClick={handlePriorityToggle}
          icon={customer?.priority === true ? <StarFilled /> : <StarOutlined />}
        />

        <div>
          <div className={classes.customerName}>{customer?.name}</div>
          <div className={classes.phoneNo}>{customer?.phone_no}</div>
        </div>

        <div className={classes.customerSum}>
          ₹{(+customer?.sum).toFixed(2)}
        </div>

        <Button size="large" type="text" icon={<RightOutlined />} />
      </div>
    </div>
  );
};

const useStyles = createUseStyles(({ colors }: Theme) => ({
  customerList: {
    overflow: "hidden",
    padding: "0 2rem",
    background: "#fbfbfb",
  },
  customerListWrapper: {
    height: "calc(100vh - 285px)",
    overflowY: "auto",
  },
  customerListCard: {
    marginBottom: 15,
    padding: [15, 5, 15, 10],
    background: "rgba(34, 94, 119, 0.1)",
    borderRadius: 5,
  },
  CustomerListCardInner: {
    display: "flex",
    alignItems: "center",
  },
  priorityToggler: {
    "& .anticon": {
      color: (props) => (props?.isPriority ? "#E76F51" : ""),
    },
  },
  customerName: {
    fontWeight: 600,
  },
  phoneNo: {
    fontSize: 12,
    marginTop: 2,
  },
  customerSum: {
    fontSize: 14,
    fontWeight: 600,
    marginLeft: "auto",
  },
  emptyContainer: {
    height: "50vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
