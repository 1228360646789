import {
  DeleteOutlined,
  SearchOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Button,
  Card,
  Col,
  Empty,
  Input,
  message,
  Row,
  Space,
  Typography,
} from "antd";
import {
  deleteCartItem,
  getCartItems,
  getCatalogue,
  getCustomerCart,
  getRetailStores,
  insertCart,
  insertCartItems,
  updateCartItemsByProductId,
} from "api/api";
import NumberSelect from "components/dataEntry/NumberSelect";
import Loader from "components/loader/Loader";
import { RetailerDataType } from "components/pages/retailerDashboard";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { Link, useRouteMatch } from "react-router-dom";

type RouteParams = {
  customerUserId: string;
};

const getPriceWithoutGst = (gstPercentage: number, priceWithGst: number) => {
  let gstAmount = priceWithGst - priceWithGst * (100 / (100 + gstPercentage));
  return priceWithGst - gstAmount;
};

const getPercentageOff = (data: any) => {
  let absDiscount = +data.pack_price - +data.strike_price;
  return Math.round((absDiscount / +data.pack_price) * 100);
};

interface PlaceReorderProps {
  retailerData: RetailerDataType;
}

export default function PlaceReorder({ retailerData }: PlaceReorderProps) {
  const classes = useStyle();
  const {
    params: { customerUserId },
  } = useRouteMatch<RouteParams>() || {};

  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLoading, setPageloading] = useState(true);
  const [endOfList, setEndOfList] = useState(false);
  const [cartId, setCartId] = useState(0);
  const [countChange, setCountChange] = useState(false);
  const [itemsCount, setItemsCount] = useState(0);

  const handleScroll = async (e: any) => {
    if (endOfList) {
      return;
    }
    //check if reached bottom
    let element = e.target;
    if (
      element.scrollHeight - Math.round(element.scrollTop) ===
      element.clientHeight
    ) {
      setPageNumber((curr) => curr + 1);
    }
  };

  const fetchCartItems = async () => {
    if (+cartId > 0) {
      let cartItems = await getCartItems(+cartId);
      setItemsCount(cartItems.length);
    } else if (itemsCount > 0) {
      setItemsCount(itemsCount);
    }
    setCountChange(false);
  };

  const getCartId = async () => {
    let cartIdTemp = 0;
    let cartRes = await getCustomerCart(+retailerData.ID, +customerUserId);

    if (cartRes.length) {
      cartIdTemp = cartRes[0].id;
    } else {
      let cartData = {
        retail_store_id: retailerData.ID,
        payment_status: false,
        discount: 0,
        gst: 0,
        price_with_gst: 0,
        price_with_out_gst: 0,
        user_id: customerUserId,
      };
      // Create the cart
      const insertCartRes = await insertCart(cartData);
      if (insertCartRes?.length) {
        cartIdTemp = insertCartRes[0].id;
      }
    }

    setCartId(cartIdTemp);
    return cartIdTemp;
  };

  const onAddHandler = async (Product: any) => {
    if (+Product.quantity === 0) {
      Product.quantity = 1;
    }
    if (!!!Product.cart_id || Product.cart_id === "0") {
      try {
        let cartId: any = await getCartId();
        Product.cart_id = cartId;
        setCartId(Product.cart_id);
      } catch (e) {
        console.log(e);
      }
    }

    try {
      let productPrice = Product?.strike_price ?? Product.pack_price;
      let sub_total = getPriceWithoutGst(
        +Product.sgst + +Product.cgst,
        +productPrice * +Product.quantity
      );
      let newCartItem = {
        product_section: "other_products",
        product_id: Product?.product_id ?? Product.id,
        product_sku: Product?.product_sku,
        quantity: Product.quantity,
        cart_id: !!Product.cart_id ? +Product.cart_id : 0,
        cgst: Product.cgst,
        sgst: Product.sgst,
        discount: Product.discount,
        total: (+productPrice * +Product.quantity).toFixed(2),
        sub_total: sub_total.toFixed(2),
      };

      await insertCartItems(newCartItem).then((res) => {
        if (res && res.id) {
          message.success("Items added to cart");
          setCountChange(true);
          // setLoading(true);
          setProducts((curr) => {
            let index = curr.findIndex(
              (i) => i.product_sku === newCartItem.product_sku
            );

            console.log(index);
            if (index >= 0) {
              curr[index].quantity = newCartItem.quantity;
              curr[index].cart_id = newCartItem.cart_id;
              curr[index].cart_item_id = res.id;
              curr[index].retailer_specific_product_id = res.product_id;
            }

            return [...curr];
          });
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onUpdateHandler = async (Product: any) => {
    try {
      let productPrice = Product?.strike_price ?? Product.pack_price;
      let sub_total = getPriceWithoutGst(
        +Product.sgst + +Product.cgst,
        +productPrice * +Product.quantity
      );

      await updateCartItemsByProductId(
        +Product.cart_id,
        Product?.retailer_specific_product_id ??
          Product?.product_id ??
          Product.id,
        {
          quantity: Product.quantity,
          total: (+productPrice * +Product.quantity).toFixed(2),
          sub_total: sub_total.toFixed(2),
        }
      ).then((res) => {
        if (res?.length) {
          message.warning("Qunatity has been updated");

          setProducts((curr) => {
            let index = curr.findIndex(
              (i) => i.product_sku === Product.product_sku
            );

            if (index >= 0) {
              curr[index].quantity = Product.quantity;
            }

            return [...curr];
          });
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const removeCartItem = async (Product: any) => {
    await deleteCartItem(+Product?.cart_item_id).then((result) => {
      if (result.length) {
        message.success("Item removed from cart");
        setCountChange(true);

        setProducts((curr) => {
          let index = curr.findIndex(
            (i) => i.product_sku === Product.product_sku
          );

          if (index >= 0) {
            curr[index].quantity = "0";
            curr[index].cart_item_id = "0";
          }

          return [...curr];
        });
      }
    });
  };

  const fetchInitialData = async () => {
    let [pCount, ...productsRes] = await getCatalogue(
      "/catalogue/products",
      +retailerData.ID,
      pageNumber,
      searchQuery
    );

    if (productsRes.length % 20 !== 0) {
      setEndOfList(true);
    }

    if (pageNumber > 1) {
      setProducts((curr) => [...curr, ...productsRes]);
    } else {
      setCartId(productsRes?.[0]?.cart_id ?? 0);
      setProducts(productsRes);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCartItems();
  }, [cartId, countChange]);

  useEffect(() => {
    fetchInitialData();
  }, [pageNumber, searchQuery, loading]);

  // if (pageLoading) {
  //   return <Loader />;
  // }

  return (
    <div className={classes.placeReorder}>
      <Space className={classes.pageHeader}>
        <Input
          size="large"
          suffix={<SearchOutlined />}
          className={classes.searchInput}
          placeholder="Search for medicines..."
          value={searchQuery}
          onChange={(e) => {
            setPageNumber(1);
            setSearchQuery(e.target.value);
          }}
        />

        <Badge size="small" count={itemsCount}>
          <Link
            to={`/retailer/dashboard/customer-details/${customerUserId}/place-reorder/${cartId}`}
          >
            <Button size="large" type="text" icon={<ShoppingCartOutlined />} />
          </Link>
        </Badge>
      </Space>

      <Space
        size={10}
        direction="vertical"
        className={classes.productsWrapper}
        // @ts-ignore
        onScroll={handleScroll}
      >
        {!loading ? (
          products.length ? (
            products.map((product, index) => (
              <ProductCard
                key={index}
                data={product}
                onAdd={onAddHandler}
                onUpdate={onUpdateHandler}
                onRemove={removeCartItem}
                retailerData={retailerData}
              />
            ))
          ) : (
            <div className={classes.emptyWrapper}>
              <Empty description="No results found" />
            </div>
          )
        ) : (
          <Loader height="60vh" />
        )}
      </Space>
    </div>
  );
}

const ProductCard = ({
  data,
  onAdd,
  onUpdate,
  onRemove,
  retailerData,
}: any) => {
  const classes = useStyle();

  return (
    <Card className={classes.productCard}>
      {data.prescription === "PRESCRIPTION" && (
        <Typography.Text className={classes.prescriptionText}>
          Prescription Required
        </Typography.Text>
      )}
      <Row justify="space-between" className={classes.productCardContent}>
        <Col className={classes.productCardLeft}>
          <Link
            to={`/dashboard/stores/${retailerData.ID}/product/${data?.product_sku}?source=other_products`}
          >
            <Typography.Text strong className={classes.productName}>
              {data.name}
            </Typography.Text>
            <Typography.Text className={classes.productStripText}>
              {data.pack}
            </Typography.Text>
          </Link>

          <Typography.Text className={classes.productManufacturer}>
            {data.manufacturer}
          </Typography.Text>
        </Col>
        <Col className={classes.productCardRight}>
          <div>
            <Typography.Text strong className={classes.sellingPrice}>
              ₹{parseFloat(data.strike_price ?? data.pack_price).toFixed(2)}
            </Typography.Text>
            {!!data.strike_price && +data.strike_price < +data.pack_price && (
              <>
                <Typography.Text
                  type="secondary"
                  delete
                  className={classes.deletedPrice}
                >
                  ₹{(+data.pack_price).toFixed(2)}
                </Typography.Text>
                <Typography.Text className={classes.offPercentage}>
                  <i>{getPercentageOff(data)}% off</i>
                </Typography.Text>
              </>
            )}
          </div>

          {+data.cart_item_id > 0 ? (
            <div className={classes.productActions}>
              <Button
                size="small"
                type="text"
                icon={<DeleteOutlined />}
                onClick={() => onRemove(data)}
              />
              <NumberSelect
                whiteBg
                till={100}
                defaultValue={data.quantity}
                style={{ marginTop: 15 }}
                onChange={(qty: any) => onUpdate({ ...data, quantity: qty })}
              />
            </div>
          ) : (
            <Button
              type="primary"
              className={classes.addButton}
              onClick={() => onAdd(data)}
            >
              Add
            </Button>
          )}
        </Col>
      </Row>
    </Card>
  );
};

const useStyle = createUseStyles(({ colors }: Theme) => ({
  placeReorder: {
    height: "calc(100vh - 130px)",
    overflow: "auto",
    padding: "0.5rem",
    background: "#fbfbfb",
  },
  pageHeader: {
    padding: [10, 15],
    display: "flex",
    alignItems: "center",
    "& .ant-space-item:first-child": {
      flex: 1,
    },
  },
  searchInput: {
    "& input": {
      fontSize: 12,
    },
  },

  productsWrapper: {
    width: "100%",
    paddingBottom: 50,
    height: "calc(100vh - 200px)",
    overflowY: "auto",
    padding: 15,
  },
  productCard: {
    height: 105,
    width: "100%",
    borderRadius: 3,
    overflow: "hidden",
    position: "relative",

    background: "#F4F4F4",
    color: colors.textPrimary,
    border: "0.3px solid #D9D9D9",
    boxShadow:
      "-1px -1px 2px rgba(0, 0, 0, 0.1), 1px 1px 2px rgba(0, 0, 0, 0.1)",

    "& .ant-card-body": {
      padding: [10, 0, 0],
      height: "100%",
    },
  },
  productCardContent: {
    padding: 10,
    height: "100%",
  },
  productCardLeft: {
    flex: 1,
    maxWidth: "80%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  productCardRight: {
    textAlign: "right",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  prescriptionText: {
    width: "100%",
    // padding: 2,
    fontSize: 8,
    textAlign: "center",
    display: "block",
    color: colors.light100,
    backgroundColor: "#F47070",
    position: "absolute",
    top: 0,
    left: 0,
  },
  productName: {
    display: "block",
    fontSize: 14,
    fontWeight: 600,
    wordBreak: "break-word",
  },
  productStripText: {
    display: "block",
    fontSize: 10,
  },
  productManufacturer: {
    fontSize: 8,
    fontStyle: "italic",
    color: colors.textPrimary,
  },
  sellingPrice: {
    fontSize: 12,
    display: "block",
  },
  deletedPrice: {
    fontSize: 10,
    display: "block",
  },
  offPercentage: {
    fontSize: 8,
    display: "block",
  },
  addButton: {
    width: 45,
    height: 21,
    fontSize: 10,
    marginTop: 15,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  productActions: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    "& > .ant-btn": {
      marginRight: 5,
      height: 21,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      // "& .anticon":{}
    },
  },
  emptyWrapper: {
    height: "60vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
