import React from "react";
import { message } from "antd";
import RegisterForm from "components/forms/RegisterForm";

type RegisterViewProps = {
  onComplete: (
    token: string,
    user_id: string,
    user_type: string,
    user_name: string,
    mobile_number: string
  ) => void;
  isRetailer: boolean;
};

function RegisterView({ onComplete, isRetailer }: RegisterViewProps) {
  const onRegisterUser = async ({ confirmPassword, ...values }: any) => {
    const url = process.env.REACT_APP_API_NODE_BASE_URL;
    let user_type = "";
    if (isRetailer) {
      user_type = "Retailer";
    } else {
      user_type = "Customer";
    }
    let data = {
      name: `'${values.name}'`,
      mobile_number: `'${values.mobileNumber}'`,
      email: "''",
      password_digest: `${values.password}`,
      user_type: `'${user_type}'`,
    };
    await fetch(url + "/insertUsers", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.auth) {
          onComplete(
            json.token,
            json.user_id,
            json.user_type,
            json.user_name,
            json.mobile_number
          );
        } else {
          message.info(json.error);
        }
      });
  };

  return <RegisterForm onSubmit={onRegisterUser}  defaultValues={{
    name: "",
    mobileNumber: "",
    otp: "",
    password: "",
    confirmPassword: "",
  }} />;
}

export default RegisterView;
