import React, { useEffect, useState } from "react";
import {
  Button,
  message,
  Modal,
  ModalProps,
  Space,
  Table,
  Typography,
} from "antd";
import { createUseStyles } from "react-jss";
import { deletePricingRule, getPricingRules } from "api/retailerApi";
import Loader from "components/loader/Loader";
import moment from "moment";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import AddPricingRuleModal from "./AddPricingRuleModal";

interface PricingRulesModalProps extends ModalProps {
  retailerData: {
    ID: number;
    Name: string;
  };
}

export default function PricingRulesModal({
  visible,
  onCancel,
  retailerData,
}: PricingRulesModalProps) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [pricingRules, setPricingRules] = useState([]);
  const [addPricingRuleModalVisible, setAddPricingRuleModalVisible] =
    useState(false);

  const handleDeleteRuleClick = (rule: any) => () => {
    Modal.confirm({
      title: "Are you sure you want to delete this rule?",
      okText: "Confirm",
      onOk: async () => {
        return await deletePricingRule(+rule.id).then((res) => {
          message.success(res?.message);
          setLoading(true);
        });
      },
    });
  };

  const fetchRules = async () => {
    await getPricingRules(retailerData.ID).then((res) => {
      if (res.data?.length) {
        setPricingRules(res.data);
      }
    });

    setLoading(false);
  };

  useEffect(() => {
    if (loading) {
      fetchRules();
    }
  }, [loading]);

  const productsTablecolums: any = [
    {
      title: "#ID",
      dataIndex: "id",
      width: "60px",
      align: "center",
    },
    {
      title: "Tier",
      dataIndex: "rule_tier",
      key: "rule_tier",
      width: "100px",
    },
    {
      title: "Type",
      dataIndex: "rule_type",
      key: "rule_type",
      width: "100px",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      width: "150px",
      render: (val: any) => moment(val).format("hh:mm, Do MMM YY"),
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
      width: "150px",
      render: (val: any) => moment(val).format("hh:mm, Do MMM YY"),
    },
    {
      title: "Action",
      dataIndex: "share",
      width: "80px",
      render: (val: any, row: any) => (
        <Button
          type="link"
          icon={<DeleteOutlined />}
          onClick={handleDeleteRuleClick(row)}
        />
      ),
    },
  ];

  return (
    <Modal
      centered
      visible={visible}
      title={
        <Space align="center">
          <Typography.Text>Add Price</Typography.Text>
          <Button
            type="link"
            icon={<PlusCircleOutlined />}
            onClick={() => setAddPricingRuleModalVisible(true)}
          >
            ADD RULE
          </Button>
        </Space>
      }
      footer={false}
      onCancel={onCancel}
      className={classes.pricingRulesModal}
    >
      <AddPricingRuleModal
        retailerData={retailerData}
        visible={addPricingRuleModalVisible}
        onRuleAdd={() => setLoading(true)}
        onCancel={() => setAddPricingRuleModalVisible(false)}
      />

      {!loading ? (
        <Table
          dataSource={pricingRules}
          columns={productsTablecolums}
          size={"middle"}
          scroll={{ y: "calc(100vh - 230px)" }}
          pagination={false}
        />
      ) : (
        <Loader height="70vh" />
      )}
    </Modal>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  pricingRulesModal: {
    maxWidth: "90vw",
  },
}));
