import React from "react";
import {
  ArrowLeftOutlined,
  CrownOutlined,
  FundProjectionScreenOutlined,
  LogoutOutlined,
  MessageOutlined,
  ShopOutlined,
  EditOutlined,
  UnorderedListOutlined,
  UserOutlined,
  SettingOutlined,
  AreaChartOutlined,
  FundOutlined,
  HomeOutlined,
  FilterOutlined,
  NotificationOutlined,
  ShoppingOutlined,
} from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { NavLink } from "react-router-dom";
import { useStyles } from "./Sidebar.style";
import RaseetLogo from "assets/logos/retailer-white-with-name.png";
import RaseetLogoCollapsed from "assets/logos/retailer-white.png";

function Sidebar({ sidebarCollapsed, setsidebarCollapse }: any) {
  const classes = useStyles({ sidebarCollapsed });

  const baseUrl = "/retailer/dashboard/";

  const NavTooltip = (props: any) => (
    <Tooltip placement="right" color="#225E77" {...props} />
  );

  return (
    <div className={classes.sidebar}>
      {/* logo */}
      <div
        className={classes.logo}
        onClick={() => setsidebarCollapse(!sidebarCollapsed)}
      >
        <img src={RaseetLogo} alt="Raseet Logo" hidden={sidebarCollapsed} />
        <img
          src={RaseetLogoCollapsed}
          alt="Raseet Logo"
          hidden={!sidebarCollapsed}
        />
        <Button
          type="text"
          shape="circle"
          className={classes.sidebarToggleButton}
          onClick={() => setsidebarCollapse(!sidebarCollapsed)}
          icon={<ArrowLeftOutlined rotate={sidebarCollapsed ? 180 : 0} />}
        />
      </div>

      {/* sidebar options */}
      <div className={classes.sidebarMenu}>
        <NavTooltip overlay="Home">
          <NavLink
            to={baseUrl + "home"}
            activeClassName={classes.activeMenuOption}
            className={classes.menuOption}
          >
            <div className={classes.menuIcon}>
              <HomeOutlined />
            </div>
            <div className={classes.menuLabel}>Home</div>
          </NavLink>
        </NavTooltip>

        <NavTooltip overlay="Incoming Orders">
          <NavLink
            to={baseUrl + "incoming-orders"}
            activeClassName={classes.activeMenuOption}
            className={classes.menuOption}
          >
            <div className={classes.menuIcon}>
              <ShoppingOutlined />
            </div>
            <div className={classes.menuLabel}>Incoming Orders</div>
          </NavLink>
        </NavTooltip>

        <NavTooltip overlay="Business Summary">
          <NavLink
            to={baseUrl + "business-summary"}
            activeClassName={classes.activeMenuOption}
            className={classes.menuOption}
          >
            <div className={classes.menuIcon}>
              <FilterOutlined />
            </div>
            <div className={classes.menuLabel}>Business Summary</div>
          </NavLink>
        </NavTooltip>

        <NavTooltip overlay="Customer Details">
          <NavLink
            to={baseUrl + "customer-details"}
            activeClassName={classes.activeMenuOption}
            className={classes.menuOption}
          >
            <div className={classes.menuIcon}>
              <UserOutlined />
            </div>
            <div className={classes.menuLabel}>Customer Details</div>
          </NavLink>
        </NavTooltip>

        <NavTooltip overlay="Chat">
          <NavLink
            to={baseUrl + "chat"}
            activeClassName={classes.activeMenuOption}
            className={classes.menuOption}
          >
            <div className={classes.menuIcon}>
              <MessageOutlined />
            </div>
            <div className={classes.menuLabel}>Chat</div>
          </NavLink>
        </NavTooltip>

        <NavTooltip overlay="Inventory">
          <NavLink
            to={baseUrl + "inventory"}
            activeClassName={classes.activeMenuOption}
            className={classes.menuOption}
          >
            <div className={classes.menuIcon}>
              <ShopOutlined />
            </div>
            <div className={classes.menuLabel}>Inventory</div>
          </NavLink>
        </NavTooltip>

        {/*

        <NavTooltip overlay="Loyalty Program">
          <NavLink
            to={baseUrl + "loyalty-program"}
            activeClassName={classes.activeMenuOption}
            className={classes.menuOption}
          >
            <div className={classes.menuIcon}>
              <CrownOutlined />
            </div>
            <div className={classes.menuLabel}>Loyalty Program</div>
          </NavLink>
        </NavTooltip> */}

        <NavTooltip overlay="Edit Profile">
          <NavLink
            to={baseUrl + "edit-business-profile"}
            activeClassName={classes.activeMenuOption}
            className={classes.menuOption}
          >
            <div className={classes.menuIcon}>
              <EditOutlined />
            </div>
            <div className={classes.menuLabel}>Edit Profile</div>
          </NavLink>
        </NavTooltip>

        <NavTooltip overlay="Configs">
          <NavLink
            to={baseUrl + "configs"}
            activeClassName={classes.activeMenuOption}
            className={classes.menuOption}
          >
            <div className={classes.menuIcon}>
              <SettingOutlined />
            </div>
            <div className={classes.menuLabel}>Configs</div>
          </NavLink>
        </NavTooltip>

        <NavTooltip overlay="Marketing Campaigns">
          <NavLink
            to={baseUrl + "marketing-campaigns"}
            activeClassName={classes.activeMenuOption}
            className={classes.menuOption}
          >
            <div className={classes.menuIcon}>
              <NotificationOutlined />
            </div>
            <div className={classes.menuLabel}>Marketing Campaigns</div>
          </NavLink>
        </NavTooltip>

        <NavTooltip overlay="Promotions">
          <NavLink
            to={baseUrl + "promotions"}
            activeClassName={classes.activeMenuOption}
            className={classes.menuOption}
          >
            <div className={classes.menuIcon}>
              <FundOutlined />
            </div>
            <div className={classes.menuLabel}>Promotions</div>
          </NavLink>
        </NavTooltip>

        <NavTooltip overlay="Logout">
          <NavLink
            to={"/logout?redirect=/retail/login"}
            activeClassName={classes.activeMenuOption}
            className={classes.menuOption}
          >
            <div className={classes.menuIcon}>
              <LogoutOutlined />
            </div>
            <div className={classes.menuLabel}>Logout</div>
          </NavLink>
        </NavTooltip>
      </div>
    </div>
  );
}

export default Sidebar;
