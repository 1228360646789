import { ArrowLeftOutlined, InfoCircleTwoTone } from "@ant-design/icons";
import { Button, Empty, Typography } from "antd";
import { applyCoupon, getCart, getCustomerCoupons } from "api/api";
import gtag from "components/Tracking/gtag";
import React, { useState, useEffect } from "react";
import { createUseStyles } from "react-jss";
import { useHistory, useRouteMatch } from "react-router";

type RouteParams = {
  cartId: string;
  retailStoreId: string;
};

export default function ApplyCouponPage() {
  const {
    params: { cartId, retailStoreId },
    path,
  } = useRouteMatch<RouteParams>() || {};
  const history = useHistory();
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [coupons, setCoupons] = useState([]);
  const [cartAmount, setCartAmount] = useState(0);

  const fetchInitialData = async () => {
    const cart = await getCart(+cartId);
    const couponsList = await getCustomerCoupons(
      +cart[0]?.retail_store_id,
      +cart[0]?.price_with_gst
    );
    if (couponsList?.length) {
      setCartAmount(+cart[0]?.price_with_gst);
      setCoupons(couponsList);
      console.log(couponsList);
    }
    setLoading(false);
  };

  const handleCouponCodeApply = async (couponCode: string) => {
    if (couponCode !== "") {
      await applyCoupon(couponCode, +cartId).then((res: any) => {
        if (res.length && res[0]?.id) {
          history.goBack();

          couponCode !== "" &&
            gtag("event", "coupon_apply", {
              coupon: res[0].coupon_code,
              store: res[0].retail_store_id,
              user: res[0].user_id,
            });
        }
      });
    }
  };

  useEffect(() => {
    if (loading) {
      fetchInitialData();
    }
  }, []);

  return (
    <div className={classes.applyCouponPage}>
      <div className={classes.pageHeader}>
        <Button
          type="text"
          icon={<ArrowLeftOutlined />}
          onClick={history.goBack}
        />
        <div className={classes.pageHeaderTitle}>APPLY COUPON</div>
      </div>

      <div className={classes.couponsWrapper}>
        {coupons.length ? (
          coupons.map((item: any) => (
            <div className={classes.couponCard}>
              <div
                hidden={item.is_applicable}
                className={classes.addMoreItemsText}
              >
                <InfoCircleTwoTone />
                <span>
                  Please add items worth{" "}
                  <b>₹{+item.min_cart_amount - cartAmount}</b> more to use this
                  coupon.
                </span>
              </div>
              <div className={classes.couponCardTitle}>
                <div>
                  <Typography.Text mark>{item?.coupon_code}</Typography.Text>
                  <Typography.Text className={classes.couponTitle}>
                    {item?.coupon_name}
                  </Typography.Text>
                </div>
                <Button
                  type="text"
                  size="small"
                  disabled={!item.is_applicable}
                  className={classes.couponApplyBtn}
                  onClick={() =>
                    item.is_applicable &&
                    handleCouponCodeApply(item?.coupon_code)
                  }
                >
                  APPLY
                </Button>
              </div>
              <div className={classes.couponCardDesc}>{item?.description}</div>
            </div>
          ))
        ) : (
          <div className={classes.empty}>
            <Empty description="No coupons available" />
          </div>
        )}
      </div>
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  applyCouponPage: {
    height: "100vh",
    overflow: "hidden",
  },
  pageHeader: {
    height: 65,
    padding: "1rem",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid rgba(40,44,63,.1)",
  },
  pageHeaderTitle: {
    marginLeft: 10,
    fontSize: 16,
    fontWeight: 600,
  },
  couponsWrapper: {
    marginTop: 20,
    height: "calc(100vh - 65px)",
    overflow: "auto",
  },

  couponCard: {
    margin: [10, 0],
    padding: "1.5rem 2rem",
    borderTop: "1px solid rgba(40,44,63,.1)",
    borderBottom: "1px solid rgba(40,44,63,.1)",
  },
  couponCardTitle: {
    width: "100%",
    fontSize: 14,
    fontWeight: 500,
    display: "flex",
    justifyContent: "space-between",
  },
  couponTitle: {
    display: "block",
    marginTop: 10,
  },
  couponApplyBtn: {
    fontWeight: 600,
    color: colors.dark400,
  },
  couponCardDesc: {
    width: "75%",
    fontSize: 12,
    marginTop: 3,
    color: colors.light500,
  },
  empty: {
    height: "80vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  addMoreItemsText: {
    display: "flex",
    alignItems: "center",
    paddingBottom: 10,

    "& .anticon": {
      paddingRight: 5,
    },

    "& > span": {
      fontSize: 12,
      color: colors.dark400,
    },
  },
}));
