import ChatDate from "./ChatDate";
import Empty from "antd/es/empty";
import { Button, Badge } from "antd";
import {
  customerMessagesArray,
  customerUpperArray,
} from "components/chat/chatfunctions";
import { createUseStyles } from "react-jss";
import Loader from "components/loader/Loader";
import { getRetailStores } from "../../api/api";
import { ChatIcon } from "components/customIcons";
import { Channel } from "twilio-chat/lib/channel";
import ChatInput from "components/chat/ChatInput";
import { ArrowLeftOutlined } from "@ant-design/icons";
import ChatMessage from "components/chat/ChatMessage";
import FullScreenModal from "../modals/FullScreenModal";
import React, { useEffect, useRef, useState } from "react";
import { getSession, Session } from "client/reactives/session";

const Chat = require("twilio-chat");

type ChatModalProps = {
  retailStoreId: string | number;
  retailerName?: string;
  customClient?: any;
};

type chatUpperArray = any[];

function ChatModal({
  retailStoreId,
  retailerName,
  customClient,
}: ChatModalProps) {
  const { userId } = getSession() as Session;
  const [modalOpen, setModalOpen] = useState(false);
  const messageContainerBottomRef = useRef<HTMLDivElement>(null);
  const [channelName, setChannelName] = useState<Channel>();
  const [msgInputValue, setMsgInputValue] = useState("");

  const tokenRef = useRef<any>();
  const [client, setClient] = useState<any>(customClient ?? null);
  const [chatData, setChatData] = useState({
    messages: [{}] as any,
    loading: true,
    intervalSet: false,
  });
  const setIntervalKey = useRef<any>(0);
  const classes = useStyles(chatData.loading);

  const [msgListener, setMsgListener] = useState(false);
  const newMsgsCount = useRef<any>(0);

  const toggleModal = () => setModalOpen(!modalOpen);

  const handleScroll = async (e: any) => {
    let element = e.target;

    if (
      element.scrollTop === 0 &&
      chatData.messages != undefined &&
      channelName != undefined
    ) {
      var initialHeight2 = element.scrollHeight;
      let newMsgArray = [...chatData.messages];
      let lastObject = newMsgArray[0];
      if (lastObject !== undefined) {
        if (lastObject.messagesArray[0].message.index !== 0) {
          var messageIndex = lastObject.messagesArray[0].message.index - 1;

          clearInterval(setIntervalKey.current);
          setIntervalKey.current = 0;

          var oldMessages = await channelName.getMessages(
            30,
            messageIndex,
            "backwards"
          );
          oldMessages.items.reverse();
          const datedMessages = oldMessages.items.map((item: any) =>
            item.dateCreated.toDateString()
          );

          for (let i = 0; i < datedMessages.length; i++) {
            if (datedMessages[i] == newMsgArray[0].date) {
              newMsgArray[0].messagesArray.unshift(
                await customerMessagesArray(oldMessages.items[i], retailStoreId)
              );
            } else {
              newMsgArray.unshift(
                await customerUpperArray(
                  oldMessages.items[i],
                  retailStoreId,
                  datedMessages[i]
                )
              );
            }
          }

          setChatData({
            messages: newMsgArray,
            loading: false,
            intervalSet: chatData.intervalSet,
          });
        }
      }

      var finalHeight2 = element.scrollHeight;
      element.scrollTop = finalHeight2 - initialHeight2;
    }
  };

  const joinChannel = async (channel: any) => {
    if (channel.channelState.status !== "joined") {
      await channel.join();
    }
  };

  async function joinOrCreateChannel(
    channelUniqueName: string,
    channelFriendlyName: string
  ) {
    try {
      const channel = await client.getChannelByUniqueName(channelUniqueName);
      joinChannel(channel);
      setChannelName(channel);
    } catch (err) {
      try {
        const channel = await client.createChannel({
          uniqueName: channelUniqueName,
          friendlyName: channelFriendlyName,
        });
        joinChannel(channel);
        setChannelName(channel);
      } catch (err) {
        console.log("error: ", err);
        alert("Unable to create channel, please reload this page");
      }
    }
  }

  async function handleChannelChange() {
    var userId = localStorage.getItem("userId");
    var userName = localStorage.getItem("userName");
    var retailStore = await getRetailStores(+retailStoreId);
    if (userId !== null && +userId > 0) {
      const channelUniqueName = `${retailStore.id}_${userId}`;
      const channelFriendlyName = `${retailStore.name}_to_${userName}`;

      if (channelUniqueName || channelFriendlyName) {
        joinOrCreateChannel(channelUniqueName, channelFriendlyName);
      }
    }
  }

  async function getMessages() {
    var UpperArray: chatUpperArray = [];
    if (channelName !== undefined) {
      const newMessages = await channelName.getMessages();
      const datedMessages = newMessages.items.map((item: any) => {
        return item.dateCreated.toDateString();
      });

      for (let i = 0; i < datedMessages.length; i++) {
        if (i == 0) {
          UpperArray.push(
            await customerUpperArray(
              newMessages.items[i],
              retailStoreId,
              datedMessages[i]
            )
          );
        } else if (datedMessages[i] == UpperArray[UpperArray.length - 1].date) {
          UpperArray[UpperArray.length - 1].messagesArray.push(
            await customerMessagesArray(newMessages.items[i], retailStoreId)
          );
        } else {
          UpperArray.push(
            await customerUpperArray(
              newMessages.items[i],
              retailStoreId,
              datedMessages[i]
            )
          );
        }
      }

      setChatData({
        messages: UpperArray,
        loading: false,
        intervalSet: chatData.intervalSet,
      });
      scrollToBottom();
    }
    setChatData({
      messages: UpperArray,
      loading: false,
      intervalSet: chatData.intervalSet,
    });
    scrollToBottom();
  }

  const handleMessageSend = async (channel: any) => {
    if (msgInputValue) {
      console.log("msgInputValue: ", msgInputValue);
      try {
        await channel.sendMessage(String(msgInputValue).trim());
      } catch (err) {
        console.log("Failed to send the message, error: ", err);
        alert("Something went wrong, please reload !");
      }
      setMsgInputValue("");
    }
  };

  const handleGallery = async (target: any) => {
    const file = target.files;
    const formData = new FormData();
    formData.append("file", file[0]);
    if (channelName !== undefined) {
      await channelName.sendMessage(formData);
      getMessages();
    }
  };

  // function to scroll the messages container to bottom
  const scrollToBottom = () => {
    if (messageContainerBottomRef.current) {
      messageContainerBottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleMsgInputChange = (e: any) => setMsgInputValue(e.target.value);

  async function clientRefresh() {
    if (!!client) {
      const { token, userId } = (await getSession()) as Session;
      client.on("tokenAboutToExpire", async function () {
        await getTwilioToken(userId);
        client.updateToken(tokenRef.current);
      });

      client.on("tokenExpired", async function () {
        await getTwilioToken(userId);
        client.updateToken(tokenRef.current);
      });
      getMessages();
    }
  }

  const getTwilioToken = async (id: any) => {
    const url = process.env.REACT_APP_API_NODE_BASE_URL;
    const data = await fetch(`${url}/createTwilioToken`, {
      method: "POST",
      body: JSON.stringify({
        id: id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => res.json());
    tokenRef.current = data.token;
  };

  async function instantiateTwilio() {
    const { token, userId } = (await getSession()) as Session;
    if (userId !== null && +userId > 0) {
      try {
        await getTwilioToken(userId);
      } catch {
        console.log("Unable to get token, please reload this page");
        alert("Something went wrong, please reload !");
        // throw new Error("Unable to get token, please reload this page");
      }
      setClient(await Chat.Client.create(tokenRef.current));
    }
  }

  // function setIntervalForMsgs() {
  //   //this needs to be improved or need to switch to new third-party provider
  //   if (setIntervalKey.current > 0) {
  //     // console.log("I'm sending back the request");
  //     return;
  //   }
  //   var intervalKey = setInterval(getMessages, 2000);
  //   setIntervalKey.current = intervalKey;
  //   if (!chatData.intervalSet) {
  //     setChatData({
  //       loading: chatData.loading,
  //       intervalSet: true,
  //       messages: chatData.messages
  //     });
  //   }
  // }

  function messageReceived(message: any) {
    newMsgsCount.current++;
    getMessages();
  }

  useEffect(() => {
    if (+retailStoreId > 0) {
      instantiateTwilio();
    }
  }, [retailStoreId]);

  useEffect(() => {
    clientRefresh();
    if (client) {
      handleChannelChange();
    }
  }, [client]);

  useEffect(() => {
    if (msgInputValue === "" && channelName) {
      getMessages();
      // setIntervalForMsgs();
    }
    if (channelName && !msgListener && !chatData.loading) {
      var listn = channelName.on("messageAdded", messageReceived);
      if (listn) {
        setMsgListener(true);
      }
    }
  }, [channelName, msgInputValue]);

  if (modalOpen) {
    newMsgsCount.current = 0;
  }

  return (
    <div className={classes.chatModal} onScroll={(e) => handleScroll(e)}>
      <a
        id="open-chat-button"
        data-store={`${retailerName} - ${retailStoreId}`}
        data-user={userId}
      >
        <Button
          className={classes.openChatBtn}
          type="link"
          shape="circle"
          onClick={() => setModalOpen(!modalOpen)}
          icon={
            <Badge
              size="small"
              count={newMsgsCount.current > 0 ? newMsgsCount.current : ""}
            >
              <ChatIcon />
            </Badge>
          }
        />
      </a>

      <FullScreenModal modalOpen={modalOpen}>
        <div className={classes.chatCardHeader}>
          <Button className={classes.closeModalIcon} onClick={toggleModal}>
            <ArrowLeftOutlined />
          </Button>
          <div>
            <div className={classes.headerRetailerName}>{retailerName}</div>
            <div className={classes.headerRegisterNumber}>
              {/* {newChatObject.current.retailerDetails.registerId} */}
            </div>
          </div>
        </div>
        <div className={classes.messagesContainer}>
          {!chatData.loading ? (
            chatData.messages?.length ? (
              chatData.messages.map((item: any) => (
                <>
                  <ChatDate date={item.date} />
                  {item?.messagesArray?.length &&
                    item.messagesArray.map((message: any, index: any) => (
                      <ChatMessage
                        {...message}
                        onLoad={!index ? scrollToBottom : undefined}
                      />
                    ))}
                </>
              ))
            ) : (
              <div className={classes.emptyContainer}>
                <Empty description={<b>Start Chatting.!</b>} />
              </div>
            )
          ) : (
            <Loader />
          )}
          <div ref={messageContainerBottomRef}></div>
        </div>
        <div className={classes.chatFooter}>
          <ChatInput
            onMessageSend={() => {
              handleMessageSend(channelName);
            }}
            onAttachmentChange={(e: any) => handleGallery(e.target)}
            value={msgInputValue}
            onChange={handleMsgInputChange}
            placeholder="Type Something..."
          />
        </div>
      </FullScreenModal>
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  chatModal: {},

  openChatBtn: {
    boxShadow: "none",

    "& .anticon": {
      fontSize: 18,
    },
  },

  chatCardHeader: {
    height: 58,
    width: "100%",
    background: "#2A9D8F",
    color: colors.light100,
    position: "fixed",
    top: 0,
    zIndex: 5,
    left: 0,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },

  closeModalIcon: {
    width: "60px",
    height: "58px",
    margin: "0 0.5rem",
    fontSize: "1.2rem",
    background: "inherit",
    color: "inherit",
    border: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  headerRetailerName: {
    fontWeight: 600,
    fontSize: "1.1rem",
  },

  headerRegisterNumber: {
    fontSize: "0.75rem",
  },

  messagesContainer: {
    position: "fixed",
    left: "0",
    top: "0",
    display: "flex",
    width: "100%",
    height: "calc(100% - 50px)",
    padding: "calc(65px + 1rem) 1rem 0.5rem 1rem",
    overflowY: "auto",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "column",
    alignContent: "center",
  },
  chatFooter: {
    width: "100%",
    padding: "0.5rem",
    position: "fixed",
    bottom: 0,
    left: 0,
  },
  emptyContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default ChatModal;
