import { Input } from "antd";
import Button from "antd/es/button";
import Field from "components/field/Field";
import FormBox from "components/FormBox";
import hideNumberInputArrows from "components/style/hideNumberInputArrows";
import React, { useState, useEffect } from "react";
import User from "models/User";
import useForm from "hooks/useForm";
import { createUseStyles } from "react-jss";
import { FormProvider } from "react-hook-form";
import {
  LockOutlined,
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
} from "@ant-design/icons";
import Countdown from "antd/lib/statistic/Countdown";

function CustomerRegisterUserForm({
  resendOtp,
  onReset,
  otpStatus,
  onPasswordSkip,
  ...props
}: any) {
  // const[timer,setTimer] = useState(15);
  // useEffect(()=>{
  //   countDown();

  // },[otpStatus.resend])
  const classes = useStyles();
  const { isSubmitting, methods, submitHandler, reset } = useForm<any>({
    ...props,
    resolver: otpStatus.otpVerified
      ? User.registerUser()
      : User.registerUserWithoutPassword(),
  });

  const resetForm = () => {
    onReset();
    reset();
  };

  // const countDown = () =>{
  //   var timeleft = 15;
  //   var downloadTimer = setInterval(function(){
  //     if(timeleft <= 0){
  //       clearInterval(downloadTimer);
  //       otpStatus.setResend(true);
  //     }
  //     setTimer(timeleft);
  //     timeleft -= 1;
  //     }, 2000);
  // }

  return (
    <FormBox
      title={
        otpStatus.otpVerified
          ? "Create Password"
          : otpStatus.otpSent
          ? "Verify OTP"
          : "Register to Raseet"
      }
      showBackButton={otpStatus.otpSent}
      onBack={resetForm}
    >
      <FormProvider {...methods}>
        <form
          onSubmit={submitHandler}
          className={classes.customerRegisterUserView}
        >
          <Field
            as={Input}
            name="name"
            placeholder="Full Name"
            prefix={<UserOutlined />}
            readOnly={otpStatus.otpSent}
          />

          <Field
            as={Input}
            type="tel"
            name="mobileNumber"
            placeholder="Mobile Number"
            readOnly={otpStatus.otpSent}
            prefix={<PhoneOutlined rotate={90} />}
          />

          {/* {otpStatus.moboError ? (
            <p className={classes.warning}>Mobile number already registered</p>
          ) : null} */}

          {otpStatus.otpVerified ? (
            <>
              <Field
                as={Input}
                name="password"
                type="password"
                placeholder="Enter Your Password"
                prefix={<LockOutlined />}
              />
              <Field
                as={Input}
                name="confirmPassword"
                type="password"
                placeholder="Confirm Password"
                prefix={<LockOutlined />}
              />
            </>
          ) : otpStatus.otpSent ? (
            <>
              <Field
                as={Input}
                name="otp"
                type="password"
                placeholder="Enter The OTP"
                prefix={<MailOutlined />}
              />
              {otpStatus.wrongOtp ? (
                <p className={classes.warning}>Wrong OTP</p>
              ) : null}
              {/* {otpStatus.otpInput.length > 4 ? (
                <p className={classes.warning}>OTP is of 4 digits</p>
              ) : null} */}
              {/* {otpStatus.resend ? (<p onClick={sendOtp} className={classes.resendOTP}>
                Resend OTP
              </p>):
              (<p className={classes.resendOTP}>Request another OTP in {timer}sec</p>)
             } */}

              <p onClick={resendOtp} className={classes.resendOTP}>
                Resend OTP
              </p>
            </>
          ) : (
            <p className={classes.formText}>
              An OTP would be sent on this number.
            </p>
          )}

          <Button block type="primary" loading={isSubmitting} htmlType="submit">
            {otpStatus.otpVerified
              ? "Next"
              : otpStatus.otpSent
              ? "Verify OTP"
              : "Send OTP"}
          </Button>
          {otpStatus.otpVerified && (
            <Button block onClick={onPasswordSkip} style={{ marginTop: 10 }}>
              Skip Password
            </Button>
          )}
        </form>
      </FormProvider>
    </FormBox>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  customerRegisterUserView: {
    ...hideNumberInputArrows,

    "& .anticon": {
      padding: "0 0.4rem",
    },
    "& .ant-btn": {
      fontWeight: 600,
      letterSpacing: 0.45,
    },
  },
  resendOTP: {
    width: "fit-content",
    marginTop: 5,
    marginLeft: "auto",
    color: colors.primary200,
    fontWeight: 600,
    cursor: "pointer",
  },
  warning: {
    fontWeight: 600,
    marginTop: 5,
    color: "red",
    marginLeft: 15,
  },
  formText: {
    color: colors.dark400,
    fontSize: 11,
  },
}));

export default CustomerRegisterUserForm;
