import React from 'react'

import Result from 'antd/es/result'
import Button from 'antd/es/button'

function NotFoundPage() {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={<Button type="primary">Back Home</Button>}
    />
  )
}

export default NotFoundPage
