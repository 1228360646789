import React from 'react'

import Container from 'components/Container'

type OnboardingLayoutProps = React.PropsWithChildren<{}>

function OnboardingLayout({ children }: OnboardingLayoutProps) {
  return (
    <Container>
      {children}
    </Container>
  )
}

export default OnboardingLayout
