import React, { useState, useEffect } from "react";
import { createUseStyles } from "react-jss";
import { getShipping } from "api/api";
import ShippingConfigsForm from "components/forms/ShippingConfigsForm";
import Loader from "components/loader/Loader";

type ConfigsPageProps = {
  retailerData: {
    ID: number;
    Name: string;
  };
};

export default function ConfigsPage({ retailerData }: ConfigsPageProps) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [checkBoxChecked, setCheckBoxChecked] = useState(false);
  const [shippingConfigsDefaultValues, setShippingConfigsDefaultValues] =
    useState({
      minimum_order_amount: "",
      shipping_charge_applicable: "",
    });

  const fetchInitialData = async () => {
    const data = await getShipping(retailerData.ID);
    if (!!data) {
      setShippingConfigsDefaultValues({
        minimum_order_amount: data?.minimumOrderAmount ?? "",
        shipping_charge_applicable: data?.shippingCharge ?? "",
      });
      setCheckBoxChecked(!data?.shipping_waived_off ?? true);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={classes.configsPage}>
      <ShippingConfigsForm
        checkBoxChecked={checkBoxChecked}
        retailerData={retailerData}
        onSubmit={() => {}}
        defaultValues={shippingConfigsDefaultValues}
      />
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  configsPage: {
    padding: "2rem",
  },
}));
