import { Button, Col, Divider, Drawer } from "antd";
import { Row } from "antd/es/grid";
import React from "react";
import { createUseStyles } from "react-jss";

interface CompletedOrderDrawerProps {
  onClose: () => void;
  visibility: boolean;
  clicked: any;
  totalCost: number;
  data?: any;
}
export const CompletedOrderDrawer = ({
  onClose,
  visibility,
  clicked,
  totalCost,
  data,
}: CompletedOrderDrawerProps) => {
  const classes = useStyles();
  return (
    <div>
      <Drawer
        placement="bottom"
        closable={true}
        onClose={onClose}
        visible={visibility}
        title="Payment Details"
        className={classes.completedOrderDrawer}
      >
        <Row justify="space-between">
          <Col span={6}>Subtotal</Col>
          <Col span={6} style={{ textAlign: "right" }}>
            ₹ {data?.subTotal}
          </Col>
        </Row>
        <Divider className={classes.divider} />
        <Row justify="space-between">
          <Col span={6}>GST</Col>
          <Col span={6} style={{ textAlign: "right" }}>
            ₹ {data?.gst}
          </Col>
        </Row>
        <Divider className={classes.divider} />
        <Row justify="space-between">
          <Col span={6}>Total</Col>
          <Col span={6} style={{ textAlign: "right" }}>
            ₹ {data?.orderValue}
          </Col>
        </Row>
        <Divider className={classes.divider} />
        <Row
          style={{ marginTop: "1rem", fontWeight: 600, fontSize: "1.05rem" }}
          align="middle"
        >
          <Col span={4}>Total</Col>
          <Col span={7} offset={1}>
            ₹ {totalCost}
          </Col>
          <Col span={12} style={{ textAlign: "end" }}>
            <Button
              size="large"
              type="primary"
              danger
              block
              onClick={clicked}
              style={{ width: "100%" }}
            >
              Cancel Order
            </Button>
          </Col>
        </Row>
      </Drawer>
    </div>
  );
};

const useStyles = createUseStyles(({ colors }: Theme) => ({
  completedOrderDrawer: {
    "& .ant-drawer-content-wrapper": {
      height: "215px !important",
    },
    "& .ant-drawer-body": {
      padding: 15,
    },
    "& *": {
      fontSize: 12,
    },
  },
  divider: {
    margin: "5px 0",
    borderTop: "2px solid" + colors.light400,
  },
  "@global": {
    ".ant-drawer-title": {
      textAlign: "center",
    },
  },
}));
