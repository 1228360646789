import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(({ colors }: Theme) => ({
  morePageOptions: {
    padding: "1rem 2rem",
  },
  optionCard: {
    height: 60,
    marginBottom: "1.5rem",
    boxShadow: "-1px -1px 2px rgb(0 0 0 / 15%), 1px 1px 2px rgb(0 0 0 / 15%)",

    "& .ant-card-body": {
      height: "100%",
      display: "flex",
      alignItems: "center",
    },
  },
  optionTitle: {
    flex: 1,
    fontSize: 13,
  },
  footer: {
    padding: 0,
  },
  center: {
    textAlign: "center",
  },
  moreDetails: {
    fontSize: 13,
    fontWeight: 700
  },
  topAlignment: {
    alignItems: "center",
    margin: "10px 0px"
  }
}));

export { useStyles };
