import Col from "antd/es/grid/col";
import React, { useMemo } from "react";
import Row from "antd/es/row";
import { createUseStyles } from "react-jss";
import Stepper from "./stepper/Stepper";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { Steps } from "antd";

type FormStepperProps = {
  title: string;
  subTitle: string;
  children: any;
  isRetailer?: boolean;
  currentStep?: number;
  steps: string[];
  shrink?: boolean;
};

function FormStepper({
  title,
  subTitle,
  isRetailer = false,
  children,
  steps,
  currentStep = 0,
  shrink,
}: FormStepperProps) {
  const classes = useStyles({ shrink });
  const isLG = useBreakpoint().lg;
  const isMD = useBreakpoint().md;

  const views = useMemo(() => React.Children.toArray(children), [children]);
  const currentView = views[currentStep];

  return (
    <>
      <Row className={classes.formStepper}>
        <Col
          hidden={!isLG}
          className={classes.registerLeft}
          span={shrink ? 2 : 12}
        >
          <div className={classes.header}>
            {isRetailer ? (
              <p>
                <p>{title}</p>
                <b>{subTitle}</b>
              </p>
            ) : (
              <>
                <b>{title}</b>
                <p>{subTitle}</p>
              </>
            )}
          </div>

          <Stepper
            stepsArray={steps}
            activeStep={currentStep || 0}
            showIndexOnly={shrink}
            isRetailer={isRetailer}
          />
        </Col>
        <Col
          xs={24}
          md={18}
          lg={shrink ? 22 : 12}
          className={classes.currentView}
        >
          <Steps
            labelPlacement="vertical"
            size="small"
            className={classes.mobileSteps}
            style={{
              paddingTop: isMD ? 40 : 20,
              display: isLG ? "none" : "",
            }}
          >
            <Steps.Step title="Registration" />
            <Steps.Step title="Business" />
            <Steps.Step title="Get started" />
          </Steps>
          {currentView}
        </Col>
      </Row>
    </>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  formStepper: {
    height: "100vh",
    overflowY: "hidden",
  },

  currentView: {
    margin: "auto",

    transition: ({ shrink }) => (shrink ? "1s" : "0s"),
  },

  // Register Page Left Side
  registerLeft: ({ shrink }) => ({
    minHeight: "100vh",
    padding: !shrink && "2rem 2.5rem",
    backgroundColor: colors.primary200,
    transition: "1s",
    display: shrink && "flex",
    justifyContent: "center",
    alignItems: "center",

    "@media (min-width: 1280px)": {
      padding: !shrink && "3rem 4rem",
    },
  }),
  header: {
    padding: "2rem 0 2rem 0.65rem",
    color: colors.light300,
    display: ({ shrink }) => shrink && "none",

    "& b": {
      fontSize: "2.4rem",
    },
    "& p": {
      fontSize: "1.3rem",
      margin: "0",
    },
  },

  mobileSteps: {
    padding: 20,
    paddingBottom: 0,
    paddingLeft: 10,

    "& .ant-steps-item-title": {
      fontSize: 12,
    },
  },
}));

export default FormStepper;
