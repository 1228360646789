import {
  CheckCircleFilled,
  CloseCircleFilled,
  RightOutlined,
  StarFilled,
  StarOutlined,
} from "@ant-design/icons";
import { Button, message, Select } from "antd";
import { updateReorderStatus } from "api/api";
import { getOrderItems } from "api/retailerApi";
import OrderSummaryTableCard from "components/cards/OrderSummaryTableCard";
import { EmptyStarIcon } from "components/customIcons";
import Loader from "components/loader/Loader";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { createUseStyles } from "react-jss";

interface OrdersCardProps {
  customerName: string;
  date: string;
  orderId: number;
  invoiceNumber: string;
  orderAmount: string;
  isPriority: boolean;
  paymentStatus: string;
  orderStatus: number;
  onPriorityToggle: () => void;
}

export default function OrdersCard({
  customerName,
  date,
  orderId,
  invoiceNumber,
  orderAmount,
  isPriority,
  paymentStatus,
  orderStatus,
  onPriorityToggle,
}: OrdersCardProps) {
  const classes = useStyles({ isPriority });
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  const handleCardClick = () => {
    setLoading(!isOpen);
    setIsOpen(!isOpen);
  };

  const handlePriorityToggle = (e: any) => {
    e.stopPropagation();
    onPriorityToggle();
  };

  const handleOrderStatusChange = async (value: number) => {
    await updateReorderStatus(orderId, value).then((res) =>
      message.success(res.message)
    );
  };

  const fetchInitialData = async () => {
    const orderItems = await getOrderItems(+orderId);
    for (var i = 0; i < orderItems.length; i++) {
      orderItems[i] = {
        isAvailable: true,
        key: i + 1,
        ...orderItems[i],
      };
    }
    setItems(orderItems);
    setLoading(false);
  };

  useEffect(() => {
    if (loading && isOpen) {
      fetchInitialData();
    }
  }, [loading]);

  const columns: any = [
    {
      title: "#",
      dataIndex: "key",
      align: "center",
      width: "30px",
      render: (key: number) => `${key}`,
    },
    {
      title: "Item",
      dataIndex: "name",
      width: "100px",
      render: (name: string) => `${name}`,
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      width: "40px",
      render: (quantity: string) => `${+quantity}`,
      // sorter: (a: any, b: any) => +a.quantity - +b.quantity,
    },
    {
      title: "Total",
      dataIndex: "total",
      width: "60px",
      render: (total: string) => `₹ ${(+total).toFixed(0)}/-`,
      // sorter: (a: any, b: any) => +a.total - +b.total,
    },
    // {
    //   title: "Availability",
    //   dataIndex: "isAvailable",
    //   width: "120px",
    //   render: (isAvailable: boolean) =>
    //     isAvailable ? (
    //       <CheckCircleFilled className={classes.availableIcon} />
    //     ) : (
    //       <CloseCircleFilled className={classes.unavailableIcon} />
    //     ),
    //   filters: [
    //     {
    //       text: "Available",
    //       value: true,
    //     },
    //     {
    //       text: "Unavailable",
    //       value: false,
    //     },
    //   ],
    //   onFilter: (value: any, item: any) => item.isAvailable === value,
    // },
  ];

  return (
    <div className={classes.OrdersCard} onClick={handleCardClick}>
      <div className={classes.OrdersCardInner}>
        <Button
          size="large"
          type="text"
          className={classes.priorityToggler}
          onClick={handlePriorityToggle}
          icon={isPriority === true ? <StarFilled /> : <StarOutlined />}
        />

        <div>
          <div className={classes.customerName}>{customerName}</div>
          <div className={classes.orderId}>{invoiceNumber ? `Invoice Number: ${invoiceNumber}` : `Order ID: #${orderId}`}</div>
        </div>

        <div className={classes.orderAmount}>₹{orderAmount}</div>

        <Button
          size="large"
          type="text"
          icon={<RightOutlined rotate={isOpen ? 90 : 0} />}
        />
      </div>

      <div
        style={{ height: isOpen ? "auto" : "" }}
        onClick={(e) => e.stopPropagation()}
        className={classes.OrdersCardExpandedPart}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className={classes.date}>
            {moment(date).format("DD MMM YY, hh:mm A")}
          </div>
        </div>

        {!loading ? (
          <OrderSummaryTableCard
            hideBg
            size="small"
            items={items}
            customColumns={columns}
          />
        ) : (
          <Loader height={200} />
        )}
      </div>
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  incomingOrders: {
    height: "100vh",
    padding: "0 2rem",
    background: "#fbfbfb",
  },

  OrdersCard: {
    marginBottom: 15,
    padding: [15, 5, 15, 10],
    background: "rgba(34, 94, 119, 0.1)",
    borderRadius: 5,

    transition: "all 0.2s",
  },
  OrdersCardInner: {
    display: "flex",
    alignItems: "center",
  },
  priorityToggler: {
    "& .anticon": {
      color: ({ isPriority }) => (isPriority ? "#E76F51" : ""),
    },
  },
  customerName: {
    fontWeight: 600,
  },
  orderId: {
    fontSize: 12,
    marginTop: 2,
  },
  orderAmount: {
    fontSize: 14,
    fontWeight: 600,
    marginLeft: "auto",
  },
  OrdersCardExpandedPart: {
    height: 0,
    overflow: "hidden",
    // minHeight: 400,
    transition: "all 0.2s",
  },
  availableIcon: {
    marginLeft: "5px",
    color: "#359C10",
  },
  unavailableIcon: {
    marginLeft: "5px",
    color: "#D10B0B",
  },
  paymentStatus: {
    padding: "10px 20px 0px",
    fontWeight: 500,
    fontSize: 12,
  },
  date: {
    padding: "10px 20px 0px",
    fontWeight: 500,
    fontSize: 12,
    color: colors.dark400,
  },
  orderStatus: {
    padding: "10px 20px 0px",
    fontWeight: 500,
    fontSize: 12,
    "& > span": {
      marginRight: 10,
    },
    "& .ant-select-selection-item": {
      fontSize: 12,
    },
  },
}));
