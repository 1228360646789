import { Button, message } from "antd";
import Modal from "antd/lib/modal/Modal";
import {
  DownloadBillIconLight,
  DownloadPrescriptionIconLight,
} from "components/customIcons";
import React from "react";
import { createUseStyles } from "react-jss";
import { Link } from "react-router-dom";

type OrderDownloadModalProps = {
  order: any;
  visible: boolean;
  onClose: () => any;
};

function OrderDownloadModal({
  order,
  visible,
  onClose,
}: OrderDownloadModalProps) {
  const classes = useStyles();

  return (
    <Modal
      title={false}
      closable={false}
      footer={false}
      centered
      width={260}
      visible={visible}
      onCancel={onClose}
      className={classes.orderDownloadModal}
    >
      <a
        href={order?.s3_public_url}
        target="_blank"
        id="bill-receipt-download-btn"
        data-source="Bills Page"
        onClick={() =>
          !!!order?.s3_public_url && message.info("Bill copy not available.")
        }
      >
        <div className={classes.downloadItem}>
          <span>Bill Copy</span>
          <Button
            shape="circle"
            type="primary"
            icon={<DownloadBillIconLight />}
          />
        </div>
      </a>
      <Link to="/dashboard/stores/prescriptions-and-reports">
        <div className={classes.downloadItem}>
          <span>Prescriptions</span>
          <Button
            shape="circle"
            type="primary"
            icon={<DownloadPrescriptionIconLight />}
          />
        </div>
      </Link>
    </Modal>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  "@global": {
    ".ant-modal-centered": {
      textAlign: "center !important",
    },
    ".ant-modal-mask": {
      background: "rgba(196, 196, 196, 0.8)",
    },
  },
  orderDownloadModal: {
    "& .ant-modal-content": {
      borderRadius: "5px !important",
    },
    "& .ant-modal-body": {
      padding: "5px 24px !important",
    },
  },
  downloadItem: {
    margin: [20, 0],
    width: "100%",
    fontSize: 11,
    fontFamily: "Roboto",
    lineHeight: "13px",
    color: "#000000",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",

    "&:hover": {
      opacity: 0.8,
    },

    "& .anticon": {
      fontSize: 15,
    },
  },
}));

export default OrderDownloadModal;
