import { createUseStyles } from "react-jss";
import customeScrollbarStyle from "components/style/CustomScrollbar";

const flexStyles = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
};

const useStyles = createUseStyles(({ colors }: Theme) => ({
  tabs: {
    ...flexStyles,

    "& .ant-tabs-content-holder, & .ant-tabs-content, & .ant-tabs-tabpane": {
      ...flexStyles,
    },

    "& .ant-tabs-nav": {
      margin: [0, 10],
    },

    "& .ant-tabs-nav-list, & .ant-tabs-tab-btn": {
      width: "100%",
    },

    "& .ant-tabs-tab": {
      padding: 5,
      textAlign: "center",
      width: "50%",
      marginRight: "10px !important",

      "&:last-of-type": {
        margin: "0 !important",
      },
    },

    "& .ant-tabs-tab-btn": {
      fontSize: 12,
      lineHeight: "22px",
    },
  },
  itemsListCard: {
    width: "100%",
    height: "100%",
    padding: "0.5rem",
    backgroundColor: colors.light100,
    borderRadius: "5px",
    boxShadow:
      "-1px -1px 4px rgba(34, 94, 119, 0.25), 1px 1px 4px rgba(34, 94, 119, 0.25)",
  },
  listSettings: {
    minHeight: 70,
    width: "fit-content",
    padding: "0 0.5rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
  },
  searchWrapper: {
    padding: "0 0.5rem",
  },
  searchInput: {
    width: "100%",
    marginBottom: 20,
    borderRadius: "0.5rem",
    "& input": {
      fontSize: 14,
    },
  },
  filterBtn: {},
  itemsList: {
    height: "calc(100% - 165px)",
    overflowY: "auto",

    //  custom scrollbar
    ...customeScrollbarStyle,
  },
  orderItem: {
    width: "100%",
    height: 60,
    cursor: "pointer",
    paddingRight: "1rem",
    paddingLeft: "calc(1rem + 30px)",
    color: "#505050",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `1.5px solid ${colors.light400}`,
    position: "relative",
  },
  selectedItem: {
    backgroundColor: "#ededed",
    borderBottom: "none",
  },
  OrderItemLeft: {
    display: "flex",
    justifyContent: "center",
    // alignItems: "center",

    "& .anticon": {
      marginTop: "10px",
      marginRight: "15px",
      fontSize: 12,
      color: "#E76F51",
      position: "absolute",
      left: 22,
      top: 5,
    },
  },
  orderID: {
    fontWeight: 700,
    fontSize: 14,
    paddingBottom: "0.3rem",
  },
  customerName: {
    fontWeight: 500,
    textTransform: "capitalize",
    fontSize: 12,
  },
  orderAmount: {
    fontWeight: 700,
    fontSize: 12,
  },
  centreText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    fontWeight: "bold",
    fontSize: "1.1rem",
    color: "#225E77",
  },
  "@media (max-width: 1050px)": {
    listSettings: {
      minHeight: 70,
    },
    centreText: { fontSize: "1rem" },
  },
}));

export { useStyles };
