import getPlatform from "./getPlatform";

window.dataLayer = window.dataLayer || [];

export default function gtag() {
  if (arguments[0] === "event") {
    arguments[2] =
      arguments.length >= 3
        ? { ...arguments[2], platform: getPlatform() }
        : { platform: getPlatform() };
  }

  window.dataLayer.push(arguments);
}

gtag("js", new Date());

gtag("config", "G-94MW1ENMWK");
