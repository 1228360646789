import hideNumberInputArrows from "components/style/hideNumberInputArrows";
import React from "react";
import { createUseStyles } from "react-jss";

// type FormWrapperProps = {
//   formTitle: string;
//   action: string;
//   method: string;
//   onFormSubmit: () => void;
//   children: React.ReactNode;
// };

const FormWrapper = (props: any) => {
  const classes = useStyles();
  const { formTitle, children, ...otherFormProps } = props;

  return (
    <div className={classes.formWrapper}>
      <div className={classes.formTitle}>{formTitle}</div>
      <form className={classes.formContainer} {...otherFormProps}>
        <div className={classes.formInputs}>{children}</div>
      </form>
    </div>
  );
};

const useStyles = createUseStyles(({ colors }: Theme) => ({
  formWrapper: {
    width: "100%",
    padding: "1rem",
  },
  formTitle: {
    width: "100%",
    minHeight: "5rem",
    fontSize: "1.5rem",
    fontWeight: 600,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  formContainer: {
    width: "100%",
    padding: "1.5rem",
    backgroundColor: "rgba(34, 94, 119, 0.1)",
  },
  formInputs: {
    "& *": {
      borderRadius: "4px !important",
      border: "none",
    },
    ...hideNumberInputArrows,
  },
}));

export default FormWrapper;
