import { createUseStyles } from "react-jss";
import React, { useEffect,useState } from "react";
import { Form, Input, Button, message } from "antd";
import { updateAddresses, getCustomer, getAddressesByUserID } from "api/api";

export const EditAddress = () => {
  const classes = useStyles();
  const { Item } = Form;
  const [form] = Form.useForm();
  const[reload,setReload] = useState(false);

  useEffect(()=>{
    
    async function populate(){
      const Customer = await getCustomer()
      console.log(Customer);
      var oldaddress = await getAddressesByUserID('Customer');
      console.log(oldaddress);
      if(oldaddress !== undefined){
        form.setFieldsValue({ StreetAddress: `${oldaddress?.line1}` });
      form.setFieldsValue({ State: `${oldaddress?.state}` });
      form.setFieldsValue({ City: `${oldaddress?.city}` });
      form.setFieldsValue({ Landmark: `${oldaddress?.landmark}` });
      form.setFieldsValue({ PinCode: `${oldaddress?.pincode}` });
      }
      // else{
      //   form.setFieldsValue({ StreetAddress: `${oldaddress?.line1}` });
      // form.setFieldsValue({ State: `${oldaddress?.state}` });
      // form.setFieldsValue({ City: `${oldaddress?.city}` });
      // form.setFieldsValue({ Landmark: `${oldaddress?.landmark}` });
      // form.setFieldsValue({ PinCode: `${oldaddress?.pincode}` });
      // }
      
    }
    populate();
  },[reload])

  const onFinish = async (values: any) => {
    const Customer = await getCustomer();
    let address = {
      line1: `'${values?.StreetAddress}'`,
      line2: "''",
      landmark: `'${values?.Landmark}'`,
      pincode: `'${values?.PinCode}'`,
      city: `'${values?.City}'`,
      state: `'${values?.State}'`,
    };
    console.log(values);
    await updateAddresses(Customer?.id, "Customer", address);
    setReload(!reload);
    message.info("Successfully Updated");
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <div>
      <Form
        style={{ marginTop: "1rem" }}
        form={form}
        name="control-hooks"
        onFinish={onFinish}
      >
        <Item>
          <Item
            className={classes.formItem}
            name="StreetAddress"
            rules={[
              { required: true, message: "Please input your street address!" },
            ]}
          >
            <Input
              placeholder="Street Address"
              className={classes.inputField}
            />
          </Item>

          {/* <Item
            className={classes.formItem}
            name='StateName'
            rules={[{ required: true, message: "Please input your state name" }]}
          >
            <Cascader placeholder='State' />
          </Item> */}
          <Item
            className={classes.formItem}
            name="State"
            rules={[{ required: true, message: "Please input your State!" }]}
          >
            <Input placeholder="State" className={classes.inputField} />
          </Item>

          {/* <Item
            className={classes.formItem}
            name='CityName'
            rules={[{ required: true, message: "Please input your city name" }]}
          >
            <Cascader placeholder='City' />
          </Item> */}
          <Item
            className={classes.formItem}
            name="City"
            rules={[{ required: true, message: "Please input your City!" }]}
          >
            <Input placeholder="City" className={classes.inputField} />
          </Item>

          <Item
            className={classes.formItem}
            name="Landmark"
            rules={[{ required: true, message: "Please input your landmark" }]}
          >
            <Input
              type="location"
              placeholder="Locality (or nearest Landmark)"
              className={classes.inputField}
            />
          </Item>

          <Item
            className={classes.formItem}
            name="PinCode"
            rules={[{ required: true, message: "Please input your pin" }]}
          >
            <Input
              type="number"
              placeholder="Pin Code"
              className={classes.inputField}
            />
          </Item>

          <a
            className="login-form-forgot"
            style={{ float: "right", fontSize: 11 }}
            onClick={onReset}
          >
            Clear all Sections
          </a>
        </Item>

        <Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "100%", fontSize: 12 }}
          >
            Save
          </Button>
        </Item>
      </Form>
    </div>
  );
};

const useStyles = createUseStyles(({ colors }: Theme) => ({
  formItem: {
    padding: "0.5rem 0rem",
    marginBottom: "0 !important",

    "& input": {
      fontSize: 12,
    },
  },
  inputField: {
    borderRadius: 5,
  },
}));
