import Modal from "antd/lib/modal/Modal";
import { generateGetUrl, getPrescriptionsById } from "api/api";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import Loader from "components/loader/Loader";
import { CloseOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Input } from "antd";

function ShowPrescriptionModal({ prescriptionId, visible, onCancel }: any) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [prescriptions, setPrescriptions] = useState<any>([]);
  const [currentIndex, setCurentIndex] = useState(0);

  const nextPres = () => {
    if (currentIndex + 1 < prescriptions.length) {
      setCurentIndex(currentIndex + 1);
    } else {
      setCurentIndex(0);
    }
  };
  const prevPres = () => {
    if (currentIndex > 0) {
      setCurentIndex(currentIndex - 1);
    } else {
      setCurentIndex(prescriptions.length - 1);
    }
  };

  useEffect(() => {
    (async () => {
      if (prescriptionId !== "" && prescriptionId !== undefined) {
        const result = await getPrescriptionsById(prescriptionId);
        if (result?.id && result?.prescription_data.length) {
          let presArray = [];
          for (let i = 0; i < result?.prescription_data.length; i++) {
            const prescriptionName = result?.prescription_data[i];

            const prescriptionUrl = await generateGetUrl(
              prescriptionName,
              "prescriptionImages"
            );

            presArray.push(prescriptionUrl);
          }
          setPrescriptions(presArray);
          setLoading(false);
        }
      }
    })();
  }, [prescriptionId]);

  if (!prescriptionId) {
    return null;
  }

  return (
    <Modal
      visible={visible}
      footer={false}
      centered
      title={false}
      closable={false}
      className={classes.showPrescriptionModal}
      onCancel={() => {
        setLoading(true);
        onCancel();
      }}
    >
      {!loading ? (
        // <img src="https://www2.cortland.edu/cost-aid/images/samplebill.png" />
        <img
          src={prescriptions[currentIndex]}
          alt={`prescription-${currentIndex}`}
        />
      ) : (
        <Loader height="250px" />
      )}
      <CloseOutlined
        className={classes.closeModalIcon}
        onClick={() => {
          setLoading(true);
          onCancel();
        }}
      />
      <Input
        readOnly
        value={`${currentIndex + 1}/${prescriptions.length}`}
        size="small"
        prefix={<LeftOutlined onClick={prevPres} />}
        suffix={<RightOutlined onClick={nextPres} />}
        className={classes.prescriptionPagination}
      />
    </Modal>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  showPrescriptionModal: {
    "& .ant-modal-content": {
      width: "fit-content",
      margin: "auto",
    },
    "& .ant-modal-body": {
      padding: "0px !important",
    },
    "& img": {
      maxHeight: "60vh",
      maxWidth: "50vw",
    },
  },
  closeModalIcon: {
    position: "fixed",
    color: "#fff",
    zIndex: 9,
    top: 60,
    right: 90,
    fontSize: 25,
  },
  prescriptionPagination: {
    width: 80,
    position: "fixed",
    left: "calc(50% - 40px)",
    bottom: 90,
    zIndex: 9,
    fontSize: 12,
    textAlign: "center",
    color: "#fff",
    background: "#000000",
    borderRadius: 10,

    "& .ant-input": {
      color: "inherit",
      background: "inherit",
      fontSize: "inherit",
      textAlign: "inherit",
    },
  },
}));

export default ShowPrescriptionModal;
