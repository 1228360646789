import { getSession, Session, setSession } from "client/reactives/session";

const url = process.env.REACT_APP_API_NODE_BASE_URL;

export async function createOtp(data: any) {
  const { token } = (await getSession()) as Session;
  const endpoint = "/createOtp";
  return await fetch(url + endpoint, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
}
export async function validateOtp(data: any) {
  let otp = data.otp;
  let mobile_number = data.mobile_number;
  let otp_type = data.otp_type;
  const endpoint = `/validateOtp?otp=${otp}&mobile_number=${mobile_number}&otp_type=${otp_type}`;

  return await fetch(url + endpoint, {
    method: "GET",
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
}

export async function validatePasswordOtp({
  otp,
  mobile_number,
  otp_type,
}: any) {
  const endpoint = `/validatePasswordOtp?otp=${otp}&mobile_number=${mobile_number}&otp_type=${otp_type}`;

  return await fetch(url + endpoint, {
    method: "GET",
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
}

export async function getUserById(userId: number) {
  const { token } = getSession() as Session;
  const endpoint = `/getUserById?user_id=${userId}`;

  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function getAddresses(id: number) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/getAddresses?id=${id}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json?.length ? json[0] : null;
    });
}

export async function getAddressesByUserID(addressable_type: string) {
  const { token, userId } = (await getSession()) as Session;
  const endpoint = `/getAddressesByUserID?user_id=${userId}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json[0];
    });
}

export async function getRetailStores(id: number) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/getRetailStores?id=${id}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json[0];
    });
}

export async function getRetailerRetailStores() {
  const { token, userId } = (await getSession()) as Session;
  const endpoint = `/getRetailerRetailStores?user_id=${userId}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json[0];
    });
}

export async function getOrders(mobile_number?: string) {
  const { token, mobileNumber } = getSession() as Session;
  const endpoint = `/getOrders?mobile_number='${
    mobile_number ?? mobileNumber
  }'`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
}

export async function getOrdersByIds(matchIds: string[]) {
  const { token, mobileNumber } = getSession() as Session;
  const endpoint = `/getOrders?match_ids=${matchIds}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
}

export async function getOrdersByUserId(userId: number, storeId: number) {
  const { token, mobileNumber } = getSession() as Session;
  const endpoint = `/getOrders?user_id=${userId}&retail_store_id=${storeId}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
}

export async function getRetailStoresUsersMapping() {
  const { token, userId } = (await getSession()) as Session;
  const endpoint = `/getRetailStoresUsersMapping?user_id=${userId}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
}

export async function insertRetailStoreUsersMapping(
  retail_store_code: any,
  customerUserId?: number | string
) {
  const { token, userId } = (await getSession()) as Session;
  let endpoint = `/insertRetailStoreUsersMapping`;

  if (!!customerUserId) {
    endpoint += `?retail_store_id=${retail_store_code}&user_id=${customerUserId}`;
  } else {
    endpoint += `?retail_store_code=${retail_store_code}&user_id=${userId}`;
  }

  return await fetch(url + endpoint, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
}

export async function getRetailerOrders(retailStoreId: number) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/getRetailerOrders?retail_store_id=${retailStoreId}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
}

export async function getOrderByUid(uid: string) {
  const endpoint = `/getOrderByUid?uid='${uid}'`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
}

export async function fetchOrders(
  id: number,
  page?: any,
  search?: any,
  priority?: boolean | string
) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/getOrders?retail_store_id=${id}&limit=20&page=${
    page ?? ""
  }&search=${search ?? ""}&priority=${priority}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
}

export async function getOrderImage(uid: string) {
  const endpoint = `/getOrderImage?uid='${uid}'`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
}

export async function getOrderItems(orderId: number) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/getOrderItems?order_id=${orderId}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
}

export async function getProducts(id: number) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/getProducts?id=${id}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json[0];
    });
}

export async function getShipping(retailerId: number, cartId?: number) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/shipping/get?retail_store_id=${retailerId}&cart_id=${
    cartId ?? ""
  }`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function setShipping(data: any) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/shipping/post`;
  return await fetch(url + endpoint, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function generateGetUrl(key: string, bucketName: string) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/generateGetUrl?Key=${key}&BucketDirName=${bucketName}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json.getURL;
    });
}

export async function generateGetUrlForUid(key: string, bucketName: string) {
  // const { token } = await getSession() as Session
  const endpoint = `/generateGetUrlForUid?Key=${key}&BucketDirName=${bucketName}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      // "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json.getURL;
    });
}

export async function generatePutUrl(key: string, type: string) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/generatePutUrl?Key=${key}&ContentType=${type}&BucketDirName=prescriptionImages`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json.putURL;
    });
}

export async function uploadImage(url: string, file: File) {
  return await fetch(url, {
    method: "PUT",
    body: file,
    redirect: "follow",
    headers: {
      "Content-Type": file.type,
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => console.log(response.status))
    .then((result) => console.log("Success"))
    .catch((err) => {
      console.log("err", err);
    });
}

export async function deleteImageFromS3(key: string) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/deleteImageFromS3?Key=${key}&BucketDirName=prescriptionImages`;
  let data = {};
  return await fetch(url + endpoint, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json.putURL;
    });
}

export async function getUsers(mobile_nuumber: String) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/getUsers?mobile_number=${mobile_nuumber}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function getCart(id: number) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/getCart?id='${id}'`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
}

export async function getCustomerCart(
  retailStoreId: number,
  customerUserId?: number
) {
  const { token, userId } = (await getSession()) as Session;
  let endpoint = `/getCheckCart?retail_store_id=${retailStoreId}`;

  if (!!customerUserId) {
    endpoint += `&user_id=${customerUserId}`;
  } else {
    endpoint += `&user_id=${userId}`;
  }

  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
}

export async function getCartItems(cartId: number) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/getCartItems?cart_id=${cartId}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
}

export async function deleteCart(id: number) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/deleteCart?id=${id}`;
  return await fetch(url + endpoint, {
    method: "DELETE",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
}

export async function deleteCartItem(id: number) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/deleteCartItems?id=${id}`;
  return await fetch(url + endpoint, {
    method: "DELETE",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
}

export async function deleteAllCartItems(cart_id: number) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/deleteAllCartItems?cart_id=${cart_id}`;
  return await fetch(url + endpoint, {
    method: "DELETE",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
}

export async function updateCart(id: number, data: any) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/updateCart?id=${id}`;
  let body = {
    price_with_gst: data?.price_with_gst,
    price_with_out_gst: data?.price_with_out_gst,
    discount: data?.discount,
    gst: data?.gst,
  };
  return await fetch(url + endpoint, {
    method: "PUT",
    body: JSON.stringify(body),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
}

export async function updateCartItems(id: number, data: any) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/updateCartItems?id=${id}`;
  return await fetch(url + endpoint, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
}

export async function updateCartItemsByProductId(
  cart_id: number,
  product_id: number,
  data: any
) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/updateCartItemsProduct?cart_id=${cart_id}&product_id=${product_id}`;
  return await fetch(url + endpoint, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
}

export async function updateCartItemsByProductSKU(
  cart_id: number,
  product_sku: number,
  data: any
) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/updateCartItemsProduct?cart_id=${cart_id}&product_sku=${product_sku}`;
  return await fetch(url + endpoint, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
}

export async function insertCartItems(data: any) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/insertCartItems`;
  return await fetch(url + endpoint, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
}

export async function insertCart(
  data: any,
  returnExistingCart?: boolean,
  customerUsedId?: string
) {
  const { token, userId } = (await getSession()) as Session;
  let endpoint = `/insertCart`;

  if (!!returnExistingCart) {
    endpoint += "?return_existing_cart=true";
  }

  const body = {
    retail_store_id: data?.retail_store_id,
    payment_status: data?.payment_status,
    discount: data?.discount,
    gst: data?.gst,
    price_with_gst: data?.price_with_gst,
    price_with_out_gst: data?.price_with_out_gst,
    user_id: data?.user_id ?? userId,
  };

  return await fetch(url + endpoint, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
}

export async function insertPrescription(data: any) {
  const { userId, token } = (await getSession()) as Session;
  const endpoint = `/insertPrescription`;
  let body = {
    user_id: userId,
    retail_store_id: data?.retailStoreId,
    prescription_data: data?.prescriptionData,
  };
  return await fetch(url + endpoint, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json[0];
    });
}

export async function getPrescriptions() {
  const { token, userId } = (await getSession()) as Session;
  const endpoint = `/getPrescriptions?user_id=${userId}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
}

export async function changePassword(data: any) {
  const { token, userId } = (await getSession()) as Session;
  const endpoint = `/changePassword?id=${userId}`;
  return await fetch(url + endpoint, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json.error;
    });
}

export async function getCustomer() {
  const { token, userId } = (await getSession()) as Session;
  const endpoint = `/getCustomer?user_id=${userId}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json[0];
    });
}

export async function updateAddresses(
  addressable_id: number,
  addressable_type: string,
  data: any
) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/updateAddresses?addressable_id=${addressable_id}&addressable_type='${addressable_type}'`;
  return await fetch(url + endpoint, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json[0];
    });
}

export async function updateRetailStores(id: number, data: any) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/updateRetailStores?id=${id}`;
  return await fetch(url + endpoint, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json[0];
    });
}

export async function updatePassword(
  id: any,
  newPassword: any,
  customToken?: string
) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/updatePassword?id=${id}`;
  return await fetch(url + endpoint, {
    method: "PUT",
    body: JSON.stringify({ password_digest: newPassword }),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${customToken || token}`,
    },
  }).then((response) => response.json());
}

export async function updateUser(id: number, customer_name: any, _token: any) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/updateUser?user_id=${id}`;
  return await fetch(url + endpoint, {
    method: "PUT",
    body: JSON.stringify({ user_name: customer_name, token: _token }),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function insertUsers(data: any) {
  const endpoint = "/insertUsers";
  return await fetch(url + "/insertUsers", {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  }).then((response) => response.json());
}

export async function insertInactiveCustomer(data: any) {
  const endpoint = `/insertInactiveCustomer`;
  const { token } = (await getSession()) as Session;

  return await fetch(url + endpoint, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function insertAddresses(data: any) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/insertAddresses`;

  return await fetch(url + endpoint, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function insertAddress(data: any) {
  const { token } = (await getSession()) as Session;
  const endpoint = "/insertAddresses";
  return await fetch(url + endpoint, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json.length ? json[0] : null;
    });
}

export async function getReorders(retailStoreId?: any) {
  const { token, userId } = (await getSession()) as Session;
  const endpoint = `/getCustomerReorders?user_id=${userId}&retail_store_id=${
    retailStoreId || ""
  }`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
}

export async function getReorderItems(reorderId: number) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/getReorderItems?reorder_id=${reorderId}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
}

export async function deleteReorder(id: number) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/deleteReorders?id=${id}`;
  return await fetch(url + endpoint, {
    method: "DELETE",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
}

export async function deleteAllReorderItems(reorder_id: number) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/deleteAllReorderItems?reorder_id=${reorder_id}`;
  return await fetch(url + endpoint, {
    method: "DELETE",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
}

export async function insertReorder(reorderData: any) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/insertReorder`;
  return await fetch(url + endpoint, {
    method: "POST",
    body: JSON.stringify(reorderData),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function insertReorderItems(reorderItemData: any) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/insertReorderItems`;
  return await fetch(url + endpoint, {
    method: "POST",
    body: JSON.stringify(reorderItemData),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function getPrescriptionsById(id: number) {
  const { token, userId } = (await getSession()) as Session;
  const endpoint = `/getPrescriptionsById?id=${id}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json[0];
    });
}

export async function getRatings(
  order_id: number,
  bill_type: "order" | "reorder"
) {
  const { token, userId } = (await getSession()) as Session;
  const endpoint = `/get-ratings?${bill_type}_id=${order_id}&customer_id=${userId}&bill_type=${bill_type}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json[0];
    });
}

export async function postRatings(data: any, update: boolean) {
  const { token, userId } = (await getSession()) as Session;
  var endpoint = `/post-ratings`;
  var methodType = `POST`;
  if (update) {
    endpoint = `/update-ratings`;
    methodType = `PUT`;
  }
  return await fetch(url + endpoint, {
    method: methodType,
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json[0];
    });
}

export async function getReorderById(id: string) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/getReorderById?reorder_id='${id}'`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
}

export async function getCartLoyaltyInfo(
  cartId: number,
  retailStoreId: number,
  customerUserId?: number
) {
  const { token, userId } = (await getSession()) as Session;
  let endpoint = `/loyalty/get/info?retail_store_id=${retailStoreId}&cart_id=${cartId}`;

  if (!!customerUserId) {
    endpoint += `&customer_id=${customerUserId}`;
  } else {
    endpoint += `&customer_id=${userId}`;
  }

  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((e) => console.log(e));
}

export async function getReorderLoyaltyInfo(
  reorderId: number,
  retailStoreId: number
) {
  const { token, userId } = (await getSession()) as Session;
  const endpoint = `/loyalty/get/info?retail_store_id=${retailStoreId}&customer_id=${userId}&reorder_id=${reorderId}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((e) => console.log(e));
}

export async function getOrderLoyaltyInfo(
  orderId: number,
  retailStoreId: number
) {
  const { token, userId } = (await getSession()) as Session;
  const endpoint = `/loyalty/get/orderInfo?retail_store_id=${retailStoreId}&user_id=${userId}&order_id=${orderId}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((e) => console.log(e));
}

export async function insertReorderLoyalty(data: any) {
  const { token, userId }: any = (await getSession()) as Session;
  const endpoint = `/loyalty/reorder`;

  let bodyData: any = {
    cart_id: +data?.cart_id,
    retail_store_id: +data.retail_store_id,
    reorder_amount: +data.reorder_amount,
    use_loyalty_points: `${data.use_loyalty_points}`,
    reorder_id: +data.reorder_id,
    customer_id: data?.user_id ?? +userId,
  };

  return await fetch(url + endpoint, {
    method: "POST",
    body: JSON.stringify(bodyData),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
}

export async function getStates(country: string) {
  const endpoint1 = `https://www.universal-tutorial.com/api/getaccesstoken`;
  const endpoint2 = `https://www.universal-tutorial.com/api/states/${country}`;

  return await fetch(endpoint1, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "api-token": `Mq5C9IQtqCX8DsYbm6_bXQgrp3NZjOBKgdABB1f_I4_B_fL9pneoR-iy8E-0Rzqp7Ec`,
      "user-email": "karan.rajput@raseet.com",
    },
  })
    .then((response) => response.json())
    .then(async (json) => {
      if (json?.auth_token) {
        return await fetch(endpoint2, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${json.auth_token}`,
            Accept: "application/json",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            return data.map((item: any) => ({
              label: item.state_name,
              value: item.state_name,
            }));
          });
      }
    });
}

export async function getCities(state: string) {
  const endpoint1 = `https://www.universal-tutorial.com/api/getaccesstoken`;
  const endpoint2 = `https://www.universal-tutorial.com/api/cities/${state}`;

  return await fetch(endpoint1, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "api-token": `Mq5C9IQtqCX8DsYbm6_bXQgrp3NZjOBKgdABB1f_I4_B_fL9pneoR-iy8E-0Rzqp7Ec`,
      "user-email": "karan.rajput@raseet.com",
    },
  })
    .then((response) => response.json())
    .then(async (json) => {
      if (json?.auth_token) {
        return await fetch(endpoint2, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${json.auth_token}`,
            Accept: "application/json",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            return data.map((item: any) => ({
              label: item.city_name,
              value: item.city_name,
            }));
          });
      }
    });
}

export async function getUserLoyaltyDetails(
  customerId: any,
  retailStoreId: any
) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/loyalty?retail_store_id=${retailStoreId}&customer_id=${customerId}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((e) => console.log(e));
}

export async function redeemLoyaltyPoints(data: any) {
  const { token }: any = (await getSession()) as Session;
  const endpoint = `/loyalty/add`;

  return await fetch(url + endpoint, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
}
export async function getUtilities(
  storeId: number,
  pageNumber: any,
  category: string
) {
  const { token } = (await getSession()) as Session;
  var endpoint: any;
  if (category === null || category === "All")
    endpoint = `/utility/get?retail_store_id=${storeId}&page=${pageNumber}`;
  else
    endpoint = `/utility/get?retail_store_id=${storeId}&page=${pageNumber}&category=${category}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "x-access-token": `${token}`,
      "Content-Type": "application/json",
    },
  })
    .then((reponse) => {
      return reponse.json();
    })
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log("error", err);
    });
}

export async function getUtilityCategory(storeId: number) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/utility/get/category?retail_store_id=${storeId}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "x-access-token": `${token}`,
      "Content-Type": "application/json",
    },
  })
    .then((reponse) => {
      return reponse.json();
    })
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log("error", err);
    });
}

export async function getCatalogue(
  api: string,
  storeId: number,
  pageNumber: number = 1,
  search: string = "",
  customerUserId?: number
) {
  const { token, userId } = (await getSession()) as Session;

  let endpoint = `${api}?retail_store_id=${storeId}&page=${pageNumber}&name=${search}`;

  if (!!customerUserId) {
    endpoint += `&user_id=${customerUserId}`;
  } else {
    endpoint += `&user_id=${userId}`;
  }

  return await fetch(encodeURI(url + endpoint), {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((e) => console.log(e));
}

export async function userLoginByUID(uid: any, isUserUUID = false) {
  let endpoint = `/uidLogin?order_uuid=${uid}`;

  if (isUserUUID) {
    endpoint = `/uidLogin?user_uuid=${uid}`;
  }

  return await fetch(url + endpoint, {
    method: "POST",
    body: JSON.stringify({
      client_key: uid,
    }),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
}

export async function createRazorpayOrder(reqBody: any) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/payments/create/order`;
  return await fetch(url + endpoint, {
    method: "POST",
    body: JSON.stringify(reqBody),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
}

export async function verifyPaymentSuccess(reqBody: any) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/payments/verify/success`;
  return await fetch(url + endpoint, {
    method: "POST",
    body: JSON.stringify(reqBody),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
}

export async function getCustomerCoupons(
  retailStoreId: number,
  cartAmount: number,
  customerUserId?: number
) {
  const { token, userId } = (await getSession()) as Session;
  let endpoint = `/coupons/get?type=customer&retail_store_id=${retailStoreId}&cart_amount=${cartAmount}`;

  if (!!customerUserId) {
    endpoint += `&user_id=${customerUserId}`;
  } else {
    endpoint += `&user_id=${userId}`;
  }

  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
}

export async function updatePaymentFailedStatus(reqBody: any) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/payments/failed`;
  return await fetch(url + endpoint, {
    method: "POST",
    body: JSON.stringify(reqBody),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
}

export async function applyCoupon(coupon_code: string, cart_id: number) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/coupons/apply`;
  const data = { coupon_code, cart_id };
  return await fetch(url + endpoint, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
}

export async function updateReorderStatus(reorder_id: number, status: number) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/updateReorderStatus`;

  return await fetch(url + endpoint, {
    method: "PUT",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
    body: JSON.stringify({
      reorder_id: reorder_id,
      new_status: status,
    }),
  }).then((res) => res.json());
}

export async function getProductDetails(
  pid: number,
  retailStoreId: number,
  source: string,
  customerUserId?: number
) {
  const { token, userId } = (await getSession()) as Session;
  let endpoint = `/catalogue/products/${pid}?retail_store_id=${retailStoreId}&source=${source}`;

  if (!!customerUserId) {
    endpoint += `&user_id=${customerUserId}`;
  } else {
    endpoint += `&user_id=${userId}`;
  }

  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((res) => res.json());
}

export async function generateLoginOtp(data: any) {
  const endpoint = `/createLoginOtp`;

  return await fetch(url + endpoint, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
}

export async function loginUser(data: any) {
  const endpoint = `/otpLogin`;
  return await fetch(url + "/login", {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.json())
    .then((json) => {
      if (json.auth) {
        setSession(
          json.token,
          json.user_id,
          json.user_type,
          json.user_name,
          json.mobile_number
        );
        return { status: true };
      } else {
        console.log(json.error);
        return { status: false, error: json.error };
      }
    });
}

export async function OtpLogin(data: any) {
  const endpoint = `/otpLogin`;
  return await fetch(url + endpoint, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.json())
    .then((json) => {
      if (json.auth) {
        setSession(
          json.token,
          json.user_id,
          json.user_type,
          json.user_name,
          json.mobile_number
        );
      }
      return json;
    });
}

export async function getShopBanner(retailStoreId: number, page?: string) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/banner/get?retail_store_id=${retailStoreId}&page=${page}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function getPromotions(retailStoreId: number) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/promotion/getAll?retail_store_id=${retailStoreId}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function subscribeFCMTopic(data: any) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/subscribeFCMTopic`;
  return await fetch(url + endpoint, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function unsubscribeFCMTopic(data: any) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/unsubscribeFCMTopic`;
  return await fetch(url + endpoint, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function getConnectedRetailStores(userId: number) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/getConnectedRetailStores?user_id=${+userId}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function getBlogs(categories?: number, page?: number) {
  let endpoint = `https://blog.raseet.com/wp-json/wp/v2/posts`;
  if (!!categories) {
    endpoint += `?categories=${categories}&page=${page}`;
  }

  return await fetch(endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
    },
  }).then((response) => response.json());
}

export async function getBlogDetails(slug?: string) {
  let endpoint = `https://blog.raseet.com/wp-json/wp/v2/posts?slug=${slug}`;

  return await fetch(endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
    },
  }).then((response) => response.json());
}

export async function getBlogCategories() {
  const endpoint = `https://blog.raseet.com/wp-json/wp/v2/categories`;
  return await fetch(endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
    },
  }).then((response) => response.json());
}

export async function getRetailStoreBasicDetails(code: string) {
  const endpoint = `/getRetailStoreBasicDetails?retail_store_code=${code}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  }).then((response) => response.json());
}

export async function getCustomerLoyaltyDetails() {
  const { token, userId } = (await getSession()) as Session;
  const endpoint = `/loyalty/customer/basic?user_id=${userId}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function getCustomerLoyaltyLogs() {
  const { token, userId } = (await getSession()) as Session;
  const endpoint = `/loyalty/log?&rows_to_skip=0&row_count=1000&customer_id=${userId}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function getSubscriptionHistory(retailStoreId: number) {
  const { token, userId } = (await getSession()) as Session;
  const endpoint = `/subscriptions/${userId}/history?retail_store_id=${retailStoreId}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function getCurrentSubscription(retailStoreId: number) {
  const { token, userId } = (await getSession()) as Session;
  const endpoint = `/subscriptions/${userId}/current?retail_store_id=${retailStoreId}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function getPlans() {
  const { token, userId } = (await getSession()) as Session;
  const endpoint = `/subscriptions/listPlans?user_id=${userId}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function createTicket(data: any) {
  const { token, userId } = (await getSession()) as Session;
  const endpoint = `/supportTickets/createTicket?user_id=${userId}`;
  return await fetch(url + endpoint, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}

export async function getUtilitiesByType(retailStoreId: number, type: string) {
  const { token } = (await getSession()) as Session;
  const endpoint = `/utility/get?retail_store_id=${retailStoreId}&utility_type=${type}`;
  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((response) => response.json());
}
