import { Button, Carousel, message, Typography } from "antd";
import React from "react";
import { createUseStyles } from "react-jss";

import DotsVector from "assets/svgs/dots-vector.svg";
import DotsVector2 from "assets/svgs/dots-vector-2.svg";
import DiabatesCare from "assets/svgs/diabetes-care.svg";
import SuperSaver from "assets/svgs/super-saver.svg";

import DrIcon from "assets/svgs/dr-icon.svg";
import TestIcon from "assets/svgs/test-icon.svg";
import DietIcon from "assets/svgs/diet-icon.svg";
import DeiceIcon from "assets/svgs/deice-icon.svg";
import { useHistory, useRouteMatch } from "react-router-dom";
import { getSession, Session } from "client/reactives/session";
import { insertCart, insertCartItems } from "api/api";
import gtag from "components/Tracking/gtag";

const DiabatesCareBenefits = [
  {
    icon: DrIcon,
    label: "Unlimited Doctor consultations",
  },
  {
    icon: TestIcon,
    label: "Unlimited Diagnostic tests",
  },
  {
    icon: DietIcon,
    label: "Personalised Diet & Nutrition planning",
  },
  {
    icon: DeiceIcon,
    label: "Monthly Subscription of Test strips & Lancets",
  },
];

const PlansCardVectors = {
  "Basic Plan": SuperSaver,
  "Super Saver": SuperSaver,
  "Diabetes Care": DiabatesCare,
};

type RouteParams = {
  retailStoreId: string;
};

interface PlansSectionProps {
  plans: any[];
}

export default function PlansSection({ plans }: PlansSectionProps) {
  const classes = useStyle();
  const history = useHistory();
  const {
    params: { retailStoreId },
  } = useRouteMatch<RouteParams>() || {};
  const { userId } = getSession() as Session;

  const getCartId = async () => {
    let cartId = 0;

    let cartData = {
      retail_store_id: +retailStoreId,
      payment_status: false,
      discount: 0,
      gst: 0,
      price_with_gst: 0,
      price_with_out_gst: 0,
      user_id: userId,
    };
    // Create the cart
    const insertCartRes = await insertCart(cartData, true);
    if (insertCartRes?.length) {
      cartId = insertCartRes[0].id;
    }

    return cartId;
  };

  const handlePlanSubscribe = (item: any) => async () => {
    let cartID = await getCartId();

    let productPrice = item?.strike_price ?? item.pack_price;
    let newCartItem = {
      product_section: "other_products",
      product_id: item.catalogue_product_id,
      product_sku: item.product_sku,
      quantity: 1,
      cart_id: cartID,
      cgst: 0,
      sgst: 0,
      discount: 0,
      total: productPrice,
      sub_total: productPrice,
      is_plan: true,
    };

    await insertCartItems(newCartItem).then((res) => {
      if (res && res.id) {
        message.success("Added to cart");
        history.push(`/dashboard/stores/${retailStoreId}/cart/${cartID}`);

        gtag("event", "plan_cart_add", {
          source: "plans_page",
          plan: "saver",
          user: userId,
        });
      }
    });
  };

  return (
    <Carousel className={classes.plansCarousel} arrows>
      {plans.map((plan, index) => (
        <PlanCard
          key={index}
          item={plan}
          onSubscribeClick={handlePlanSubscribe}
        />
      ))}
    </Carousel>
  );
}

const PlanCard = ({ item, onSubscribeClick }: any) => {
  const classes = useStyle();

  return (
    <div className={classes.plansCard}>
      <img src={DotsVector} alt="" className={classes.dotsVector} />
      <img src={DotsVector2} alt="" className={classes.dotsVector2} />

      <img
        src={PlansCardVectors?.[item.plan_name]}
        alt=""
        className={classes.planCardVector}
      />

      <div>
        <Typography.Text className={classes.plansCardTitle}>
          {item.plan_name}
        </Typography.Text>
        <Typography.Text className={classes.plansCardDescription}>
          {item.plan_name === "Diabetes Care"
            ? "Manage your diabetes with ease"
            : "Make your healthcare affordable"}
        </Typography.Text>
      </div>

      <div>
        {item.plan_name === "Diabetes Care" ? (
          <div style={{ display: "flex" }}>
            {DiabatesCareBenefits.map((item, index) => (
              <div key={index} className={classes.planBenefitItem}>
                <img src={item.icon} alt="" />
                <Typography.Text>{item.label}</Typography.Text>
              </div>
            ))}
            <Typography.Text className={classes.andMoreText}>
              & more
            </Typography.Text>
          </div>
        ) : (
          <Typography.Text className={classes.basicPlanContent}>
            Get additional <Typography.Text strong>5% cashback</Typography.Text>{" "}
            on every order*
          </Typography.Text>
        )}
      </div>

      <div>
        <Typography.Text className={classes.plansCardPrice}>
          starts at
          <Typography.Text strong>
            ₹{parseInt(item?.strike_price ?? item?.pack_price)}
          </Typography.Text>
          per{" "}
          {item.plan_duration_in_months > 1
            ? `${item.plan_duration_in_months} months`
            : "month"}
        </Typography.Text>

        <Button
          type="primary"
          // size="small"
          className={classes.plansCardCta}
          onClick={onSubscribeClick(item)}
        >
          Click to Select
        </Button>
      </div>
    </div>
  );
};

const useStyle = createUseStyles(({ colors }: Theme) => ({
  plansCarousel: {
    marginTop: -15,

    "& .slick-slide": {
      padding: [10, 2],
    },
  },

  plansCard: {
    height: 240,
    padding: [30, 20, 20],
    position: "relative",
    background: colors.light100,
    border: "2.5px solid #f7f7f7",
    // filter: "drop-shadow(4px 4px 5px rgba(0, 0, 0, 0.15))",
    borderRadius: 10,

    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  dotsVector: {
    position: "absolute",
    top: 10,
    left: 0,
  },
  dotsVector2: {
    position: "absolute",
    right: 10,
    bottom: 0,
  },
  planCardVector: {
    position: "absolute",
    right: 10,
    bottom: 0,
  },
  plansCardTitle: {
    fontSize: 20,
    display: "block",
    fontWeight: 700,
    lineHeight: "18px",
  },
  plansCardDescription: {
    fontSize: 10,
  },
  basicPlanContent: {
    display: "block",
    fontSize: 14,

    "& strong": {
      fontSize: 16,
      color: colors.primary,
    },
  },
  plansCardPrice: {
    display: "block",
    "& strong": {
      margin: [0, 5],
      fontSize: 20,
    },
  },
  planBenefitItem: {
    width: "25%",
    paddingRight: 10,

    "& img": {
      width: 20,
      height: 20,
      marginBottom: 5,
    },
    "& .ant-typography": {
      display: "block",
      fontSize: 8,
    },
  },
  andMoreText: {
    display: "block",
    marginTop: 20,
    fontSize: 12,
    whiteSpace: "nowrap",
    color: colors.dark400,
  },
  plansCardCta: {
    marginTop: 10,
    padding: [5, 20],
    fontSize: 10,
    borderRadius: 5,
  },
}));
