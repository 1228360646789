import {
  Button,
  Card,
  Empty,
  Layout,
  message,
  Modal,
  Row,
  Space,
  Tabs,
  Typography,
} from "antd";
import {
  createTicket,
  getCurrentSubscription,
  getPlans,
  getRetailStores,
  getSubscriptionHistory,
  insertCart,
  insertCartItems,
} from "api/api";
import { getSession, Session } from "client/reactives/session";
import FooterMenu from "components/FooterMenu";
import Loader from "components/loader/Loader";
import gtag from "components/Tracking/gtag";
import RetailStoreDetailsView from "components/views/RetailerStoreDetailsView";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { createUseStyles } from "react-jss";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

import GoodByeVector from "assets/svgs/goodbye-vector.png";
import DotsVector from "assets/svgs/dots-vector.svg";
import DotsVector2 from "assets/svgs/dots-vector-2.svg";
import DiabatesCare from "assets/svgs/diabetes-care.svg";
import SuperSaver from "assets/svgs/super-saver.svg";

import DrIcon from "assets/svgs/dr-icon.svg";
import TestIcon from "assets/svgs/test-icon.svg";
import DietIcon from "assets/svgs/diet-icon.svg";
import DeiceIcon from "assets/svgs/deice-icon.svg";

type RouteParams = {
  retailStoreId: string;
};

const DiabatesCareBenefits = [
  {
    icon: DrIcon,
    label: "Unlimited Doctor consultations",
  },
  {
    icon: TestIcon,
    label: "Unlimited Diagnostic tests",
  },
  {
    icon: DietIcon,
    label: "Personalised Diet & Nutrition planning",
  },
  {
    icon: DeiceIcon,
    label: "Monthly Subscription of Test strips & Lancets",
  },
];

const PlansCardVectors = {
  "Basic Plan": SuperSaver,
  "Super Saver": SuperSaver,
  "Diabetes Care": DiabatesCare,
};

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function PlansPage() {
  const classes = useStyle();
  const query = useQuery();
  const history = useHistory();
  const {
    params: { retailStoreId },
  } = useRouteMatch<RouteParams>() || {};

  const { userId } = getSession() as Session;
  const [activeKey, setActiveKey] = useState("2");
  const [loading, setLoading] = useState(true);
  const [storeDetails, setStoreDetails] = useState({});
  const [currentPlan, setCurrentPlan] = useState<any>(null);
  const [plansHistory, setPlansHistory] = useState<any[]>([]);
  const [plans, setPlans] = useState<any[]>([]);

  const handleCancelSubscriptionClick = () => {
    Modal.confirm({
      icon: false,
      centered: true,
      width: "90vw",
      title: "Are you sure you want to cancel the subscription?",
      // content: "",
      okText: "Confirm",
      onOk: async () => {
        await createTicket({
          retail_store_id: retailStoreId,
          status: "OPEN",
          ticket_cause: "CANCEL SUBSCRIPTION",
        }).then((res) => {
          if (!!res?.id) {
            Modal.info({
              icon: false,
              closable: true,
              okText: false,
              centered: true,
              width: "90vw",
              className: classes.cancelModal,
              content: (
                <>
                  <img src={GoodByeVector} alt="" />
                  <Typography.Text strong className={classes.cancelModalTitle}>
                    We are sorry to see you unsubscribe
                  </Typography.Text>
                  <Typography.Paragraph>
                    Your request will be processed within the next 24 hours.
                  </Typography.Paragraph>
                </>
              ),
            });

            return message.success("Request sent successfully!");
          }

          message.error("Something went wrong!");
        });
      },
    });
  };

  const getCartId = async () => {
    let cartId = 0;

    let cartData = {
      retail_store_id: +retailStoreId,
      payment_status: false,
      discount: 0,
      gst: 0,
      price_with_gst: 0,
      price_with_out_gst: 0,
      user_id: userId,
    };
    // Create the cart
    const insertCartRes = await insertCart(cartData, true);
    if (insertCartRes?.length) {
      cartId = insertCartRes[0].id;
    }

    return cartId;
  };

  const handlePlanSubscribe = (item: any) => async () => {
    let cartID = await getCartId();

    let productPrice = item?.strike_price ?? item.pack_price;
    let newCartItem = {
      product_section: "other_products",
      product_id: item.catalogue_product_id,
      product_sku: item.product_sku,
      quantity: 1,
      cart_id: cartID,
      cgst: 0,
      sgst: 0,
      discount: 0,
      total: productPrice,
      sub_total: productPrice,
      is_plan: true,
    };

    await insertCartItems(newCartItem).then((res) => {
      if (res && res.id) {
        message.success("Added to cart");
        history.push(`/dashboard/stores/${retailStoreId}/cart/${cartID}`);

        gtag("event", "plan_cart_add", {
          source: "plans_page",
          plan: "saver",
          user: userId,
        });
      }
    });
  };

  const fetchInitialData = async () => {
    let storeDetailsRes = await getRetailStores(+retailStoreId);

    let plansRes = await getPlans();
    let currentSubscriptionRes = await getCurrentSubscription(+retailStoreId);
    let subscriptionsHistoryRes = await getSubscriptionHistory(+retailStoreId);

    setPlans(plansRes.data);
    setCurrentPlan(currentSubscriptionRes.data);
    setPlansHistory(subscriptionsHistoryRes.data);
    setStoreDetails(storeDetailsRes);
    setLoading(false);
  };

  useEffect(() => {
    if (loading) {
      fetchInitialData();
    }
  }, []);

  useEffect(() => {
    let queryTabKey = query.get("tab_key");
    // @ts-ignore
    if (+queryTabKey !== +activeKey) {
      setActiveKey(queryTabKey ?? "1");
    }
  }, [query.get("tab_key")]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={classes.plansPage}>
      <RetailStoreDetailsView retailStore={storeDetails} />

      <Tabs
        activeKey={activeKey}
        onChange={setActiveKey}
        className={classes.pageTabs}
      >
        {/* Current Plan Tab */}
        <Tabs.TabPane tab="Current Plan" key={1}>
          <div className={classes.currentPlanContent}>
            {!!currentPlan ? (
              <div>
                <Typography.Text strong>Plan Details</Typography.Text>

                <DetailItem
                  className={classes.currentPlanDetailsItem}
                  left={"Plan"}
                  right={currentPlan?.plan_name}
                />
                <DetailItem
                  className={classes.currentPlanDetailsItem}
                  left={"Price"}
                  right={
                    <>
                      {+currentPlan?.bought_at_cost <
                      +currentPlan?.pack_price ? (
                        <>
                          <del>₹{currentPlan?.pack_price}</del> ₹
                          {currentPlan?.bought_at_cost}
                        </>
                      ) : (
                        "₹" + currentPlan?.bought_at_cost
                      )}
                    </>
                  }
                />
                <DetailItem
                  className={classes.currentPlanDetailsItem}
                  left={"Starting Date"}
                  right={moment(currentPlan?.bought_on).format("DD MMMM YYYY")}
                />
                <DetailItem
                  className={classes.currentPlanDetailsItem}
                  left={"Expiry Date"}
                  right={moment(currentPlan?.expires_on).format("DD MMMM YYYY")}
                />

                <Row justify="end">
                  <Button
                    type="primary"
                    className={classes.customBtn}
                    onClick={handleCancelSubscriptionClick}
                  >
                    Cancel Subscription
                  </Button>
                </Row>
              </div>
            ) : (
              <div>
                <Typography.Text className={classes.currentPlanContentTitle}>
                  You haven’t selected any plan yet
                </Typography.Text>

                <Typography.Text>
                  To get 15% off on every online purchase
                </Typography.Text>

                <Button
                  type="primary"
                  className={classes.customBtn}
                  onClick={() => setActiveKey("2")}
                >
                  Select Plan
                </Button>
              </div>
            )}
          </div>
        </Tabs.TabPane>

        {/* Plans Tab */}
        <Tabs.TabPane tab="Plans" key={2}>
          <Space
            size={15}
            direction="vertical"
            className={classes.plansCardsWrapper}
          >
            {plans.map((plan, index) => (
              <PlanCard
                key={index}
                item={plan}
                onSubscribeClick={handlePlanSubscribe}
              />
            ))}
          </Space>
        </Tabs.TabPane>

        {/* History Tab */}
        <Tabs.TabPane tab="History" key={3}>
          {plansHistory?.length ? (
            <div className={classes.historyCardsWrapper}>
              {plansHistory.map((item, index) => (
                <PlanHistoryCard key={index} item={item} />
              ))}
            </div>
          ) : (
            <Empty
              style={{ paddingTop: 80 }}
              description="No history available"
            />
          )}
        </Tabs.TabPane>
      </Tabs>

      <Layout.Footer style={{ padding: 0 }}>
        <FooterMenu retailStoreId={retailStoreId} />
      </Layout.Footer>
    </div>
  );
}

const PlanCard = ({ item, onSubscribeClick }: any) => {
  const classes = useStyle();

  return (
    <div className={classes.plansCard}>
      <img src={DotsVector} alt="" className={classes.dotsVector} />
      <img src={DotsVector2} alt="" className={classes.dotsVector2} />

      <img
        src={PlansCardVectors?.[item.plan_name]}
        alt=""
        className={classes.planCardVector}
      />

      <div>
        <Typography.Text className={classes.plansCardTitle}>
          {item.plan_name}
        </Typography.Text>
        <Typography.Text className={classes.plansCardDescription}>
          {item.plan_name === "Diabetes Care"
            ? "Manage your diabetes with ease"
            : "Make your healthcare affordable"}
        </Typography.Text>
      </div>

      <div>
        {item.plan_name === "Diabetes Care" ? (
          <div style={{ display: "flex" }}>
            {DiabatesCareBenefits.map((item, index) => (
              <div key={index} className={classes.planBenefitItem}>
                <img src={item.icon} alt="" />
                <Typography.Text>{item.label}</Typography.Text>
              </div>
            ))}
            <Typography.Text className={classes.andMoreText}>
              & more
            </Typography.Text>
          </div>
        ) : (
          <Typography.Text className={classes.basicPlanContent}>
            Get additional <Typography.Text strong>5% cashback</Typography.Text>{" "}
            on every order*
          </Typography.Text>
        )}
      </div>

      <div>
        <Typography.Text className={classes.plansCardPrice}>
          starts at
          <Typography.Text strong>
            ₹{parseInt(item?.strike_price ?? item?.pack_price)}
          </Typography.Text>
          per{" "}
          {item.plan_duration_in_months > 1
            ? `${item.plan_duration_in_months} months`
            : "month"}
        </Typography.Text>

        <Button
          type="primary"
          // size="small"
          className={classes.plansCardCta}
          onClick={onSubscribeClick(item)}
        >
          Click to Select
        </Button>
      </div>
    </div>
  );
};

const PlanHistoryCard = ({ item }: any) => {
  const classes = useStyle();

  return (
    <Card className={classes.historyCard}>
      <DetailItem
        className={classes.historyCardDetailsItem}
        left={"Plan"}
        right={item?.plan_name}
        rightProps={{ className: classes.historyCardPlan }}
      />

      <DetailItem
        className={classes.historyCardDetailsItem}
        left={"Plan"}
        right={
          <>
            {+item?.bought_at_cost < +item?.pack_price ? (
              <>
                <del>₹{item?.pack_price}</del> ₹{item?.bought_at_cost}
              </>
            ) : (
              "₹" + item?.bought_at_cost
            )}
          </>
        }
      />
      <DetailItem
        className={classes.historyCardDetailsItem}
        left={"Started Date"}
        right={moment(item?.bought_on).format("DD MMMM YYYY")}
      />
      <DetailItem
        className={classes.historyCardDetailsItem}
        left={"Expired Date"}
        right={moment(item?.expires_on).format("DD MMMM YYYY")}
      />
      <DetailItem
        className={classes.historyCardDetailsItem}
        left={"Transaction ID"}
        right={item?.transaction_id ?? "Unavailable"}
      />
    </Card>
  );
};

const DetailItem = ({ className, left, right, rightProps }: any) => {
  const classes = useStyle();

  return (
    <div className={className}>
      <Typography.Text>{left}</Typography.Text>
      <span className={classes.colorSeparator}>:</span>
      <Typography.Text {...rightProps}>{right}</Typography.Text>
    </div>
  );
};

const useStyle = createUseStyles(({ colors }: Theme) => ({
  plansPage: {},
  pageHeader: {
    padding: 20,
    background: colors.light100,
    display: "flex",
    alignItems: "center",
    "& > .ant-typography": {
      fontSize: 14,
      marginLeft: 20,
    },
  },
  pageTabs: {
    "& .ant-tabs-nav-list": {
      width: "100%",
    },
    "& .ant-tabs-tab, & .ant-tabs-tab .ant-tabs-tab-btn": {
      flex: 1,
      textAlign: "center",
    },
  },
  currentPlanContent: {
    padding: [20, 50],
  },
  currentPlanContentTitle: {
    marginBottom: 10,
    fontSize: 28,
    display: "block",
    color: colors.dark400,
  },
  customBtn: {
    marginTop: 20,
    padding: [5, 20],
    fontSize: 10,
    borderRadius: 5,
  },
  plansCardCta: {
    marginTop: 10,
    padding: [5, 20],
    fontSize: 10,
    borderRadius: 5,
  },

  colorSeparator: {
    marginRight: 5,
    marginTop: 12,
  },

  plansCardsWrapper: {
    padding: [5, 20, 50],
    width: "100%",
    height: "calc(100vh - 200px)",
    overflowY: "auto",
  },
  plansCard: {
    height: 240,
    padding: [30, 20, 20],
    position: "relative",
    background: colors.light100,
    filter: "drop-shadow(4px 4px 5px rgba(0, 0, 0, 0.15))",
    borderRadius: 10,

    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  dotsVector: {
    position: "absolute",
    top: 10,
    left: 0,
  },
  dotsVector2: {
    position: "absolute",
    right: 10,
    bottom: 0,
  },
  planCardVector: {
    position: "absolute",
    right: 10,
    bottom: 0,
  },
  plansCardTitle: {
    fontSize: 20,
    display: "block",
    fontWeight: 700,
    lineHeight: "18px",
  },
  plansCardDescription: {
    fontSize: 10,
  },
  basicPlanContent: {
    display: "block",
    fontSize: 14,

    "& strong": {
      fontSize: 16,
      color: colors.primary,
    },
  },
  plansCardPrice: {
    display: "block",
    "& strong": {
      margin: [0, 5],
      fontSize: 20,
    },
  },
  planBenefitItem: {
    width: "25%",
    paddingRight: 10,

    "& img": {
      width: 20,
      height: 20,
      marginBottom: 5,
    },
    "& .ant-typography": {
      display: "block",
      fontSize: 8,
    },
  },
  andMoreText: {
    display: "block",
    marginTop: 20,
    fontSize: 12,
    whiteSpace: "nowrap",
    color: colors.dark400,
  },

  plansCardDetailsItem: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    "& > .ant-typography": {
      marginTop: 15,
    },
    "& > .ant-typography:first-child": {
      width: 70,
      fontWeight: 500,
    },
  },

  historyCardsWrapper: {
    padding: 20,
  },
  historyCard: {
    padding: 10,
    marginBottom: 15,
    border: "0.3px solid #D9D9D9",
    boxShadow:
      "-1px -1px 2px rgba(0, 0, 0, 0.1), 1px 1px 2px rgba(0, 0, 0, 0.1)",
    "& .ant-card-body": {
      paddingTop: 0,
    },
  },
  historyCardPlan: {
    fontWeight: 600,
    color: colors.dark400,
  },
  currentPlanDetailsItem: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    "& > .ant-typography": {
      marginTop: 15,
    },
    "& > .ant-typography:first-child": {
      width: 110,
    },
  },
  historyCardDetailsItem: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    "& > .ant-typography": {
      marginTop: 15,
    },
    "& > .ant-typography:first-child": {
      width: 110,
      fontWeight: 500,
    },
  },
  cancelModal: {
    "& .ant-modal-confirm-content": {
      padding: [20, 0, 0],
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
    },
    "& .ant-modal-confirm-btns": {
      display: "none",
    },
    "& img": {
      width: 240,
    },
  },
  cancelModalTitle: {
    fontSize: 16,
    margin: [20, 0, 5],
  },
}));
