import React, { useState, useEffect } from "react";
import {
  PlusOutlined,
  HomeFilled,
  PhoneFilled,
  StarFilled,
  PercentageOutlined,
  CreditCardFilled,
  UnorderedListOutlined,
} from "@ant-design/icons";
import Button from "antd/es/button";
import TextArea from "antd/lib/input/TextArea";
import DetailListItem from "components/random/DetailListItem";
import { postNotes } from "api/retailerApi";
import { useStyles } from "./CustomerDetails.style";
import { getCustomerById } from "./../APIs/getCustomerDetails";
import { Input } from "antd";

type CustomerDetailsProps = {
  customerId: number;
  customerName: string;
  isPriority: boolean;
  retailStoreId: number;
  fullHeight?: boolean;
  toggleCustomerPriority?: any;
};

function CustomerDetails({
  customerId,
  customerName,
  isPriority,
  retailStoreId,
  fullHeight,
  toggleCustomerPriority,
}: CustomerDetailsProps) {
  const [customer, setCustomer] = useState({
    details: [{}],
    moreDetailItems: [{}],
    showLess: false,
    notes: "",
  });
  const classes = useStyles({ isPriority });

  const toggleShowLess = () => {
    setCustomer({
      ...customer,
      showLess: !customer.showLess,
    });
  };

  const onClick = async () => {
    let data = {
      customer_id: customerId,
      retail_store_id: retailStoreId,
      message: `'${customer.notes}'`,
    };
    var res = await postNotes(data);
    setCustomer({
      ...customer,
      notes: res[0].message,
    });
  };

  function getMoreItems(userId: any) {
    return [
      {
        label: "Priority",
        icon: <StarFilled />,
        iconBackground: "#C5E5E0",
        suffix: (
          <StarFilled
            className={classes.priorityStar}
            id="starfill"
            onClick={async () => await toggleCustomerPriority(userId)}
          />
        ),
      },
      {
        label: "Average Discount",
        icon: <PercentageOutlined />,
        iconBackground: "#DBD4F4",
        suffix: `0 %`,
      },
      {
        label: "Credit history",
        icon: <CreditCardFilled />,
        iconBackground: "#F5C9C9",
        suffix: (
          <Input
            defaultValue="₹ 0.00"
            style={{ padding: 4, width: 70, textAlign: "right" }}
          />
        ),
      },
      {
        label: "Total orders",
        icon: <UnorderedListOutlined />,
        iconBackground: "#CEECFD",
        suffix: `${1}`,
      },
    ];
  }

  function getDetailItems(phoneNumber: string, fullAddress: string) {
    return [
      {
        label: "Phone Number",
        icon: <PhoneFilled rotate={90} />,
        iconBackground: "#F5C9C9",
        content: phoneNumber,
      },
      {
        label: "Address",
        icon: <HomeFilled />,
        iconBackground: "#CEECFD",
        content: fullAddress,
      },
    ];
  }

  async function fetchCustomerDetails() {
    var customerData = await getCustomerById(customerId);
    if (customerData?.phone_no) {
      setCustomer({
        details: getDetailItems(
          customerData.phone_no,
          (customerData.line1 ? customerData.line1 + ", " : "") +
            (customerData.line2 ? customerData.line2 + ", " : "") +
            (customerData.landmark ? customerData.landmark + ", " : "") +
            (customerData.city ? customerData.city + ", " : "") +
            (customerData.state
              ? customerData.state + ", "
              : customerData.state) +
            customerData.pincode
        ),
        moreDetailItems: getMoreItems(customerData.user_id),
        showLess: fullHeight ? true : false,
        notes: "",
      });
    }
  }

  useEffect(() => {
    if (customerId > 0) {
      fetchCustomerDetails();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);

  return (
    <div
      className={classes.customerDetailsCard}
      style={fullHeight ? { height: "100%" } : {}}
    >
      {customer.details ? (
        <div className={classes.customerDetailsCardInner}>
          <div className={classes.header}>
            <div className={classes.cardTitle}>Customer details</div>
            {!fullHeight && (
              <div className={classes.showLessMoreBtn} onClick={toggleShowLess}>
                {customer.showLess ? "Show less" : "Show More"}
              </div>
            )}
          </div>
          <div className={classes.basicDetailsList}>
            {customer.details?.map((item: any, index: any) => (
              <DetailListItem key={index} {...item} />
            ))}
          </div>

          <div
            className={
              classes.moreDetailsContainer +
              (customer.showLess ? "" : " " + classes.hidden)
            }
          >
            <hr className={classes.divider} />
            <div className={classes.moreDetailsList}>
              {customer.moreDetailItems?.map((item: any, index: any) => (
                <DetailListItem key={index} {...item} />
              ))}
            </div>
            <hr className={classes.divider} />
          </div>
          <div className={classes.notesInputContainer}>
            <p>Notes</p>
            <TextArea
              className={classes.notesInputField}
              autoSize={{ minRows: 5, maxRows: 5 }}
              placeholder="Type something..."
              value={customer.notes}
              onChange={(event) =>
                setCustomer({
                  ...customer,
                  notes: event.target.value,
                })
              }
            />
            <Button
              type="primary"
              shape="circle"
              className={classes.addToNotesButton}
              onClick={onClick}
              disabled={customer.notes === "" ? true : false}
              icon={<PlusOutlined />}
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default CustomerDetails;
