import Button from "antd/es/button";
import Input from "antd/es/input";
import Select from "antd/es/select";
import React, { useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { createUseStyles } from "react-jss";
import Col from "antd/es/col";
import Field from "components/field/Field";
import FormGroup from "components/FormGroup";
import Row from "antd/es/row";
import UploadInput from "components/inputs/UploadInput";
import useForm from "hooks/useForm";
import type { FormProps } from "hooks/useForm";
import { CloseOutlined, EnvironmentFilled } from "@ant-design/icons";
import Tooltip from "antd/es/tooltip";
import Checkbox from "antd/es/checkbox";
import { Preview } from "components/Preview";
import customeScrollbarStyle from "components/style/CustomScrollbar";
import hideNumberInputArrows from "components/style/hideNumberInputArrows";
import LocationPickerModal from "components/modals/LocationPickerModal";
import { getCities, getStates } from "api/api";
import { AutoComplete, message } from "antd/es";
import FullScreenModal from "components/modals/FullScreenModal";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import RetailStore from "models/RetailStore";
import { Radio } from "antd";

type RetailStoreFormProps = FormProps<any> & {
  title?: string;
  showPreview: boolean;
  setShowPreview?: any;
};

const DEFAULT_TITLE = "Business set up";

function RetailStoreForm({
  defaultValues,
  title = DEFAULT_TITLE,
  showPreview,
  setShowPreview,
  ...rest
}: RetailStoreFormProps) {
  const classes = useStyles();
  const isLG = useBreakpoint().lg;
  const isMD = useBreakpoint().md;
  const [locationPickerVisble, setLocationPickerVisble] = useState(false);
  const [pageData, setPageData] = useState<any>({
    statesOptions: [],
    citiesOptions: [],
    statesArray: [],
    citiesArray: [],
    stateValue: "",
  });

  const { isSubmitting, methods, submitHandler, reset } = useForm<any>({
    defaultValues,
    resolver: RetailStore.validateCreate(),
    ...rest,
  });
  const togglePreview = () => {
    if (
      (!methods.watch().name || methods.watch().name === "") &&
      !showPreview
    ) {
      message.info("Please enter Business Name.");
      return;
    }
    setShowPreview(!showPreview);
  };

  async function getIndianStates() {
    let statesList = await getStates("India");
    setPageData({
      ...pageData,
      statesOptions: statesList,
      statesArray: statesList.map((item: any) => item.value),
    });
  }

  async function getCitiesList(state: string) {
    let s = pageData.statesOptions.map((item: any) => item.value.toLowerCase());
    if (state && s.includes(state.toLowerCase())) {
      let citiesList = await getCities(state);
      setPageData({
        ...pageData,
        citiesOptions: citiesList,
        citiesArray: citiesList.map((item: any) => item.value),
        stateValue: state,
      });
    }
  }

  useEffect(() => {
    if (!pageData.statesOptions.length) {
      getIndianStates();
    }
  }, []);

  useEffect(() => {
    let state = methods.watch().state;
    if (state !== pageData.stateValue) {
      getCitiesList(state);
    }
  }, [methods.watch().state]);

  const onLocationChange = (data: any) => {
    reset({
      ...defaultValues,
      map_url: data.mapUrl,
      address: data.area,
      city: data.city,
      state: data.state,
      pincode: data.pincode,
    });
  };

  return (
    <Row>
      {showPreview && (
        <>
          {isLG ? (
            <Col lg={12} className={classes.previewContainer}>
              <div className={classes.previewHeader}>
                <CloseOutlined onClick={togglePreview} />
                <div className={classes.previewTitle}>
                  Your raseet will look like this
                </div>
              </div>
              <div className={classes.previewBox}>
                <Preview retailStore={methods.watch()} />
              </div>
            </Col>
          ) : (
            <FullScreenModal
              modalOpen={showPreview}
              disabledFullScreen={isMD}
              onClose={togglePreview}
            >
              <div className={classes.previewBox}>
                <Preview retailStore={methods.watch()} />
                <Button
                  shape="circle"
                  hidden={isMD}
                  type="primary"
                  icon={<CloseOutlined />}
                  onClick={togglePreview}
                  className={classes.modalCloseBtn}
                />
              </div>
            </FullScreenModal>
          )}
        </>
      )}
      <Col
        xs={24}
        lg={showPreview ? 12 : 24}
        className={classes.retailStoreForm}
      >
        <div className={classes.pageHeader}>
          <div className={classes.pageHeaderLeft}>
            <div className={classes.pageTitle}>Business set up</div>
          </div>
          <Tooltip
            title={isLG ? "Let's see 👀 how'll your raseet look.!" : false}
            className={classes.previewButton}
          >
            <Button onClick={togglePreview}>Preview</Button>
          </Tooltip>
        </div>
        <FormProvider {...methods}>
          <form
            className={classes.formInputs}
            onSubmit={submitHandler}
            spellCheck="false"
            autoComplete="off"
          >
            {defaultValues?.id && <Field as={Input} name="id" type="hidden" />}
            <Field
              as={Input}
              name="name"
              placeholder="Business name"
              label="Business name"
            />

            <Row className={classes.numberWrapper} gutter={{ md: 10, lg: 20 }}>
              <Col xs={24} md={12}>
                <div>
                  <Field
                    as={Input}
                    disabled
                    name="primaryMobileNumber"
                    type="number"
                    placeholder="Registered  mobile number *"
                    label="Registered mobile number *"
                  />
                  <Field as={Checkbox} name="whatsapp_check1">
                    Whatsapp number
                  </Field>
                </div>
              </Col>
              <Col xs={24} md={12}>
                <div>
                  <Field
                    as={Input}
                    name="alternateNumber"
                    type="number"
                    placeholder="Alternate number"
                    label="Alternate number"
                  />
                  <Field as={Checkbox} name="whatsapp_check2">
                    Whatsapp number
                  </Field>
                </div>
              </Col>
            </Row>

            <Field
              as={Input}
              name="map_url"
              placeholder="Maps Url"
              label="Maps Url"
              suffix={
                <Button
                  type="text"
                  size="small"
                  onClick={() => setLocationPickerVisble(true)}
                  style={{ fontWeight: 600 }}
                >
                  Open Map <EnvironmentFilled />
                </Button>
              }
            />
            <LocationPickerModal
              visible={locationPickerVisble}
              onCancel={() => setLocationPickerVisble(false)}
              onDone={onLocationChange}
            />

            <Field
              as={Input}
              name="address"
              placeholder="Business address"
              label="Business address"
            />

            <Row gutter={{ md: 10, lg: 20 }}>
              <Col xs={24} md={12}>
                <Field
                  as={AutoComplete}
                  name="state"
                  placeholder="State"
                  label="State"
                  backfill
                  options={pageData.statesOptions}
                  aria-autoComplete="off"
                  filterOption={(inputValue: any, option: any) => {
                    if (!pageData.statesArray.includes(inputValue)) {
                      return (
                        option!.value
                          .toUpperCase()
                          .indexOf(inputValue.toUpperCase()) !== -1
                      );
                    } else {
                      return true;
                    }
                  }}
                />
              </Col>
              <Col xs={24} md={12}>
                <Field
                  as={AutoComplete}
                  name="city"
                  placeholder="City"
                  label="City"
                  backfill
                  options={pageData.citiesOptions}
                  filterOption={(inputValue: any, option: any) => {
                    if (!pageData.citiesArray.includes(inputValue)) {
                      return (
                        option!.value
                          .toUpperCase()
                          .indexOf(inputValue.toUpperCase()) !== -1
                      );
                    } else {
                      return true;
                    }
                  }}
                  aria-autoComplete="off"
                />
              </Col>
            </Row>

            <Row gutter={{ md: 10, lg: 20 }}>
              <Col xs={24} md={12}>
                <Field
                  as={Input}
                  name="locality"
                  placeholder="Locality"
                  label="Locality"
                />
              </Col>
              <Col xs={24} md={12}>
                <Field
                  as={Input}
                  name="pincode"
                  type="number"
                  placeholder="Pincode"
                  label="Pincode"
                />
              </Col>
            </Row>
            <Row className={classes.uploadRow}>
              {methods.watch().logo !== undefined && (
                <Col xs={10} lg={7} style={{ marginTop: 10 }}>
                  <img
                    src={URL.createObjectURL(methods.watch().logo)}
                    width="100%"
                    height="80"
                    style={{ objectFit: "contain" }}
                  />
                </Col>
              )}
              <Col xs={24} lg={16} style={{ paddingLeft: 0 }}>
                {/* @ts-ignore */}
                <Field
                  as={UploadInput}
                  name="logo"
                  label="Upload your business logo"
                />
                <p>File formats: PNG, SVG, JPEG (not more than 2 MB)</p>
              </Col>
            </Row>

            <Row className={classes.uploadRow}>
              {methods.watch().sample_invoice !== undefined && (
                <Col xs={10} lg={7} style={{ marginTop: 10 }}>
                  <img
                    src={URL.createObjectURL(methods.watch().sample_invoice)}
                    width="100%"
                    height="80"
                    style={{ objectFit: "contain" }}
                  />
                </Col>
              )}
              <Col xs={24} lg={16} style={{ paddingLeft: 0 }}>
                {/* @ts-ignore */}
                <Field
                  as={UploadInput}
                  name="sample_invoice"
                  label="Upload a sample invoice"
                />
                <p>File formats: PNG, SVG, JPEG (not more than 2 MB)</p>
              </Col>
            </Row>

            <Field
              as={Select}
              name="operating_system"
              placeholder="Operating system"
              label="Operating system"
              options={[
                { label: "Windows-10", value: "Windows-10" },
                { label: "Windows-7 32 Bit", value: "Windows-7 32 Bit" },
                { label: "Windows-7 64 Bit", value: "Windows-7 64 Bit" },
                { label: "Windows-10 Server", value: "Windows-10 Server" },
                { label: "Windows-8", value: "Windows-8" },
              ]}
            />

            <Field
              as={Select}
              name="name_of_printer"
              placeholder="Name of printer"
              label="Name of printer"
              options={[
                { label: "Laser Printer", value: "Laser Printer" },
                { label: "Dot Matrix Printer", value: "Dot Matrix Printer" },
                { label: "Thermal Printer", value: "Thermal Printer" },
                { label: "Inkjet Printer", value: "Inkjet Printer" },
              ]}
            />

            <Field
              as={Input}
              name="reference_code"
              placeholder="Reference code"
              label="Reference code"
            />

            <div style={{ marginBottom: -7 }}>
              <Field
                as={({ onChange, value }) => (
                  <Radio.Group
                    value={value}
                    defaultValue="GSTIN"
                    onChange={(e) => onChange(e.target.value)}
                  >
                    <Radio value="GSTIN">GSTIN</Radio>
                    <Radio value="DL No">DL No.</Radio>
                    <Radio value="SR No">SR No.</Radio>
                    <Radio value="PAN">PAN</Radio>
                  </Radio.Group>
                )}
                name="authenticity_document_type"
              />
            </div>
            <Field as={Input} name="authenticity_document_value" label="" />

            <Button
              type="primary"
              htmlType="submit"
              block
              loading={isSubmitting}
              className={classes.submitButton}
            >
              Submit
            </Button>
          </form>
        </FormProvider>
      </Col>
    </Row>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  retailStoreForm: {
    padding: "1rem 1.8rem !important",
    ...hideNumberInputArrows,
  },
  previewContainer: {
    width: "100%",
    height: "100vh",
    padding: "1rem 2rem 2rem",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",

    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  previewHeader: {
    width: "100%",
    padding: "2.5rem 3.5rem 3.5rem",
    height: "60px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",

    "& .anticon": {
      fontSize: 20,
    },
  },
  previewTitle: {
    flex: 1,
    fontSize: 18,
    fontWeight: 600,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  pageHeader: {
    width: "100%",
    height: "100px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  pageHeaderLeft: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  pageTitle: {
    fontWeight: 600,
    paddingLeft: "2.6rem",
    fontSize: 18,
  },
  previewBox: {
    width: 350,
    height: 550,
    minHeight: 550,
    boxShadow: "0px 2px 8px rgb(0 0 0 / 25%)",
    "& .ant-layout-footer": {
      position: "static !important",
      boxShadow: "inherit !important",
    },
  },
  previewButton: {
    width: "9rem",
    margin: "0 1.5rem",
    fontWeight: 600,
    borderRadius: "0.3rem",
    color: "#E76F51",
    border: "1px solid #E76F51",
  },
  formInputs: {
    padding: "0 3rem 2.5rem 3rem",
    overflowY: "auto",
    height: "calc(100vh - 135px)",

    "& *": {
      fontSize: "12px",
      whiteSpace: "nowrap",
    },
    "& > div": {
      marginBottom: "1.5rem",
    },
    "& .ant-form-item": {
      marginBottom: "0.5rem",
    },

    // customizing the scrollbar
    ...customeScrollbarStyle,
  },
  submitButton: {
    marginBottom: "1rem",
    fontWeight: 600,
    color: colors.light100,
    background: colors.primary200,
  },
  skipThisStepButton: {
    color: colors.dark400,
    fontWeight: 600,
  },
  numberWrapper: {},
  modalCloseBtn: {
    position: "fixed",
    top: 15,
    right: 20,
  },

  "@media (max-width: 450px)": {
    previewBox: {
      width: "100vw",
      height: "100vh",
      minHeight: "100vh",
      "& .ant-layout-footer": {
        position: "fixed !important",
      },
    },
  },
  "@media (max-width: 768px)": {
    pageTitle: {
      paddingLeft: 0,
      whiteSpace: "nowrap",
    },
    previewButton: {
      width: "7rem",
      margin: 0,
      marginRight: 5,
    },
    pageHeader: {
      height: "70px",
      paddingBottom: 10,
    },
    previewBox: {
      "& .shopHeader": {
        paddingTop: "0.7rem !important",
        paddingBottom: "0.5rem !important",
      },
    },
    formInputs: {
      padding: "0 0rem 2.5rem 0rem",

      "& .ant-checkbox": {
        transform: "scale(0.85) translateY(10%)",
      },
      "& .ant-form-item": {
        marginBottom: "0.7rem",
      },
      "& > div": {
        marginBottom: "auto",
      },
      "& .ant-form-item-control-input-content > .ant-btn, & .ant-btn-primary": {
        marginTop: 15,
      },
      "& .ant-typography": {
        display: "none !important",
      },
    },
    numberWrapper: {
      "& > .ant-col": {
        marginBottom: 10,

        "& .ant-form-item:last-child": {
          marginBottom: 0,
        },
      },
    },
    uploadRow: {
      "& .ant-typography": {
        display: "flex !important",
        marginTop: 10,
        marginBottom: "-5px !important",
      },
    },
  },
  uploadRow: {},
}));

export default RetailStoreForm;
