import {
  ArrowLeftOutlined,
  SearchOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import DummyImage from "assets/svgs/medicine-bottle.svg";
import {
  Badge,
  Button,
  Carousel,
  Input,
  message,
  Table,
  Typography,
} from "antd";
import NumberSelect from "components/dataEntry/NumberSelect";
import { Link, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import {
  getProductDetails,
  getCustomerCart,
  insertCart,
  getCartItems,
  insertCartItems,
  updateCartItemsByProductId,
} from "api/api";
import Loader from "components/loader/Loader";
import { getSession, Session } from "client/reactives/session";
import gtag from "components/Tracking/gtag";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const getPriceWithoutGst = (gstPercentage: number, priceWithGst: number) => {
  let gstAmount = priceWithGst - priceWithGst * (100 / (100 + gstPercentage));
  return priceWithGst - gstAmount;
};

const getPImgUrl = (key: string, pid: any) =>
  `https://raseet-media.s3.ap-south-1.amazonaws.com/catalogueImages/product/${pid}/${key}`;

export default function ProductDetails() {
  const classes = useStyles();
  const query = useQuery();
  const history = useHistory();
  const {
    params: { pid, retailStoreId },
  } = useRouteMatch<any>() || {};
  const { userId } = getSession() as Session;

  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState<any>({});
  const [selectedQty, setSelectedQty] = useState(1);
  const [cartData, setCartData] = useState({
    id: 0,
    cartItemsCount: 0,
  });

  const getCartId = async () => {
    let cartId = 0;
    let cartRes = await getCustomerCart(retailStoreId);

    if (cartRes.length) {
      cartId = cartRes[0].id;
    } else {
      let cartData = {
        retail_store_id: retailStoreId,
        payment_status: false,
        discount: 0,
        gst: 0,
        price_with_gst: 0,
        price_with_out_gst: 0,
        user_id: userId,
      };
      // Create the cart
      const insertCartRes = await insertCart(cartData);
      if (insertCartRes?.length) {
        cartId = insertCartRes[0].id;
      }
    }

    return cartId;
  };

  const handleAddToCart = async (qty?: number) => {
    let cartId = await getCartId();
    let quantity = qty ?? selectedQty;

    if (!!cartId) {
      let cartItems = await getCartItems(cartId);
      let productPrice = product?.strike_price ?? product.pack_price;

      if (product?.cart_item_id !== "0") {
        product.sgst = product?.sgst ?? 0;
        product.cgst = product?.cgst ?? 0;
        product.discount = product?.sgst ?? 0;

        let sub_total = getPriceWithoutGst(
          +product.sgst + +product.cgst,
          +productPrice * +quantity
        );

        await updateCartItemsByProductId(
          +cartId,
          +product.product_id ?? +product.id,
          {
            quantity: quantity,
            total: (+productPrice * +quantity).toFixed(2),
            sub_total: sub_total.toFixed(2),
          }
        );
        message.warning("Qunatity has been updated");
      } else {
        product.sgst = product?.sgst ?? 0;
        product.cgst = product?.cgst ?? 0;
        product.discount = product?.sgst ?? 0;

        let sub_total = getPriceWithoutGst(
          +product.sgst + +product.cgst,
          +productPrice * +quantity
        );
        let newCartItem = {
          product_section: query.get("source") ?? "my_catalogue",
          product_id: product?.product_id ?? product.id,
          quantity: quantity,
          cart_id: cartId ?? 0,
          cgst: product.cgst,
          sgst: product.sgst,
          discount: product.discount,
          total: (+productPrice * +quantity).toFixed(2),
          sub_total: sub_total.toFixed(2),
        };

        await insertCartItems(newCartItem).then((res) => {
          if (res && res.id) {
            message.success("Items added to cart");

            gtag("event", "cart_item_added", {
              source: "shop",
              user: userId,
              store: retailStoreId,
              product: product.name || "notset",
            });
          }
        });
      }

      await fetchInitialData();
    }
  };

  const fetchInitialData = async () => {
    let cartId = await getCartId();
    let cartItems = await getCartItems(cartId);

    let productRes = await getProductDetails(
      pid,
      retailStoreId,
      query.get("source") ?? "my_catalogue"
    );

    if (productRes.length) {
      let productImages = [];

      if (!!productRes[0].images?.primary) {
        productImages.push(
          getPImgUrl(
            productRes[0].images.primary,
            productRes[0]?.product_id ?? productRes[0]?.id
          )
        );

        if (productRes[0].images?.secondary?.length) {
          for (let i = 0; i < productRes[0].images.secondary.length; i++) {
            const imgKey = productRes[0].images.secondary;
            productImages.push(
              getPImgUrl(imgKey, productRes[0]?.product_id ?? productRes[0]?.id)
            );
          }
        }
      }

      productRes[0].imagesArr = productImages;
      setProduct(productRes[0]);
    }
    setCartData({ id: cartId, cartItemsCount: cartItems?.length ?? 0 });
    setLoading(false);
  };

  const handleBack = () =>
    !!query.get("source")
      ? history.goBack()
      : history.replace(`/dashboard/stores/${retailStoreId}/shop`);

  useEffect(() => {
    if (loading) {
      fetchInitialData();
    }
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={classes.productPage}>
      <div className={classes.pageHeader}>
        <ArrowLeftOutlined onClick={handleBack} />
        <Typography.Text>{product.name}</Typography.Text>
        <div style={{ flex: 1 }} />
        <Badge size="small" count={cartData.cartItemsCount}>
          <Link
            to={"/dashboard/stores/" + retailStoreId + "/cart/" + cartData.id}
            id="view-cart-button"
            data-store={`store - ${retailStoreId}`}
            data-source="Header Cart Button"
            data-user={userId}
          >
            <ShoppingCartOutlined style={{ fontSize: 21.5 }} />
          </Link>
        </Badge>

        {/* <Input
          suffix={<SearchOutlined />}
          placeholder="Search anything in the catalogue..."
          className={classes.pageHeaderInput}
        /> */}
      </div>

      {product.imagesArr.length ? (
        <Carousel autoplay className={classes.productImgWrapper}>
          {product.imagesArr.map((item: any, index: number) => (
            <img src={item} key={index} className={classes.productImg} />
          ))}
        </Carousel>
      ) : (
        <Carousel autoplay className={classes.productImgWrapper}>
          <img src={DummyImage} className={classes.productImg} />
          <img src={DummyImage} className={classes.productImg} />
          <img src={DummyImage} className={classes.productImg} />
        </Carousel>
      )}
      <div className={classes.productDetailsWrapper}>
        <div style={{ position: "relative", marginBottom: 30 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <div className={classes.productTitle}>{product.name}</div>
              {/* <div>325 mg</div> */}
              <div hidden={!!!product.product_domain}>
                {product.product_domain}
              </div>
              <br hidden={!!!product.product_domain} />
              <div hidden={!!!product.manufacturer}>
                Mfd. by: {product.manufacturer}{" "}
              </div>
            </div>

            <NumberSelect
              till={100}
              value={selectedQty}
              style={{
                transform: "scale(1.4)",
              }}
              onChange={(val: any) => {
                setSelectedQty(val);
                handleAddToCart(val);
              }}
            />
          </div>
        </div>

        <div className={classes.productDetailsItem}>
          <div className={classes.productDetailsTitle}>Description</div>
          <div className={classes.productDetailsContent}>
            {product.description ?? "No description available."}
          </div>
        </div>

        <div
          hidden={!!!product.dosage_form}
          className={classes.productDetailsItem}
        >
          <div className={classes.productDetailsTitle}>How To Use</div>
          <div className={classes.productDetailsContent}>
            {product.dosage_form}
          </div>
        </div>

        <div
          hidden={!!!product.when_to_use}
          className={classes.productDetailsItem}
        >
          <div className={classes.productDetailsTitle}>When To Use</div>
          <div className={classes.productDetailsContent}>
            {product.when_to_use}
          </div>
        </div>

        <div
          hidden={!!!product.benefits}
          className={classes.productDetailsItem}
        >
          <div className={classes.productDetailsTitle}>Benefits</div>
          <div className={classes.productDetailsContent}>
            {product.benefits}
          </div>
        </div>

        <div
          hidden={!!!product.side_effects}
          className={classes.productDetailsItem}
        >
          <div className={classes.productDetailsTitle}>Side Effects</div>
          <div className={classes.productDetailsContent}>
            {product.side_effects}
          </div>
        </div>

        <div
          hidden={!!!product.prescription || product.prescription === "OTC"}
          className={classes.productDetailsItem}
        >
          <div className={classes.productDetailsTitle}>Prescription</div>
          <div className={classes.productDetailsContent}>
            Prescription required.
          </div>
        </div>
      </div>

      <div className={classes.pageFooter}>
        <div>
          <div className={classes.foooterPrice}>
            ₹
            {parseFloat(product?.strike_price ?? product.pack_price).toFixed(2)}
          </div>
          {!!product.strike_price &&
            +product.strike_price < +product.pack_price && (
              <div>
                <del>MRP ₹{(+product.pack_price).toFixed(2)}</del>
              </div>
            )}
        </div>

        <Button
          type="primary"
          size="middle"
          style={{ width: "40%" }}
          onClick={() => handleAddToCart()}
          disabled={product.cart_item_id !== "0"}
        >
          Add to cart
        </Button>
      </div>
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  productPage: {
    paddingTop: 80,
    paddingBottom: 100,
  },
  pageHeader: {
    width: "100%",
    height: 60,
    padding: [0, 30, 0, 20],
    background: "#fff",
    display: "flex",
    alignItems: "center",
    zIndex: 3,
    position: "fixed",
    top: 0,
    left: 0,

    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.07)",

    "& > .anticon": {
      paddingRight: 15,
      fontSize: 16,
    },
  },

  pageHeaderInput: {
    paddingLeft: 20,

    "& input": {
      fontSize: 12,
    },
    "& .anticon": {
      color: colors.light500,
    },
  },
  productImgWrapper: {
    width: "100%",
    padding: [10, 0],
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  productImg: {
    height: 240,
    objectFit: "contain",
  },
  productDetailsWrapper: {
    padding: 40,
    position: "relative",
  },
  productTitle: {
    fontSize: 20,
    fontWeight: 600,
    color: "#505050",
  },
  productDetailsItem: {
    margin: [30, 0],
  },
  productDetailsTitle: {
    marginBottom: 10,
    fontSize: 16,
    fontWeight: 500,
    color: "#505050",
  },
  productDetailsContent: {},
  pageFooter: {
    width: "100%",
    padding: [15],
    background: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    position: "fixed",
    bottom: 0,
    left: 0,

    boxShadow: "0px -4px 10px rgba(0, 0, 0, 0.07)",
  },
  foooterPrice: {
    fontSize: 16,
    fontWeight: 600,
    color: "#505050",
  },
}));
