import React from "react";
import { Radio } from "antd";
import { createUseStyles } from "react-jss";

interface RadioTogglerProps {
  onChange: (value: string) => void;
}

export default function RadioToggler({ onChange }: RadioTogglerProps) {
  const classes = useStyles();

  return (
    <div className={classes.radioTogglerWrapper}>
      <Radio.Group
        options={[
          { label: "Priority", value: "Priority" },
          { label: "All", value: "All" },
        ]}
        onChange={(e) => onChange(e.target.value)}
        defaultValue="All"
        optionType="button"
        buttonStyle="solid"
      />
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  radioTogglerWrapper: {
    height: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& .ant-radio-group": {
      overflow: "hidden",
      borderRadius: "0.5rem",
      boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)",
    },

    "& .ant-radio-button-wrapper": {
      width: 80,
      textAlign: "center",
      border: "none !important",
    },

    "& .ant-radio-button-wrapper::before": {
      display: "none",
    },

    "& .ant-radio-button-wrapper-checked": {
      borderRadius: "0.5rem",
      background: colors.primary200 + " !important",
      borderColor: colors.primary200 + " !important",
    },
  },
}));
