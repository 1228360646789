import Col from "antd/es/grid/col";
import {
  HomeFilled,
  DownCircleFilled,
  FileAddFilled,
  CrownFilled,
  EditOutlined,
  LogoutOutlined,
  ReadFilled,
  GoldFilled,
  CaretDownFilled,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Drawer,
  Dropdown,
  Menu,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { UserOutlined } from "@ant-design/icons";
import { getSession, Session } from "client/reactives/session";
import { Divider } from "antd/es";
import { createUseStyles } from "react-jss";
import {
  ActiveOrdersIcon,
  DownloadBillIcon,
  LoyaltyIcon,
} from "components/customIcons";
import { getRetailStores, getRetailStoresUsersMapping } from "api/api";
import constants from "constants/constants";

const getAcronym = (text: string) => {
  let acronym = text
    ?.split(/\s/)
    .reduce((response, word) => (response += word.slice(0, 1)), "");
  return acronym;
};

type RouteParams = {
  retailStoreId: string;
};

type DrawerSidebarProps = {
  hamburgerColor?: string;
  onStoreChange?: () => void;
};

function DrawerSidebar({
  onStoreChange,
  hamburgerColor = "rgb(42, 157, 143)",
}: DrawerSidebarProps) {
  const classes = useStyles();
  const history = useHistory();

  const {
    params: { retailStoreId },
  } = useRouteMatch<RouteParams>() || {};

  // -------------NEW STATES IN USE-----------------------------
  const { userName } = getSession() as Session;
  const [drawer, setDrawer] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [mappedStores, setMappedStores] = useState([]);
  const [selectedStoreId, setSelectedStoreId] = useState(0);
  // -----------------------------------------------------------

  const openDrawer = () => setDrawer(!drawer);

  const handleStoreChange = async (store: any) => {
    history.push(`/dashboard/stores/${store.retail_store_id}/home`);
    setDrawer(false);
    setSelectedStoreId(+store.retail_store_id);

    localStorage.setItem("defaultStore", store.retail_store_id);

    if (!!onStoreChange) {
      onStoreChange();
    }
  };

  const fetchStores = async (setStore?: boolean) => {
    let storesMappingRes = await getRetailStoresUsersMapping();

    setMappedStores(storesMappingRes);
    setLoading(false);
    // if (setStore)
    //   history.replace(`/dashboard/stores/${storesMappingRes[0].id}/home`);
  };

  useEffect(() => {
    if (loading) {
      fetchStores();
    }
  }, [loading]);

  useEffect(() => {
    if (+retailStoreId !== selectedStoreId) {
      setSelectedStoreId(+retailStoreId);
    }
  }, [retailStoreId]);

  const DrawerFooter = () => (
    <>
      <Divider className={classes.sidebarDivider} />
      <div className={classes.sidebarFooterOption}>
        <a href="https://raseet.com/#contact">Help Centre</a>
      </div>
      <div className={classes.sidebarFooterOption}>
        <Link to="/privacy-policy">
          <div>Privacy Policy</div>
        </Link>
      </div>
      <div className={classes.sidebarFooterOption}>
        <Link to="/terms-and-conditions">
          <div>Terms & Conditions</div>
        </Link>
      </div>
      <div className={classes.sidebarFooterOption}>
        <Link to="/about-us">
          <div>About Us</div>
        </Link>
      </div>
      <div className={classes.sidebarFooterOption}>
        <Link to="/return-policy">
          <div>Return Policy</div>
        </Link>
      </div>
      <div className={classes.sidebarFooterOption}>
        <Link to="/pricing-policy">
          <div>Pricing Policy</div>
        </Link>
      </div>
    </>
  );

  return (
    <div>
      <Col span={4} onClick={openDrawer} style={{ height: 19 }}>
        <svg
          viewBox="0 0 100 80"
          width="20"
          height="20"
          style={{ fill: hamburgerColor }}
        >
          <rect width="80" height="10"></rect>
          <rect y="30" width="80" height="10"></rect>
          <rect y="60" width="80" height="10"></rect>
        </svg>
      </Col>
      <Drawer
        title={
          <div className={classes.drawerTitle}>
            <Avatar size={30} icon={<UserOutlined />} />
            <span className={classes.sidebarHeaderTitle}>
              Hi, {userName?.toString()}
            </span>
          </div>
        }
        footer={<DrawerFooter />}
        width="70%"
        placement={"left"}
        closable={false}
        onClose={openDrawer}
        visible={drawer}
        key={"left"}
        className={classes.sidebar}
        headerStyle={{
          background: "#2A9D8F",
          minHeight: 80,
          display: "flex",
          alignItems: "center",
        }}
        bodyStyle={{ fontSize: 16 }}
      >
        {!loading && (
          <>
            <div className={classes.storesWrapper}>
              <Typography.Text
                strong
                type="secondary"
                className={classes.myStoresText}
              >
                My Stores
              </Typography.Text>

              {!!selectedStoreId && (
                <Dropdown
                  trigger={["click"]}
                  overlay={
                    <Menu>
                      {mappedStores.map((store: any, index) => (
                        <MenuItem
                          key={index}
                          store={store}
                          onChange={handleStoreChange}
                        />
                      ))}
                    </Menu>
                  }
                >
                  <div className={classes.selectedStoreWrapper}>
                    <MenuItem
                      noClick
                      store={mappedStores.find(
                        (i: any) => +i.retail_store_id === +selectedStoreId
                      )}
                    />
                    <Button type="text" icon={<CaretDownFilled />} />
                  </div>
                </Dropdown>
              )}
            </div>

            <Divider className={classes.sidebarDivider} />

            <div className={classes.sidebarOption}>
              <Link to="/dashboard/stores/">
                <HomeFilled /> Home
              </Link>
            </div>
            <div className={classes.sidebarOption}>
              <Link to="/dashboard/stores/more/active-orders">
                <ActiveOrdersIcon /> Active Orders
              </Link>
            </div>
            <div className={classes.sidebarOption}>
              <Link to="/dashboard/stores/download-bill-receipts">
                <DownloadBillIcon /> Download Bill Receipts
              </Link>
            </div>
            <div className={classes.sidebarOption}>
              <Link to="/dashboard/stores/prescriptions-and-reports">
                <FileAddFilled /> Prescriptions & Reports
              </Link>
            </div>
            <div className={classes.sidebarOption}>
              <Link to="/dashboard/stores/cashback-summary">
                <LoyaltyIcon /> Cashback Summary
              </Link>
            </div>
            <div className={classes.sidebarOption}>
              <Link to="/dashboard/stores/articles">
                <ReadFilled /> Articles
              </Link>
            </div>
            {/* <div className={classes.sidebarOption}>
              <Link to="/dashboard/plans">
                <GoldFilled /> Plans
              </Link>
            </div> */}
            <Divider className={classes.sidebarDivider} />
            <div className={classes.sidebarOption}>
              <Link to="/dashboard/profile">
                <EditOutlined /> Edit Profile
              </Link>
            </div>
            <div className={classes.sidebarOption}>
              <Link to="/logout">
                <LogoutOutlined /> Logout
              </Link>
            </div>
          </>
        )}
      </Drawer>
    </div>
  );
}

const MenuItem = ({ store, onChange, noClick = false }: any) => {
  const classes = useStyles();
  const {
    params: { retailStoreId },
  } = useRouteMatch<RouteParams>() || {};

  const [logoLoaded, setLogoLoaded] = useState(false);

  return (
    <div
      className={classes.storeMenuItem}
      style={{
        backgroundColor:
          store?.retail_store_id === retailStoreId && !noClick
            ? "#f7f7f7"
            : "#ffffff",
      }}
      onClick={() => !noClick && onChange(store)}
    >
      <img
        hidden={!logoLoaded}
        onLoad={() => setLogoLoaded(true)}
        src={constants.s3Url + store?.logo_data?.key}
      />
      {!logoLoaded && (
        <Avatar size="large" shape="square">
          {getAcronym(store?.name?.split(" ")[0])}
        </Avatar>
      )}

      <Typography.Text>{store?.name}</Typography.Text>
    </div>
  );
};

const useStyles = createUseStyles(({ colors }: Theme) => ({
  sidebar: {
    "& .ant-drawer-header": {
      borderRadius: "0",
      minHeight: "56px !important",
      padding: [10, 15],
    },
    "& .ant-drawer-body": {
      padding: 0,
    },
    "& .ant-drawer-footer": {
      padding: [0, 0, 10],
      borderTop: "none !important",
    },
  },
  drawerTitle: {
    color: "white",
    fontSize: 16,
    fontWeight: 700,
    fontFamily: "ROBOTO",

    "& .ant-avatar": { marginRight: 10 },
  },
  sidebarHeaderTitle: {
    fontWeight: 700,
    fontSize: 16,
  },
  sidebarOption: {
    margin: "20px 20px 0px",
    fontSize: 12,
    fontWeight: 300,
    lineHeight: "14px",

    "& *": {
      color: "#505050",
    },
    "& .anticon": {
      marginRight: 15,
      fontSize: 12,
    },
  },
  sidebarDivider: {
    margin: [20, 0],
    borderTop: "0.3px solid #5050507a",
  },
  sidebarFooterOption: {
    margin: "0px 21px 13px",
    fontSize: 10,
    fontWeight: 300,
    lineHeight: "12px",

    "& *": {
      color: "#505050",
    },
  },
  storesWrapper: {
    width: "100%",
    padding: [15, 10, 0],

    "& .ant-select": {
      width: "100%",
    },
  },
  selectedStoreWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  myStoresText: {
    display: "block",
    fontSize: 12,
    marginLeft: 5,
    marginBottom: 5,
  },
  storeMenuItem: {
    width: "100%",
    padding: [5, 10],
    cursor: "pointer",

    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",

    "& img, & .ant-avatar": {
      width: 25,
      height: 25,
      marginRight: 10,
      objectFit: "contain",

      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .ant-typography": {
      fontSize: 10,
    },
  },
}));

export default DrawerSidebar;
