import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import Loader from "components/loader/Loader";
import {
  ArrowLeftOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Input } from "antd";

type PreviewImageModalProps = {
  images: Array<string>;
  visible: boolean;
  onCancel: any;
  current: number;
};

function PreviewImageModal({
  images,
  visible,
  onCancel,
  current,
}: PreviewImageModalProps) {
  const classes = useStyles();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setCurrentIndex(current);
  }, [current]);

  const nextPres = () => {
    setLoading(true);
    if (currentIndex + 1 < images.length) {
      setCurrentIndex(currentIndex + 1);
    } else {
      setCurrentIndex(0);
    }
  };
  const prevPres = () => {
    setLoading(true);
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    } else {
      setCurrentIndex(images.length - 1);
    }
  };

  useEffect(() => {
    setCurrentIndex(current);
  }, [current]);

  if (!images.length) {
    return null;
  }

  return (
    <Modal
      visible={visible}
      footer={false}
      centered
      title={false}
      closable={false}
      className={classes.showimageModal}
      onCancel={() => {
        onCancel();
      }}
    >
      <img
        src={images[currentIndex]}
        hidden={loading}
        onLoad={() => setLoading(false)}
      />
      {loading && <Loader height="250px" />}
      <ArrowLeftOutlined
        className={classes.closeModalIcon}
        onClick={() => {
          onCancel();
        }}
      />
      <Input
        readOnly
        value={`${currentIndex + 1} / ${images.length}`}
        size="small"
        prefix={<LeftOutlined onClick={prevPres} />}
        suffix={<RightOutlined onClick={nextPres} />}
        className={classes.imagePagination}
      />
    </Modal>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  "@global": {
    ".ant-modal-mask": {
      background: "#333333 !important",
    },
  },
  showimageModal: {
    "& .ant-modal-content": {
      width: "fit-content",
      margin: "auto",
      background: "transparent",
      boxShadow: "none",
    },
    "& .ant-modal-body": {
      padding: "0px !important",
    },
    "& img": {
      maxHeight: "80vh",
      maxWidth: "90vw",
    },
  },
  closeModalIcon: {
    position: "fixed",
    color: "#fff",
    zIndex: 9,
    top: 25,
    left: 25,
    fontSize: 15,
  },
  deletePrescriptonButton: {
    position: "fixed",
    color: "#fff",
    zIndex: 9,
    top: 25,
    right: 25,
    fontSize: 15,
  },
  imagePagination: {
    width: 80,
    position: "fixed",
    left: "calc(50% - 40px)",
    bottom: 30,
    zIndex: 9,
    fontSize: 10,
    textAlign: "center",
    color: "#fff",
    background: "#000000",
    borderRadius: 10,
    border: "none",

    "& .ant-input": {
      color: "inherit",
      background: "inherit",
      fontSize: "inherit",
      textAlign: "inherit",
    },
  },
}));

export default PreviewImageModal;
