import React from "react";
import { createUseStyles } from "react-jss";

type LogoProps = {
  url?: string;
};

const useStyles = createUseStyles({
  logo: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    height: 40,
    width: 40,
    objectFit: "contain",
  },
});

function Logo({ url = "" }: LogoProps) {
  const classes = useStyles({ url });

  return (
    <div
      className={classes.logo}
      style={{ backgroundImage: `url(${url})`, borderRadius: "3px" }}
    />
  );
}

export default Logo;
