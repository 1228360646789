import React from 'react'
import type { RouteProps } from 'react-router-dom'

import ProtectedRoute, { SessionState, RestrictedSection, UserType } from 'components/routes/ProtectedRoute'
import CustomerLayout from 'components/layouts/CustomerLayout'

function CustomerRoute(props: RouteProps) {
  return (
    <ProtectedRoute
      layout={CustomerLayout}
      requiredSessionState={SessionState.LOGGED_IN}
      requiredRestrictedSection={RestrictedSection.CUSTOMER}
      requiredUserType={UserType.USER}
      {...props}
    />
  )
}

export default CustomerRoute
