import { Switch, Route, Redirect } from "react-router-dom";
import React from "react";
import "antd/dist/antd.less";
import NoAuthPage from "noAuth/pages/NoAuthPage";
import LoginPage from "components/pages/LoginPage";
import AuthRoute from "components/routes/AuthRoute";
import LogoutPage from "components/pages/LogoutPage";
import AdminRoute from "components/routes/AdminRoute";
import NotFoundPage from "components/pages/NotFoundPage";
import RegisterPage from "components/pages/RegisterPage";
import { useSessionQuery } from "client/reactives/session";
import ViewInvoicePage from "noAuth/pages/ViewInvoicePage";
import RetailerRoute from "components/routes/RetailerRoute";
import CustomerRoute from "components/routes/CustomerRoute";
import PublicBillPage from "components/pages/PublicBillPage";
import RetailerDashboard from "components/pages/retailerDashboard";
import RetailerLoginPage from "components/pages/RetailerLoginPage";
import CustomerDashboard from "components/pages/customerDashboard";
import ForgotPasswordPage from "components/pages/ForgotPasswordPage";
import AdminDashboardPages from "components/pages/adminDashboardPages";
import AboutUs from "./components/pages/customerDashboard/InfoPages/AboutUs";
import ReturnPolicy from "./components/pages/customerDashboard/InfoPages/ReturnPolicy";
import PrivacyPolicy from "./components/pages/customerDashboard/InfoPages/PrivacyPolicy";
import PricingPolicy from "./components/pages/customerDashboard/InfoPages/PricingPolicy";
import TermsAndCondtions from "./components/pages/customerDashboard/InfoPages/TermsAndCondtions";
import { onMessageListener } from "utils/firebase";
import Notifications from "components/Notifications";
import { notification } from "antd";
import firebase from "firebase/app";
import localforage from "localforage";

function App() {
  const { data: { session } = {} } = useSessionQuery();

  if (firebase.messaging.isSupported()) {
    onMessageListener()
      .then((payload) => {
        notification.open({
          message: payload.notification.title,
          description: payload.notification.body,
        });
        console.log(payload);
      })
      .catch((err) => console.log("failed: ", err));
  }

  return (
    <>
      <Notifications />
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/login" />} />
        <Route
          path="/terms-and-conditions"
          exact
          render={() => <TermsAndCondtions />}
        />
        <Route path="/privacy-policy" exact render={() => <PrivacyPolicy />} />
        <Route path="/about-us" exact render={() => <AboutUs />} />
        <Route path="/return-policy" exact render={() => <ReturnPolicy />} />
        <Route path="/pricing-policy" exact render={() => <PricingPolicy />} />
        <AuthRoute path="/login" component={LoginPage} />
        <AuthRoute path="/register" component={RegisterPage} />
        <AuthRoute path="/forgot-password" component={ForgotPasswordPage} />
        <AuthRoute
          path="/retail/register"
          render={() => <RegisterPage isRetailer />}
        />
        <AuthRoute path="/retail/login" render={() => <RetailerLoginPage />} />

        <CustomerRoute
          path={["/dashboard", "/customer"]}
          render={CustomerDashboard}
        />

        <RetailerRoute
          exact
          path="/retailer"
          render={() => <Redirect to="/retailer/dashboard" />}
        />
        <RetailerRoute
          path="/retailer/dashboard"
          component={RetailerDashboard}
        />

        <AuthRoute path="/admin/login" render={() => <LoginPage isAdmin />} />
        <AdminRoute
          exact
          path="/admin"
          render={() => <Redirect to="/admin/dashboard" />}
        />
        <AdminRoute path="/admin/dashboard" component={AdminDashboardPages} />

        {/* <Route path="/order/:friendlyId" component={PublicBillPage} /> */}

        <Route path="/logout" component={LogoutPage} />

        <Route path={"/orderPlaced/:uid"} component={NoAuthPage} />

        <Route path={"/viewInvoice/:uid"} component={ViewInvoicePage} />

        <Route component={NotFoundPage} />
      </Switch>
    </>
  );
}

export default App;
