import React from "react";
import Button from "antd/es/button";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

type ComingSoonViewProps = {
  image?: string;
};

function ComingSoonView({ image }: ComingSoonViewProps) {
  const { goBack } = useHistory();
  const classes = useStyles();

  return (
    <div className={classes.resultStyle}>
      <img src={image} style={{ width: "100%" }} />
      <p style={{ marginTop: "2.5rem" }}>Coming soon...</p>
      <Button
        type="primary"
        size="small"
        icon={<ArrowLeftOutlined />}
        onClick={goBack}
      >
        Go Back
      </Button>
    </div>
  );
}

const useStyles = createUseStyles({
  resultStyle: {
    height: "80vh",
    marginTop: "2rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "2rem",
    fontWeight: 700,
    color: "#7f5e5e",

    "& .ant-btn": {
      fontSize: 12,
    },
  },
});

export default ComingSoonView;
