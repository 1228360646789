import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import Countdown from "antd/lib/statistic/Countdown";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import OtpInput from "react-otp-input";
import { Link } from "react-router-dom";

export default function EnterOtpPage({
  deadline,
  mobileNumber,
  handleSendOtp,
  resendVisible,
  onFinishCounter,
  onLoginModeChange,
  onSubmit,
  onBack,
}: any) {
  const classes = useStyles();
  const [otpValue, setOtpValue] = useState("");

  useEffect(() => {
    if (otpValue.length === 6) {
      onSubmit(otpValue);
    }
  }, [otpValue]);

  return (
    <div>
      <div className={classes.pageHeader}>
        <Button icon={<ArrowLeftOutlined />} onClick={onBack} type="text" />
        {/* <Link to="">Help</Link> */}
      </div>

      <div className={classes.otpFormWrapper}>
        <div className={classes.enterOtpText}>
          Enter the six digit OTP sent to
        </div>
        <div className={classes.mobileNumberText}>+91{mobileNumber}</div>

        <OtpInput
          value={otpValue}
          onChange={setOtpValue}
          numInputs={6}
          containerStyle={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          inputStyle={{
            width: 35,
            height: 40,
            fontSize: 18,
            borderRadius: 5,
            border: "1px solid rgba(0,0,0,0.3)",
          }}
        />

        <div className={classes.continueButtonWrapper}>
          <Button
            block
            size="large"
            type="primary"
            disabled={otpValue.length !== 6}
            onSubmit={() => onSubmit(otpValue)}
          >
            Continue
          </Button>
        </div>

        <div className={classes.bottomOptions}>
          <div style={{ cursor: "pointer" }} onClick={handleSendOtp}>
            {resendVisible && (
              <>
                Didn't received the OTP? <b>Resend</b>
              </>
            )}
          </div>
          <Countdown
            value={deadline}
            format="mm:ss"
            className={classes.CountDown}
            onFinish={onFinishCounter}
          />
          <div
            onClick={onLoginModeChange}
            style={{
              width: "100%",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Login with password
          </div>
        </div>
      </div>
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  pageHeader: {
    height: 80,
    padding: [0, 25],

    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "& .anticon": {
      fontSize: 18,
    },
  },
  otpFormWrapper: {
    padding: [0, 25],
  },
  enterOtpText: {
    // fontWeight: 500,
  },
  mobileNumberText: {
    fontWeight: 500,
    marginBottom: 20,
  },

  bottomOptions: {
    marginTop: 20,
    fontSize: 12,
    color: colors.dark400,

    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  CountDown: {
    // width: "100%",
    "& .ant-statistic-content-value": {
      fontSize: "0.55em",
      color: colors.dark400,
    },
    "& .ant-statistic-content": {
      display: "flex",
      justifyContent: "flex-start",
    },
  },
  continueButtonWrapper: {
    width: "100%",
    padding: [10, 10, 15],
    position: "fixed",
    bottom: 0,
    left: 0,

    "& .ant-btn": {
      fontSize: 12,
      marginTop: 20,
      borderRadius: 5,
    },
  },
}));
