import {
  CheckOutlined,
  ClockCircleOutlined,
  DeleteOutlined,
  FileDoneOutlined,
  GiftOutlined,
  SendOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { Steps, Button, Card } from "antd";
import { createUseStyles } from "react-jss";
import React, { useState, useEffect } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { deleteAllReorderItems, deleteReorder } from "api/api";
import CancelOrderModal from "components/modals/CancelOrderModal";

const { Step } = Steps;
export let delReorder: any;

const steps = [
  { title: "Packed", icon: <GiftOutlined /> },
  {
    title: "Shipped",
    icon: <SendOutlined />,
  },
  {
    title: "Delivered",
    icon: <FileDoneOutlined />,
  },
];
type ActiveOrdersCardProps = {
  order?: any;
  orders?: any;
  setOrders: (order: any) => void;
  setLoader: any;
};

const getCurrentStep = (orderStatus: number) => {
  console.log(orderStatus);

  if (orderStatus === 1) {
    return -1;
  } else if (orderStatus === 2) {
    return 0;
  } else if (orderStatus === 3 || orderStatus === 4) {
    return 1;
  } else {
    return 2;
  }
};
const ActiveOrdersCard = ({
  order,
  setOrders,
  orders,
  setLoader,
}: ActiveOrdersCardProps) => {
  const classes = useStyles();

  const getStepIcon = (index: any, currentStep: any, stepIcon: any) => {
    let icon = stepIcon;
    if (currentStep >= index) icon = <CheckOutlined />;

    return (
      <Button
        type="primary"
        shape="circle"
        icon={icon}
        className={classes.stepsIcon}
      />
    );
  };
  const [show, setShow] = useState(true);
  const [visible, setVisible] = useState(false);
  const currentStep = getCurrentStep(order?.order_status ?? 1); // keeping default for now as hard coded in the old code
  const { url } = useRouteMatch();

  delReorder = async (onComplete: any) => {
    let data = [...orders];
    const index = order?.key;
    data.splice(index, 1);
    setOrders?.(data);
    setShow(false);
    await deleteAllReorderItems(order?.id);
    await deleteReorder(order?.id);
    onComplete();
    setLoader();
  };

  const showCancelModal = () => setVisible(true);

  // ##################################
  // Need to add order item page and it's functionality here as well
  // ##################################

  return (
    <Card hoverable className={classes.activeOrdersCard}>
      <div className={classes.cardContent}>
        <Link
          to={
            show
              ? "/dashboard/stores/" +
                order.retail_store_id +
                "/reorder-placed/" +
                order.id +
                "?from=active-orders"
              : url
          }
          id="reorder-card"
          data-store={order.retail_store_id}
          data-user={order.user_id}
        >
          <div className={classes.orderDetails}>
            <div style={{ fontSize: 12, color: "#2a9d8f" }}>
              Order no : {order.id}
            </div>
            <div style={{ fontSize: 12, color: "#2a9d8f" }}>
              ₹ {(+order.grandtotal).toFixed(2)}
            </div>
            <div className={classes.orderDateTime}>
              {moment(order.created_at).format("MMMM Do YYYY, h:mm:ss A")}
            </div>
          </div>
        </Link>

        <Button
          type="primary"
          shape="circle"
          icon={<DeleteOutlined />}
          className={classes.deleteButton}
          onClick={showCancelModal}
        />

        <CancelOrderModal
          retailStoreId={order?.retail_store_id}
          visible={visible}
          setVisible={setVisible}
          onOk={delReorder}
        />
      </div>

      {order.payment_status == "true" || order?.payment_mode === "cod" ? (
        <div className={classes.orderProgressSteps}>
          <Steps
            size="small"
            labelPlacement="vertical"
            current={getCurrentStep(order?.order_status)}
          >
            {steps.map((step, index) => (
              <Step
                title={step.title}
                icon={getStepIcon(index, currentStep, step.icon)}
              />
            ))}
          </Steps>
        </div>
      ) : (
        <div style={{ fontSize: 15, color: "#2a9d8f", paddingTop: "10px" }}>
          Complete payment to get your order delivered
        </div>
      )}
    </Card>
  );
};

const useStyles = createUseStyles(({ colors }: Theme) => ({
  ".ant-modal-centered": {
    textAlign: "center !important",
  },
  activeOrdersCard: {
    padding: "0 0.5rem",
    marginBottom: "1.5rem",
    boxShadow: "-1px -1px 2px rgb(0 0 0 / 15%), 1px 1px 2px rgb(0 0 0 / 15%)",
  },
  cardContent: {
    fontWeight: 700,
    color: "#838383",
    fontFamily: "'Roboto'",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  orderDetails: {
    fontSize: 12,

    "& > div": {
      paddingBottom: 5,
    },
  },
  orderDateTime: {
    paddingBottom: "0 !important",
    color: colors.light500,
    fontSize: 9,
  },
  deleteButton: {
    color: "#D62D20",
    background: "rgba(234, 234, 234, 0.6)",
    border: "none",
  },
  stepsIcon: {
    padding: "7px !important",
    color: "#D62D20",
    background: "#F4F4F4",
    border: "none",
  },
  orderProgressSteps: {
    paddingTop: "1.4rem",

    "& *": {
      color: "#838383",
    },

    // customizing stepper
    "& .ant-steps": {
      width: "100%",
    },
    "& .ant-steps-item": {
      width: "25vw !important",
    },
    "& .ant-steps-item-title": {
      fontSize: 9.5,
      fontWeight: 500,
    },
    "& .ant-steps-item-icon, & .ant-steps-item-content": {
      marginLeft: "0 !important",
      width: "100% !important",
    },
    "& .ant-steps-item-tail": {
      marginLeft: "12vw !important",
      top: 15,
      "&:after": {
        height: 1.5,
        background: `${colors.light400} !important`,
      },
    },
    "& .ant-steps-item-process": {
      fontWeight: 600,

      "& $stepsIcon": {
        background: "rgba(234, 234, 234)",
      },

      "&  .ant-steps-item-tail::after": {
        background: "#2A9D8F !important",
      },
    },
    "@media (min-width: 500px)": {
      "& .ant-steps-item-tail": {
        marginLeft: "15vw !important",
      },
    },
  },
}));

export default ActiveOrdersCard;
