import { message, Modal, ModalProps, Select, Space, Typography } from "antd";
import { createTicket } from "api/retailerApi";
import Flex from "components/layouts/Flex";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";

interface OrderBagsModalProps extends ModalProps {
  // ....
  retailerData: {
    ID: number;
    Name: string;
  };
}

export default function OrderBagsModal({
  visible,
  onCancel,
  retailerData,
}: OrderBagsModalProps) {
  const classes = useStyle();
  const [pricePerBag] = useState<any>(2);
  const [bagsOrdered, setBagsOrdered] = useState<any>(0);

  const handlePlaceOrder = async () => {
    if (!bagsOrdered)
      return message.info("Please select number of bags you needs.");

    await createTicket({
      retail_store_id: retailerData.ID,
      status: "OPEN",
      bags_ordered: bagsOrdered,
      ticket_cause: "BAG ORDER",
    }).then((res) => {
      if (!!res?.id) {
        message.success("Bags ordered successfully!");
        // @ts-ignore
        onCancel();
      }
    });
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title="Order Bags"
      okText="Place Order"
      onOk={handlePlaceOrder}
      className={classes.orderBagsModal}
    >
      <Space direction="vertical">
        <Select
          placeholder="No. Of Bags"
          onChange={(val) => setBagsOrdered(val)}
        >
          {[100, 200, 300, 400, 500, 600, 700, 800, 900, 1000].map((val) => (
            <Select.Option key={val} value={val}>
              {val}
            </Select.Option>
          ))}
        </Select>

        {bagsOrdered > 0 && (
          <Space direction="vertical" className={classes.costCalc}>
            <Flex justify="space-between">
              <Typography.Text>No. Of Bags</Typography.Text>
              <Typography.Text>{bagsOrdered}</Typography.Text>
            </Flex>
            <Flex justify="space-between">
              <Typography.Text>Cost Per Bag</Typography.Text>
              <Typography.Text>{pricePerBag}</Typography.Text>
            </Flex>
            <Flex justify="space-between">
              <Typography.Text strong>Total Cost</Typography.Text>
              <Typography.Text strong>
                ₹ {bagsOrdered * pricePerBag}
              </Typography.Text>
            </Flex>
          </Space>
        )}
      </Space>
    </Modal>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  orderBagsModal: {
    maxWidth: "90vw",

    "& .ant-space, & .ant-select": {
      width: "100%",
    },
  },
  costCalc: {
    marginTop: 10,
    fontSize: 12,
  },
}));
