import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Card, Col, Empty, Row, Typography } from "antd";
import { getBlogCategories, getBlogs } from "api/api";
import Loader from "components/loader/Loader";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { Link, useHistory } from "react-router-dom";

export default function ArticlesPage() {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [articlesCategories, setArticlesCategories] = useState<any[]>([]);
  const [articles, setArticles] = useState<any[]>([]);
  const [categoryId, setCategoryId] = useState(3);
  const [pageNumber, setPageNumber] = useState(1);

  const handleScroll = async (e: any) => {
    if (articles.length % 10 !== 0) {
      return;
    }
    //check if reached bottom
    let element = e.target;
    if (
      element.scrollHeight - Math.round(element.scrollTop) ===
      element.clientHeight
    ) {
      setPageNumber((pageNumber) => pageNumber + 1);
    }
  };

  const handleCategoryIdChange = (catID: number) => () => {
    setCategoryId(catID);
    setLoading(true);
  };

  const fetchCategories = async () => {
    // let categories = await getBlogCategories();
    let blogs = await getBlogs(categoryId, pageNumber);

    // setArticlesCategories(categories);
    setArticles((curr) => [...curr, ...blogs]);
    setLoading(false);
  };

  useEffect(() => {
    if (loading || pageNumber > 1) {
      fetchCategories();
    }
  }, [loading, pageNumber]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={classes.readArticlesPage}>
      <Row className={classes.header}>
        <Col className={classes.backButton} span={4}>
          <ArrowLeftOutlined onClick={() => history.goBack()} />
        </Col>
        <Col className={classes.pageHeader} span={20}>
          Articles
        </Col>
      </Row>

      <div className={classes.pageContent}>
        {/* <div className={classes.categoriesWrapper}>
          <Button
            className={classes.categoriesBtn}
            type={!!categoryId ? "default" : "primary"}
            onClick={handleCategoryIdChange(0)}
          >
            All
          </Button>
          {articlesCategories.map((item, index) => (
            <Button
              key={index}
              className={classes.categoriesBtn}
              type={categoryId !== item.id ? "default" : "primary"}
              onClick={handleCategoryIdChange(item.id)}
            >
              {item.name}
            </Button>
          ))}
        </div> */}

        <div onScroll={handleScroll} className={classes.articlesWrapper}>
          {articles.length ? (
            articles.map((item, index) => (
              <Link to={`/dashboard/stores/articles/${item.slug}`}>
                <Card className={classes.articleCard}>
                  <Typography.Text className={classes.articleCardTitle}>
                    {item.title.rendered}
                  </Typography.Text>
                  <div
                    className={classes.articleCardContent}
                    dangerouslySetInnerHTML={{
                      __html: item.excerpt.rendered.substr(0, 150) + "...",
                    }}
                  ></div>
                </Card>
              </Link>
            ))
          ) : (
            <div className={classes.emptyWrapper}>
              <Empty description="No blogs found" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  readArticlesPage: {
    height: "100vh",
    overflow: "hidden",
    background: "rgba(217, 217, 217, 0.2)",
  },
  backButton: {
    textAlign: "center",
  },
  pageHeader: {
    fontSize: 13,
    fontWeight: 700,
  },
  header: {
    height: 58,
    position: "sticky",
    top: "0",
    background: "transparent",
    alignItems: "center",
  },
  pageContent: {
    padding: [0, 20, 20],
  },

  categoriesWrapper: {
    width: "100%",
    marginBottom: 10,
    padding: [5, 10, 5, 0],

    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    overflowY: "auto",

    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  categoriesBtn: {
    fontSize: 12,
    margin: 5,
    borderRadius: 5,
    border: "none",
  },

  articlesWrapper: {
    height: "calc(100vh - 120px)",
    margin: [10, 0],
    paddingBottom: 50,
    overflowY: "auto",
    overflowX: "visible",
  },
  articleCard: {
    marginTop: 15,
    border: "1px solid rgba(0,0,0,.06)",
  },
  articleCardTitle: {
    display: "block",
    fontWeight: 500,
    marginBottom: 5,
    color: "#111",
  },
  articleCardContent: {
    fontSize: 12,
    color: colors.dark400,
  },

  emptyWrapper: {
    height: "85%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
