import { Button, Card, Divider, Input, Layout, Space, Typography } from "antd";
import {
  getBlogs,
  getCatalogue,
  getPlans,
  getRetailStores,
  getRetailStoresUsersMapping,
  getShopBanner,
} from "api/api";
import FooterMenu from "components/FooterMenu";
import Loader from "components/loader/Loader";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import BannerCard from "components/cards/BannerCard";
import ArticlesSection from "components/sections/ArticlesSection";
import ProductCard from "components/cards/ProductCard";
import RecentDocumentsSection from "components/sections/RecentDocumentsSection";
import { getUserAllPrescriptions } from "api/retailerApi";
import { hideScrollbar } from "components/style/CustomScrollbar";
import RetailStoreDetailsView from "components/views/RetailerStoreDetailsView";
import PlansSection from "components/sections/PlansSection";
import TemplateShareCard from "components/cards/TemplateShareCard";

type RouteParams = {
  retailStoreId: string;
};

interface SectionWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  title: string;
  viewAll: string | false;
}

export default function NewHomePage() {
  const classes = useStyle();
  const history = useHistory();
  const {
    params: { retailStoreId },
  } = useRouteMatch<RouteParams>() || {};

  const [storeDetails, setStoreDetails] = useState<any>({});
  const [mappedStores, setMappedStores] = useState<any[]>([]);
  const [frequentlyBoughtItems, setFrequentlyBoughtItems] = useState<any[]>([]);
  const [prescriptions, setPrescriptions] = useState<any[]>([]);
  const [articles, setArticles] = useState<any[]>([]);
  const [banners, setBanners] = useState<any[]>([]);
  const [suggestedItems, setSuggestedItems] = useState<any[]>([]);
  const [plans, setPlans] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [countChange, setCountChange] = useState(false);

  const handleProductChange =
    (setState: React.Dispatch<React.SetStateAction<any[]>>) =>
    (action: string, product: any) => {
      switch (action) {
        case "add":
          setState((curr) => {
            let index = curr.findIndex(
              (i) => i.product_sku === product.product_sku
            );

            if (index >= 0) {
              curr[index].quantity = product.quantity;
              curr[index].cart_id = product.cart_id;
              curr[index].cart_item_id = product.id;
              curr[index].retailer_specific_product_id = product.product_id;
            }

            setCountChange(true);

            return [...curr];
          });
          break;

        case "update":
          setState((curr) => {
            let index = curr.findIndex(
              (i) => i.product_sku === product.product_sku
            );

            if (index >= 0) {
              curr[index].quantity = product.quantity;
            }

            return [...curr];
          });
          break;

        case "remove":
          setState((curr) => {
            let index = curr.findIndex(
              (i) => i.product_sku === product.product_sku
            );

            if (index >= 0) {
              curr[index].quantity = "0";
              curr[index].cart_item_id = "0";
            }

            setCountChange(true);

            return [...curr];
          });
          break;
      }
    };

  const fetchPrescriptions = async () => {
    let presArray: any = [];
    let result = await getUserAllPrescriptions();

    for (let i = 0; i < result.linkArray.length; i++) {
      let pres = result.linkArray[i];

      presArray.push({
        pres_id: pres.objectId,
        name: pres.filename,
        image: pres.value,
        date: pres.date,
      });
    }

    return presArray;
  };

  const fetchProducts = async (apiStr: string) => {
    let [count, ...productsRes] = await getCatalogue(apiStr, +retailStoreId);
    return productsRes;
  };

  const fetchInitialData = async () => {
    let storeDetailsRes = await getRetailStores(+retailStoreId);
    let myCatalogueRes = await fetchProducts("/getMyCatalogue");
    let productsCatalogueRes = await fetchProducts("/catalogue/products");
    let articlesRes = await getBlogs(3, 1);
    let bannersRes = await getShopBanner(+retailStoreId, "home");
    let plansRes = await getPlans();
    fetchPrescriptions().then((prescriptionsRes) =>
      setPrescriptions(prescriptionsRes)
    );

    setFrequentlyBoughtItems(myCatalogueRes);
    setSuggestedItems(productsCatalogueRes);

    setPlans(plansRes.data);
    setBanners(bannersRes);
    setArticles(articlesRes);
    setStoreDetails(storeDetailsRes);
    setLoading(false);
  };

  const fetchStores = async (setStore?: boolean) => {
    let storesMappingRes = await getRetailStoresUsersMapping();
    setMappedStores(storesMappingRes);

    if (setStore) {
      let defaultStore = localStorage.getItem("defaultStore");
      if (!!defaultStore) {
        history.replace(`/dashboard/stores/${+defaultStore}/home`);
      } else {
        history.replace(
          `/dashboard/stores/${storesMappingRes[0].retail_store_id}/home`
        );
      }
    }
  };

  useEffect(() => {
    if (loading && !!retailStoreId) {
      fetchInitialData();
      fetchStores();
    } else if (!!!retailStoreId) {
      fetchStores(true);
    }
  }, [loading, retailStoreId]);

  return (
    <div className={classes.homePage}>
      {!loading ? (
        <>
          <RetailStoreDetailsView
            hideAddress
            retailStore={storeDetails}
            countChange={countChange}
            setCountChange={setCountChange}
            onStoreChange={() => setLoading(true)}
          />
          <div className={classes.homePageInner}>
            <div className={classes.searchWrapper}>
              <Input.Search
                size="large"
                placeholder="Search..."
                onSearch={(val) => {
                  history.push(
                    `/dashboard/stores/${retailStoreId}/shop?search=${val}`
                  );
                }}
              />
            </div>

            {banners.length && (
              <BannerCard
                defaultBanners={banners}
                storeId={+retailStoreId}
                page="home"
              />
            )}

            {+retailStoreId === 20 && (
              <>
                <Divider />
                <TemplateShareCard />
              </>
            )}

            <Divider />

            <PlansSection plans={plans} />

            <Typography.Text className={classes.forYouText}>
              For you
            </Typography.Text>

            <SectionWrapper
              title="Articles"
              viewAll="/dashboard/stores/articles"
            >
              <ArticlesSection articles={articles} />
            </SectionWrapper>

            <SectionWrapper
              title="Frequently Bought Items"
              viewAll={`/dashboard/stores/${retailStoreId}/shop?filter_mycatalog=true`}
              hidden={frequentlyBoughtItems.length < 3}
            >
              <Space className={classes.horizontalListWrapper}>
                {frequentlyBoughtItems.map((item, index) => (
                  <ProductCard
                    data={item}
                    key={index}
                    onChange={handleProductChange(setFrequentlyBoughtItems)}
                  />
                ))}
              </Space>
            </SectionWrapper>

            <SectionWrapper
              title="Suggested Items"
              viewAll={`/dashboard/stores/${retailStoreId}/shop`}
              hidden={suggestedItems.length < 3}
            >
              <Space className={classes.horizontalListWrapper}>
                {suggestedItems.map((item, index) => (
                  <ProductCard
                    data={item}
                    key={index}
                    onChange={handleProductChange(setSuggestedItems)}
                  />
                ))}
              </Space>
            </SectionWrapper>

            <Divider />

            <SectionWrapper
              title="Recent Documents"
              viewAll="/dashboard/stores/prescriptions-and-reports"
              hidden={prescriptions.length < 3}
            >
              <RecentDocumentsSection prescriptions={prescriptions} />
            </SectionWrapper>
          </div>
        </>
      ) : (
        <Loader />
      )}

      <Layout.Footer style={{ padding: 0 }}>
        <FooterMenu retailStoreId={retailStoreId} />
      </Layout.Footer>
    </div>
  );
}

const SectionWrapper = ({
  children,
  title,
  viewAll,
  ...props
}: SectionWrapperProps) => {
  const classes = useStyle();

  return (
    <div className={classes.sectionWrapper} {...props}>
      <div className={classes.sectionTitleWrapper}>
        <Typography.Text className={classes.sectionTitle}>
          {title}
        </Typography.Text>

        {!!viewAll && (
          <Link to={viewAll}>
            <Button type="link">View all</Button>
          </Link>
        )}
      </div>

      {children}
    </div>
  );
};

const useStyle = createUseStyles(({ colors }: Theme) => ({
  homePage: {
    height: "100vh",
    overflow: "hidden",
  },
  homePageInner: {
    height: "calc(100vh - 110px)",
    overflowY: "auto",
    overflowX: "hidden",
    padding: [15, 15, 50],
  },
  searchWrapper: {
    marginBottom: 15,

    "& input": {
      height: 40,
      fontSize: 12,
    },
    "& .ant-btn": {
      borderLeft: "none !important",
    },
  },

  planCard: {
    margin: [20, 0],
    "& .ant-card-body": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },

    "& .ant-btn": {
      fontSize: 10,
    },
  },
  planCardTitle: {
    fontSize: 20,
    color: colors.primary,
  },
  planCardSubtitle: {
    fontSize: 12,
  },

  forYouText: {
    marginTop: 15,
    display: "block",
    fontSize: 18,
    color: colors.textPrimary,
    paddingLeft: 5,
    fontWeight: 600,
  },
  sectionWrapper: {
    margin: [5, 0, 20],
  },
  sectionTitleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  sectionTitle: {
    display: "block",
    padding: [10, 5, 10],
    // fontWeight: 600,
    color: colors.textPrimary,
  },
  horizontalListWrapper: {
    width: "100%",
    paddingRight: 10,

    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",

    overflowX: "auto",
    ...hideScrollbar,
  },

  testCard: {
    width: "50vw",
    fontSize: 12,
  },
  testCardName: {
    display: "block",
  },
  testCardTop: {
    display: "flex",
    justifyContent: "flex-start",
    "& img": {
      marginRight: 10,
      maxWidth: 50,
      objectFit: "contain",
    },
  },
  testCardBottom: {
    marginTop: 20,
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "& .ant-btn": {
      fontSize: 10,
    },
  },

  personalizedOffersCard: {
    width: "100%",
    fontSize: 12,
    "& .ant-btn": {
      fontSize: 12,
      background: "#FCB415",
      borderColor: "#FCB415",
    },
  },
  personalizedOffersCardPrice: {
    display: "block",
    fontSize: 14,
    margin: [10, 0, 10],
  },

  payentOfferCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    fontSize: 12,

    "& img": {
      height: 20,
      objectFit: "contain",
    },
    "& .ant-btn": {
      fontSize: 12,
      marginTop: 5,
      background: "#FCB415",
      borderColor: "#FCB415",
    },
  },

  documentCardWrapper: {
    width: "100%",
  },
  documentCard: {
    width: "100%",
    padding: [15, 10],
    fontSize: 12,

    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    border: "0.3px solid #D9D9D9",
    boxShadow:
      "-1px -1px 2px rgba(0, 0, 0, 0.1), 1px 1px 2px rgba(0, 0, 0, 0.1)",
    borderRadius: 2,

    "& img": {
      width: 60,
      objectFit: "contain",
    },
    "& .ant-typography": {
      display: "block",
    },
  },

  articleCategoryCard: {
    "& img": {
      width: "100%",
      objectFit: "contain",
      marginBottom: 10,
    },
    "& .ant-card-body": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
}));
