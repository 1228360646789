import Button from "antd/es/button";
import Input from "antd/es/input";
import React from "react";
import { FormProvider } from "react-hook-form";

import Field from "components/field/Field";
import useForm from "hooks/useForm";
import User from "models/User";
import type { FormProps } from "hooks/useForm";
import { ResetPasswordInput } from "generated/schema";

type ResetPasswordFormProps = FormProps<ResetPasswordInput>;

function ResetPasswordForm(props: ResetPasswordFormProps) {
  const { isSubmitting, methods, submitHandler } = useForm<ResetPasswordInput>({
    ...props,
    resolver: User.validateResetPassword(),
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={submitHandler}>
        <Field as={Input} name="token" type="hidden" />
        <Field as={Input} name="userId" type="hidden" />

        <Field
          as={Input}
          disabled
          name="mobileNumber"
          type="number"
          label="Mobile Number"
        />
        <Field as={Input} name="password" type="password" label="Password" />
        <Field
          as={Input}
          name="confirmPassword"
          type="password"
          label="Confirm password"
        />

        <Button type="primary" htmlType="submit" loading={isSubmitting} block>
          Submit
        </Button>
      </form>
    </FormProvider>
  );
}

export default ResetPasswordForm;
