import { Card, Space, Typography } from "antd";
import { getBlogs } from "api/api";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { Link } from "react-router-dom";

interface ArticlesSectionProps {
  articles: any[];
}

export default function ArticlesSection({ articles }: ArticlesSectionProps) {
  const classes = useStyle();

  return (
    <Space className={classes.horizontalListWrapper}>
      {articles.map((item, index) => (
        <Link key={index} to={`/dashboard/stores/articles/${item.slug}`}>
          <Card className={classes.articleCard}>
            <img
              src={
                index % 2 === 0
                  ? "https://img.freepik.com/free-vector/happy-freelancer-with-computer-home-young-man-sitting-armchair-using-laptop-chatting-online-smiling-vector-illustration-distance-work-online-learning-freelance_74855-8401.jpg?w=2000"
                  : "https://img.freepik.com/free-vector/blogging-illustration-concept_114360-788.jpg?w=2000&t=st=1660186794~exp=1660187394~hmac=f8782b8d88ca52abf88ab4faeceab043b0aa41260d35d1418a1265a907fefc0c"
              }
              alt=""
            />
            <Typography.Text>{item.title.rendered}</Typography.Text>
          </Card>
        </Link>
      ))}
    </Space>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  articleCard: {
    width: "80vw",

    "& .ant-card-body": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "& img": {
      marginRight: 10,
      maxWidth: 150,
      height: "100%",
      objectFit: "contain",
    },
  },
  horizontalListWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    overflowX: "auto",
  },
}));
