import React, { useEffect } from "react";
import { useStyles } from "./index.style";
import MorePage from "./MorePage/MorePage";
import ShopPage from "./SinglePages/ShopPage";
import CartPage from "./CheckoutPages/CartPage";
import BillView from "components/views/BillView";
import OffersPage from "./SinglePages/OffersPage";
import UtilityPage from "./SinglePages/UtilityPage";
import BillReceiptsPage from "./SinglePages/BillReceiptsPage";
import OrderPlacedPage from "./CheckoutPages/OrderPlacedPage";
import OrderDetailsPage from "./CheckoutPages/OrderDetailsPage";
import { EditProfilePage } from "./ProfilePage/EditProfilePage";
import ActiveOrdersView from "components/views/ActiveOrdersView";
import OrderDetailsView from "components/views/OrderDetailsView";
import { Route, Redirect, useRouteMatch } from "react-router-dom";
import PrescriptionsAndReports from "./SinglePages/PrescriptionsAndReports";
import { MyPrescriptionsPage } from "./PrescriptionsPage/MyPrescriptionsPage";
import ArticlesPage from "./SinglePages/ArticlesPage";
import ArticleDetailsPage from "./SinglePages/ArticleDetailsPage";
import StatsPage from "components/pages/customerDashboard/SinglePages/StatsPage";
import DownloadPreviousBillsView from "components/views/DownloadPreviousBillsView";
import RetailStoresPage from "components/pages/customerDashboard/StoresHomepage/RetailStoresPage";
import ApplyCouponPage from "./CheckoutPages/ApplyCouponPage";
import PlansPage from "./SinglePages/PlansPage";
import NewHomePage from "../HomePage/NewHomePage";
import ProductDetails from "./SinglePages/ProductDetails";
import { useLocation } from "react-router-dom";
import CashbackSummaryPage from "./SinglePages/CashbackSummaryPage";
import NewShopPage from "./SinglePages/NewShopPage";
import CarePage from "./SinglePages/CarePage";

function Index() {
  const { search } = useLocation();
  const { path } = useRouteMatch(["/customer", "/dashboard"]) || {};
  const classes = useStyles();

  useEffect(() => {
    const favicon = document.getElementById("favicon");
    const touchFavicon = document.getElementById("touch-favicon");
    var userType = localStorage.getItem("userType");
    if (userType === "Customer" && favicon && touchFavicon) {
      favicon.setAttribute("href", "/Customer-logo.png");
      touchFavicon.setAttribute("href", "/Customer-logo.png");
    }
  });

  return (
    <div className={classes.customerDashboard}>
      <Route
        path={path}
        exact
        render={() => (
          <Redirect to={{ search, pathname: "/dashboard/stores/" }} />
        )}
      />

      <Route
        path={`/dashboard/stores/:retailStoreId/bill`}
        exact
        render={() => <BillView />}
      />
      <Route
        path={`/dashboard/stores/:retailStoreId/offers`}
        exact
        render={() => <OffersPage />}
      />
      <Route
        path={`/dashboard/stores/:retailStoreId/plans`}
        exact
        render={() => <PlansPage />}
      />
      <Route
        path={`/dashboard/stores/:retailStoreId/shop`}
        exact
        render={() => <NewShopPage />}
      />
      <Route
        path={`/dashboard/stores/:retailStoreId/stats`}
        exact
        render={() => <StatsPage />}
      />
      <Route
        path={`/dashboard/stores/:retailStoreId/cart/:cartId`}
        exact
        render={() => <CartPage />}
      />
      <Route
        path={`/dashboard/stores/:retailStoreId/cart/:cartId/apply-coupon`}
        exact
        render={() => <ApplyCouponPage />}
      />
      <Route
        path={`/dashboard/stores/:retailStoreId/reorder-placed/:reorderId`}
        exact
        render={() => <OrderPlacedPage />}
      />
      <Route
        path={`/dashboard/stores/:retailStoreId/reorder-placed/:reorderId/order-summary`}
        exact
        render={() => <OrderDetailsPage />}
      />
      <Route
        path={`/dashboard/stores/:retailStoreId/more`}
        exact
        render={() => <MorePage />}
      />
      <Route
        path={`/dashboard/stores/more/active-orders/:reorderId`}
        exact
        render={() => <OrderDetailsView />}
      />
      <Route
        path={`/dashboard/stores/more/active-orders`}
        exact
        render={() => <ActiveOrdersView />}
      />
      <Route
        path={`/dashboard/stores/:retailStoreId/more/previous-bills`}
        exact
        render={() => <DownloadPreviousBillsView />}
      />
      <Route
        path={"/dashboard/profile"}
        exact
        render={() => <EditProfilePage />}
      />
      <Route
        path={"/dashboard/stores/prescriptions-and-reports"}
        exact
        render={() => <PrescriptionsAndReports />}
      />
      <Route
        path={"/dashboard/stores/download-bill-receipts"}
        exact
        render={() => <BillReceiptsPage />}
      />
      <Route
        path={"/dashboard/stores/cashback-summary"}
        exact
        render={() => <CashbackSummaryPage />}
      />
      <Route
        exact
        path={"/dashboard/stores/:retailStoreId/cart/:cartId/my-prescriptions"}
        render={() => <MyPrescriptionsPage />}
      />
      <Route
        path={"/dashboard/stores/articles"}
        exact
        render={() => <ArticlesPage />}
      />
      <Route
        path={"/dashboard/stores/articles/:articleSlug"}
        exact
        render={() => <ArticleDetailsPage />}
      />
      <Route
        exact
        path={"/dashboard/stores/:retailStoreId/utility"}
        render={() => <CarePage />}
      />
      <Route
        exact
        path={"/dashboard/stores/:retailStoreId/care"}
        render={() => <CarePage />}
      />
      <Route
        exact
        path={"/dashboard/stores/:retailStoreId/product/:pid"}
        render={() => <ProductDetails />}
      />

      <Route
        exact
        path={["/dashboard/stores/", "/dashboard/stores/:retailStoreId/home"]}
        render={() => <NewHomePage />}
      />
    </div>
  );
}

export default Index;
