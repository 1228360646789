import {
  ArrowLeftOutlined,
  HomeOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { Button, Layout } from "antd/es";
import {
  getReorderById,
  getReorderItems,
  getCustomer,
  getRetailStores,
} from "api/api";
import Loader from "components/loader/Loader";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useHistory, useRouteMatch } from "react-router-dom";
import FooterMenu from "components/FooterMenu";

type RouteParams = {
  reorderId: string;
  retailStoreId: string;
};

const getAddressString = (customer: any) =>
  customer.line1 +
  ", " +
  customer.line2 +
  ", " +
  customer.city +
  ", " +
  customer.state +
  ", " +
  customer.pincode;

function OrderDetailsPage() {
  const {
    params: { reorderId, retailStoreId },
  } = useRouteMatch<RouteParams>() || {};
  const classes = useStyles();
  const history = useHistory();
  const [pageData, setPageData] = useState<any>({
    reorder: {},
    retailerDetails: {},
    addressDetails: {},
    loading: true,
    items: [],
  });

  useEffect(() => {
    (async () => {
      const reorder = await getReorderById(reorderId);
      const items = await getReorderItems(+reorderId);
      const retailer = await getRetailStores(+retailStoreId);
      const customer = await getCustomer();

      const addressData = {
        address: getAddressString(customer),
        phone_no: customer?.phone_no,
      };

      setPageData({
        ...pageData,
        items,
        reorder: reorder.length > 0 ? reorder[0] : [],
        addressDetails: addressData,
        retailerDetails: retailer,
        loading: false,
      });
    })();
  }, [reorderId]);

  const continueReorderingClick = () =>
    history.replace(`/dashboard/stores/${retailStoreId}/bill`);
  const goBackToReorderPlacedPage = () =>
    history.replace(
      `/dashboard/stores/${retailStoreId}/reorder-placed/${reorderId}`
    );

  console.log(pageData.reorder);

  return (
    <div className={classes.orderDetailsPage}>
      <div className={classes.pageHeader}>
        <ArrowLeftOutlined onClick={goBackToReorderPlacedPage} />
        Order Details
      </div>

      {!pageData.loading ? (
        <>
          <div className={classes.pageContent}>
            <div className={classes.addressWrapper}>
              <div className={classes.addressWrapperTitle}>
                Delivery Address
              </div>

              <div className={classes.address}>
                <div className={classes.addressItem}>
                  <HomeOutlined />
                  <span>{pageData?.addressDetails?.address}</span>
                </div>
                <div className={classes.addressItem}>
                  <PhoneOutlined rotate={90} />
                  <span>{pageData?.addressDetails.phone_no}</span>
                </div>
              </div>
            </div>

            <div className={classes.itemDetailsWrapper}>
              <div className={classes.itemDetailsWrapperTitle}>
                Item Details <span>{pageData?.items?.length} ITEMS</span>
              </div>

              {pageData.items.map((item: any) => (
                <div className={classes.itemWrapper}>
                  <div>
                    <div className={classes.itemName}>{item?.name}</div>
                    <div className={classes.itemQuantity}>
                      Qty : {item?.quantity}
                    </div>
                  </div>
                  <div className={classes.itemTotal}>
                    ₹ {parseFloat(item?.total).toFixed(2)}
                  </div>
                </div>
              ))}
            </div>

            <div className={classes.paymentDetailsWrapper}>
              <div className={classes.paymentDetailsWrapperTitle}>
                Payment Details
              </div>
              <div className={classes.paymentDetails}>
                {/* <div className={classes.paymentDetailsItem}>
                  <span>Order No:</span>
                  <span>MNOO-17899</span>
                </div>
                <hr/>
                <div className={classes.paymentDetailsItem}>
                  <span>Invoice No:</span>
                  <span>APO1506001</span>
                </div>
                <hr/>
                <div className={classes.paymentDetailsItem}>
                  <span>Payment Option</span>
                  <span>UPI</span>
                </div>
                <hr/> */}
                <div className={classes.paymentDetailsItem}>
                  <span>Order Amount</span>
                  <span>
                    ₹{" "}
                    {(
                      +pageData?.reorder?.grandtotal +
                      +pageData?.reorder?.discount +
                      +pageData?.reorder?.coupon_discount +
                      +pageData?.reorder?.redeemed_loyalty_points -
                      +pageData?.reorder?.shipping_charge
                    ).toFixed(2)}
                  </span>
                </div>
                <hr />
                <div className={classes.paymentDetailsItem}>
                  <span>Discount Availed</span>
                  <span>
                    ₹{" "}
                    {(
                      +pageData?.reorder?.discount +
                      +pageData?.reorder?.coupon_discount
                    ).toFixed(2)}
                  </span>
                </div>
                <hr />
                <div className={classes.paymentDetailsItem}>
                  <span>Redeemed Loyalty Points</span>
                  <span>
                    ₹ {(+pageData?.reorder?.redeemed_loyalty_points).toFixed(2)}
                  </span>
                </div>
                <hr />
                <div className={classes.paymentDetailsItem}>
                  <span>Shipping Charge</span>
                  <span>
                    ₹ {(+pageData?.reorder?.shipping_charge).toFixed(2)}
                  </span>
                </div>
                <hr />
                <div className={classes.paymentDetailsItem}>
                  <span>Total</span>
                  <span>
                    ₹ {parseFloat(pageData?.reorder?.grandtotal).toFixed(2)}
                  </span>
                </div>
              </div>
            </div>
            <Button
              type="text"
              href={
                "tel:+91" + pageData?.retailerDetails?.primary_mobile_number
              }
              icon={<PhoneOutlined rotate={90} />}
              className={classes.contactStoreBtn}
            >
              Contact Store
            </Button>
          </div>

          {/* <div className={classes.continueButtonWrapper}>
            <Button block type="default" onClick={continueReorderingClick}>
              Continue Reordering
            </Button>
          </div> */}
          <Layout.Footer style={{ padding: 0 }}>
            <FooterMenu retailStoreId={retailStoreId} />
          </Layout.Footer>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  orderDetailsPage: {
    height: "100vh",
    overflow: "hidden",
    backgroundColor: "#EAF5F4",
  },
  pageContent: {
    height: "calc(100vh - 130px)",
    overflowY: "auto",
  },
  pageHeader: {
    height: 58,
    padding: [0, 26],
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "22px",
    color: "#2A9D8F",

    "& .anticon": {
      fontSize: 15,
      marginRight: 18,
    },
  },

  addressWrapper: {},
  addressWrapperTitle: {
    height: 30,
    paddingLeft: 22,
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "30px",
    color: "#505050",
  },
  address: {
    padding: [10, 20],
    background: "#FFFFFF",
    border: "0.3px solid #D9D9D9",
    boxSizing: "border-box",
  },
  addressItem: {
    fontSize: "10px",
    lineHeight: "18px",
    color: "#505050",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",

    "&:first-child": {
      marginBottom: 5,
    },

    "& .anticon": {
      marginRight: 10,
      color: "#2A9D8F",
    },
  },

  itemDetailsWrapper: {},
  itemDetailsWrapperTitle: {
    height: 30,
    width: "100%",
    padding: [0, 22],
    marginTop: 15,
    fontWeight: "500",
    fontSize: "12px",
    color: "#505050",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  itemWrapper: {
    padding: [10, 20],
    marginBottom: 12,
    fontWeight: 500,
    fontSize: "10px",
    lineHeight: "12px",
    color: "#505050",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#FFFFFF",
    border: "0.3px solid #D9D9D9",
    boxSizing: "border-box",
  },
  itemName: {
    marginBottom: 10,
  },
  itemQuantity: {},
  itemTotal: {
    fontWeight: 700,
  },

  paymentDetailsWrapper: {},
  paymentDetailsWrapperTitle: {
    height: 30,
    paddingLeft: 22,
    marginTop: 15,
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "30px",
    color: "#505050",
  },
  paymentDetails: {
    padding: [10, 22],
    background: "#FFFFFF",
    border: "0.3px solid #D9D9D9",
    boxSizing: "border-box",
  },
  paymentDetailsItem: {
    width: "100%",
    marginBottom: 7,
    fontWeight: "normal",
    fontSize: "10px",
    lineHeight: "12px",
    color: "#505050",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "&:last-child": {
      fontWeight: 700,
    },
  },

  continueButtonWrapper: {
    width: "100%",
    padding: [20, 90],
    background: "#EAF5F4",
    position: "fixed",
    bottom: 70,

    "& .ant-btn": {
      background: "transparent",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "22px",
      color: "#2A9D8F",
      border: "1px solid #2A9D8F",
      boxSizing: "border-box",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  contactStoreBtn: {
    fontSize: 12,
    margin: 10,

    "& .anticon": {
      fontSize: 14,
    },
  },
}));

export default OrderDetailsPage;
