import {
  getConnectedRetailStores,
  getRetailerRetailStores,
  getRetailStoresUsersMapping,
  subscribeFCMTopic,
} from "api/api";
import { getSession, Session } from "client/reactives/session";
import React, { useState, useEffect } from "react";
import { fetchToken } from "utils/firebase";
import firebase from "firebase/app";

export default function Notifications(props) {
  const { userType, userId } = getSession();

  async function tokenFunc(topics, type) {
    const token = await fetchToken();
    if (!!token) {
      await subscribeFCMTopic({
        topics,
        user_id: userId,
        regTokens: [token],
        application_type: type,
      }).then((res) => {
        if (!res?.failureCount === "0") {
          localStorage.setItem("subscribedToNotifications", "true");
        }
      });
    }
  }

  const subscribeFCM = async () => {
    if (userType === "Customer") {
      await getRetailStoresUsersMapping(userId).then((res) => {
        if (res?.length) {
          let topics = res.map((i) => "store_" + i?.retail_store_id);
          console.log(topics);

          tokenFunc(topics, "customer");
        } else {
          fetchToken();
        }
      });
    } else if (userType === "Retailer") {
      await getRetailerRetailStores().then((res) => {
        if (!!res) {
          tokenFunc(["store_" + res?.id, "retailer"]);
          console.log("store_" + res?.id);
        }
      });
    }
  };

  // To load once
  useEffect(() => {
    if (firebase.messaging.isSupported()) {
      subscribeFCM();
    }
  }, [userType]);

  return <></>;
}
