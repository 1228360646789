import { Divider, Typography } from "antd";
import Button from "antd/es/button";
import { getReorderItems, updateReorderStatus } from "api/api";
import { useStyles } from "./OrderSummary.style";
import React, { useState, useEffect } from "react";
import OrderMsgCard from "components/cards/OrderMsgCard";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import LoyaltyPointsCard from "components/cards/LoyaltyPointsCard";
import OrderSummaryTableCard from "components/cards/OrderSummaryTableCard";
import Column from "antd/lib/table/Column";
import MapOrderModal from "components/modals/MapOrderModal";
import ReportReorderModal from "components/modals/ReportReorderModal.tsx";

type OrderSummaryCardProps = {
  order: any;
  orderStatus: string;
};

function OrderSummaryCard({ order, orderStatus }: OrderSummaryCardProps) {
  const classes = useStyles();
  const [orderSummaryData, setOrderSummaryData] = useState({
    items: [],
    orderDateTime: "",
    message_card: [{}],
  });

  const screens = useBreakpoint();
  const isXl = screens.xl;
  const paymentStatus =
    order && order.payment_status
      ? order.payment_status === "SUCCESS"
        ? "PAID"
        : order.payment_status === "INITIATED"
        ? "PENDING"
        : order.payment_status
      : "PENDING";

  const getTotal = (type?: string, Items?: any) => {
    let itemsArr = [...Items];

    if (type === "availableItems") {
      itemsArr = itemsArr.filter((item) => item.availability);
    } else if (type === "unavailableItems") {
      itemsArr = itemsArr.filter((item) => !item.availability);
    }

    let total_amount = itemsArr.reduce((total, item) => total + +item.total, 0);
    return total_amount.toFixed(0);
  };

  async function fetchOrderItems() {
    var orderDate = new Date(order.created_at);
    var utcOrderDate = orderDate.toLocaleDateString();
    var utcOrderTime = orderDate.toLocaleTimeString("en-US");
    var orderItems = await getReorderItems(order.id);
    for (var i = 0; i < orderItems.length; i++) {
      orderItems[i] = {
        isAvailable: true,
        key: i + 1,
        ...orderItems[i],
      };
    }
    setOrderSummaryData({
      items: orderItems,
      orderDateTime: utcOrderDate + ", " + utcOrderTime,
      message_card: [
        { title: "Total sale of the order", amount: getTotal("", orderItems) },
        {
          title: "Available items",
          badge: "confirmBadge",
          amount: getTotal("availableItems", orderItems),
        },
        {
          title: "unavailable items",
          badge: "lostBadge",
          amount: getTotal("unavailableItems", orderItems),
        },
      ],
    });
  }

  useEffect(() => {
    if (order) {
      fetchOrderItems();
      setCurrentStatus(order["order_status"]);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);
  enum status {
    "New" = 1,
    "Packed",
    "Partial Shipped",
    "Shipped",
    "Partially Delivered",
    "Delivered",
    "Closed",
  }
  // for ref check flow image
  // new 1
  // packed 2
  // partial shipped 3
  // shipped 4
  // Partial delivered 5
  // Delivered 6
  // Closed 7

  const [currentStatus, setCurrentStatus] = useState(0);
  const [firstChoise, setFirstChoice] = useState("");
  const [secondChoice, setSecondChoice] = useState("");
  const [mapOrderModalVisible, setMapOrderModalVisible] = useState(false);
  const [reportModalVisible, setReportModalVisible] = useState(false);

  useEffect(() => {
    setCurrentStatus(order?.order_status);
  }, []);

  useEffect(() => {
    if (currentStatus == 1) {
      setFirstChoice("Packed");
      setSecondChoice("");
    } else if (currentStatus == 2) {
      setFirstChoice("Shipped");
      setSecondChoice("Partial Shipped");
    } else if (currentStatus == 3) {
      setFirstChoice("Partially Delivered");
      setSecondChoice("");
    } else if (currentStatus == 4) {
      setFirstChoice("Delivered");
      setSecondChoice("Partially Delivered");
    } else if (currentStatus == 5) {
      setFirstChoice("Closed");
      setSecondChoice("");
    } else if (currentStatus == 6) {
      setFirstChoice("Closed");
      setSecondChoice("");
    } else {
      setFirstChoice("");
      setSecondChoice("");
    }
  }, [currentStatus]);

  const toggleMapOrderModal = () => {
    setMapOrderModalVisible(!mapOrderModalVisible);
  };

  const toggleReportModal = () => {
    setReportModalVisible(!reportModalVisible);
  };

  const handleMapOrderClick = () => {
    setMapOrderModalVisible(true);
  };

  const handleClick = async (e: any) => {
    if (orderSummaryData.items.length > 0 && e.currentTarget.value) {
      setCurrentStatus(e.currentTarget.value);
      var status = await updateReorderStatus(
        orderSummaryData.items[0]["reorder_id"],
        e.currentTarget.value
      );
    } else {
      alert("No Items for the order");
    }
  };

  return (
    <>
      {order ? (
        <div className={classes.orderSummaryCard}>
          <div className={classes.orderSummaryCardInner}>
            <div className={classes.orderDetails}>
              <div style={{ flexDirection: "column" }}>
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <div className={classes.orderID}>Order ID: {order.id}</div>
                  <div className={classes.orderDateTime}>
                    Placed on {orderSummaryData.orderDateTime}
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div className={classes.paymentStatusTitle}>
                    Payment Status:
                    <div className={classes.paymentStatus}>{paymentStatus}</div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "centre",
                  width: "50%",
                }}
              >
                <div className={classes.statusTitle}>
                  {status[currentStatus]}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  {firstChoise.length > 0 || secondChoice.length > 0 ? (
                    <div>Mark as: &nbsp;</div>
                  ) : (
                    ""
                  )}

                  {firstChoise.length > 0 ? (
                    <>
                      <Button
                        value={status[firstChoise]}
                        type="primary"
                        size="small"
                        onClick={(e) => handleClick(e)}
                      >
                        {firstChoise}
                      </Button>
                      &nbsp;
                    </>
                  ) : (
                    ""
                  )}
                  {secondChoice.length > 0 ? (
                    <Button
                      value={status[secondChoice]}
                      type="primary"
                      size="small"
                      onClick={(e) => handleClick(e)}
                    >
                      {secondChoice}
                    </Button>
                  ) : (
                    ""
                  )}
                </div>

                <div
                  style={{
                    marginTop: 10,
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Button
                    size="small"
                    onClick={handleMapOrderClick}
                    hidden={orderStatus !== "delivered"}
                    style={{ marginRight: 5 }}
                  >
                    Map Order
                  </Button>
                  <Button size="small" onClick={toggleReportModal}>
                    Report
                  </Button>

                  <MapOrderModal
                    mappedOrders={order.mapped_orders}
                    reorderId={+order.id}
                    customerUserId={+order.user_id}
                    storeId={+order.retail_store_id}
                    visible={mapOrderModalVisible}
                    onCancel={toggleMapOrderModal}
                  />
                  <ReportReorderModal
                    reorderId={+order.id}
                    visible={reportModalVisible}
                    onCancel={toggleReportModal}
                  />
                </div>
              </div>
            </div>
            {orderSummaryData.items.length > 0 ? (
              <OrderSummaryTableCard
                size={isXl ? "middle" : "small"}
                items={orderSummaryData.items}
              />
            ) : (
              ""
            )}
            {/* hiding below elements for now, remove hidden attr to make visible */}
            <div className={classes.sendMsgBtn} hidden={true}>
              <Button disabled>Send Message To Supplier</Button>
            </div>
            <hr className={classes.divider} hidden={true} />
            <div className={classes.orderMsgsWrapper} hidden={true}>
              <div className={classes.orderMsgsTitle}>
                Send the Order message for Customer
              </div>
              <div className={classes.orderMsgs}>
                {orderSummaryData.message_card &&
                  orderSummaryData.message_card?.map(
                    (card: any, index: number) => (
                      <OrderMsgCard key={index} {...card} />
                    )
                  )}
              </div>
              <div className={classes.overlayComingSoon}>
                <div className={classes.overlayComingSoonText}>
                  Feature coming soon...
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.centreText}>No Orders</div>
      )}
      <div>
        <Divider className={classes.seperator} />
        <LoyaltyPointsCard customer={order} />
      </div>
    </>
  );
}

export default OrderSummaryCard;
