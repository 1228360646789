import { createUseStyles } from "react-jss";
import customeScrollbarStyle, {
  hideScrollbar,
} from "components/style/CustomScrollbar";
const useStyles = createUseStyles(({ colors }: Theme) => ({
  persOffer: {
    backgroundColor: "#E9EFF1",
    borderRadius: "5px",
    width: "100%",
    height: "100%",
    boxShadow:
      "-1px -1px 4px rgba(34, 94, 119, 0.25), 1px 1px 4px rgba(34, 94, 119, 0.25)",

    "& h2": {
      padding: "2rem",
    },
  },
  emptyImage: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "1rem",
  },
  progressbarCard: { padding: "1rem" },
  offer: { padding: "1rem" },
  emptyImage2: {
    display: "flex",
    float: "right",
    alignItems: "center",
    padding: "1rem",
  },
  orderSummaryCard: {
    width: "100%",
    height: "100%",
    padding: "0 0.8rem 0.8rem",
    color: "#000",
    backgroundColor: colors.light100,
    borderRadius: "5px",
    boxShadow:
      "-1px -1px 4px rgba(34, 94, 119, 0.25), 1px 1px 4px rgba(34, 94, 119, 0.25)",
  },
  orderSummaryCardInner: {
    height: "100%",
    padding: "0.2rem",
    overflowY: "auto",

    // customizing scrollbar
    ...hideScrollbar,
    // ...customeScrollbarStyle,
  },
  orderDetails: {
    minHeight: 50,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  orderID: {
    margin: "0 0.7rem",
    fontSize: 16,
    fontWeight: 700,
    color: colors.primary200,
  },
  orderDateTime: {
    fontSize: "0.9rem",
    fontWeight: 600,
    color: colors.light500,
  },

  sendMsgBtn: {
    minHeight: "90px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  divider: {
    height: "1.5px",
    background: colors.light500,
  },
  seperator: {
    border: "0.5px solid rgba(0, 0, 0, 0.25)",
  },
  orderMsgsTitle: {
    minHeight: "70px",
    color: colors.primary200,
    fontSize: "1.3rem",
    fontWeight: 600,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  orderMsgs: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  "@media (max-width: 1050px)": {
    orderDetails: { minHeight: 50 },
    orderID: { fontSize: "1.1rem" },
  },
}));

export { useStyles };
