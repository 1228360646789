// https://github.com/react-hook-form/resolvers/blob/master/src/yup.ts
import Yup, { lazy, object, reach, setLocale } from 'yup'
import { Resolver, transformToNestObject, FieldValues } from 'react-hook-form'
import type { ObjectSchema } from 'yup'

type ResolverOptions = Omit<Yup.ValidateOptions, 'context'>

/* eslint-disable no-template-curly-in-string */

setLocale({
  mixed: {
    required: "can't be empty"
  },
  string: {
    email: 'invalid email',
    length: 'should have ${length} characters',
    min: 'too short, minimum ${min}',
    max: 'too long, maximum ${max}'
  }
})

const parseErrorSchema = (
  error: Yup.ValidationError,
  validateAllFieldCriteria: boolean
) => (Array.isArray(error.inner) && error.inner.length
  ? error.inner.reduce(
    (previous: Record<string, any>, { path, message, type }) => {
      const previousTypes = (previous[path] && previous[path].types) || {}
      const key = path || type

      return {
        ...previous,
        ...(key
          ? {
            [key]: {
              ...(previous[key] || {
                message,
                type
              }),
              ...(validateAllFieldCriteria
                ? {
                  types: {
                    ...previousTypes,
                    [type]: previousTypes[type]
                      ? [ ...[].concat(previousTypes[type]), message ]
                      : message
                  }
                }
                : {})
            }
          }
          : {})
      }
    },
    {}
  )
  : {
    [error.path]: { message: error.message, type: error.type }
  })

const yupResolver = <TFieldValues extends FieldValues>(
  schema: Yup.ObjectSchema | Yup.Lazy,
  options: ResolverOptions
): Resolver<TFieldValues> => async (
    values,
    context,
    validateAllFieldCriteria = false
  ) => {
    try {
      if (
        (options as Yup.ValidateOptions).context
        && process.env.NODE_ENV === 'development'
      ) {
        // eslint-disable-next-line no-console
        console.warn(
          "You should not used the yup options context. Please, use the 'useForm' context object instead"
        )
      }
      return {
        values: (await schema.validate(values, {
          ...options,
          context
        })) as any,
        errors: {}
      }
    } catch (e) {
      const parsedErrors = parseErrorSchema(e, validateAllFieldCriteria)
      return {
        values: {},
        errors: transformToNestObject(parsedErrors)
      }
    }
  }

class BaseModel {
  static schema: ObjectSchema

  static validationResolver<TInput>(
    fields: string[],
    options: ResolverOptions = {
      abortEarly: false
    }
  ) {
    const validationSchema = lazy(() => {
      if (fields.length === 0) {
        return this.schema
      }

      return object(fields.reduce((fieldList: any, field) => {
        // eslint-disable-next-line no-param-reassign
        fieldList[field] = reach(this.schema, field)

        return fieldList
      }, {}))
    })

    return yupResolver<TInput>(validationSchema, options)
  }
}

export default BaseModel
