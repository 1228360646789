import { ArrowLeftOutlined } from "@ant-design/icons";
import { Col, Row, Typography } from "antd";
import { getBlogDetails } from "api/api";
import Loader from "components/loader/Loader";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useHistory, useRouteMatch } from "react-router-dom";

export default function ArticleDetailsPage() {
  const classes = useStyles();
  const history = useHistory();
  const [pageData, setPageData] = useState<any>({
    loading: true,
    article: {},
  });
  const {
    params: { articleSlug },
  } = useRouteMatch<any>();

  const fetchInitialData = async () => {
    let articleDetails = await getBlogDetails(articleSlug);

    if (articleDetails.length) {
      setPageData({
        ...pageData,
        loading: false,
        article: articleDetails[0],
      });
    }
  };

  useEffect(() => {
    if (pageData.loading) {
      fetchInitialData();
    }
  }, []);

  if (pageData.loading) {
    return <Loader />;
  }

  return (
    <div className={classes.readArticlesPage}>
      <Row className={classes.header}>
        <Col className={classes.backButton} span={4}>
          <ArrowLeftOutlined onClick={() => history.goBack()} />
        </Col>
        <Col className={classes.pageHeader} span={20}>
          {/* Articles */}
        </Col>
      </Row>

      <div className={classes.pageContent}>
        <Typography.Title level={3} style={{ marginBottom: 20, color: "#111" }}>
          {pageData.article?.title?.rendered}
        </Typography.Title>
        <div
          dangerouslySetInnerHTML={{
            __html: pageData.article.content.rendered,
          }}
        ></div>
      </div>
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  readArticlesPage: {
    background: "rgba(217, 217, 217, 0.2)",
  },
  backButton: {
    textAlign: "center",
  },
  pageHeader: {
    fontSize: 13,
    fontWeight: 700,
  },
  header: {
    height: 58,
    // position: "sticky",
    top: "0",
    background: "transparent",
    alignItems: "center",
  },
  pageContent: {
    padding: [0, 20, 50],

    "& img": {
      maxWidth: "100%",
      objectFit: "contain",
      height: "auto",
    },
  },
}));
